import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment-timezone';

@Pipe({
	name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

	transform(value: any, args?: any): any {

		if (value != null && value != undefined) {
			
			if (value.includes("+")) {
				value = value.split("+")[0]
			}

			if(value.includes('Z')) {
				let now = moment().tz('Asia/Kolkata')// add this 4of 4
				value = now.format(value);
				value = new Date(value);
				let month = (value.getMonth() + 1).toString();
				let ddate = (value.getDate()).toString();
				if ((value.getDate()).toString().length == 1) {
					ddate = "0" + (value.getDate()).toString();
				}
				if ((value.getMonth() + 1).toString().length == 1) {
					month = "0" + (value.getMonth() + 1).toString();
				}
				return ddate + "/" + month + "/" + value.getFullYear();
			}
			return value = moment(value).format('DD/MM/YYYY')// add this 4of 4
			// value = now.format(value);
			// value = new Date(value);
			// let month = (value.getMonth() + 1).toString();
			// let ddate = (value.getDate()).toString();
			// if ((value.getDate()).toString().length == 1) {
			// 	ddate = "0" + (value.getDate()).toString();
			// }
			// if ((value.getMonth() + 1).toString().length == 1) {
			// 	month = "0" + (value.getMonth() + 1).toString();
			// }
			// return ddate + "/" + month + "/" + value.getFullYear();
			// value
		}
		return 'N/A';

	}

}
