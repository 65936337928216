import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PnrRetrieveBookingSummaryRoutingModule } from './pnr-retrieve-booking-summary-routing.module';
import { PnrRetrieveDetailComponent } from './pnr-retrieve-detail/pnr-retrieve-detail.component';
import { PnrRetrievePricingComponent } from './pnr-retrieve-pricing/pnr-retrieve-pricing.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { PnrRetrieveBookingSummaryComponent } from './pnr-retrieve-booking-summary.component';
import { PnrRetrieveSelectedFlightComponent } from './pnr-retrieve-selected-flight/pnr-retrieve-selected-flight.component';

@NgModule({
  imports: [
    CommonModule,
    PnrRetrieveBookingSummaryRoutingModule,ReactiveFormsModule,FormsModule,SharedModule,
    NgxLoadingModule.forRoot({
			animationType: ngxLoadingAnimationTypes.circle
		})
  ],
  declarations: [PnrRetrieveBookingSummaryComponent, PnrRetrieveDetailComponent, PnrRetrievePricingComponent, PnrRetrieveSelectedFlightComponent]
})
export class PnrRetrieveBookingSummaryModule { }
