<div class="baggage-container">
  <div class="baggage-box">
    <div *ngFor="let item of ssrAvailability; let i = index;">
       <div *ngIf="i==0">
        <div class="row baggage-identifier mb-3">
            <div class="col">
                <span class="head text-left"><i class="fa fa-users mr-1" aria-hidden="true"></i>Travellers</span>
            </div>
            <div class="col">
                <span class="head"><span >                                                    <img src="assets/v3images/icons/wheelchair.png" width="15px" class="mr-1" /> 
                </span>Wheel chair</span>
            </div>
            <div class="col">
                <span class="head text-right"><i class="fa fa-check mr-1" aria-hidden="true"></i>Your Selection</span>
            </div>
        </div>
        <div class="custom-border  row " *ngFor="let passenger of item.travellers; let k = index;">
            <ng-container *ngIf="passenger.type != 'INF'">
                <div class="col traveller-info">
                    <div class="">
                        <div class="traveller-name">{{passenger.title}} {{passenger.firstName}} {{passenger.lastName}}</div>
                    </div>
                </div>
                <div class="col meal-select">
                    <div class="">
                        <select name="baggage" id="" (change)="changeWheelChair($event.target.value, k)" style="margin-bottom: 2px;height: 28px;">
                            <option value="-1">No Wheel Chair</option>
                            <ng-container *ngFor="let wheel_chair of item.wheelChairs; let j=index">
                                <option [value]="j">{{wheel_chair.type}} {{wheel_chair.name}} &#8377; {{wheel_chair['totalFare']}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>
                <div class="col ">
                    <ng-container *ngIf="passenger.wheelchair !=undefined">
                        <div class="selected-preview text-right">
                            <p style="font-size:12px"> <img src="assets/v3images/icons/wheelchair.png" width="15px" class="mr-1" /> 

                                <span class="s-meal-name">{{passenger.wheelchair}} </span></p>
                        </div>
                    </ng-container>
                </div>

            </ng-container>
        </div>
       </div>
    </div>
  </div>
</div>