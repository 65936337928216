export enum ActionTypes {
  SetSsr = '[AddOn] Add SSR',
  ResetSelectedSsr = '[AddOn] Reset SSR selection',
  InitializeSelectedSSR = '[AddOn] Initialise SSR',
  ToggleAddonSummary = '[AddOn] Show Hide Addon Summary'
}

export const SetSsr = payload => ({
  type : ActionTypes.SetSsr,
  payload
})
export const InitializeSelectedSSR = payload => ({
  type : ActionTypes.InitializeSelectedSSR,
  payload
})

export const ResetAddonSelection = payload => ({
  type : ActionTypes.ResetSelectedSsr
})

export const ToggleAddonSummary = payload => ({
  type : ActionTypes.ToggleAddonSummary,
  payload
})


