import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-flight-segment-info',
  templateUrl: './flight-segment-info.component.html',
  styleUrls: ['./flight-segment-info.component.scss']
})
export class FlightSegmentInfoComponent implements OnInit {
  @Input() flightInfo = []
  @Input() fareClass = []
  constructor(
    private commonService: CommonService,
  ) { }

  ngOnInit() {
  }
  // function to calculate duration between two datetime
  // getDuration(dt1, dt2) {
  //   return this.commonService.timeDuration(dt2, dt1);
  // }
  getDuration(dt1, dt2,duration) {
		if(!duration){
			duration = this.commonService.timeDuration(dt2,dt1);
		}
		return duration
	}

	calculateStopOverDuration(legs, index) {
		let fistDate = legs[index].arrivalDateTime;
		if (legs[index + 1] != undefined) {
			let secDate = legs[index + 1].departureDateTime;
			return this.getDuration(fistDate, secDate,'');
		}
		return null;
	}
}
