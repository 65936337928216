import { ActionTypes } from "./actions";

export const initialState = {
  outBoundFlights : [],
  inBoundFlights : [],
  specialReturnFlight : [],
  pushType : 'OUTBOUND'
};

export function flightReducerData(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.setOutBoundFlight:
      return {
        ...state,
        pushType : 'OUTBOUND',
        outBoundFlights : action.payload
      }
    case ActionTypes.setInBoundFlight:
      return {
        ...state,
        pushType : 'INBOUND',
        inBoundFlights : action.payload
      }
    case ActionTypes.setSpecialReturnFlight:
      return {
        ...state,
        pushType : 'SPECIALROUNDTRIP',
        specialReturnFlight : action.payload
      }
    default:
      return state;
  }
}






