import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';

@Component({
	selector: 'app-baggage',
	templateUrl: './baggage.component.html',
	styleUrls: ['./baggage.component.scss']
})
export class BaggageComponent implements OnInit {
	@Input() ssrAvailability;
	@Input() segmentIndex = 0
	@Output() callBaggageEvent : EventEmitter<any> = new EventEmitter();
	@Output() callAdditionalBaggageEvent : EventEmitter<any> = new EventEmitter();
	constructor() { }

	ngOnInit() {
		for (var ssr of this.ssrAvailability) {
			for (var traveller of ssr.travellers) {
				if(ssr.baggages && ssr.baggages.length > 0 && ssr.baggages[0].additional_baggage){
					traveller['additional_baggage'] = ssr.baggages[0].additional_baggage
				}
			}
		}
	}
	ngOnChanges(){
		for (var ssr of this.ssrAvailability) {
			for (var traveller of ssr.travellers) {
				if(ssr.baggages && ssr.baggages.length > 0 && ssr.baggages[0].additional_baggage){
					traveller['additional_baggage'] = ssr.baggages[0].additional_baggage
				}
			}
		}
	}
	changeBaggage(index, pax){
		let leg = 0; 
		const tempArr = Object.assign({},  this.ssrAvailability[leg])
		
		if(index == "-1") {
			this.ssrAvailability[leg].travellers[pax]['baggage'] = undefined;
			this.ssrAvailability[leg].travellers[pax]['baggage_code'] = undefined;
			this.ssrAvailability[leg].travellers[pax]['baggageFare'] = 0;
			this.makeBaggeObject(pax, null, true, 0, leg,tempArr,null);
		}
		else{
			let baggage = tempArr.baggages[index];
			// this.ssrAvailability[leg].travellers[pax]['baggage'] = baggage.name;	
			this.ssrAvailability[leg].travellers[pax]['baggage_code'] = baggage.code;
			this.ssrAvailability[leg].travellers[pax]['additional_baggage'] = baggage.additional_baggage ? baggage.additional_baggage : null;		
			if(!this.ssrAvailability[leg].travellers[pax]['additional_baggage_selected_count']){
				this.ssrAvailability[leg].travellers[pax]['additional_baggage_selected_count'] = 0
			}
			this.ssrAvailability[leg].travellers[pax]['baggageFare'] = baggage.totalFare;
			this.makeBaggeObject(pax, baggage.code, false, baggage.totalFare, leg,tempArr,baggage)
		}
	
			
	}
	
	makeBaggeObject(passengerIndex, seatNumber, isRemove, price, leg,tempArr,baggage){
		let flag = true;
		let segment = this.segmentIndex
		if(tempArr.flightInfo.ssrSRT[segment] && tempArr.flightInfo.ssrSRT[segment][leg]  && tempArr.flightInfo.ssrSRT[segment][leg].baggage != undefined && tempArr.flightInfo.ssrSRT[segment][leg].baggage != null) {
			tempArr.flightInfo.ssrSRT[segment][leg].baggage = tempArr.flightInfo.ssrSRT[segment][leg].baggage.filter(obj =>{
				if(isRemove) {
					flag = false;
					if(obj.travellerNumber != passengerIndex) {
						return obj;
					}
				}
				else{
					if(obj.travellerNumber == passengerIndex) {
						obj['code'] = seatNumber;
						obj['price'] = price;
						obj['item'] = baggage;
						flag = false;
					}
					return obj;
				}
				
			});
		}
		
		if(flag) {
			let obj = {
				"code": seatNumber,
				"travellerNumber": passengerIndex,
				"item": baggage,
				"price" : price,
				"title": this.ssrAvailability[leg].travellers[passengerIndex].title,
				"firstName": this.ssrAvailability[leg].travellers[passengerIndex].firstName,
				"lastName": this.ssrAvailability[leg].travellers[passengerIndex].lastName,
			}
			if(tempArr.flightInfo.ssrSRT[segment] == undefined){
				tempArr.flightInfo.ssrSRT[segment] = []
			}
			if(tempArr.flightInfo.ssrSRT[segment][leg] == undefined){
				tempArr.flightInfo.ssrSRT[segment][leg] = {}
			}
			if(tempArr.flightInfo.ssrSRT[segment][leg].baggage == undefined || tempArr.flightInfo.ssrSRT[segment][leg].baggage == null) {
				tempArr.flightInfo.ssrSRT[segment][leg].baggage = [];				
			}
			tempArr.flightInfo.ssrSRT[segment][leg].baggage.push(obj)
		}
		this.callBaggageEvent.emit(tempArr.flightInfo.ssrSRT);
		
	}

	makeAdditionalBaggeObject(passengerIndex, pax, isRemove, leg,tempArr){
		let flag = true;
		let segment = this.segmentIndex
		if(tempArr.flightInfo.ssrSRT[segment] && tempArr.flightInfo.ssrSRT[segment][leg]  && tempArr.flightInfo.ssrSRT[segment][leg].additionalBaggage != undefined && tempArr.flightInfo.ssrSRT[segment][leg].additionalBaggage != null) {
			tempArr.flightInfo.ssrSRT[segment][leg].additionalBaggage = tempArr.flightInfo.ssrSRT[segment][leg].additionalBaggage.filter(obj =>{
				if(isRemove &&  pax.additional_baggage_selected_count == 0) {
					flag = false;
					if(obj.travellerNumber != passengerIndex) {
						return obj;
					}
				}
				else{
					if(obj.travellerNumber == passengerIndex) {
						obj['code'] = pax.additional_baggage.code;
						obj['price'] = pax.additional_baggage_selected_count * pax.additional_baggage.price;
						obj['item'] = pax.additional_baggage;
						obj['count'] = pax.additional_baggage_selected_count;
						flag = false;
					}
					return obj;
				}
				
			});
		}
		
		if(flag) {
			let obj = {
				"code": pax.additional_baggage.code,
				"travellerNumber": passengerIndex,
				"item": pax.additional_baggage,
				"price" : pax.additional_baggage_selected_count * pax.additional_baggage.price,
				"count" : pax.additional_baggage_selected_count,
				"title": this.ssrAvailability[leg].travellers[passengerIndex].title,
				"firstName": this.ssrAvailability[leg].travellers[passengerIndex].firstName,
				"lastName": this.ssrAvailability[leg].travellers[passengerIndex].lastName,
			}
			if(tempArr.flightInfo.ssrSRT[segment] == undefined){
				tempArr.flightInfo.ssrSRT[segment] = []
			}
			if(tempArr.flightInfo.ssrSRT[segment][leg] == undefined){
				tempArr.flightInfo.ssrSRT[segment][leg] = {}
			}
			if(tempArr.flightInfo.ssrSRT[segment][leg].additionalBaggage == undefined || tempArr.flightInfo.ssrSRT[segment][leg].additionalBaggage == null) {
				tempArr.flightInfo.ssrSRT[segment][leg].additionalBaggage = [];				
			}
			tempArr.flightInfo.ssrSRT[segment][leg].additionalBaggage.push(obj)
		}
		this.callAdditionalBaggageEvent.emit(tempArr.flightInfo.ssrSRT);
		
	}

	handleAdditionalSelect(isIncrement,pax,passengerIndex){
		let leg = 0
		const tempArr = Object.assign({},  this.ssrAvailability[leg])
		if(isIncrement){
			pax.additional_baggage_selected_count  = pax.additional_baggage_selected_count ? pax.additional_baggage_selected_count+1 : 1
			this.makeAdditionalBaggeObject(passengerIndex, pax, false, leg,tempArr)
		}else{
			pax.additional_baggage_selected_count = pax.additional_baggage_selected_count-1
			this.makeAdditionalBaggeObject(passengerIndex, pax, true, leg,tempArr)
		}
	}
	getSelected(ssrIndex,paxIndex,baggage){
		let item = this.ssrAvailability[ssrIndex]
		if(item.travellers[paxIndex] && item.travellers[paxIndex].baggage_code){
			return item.travellers[paxIndex].baggage_code == baggage.code
		}
		return false
	}
}
