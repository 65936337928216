export const environmentConstant = {
    baseUrl: '',
    homePageSlider: [
        { name: 'image1', url: 'assets/v3images/banner1.jpg' },
        { name: 'image2', url: 'assets/images/banners/aadesh_cargo.jpeg' },
        { name: 'image3', url: 'assets/images/banners/singapore-vistara.jpg' },
        { name: 'image4', url: 'assets/images/banners/africa-regional-infographics_B2B-Corporate.jpg'},
    ],
    flightSlider: [
        { name: 'image1', url: 'assets/images/go.png' },
        { name: 'image2', url: 'assets/images/airindia.png' },
        { name: 'image3', url: 'assets/images/jetairway.png' },
        { name: 'image4', url: 'assets/images/indigo.png' },
        { name: 'image5', url: 'assets/images/spicejet.png' }
    ],
    dashBoardSlider: [
        { name: 'image1', url: 'assets/images/banners/vistara_banner_001.jpg' },
        { name: 'image2', url: 'assets/images/banners/aadesh_cargo.jpeg' },
        // { name: 'image2', url: 'assets/images/banners/AMD_FLYER.jpg' },
        { name: 'image3', url: 'assets/images/banners/singapore-vistara.jpg' },
        { name: 'image4', url: 'assets/images/banners/africa-regional-infographics_B2B-Corporate.jpg' },

    ],
    //     SG_REGULAR
    // SG_CORPORATE
    // SG_COUPON
    // SG_SME
    // GO_CORPORATE
    // GO_SPECIAL
    // GOAIR_REGULAR
    // 6E_REGULAR
    // 6E_CORPORATE
    // AMADEUS_V4
    // Air Arabia
    // SABRE
    // 2T
    // airasia
    // airasia_v2
    promocodeSupplier: [
        {
            name: 'REGULAR',
            value: 'REGULAR',
        },
        {
            name: 'SPL',
            value: 'SPL',
        },
        {
            name: 'COUPON',
            value: 'COUPON',
        },
        {
            name: 'SPECIAL',
            value: 'SPECIAL',
        },

    ]
}