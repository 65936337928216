import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-re-attemp-baggage',
  templateUrl: './re-attemp-baggage.component.html',
  styleUrls: ['./re-attemp-baggage.component.scss']
})
export class ReAttempBaggageComponent implements OnInit {
  @Input() ssrAvailability;
	@Output() callBaggageEvent : EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  changeBaggage(index, pax){
		let leg = 0; 
		const tempArr = Object.assign({},  this.ssrAvailability[leg])
		
		if(index == "-1") {
			this.ssrAvailability[leg].travellers[pax]['baggage'] = undefined;
			this.ssrAvailability[leg].travellers[pax]['baggageFare'] = 0;
			this.makeSeatObject(pax, null, true, 0, leg,tempArr,null);
		}
		else{
			let baggages = tempArr.baggages[index];
			this.ssrAvailability[leg].travellers[pax]['baggage'] = baggages.name;		
			if(baggages.name == null) {
				this.ssrAvailability[leg].travellers[pax]['baggage'] = baggages.code;
			}
			this.ssrAvailability[leg].travellers[pax]['baggageFare'] = baggages.totalFare;
			this.makeSeatObject(pax, baggages.code, false, baggages.totalFare, leg,tempArr,baggages)
		}
	
			
	}
	

	makeSeatObject(passengerIndex, seatNumber, isRemove, price, leg,tempArr,baggages){
		let flag = true;
		if(tempArr.flightInfo.ssrs[leg].baggage != undefined && tempArr.flightInfo.ssrs[leg].baggage != null) {
			tempArr.flightInfo.ssrs[leg].baggage = tempArr.flightInfo.ssrs[leg].baggage.filter(obj =>{
				if(isRemove) {
					flag = false;
					if(obj.travellerNumber != passengerIndex) {
						return obj;
					}
				}
				else{
					if(obj.travellerNumber == passengerIndex) {
						obj['code'] = seatNumber;
						obj['name'] = baggages['name'];
						obj['price'] = price;
						obj['item'] = baggages;
						flag = false;
					}
					return obj;
				}
				
			});
		}
		
		if(flag) {
			let obj = {
				"code": seatNumber,
				"name": baggages['name'],
				"travellerNumber": passengerIndex,
				"item": baggages,
				"price" : price
			}
			if(tempArr.flightInfo.ssrs[leg].baggage == undefined || tempArr.flightInfo.ssrs[leg].baggage == null) {
				tempArr.flightInfo.ssrs[leg].baggage = [];				
			}
			tempArr.flightInfo.ssrs[leg].baggage.push(obj)
		}
		console.log(tempArr.flightInfo.ssrs);
		this.callBaggageEvent.emit(tempArr.flightInfo.ssrs);
	}

}
