import { ActionTypes } from "./actions";

export const initialState = {
  insuranceTravellers : [],
};

export function InsuranceTravellerReducers(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SetInsuranceTraveller:
      return {
        ...state,
        insuranceTravellers : action.payload
      }
    default:
      return state;
  }
}






