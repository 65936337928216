<div class="baggage-container">
    <div class="baggage-box" [@.disabled]="true">
        <mat-tab-group>
            <div *ngFor="let segment of ssrAvailability; let s=index;">
                <mat-tab label="{{segment[0].departure}}-{{segment[segment.length-1].arrival}}">
                    <div *ngFor="let item of segment; let i = index;">
                        <ng-container *ngIf="i==0">
                            <div class="row baggage-identifier">
                                <div class="col-3">
                                    <span class="head">Travellers</span>
                                </div>
                                <div class="col p-0">
                                    <span class="head"> Baggage</span>
                                </div>
                                <div class="col-3">
                                    <span class="head">Addnl. PC</span>
                                </div>
                                <div class="col-4">
                                    <span class="head">Your
                                        Selection</span>
                                </div>
                            </div>
                            <div class="row mb-3" *ngFor="let passenger of item.travellers; let k = index;">
                                <ng-container>
                                    <ng-container *ngIf="passenger.type != 'INF'">
                                        <div class="col-3 traveller-info">
                                            <div class="">
                                                <div class="traveller-name">{{passenger.title}}
                                                    {{passenger.first_name}} {{passenger.last_name}}</div>
                                            </div>
                                        </div>
                                        <div class="col meal-select">
                                            <ng-container
                                                *ngIf="responseData != undefined && responseData?.ssrs != undefined && responseData?.ssrs[i] != undefined && responseData.ssrs[i]?.baggages != undefined && responseData.ssrs[i].baggages[k] != undefined && responseData.ssrs[i].baggages[k].status !=undefined && responseData.ssrs[i].baggages[k].status == 'SUCCESS';else baggageSelection">
                                                <span >
                                                    {{passenger.baggage}}</span>
                                            </ng-container>

                                            <!-- <ng-container *ngFor="let paxSSR of passenger.ssr">
                                                <div class="meal-input"
                                                *ngIf="paxSSR.origin == item.departure && paxSSR.destination == item.arrival && paxSSR.hasBaggage == undefined">
                                                <div class="">
                                                    <select name="baggage" id=""
                                                        (change)="changeBaggage($event.target.value, k,s)">
                                                        <option value="-1">No Baggage</option>
                                                        <ng-container
                                                            *ngFor="let baggages of item.baggages; let j=index">
                                                            <option [value]="j">{{baggages.type}} {{baggages.name}}
                                                                &#8377; {{baggages['totalFare']}}</option>
                                                        </ng-container>
                                                    </select>
                                                </div>
                                            </div>
                                            </ng-container> -->

                                            <ng-template #baggageSelection>
                                                <ng-container *ngIf="passenger.ssr && passenger.ssr[s] && passenger.ssr[s] && passenger.ssr[s].baggages && passenger.ssr[s].baggages.name; else assignedNewBaggage">
                                                    {{passenger.ssr[s].baggages.name}}
                                                </ng-container>
                                                <ng-template #assignedNewBaggage>
                                                    
                                                    <select name="baggage" id=""
                                                    (change)="changeBaggage($event.target.value, k,s)">
                                                    <option value="-1">No Baggage</option>
                                                    <ng-container
                                                        *ngFor="let baggages of item.baggages; let j=index">
                                                        <option [value]="j">
                                                        {{baggages['code']}} | &#8377; {{baggages['totalFare']}} |  {{baggages.type}} {{baggages.name}}
                                                        </option>
                                                    </ng-container>
                                                    </select>
                                                </ng-template>
                                               
                                            </ng-template>
                                            <ng-template #assignedBaggage>
                                                <ng-container *ngFor="let paxSSR of passenger.ssr">
                                                    <ng-container *ngIf="paxSSR.origin == item.departure && paxSSR.destination == item.arrival">
                                                        <span class="baggage-icon0 mr-2"
                                                        *ngIf="paxSSR?.baggages != null"><img
                                                            src="assets/images/briefcase.png" /></span>
                                                        {{paxSSR?.baggages?.name}}
                                                    </ng-container>
                                                 </ng-container>
                                            </ng-template>
                                        </div>
                                        <div class="col-3 meal-select" >
                                            <ng-container
                                                *ngIf="responseData != undefined && responseData?.ssrs != undefined && responseData?.ssrs[i] != undefined && responseData.ssrs[i]?.additionalBaggages != undefined && responseData.ssrs[i].additionalBaggages[k] != undefined && responseData.ssrs[i].additionalBaggages[k].status !=undefined && responseData.ssrs[i].additionalBaggages[k].status == 'SUCCESS';else addtionalBaggageSelection">
                                                <span >
                                                    {{passenger.baggage}}</span>
                                            </ng-container>
                                            <ng-template #addtionalBaggageSelection>
                                                <ng-container *ngIf="passenger.ssr && passenger.ssr[s] && passenger.ssr[s] && passenger.ssr[s].additionalBaggages && passenger.ssr[s].additionalBaggages.name;else assignNewAdditionalBaggage">
                                                    <div class="d-flex justify-content-around">
                                                        {{passenger.ssr[s].additionalBaggages.name}}
                                                    </div>
                                                </ng-container>
                                                <ng-template #assignNewAdditionalBaggage>
                                                    <div class="d-flex justify-content-around" *ngIf="passenger.additional_baggage !=undefined && passenger.additional_baggage">
                                                        <div class="text-center w-25">
                                                            <i *ngIf="passenger.additional_baggage_selected_count && passenger.additional_baggage_selected_count > 0" class="fa fa-solid fa-minus-circle" (click)="handleAdditionalSelect(false,passenger,k,s)"></i>
                                                        </div>
                                                        <div class="border border-radius-5 border-primary w-50 text-center">
                                                            {{passenger.additional_baggage_selected_count ? passenger.additional_baggage_selected_count : 0}} 
                                                            <ng-container *ngIf="passenger.additional_baggage_selected_count > 0">
                                                                (&#8377; {{passenger.additional_baggage.price * passenger.additional_baggage_selected_count}})
                                                            </ng-container>
                                                        </div>
                                                        <div class="text-center w-25">
                                                            <i *ngIf="passenger.additional_baggage.maxAllowed && (!passenger.additional_baggage_selected_count || passenger.additional_baggage_selected_count < passenger.additional_baggage.maxAllowed) " class="fa fa-solid fa-plus-circle" (click)="handleAdditionalSelect(true,passenger,k,s)"></i>
                                                        </div>
                                                        <ng-container *ngIf="responseData != undefined">
                                                            <ng-container *ngIf="responseData?.ssrs != undefined">
                                                                <ng-container *ngIf="responseData?.ssrs[i] != undefined">
                                                                    <ng-container
                                                                        *ngIf="responseData.ssrs[i]?.additionalBaggages != undefined && responseData.ssrs[i]?.additionalBaggages != null">
                                                                        <ng-container
                                                                            *ngFor="let responseBaggage of responseData.ssrs[i].additionalBaggages">
                                                                            <ng-container
                                                                                *ngIf="responseBaggage.travellerNumber == k">
                                                                                <span
                                                                                    *ngIf="responseBaggage.status == 'SUCCESS'"
                                                                                    class="ssr-status  pl-2">
                                                                                    <i class="fa fa-check-circle text-success" title="SUCCESS"></i>
                                                                                </span>
                                                                                <span *ngIf="responseBaggage.status == 'FAILED'"
                                                                                    class="ssr-status  pl-2 ">
                                                                                    <i class="fa fa-times-circle text-danger" title="{{responseBaggage.message}}"></i>
                                                                                    
                                                                                </span>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </ng-template>
                                            </ng-template>
                                        </div>
                                        <div class="col-4 selected-baggage">
                                            <ng-container
                                                *ngIf="passenger.baggage !=undefined else assignedBaggage">
                                                <div class="selected-preview">
                                                    <span class="baggage-icon0"><img
                                                            src="assets/images/briefcase.png" /></span>
                                                    <span class="s-meal-name">{{passenger.baggage}} &nbsp;</span>
                                                    <span class="s-price">&#8377; {{passenger.baggageFare}}</span>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="responseData != undefined">
                                                <ng-container *ngIf="responseData?.ssrs != undefined">
                                                    <ng-container *ngIf="responseData?.ssrs[i] != undefined">
                                                        <ng-container
                                                            *ngIf="responseData.ssrs[i]?.baggages != undefined && responseData.ssrs[i]?.baggages != null">
                                                            <ng-container
                                                                *ngFor="let responseBaggage of responseData.ssrs[i].baggages">
                                                                <ng-container
                                                                    *ngIf="responseBaggage.travellerNumber == k">
                                                                    <span
                                                                        *ngIf="responseBaggage.status == 'SUCCESS'"
                                                                        class="ssr-status  pl-2">
                                                                        <i class="fa fa-check-circle text-success" title="SUCCESS"></i>
                                                                    </span>
                                                                    <span *ngIf="responseBaggage.status == 'FAILED'"
                                                                        class="ssr-status  pl-2 ">
                                                                        <i class="fa fa-times-circle text-danger" title="{{responseBaggage.message}}"></i>
                                                                        
                                                                    </span>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="responseData == undefined && showRequestLoader">
                                                <span class="status-loader"> <i
                                                        class="fas fa-circle-notch fa-spin"></i></span>
                                            </ng-container>
                                            <ng-template #statusField>
                                                <ng-container
                                                    *ngIf="responseData != undefined && responseData?.ssrs != undefined && responseData?.ssrs[i] != undefined && responseData.ssrs[i]?.baggages != undefined &&responseData.ssrs[i]?.baggages != null">
                                                    <ng-container
                                                        *ngFor="let bResp of responseData.ssrs[i].baggages">
                                                        <span
                                                            *ngIf="bResp.status == 'SUCCESS' && bResp.travellerNumber == k"
                                                            class="ssr-status status-success">
                                                            {{bResp.status}}</span>
                                                        <span
                                                            *ngIf="bResp.status == 'FAILED' && bResp.travellerNumber == k"
                                                            class="ssr-status status-error"> {{bResp.status}}</span>

                                                    </ng-container>
                                                </ng-container>
                                            </ng-template>
                                        </div>

                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </mat-tab>
            </div>
        </mat-tab-group>
    </div>
</div>