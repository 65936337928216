import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PostService } from './post.service';
import { BehaviorSubject } from 'rxjs';
import { ExcelService } from './excel.service';
import { TravellerService } from './traveller/traveller.service';
import { CommonService } from './common.service';
import { NotificationService } from './notification.service';

@Injectable({
	providedIn: 'root'
})
export class BookingRequestService {

	private subject = new BehaviorSubject([]);
	getLimit = this.subject.asObservable();
	private cashSubject = new BehaviorSubject([]);
	// cashLimit = this.cashSubject.asObservable();
	private creditSubject = new BehaviorSubject([]);
	creditLimit = this.creditSubject.asObservable();
	
	private checkLimit = new BehaviorSubject({});
	getCheckLimit = this.checkLimit.asObservable();
	
	  nonInfantPaxCount = 0;

	constructor(private postService: PostService,
		private excelService : ExcelService,
		private commonService : CommonService,		
		private travellerService : TravellerService,
		private notificationService : NotificationService) { }

	setLimit(data : any) {
		this.subject.next(data)
	}
	setCreditLimit(data : any) {
		this.creditSubject.next(data)
	}

	setCheckLimitData(data:any){
		this.checkLimit.next(data)
	}

	getBookingRequest(data, request, supplierName, isLcc) {	
		this.nonInfantPaxCount = 0;	
		let limit = 'credit'
		let supplierInfo = null;
		if(!isLcc) {
			supplierInfo = {
				"SUPPLIER" : request['selectFlight']['supplierCode'],
				"ClassOfService": "",
				"DEAL_CODE": "",
				"FareBasisCode": ""
			}
		}
		else{
			supplierInfo = request['selectFlight']['selectedFare']['supplierInfo'];
		}
		let baggageRule = []
		if (JSON.parse(localStorage.selectedFlight).baggageRules != undefined) {
			baggageRule.push(JSON.parse(localStorage.selectedFlight).baggageRules[0])
		}
		let userDetails = JSON.parse(localStorage.getItem('user_details'));
		let gds_pnr = ''
		if (data.gdsPnr != undefined && data.gdsPnr!= "") {
			gds_pnr = data.gdsPnr
		}
		let obj = {
			"booking_ref": data.bookingRefNo,
			"invoice_no": data.bookingInvoice,
			"booking_res_message" : data.message,
			"fare_type" :  request['selectFlight']['selectedFare']['priceType'],
			"xl_code": userDetails.xlAccountCode,
			"affiliate_id": userDetails.affiliateId,
			"affiliate_company_name": userDetails.companyName, 
			"booked_by_user_id": 1,  
			"booked_by_user_name": userDetails.firstName + " " +userDetails.lastName, 
			"booked_timestamp": new Date(),
			"is_domestic": data.isDomestic,
			"pnr_status" : data.bookingStatus,
			"gds_pnr" : gds_pnr,
			"baggageRules" : baggageRule,
			"segments": this.bindSegments(request.selectFlight.segments, data.pnrNo, supplierName,data),
			"travellers": this.bindTravellers(request,data),
			"utilize_limit_type" : limit, 
			"gstDetail": {
				"companyName":data.corporateName,
				"email": data.corporateEmail,
				"gstNumber": data.corporateGstNumber,
				"phone": data.corporateMobile,	
			},
			"contactdetails": {
				"email": data.email,
				"phone": data.mobile,
				"affiliateAddress": {
					"address1": userDetails.address1,
					"email": userDetails.primaryEmail,
					"address2": userDetails.address2,
					"city": userDetails.city_name,
					"companyName": userDetails.companyName,
					"countryCode": "IN",
					"stateCode": userDetails.state_name,
					"zip": userDetails.pincode,
					"phone" : userDetails.mobile
				  },
			},
			"original_booking_total" : request.selectFlight.selectedFare.total,
			"commission": this.excelService.getCommission(request.selectFlight.selectedFare, this.nonInfantPaxCount),
			"supplierInfo" : supplierInfo
		
		}
		return obj;
	}

	bindSegments(segments, pnr, supplierName,data) {
		
		let segmentsArr = [];
		let selectFlightSegment = {}
		for(let i=0; i<segments.length;i++) {
			if (data.bookingResp != undefined) {
				selectFlightSegment =	data.bookingResp.selectFlight.segments[i]	
			}
			let obj = {
				"pnr": pnr,
				"booking_supplier": supplierName, 
				"ticketing_supplier": supplierName, 
				"legs": this.bindLegs(segments[i].legs,selectFlightSegment)
			}
			segmentsArr.push(obj);
		}
		return segmentsArr;
	}

	bindLegs(legs,segment) {
		let legsArr = [];
		for(let i=0; i<legs.length;i++) {
			let freeCheckInBaggage = ""
			if (legs[i].hasOwnProperty('freeCheckInBaggage')) {
				freeCheckInBaggage = legs[i].freeCheckInBaggage
			}
			let aircraft_type;
			let airline_pnr = '';
			if (segment.airlinePnr != undefined) {
				airline_pnr = segment.airlinePnr 
			}
			if (segment.legs != undefined) {
				aircraft_type = segment.legs[i].aircraftType
			}
			let obj = {
				"aircraft_type" : aircraft_type,
				"freeCheckInBaggage" : freeCheckInBaggage,
				"airline_pnr" : airline_pnr,
				"origin_airport_code": legs[i].originAirport.code,
				"origin_airport_name": legs[i].originAirport.name,
				"origin_airport_city": legs[i].originAirport.city,
				"origin_airport_country": legs[i].originAirport.country,
				"origin_timestamp": legs[i].departureDateTime,
				"origin_terminal": legs[i].originAirport.terminal,
				"destination_airport_code": legs[i].destinationAirport.code,
				"destination_airport_name": legs[i].destinationAirport.name,
				"destination_airport_city": legs[i].destinationAirport.city,
				"destination_airport_country": legs[i].destinationAirport.country,
				"destination_timestamp": legs[i].arrivalDateTime,
				"destination_terminal": legs[i].destinationAirport.terminal,
				"flight_number": legs[i].flightNumber,
				"marketing_airline_code": legs[i].marketingAirlineCode,
				"marketing_airline_name": legs[i].marketingAirlineName,
				"operating_airline_code": legs[i].operatingAirlineCode,
				"operating_airline_name": legs[i].operatingAirlineName,
				"cabin_class": legs[i].cabinClass,
				"is_technical_stop": legs[i].technicalStop,
				"duration": legs[i].duration
			}
			legsArr.push(obj);
		}
		return legsArr;
	}

	bindTravellers(request,data) {
		let travellers = request.travellers; 
		let fares = request.selectFlight.selectedFare;
		let travellersArr = [];		
		for(let i=0;i<travellers.length;i++) {

			if(travellers[i].type != "INF") {
				this.nonInfantPaxCount++;
			}
			let responseData = null;
			if(data.bookingResp != null) {
				responseData = data.bookingResp.travellers[i];
			}
			let nationality =""
			if (travellers[i].passport != null) {
				nationality = travellers[i].passport.nationality
			}

			let obj = {
				"title":travellers[i].title,
				"first_name": travellers[i].firstName,
				"last_name": travellers[i].lastName,
				"date_of_birth": travellers[i].dob,
				"gender": travellers[i].gender,
				"nationality": nationality,
				"type": travellers[i].type,
				"parent": null,
				"frequentFlyerInfos": [
					{
					  "airline":data.passengerList[i].frequentflyers,
					  "number": data.passengerList[i].frequentflyersNumber
					}
				],
				"mealPreference": {
					"code": data.passengerList[i].mealServices,
					"travellerNumber": i
				  },
				"specialServices": {
					"code": data.passengerList[i].specialServices,
					"travellerNumber": i
				  },
				
				"fare": this.bindTravellerFare(fares, travellers[i].type),
				"passport": this.bindPassportDetails(travellers[i].passport),
				"ticket": this.bindTicketDetails(responseData),
				"ssr": this.bindSSR(request, i)
			}
			travellersArr.push(obj)
		}
		return travellersArr;
	}

	bindTravellerFare(fare, type) {
		let taxArr = [];
		let tax = {};		
		let keys  = Object.keys(fare.travellerFares[type].fareComponents);
		let keyCount = 0;
		Object.values(fare.travellerFares[type].fareComponents).forEach(function(v) {
			let key = keys[keyCount];
			tax[key] = v;
			keyCount++;
		})
		taxArr.push(tax);
		let obj =  {
			"base": fare.travellerFares[type].base,
			"tax" : fare.travellerFares[type].fareComponents.TAX,
			"cancellation_charges": null,			
			"taxes": taxArr,
		}
		return obj;
	}

	bindPassportDetails(passport) {
		if(passport != null) {
			let obj = {
				"number": passport.number,
				"expiry_date": passport.expiryDate,
				"issuing_country_code": passport.issuingCountry,
				"issuing_country_name": passport.nationality
			}
			return obj;
		}
		return null;
	}

	bindTicketDetails(data) {

		if(data != null) {
			let obj = {
				"number": data.ticketNumber, // Need to make it dynamic
				"status": "CONF"// Need to make it dynamic
				// "hold_date": "2019-04-26", // Need to make it dynamic
				// "ticket_date": "2019-04-26", // Need to make it dynamic
				// "cancel_date": ""
			}
			return obj;
		}else{
			return null;
		}
		
	}
	
	bindSSR(request, pax) {
		let ssrArr = [];
		for(let i=0;i<request.ssrs.length;i++) {
			let obj = {
				"origin": request.ssrs[i].supplierInfo['arrivalStation'],
				"destination": request.ssrs[i].supplierInfo['departureStation'],
				"flight": request.ssrs[i].supplierInfo['flightNo'],
				"leg_index": i,
				"seats": this.getPaxNumber(request.ssrs[i].seats, pax, 'seats'),
				"meals": this.getPaxNumber(request.ssrs[i].meals, pax, 'meals'),
				"baggages": this.getPaxNumber(request.ssrs[i].baggages, pax, 'baggages')
			}
			ssrArr.push(obj);
		}
		return ssrArr;
	}
	
	getPaxNumber(item, pax, type) {
		if(item != null) {
			for(let i=0;i<item.length;i++) {
				if(item[i].travellerNumber == pax){
					if(type == 'seats') {
						let obj = {
							"code": item[i].item,
							"name": item[i].number,
							"fare": {
								"base": item[i].price,
								"tax": 0
							}
						}
						return obj;
					}
					else{
						let obj = {
							"code": item[i].item,
							"name": item[i].code,
							"fare": {
								"base": item[i].price,
								"tax": 0
							}
						}
						return obj;
					}
					
				}
			}
		}		
		return null;
		
	}

	updateLedger(value, isLCC, netPayable) {
		let limit_type = 'credit';
		let obj = {
			"transaction_ref_no": "IW19-"+value.bookingInvoice,
			"typhe": "debit",
			"amount": netPayable,
			"narration_1": value.ledgerDetails.narration1,
			"narration_2": value.ledgerDetails.narration2,
			"narration_3": value.ledgerDetails.narration3,
			"limit_type": limit_type,
			"affiliate_id": JSON.parse(localStorage.getItem('user_details'))['affiliateId']
		}
		this.postService.postMethod(`${environment.account}/ledger/updateLedger`, obj).subscribe(res => {	//fucntion to run excel accounting request
			console.log('Ledger Updated', res);
		}, err => {
			console.log('error occured in ledger updating', err);
		})
	}

	getCreditLimit(isLcc, netPayable, affiliateId, direction) {
		//  ;
		let isDiAccount = false;
		
		let limit = 'credit';
		let affiliate_code = ""
		if(localStorage.user_details != undefined) {
			if(JSON.parse(localStorage.user_details)['is_di_account'] != null) {
				isDiAccount = (JSON.parse(localStorage.user_details)['is_di_account'] == 'true')
			}
			affiliate_code = JSON.parse(localStorage.user_details).xlAccountCode
		}
		let obj = {
			"booking_date": new Date(),
			"booking_amount": netPayable,
			"type": limit,
			"affiliate_id": affiliateId,
			"isLCC" : isLcc,
			"source" : "online",
			"isDiAccount" : isDiAccount,
			"affiliate_code" : affiliate_code


		}
		let responseObj = {
			"creditLimit" : 0,
			"invalidCredit" : false,
			"type" : limit,
			"direction" : direction,
			"message" : ""
		}
	this.postService.postMethod(`${environment.account}/limit/checkLimit`, obj).subscribe(res => {
			if (res != "") {
				
				// let response = JSON.parse(res["_body"])
				let response = res
				this.setCheckLimitData(response)
				responseObj.creditLimit = response['amount'];
				responseObj.invalidCredit = response['invalidCredit'];
				responseObj.type = limit
				if(limit == 'credit'){
					this.setCreditLimit(responseObj)
				}
				this.setLimit(responseObj);
			}
			else {
				let response = res
				responseObj.creditLimit = response['amount'];
				responseObj.invalidCredit = response['invalidCredit'];
				responseObj.type = limit
				this.setLimit(responseObj);
				if(limit == 'credit'){
					this.setCreditLimit(responseObj)
				}

			}
		}, err => {
			let errRes = err
			if(errRes.error){
				responseObj.creditLimit = errRes.error.amount;
				responseObj.invalidCredit = true;
				responseObj.type = limit
				this.setLimit(responseObj);
				if(limit == 'credit'){
					this.setCreditLimit(responseObj)
				}
				if(errRes.error.message){
					let messageList = {
						status : true,
						messageList : []
					}
					let obj = {
						warningType: 'warning-box',
						message: errRes.error.message,
						detail: null,
					};
					messageList["messageList"].push(obj);
					this.notificationService.showMessage(messageList);
				}
			}
		})
		
	}

	generateRandomPnr(length) {
		var result           = '';
		var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
		var charactersLength = characters.length;
		for ( var i = 0; i < length; i++ ) {
		   result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	 }

	 checkifExistGST(values){
		if(values.corporateGstNumber != "" && values.corporateGstNumber) {
			let tArr;
			this.travellerService.checkifExistgst(values).subscribe(res => {
				tArr = res
				if (tArr.length == 0) {
					this.travellerService.saveBookingGst(values).subscribe(res=>{
						console.log('saved',res)
					})
				}
			})
		}		
	}

	
}
