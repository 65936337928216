import { NgModule } from '@angular/core';
import { CommonModule as CM } from '@angular/common';
import { AppDownloadComponent } from './app-download/app-download.component';
import { FooterComponent } from './footer/footer.component';
import { BannersComponent } from './banners/banners.component';
import { OwlModule } from 'ngx-owl-carousel';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { SignupComponent } from './signup/signup.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { CommonComponent } from './common.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PaymentPolicyComponent } from './payment-policy/payment-policy.component';
import { GeneralPolicyComponent } from './general-policy/general-policy.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CommonService } from '../services/common.service';
import { NotificationService } from '../services/notification.service';
@NgModule({
	imports: [
		CM,
		OwlModule,
		MatDialogModule,
		MatInputModule,
		MatTabsModule,
		MatSelectModule,
		MatCheckboxModule,
		RouterModule,FormsModule,ReactiveFormsModule,MatIconModule,
		SlickCarouselModule,
	],
	declarations: [
		AppDownloadComponent,
		FooterComponent,
		BannersComponent,
		ForgetPasswordComponent,
		SignupComponent,
		SidemenuComponent,
		UpdatePasswordComponent,
		CommonComponent,
		PrivacyPolicyComponent,
		PaymentPolicyComponent,
		GeneralPolicyComponent,
		AboutUsComponent,
		ContactUsComponent,
	],
	exports: [
		AppDownloadComponent,
		FooterComponent,
		BannersComponent,
		ForgetPasswordComponent,
		SignupComponent,
		SidemenuComponent,
		SlickCarouselModule,
	],
	entryComponents: [ForgetPasswordComponent],
	providers : [CommonService,NotificationService]
})
export class CommonModule { }
