<!-- <div class="row ml-0 mr-0 mt-3">
    <div class="tg-wrap w-100">
        <table class="table table-sm table-bordered text-center">
            <tr>
                <th class="final-price-hilight">
                    Total price includes taxes &amp; fees
                </th>
            </tr>
            <tr>
                <td class="tg-0lax final-price-hilight custom-price-box text-center pl-0">&#8377; {{totalPayable}}
                </td>
            </tr>
        </table>
    </div>
</div> -->
<div class="card active p-0 mt-2 traveller-grand-total">
    <div class="row m-0 grand-total-card">       
        <div class=" text-center pl-0">
            <h4 class="text-bold text-uppercase">Total price</h4>
            <small class="d-block">includes taxes &amp; fees</small>
            <span class="   bg-white price-alert price-increased text-danger" *ngIf="isIncreasedPrice">Price Increased</span>
            <!-- <span class="   bg-white price-alert  non-refundable text-danger" >Non Refundable</span> -->
        </div>
        <div class=" price-col d-block" *ngIf="!isReissue">
            <h3 class="offered-price d-flex align-items-center"><i class="fas fa-tags mr-3"></i>&#8377; {{totalPayable}}</h3>
            <del  class="strike-price-col">
                <span class="strike-price text-black"  *ngIf="isIncreasedPrice" >&#8377; {{oldTtotalNetPayable}}</span>
                <span class="strike-line"></span>
            </del>
        </div>
        <div class=" price-col" *ngIf="isReissue">
            <h3 class="offered-price  d-flex align-items-center"><i class="fas fa-tags mr-3"></i>
            
                <span   *ngIf="reIssueData.hasOwnProperty('cancellationCharges') && reIssueData.hasOwnProperty('currentNetPayable') && reIssueData.hasOwnProperty('oldBookingAmount')">&#8377;  {{
                        (reIssueData.currentNetPayable) + 
                        (reIssueData.reIssuanceChargeAdmin?reIssueData.reIssuanceChargeAdmin:0) +
                        (reIssueData.reIssuanceChargeAdminGST?reIssueData.reIssuanceChargeAdminGST:0) +
                        (reIssueData.cancellationCharges)
                        -(reIssueData.oldBookingAmount) 
                        | number : '0.0-0'}}</span>
                  <div class="shimmer" *ngIf="!(reIssueData.hasOwnProperty('cancellationCharges') && reIssueData.hasOwnProperty('currentNetPayable') && reIssueData.hasOwnProperty('oldBookingAmount'))">
                    <div class="wrapper">
                      <div class="stroke animate title m-0"></div>
                      </div>
                    </div>
            </h3>
           
        </div>
    </div>
</div>
<!-- <div class="row ml-0 mr-0 mt-3">
    <div class="tg-wrap w-100">
        <div class="fprice-container increased-price shadow-fare-inc">
            <span class="fprice-head">Total price includes taxes &amp; fees</span>

            <span class="price-col" *ngIf="!isReissue">
                <span class="offered-price"><i class="fas fa-tags mr-3"></i>&#8377; {{totalPayable}}</span>
                <span class="strike-price-col">
                    <span class="strike-price" *ngIf="isIncreasedPrice" >&#8377; {{oldTtotalNetPayable}}</span>
                    <span class="strike-line"></span>
                </span>
            </span>
            <span class="price-col" *ngIf="isReissue">
                <span class="offered-price"><i class="fas fa-tags mr-3"></i>&#8377;  {{(reIssueData.currentNetPayable) + (reIssueData.cancellationCharges)-(reIssueData.oldBookingAmount) | number : '0.0-0'}}</span>
            </span>
            <span class="price-alert alert-inc focus-in-expand" *ngIf="isIncreasedPrice">Price Increased</span>
            <span class="price-alert alert-inc focus-in-expand" *ngIf="groupfareFoundStatus == 'found'">Non Refundable</span>
            
        </div>
    </div>
</div> -->




<!-- <div class="policy-head text-center">Policies</div>
<div class="row justify-content-center text-center mt-2 mb-2">
    <div class="col policy-link pl-0 pr-0"><a href="#">Baggage Info</a></div>
    <div class="col policy-link pl-0 pr-0"><a href="">Cancellation</a></div>
    <div class="col policy-link pl-0 pr-0"><a href="#">Date Change</a></div>
</div> -->