import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatTabChangeEvent, MatTabHeaderPosition } from '@angular/material';
@Component({
	selector: 'app-addon-seat',
	templateUrl: './addon-seat.component.html',
	styleUrls: ['./addon-seat.component.scss']
})
export class AddonSeatComponent implements OnInit {



	@Input() seatMapArr;
	@Input() responseData;
	@Input() showRequestLoader;
	@Input() headerPosition: MatTabHeaderPosition
	@Output() callSeatEvent: EventEmitter<any> = new EventEmitter();
	selected_seat: boolean = false;
	selectCount: number = 0;
	totalFare: Number = 0;
	passNumber: number;
	passenger: any = [];
	seat = [];
	selectedPass: number;
	@Input() passengerArr = []
	removedSeatPassengerNumber = 0;
	removedSeat = false;
	errorMessage: any = [];
	constructor() { }

	ngOnInit() {
		this.selectedPass = 0
	}
	selectSeat(key, item, leg,segment) {
		const tempArr = Object.assign({}, this.seatMapArr[segment][leg])
		if (key.type.toLocaleUpperCase() == 'OPEN' && item["totalSelected"]< item["travellerCount"]) {

			let obj = {
				pCount:this.getMostTopUnlocatedSeatTravellerIndex(item.travellers,leg)
			}
			if(this.passengerArr[segment] == undefined){
					this.passengerArr[segment] = []
			}
			if(this.passengerArr[segment][leg]== undefined){
					this.passengerArr[segment][leg] = []
			}
			this.passengerArr[segment][leg] = obj
			this.selectCount = this.passengerArr[segment][leg].pCount;

			if (this.selectCount>=0) {
				let assosiateTravellerInfoMap = item["associateTravellerInfoMap"];
				if(assosiateTravellerInfoMap.has(item.travellers[this.selectCount].firstName)) {
					let exitRow = key.displayAttribute ? key.displayAttribute['EXITROW'] ? key.displayAttribute['EXITROW'] : false : false;
					if(exitRow == "True") {
						exitRow = true;
						if(this.passengerArr[segment][leg].pCount == 0) {
							this.passengerArr[segment][leg] = [];
						} 
						else{
							this.passengerArr[segment][leg].pCount--;
						}
						alert('This seat can not be reserved for adult who is accompanying infant!');
						return false;
					}
					else{
						for(let i = 0; i<item.values.length;i++){
							if(item.values[i][0].number == key.number) {
								if(this.passengerArr[segment][leg].pCount == 0) {
									this.passengerArr[segment][leg] = [];
								} 
								else{
									this.passengerArr[segment][leg].pCount--;
								}
								alert('This seat can not be reserved for adult who is accompanying infant!');
								return false;
							}
						}
					}
				}


				this.totalFare = 0;
				
				this.passenger = tempArr.travellers[this.selectCount];
				const element = Object.assign({}, tempArr);
				const tempMyObj = Object.assign({}, element.travellers[this.selectCount]);
				tempMyObj["seat"] = key.number;
				tempMyObj["totalFare"] = key['totalFare'];
				if (element.travellers[this.selectCount].seat == undefined || element.travellers[this.selectCount].seat == "") {
					element.travellers[this.selectCount] = tempMyObj;
				} else {
					this.unselectSeat(tempArr.values, element.travellers[this.selectCount].seat)
					element.travellers[this.selectCount] = tempMyObj;
				}
				this.makeSeatObject(this.selectCount, element.travellers[this.selectCount].seat, false, tempMyObj["totalFare"], leg,segment, tempArr, key)
				tempArr['totalSelected'] += 1;
				key['status'] = 'selected';
				key['type'] = 'selected';
				
				this.seatMapArr[segment][leg] = tempArr;
		}else{
			for(let i=0;i<tempArr.travellers.length;i++){
				let selectedPaxSegLegSSR =this.getSlectedPax(tempArr.travellers[i],tempArr)
				if(selectedPaxSegLegSSR){
					let aasignedSeat = selectedPaxSegLegSSR.seats.name
					if(!aasignedSeat){
						aasignedSeat = selectedPaxSegLegSSR.seats.code
					}
					this.setErrorMessage(segment,leg,i,aasignedSeat)
				}
				
			}
		}
		}
		else if (key.type == 'Reserved') {
			alert('Seat is Already reserved')
		}
		else if (key.type == 'FleetBlocked') {
			alert('Fleet Blocked')
		}
		else if(item["totalSelected"]< item["travellerCount"]){
			alert('Selected Seat Cannot be booked online')
		}
		console.log('Seat Is', key.type)
	}
	getSlectedPax(travellerSSRs: any, tempArr: any) {
		let selectedSSR;
		for (let i = 0; i < travellerSSRs.ssr.length; i++) {
			const ssr = travellerSSRs.ssr[i];
			if(ssr.origin == tempArr.departure && ssr.destination == tempArr.arrival){
				selectedSSR = ssr
			}
		}
		return selectedSSR
	}

	getMostTopUnlocatedSeatTravellerIndex(travellers,legNo){

		for(let ind=0;ind<travellers.length;ind++){

			let traveller = travellers[ind]
			if(travellers[ind].type!="INF" && (!traveller.hasOwnProperty("seat") || !traveller.seat)){
				if(traveller.hasOwnProperty("ssr") && traveller['ssr'][legNo] && traveller['ssr'][legNo].seats && traveller['ssr'][legNo].seats.name && traveller['ssr'][legNo]['seats'].name == "NA") return ind
				if(!traveller.hasOwnProperty("ssr") || traveller['ssr']==null || traveller.ssr.length==0) return ind
				if(traveller['ssr'][legNo]['seats']==null) return ind
			}
		}
		return -1
	}


	getTravellerIndexBySeatNumber(key,item){
		let index = -1
		for(let i=0;i<item.travellers.length;i++){
			if(key.number && item.travellers[i].seat &&  key.number==item.travellers[i].seat){
				index = i
				break
			}
		}
		return index
	}



	setErrorMessage(segment,leg,pax,seatNumber){
		if(this.errorMessage[segment] && this.errorMessage[segment][leg] && this.errorMessage[segment][leg][pax]){
			this.errorMessage[segment][leg][pax] = seatNumber
		}else{
			if(this.errorMessage[segment] == undefined){
				this.errorMessage[segment] = []
				this.setErrorMessage(segment,leg,pax,seatNumber)
				return
			}
			if(this.errorMessage[segment] && this.errorMessage[segment][leg] == undefined){
				this.errorMessage[segment][leg] = []
				this.setErrorMessage(segment,leg,pax,seatNumber)
				return
			}
			if(this.errorMessage[segment] && this.errorMessage[segment][leg] && this.errorMessage[segment][leg][pax] == undefined){
				this.errorMessage[segment][leg][pax] = []
				this.setErrorMessage(segment,leg,pax,seatNumber)
				return
			}
		}

		console.log(this.errorMessage)
	}


	selectPassenger(l, isReturn){
		if(!isReturn) {
			this.removedSeat = l;
		}
	
	}

	removeSeat(item, selectedSeat, legNo,segment, passengerNo) {
		let fullSeats = item.values
		let traveller = item.travellers;
		this.unselectSeat(fullSeats, selectedSeat);
		const element = traveller[passengerNo];
		if (selectedSeat == element.seat) {
			element.seat = undefined;
			this.seatMapArr[segment][legNo]["totalSelected"]-=1
		}
		this.makeSeatObject(passengerNo, selectedSeat, true, 0, legNo,segment, item, '')
	}
	unselectSeat(fullSeats, selectedSeat) {
		 
		for (let j = 0; j < fullSeats.length; j++) {
			const element = fullSeats[j];
			let charges = Object.values(element)
			for (let k = 0; k < charges.length; k++) {
				const ch = charges[k]
				if (ch['number'] == selectedSeat) {
					ch['status'] = 'notselected'
					ch['type'] = 'Open'

				}
			}
		}

	}

	makeSeatObject(passengerIndex, seatNumber, isRemove, price, leg,segment, tempArr, key) {
		let flag = true;
		if (tempArr.flightInfo.ssrs[segment] && tempArr.flightInfo.ssrs[segment][leg] && tempArr.flightInfo.ssrs[segment][leg].seats != undefined) {
			tempArr.flightInfo.ssrs[segment][leg].seats = tempArr.flightInfo.ssrs[segment][leg].seats.filter(obj => {
				if (isRemove) {
					flag = false;
					if (obj.travellerNumber != passengerIndex) {
						return obj;
					}
				}
				else {
					if (obj.travellerNumber == passengerIndex) {
						obj['number'] = seatNumber;
						obj['price'] = price;
						obj['item'] = key;
						flag = false;
					}
					return obj;
				}

			});
		}

		if (flag) {
			let obj = {
				"number": seatNumber,
				"travellerNumber": passengerIndex,
				"item": key,
				"price": price,
				"title": this.seatMapArr[segment][leg].travellers[passengerIndex].title,
				"firstName": this.seatMapArr[segment][leg].travellers[passengerIndex].first_name,
				"lastName": this.seatMapArr[segment][leg].travellers[passengerIndex].last_name,
			}
			if(tempArr.flightInfo.ssrs[segment] == undefined){
				tempArr.flightInfo.ssrs[segment] = []
			}
			if(tempArr.flightInfo.ssrs[segment][leg] == undefined){
				tempArr.flightInfo.ssrs[segment][leg] = {}
			}
			if (tempArr.flightInfo.ssrs[segment][leg].seats == undefined || tempArr.flightInfo.ssrs[segment][leg].seats == null) {
				tempArr.flightInfo.ssrs[segment][leg].seats = [];
			}
			tempArr.flightInfo.ssrs[segment][leg].seats.push(obj)
		}
		 
		if(tempArr.flightInfo.ssrs) {
			let ssrs = tempArr.flightInfo.ssrs
			for(let ssrArr of ssrs) {
				for(let ssr of ssrArr) {
					let seats = ssr.seats 
					if(seats && seats.length> 0) {
						for(let seat of seats) {
							if(seat.hasOwnProperty('name') && seat.name) {
								if( seat.name == "NA") {
									seats.shift();
								}
							}
					   }
				    }
			    }
			}
		}

		this.callSeatEvent.emit(tempArr.flightInfo.ssrs);
	}
}