import { ActionTypes } from './actions';

export const initialState = {
  selectedSsr : {},
  showAddonSummary : false
};

export function SSrReducers(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ToggleAddonSummary:
      return {
        ...state,
        showAddonSummary : action.payload
      }
    case ActionTypes.SetSsr:
      return {
        ...state,
        selectedSsr : setSelectedSSR(action.payload,state.selectedSsr)
      }
    case ActionTypes.InitializeSelectedSSR:
      return {
        ...state,
        selectedSsr : initializeSelectedSSR(action.payload)
      }
    case ActionTypes.ResetSelectedSsr:
      return {
        ...state,
        selectedSsr : {}
      }
    default:
      return state;
  }
}

function initializeSelectedSSR(payload: any) {
  let travellers = payload.travellers
  let segments = payload.segments
  let selectedSsrObj = {
    "totalAmount" : 0, 
  }
  let travellersArr = []
  for (const traveller of travellers) {
    let travellerObj = {   
      "type" : traveller.type,
      "title" : traveller.title,
      "firstName" : traveller.firstName?traveller.firstName:traveller.first_name,
      "lastName" : traveller.lastName?traveller.lastName:traveller.last_name,
      "subTotalAmount" : 0,      
    }
    let segmentArr =  []
    for (const segment of segments) {
      let legsArr = []
      for (const leg of segment.legs) {
        let legsObj = {
          "origin" : leg.originAirport?leg.originAirport:{"code":leg.origin_airport_code},
          "destination" : leg.destinationAirport?leg.destinationAirport:{"code":leg.destination_airport_code},
          "legSubtotal" : 0,
          "seat" : null,
          "baggage" : null,
          "meal" : null,
          'wheelchair':null
        }
        legsArr.push(legsObj)
      }
      let segmentObj =  {
        "legs" : legsArr
      }
      segmentArr.push(segmentObj)
    }
    travellerObj['segments'] = segmentArr
    travellersArr.push(travellerObj)
  }
  selectedSsrObj['travellers'] = travellersArr
  console.log("ssr object: ",selectedSsrObj)
  return selectedSsrObj
}

function setSelectedSSR(selectedSSRArr,previousSelectedSSr) {
  let previousSelectedLeg = new Set()
  let legSSrMap = new Map()
  for (const leg of selectedSSRArr) {
    if(leg.supplierInfo){
      let key = leg.supplierInfo.departureStation+"_"+leg.supplierInfo.arrivalStation
      legSSrMap.set(key.trim(),leg)
    }
  }

  let totalAmount = 0
  for (const traveller of previousSelectedSSr.travellers) {
      let travellerTotal = 0
      for (const segment of traveller.segments) {
          let segmentTotal = 0
          for (const leg of segment.legs) {
              let legTotal = 0
              let key = leg.origin.code+"_"+leg.destination.code
              let value = legSSrMap.get(key)
              if(value){  
                let seat = getSelectedSSR(value,'seats',previousSelectedSSr.travellers.indexOf(traveller))
                let meal = getSelectedSSR(value,'meals',previousSelectedSSr.travellers.indexOf(traveller))
                let baggage = getSelectedSSR(value,'baggage',previousSelectedSSr.travellers.indexOf(traveller))
                let additionalBaggage = getSelectedSSR(value,'additionalBaggage',previousSelectedSSr.travellers.indexOf(traveller))
                let wheelchair = getSelectedSSR(value,'wheelChairs',previousSelectedSSr.travellers.indexOf(traveller))
                let ancillary = getSelectedSSR(value,'ancillary',previousSelectedSSr.travellers.indexOf(traveller))

                leg.seat = seat ? seat : null
                leg.meal = meal ? meal : null
                leg.baggage = baggage ? baggage : null
                leg.additionalBaggage = additionalBaggage ? additionalBaggage : null
                leg.wheelchair = wheelchair ? wheelchair : null
                leg.ancillary = ancillary ? ancillary : null

                legTotal += leg.seat ? leg.seat.price : 0
                legTotal += leg.meal ? leg.meal.price : 0
                legTotal += leg.baggage ? leg.baggage.price : 0
                legTotal += leg.additionalBaggage ? leg.additionalBaggage.price : 0
                legTotal += leg.wheelchair ? leg.wheelchair.price : 0
                if(leg.ancillary && leg.ancillary.selectedItems){
                  leg.ancillary.selectedItems.forEach(element => {
                    legTotal += element.totalFare ? element.totalFare : 0
                  });
                }

                leg.legSubtotal = legTotal
                segmentTotal += legTotal
              }else{
                segmentTotal+=leg.legSubtotal
              }
          }
          travellerTotal += segmentTotal
      }
      traveller.subTotalAmount = travellerTotal
      totalAmount += travellerTotal
  }
  previousSelectedSSr.totalAmount = totalAmount
  console.log(previousSelectedSSr)
  return previousSelectedSSr
}
function getSelectedSSR(selectedSSR,type,travellerIndex) {
  if(selectedSSR && selectedSSR[type] && selectedSSR[type].length > 0){
      for (const ssr of selectedSSR[type]) {
          if(ssr.travellerNumber == travellerIndex){
              return ssr
          }
      }
  }
  return null
}


