<div class="clearfix"></div>
<div class="container terms-box my-5">

  <div class="section-head">Privacy Policy</div>
  <span class="para">Aadesh Travels Pvt. Ltd. (hereinafter “ATPL”) reserves the right, in its sole discretion, to  terminate the access to any or all ATPL websites or its other sales channels and the related services or any portion thereof at any time, without notice, for general maintenance or any reason what so ever...</span>
  <span class="para">Your privacy is important to us and we are committed toward protecting your
    information to the best extent possible. We maintain the privacy of your personal information, including
    your name, address, email address, username, passwords, contact details, shopping or transaction history.</span>
  <span class="para">Any information gathered by us is used to customize the experience for the user and is not shared or
    sold to any third parties, except as expressly provided for in this Privacy Policy and our General
    Terms &amp; Conditions. However, we will use your e-mail address to send correspondence about
    ATPL, in which case we may allow third party application to access your email address for the
    purpose of sending correspondence about ATPL, including:</span>
  <span class="sub-para">Reservation confirmations</span>
  <span class="sub-para">Important updates about your travel plans</span>
  <span class="sub-para">Newsletters</span>
  <span class="sub-para">Notifications of travel specials</span>

  
  <span class="para">All credit/debit cards details and personally identifiable information will NOT be
    stored, sold, shared, rented or leased to any third parties.</span>

    <span class="para">
      The Website's policies and Terms &amp; Conditions may be changed or updated occasionally
            to meet the industry and regulatory requirements and standards. Therefore, the travel partners are
            encouraged to frequently visit these sections in order to be updated about the changes on the website.
            Modifications will be effective on and from the day they are posted.
    </span>

  <div class="action-btn text-center clearfix mt-4">
    <a class="" href="javascript:void(0)" routerLink="/">Back</a>
  </div>

</div>