import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-general-policy',
  templateUrl: './general-policy.component.html',
  styleUrls: ['./general-policy.component.scss']
})
export class GeneralPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0,0)
  }

}
