<section #printBox class="contents viewledger mat-white-input-tab mb-3 pb-5">

    <div class="container-fluid mt-3 ledger-container">
      <div class="row" >
        <div class="col-12 text-center" >
          <div >
            <img src="../../../assets/images/logo2.png" alt="">
          </div>
        </div>
        <div class="col-12 text-center">
          <h3>Weekly Statement</h3>
        </div>
      </div>
      <div class="ledger-table-container container-fluid">
        <div class="col-12 text-center clearfix  mb-2 pr-0">
          <label class="col control-lable font-weight-bold text-center">For the Period of <span
              class="lblHead">{{fromDate | date}}</span> To <span class="lblHead">{{toDate | date}}</span></label>
        </div>
      
        <div class="mobile-scroll-table">
          <table class="table table-sm table-hover table-striped table-bordered myCustomTable">
            <tbody class="custom-table-body">
                <tr>
                    <td> <strong>Date</strong></td>
                    <td> <strong>Reference No.</strong></td>
                    <td> <strong>Particulars</strong></td>
                    <td> <strong>Debit</strong></td>
                    <td> <strong>Credit</strong></td>
                    <td> <strong>Balance</strong></td>
                  </tr>
              <tr class="custom-table-row" *ngFor="let element of dataSource">
                <td>{{element.timestamph | dateFormat}}</td>
                <td><span>{{element.transaction_ref_no}}</span></td>
                <td>
                  <span class="no-wrap">{{element.narration_1}}</span> <br>
                  <span class="no-wrap">{{element.narration_2}}</span><br>
                  <span class="no-wrap">{{element.narration_3}}</span><br>
                  <span class="no-wrap">{{element.narration_4}}</span>
                </td>
                <td>
                  <span class="bold" *ngIf="element.typhe == 'debit'">
                    {{element.amount | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                  <span class="bold"
                    *ngIf="element.typhe == 'credit'">{{0 | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                </td>
                <td>
                  <span class="bold"
                    *ngIf="element.typhe == 'debit'">{{0 | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                  <span class="bold"
                    *ngIf="element.typhe == 'credit'">{{element.amount | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                </td>
                <td>&#8377; {{element.balance | number : '1.0-0'}}</td>
              </tr>
              <tr class="custom-table-row2">
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><strong>Total : </strong></td>
                  <td>
                    <span class="bold">
                      {{dataSource | statementTotal:'debit' | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                  </td>
                  <td>
                    <span
                      class="bold">{{dataSource | statementTotal:'credit' | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                  </td>
                  <td>&#8377;
                    {{(dataSource | statementTotal : 'debit') - (dataSource | statementTotal : 'credit') | number : '1.0-0'}}
                  </td>
                </tr>
            </tbody>
            
          </table>
        </div>
      </div>
    </div>
  </section>