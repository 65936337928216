import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { LedgerService } from 'src/app/services/ledger/ledger.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/public_api';
import { BookingService } from 'src/app/services/booking/booking.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { FinancialsComponent } from '../financials/financials.component';
import { CommonService } from 'src/app/services/common.service';
import * as FileSaver from 'file-saver'; 

@Component({
	selector: 'app-ledger',
	templateUrl: './ledger.component.html',
	styleUrls: ['./ledger.component.scss']
})
export class LedgerComponent implements OnInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	isMinimized : boolean = false;
	dataSource: any = [];
	displayedColumns: any[] = ['date', 'refNumber', 'particulars', 'debit', 'credit', 'runningBal']
	filterForm: FormGroup;
	openingBal: number = 0;
	dateFilter;
	fromDate;
	toDate;
	selectedledger: any;
	selectedledgers = '';
	navigationSubscription;
	ascSortRef: boolean = true;
	ascBookDateSort: boolean = true;
	ascJourneyDateSort: boolean = true;
	searchField;
	p;
	bsConfig: Partial<BsDatepickerConfig>;
	colorTheme = 'theme-purple'
	innerHeight: string;
	//Filter ids
	@ViewChild('fDate') fDate: ElementRef;
	@ViewChild('tDate') tDate: ElementRef;
	balanceArr: any = [];
	isDiAccount: boolean;
	snackBarMessage = ''
	totalAmountObj;
	constructor(private ledegrService: LedgerService, 
		private router: Router,
		private bookingService: BookingService,
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private commonService : CommonService
		) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
	 }

	ngOnInit() {
		if(localStorage.user_details != undefined) {
			if(JSON.parse(localStorage.user_details)['is_di_account'] != null) {
				this.isDiAccount = (JSON.parse(localStorage.user_details)['is_di_account'] == 'true')
			}
			else{
				this.isDiAccount = false;
			}
		}

		let queryParams = this.route.queryParams['value']
		let searchObj ={}
		let fromDate = new Date()
		let day = fromDate.getDate() - 6;
		fromDate.setDate(day);
		searchObj['fromDate'] = fromDate
		searchObj['toDate'] = new Date()

		if(queryParams && queryParams.from_date && queryParams.from_date.length>0 && queryParams.to_date && queryParams.to_date.length>0){
			searchObj['fromDate'] = new Date(queryParams.from_date)
			searchObj['toDate'] = new Date(queryParams.to_date)
		}
		this.filterForm = this.fb.group({
			'type': ['both'],
			'fromDate': [searchObj['fromDate']],
			'toDate': [searchObj['toDate']],
		})
		this.submit(searchObj)
		// let day = searchObj['fromDate'] .getDate() - 18;
		// todayDate.setDate(day);
		// this.viewLedger()
		this.bsConfig = Object.assign({ dateInputFormat: 'DD-MM-YYYY' }, { containerClass: this.colorTheme },{ showWeekNumbers:false })
		// this.selectedledger = ''
	}
	private focusFromDate(): void {
		this.fDate.nativeElement.focus();
	}
	private focusToDate(): void {
		this.tDate.nativeElement.focus();
	}
	focusOn(control){
		if (control == 'fromDate') {
			this.focusFromDate()
		}
		if (control == 'toDate') {
			this.focusToDate()
		}
	}
	initialiseInvites() {
		this.ngOnInit()
		// let searchObj ={}
		// let fromDate = new Date()
		// let day = fromDate.getDate() - 6;
		// fromDate.setDate(day);
		// searchObj['fromDate'] = fromDate
		// searchObj['toDate'] = new Date()
		// this.submit(searchObj)
	}

	ngOnDestroy() {
		// avoid memory leaks here by cleaning up after ourselves. If we  
		// don't then we will continue to run our initialiseInvites()   
		// method on every navigationEnd event.
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}
	invoiceSort() {
		this.ascSortRef = !this.ascSortRef;
		var testData = this.dataSource;
		this.dataSource = testData.sort((a, b) => {
			if (this.ascSortRef) {
				if (b.transaction_ref_no < a.transaction_ref_no) {
					return -1;
				}
				if (b.transaction_ref_no > a.transaction_ref_no) {
					return 1;
				}
				return 0;
			} else {
				if (a.transaction_ref_no < b.transaction_ref_no) {
					return -1;
				}
				if (a.transaction_ref_no > b.transaction_ref_no) {
					return 1;
				}
				return 0;
			}
		});
	}
	dateSort() {
		this.ascBookDateSort = !this.ascBookDateSort;
		var testData = this.dataSource;
		this.dataSource = testData.sort((a, b) => {
			if (this.ascBookDateSort) {
				if (b.timestamph < a.timestamph) { return -1; }
				if (b.timestamph > a.timestamph) { return 1; }
				return 0;
			}
			else {
				if (a.timestamph < b.timestamph) { return -1; }
				if (a.timestamph > b.timestamph) { return 1; }
				return 0;
			}
		});

	}
	// searchField(filterValue: string) {
	// 	this.dataSource.filteredData = filterValue.trim().toLowerCase();
	//   }

	submit(value) {
		value.fromDate = this.bookingService.gmtDateParserFrom(value.fromDate)
		value.toDate = this.bookingService.gmtDateParserTo(value.toDate)
		let user = JSON.parse(localStorage.user_details)
		value['affiliate_id'] = user.affiliateId;
		value['affiliate_code'] = user.xlAccountCode;
		
		this.fromDate = new Date(value.fromDate).toDateString()
		this.toDate = new Date(value.toDate).toDateString()
		this.ledegrService.viewFilteredLedger(value).subscribe((res : any) => {
			let resp = res;
			if(res['data'].length > 0) {
				this.dataSource = resp.data;
				this.balanceArr= resp.balance;
			}
			this.openingBal = resp.balance[0]['opening_balance']
			this.totalAmountObj = this.getAmountObject(resp['data'])

			// if (this.dataSource.length > 0) {
			// 	this.openingBal = JSON.parse(res["_body"])[0].balance;
			// }
			// else{
			// 	this.openingBal = 0
			// }
			if (this.dataSource.length <= 3) {
				this.innerHeight = 'auto';
			}
			else {
				this.innerHeight = window.innerHeight - 400 + 'px';
			}
		})
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if (this.dataSource.length <= 3) {
			this.innerHeight = 'auto';
		}
		else {
			this.innerHeight = event.target.innerHeight - 400 + 'px';
		}
	}
	search(value) {
		if (value.fromDate == null) {
			value.fromDate = new Date()
		}
		if (value.toDate == null) {
			value.toDate = new Date()
		}
		this.filterForm.controls.fromDate.setValue(value.fromDate)
		this.filterForm.controls.toDate.setValue(value.toDate)
		value.fromDate =  this.bookingService.gmtDateParserFrom(value.fromDate)
		value.toDate =  this.bookingService.gmtDateParserTo(value.toDate)
		let user = JSON.parse(localStorage.user_details)
		value['affiliate_id'] = user.affiliateId
		value['affiliate_code'] = user.xlAccountCode;
		this.ledegrService.viewFilteredLedger(value).subscribe((res : any) => {
			let resp = res
			if(res['data'].length > 0) {
				this.dataSource = resp.data;
				this.balanceArr= resp.balance;
				this.openingBal = resp.balance[0]['opening_balance']
			}
			else{
				this.openingBal = resp.balance[0]['opening_balance']
			}
			this.totalAmountObj = this.getAmountObject(resp['data'])

			this.fromDate = new Date(value.fromDate).toDateString()
			this.toDate = new Date(value.toDate).toDateString()
		})
	}

	getAmountObject(invoicesData) {
		let amountObj = {
			debit: 0,
			credit: 0,
			diff: 0
		}
		let totalCredit = 0
		let totalDebit = 0
		let balance = 0
		let openingBalance = 0
		let openingBalanceType = 'debit'
		
		
		if(this.balanceArr && this.balanceArr.length>0) {
			let balanceItem = this.balanceArr[0];
			openingBalance = balanceItem.opening_balance ? balanceItem.opening_balance : 0;
			openingBalanceType = balanceItem.opening_balance_type ? balanceItem.opening_balance_type : 0;
		}
		
		if(openingBalanceType == 'credit' && openingBalance<0) {
			openingBalance = openingBalance * -1;
		}

		if (invoicesData != undefined) {
			invoicesData.forEach(record => {
				if(record.typhe) {
					if(record.typhe == 'debit') {
						totalDebit += record.amount ? Number(record.amount) : 0;
					} else if(record.typhe == 'credit') {
						totalCredit += record.amount ? Number(record.amount) : 0;
					}
				}
			});
			balance = invoicesData[invoicesData.length-1].balance ? Number(invoicesData[invoicesData.length-1].balance) : 0;
			amountObj['credit'] = totalCredit
			amountObj['debit'] = totalDebit
			amountObj['balance'] = balance
			amountObj['diff'] = Number(totalCredit) -(Number(totalDebit) - Number(openingBalance))
			amountObj['openingBalance'] = Number(openingBalance)
			amountObj['openingBalanceType'] = openingBalanceType;
			amountObj['type'] = 'CR.'
			if (amountObj['diff'] < 0) {
				amountObj['type'] = 'DR.'
			}
			amountObj['diff'] = Math.abs(amountObj['diff'])
		}
		
		return amountObj
	}

	openInvoice(data){
		let isBeforeFinancialYear2020_21 =moment(data.timestamph,'YYYY-MM-DD HH:mm:ss').isBefore('2020-04-01 00:00:00')
		if (isBeforeFinancialYear2020_21 == true) {
			this.openOldData(data)
		}else{
			this.openNewData(data)
		}
	}
	openNewData(data){
		
		let transactionID = data.transaction_ref_no;
		
		let link = ""
		
		let systacc_access_token = localStorage.getItem('systacc_access_token')
		if(data.reference_type == 'flight_invoice'){
			link = `transactions/invoice-view;searchId=${transactionID};type=flight?access_token=${systacc_access_token}&hide_party=true`;
		}
		else if(data.reference_type == 'credit_note'){
			link = `/transactions/print-credit-note/${transactionID};type=flight?access_token=${systacc_access_token}&hide_party=true`
		}
		else if(data.reference_type == 'payment'){
			link = `transactions/payment/print/${transactionID}?access_token=${systacc_access_token}`
		}
		else if(data.reference_type == 'receipt'){
			link = `transactions/receipt/print/${transactionID}?access_token=${systacc_access_token}`
		}else {
			link = `transactions/ms-invoice-view;searchId=${transactionID};type=flight?access_token=${systacc_access_token}`;
		}
		window.open(`${environment.okountWebAppUrl}/v3/#/${link}`, '_blank')
	}
	openOldData(value){
		let invoice_type = value.transaction_ref_no.split('-')[0]
		let invoice_number = value.transaction_ref_no.split('-')[1]
		let link = '?transactionID='+value.transaction_ref_no;
		let amount = '&amount='+Math.round(value.amount);
		let affiliate = '&affiliate='+value.affiliate_id;
		if (value.transaction_ref_no.includes('IW')) {
			//searchId=4060381?transactionID=IW19-4060381&affiliate=135&amount=4594
			link = '#/template/print-invoice;searchId='+invoice_number+link+affiliate+amount
			// window.open("#/template/print-invoice;searchId="+invoice_number+link+affiliate+amount, "_blank");
		}
		else if (value.transaction_ref_no.includes('IZ')) {
			link = "#/template/print-credit-receipt;searchId="+invoice_number+link+affiliate+amount
			// window.open("#/template/print-credit-receipt;searchId="+invoice_number+link+affiliate+amount, "_blank");
		}
		else if(value.transaction_ref_no.includes('JW')){
			link = '#/template/offline-receipt' + link + '&type=JW&subType='+value.typhe+affiliate+amount;
		}
		else if(value.transaction_ref_no.includes('RW')){
			link = '#/template/offline-receipt' + link +'&type=RW&subType='+value.typhe+affiliate+amount;
		}
		else if(value.transaction_ref_no.includes('IS')){
			link = '#/template/offline-receipt' + link +'&type=IS&subType='+value.typhe+affiliate+amount;
		}
		else if(value.transaction_ref_no.includes('PY')){
			link = '#/template/offline-receipt' + link +'&type=PY&subType='+value.typhe+affiliate+amount;
		}
		else if(value.transaction_ref_no.includes('AD')){
			link = '#/template/offline-receipt' + link +'&type=AD&subType='+value.typhe+affiliate+amount;
		}
		else if(value.transaction_ref_no.includes('IM')){
			link = '#/template/offline-receipt' + link +'&type=IM&subType='+value.typhe+affiliate+amount;
		}
		else if(value.transaction_ref_no.includes('MI')){
			link = '#/template/offline-receipt' + link +'&type=MI&subType='+value.typhe+affiliate+amount;
		}
		else if (value.transaction_ref_no.includes('GI')) {
			link = '#/template/offline-receipt' + link + '&type=GI&subType='+value.typhe+affiliate+amount;
		}
		else if (value.transaction_ref_no.includes('MS')) {
			link = '#/template/offline-receipt' + link + '&type=MS&subType='+value.typhe+affiliate+amount;
		}
		else if (value.transaction_ref_no.includes('IR')) {
			link = '#/template/offline-receipt' + link + '&type=IR&subType='+value.typhe+affiliate+amount;
		}
		else if(value.transaction_ref_no.includes('MW')){

			if(value.narration_3.includes("CHECK IN")) {

				link = 'hotel/#/home/invoice;bookingRef='+value.transaction_ref_no.split('-')[1];
			}
			else{

				link = "insurance#/insurance/invoice;searchId="+value.transaction_ref_no+link+affiliate+amount
			}
		}
		window.open(link, '_blank')
		
		
	}
	reset() {
		this.filterForm.reset();
		let searchObj ={}
		let fromDate = new Date()
		let day = fromDate.getDate() - 6;
		fromDate.setDate(day);
		searchObj['fromDate'] = fromDate
		searchObj['toDate'] = new Date()
		this.submit(searchObj)
	}
	// getLedgerBy(selectedLedger) {
	//   this.selectedledger = selectedLedger;
	// }
	// viewLedger() {
	// 	let user = JSON.parse(localStorage.user_details)
	// 	this.ledegrService.viewLedger(user.affiliateId).subscribe(res => {
	// 		this.dataSource = JSON.parse(res["_body"]);
	// 		console.log(this.dataSource)
	// 		if (this.dataSource.length > 0) {
	// 			this.openingBal = JSON.parse(res["_body"])[0].balance;
	// 		}
	// 		else{
	// 			this.openingBal = 0
	// 		}
			
	// 	})
	// }
	dateFormat(value) {		
		if(value != null) {
			let splitValue = value.split(' :')[0];
			let splitDate = value.split(' :')[1];
			if(splitDate != null && splitDate != undefined) {
				let date = splitDate.trim();
				// moment(value).format('ddd MMM D YYYY')
				// let a = new Date(date);
				return splitValue + " : " +moment(date).format('ddd MMM D YYYY');
			}
			return "";
		}
		return "";
	}

	getLedgerBy(filterValue: string) {
		this.selectedledger = filterValue;
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	}

	callNormalLedgerPdfGeneratorApi(){
		window.scrollTo(0,0)
		this.snackBarMessage = "download started..."
    	this.showSnackBar()
		let user = JSON.parse(localStorage.user_details)
		let queryParams = this.route.queryParams['value']
		let clientCode = user.xlAccountCode;

		this.commonService.downloadOkountPDF(`reports/ledger/normal/download?viewMode=normal&sub_ledger_code=&start_date=${queryParams.from_date}&end_date=${queryParams.to_date}&city_id=&client=${clientCode}&group_by_sub_ledger=&sales_person=&bf_balance=true&account_type=client&subledger_type=&grouping=false&group=&credit_note_date=${queryParams.to_date}&pay_rec_date=${queryParams.to_date}`).subscribe((res: any) => {
			let fileBlob = res.blob();
			let filename =  "ledger.pdf";
			let blob = new Blob([fileBlob], { 
			   type: 'application/pdf'
			});
			FileSaver.saveAs(blob, filename);
		},
		err => {
			this.snackBarMessage = "download failed..."
    		this.showSnackBar()
			console.log(err)
		});
	}
	callDetailedLedgerPdfGeneratorApi(){
		window.scrollTo(0,0)
		this.snackBarMessage = "download started..."
    	this.showSnackBar()
		let user = JSON.parse(localStorage.user_details)
		let queryParams = this.route.queryParams['value']
		let clientCode = user.xlAccountCode;

		this.commonService.downloadOkountPDF(`reports/ledger/detailed/grouping/download?viewMode=detailed&sub_ledger_code=&start_date=${queryParams.from_date}&end_date=${queryParams.to_date}&city_id=&client=${clientCode}&group_by_sub_ledger=&sales_person=&bf_balance=true&account_type=client&subledger_type=&grouping=true&group=&credit_note_date=${queryParams.to_date}&pay_rec_date=${queryParams.to_date}`).subscribe((res: any) => {
		let fileBlob = res.blob();
		let blob = new Blob([fileBlob], { 
			type: 'application/pdf'
		});
		let filename =  "ledger.pdf";
		FileSaver.saveAs(blob, filename);
		},
		err => {
			this.snackBarMessage = "download failed..."
    		this.showSnackBar()
			console.log(err)
		});
	}
	showSnackBar() {
		var x = document.getElementById("snackbar");
		x.className = "show";
		setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
	}
}

export interface Element {
	date: string;
	refNumber: number;
	particulars: number;
	debit: string;
	limit_type: string;
}
