<div class="clearfix"></div>

<div class="container mt-4 mb-2 contactdetails-container">

  <div class="contact-details">
    <h5 class="section-header text-bold">
      Aadesh Travels Pvt Ltd
    </h5>

    <div class="row">
      <div class="col-sm-6 section-details">
        <span class="aff-address1 d-block"><span class="label-txt d-block">Address: </span> 3rd Floor, Destination
          Plus</span>
        <span class="aff-address2 d-block"><span class="label-txt">&nbsp;</span>Amin Marg, Rajkot - 360005 </span>
        <!-- <span class="aff-email"><span class="label-txt">Email: </span>
                 <span *ngIf="data?.affiliate_email != ('' || null)"><a title="Email us" href="mailto:{{data?.affiliate_email}}">{{data?.affiliate_email}}</a></span>
                 <span *ngIf="data?.affiliate_email == ('' || null)">Not available</span>
             </span> -->
        <span class="aff-phone"><span class="label-txt">Contact no.:</span> 0281 2411111</span>
        <!-- <span *ngIf="data?.affiliate_phone != ('' || null)">{{data?.affiliate_phone}}</span>
              <span *ngIf="data?.affiliate_phone == ('' || null)">Not available</span> -->
      </div>
      <div class="col-sm-6 aoh-col">
        <span class="timings">After Office Hours Contact details (After 09:00 PM)</span>
        <span class="aff-phone"><span class="label-txt">For International // GDS Airlines: </span> 99099 62461</span>
        <span class="aff-phone d-block"><span class="label-txt">For Portal // LCC:</span> 99099 62680</span>
      </div>
    </div>







  </div>
</div>

<div class="container mt-4 mb-5 contactdetails-container">
  <h5 class="support-title"><span class="text-bold text-pinkish-red mr-2">GDS:</span> <a
      href="mailto:gds@aadeshtravels.com" target="_blank">gds@aadeshtravels.com</a> </h5>
  <div class="mobile-scroll-table  custom-table">
    <table class="table mb-0">
      <thead>
        <tr>
          <th class="text-bold">
            NAME
          </th>
          <th class="text-bold">
            DESIGNATION
          </th>
          <th class="text-bold">
            CONTACT NUMBER
          </th>
          <th class="text-bold">
            EMAIL
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            Mital Sanchaniya
          </td>
          <td>
            Ticketing Agent
          </td>
          <td>
            +91 281 2411111
          </td>
          <td>
            <a href="mailto:mital@aadeshtravels.com">mital@aadeshtravels.com</a>
          </td>
        </tr>
        <tr>
          <td>
            Disha Chandarana
          </td>
          <td>
            Ticketing Agent
          </td>
          <td>
            +91 281 2411111 
          </td>
          <td>
            <a href="mailto:gds@aadeshtravels.com">gds@aadeshtravels.com</a>
          </td>
        </tr>
        <tr>
          <td>
            Ajay Sanchaniya
          </td>
          <td>
            Ticketing Agent&nbsp;
          </td>
          <td>
            +91 281 2411111 
          </td>
          <td>
            <a href="mailto:gds@aadeshtravels.com">gds@aadeshtravels.com</a>
          </td>
        </tr>

        <tr>
          <td>
            Kripalsinh Zala
          </td>
          <td>
            Ticketing Agent&nbsp;
          </td>
          <td>
            +91 281 2411111 
          </td>
          <td>
            <a href="mailto:gds@aadeshtravels.com">gds@aadeshtravels.com</a>
          </td>
        </tr>
        <tr>
          <td>
            Makwana drashti 
          </td>
          <td>
            Ticketing Agent&nbsp;
          </td>
          <td>
            +91 281 2411111 
          </td>
          <td>
            <a href="mailto:gds@aadeshtravels.com">gds@aadeshtravels.com</a>
          </td>
        </tr>
        <tr>
          <td>
            Shivangi Maher
          </td>
          <td>
            Ticketing Agent&nbsp;
          </td>
          <td>
            +91 281 2411111 
          </td>
          <td>
            <a href="mailto:gds@aadeshtravels.com">gds@aadeshtravels.com</a>
          </td>
        </tr>
        <tr>
          <td>
            Jayraj vaghela 
          </td>
          <td>
            Ticketing Agent&nbsp;
          </td>
          <td>
            +91 281 2411111 
          </td>
          <td>
            <a href="mailto:gds@aadeshtravels.com">gds@aadeshtravels.com</a>
          </td>
        </tr>

        <!-- <tr>
            <td  >
                Heema Lathiya
            </td>
            <td  >
                Ticketing Agent
            </td>
            <td  >
                +91 281 7123123 // 9824433033&nbsp;
            </td>
            <td  >
                <a href="mailto:gds@aadeshtravels.com">gds@aadeshtravels.com</a>
            </td>
        </tr> -->
        <!-- <tr>
          <td>
            Vismay Parmar
          </td>
          <td>
            Ticketing Agent
          </td>
          <td>
            +91 281 7123123 //&nbsp;
          </td>
          <td>
            <a href="mailto:gds@aadeshtravels.com">gds@aadeshtravels.com</a>
          </td>
        </tr> -->

      </tbody>
    </table>
  </div>
  <div class="seperator"></div>
  <h5 class="support-title"><span class="text-bold text-pinkish-red mr-2">LCC/PORTAL:</span> <a
      href="mailto:lcc@aadeshtravels.com" target="_blank">lcc@aadeshtravels.com</a> </h5>
  <div class="mobile-scroll-table  custom-table">
    <table class="table mb-0">
      <thead>
        <tr>
          <th class="text-bold">
            NAME
          </th>
          <th class="text-bold">
            DESIGNATION
          </th>
          <th class="text-bold">
            CONTACT NUMBER
          </th>
          <th class="text-bold">
            EMAIL
          </th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <td>
            Diptesh Bhesaniya
          </td>
          <td>
            Head – Portal Management
          </td>
          <td>
            +91 99099 88860
          </td>
          <td>
            <a href="mailto:diptesh@aadeshtravels.com">diptesh@aadeshtravels.com</a>
          </td>
        </tr>
        <tr>
          <td>
            Ankur Pokiya
          </td>
          <td>
            Ticketing Agent
          </td>
          <td>
            +91 281 2411111 

          </td>
          <td>
            <a href="mailto:lcc@aadeshtravels.com">lcc@aadeshtravels.com</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="seperator"></div>
  <h5 class="support-title"><span class="text-bold text-pinkish-red mr-2">GROUP:</span> <a
      href="mailto:groups@aadeshtravels.com" target="_blank">groups@aadeshtravels.com</a> </h5>
  <div class="mobile-scroll-table  custom-table">
    <table class="table mb-0">
      <thead>
        <tr>
          <th class="text-bold">
            NAME
          </th>
          <th class="text-bold">
            DESIGNATION
          </th>
          <th class="text-bold">
            CONTACT NUMBER
          </th>
          <th class="text-bold">
            EMAIL
          </th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <td>
            Jagruti Parmar 
          </td>
          <td>
            Executive – Groups
          </td>
          <td>
            +91 98244 33033
          </td>
          <td>
            <a href="mailto:groups@aadeshtravels.com">groups@aadeshtravels.com</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="seperator"></div>
  <h5 class="support-title"><span class="text-bold text-pinkish-red mr-2">REFUND GDS:</span> <a
      href="mailto:refunds@aadeshtravels.com" target="_blank">refunds@aadeshtravels.com</a> </h5>
  <div class="mobile-scroll-table  custom-table">
    <table class="table mb-0">
      <thead>
        <tr>
          <th class="text-bold">
            NAME
          </th>
          <th class="text-bold">
            DESIGNATION
          </th>
          <th class="text-bold">
            CONTACT NUMBER
          </th>
          <th class="text-bold">
            EMAIL
          </th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <td>
            Vidhi Chag
          </td>
          <td>
            GDS-Amadeus, Galileo, Sabre
          </td>
          <td>
            +91 98799 48301 
          </td>
          <td>
            <a href="mailto:refunds@aadeshtravels.com
              ">refunds@aadeshtravels.com
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="seperator"></div>
  <h5 class="support-title"><span class="text-bold text-pinkish-red mr-2">REFUND LCC:</span> <a
      href="mailto:lcc.refunds@aadeshtravels.com" target="_blank">lcc.refunds@aadeshtravels.com</a> </h5>
  <div class="mobile-scroll-table  custom-table">
    <table class="table mb-0">
      <thead>
        <tr>
          <th class="text-bold">
            NAME
          </th>
          <th class="text-bold">
            DESIGNATION
          </th>
          <th class="text-bold">
            CONTACT NUMBER
          </th>
          <th class="text-bold">
            EMAIL
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            Ankur Pokiya
          </td>
          <td>
            Ticketing Agent 
          </td>
          <td>
            +91 281 2411111
          </td>
          <td>
            <a href="mailto:lcc.refunds@aadeshtravels.com
            ">lcc.refunds@aadeshtravels.com
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="seperator"></div>
  <h5 class="support-title"><span class="text-bold text-pinkish-red mr-2">HOTELS:</span> <a
      href="mailto:hotels@aadeshtravels.com" target="_blank">hotels@aadeshtravels.com</a> </h5>
  <div class="mobile-scroll-table  custom-table">
    <table class="table mb-0">
      <thead>
        <tr>
          <th class="text-bold">
            NAME
          </th>
          <th class="text-bold">
            DESIGNATION
          </th>
          <th class="text-bold">
            CONTACT NUMBER
          </th>
          <th class="text-bold">
            EMAIL
          </th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <td>
            Disha Chandarana
          </td>
          <td>
            Executive – Hotel
          </td>
          <td>
            +91 281 7123123 // 9879948302
          </td>
          <td>
            <a href="mailto:hotels@aadeshtravels.com
              ">hotels@aadeshtravels.com
            </a>
          </td>
        </tr>
        <!-- <tr>
        <td  >
          Ankur Pokiya
        </td>
        <td  >
          LCC Airlines
        </td>
        <td  >
          +91 281 7123123 // 9909962680
        </td>
        <td  >
            <a href="refunds@aadeshtravels.com
            ">refunds@aadeshtravels.com
          </a>
        </td>
    </tr>   -->
      </tbody>
    </table>
  </div>
  <div class="seperator"></div>
  <h5 class="support-title"><span class="text-bold text-pinkish-red mr-2">AOH - AFTER OFFICE HOURS</span></h5>
  <div class="mobile-scroll-table  custom-table">
    <table class="table mb-0">
      <thead>
        <tr>
          <th class="text-bold">
            NAME
          </th>
          <th class="text-bold">
            DESIGNATION
          </th>
          <th class="text-bold">
            CONTACT NUMBER
          </th>
          <th class="text-bold">
            EMAIL
          </th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <td>
            Dhruv Shah
          </td>
          <td>
            Ticketing Agent
          </td>
          <td>
            +91 99099 62461
          </td>
          <td>
            <a href="mailto:gds@aadeshtravels.com
              ">gds@aadeshtravels.com
            </a>
          </td>
        </tr>
        <tr>
          <td>
            Aarzoo Depani
          </td>
          <td>
            Ticketing Agent
          </td>
          <td>
            +91 99099 62680
          </td>
          <td>
            <a href="lcc@aadeshtravels.com
              ">lcc@aadeshtravels.com

            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="seperator"></div>
  <h5 class="support-title"><span class="text-bold text-pinkish-red mr-2">SALES</span></h5>
  <div class="mobile-scroll-table  custom-table">
    <table class="table mb-0">
      <thead>
        <tr>
          <th class="text-bold">
            NAME
          </th>
          <th class="text-bold">
            DESIGNATION
          </th>
          <th class="text-bold">
            CONTACT NUMBER
          </th>
          <th class="text-bold">
            EMAIL
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            Youraj Choudhary 
          </td>
          <td>
            Business Development
          </td>
          <td>
            +91 90990 49461 
          </td>
          <td>
            <a href="mailto:youraj@aadeshtravels.com
              ">youraj@aadeshtravels.com
            </a>
          </td>
        </tr>
        <tr>
          <td>
            Digvijaysinh Chudasama
          </td>
          <td>
            Sales Manager – Saurashtra Kutch 
          </td>
          <td>
            +91 98795 51212
          </td>
          <td>
            <a href="mailto:digvijay@aadeshtravels.com
            ">digvijay@aadeshtravels.com
            </a>
          </td>
        </tr>
        <tr>
          <td>
            Tarak Dave
          </td>
          <td>
            Assist sales Manager – South Gujarat 
          </td>
          <td>
            +91 99099 42621 
          </td>
          <td>
            <a href="mailto:Tarak@aadeshtravels.com
              ">Tarak@aadeshtravels.com
            </a>
          </td>
        </tr>
      
      </tbody>
    </table>
  </div>

  <div class="seperator"></div>
  <h5 class="support-title"><span class="text-bold text-pinkish-red mr-2">ACCOUNTS:</span> <a
      href="mailto:accounts@aadeshtravels.com" target="_blank">accounts@aadeshtravels.com</a> </h5>
  <div class="mobile-scroll-table  custom-table">
    <table class="table mb-0">
      <thead>
        <tr>
          <th class="text-bold">
            NAME
          </th>
          <th class="text-bold">
            DESIGNATION
          </th>
          <th class="text-bold">
            CONTACT NUMBER
          </th>
          <th class="text-bold">
            EMAIL
          </th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <td>
            Mehul Kakkad
          </td>
          <td>
            Manager – Accounts
          </td>
          <td>
            +91 78788 56721
          </td>
          <td>
            <a href="mailto:mkakkad@aadeshtravels.com
              ">mkakkad@aadeshtravels.com
            </a>
          </td>
        </tr>
        <tr>
          <td>
            Kandarp Rathod
          </td>
          <td>
            Executive – Accounts
          </td>
          <td>
            +91 281 2411111 
          </td>
          <td>
            <a href="mailto:acct@aadeshtravels.com
            ">acct@aadeshtravels.com
            </a>
          </td>
        </tr>
        <tr>
          <td>
            Mehul Vader
          </td>
          <td>
            Executive – Accounts
          </td>
          <td>
            +91 281 2411111 
          </td>
          <td>
            <a href="mailto:acct@aadeshtravels.com
          ">acct@aadeshtravels.com
            </a>
          </td>
        </tr>
        <tr>
          <td>
            Amit Padhiyar
          </td>
          <td>
            Executive – Accounts
          </td>
          <td>
            +91 281 2411111 
          </td>
          <td>
            <a href="mailto:acct@aadeshtravels.com
        ">acct@aadeshtravels.com
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="seperator"></div>
  <h5 class="support-title"><span class="text-bold text-pinkish-red mr-2">EMAIL</span> </h5>
  <div class="mobile-scroll-table  custom-table">
    <table class="table mb-0">
      <tbody>

        <tr>
          <td>
            International Ticketing & Reissue
          </td>
          <td>
            <a href="mailto:gds@aadeshtravels.com
              ">gds@aadeshtravels.com
            </a>
          </td>
        </tr>
        <tr>
          <td>
            Domestic Ticketing & Reissue
          </td>
          <td>
            <a href="mailto:lcc@aadeshtravels.com">lcc@aadeshtravels.com
            </a>
          </td>
        </tr>
        <tr>
          <td>
            Groups-Domestic & International
          </td>
          <td>
            <a href="mailto:groups@aadeshtravels.com">groups@aadeshtravels.com</a>
          </td>
        </tr>
        <tr>
          <td>
            Hotel Bookings – Domestic & International
          </td>
          <td>
            <a href="mailto:hotels@aadeshtravels.com">hotels@aadeshtravels.com</a>
          </td>
        </tr>
        <tr>
          <td>
            International / Domestic Refunds (GDS)
          </td>
          <td>
            <a href="mailto:refunds@aadeshtravels.com">refunds@aadeshtravels.com</a>
          </td>
        </tr>
        <tr>
          <td>
            Accounts Related Queries
          </td>
          <td>
            <a href="mailto:accounts@aadeshtravels.com">accounts@aadeshtravels.com</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>