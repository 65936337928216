import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BookingService } from 'src/app/services/booking/booking.service';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver'; 
import { DomSanitizer } from '@angular/platform-browser';


@Component({
	selector: 'app-print-ticket',
	templateUrl: './print-ticket.component.html',
	styleUrls: ['./print-ticket.component.scss'],
})
export class PrintTicketComponent implements OnInit {
	@ViewChild('close') close : ElementRef;
	always: any = [];
	data: any = [];
	bookingData: any = [];
	userData: any = [];
	flightRequest: any = [];
	fareCharges: any = [];
	legs: any = [];
	statesArr: any = [];
	segment: any;
	flightFare: any = [];
	bookingArr: any = [];
	valid: boolean = false;
	pricePaxArr: any = [];
	affiliateMarkUpPrice: number = 0;
	totalFare;
	searchId: string;
	userDetail: any = {};
	ticketArr: any = [];
	status: boolean = false;
	// isSelected : boolean = false;
	hasMeal: boolean = false;
	hasBaggage: boolean = false;
	hasSeat: boolean = false;
	hasWheelChair: boolean = false;
	hasAdditionalBaggage: boolean = false;
	hasAncillaryServices: boolean = false;
	
	ancillaryItem;

	emailForm: any;
	successMsg: boolean = false;
	errorMsg: boolean = false;
	showMulticityBtn: boolean = false;
	barcodeList;
	showBarCode;

	constructor(private router: Router,private commonService : CommonService, private route: ActivatedRoute,
		private bookingService: BookingService, private affiliateService: AffiliateService
		,private fb: FormBuilder,
		private domSanitizer: DomSanitizer) { }

	ngOnInit() {
		let searchId = this.route.snapshot.params['searchId'];
		let bookingRef = this.route.snapshot.params['booking_ref'];
		let withoutPrice = ""
		this.status  = false
		if (window.location.hash.includes('?')) {
			withoutPrice = window.location.hash.split('?')[1].split("=")[1]
		}
		if (withoutPrice == "true") {
			this.status  = true
		}
		this.userData = JSON.parse(localStorage.getItem('user_details'));
		this.searchId = searchId;
		this.bookingService.getBookingConfirmDetails(searchId,'invoice', bookingRef).subscribe(res=>{
			let data = JSON.parse(res["_body"])['data']
			this.ticketArr = data
			this.bookingArr = this.ticketArr[0]['json_doc']
			 
			console.log(this.bookingArr);
			if (this.bookingArr.hasOwnProperty('supplierInfo')) {
				if (this.bookingArr.supplierInfo.hasOwnProperty('multicity')) {
					this.showMulticityBtn = true
				}
			}
			this.checkSSR(this.bookingArr.travellers)
			this.getLoginAffiliateDetails(this.ticketArr[0]['json_doc']['affiliate_id']);
			let supplier = this.bookingArr.segments[0].booking_supplier
			if (this.bookingArr.supplierInfo && this.bookingArr.supplierInfo.SUPPLIER) {
				supplier = this.bookingArr.supplierInfo.SUPPLIER
			}
			this.getBarCodeList(this.bookingArr.gds_pnr,supplier)
		})  
		this.emailForm = this.fb.group({
			'txtEmail': ['', [Validators.required, Validators.email]],
			'nopriceCheck': [false],
		})
		// this.ticketArr = JSON.parse(localStorage.getItem('bookingArr'))
		// this.bookingArr = this.ticketArr['json_doc']
		// console.log('Booking Arr', this.bookingArr)
		
	}
	get txtEmail(){
		return this.emailForm.controls.txtEmail;
	}
	get nopriceCheck(){
		return this.emailForm.controls.nopriceCheck;
	}
	checkSSR(travellers){
		this.hasMeal = false
		this.hasBaggage = false
		this.hasSeat = false
		this.hasAdditionalBaggage = false
		this.hasAncillaryServices = false
		this.hasWheelChair = false
		this.ancillaryItem = new Map()
		for (let i = 0; i < travellers.length; i++) {
			const element = travellers[i];
			if (element.ssr != null) {
				for (let j = 0; j < element.ssr.length; j++) {
					const el = element.ssr[j];
					if (el.baggages != null) {
						if (el.baggages.name && el.baggages.name != 'NA') {
							this.hasBaggage = true
						}
					}
					if (el.additionalBaggages != null) {
						if (el.additionalBaggages.name && el.additionalBaggages.name != 'NA') {
							this.hasAdditionalBaggage = true
						}
					}
					if(el.ancillaries != null) {
						if(el.ancillaries.code && Array.isArray(el.ancillaries.code) && el.ancillaries.name && el.ancillaries.name != 'NA'){
							this.hasAncillaryServices = true
							for (const ancillary of el.ancillaries.code) {
								this.ancillaryItem.set(ancillary.code,ancillary.name)
							}
						}else if(el.ancillaries.code && el.ancillaries.code.name && el.ancillaries.name  && el.ancillaries.name != 'NA'){
							this.hasAncillaryServices = true
						}
					}
					if (el.meals != null) {
						if (el.meals.code && el.meals.name && el.meals.name != 'NA') {
							this.hasMeal = true
						}
					}
					if (el.seats != null) {
						if (el.seats.code && el.seats.name && el.seats.name != 'NA') {
							this.hasSeat = true
						}
					}

					if (el.wheelChairs != null) {
						if (el.wheelChairs.code && el.wheelChairs.name && el.wheelChairs.name != 'NA') {
							this.hasWheelChair = true
						}
					}
				}
			}
		}
		
		console.log('ancillaryServiceInfo : ',this.ancillaryItem)
		
	}
	getTime(date) {
		return date.split('+').splice(0, 1)
	}
	getLoginAffiliateDetails(affiliateID) {
		// createAffliate/getAffiliate?userId=136
		this.commonService.getAffiliateDetailForTicket('createAffliate/getAffiliate?userId='+affiliateID).subscribe(data=>{
			this.userDetail = data[0];
		})
		// this.affiliateService.getAffiliateDetails().subscribe(res => {
		// 	this.userDetail = res[0];
		// 	console.log('affiliate details', this.userDetail)
		// })
	}
	getTicket(ticket, pnr){
		if (ticket != null && ticket != undefined) {
			return ticket
		}
		else{
			return pnr
		}
	}

	printTicket(){
		window.scrollTo(0,0)
		setTimeout(() => {
			document.body.getElementsByTagName('app-print-ticket')[0].firstElementChild.classList.remove('mb-fix')
			window.print()
		}, 500)
		
	}

	emailTicket(values){
		let mailJson = {}; Object.assign(mailJson, {
							'event': "booking_confirmation_ticket_email",
							'email': values.txtEmail,
							'locals': {
								"booking_ref_no": this.bookingArr.booking_ref,
								"withoutprice": values.nopriceCheck
							},
							'invoice_number' : this.bookingArr.invoice_no
			  })
		this.commonService.postMail(mailJson).subscribe(res=>{
		  console.log(res)
		  this.successMsg = true;		
		  setTimeout(()=>{
			this.closeModal();
			this.successMsg = false;
		  },3000)
		},
		err=>{
		  console.log(err)
		  this.errorMsg = true
		})
	
	  }

	grandTotal(data) {
		this.totalFare = 0
		if (data != undefined) {
			for (let i = 0; i < data.length; i++) {
				const element = data[i];
				if (element.status == 'TICKETED' || element.status == undefined) {
				const lThis = this;
				let keys = Object.keys(element.fare.taxes[0]);
				let count = 0;
				Object.values(element.fare.taxes[0]).forEach(function (v) {
					if (keys[count] != "MARKUP" && keys[count] != "COMMISSION" && keys[count] != "TOTAL" && keys[count] != "TDS" && keys[count] != "NET_PAYABLE" &&
						keys[count] != "ADMIN_MARKUP" && keys[count] != "OTHER_TAX" && keys[count] != "AFFILIATE_MARKUP" && keys[count] != "ADMIN_SERVICE_CHARGE" && keys[count] != "GST" && keys[count]!="REISSUANCE_FARE_DIFFERENCE") {
						lThis.totalFare += Number(v);
					}
					if (keys[count] == "OTHER_TAX" || keys[count] == "ADMIN_MARKUP" || keys[count] == "AFFILIATE_MARKUP") {
						lThis.totalFare += Number(v);
					}
					count++;
				})
				this.totalFare += Number(element.fare.base) + this.getSSRPrice(element.ssr);
				}
			}
		}
		return this.totalFare
	}

	totalTax(data) {
		let totalTax = 0
		const element = data;
		const lThis = this;
		let keys = Object.keys(element.fare.taxes[0]);
		let count = 0;
		Object.values(element.fare.taxes[0]).forEach(function(v) { 
			if (keys[count] != "MARKUP" && keys[count] != "COMMISSION" && keys[count] != "TOTAL" && keys[count] != "TDS" && keys[count] != "NET_PAYABLE" && 
			keys[count] != "ADMIN_MARKUP" && keys[count] != "OTHER_TAX" && keys[count] != "AFFILIATE_MARKUP"  && keys[count] != "ADMIN_SERVICE_CHARGE" && keys[count] != "GST" &&  keys[count]!="REISSUANCE_FARE_DIFFERENCE") {
				totalTax += Number(v); 
			}
			if(keys[count] == "OTHER_TAX" || keys[count] == "ADMIN_MARKUP" || keys[count] == "AFFILIATE_MARKUP") {
				totalTax += Number(v); 
			}
			count++;
		})		
		totalTax += this.getSSRPrice(element.ssr);
		return totalTax;
	}

	getTotal(data) {
		const element = data;
		return Number(element.fare.base) + this.totalTax(data);
	}
	getSSRPrice( ssr) {
		let meal = 0;
		let baggage = 0;
		let seat = 0;
		let additionalBaggage = 0;
		let ancillaryCharges = 0;
		if (ssr != null) {
			for (let i = 0; i < ssr.length; i++) {
				const element = ssr[i];
				if (element.meals != null && element.meals.code != null) {
					meal += Number(this.checkifNull(element.meals.code.totalFare));
				}
				if (element.baggages != null && element.baggages.code != null) {
					baggage += Number(this.checkifNull(element.baggages.code.totalFare));
				}
				if (element.seats != null && element.seats.code != null) {
					seat += Number(this.checkifNull(element.seats.code.totalFare));
				}
				if (element.additionalBaggages != null) {
					additionalBaggage += Number(element.additionalBaggages.code ? element.additionalBaggages.code.totalFare : 0);
				}
				if(element.ancillaries != null) {
					if(Array.isArray(element.ancillaries.code)){
						for (const ancillary of element.ancillaries.code) {
							ancillaryCharges += Number(ancillary.code ? ancillary.totalFare : 0);
						}
					}else{
						ancillaryCharges += Number(element.ancillaries.code ? element.ancillaries.code.totalFare : 0);
					}
				}
			}
		}
		return  Number(meal) + Number(baggage) + Number(seat) +  Number(ancillaryCharges) + Number(additionalBaggage)
	}

	checkifNull(value){
		if (value == undefined) {
			return 0
		}
		else{
			return value
		}
	}

	createPriceDetails(data) {
		let mapArr = new Map();
		this.totalFare = 0;
		data.forEach(val => {
			if (val.tax_name == 'total') {
				this.totalFare += Number(val.tax_price);
			}
			if (val.tax_name == 'affiliateMarkup') {
				this.affiliateMarkUpPrice += Number(val.tax_price);
			}
			if (mapArr.has(val.pax_name + val.id)) {
				let mapVal = mapArr.get(val.pax_name + val.id)
				if (mapVal['price']) {
					if (mapVal.price[val.tax_name] != undefined) {
						let value = mapVal.price[val.tax_name];
						val.tax_price = Number(val.tax_price) + Number(value);
					}
					mapVal['price'][val.tax_name] = val.tax_price;
				}
				else {
					mapVal['price'] = {};
					mapVal['price'][val.tax_name] = val.tax_price;
				}

				val = mapVal;
				mapArr.set(val.pax_name + val.id, val)
			}
			else {
				val['price'] = {}
				val['price'][val.tax_name] = val.tax_price;
				mapArr.set(val.pax_name + val.id, val);
			}
		})
		this.pricePaxArr = Array.from(mapArr)

		console.log('map arr', this.pricePaxArr)
		// if(priceMap.has)
	}

	returnDate(date) {
		let d = new Date(date.split("+")[0]);
		return d.toDateString()

	}

	returnTime(date) {
		let d = new Date(date.split("+")[0]);
		return d.toTimeString().split(' ')[0];
	}

	print() {
		window.print()
	}

	resetForm() {
		this.emailForm.reset();
	}

	private closeModal(): void {
		this.close.nativeElement.click();
	}

	checkStatus() {
		window.scrollTo(0,0)
		this.status = true;
		setTimeout(() => {
			window.print();
			this.status = false;
		}, 100);
	}

	callPdfGeneratorApi(invoice,booking_ref,withoutPrice){
		window.scrollTo(0,0)
		let ticketUrl = environment.ticketUrl+invoice;
		if (withoutPrice) {
		ticketUrl = environment.ticketUrl+invoice+'?pricing=' + withoutPrice;
		}
		let requestBody = {
			urltemp : ticketUrl
		}
		this.commonService.generatePDF(requestBody).subscribe(res=>{
			let fileBlob = res.blob();
			let blob = new Blob([fileBlob], { 
			   type: 'application/pdf'
			});
			let filename =  booking_ref+".pdf";
			FileSaver.saveAs(blob, filename);
		},
		err => {
			console.log(err)
		});
	}

	viewAllTicket(){
		if (this.bookingArr.supplierInfo.multicity['parent_booking'] != "") {
			console.log('Parent AT number : ',this.bookingArr.supplierInfo.multicity['parent_booking'])
			let searchId = this.bookingArr.supplierInfo.multicity['parent_booking']
			window.open("#/template/print-multicity-ticket;searchId="+searchId, "_blank");
		}
		else{
			console.log('Parent AT number : ',this.bookingArr['booking_ref'])
			let searchId = this.bookingArr['booking_ref']
			window.open("#/template/print-multicity-ticket;searchId="+searchId, "_blank");
		}
	}

	isArray(data){
		return Array.isArray(data)
	}

	getBarCodeList(pnr,supplier){
		this.commonService.airGetValue(`barcode?pnr=${pnr}&supplierName=${supplier}`).subscribe(res=> {
			this.barcodeList = res
			this.showBarCode = true
			setTimeout(() => {
				this.valid = true
			}, 2000);
		},err=> {
			this.showBarCode = false
			setTimeout(() => {
				this.valid = true
			}, 2000);
		})
	}

	getBarcode(traveller){
		if(traveller && this.barcodeList){
			let travellerName = `${traveller.title}${traveller.first_name}${traveller.last_name}`.toUpperCase().replace(/ /g, '')
			// let travellerName = `${traveller.first_name} ${traveller.last_name}`.toUpperCase()
			if(this.barcodeList[travellerName]){
				return this.barcodeList[travellerName]
			}else{
				let alternateName = `${traveller.first_name}${traveller.last_name}`.toUpperCase().replace(/ /g, '')
				let names = Object.keys(this.barcodeList)
				let foundtraveller = names.filter(data=> {
					return data.includes(alternateName)
				})
				if(foundtraveller && foundtraveller.length > 0 && this.barcodeList[foundtraveller[0]]){
					return this.barcodeList[foundtraveller[0]]
				}
			}
		}
		return null
	}
	getSanitizedUrl(image){
		let data = this.domSanitizer.bypassSecurityTrustUrl(image)
		return data
	}
}
