import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { LedgerService } from 'src/app/services/ledger/ledger.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-statement-print',
  templateUrl: './statement-print.component.html',
  styleUrls: ['./statement-print.component.scss']
})
export class StatementPrintComponent implements OnInit {

  @ViewChild('printBox') printBox : ElementRef;
	dataSource: any = [];
	openingBal: number = 0;
	
	navigationSubscription;
	ascSortRef: boolean = true;
	ascBookDateSort: boolean = true;
	ascJourneyDateSort: boolean = true;
	innerHeight: string;

	fromDate = new Date();
	toDate = new Date();
	showLogo = false

	constructor(private ledegrService: LedgerService, private route: ActivatedRoute,
		private router: Router) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
	 }

	ngOnInit() {
    let affiliate = this.route.snapshot.params['affiliate'];
		this.submit(affiliate)
  }
  
	
	initialiseInvites() {
		this.ngOnInit()
  }
  private showPrintBox(): void {
    this.printBox.nativeElement.offsetParent.classList.add('d-block')
	}

	ngOnDestroy() {
	
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}


	submit(affiliate) {
		let value ={};
		value['xlCode'] = affiliate
		const lThis = this
		this.ledegrService.viewStatement(value).subscribe(res => {
			if(JSON.parse(res['_body']).length > 0) {

				this.dataSource = JSON.parse(res['_body']);
				if (this.dataSource.length > 0) {
					lThis.fromDate = this.dataSource[0].timestamph
					lThis.toDate = this.dataSource[this.dataSource.length - 1].timestamph
				}
        this.showPrintBox()
			}
			
			if (this.dataSource.length <= 3) {
				this.innerHeight = 'auto';
			}
			else {
				this.innerHeight = window.innerHeight - 400 + 'px';
			}
		})
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if (this.dataSource.length <= 3) {
			this.innerHeight = 'auto';
		}
		else {
			this.innerHeight = event.target.innerHeight - 400 + 'px';
		}
	}
}