import { combineReducers, createStore } from "redux";
import { SSrReducers } from "./addons/reducer";
import { InsuranceTravellerReducers } from "./travellerInsurance/reducers";
import { flightReducerData } from "./flight/reducers";
import { CommonReducers } from "./commonStore/reducers";
import {initialState as commonInitialState} from "./commonStore/reducers"

const reducers = {
  CommonReducers,
  SSrReducers,
  InsuranceTravellerReducers,
  flightReducerData,
}
export interface InitialState {
  SSrReducers : any,
  InsuranceTravellerReducers:any,
  flightReducerData : any,
  CommonReducers : any
}
export const initialState = {
  SSrReducers : {},
  InsuranceTravellerReducers:{},
  flightReducerData : {},
  CommonReducers : commonInitialState
};


export  const rootReducers = combineReducers({
  ...reducers,
})
