import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BookingSummaryDetailComponent } from './booking-summary-detail.component';
import { AuthGuardService } from 'src/app/services/auth.guard.service';

const routes: Routes = [
	{
		path: '',redirectTo: 'pages/dashboard',pathMatch: 'full'
	  },
	{
		path: '',
		component: BookingSummaryDetailComponent, canActivate: [AuthGuardService]
	}
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BookingSummaryDetailRoutingModule { }
