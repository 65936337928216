import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class TravellerService {

	constructor(private commonService: CommonService) { }

	private travellerSource = new BehaviorSubject(false);
	travellerStatus = this.travellerSource.asObservable();

	private gstSource = new BehaviorSubject(false);
	gstStatus = this.gstSource.asObservable();

	reloadTravellerData(data) {
		this.travellerSource.next(data)
	}

	reloadGstData(data) {
		this.gstSource.next(data)
	}

	saveTraveller(data) {
		return this.commonService.postValue(`traveller/save`, data);
	}
	saveAllTraveller(data) {
		return this.commonService.postValue(`traveller/saveAllTravellers`, data);
	}
	viewAllTravellers() {
		return this.commonService.getAffiliateValue(`traveller/viewAll`);
	}
	viewAllTravellersForBooking(firstname) {
		return this.commonService.getAffiliateValue(`traveller/viewAllinforBooking?name=${firstname}`);
	}
	viewAllGSTForBooking(name) {
		return this.commonService.getAffiliateValue(`travellerGst/viewAllGST?name=${name}`);
	}
	viewAllGSTbyid() {
		return this.commonService.getAffiliateValue(`travellerGst/viewAllGSTbyid`);
	}
	checkifExist(obj) {
		return this.commonService.getAffiliateValue(`traveller/checkifExist?firstName=${obj.firstName}&lastName=${obj.lastName}&title=${obj.title}`);
	}
	checkifExistgst(obj) {
		return this.commonService.getAffiliateValue(`travellerGst/checkifExist?gst_number=${obj.corporateGstNumber}&name=${obj.corporateName}`);
	}
	updateTraveller(data) {
		return this.commonService.postValue(`traveller/updateTraveller`, data);
	}
	deleteTraveller(data) {
		return this.commonService.postValue(`traveller/delete`, data);
	}

	saveGst(data) {
		return this.commonService.postValue(`travellerGst/save`, data);
	}

	saveBookingGst(data) {
		return this.commonService.postValue(`travellerGst/saveGST`, data);
	}

	viewAllGst() {
		return this.commonService.getAffiliateValue(`travellerGst/viewAll`);
	}
	updateGst(data) {
		return this.commonService.postValue(`travellerGst/update`, data);
	}
	deleteGst(data) {
		return this.commonService.postValue(`travellerGst/delete`, data);
	}
	getMeal(data) {
		return this.commonService.getmeal('ssrAvailability', data);
	}
	// Air Asia code : {I5:"AirAsiaIndia", AK:"AirAsiaBerhad", QZ:"IndonesiaAirAsia", FD:"ThaiAirAsia", PQ:"PhilippinesAirAsia", Z2:"PhilippinesAirAsia:"}
	checkLCC(supplier) {
		if (supplier == "SG" || supplier == "6E" || supplier == "G8" || supplier == "G9" || supplier == "3L" || supplier == "2T" || supplier == "QP" || supplier == "9I" || supplier == "S5" ||
			//AirAsia Check
			supplier == "I5" || supplier == "IX" || supplier == "AK" || supplier == "QZ" || supplier == "FD" || supplier == "PQ" || supplier == "Z2" || supplier == "D7" || supplier == "XJ" || supplier == "XT" || supplier == "DJ") {
			return true;
		}
		else {
			return false;
		}
	}

	getBaggage(data) {
		return this.commonService.getBaggage(`baggageAllowance`, data);
	}

	doHDFCPayment(obj) {
		this.commonService.hdfcPayment(obj, 'ccavRequestHandler/payment-request').subscribe(data => {
			console.log("payment response ", data)
			if (data['_body'] != "") {
				let response = JSON.parse(data['_body'])
				var url = environment.hdfcPaymentUrl + '/ccavRequestHandler/make-payment?key=' + response['encRequest'] + '&orderId=' + response['orderId'];
				window.location.href = url;
			}
			else {

			}
		}, error => {
			console.log(error)
		})
	}

	doFTCASHPayment(obj) {
		this.commonService.onlinePayment(obj).subscribe(data => {
			console.log("payment response ", data)
			if (data['_body'] != "") {
				var url = data['_body'];
				console.log(url);
				window.location.href = url;
			}
			else {

			}
		}, error => {
			console.log(error)
			 ;
		})
	}

	getTotalPaxFromSearch() {
		let totalPax = 0
		if (sessionStorage.getItem('ActiveSearchRequest') != null && sessionStorage.getItem('ActiveSearchRequest') != undefined) {
			let localSearchReq = JSON.parse(sessionStorage.getItem('ActiveSearchRequest'))
			totalPax = Number(localSearchReq.noOfAdults) + Number(localSearchReq.noOfChild)
		}
		return totalPax
	}
}
