import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class UrlService {

	baseUrl="";
	public static isStudentFare = false;

	// searchPostUrl = "https://323603c8-bc6d-4aaf-a0f0-7121885211de.mock.pstmn.io/getList";
	// searchPostUrl = "http://stage.aadeshtravels.com:8081/flight";
	// searchPostUrl = "http://localhost:7071/flight";
	// loginUrl = "http://95.216.187.252:2019/login";
	// loginUrl = "http://localhost:2019/login";
	// webSocketUrl = "ws://stage.aadeshtravels.com:8081/events/ws/signal";

	constructor() {

		if(location.hostname == "localhost") {
			this.baseUrl = "";
		}
		else{
			
		}
		

	}
}
