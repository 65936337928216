export enum ActionTypes {
  SET_OFFER_VISIBLE = '[Flight] Show/Hide offer amount',
}

export const ToggleOfferPrice2 = payload => ({
  type : ActionTypes.SET_OFFER_VISIBLE,
  payload
})


import { Action } from '@ngrx/store';


export class ToggleOfferPrice implements Action {
  readonly type =  ActionTypes.SET_OFFER_VISIBLE;
  //add an optional payload
  constructor(public payload: any) {}
}
