<div class="baggage-container">
  <div class="baggage-box" [@.disabled]="true">
      <mat-tab-group>
          <div *ngFor="let item of ssrAvailability; let i = index;">
              <mat-tab *ngIf="i==0">
                  <div class="row baggage-identifier">
                      <div class="col">
                          <span class="head"><i class="fa fa-users mr-1" aria-hidden="true"></i>Travellers</span>
                      </div>
                      <div class="col">
                          <span class="head"><span class="baggage-icon0"><img src="assets/images/briefcase.png"/></span>Baggage</span>
                      </div>
                      <div class="col">
                          <span class="head"><i class="fa fa-check mr-1" aria-hidden="true"></i>Your Selection</span>
                      </div>
                  </div>
                  <div class="row mb-3" *ngFor="let passenger of item.travellers; let k = index;">
                      <ng-container *ngIf="passenger.type != 'INF'">
                          <div class="col traveller-info">
                              <div class="">
                                  <div class="traveller-name">{{passenger.title}} {{passenger.firstName}} {{passenger.lastName}}</div>
                              </div>
                          </div>
                          <div class="col meal-select">
                              <div class="">
                                  <select name="baggage" id="" (change)="changeBaggage($event.target.value, k)">
                                      <option value="-1">No Baggage</option>
                                      <ng-container *ngFor="let baggages of item.baggages; let j=index">
                                          <option [value]="j">{{baggages.type}} {{baggages.name}} &#8377; {{baggages['totalFare']}}</option>
                                      </ng-container>
                                  </select>
                              </div>
                          </div>
                          <div class="col selected-baggage">
                              <ng-container *ngIf="passenger.baggage !=undefined">
                                  <div class="selected-preview">
                                      <span class="baggage-icon0"><img src="assets/images/briefcase.png"/></span>
                                      <span class="s-meal-name">{{passenger.baggage}} &nbsp;</span>
                                      <span class="s-price">&#8377; {{passenger.baggageFare}}</span>
                                  </div>
                              </ng-container>
                          </div>

                      </ng-container>
                  </div>
              </mat-tab>
          </div>
      </mat-tab-group>
  </div>
</div>