import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-meal',
	templateUrl: './meal.component.html',
	styleUrls: ['./meal.component.scss']
})
export class MealComponent implements OnInit {
	@Input() ssrAvailability;
	@Input() segmentIndex = 0
	@Output() callMealEvent : EventEmitter<any> = new EventEmitter();
	status : boolean = false;
	activeTab = 'free-meal'
	showTabs = false
	constructor() { }

	ngOnInit() {
		
	}
	ngOnChanges(){
		let hasFreeMeal = false
		let hasPaidMeal = false
		if(this.ssrAvailability && this.ssrAvailability.length > 0){
			for (const ssrs of this.ssrAvailability) {
				for (const meal of ssrs.meals ? ssrs.meals : []) {
					if(meal && meal.fare.base == 0){
						hasFreeMeal = true;
					}
					if(meal && meal.fare.base > 0 ){
						hasPaidMeal = true
					}
				}
				
			}
		}
		this.showTabs = hasPaidMeal && hasPaidMeal;
		this.activeTab = hasFreeMeal ? 'free-meal' : 'paid-meal'
		console.log('MEALS : ',hasFreeMeal,this.activeTab )
	}

	toggleEvent() {
		this.status = false;
		let eventValue = event.currentTarget['parentElement']['nextElementSibling']['className'];
		if(event.currentTarget['parentElement']['nextElementSibling']['className'].includes('hide')) {
			this.status = false;
			event.currentTarget['parentElement']['nextElementSibling']['className'] = "meal-dropdown show";
		}
		else{
			event.currentTarget['parentElement']['nextElementSibling']['className'] = "meal-dropdown hide";
		}
		
	}

	changeMeal(meal, leg, pax, isRemove){
		const tempArr = Object.assign({},  this.ssrAvailability[leg])
		let selectedMeal = meal;
		if(isRemove) {
			event.currentTarget['offsetParent']['offsetParent'].firstChild.firstChild.value  = 'None';
			this.ssrAvailability[leg].travellers[pax]['meal'] = undefined;
			this.createMealObject(pax, null, true, 0, leg,tempArr,null);
			event.currentTarget['offsetParent'].parentElement.className = "meal-dropdown hide";
		}
		else{ 
			this.ssrAvailability[leg].travellers[pax]['meal'] = selectedMeal.name;		
			this.ssrAvailability[leg].travellers[pax]['mealType'] = selectedMeal.type;		
			if(selectedMeal.name == null) {
				this.ssrAvailability[leg].travellers[pax]['meal'] = selectedMeal.code;
			}
			this.ssrAvailability[leg].travellers[pax]['mealFare'] = selectedMeal.totalFare;
			this.createMealObject(pax, selectedMeal.code, false, selectedMeal.totalFare, leg,tempArr,meal)
			event.currentTarget['offsetParent']['offsetParent'].firstChild.firstChild.value  = `${selectedMeal.name ? selectedMeal.name : ''} ${selectedMeal.code} `;
			event.currentTarget['offsetParent'].parentElement.className = "meal-dropdown hide";

		
		}	
		
	}
	makeSeatObject(passengerIndex, seatNumber, isRemove, price, leg,tempArr,meal){
		 ;
		let flag = true;
		if(tempArr.flightInfo.ssrs[leg].meals != undefined && tempArr.flightInfo.ssrs[leg].meals != null) {
			tempArr.flightInfo.ssrs[leg].meals = tempArr.flightInfo.ssrs[leg].meals.filter(obj =>{
				if(isRemove) {
					flag = false;
					if(obj.travellerNumber != passengerIndex) {
						return obj;
					}
				}
				else{
					if(obj.travellerNumber == passengerIndex) {
						obj['code'] = seatNumber;
						obj['name'] = meal['name'];
						obj['price'] = price;
						obj['item'] = meal;
						flag = false;
					}
					return obj;
				}
				
			});
		}
		
		if(flag) {
			let obj = {
				"code": seatNumber,
				"name": meal['name'],
				"travellerNumber": passengerIndex,
				"item" : meal,
				"price" : price
			}
			if(tempArr.flightInfo.ssrs[leg].meals == undefined || tempArr.flightInfo.ssrs[leg].meals == null) {
				tempArr.flightInfo.ssrs[leg].meals = [];				
			}
			tempArr.flightInfo.ssrs[leg].meals.push(obj)
		}
		console.log(tempArr.flightInfo.ssrs);
		this.callMealEvent.emit(tempArr.flightInfo.ssrs);
	}
	createMealObject(passengerIndex, mealCode, isRemove, price, leg, tempArr, meal) {
		let flag = true;
		let segment = this.segmentIndex
		if (tempArr.flightInfo.ssrSRT[segment] && tempArr.flightInfo.ssrSRT[segment][leg] && tempArr.flightInfo.ssrSRT[segment][leg].meals != undefined && tempArr.flightInfo.ssrSRT[segment][leg].meals != null) {
			tempArr.flightInfo.ssrSRT[segment][leg].meals = tempArr.flightInfo.ssrSRT[segment][leg].meals.filter(obj => {
				if (isRemove) {
					flag = false;
					if (obj.travellerNumber != passengerIndex) {
						return obj;
					}
				}
				else {
					if (obj.travellerNumber == passengerIndex) {
						obj['code'] = mealCode;
						obj['price'] = price;
						obj['item'] = meal;
						flag = false;
					}
					return obj;
				}

			});
		}

		if (flag) {
			let obj = {
				"code": mealCode,
				"travellerNumber": passengerIndex,
				"item": meal,
				"price": price,
				"title": this.ssrAvailability[leg].travellers[passengerIndex].title,
				"firstName": this.ssrAvailability[leg].travellers[passengerIndex].firstName,
				"lastName": this.ssrAvailability[leg].travellers[passengerIndex].lastName,
			}
			if(tempArr.flightInfo.ssrSRT[segment] == undefined){
				tempArr.flightInfo.ssrSRT[segment] = []
			}
			if(tempArr.flightInfo.ssrSRT[segment][leg] == undefined){
				tempArr.flightInfo.ssrSRT[segment][leg] = {}
			}
			if (tempArr.flightInfo.ssrSRT[segment][leg].meals == undefined || tempArr.flightInfo.ssrSRT[segment][leg].meals == null) {
				tempArr.flightInfo.ssrSRT[segment][leg].meals = [];
			}
			tempArr.flightInfo.ssrSRT[segment][leg].meals.push(obj)
		}
		this.callMealEvent.emit(tempArr.flightInfo.ssrSRT);
	}
}
