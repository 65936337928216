import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment-timezone';

@Pipe({
  name: 'dateGmtFormat'
})
export class DateGmtFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
   
    // let now = moment().tz('Asia/Kolkata')// add this 4of 4
    if(value != null && value != undefined) {
      if (value.includes("+")) {
        value = value.split("+")[0]
      }
      let now = moment(value).format('ddd MMM D YYYY')// add this 4of 4
      // let now = moment(value).tz('Asia/Kolkata').format('ddd MMM D YYYY')// add this 4of 4
      // let now = new Date(value).toDateString()
      // value = now.format(value);
      // let d = new Date(value);
      if (args == 'getMMDD') {
        now  = moment(value).format("DD MMM 'YY")
      }
      if (args == 'getddMMDDYY') {
        now  = moment(value).format("ddd, DD MMM 'YY")
      }
			return now;
		}
		return "";
  }

}
