

<section class="summeryfilter d-none">
    <div class="container" >
        <form [formGroup]="filterForm"  class="position-relative summeryfilter-search-form">
            <button  [ngClass]="isMinimized ? ' ' : 'rotateX180'" (click)="isMinimized = !isMinimized" title="Show/Hide Filter" class="min-max-btn btn-green btn-sm"><i class="fa fa-filter "></i></button>
            <div >                   
                <h5 style="    font-size: 14px;" [ngClass]="isMinimized ? 'd-none' : 'd-block'" class="text-bold m-0 text-center text-black">Showing 100  recent results of your bookings </h5>
                <div [ngClass]="isMinimized ? 'height-auto' : 'height-0'">
            <div class="row justify-content-center search-filter-responsive pt-3">
                <div class=" col-3    ">
                    <div class="date date-picker">
                        <input formControlName="fromDate" bsDatepicker autocomplete="off" #fDate #dpFromYMD="bsDatepicker" [bsConfig]="bsConfig" type="text" class="custom-input m-input" placeholder=" " id="" />
                        <span class="placeholder" (click)="focusOn('fromDate')">From</span>
                        <i class="fa fa-calendar customCalendar2 " (click)="dpFromYMD.toggle()"></i>
                    </div>
                </div>

                <div class="  col-3   ">
                    <div class="date date-picker">
                        <input formControlName="toDate" bsDatepicker autocomplete="off" #tDate #dpYMD="bsDatepicker" [bsConfig]="bsConfig" type="text" class="custom-input m-input" placeholder=" " id="" />
                        <span class="placeholder" (click)="focusOn('toDate')">To</span>
                        <i class="fa fa-calendar customCalendar2 " (click)="dpYMD.toggle()"></i>
                    </div>
                </div>
                <div class="book-refno col-3    ">
                    <input type="button" class="flight-search-btn btn btn-sm w-100 btn-green   pt-0 shadow   " (click)="search(filterForm.value)" value="Search" />
                    <!-- <span class="flight-icon"></span> -->
                </div>
            </div>
            </div>
            </div>
        </form>
    </div>
</section>
<!-- <section class="pagefilter">
	<div class="container">
		<div class="searchnow">
			<div class="searchentries">
				<label>Search Entries</label>
				<div class="ledgerSelect">
						<select>
							<option value="0">10</option>
							<option value="1">11</option>
							<option value="2">12</option>
							<option value="3">13</option>
							<option value="4">14</option>
							<option value="5">15</option>
						</select>
                </div>

			</div>
			<div class="searchtext">
				<label>Search</label>
				<input type="text" name="">
			</div>
		</div>
	</div>
</section> -->
<section class="contents viewledger mat-white-input-tab ">
    <div class="container-fluid  ledger-container">
        <div class="row mb-2">
            <div class="col md-6">
                <!--<form > 
							<div class="switch-field-color">
								<span class="sort-heading">SORT BY</span>
							  <label for="both" [ngClass]="{selected: selectedledger==''}"
								(click)="getLedgerBy('')">Both</label>
							  <input id="both" name="selectedledger"
								type="radio" value='' />
							  <label for="cash" [ngClass]="{selected: selectedledger=='cash'}"
								(click)="getLedgerBy('cash')">Cash</label>
							  <input id="cash" name="selectedledger"
								type="radio" value="cash" />
							  <label for="credit" [ngClass]="{selected: selectedledger=='credit'}"
								(click)="getLedgerBy('credit')">Credit</label>
							  <input id="credit" name="selectedledger"
								type="radio" value="credit" />
							</div>
					   </form>-->
            </div>

        </div>
        <div class="ledger-table-container container-fluid">
           
           
            <div class="row ml-0 mr-0 mb-2 pb-2 pl-3 pr-3 pt-2">
               <div class="col-sm-4 pl-0">
               </div>
                <div class=" text-center  col-sm-4">
                    <label class="control-lable text-black text-bold  ">For the Period of <br><span class="text-pinkish-red ">{{fromDate}}</span> To <span class="text-pinkish-red">{{toDate}}</span></label>
                </div>
                <div class="col-sm-4 pr-0 align-items-center d-flex justify-content-end">  
                    <!-- <button title="Export in Excel" class="btn btn-sm border-btn orange-btn transition mr-2">Excel</button> -->
                    <button title="Back" routerLink="/financials" class="btn btn-sm border-btn red-btn  transition mr-2">Back</button>
                    <div class="dropdown-btn mr-2">
                        <button title="Export in Pdf"  class="btn btn-sm border-btn green-btn transition ">Export Pdf </button>
                        <ul class="dropdown-container">
                            <li class="cursor-pointer hover-text-pinkish-red" (click)="callNormalLedgerPdfGeneratorApi()">Normal Ledger</li>
                            <li class="cursor-pointer hover-text-pinkish-red" (click)="callDetailedLedgerPdfGeneratorApi()">Detailed Ledger</li>
                        </ul>
                    </div>             
                                 
                <!--begin::Search-->
                <div class="search-box ">
                    <div class="m-stack__item customSearchWrapper m-stack__item--middle m-dropdown m-dropdown--arrow m-dropdown--large m-dropdown--mobile-full-width m-dropdown--align-right m-dropdown--skin-light m-header-search m-header-search--expandable m-header-search--skin-"
                        id="m_quicksearch" data-search-type="default">
                        <!--begin::Search Form -->
                        <form class="m-header-search__form">
                            <div class="m-header-search__wrapper customSearch float-right">
                                <span class="m-header-search__icon-search" id="m_quicksearch_search">
											<i class="la la-search"></i>
										</span>
                                <span class="m-header-search__input-wrapper">
											<input autocomplete="off" type="text" name="q" [(ngModel)]="searchField" class="m-header-search__input" value=""
											 placeholder="Search..." id="m_quicksearch_input">
										</span>
                                <span class="m-header-search__icon-close" id="m_quicksearch_close">
											<i class="la la-remove"></i>
										</span>
                                <span class="m-header-search__icon-cancel" id="m_quicksearch_cancel">
											<i class="la la-remove"></i>
										</span>
                            </div>
                        </form>
                        <!--end::Search Form -->
                        <!--begin::Search Results -->
                        <div class="m-dropdown__wrapper">
                            <div class="m-dropdown__arrow m-dropdown__arrow--center"></div>
                            <div class="m-dropdown__inner">
                                <div class="m-dropdown__body">
                                    <div class="m-dropdown__scrollable m-scrollable" data-scrollable="true" data-max-height="300" data-mobile-max-height="200">
                                        <div class="m-dropdown__content m-list-search m-list-search--skin-light"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end::Search Results -->
                    </div>
                    <!--end::Search-->
                </div>
               
            </div>
        </div>
            <div class="mobile-scroll-table  custom-table">
                    <table class="table ">
                            <thead  >
                                <tr>
                                    <th class="d-block mt-2 text-left">Date <i class="fa fa-sort" aria-hidden="true" (click)="dateSort()"></i></th>
                                    <th class="d-block mt-2 text-left">Reference No.<i class="fa fa-sort" aria-hidden="true" (click)="invoiceSort()"></i></th>
                                    <th class="text-left">Particulars</th>
                                    <th class="text-right">Debit</th>
                                    <th class="text-right">Credit</th>
                                    <th class="text-right action-btn">Balance</th>
                                </tr>
                            </thead>
                            <tbody class="custom-table-body" [ngStyle]="{height: innerHeight}">
                                <tr class="custom-table-row" *ngFor="let element of dataSource| ledger : searchField  | paginate: { itemsPerPage: 50, currentPage: p };">
                                    <td  class="text-left">{{element.timestamph | dateFormat}}</td>
                                    <td  class="text-left"><span><a (click)="openInvoice(element)" class="ref-link">{{element.transaction_ref_no}}</a></span></td>
                                    <td  class="text-left">
                                        <span class="no-wrap">{{element.narration_1}}</span>
                                        <span class="no-wrap">{{element.narration_2}}</span>
                                        <span class="no-wrap">{{dateFormat(element.narration_3) }}</span>
                                        <span class="no-wrap">{{element.narration_4}}</span>
                                    </td>
                                    <td class="text-right ">
                                        <span class="bold text-danger" *ngIf="element.typhe == 'debit'"> {{element.amount | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                                        <span class="bold text-danger" *ngIf="element.typhe == 'credit'">{{0 | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                                    </td>
                                    <td class="text-right">
                                        <span class="bold text-success" *ngIf="element.typhe == 'debit'">{{0 | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                                        <span class="bold  text-success" *ngIf="element.typhe == 'credit'">{{element.amount | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                                    </td>
                                    <td class="text-right "><span class="bold text-info">&#8377;{{element.balance | number : '1.0-0'}}</span></td>
                                </tr>
                            </tbody>
                        </table>
            </div>
            <div class="align-items-end card d-flex justify-content-flex-end mb-2 pb-2 pl-3 pr-3 ">
                <table class="balance-wrapper" width="100%" cellpadding="0" cellspacing="0">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            
                            <td><span style="font-weight: 600;padding-right: 15px;text-transform: uppercase;">Opening Balance : </span></td>
                            <td>
                                <label class=" control-lable text-black text-bold">
                                    <span class="text-danger text-bold ml-4" style="display: inline-block; text-align: right;" *ngIf="totalAmountObj && totalAmountObj.openingBalanceType == 'debit' else debitSide">
                                        {{totalAmountObj.openingBalance && totalAmountObj.openingBalance < 0 ? (totalAmountObj.openingBalance * -1 | currency:"INR":"symbol-narrow":'1.0-0') : (totalAmountObj.openingBalance | currency:"INR":"symbol-narrow":'1.0-0')}}
                                    </span>
                                </label>
                        </td>
                        <td>
                            <label class=" control-lable text-black text-bold">
                            <span class="text-success text-bold ml-4" style="display: inline-block; text-align: right;" *ngIf="totalAmountObj && totalAmountObj.openingBalanceType == 'credit' else creditSide">
                                {{totalAmountObj.openingBalance && totalAmountObj.openingBalance < 0 ? (totalAmountObj.openingBalance * -1 | currency:"INR":"symbol-narrow":'1.0-0') : (totalAmountObj.openingBalance | currency:"INR":"symbol-narrow":'1.0-0')}}
                            </span>
                        </label>
                    </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td><span style="font-weight: 600;padding-right: 15px;text-transform: uppercase;">Current Balance :</span></td>
                            <td> 
                                <label  class=" control-lable text-black text-bold">
                                    <span class="text-danger ml-4" *ngIf="totalAmountObj && totalAmountObj.debit else debitSide">
                                        {{totalAmountObj.debit | currency:"INR":"symbol-narrow":'1.0-0'}}
                                    </span>
                                </label>
                            </td>
                            <td> 
                                <label  class="control-lable text-black text-bold">
                                    <span class="text-success ml-4" *ngIf="totalAmountObj && totalAmountObj.credit else creditSide">
                                        {{totalAmountObj.credit | currency:"INR":"symbol-narrow":'1.0-0'}}
                                    </span>
                                </label>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td><span style="font-weight: 600;padding-right: 15px;text-transform: uppercase;">Closing Balance :</span></td>
                            <td> 
                                <label  class=" control-lable text-black text-bold">
                                    <span class="text-danger ml-4"  *ngIf="totalAmountObj && totalAmountObj.type == 'DR.';else debitSide">
                                        {{totalAmountObj.balance && totalAmountObj.balance < 0 ? (totalAmountObj.balance * -1 | currency:"INR":"symbol-narrow":'1.0-0') : (totalAmountObj.balance | currency:"INR":"symbol-narrow":'1.0-0')}}
                                    </span>
                                </label>
                            </td>
                            <td> 
                                <label  class=" control-lable text-black text-bold">
                                    <span class="text-success ml-4"  *ngIf="totalAmountObj && totalAmountObj.type == 'CR.';else creditSide">
                                        {{totalAmountObj.balance && totalAmountObj.balance < 0 ? (totalAmountObj.balance * -1 | currency:"INR":"symbol-narrow":'1.0-0') : (totalAmountObj.balance | currency:"INR":"symbol-narrow":'1.0-0')}}
                                    </span>
                                </label>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                    <!-- <div class="m-0">
                        <label class=" control-lable text-black text-bold">Opening Balance : 
                            <span class="text-pinkish-red text-bold ml-4" style="min-width: 78px;display: inline-block; text-align: right;" *ngIf="balanceArr.length > 0">{{openingBal | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                             <span class="text-pinkish-red text-bold ml-4" *ngIf="balanceArr.length == 0">{{openingBal | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                        </label>
                    </div>

                    <div  class="action-btn">
                        <label  class=" control-lable text-black text-bold">Current Balance :
                                <span class="text-danger ml-4" *ngIf="dataSource.length > 0">{{dataSource[dataSource.length - 1].balance | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                                <span class="text-success ml-4" *ngIf="dataSource.length == 0">{{0 | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                        </label>
                    </div>

                    <div  class="action-btn">
                        <label  class=" control-lable text-black text-bold">Closing Balance :
                                <span class="text-danger ml-4" *ngIf="dataSource.length > 0">{{dataSource[dataSource.length - 1].balance | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                                <span class="text-success ml-4" *ngIf="dataSource.length == 0">{{0 | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                        </label>
                    </div> -->
                    
                <!-- <div class="">
                    <label class="control-lable font-weight-bold text-right">Closing Balance : </label>
                    <label class="control-lable font-weight-bold lblHead" *ngIf="dataSource.length > 0">&#8377; {{dataSource[dataSource.length - 1].balance | number : '1.1-2'}}</label>
                    <label class="control-lable font-weight-bold lblHead" *ngIf="dataSource.length == 0">&#8377; {{0 | number : '1.1-2'}}</label>
                </div> -->
            </div>

         
            <!-- <div class="row pagination-div mt-3 d-flex justify-content-around">
                <pagination-controls (pageChange)="p = $event" *ngIf="dataSource != ''"></pagination-controls>
            </div> -->
        </div>
    </div>
</section><div id="snackbar"> {{snackBarMessage}}</div>


<ng-template #debitSide>
    <span class="text-danger text-bold ml-4" style="display: inline-block; text-align: right;">
        {{0 | currency:"INR":"symbol-narrow":'1.0-0'}}
    </span>
</ng-template>

<ng-template #creditSide>
    <span class="text-success text-bold ml-4" style="display: inline-block; text-align: right;">
       {{0 | currency:"INR":"symbol-narrow":'1.0-0'}}
    </span>
</ng-template>

