import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AffiliateService } from 'src/app/services/affiliate.service';

@Component({
	selector: 'app-update-password',
	templateUrl: './update-password.component.html',
	styleUrls: ['./update-password.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class UpdatePasswordComponent implements OnInit {
	resetPassword: any;
	token;
	successMsg;
	errorMsg;
	confirmMsg;

	constructor(private route: ActivatedRoute, private fb: FormBuilder, private airAffiliate: AffiliateService,
		private router: Router) { }

	ngOnInit() {
		this.resetPassword = this.fb.group({
			currentPass: ["", Validators.required],
			newPass: ["", Validators.required]
		});

		this.route.queryParams.subscribe(params => {
			this.token = params['token'];
		});
	}

	get currentPass() {
		return this.resetPassword.controls.currentPass;
	}
	get newPass() {
		return this.resetPassword.controls.newPass;
	}
	changePassword(values) {
		var obj = {
			"token": this.token,
			"password": values.currentPass,
		}
		if (values.newPass != values.currentPass) {
			this.showMsg();
			return false;
		}
		this.airAffiliate.changePassword(obj).subscribe(
			res => {
				console.log(res);
				// this.closeModal();
				this.showSuccess();
				this.resetPassword.reset();
				const localThis = this;
				setTimeout(() => {
				}, 3000)
			},
			err => {
				this.showError();
			}
		);
	}


	showSuccess() {
		window.scrollTo(0, 0);
		this.successMsg = true;
		setTimeout(() => {
			this.router.navigate(['/', 'login']);
			this.successMsg = false;
		}, 3000)
	}

	showError() {
		window.scrollTo(0, 0);
		this.errorMsg = true;
		setTimeout(() => {
			this.errorMsg = false;
		}, 3000)
	}

	showMsg() {
		window.scrollTo(0, 0);
		this.confirmMsg = true;
		setTimeout(() => {
			this.confirmMsg = false;
		}, 3000)
	}
}
