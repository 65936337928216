import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { environmentConstant } from "../Utility/environmentConstant";
import { MatDialog } from "@angular/material";
import { ForgetPasswordComponent } from "../Common/forget-password/forget-password.component";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { LoginService } from "../services/login.service";
import { PostService } from "../services/post.service";
import { UrlService } from "../services/url.service";
import { CommonService } from "../services/common.service";
import { environment } from "src/environments/environment";
import { WebsocketService } from "../services/websocket.service";
// import { RecaptchaErrorParameters } from "ng-recaptcha";
import * as OwlCarousel from "ngx-owl-carousel-o";
import { resetApplicationState } from "@angular/core/src/render3/instructions";
import { ResourceLoader } from "@angular/compiler";
import { error } from "console";
import { AffiliateService } from "../services/affiliate.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  homeSlider: any = environmentConstant.homePageSlider;
  flightSlider: any = environmentConstant.flightSlider;
  loginForm: FormGroup;
  hide;
  isLogin: boolean = false;
  isOtpModal: boolean = false;
  isOTResend: boolean = false;
  isOTPVerfing: boolean = false;
  emailNotFound: boolean = false;
  pwdNotFound: boolean = false;
  EmulationErrorMsg: any;
  EmulationError: boolean = false;
  redirecUrl: any;
  dataofRegisterForm: any;
  siteKey: string;
  @ViewChild("otpModal") otpModal: ElementRef;
  @ViewChild("closeOtpModal") closeOtpModal: ElementRef;
  userInfo: any;
  loginToken: any;
  otpForm: FormGroup;
  respMessage: any;
  respType: string;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder,
    private service: PostService,
    private url: UrlService,
    private loginService: LoginService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private affiliateService: AffiliateService
  ) {
    this.siteKey = environment.reCaptchaSiteKey;
  }

  ngOnInit() {
    if (localStorage.isLoaded == undefined) {
      localStorage.isLoaded = true;
      window.location.reload();
    }

    this.loginForm = this.fb.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
    });
    this.otpForm = this.fb.group({
      otp: ["", Validators.required],
    });

    this.commonService.showLogout(false);
    var url = window.location.href.slice(window.location.href.indexOf("?") + 1);
    this.redirecUrl = this.activatedRoute.snapshot.params["redirectTo"];
    let sectionId = this.activatedRoute.snapshot.params["sectionId"];
    if (sectionId) {
      if (document.querySelector(`#${sectionId}`)) {
        document.querySelector(`#${sectionId}`).scrollIntoView();
      }
    }
    this.doEmulateLogin(url);
  }

  get email() {
    return this.loginForm.controls.email;
  }
  get password() {
    return this.loginForm.controls.password;
  }
  forgetPassword() {
    const dialogRef = this.dialog.open(ForgetPasswordComponent, {
      width: "auto",
      data: {},
    });
  }
  submit(values, isReSendOtp) {
    this.isLogin = true;
    this.otpForm.reset();
    values["isReSendOtp"] = isReSendOtp;
    this.isOTResend = false;
    this.respType = "success";
    this.respMessage = "";
    this.service.postMethod(`${environment.adminUrl}/login`, values).subscribe(
      (data) => {
        // let jsonData = JSON.parse(data['_body']);
        //  ;
        let jsonData = data;
        this.userInfo = jsonData["userInfo"];
        this.loginToken = jsonData["token"];
        if (jsonData["event"] == "validate_otp" && this.otpModal) {
          if (!isReSendOtp) {
            this.otpModal.nativeElement.click();
          } else {
            this.respType = "success";
            this.respMessage = "OTP resent.";
          }
          return false;
        }
        localStorage.setItem("access_token", jsonData["token"]);
        localStorage.setItem('systacc_access_token', jsonData["systaccToken"]);
        if(jsonData.hasOwnProperty('importPnrAccess')) {
          localStorage.setItem('import_pnr_access', jsonData['importPnrAccess']);
        }
        localStorage.setItem(
          "user_details",
          JSON.stringify(jsonData["userInfo"])
        );
        console.log(jsonData);
        this.commonService.showLogout(true);
        this.commonService.setDiAccountStatus(
          jsonData["userInfo"]["is_di_account"]
        );
        // this.router.navigate(['pages/dashboard']);
        localStorage.setItem("showFlyer", "true");
        if (
          this.redirecUrl &&
          this.redirecUrl != null &&
          !this.redirecUrl.includes("traveller")
        ) {
          if (this.redirecUrl.includes("http")) {
            window.location.href = this.redirecUrl;
          } else {
            this.router.navigate([this.redirecUrl]);
          }
        } else {
          if (localStorage.getItem("reload") != undefined) {
            let route = localStorage.getItem("reload");
            localStorage.removeItem("reload");
            if (route.includes("/booking-summary-detail")) {
              let searchId = route.split(";searchId=")[1];
              this.router.navigate(["/booking-summary-detail", { searchId }]);
            }
            // this.router.navigate([route]);
          } else {
            this.router.navigate(["pages/dashboard"]);
            // window.location.href = 'pages/dashboard'
          }
        }
      },
      (error) => {
        this.isLogin = false;
        var msg = error.error;
        if (msg.err == "email not found") {
          this.emailNotFound = true;
        }
        if (msg.err == "password is not correct") {
          this.pwdNotFound = true;
        }
        console.log(error);
      }
    );
    console.log(values);
  }

  validateOtp() {
    let reqObj = {
      otp: this.otpForm.value.otp,
      token: this.loginToken,
      email: this.userInfo.primaryEmail,
    };
    this.isOTPVerfing = true;
    this.respMessage = "";
    this.service
      .postMethod(`${environment.adminUrl}/login/validate`, reqObj)
      .subscribe(
        (data) => {
          // let jsonData = JSON.parse(data['_body']);
          //  ;
          this.respType = "success";
          this.closeOtpModal.nativeElement.click();
          this.isOTPVerfing = false;
          let jsonData = data;
          localStorage.setItem("access_token", jsonData["token"]);
          localStorage.setItem('systacc_access_token', jsonData["systaccToken"]);
          localStorage.setItem(
            "user_details",
            JSON.stringify(jsonData["userInfo"])
          );
          console.log(jsonData);
          this.commonService.showLogout(true);
          this.commonService.setDiAccountStatus(
            jsonData["userInfo"]["is_di_account"]
          );
          // this.router.navigate(['pages/dashboard']);
          localStorage.setItem("showFlyer", "true");
          if (
            this.redirecUrl &&
            this.redirecUrl != null &&
            !this.redirecUrl.includes("traveller")
          ) {
            if (this.redirecUrl.includes("http")) {
              window.location.href = this.redirecUrl;
            } else {
              this.router.navigate([this.redirecUrl]);
            }
          } else {
            if (localStorage.getItem("reload") != undefined) {
              let route = localStorage.getItem("reload");
              localStorage.removeItem("reload");
              if (route.includes("/booking-summary-detail")) {
                let searchId = route.split(";searchId=")[1];
                this.router.navigate(["/booking-summary-detail", { searchId }]);
              }
              // this.router.navigate([route]);
            } else {
              this.router.navigate(["pages/dashboard"]);
              // window.location.href = 'pages/dashboard'
            }
          }
        },
        (error) => {
          this.isOTPVerfing = false;
          this.isLogin = false;
          var msg = error.error;
          this.respType = "error";
          this.respMessage = "";
          if (msg.err) {
            this.respMessage = msg.err;
          }
        }
      );
  }

  removeError() {
    this.emailNotFound = false;
    this.pwdNotFound = false;
  }

  doEmulateLogin(url) {
    if (url.includes("key=")) {
      this.commonService.toggleLoader(true);
      this.activatedRoute.queryParams.subscribe((params) => {
        let flag = false;
        if (
          params["employeeCode"] == undefined ||
          params["employeeCode"] == null ||
          params["employeeCode"] == ""
        ) {
          flag = true;
        }
        if (
          params["reason"] == undefined ||
          params["reason"] == null ||
          params["reason"] == ""
        ) {
          flag = true;
        }
        if (
          params["key"] == undefined ||
          params["key"] == null ||
          params["key"] == ""
        ) {
          flag = true;
        }

        if (!flag) {
          this.service
            .postMethod(`${environment.adminUrl}/emulateLogin`, params)
            .subscribe(
              (data) => {
                let jsonData = data;
                localStorage.setItem("access_token", jsonData["token"]);
                localStorage.setItem(
                  "user_details",
                  JSON.stringify(jsonData["userInfo"])
                );
                console.log(jsonData);
                this.commonService.showLogout(true);
                this.commonService.setDiAccountStatus(
                  jsonData["userInfo"]["is_di_account"]
                );
                if (params.hasOwnProperty("reference_no")) {
                  let bookingId = params["reference_no"];
                  this.router.navigate(["/pages/re-attempt", { bookingId }]);
                } else {
                  this.commonService.toggleLoader(false);
                  this.router.navigate(["pages/dashboard"]);
                }
              },
              (error) => {
                this.commonService.toggleLoader(false);
                var msg = error.error;
                if (msg != undefined) {
                  if (msg.err == "email not found") {
                    this.emailNotFound = true;
                  }
                  if (msg.err == "password is not correct") {
                    this.pwdNotFound = true;
                  } else {
                    this.EmulationErrorMsg = msg.err;
                    this.EmulationError = true;
                  }
                }
                console.log(error);
              }
            );
        } else {
          this.commonService.toggleLoader(false);
        }
      });
    }
  }
  // Registeration form data
  registerFormData = new FormGroup({
    selTitle: new FormControl("", Validators.required),
    txtFirstName: new FormControl("", [
      Validators.required,
      Validators.pattern("^[A-Za-z]+([A-Za-z]+)*"),
    ]),
    txtLastName: new FormControl("", [
      Validators.required,
      Validators.pattern("^[A-Za-z]+([A-Za-z]+)*"),
    ]),
    txtMobile: new FormControl("", [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(16),
      Validators.pattern("^[0-9]*"),
    ]),
    txtPrimaryEmail: new FormControl("", [
      Validators.required
    ]),
    txtCompanyName: new FormControl("", [Validators.required]),
    recaptcha: new FormControl("", [Validators.required]),
  });

  onRegisterSubmit() {
    this.dataofRegisterForm = null;

    this.affiliateService
      .createAffiliate(this.registerFormData.value)
      .subscribe(
        (res) => {
          if (res) {
            this.dataofRegisterForm = res;
            this.registerFormData.reset();
            // alert(this.dataofRegisterForm.message) //this is registration message response
          }
        },
        (err) => {
          this.dataofRegisterForm = err.error;
        }
      );
  }

  get txtFirstName() {
    return this.registerFormData.controls.txtFirstName;
  }
  get txtLastName() {
    return this.registerFormData.controls.txtLastName;
  }
  get Regemail() {
    return this.registerFormData.controls.txtPrimaryEmail;
  }
  get txtMobile() {
    return this.registerFormData.controls.txtMobile;
  }

  // main banners slider
  mainBanners = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: true,
    autoHeight: false,
    // autoplayHoverPause: true,
    autoplay: true,
    navSpeed: 300,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1,
      },
      //   400: {
      // 	items: 2
      //   },
      //   740: {
      // 	items: 3
      //   },
      //   940: {
      // 	items: 4
      //   }
    },
  };
  mainBannersSlides = [
    {id: 19, img: "assets/v3images/login-banners/akasa-doha-banner.jpg"},
    {id: 18, img: "assets/v3images/login-banners/Vistara-flight-Banner.jpg"},
    {id: 17, img: "assets/v3images/login-banners/Qatar-Valentine-Banner.jpg"},
    {id: 16, img: "assets/v3images/login-banners/star-airline-india.jpg"},
    {id: 15, img: "assets/v3images/login-banners/anniversary-banner.jpg"},
    {id: 1, img: "assets/v3images/login-banners/vistara-chrismas-banner.jpg"},
    {id: 2, img: "assets/v3images/login-banners/diu-banner.jpg"},
    {id: 3, img: "assets/v3images/login-banners/airarabia-ras.jpg"},
    {id: 4, img: "assets/v3images/login-banners/Kenya-Airways-Banner.jpg"},
    {id: 5, img: "assets/v3images/login-banners/air-asia-surat.jpg"},
    {id: 6, img: "assets/v3images/login-banners/airarabia-abudhabi.jpg"},
    {id: 7, img: "assets/v3images/login-banners/vistara-reward-banner.jpg"},
    {id: 8, img: "assets/v3images/login-banners/StarAirline.jpg"},
    {id: 9, img: "assets/v3images/login-banners/Indigo_to_amsterdam.png"},
    {id: 10, img: "assets/v3images/login-banners/Banner.png" },
		{id: 11, img: "assets/v3images/login-banners/kenya-airways-banner.jpg"},
		{id: 12, img: "assets/v3images/login-banners/virtual-acc-banner.svg"},
    {id: 13, img: "assets/v3images/login-banners/aadesh-indigo-flyer.jpg"},
    {id: 14, img: "assets/v3images/login-banners/whatsapp-banner-aadesh.jpg"},
   
	 ];

  // testimonial slider
  testimonialSlider = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    // nav: false,
    // autoplayHoverPause: true,
    autoplay: true,
    navSpeed: 700,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      1400: {
        items: 3,
      },
    },
  };
  testimonialSlides = [
    {
      id: 1,
      pic: "../../assets/v3images/client1.png",
      name: "Harish Sharma",
      designation: "CEO at opskube",
      comment:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sed eos quas neque distinctio nulla quam voluptatibus eum assumenda culpa sunt natus cupiditate architecto, nesciunt aliquid similique nisi eaque. Dolore, enim.",
    },
    {
      id: 2,
      pic: "../../assets/v3images/client2.png",
      name: "Prince Verma",
      designation: "SR. UI ux designer at opskube",
      comment:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sed eos quas neque distinctio nulla quam voluptatibus eum assumenda culpa sunt natus cupiditate architecto, nesciunt aliquid similique nisi eaque. Dolore, enim.",
    },
  ];

  resendOTP() {
    this.isOTResend = true;
  }
  verifyOTP() {
    this.isOTPVerfing = true;
  }
}
