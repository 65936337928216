import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-total-traveller-fare',
  templateUrl: './total-traveller-fare.component.html',
  styleUrls: ['./total-traveller-fare.component.scss']
})
export class TotalTravellerFareComponent implements OnInit {

  @Input() totalPayable;
  @Input() oldTtotalNetPayable;
  @Input() isIncreasedPrice;
  @Input() reIssueData;
  @Input() isReissue;
  @Input() groupfareFoundStatus;
  constructor() { }

  ngOnInit() {
  }

}
