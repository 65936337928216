
<div class="container" *ngIf="valid">
    <div class="row">
        <div class="address col-12 float-right">
            <span class="cname">Aadesh Travels Pvt. Ltd.</span>
            <span class="c-address">Destination Plus, 3rd Floor, Amin Marg, Rajkot-360001</span>
            <span class="t-num">
                <span>Tel: </span>+91-0281-2476382
            </span>
            <span class="cin">
                <span>CIN: </span>TFD566433VDG4
            </span>
            <span class="gstin">
                <span>GSTIN: </span>24AATR4533DDS (Gujarat)
            </span>
        </div>
    </div>
    <!-- row -->
    <h3 class="receipt-head mb-3" *ngIf=" receiptType == 'RW'">
        Receipt Voucher<br>
        <!-- (Deposit Request) -->
    </h3>
    <h3 class="receipt-head mb-3" 
    *ngIf=" 
    receiptType == 'IS' ||
    receiptType == 'PY' ||
    receiptType == 'IR' ||
    receiptType == 'AD' ||
    receiptType == 'MS' ||
    receiptType == 'IM'">
        Offine Payment<br>
        <!-- (Deposit Request) -->
    </h3>
    <h3 class="receipt-head mb-3" *ngIf="receiptType == 'JW'">
        Journal Voucher<br>
        ({{receiptSubType}} Note)
    </h3>
    <h3 class="receipt-head mb-3" *ngIf="receiptType == 'MI'">
        Journal Voucher<br>
        (Deposit Incentive)
    </h3>
    <h3 class="receipt-head mb-3" *ngIf="receiptType == 'GI'">
        Journal Voucher<br>
        (GST Input Claim)
    </h3>
    <h3 class="receipt-head mb-3" *ngIf="receiptType == 'IW'">
        Invoice Voucher<br>
        (Ticket)
    </h3>
    <h3 class="receipt-head mb-3" *ngIf="receiptType == 'IZ'">
        Credit Note<br>
        (Refund Request)
    </h3>
    <div class="mb-4">
        <!-- <span class="ac-no float-left col-4">
				<span>Bank Account NO :</span> Cash-Cash
			</span> -->
        <span class="rec-no text-left col-4">
            <span>VOUCHER NO :</span>{{transactionRefNo}}
        </span>
        <span class="rec-date float-right text-right offset-4 col-4">
            <span>TRANSACTION DATE :</span>{{date}}
        </span>
    </div>
    <div class="row table-section">
        <table class="table table striped table-bordered">
            <tr>
                <th class="text-center">SR.</th>
                <th>Account Head / Description</th>
                <!-- <th class="text-center">Chq. No.</th>
					<th class="text-center">Chq. Date</th> -->
                <th class="text-right">(INR) Amount</th>
            </tr>
            <tr>
                <td class="text-center">1.</td>
                <td>{{accountHead}}
                    <span  *ngIf="
                        receiptType == 'RW' || 
                        receiptType == 'IS' ||
                        receiptType == 'PY' ||
                        receiptType == 'MI' ||
                        receiptType == 'AD' ||
                        receiptType == 'MS' ||
                        receiptType == 'IM'
                        ">{{affiliate_detail}}</span>
                    <span *ngIf="description != null">{{description}} </span><br>
                    <span *ngIf="narration_1 != null">{{narration_1}} </span><br>
                    <span *ngIf="narration_2 != null">{{narration_2}} </span><br>
                    <span *ngIf="narration_3 != null">{{narration_3}} </span><br>
                    <span *ngIf="narration_4 != null">{{narration_4}} </span><br>
                    <span *ngIf="narration_5 != null">{{narration_5}} </span><br>
                    <!-- <span>CASH BALANCE</span> -->
                </td>
                <!-- <td class="text-center"></td>
						<td class="text-center">2019-05-01</td> -->
                <td class="text-right">{{amount |currency:"INR":"symbol-narrow"}}</td>
            </tr>
            <tr>
                <td class="hilight text-right" colspan="2">Total</td>
                <!--Fix ME -->
                <td class="hilight text-right">{{amount |currency:"INR":"symbol-narrow"}}</td>
            </tr>
        </table>
        <span class="eoe float-left col-6">E & O E</span>
        <span class="for-name float-right col-6 text-right">For Aadesh Travels Pvt. Ltd.</span>
    </div>
</div>
 
    <div class="row m-0 justify-content-center align-items-baseline" *ngIf="!showBox">
        <div class="actionbtn">
            <a href="javascript:void(0)" class="btnPrint" (click)="print()">Print</a>
        </div>
       
    </div>
  
   