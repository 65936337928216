<div class="baggage-container">
  <div class="baggage-box">
    <div *ngFor="let item of ssrAvailability; let i = index;">
       <div *ngIf="i==0">
        <div class="row baggage-identifier">
            <div class="col-3">
                <span class="head text-left"><i class="fa fa-users mr-1" aria-hidden="true"></i>Travellers</span>
            </div>
            <div class="col-3">
                <span class="head"><span class="baggage-icon0"><i class="fa fa-suitcase mr-1" aria-hidden="true"></i></span>Baggage</span>
            </div>
            <div class="col-3">
                <span class="head"><span class="baggage-icon0"><i class="fa fa-suitcase mr-1" aria-hidden="true"></i></span>Addnl. PC</span>
            </div>
            <div class="col-3">
                <span class="head text-right"><i class="fa fa-check mr-1" aria-hidden="true"></i>Your Selection</span>
            </div>
        </div>
        <div class="align-items-start custom-border row" *ngFor="let passenger of item.travellers; let k = index;">
            <ng-container *ngIf="passenger.type != 'INF'">
                <div class="col-3 traveller-info">
                    <div class="">
                        <div class="traveller-name">{{passenger.title}} {{passenger.firstName}} {{passenger.lastName}}</div>
                    </div>
                </div>
                <div class="col-3 meal-select">
                    <div class="">
                        <select name="baggage" id="" (change)="changeBaggage($event.target.value, k)" style="margin-bottom: 2px;height: 28px;">
                            <option  value="-1">No Baggage</option>
                            <ng-container *ngFor="let baggage of item.baggages; let j=index">
                                <option [selected]="getSelected(i,k,baggage)" [value]="j"> {{baggage['code']}} | &#8377; {{baggage['totalFare']}} |  {{baggage.type}} {{baggage.name}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>
                <div class="col-3 meal-select" >
                    <div class="d-flex justify-content-around" *ngIf="passenger.additional_baggage !=undefined && passenger.additional_baggage">
                        <div class="text-center w-25">
                            <i *ngIf="passenger.additional_baggage_selected_count && passenger.additional_baggage_selected_count > 0" class="fa fa-solid fa-minus-circle" (click)="handleAdditionalSelect(false,passenger,k)"></i>
                        </div>
                        <div class="border border-radius-5 border-primary w-50 text-center">
                            {{passenger.additional_baggage_selected_count ? passenger.additional_baggage_selected_count : 0}} 
                            <ng-container *ngIf="passenger.additional_baggage_selected_count > 0">
                                (&#8377; {{passenger.additional_baggage.price * passenger.additional_baggage_selected_count}})
                            </ng-container>
                        </div>
                        <div class="text-center w-25">
                            <i *ngIf="passenger.additional_baggage.maxAllowed && (!passenger.additional_baggage_selected_count || passenger.additional_baggage_selected_count < passenger.additional_baggage.maxAllowed) " class="fa fa-solid fa-plus-circle" (click)="handleAdditionalSelect(true,passenger,k)"></i>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <ng-container *ngIf="passenger.baggage !=undefined">
                        <div class="selected-preview text-right">
                            <p style="font-size:11px"><i class="fa fa-suitcase mr-2 " ></i>
                                <span class="s-meal-name">{{passenger.baggage_code}} </span></p>
                                <span class="s-price">&#8377; {{passenger.baggageFare}}</span>
                        </div>
                    </ng-container>
                </div>

            </ng-container>
        </div>
       </div>
    </div>
  </div>
</div>