<div class="baggage-container">
  <div class="baggage-box">
    <div *ngFor="let item of ssrAvailability; let i = index;">
       <div *ngIf="i==0">
        <div class="row baggage-identifier">
            <div class="col">
                <span class="head text-left"><i class="fa fa-users mr-1" aria-hidden="true"></i>Travellers</span>
            </div>
            <div class="col">
                <span class="head"><span class="baggage-icon0"><i class="fa fa-suitcase mr-1" aria-hidden="true"></i></span>Ancillary</span>
            </div>
            <div class="col">
                <span class="head text-right"><i class="fa fa-check mr-1" aria-hidden="true"></i>Your Selection</span>
            </div>
        </div>
        <div class="custom-border  row ">
            <ng-container >
                <div class="col-3 " style="max-height: 200px;height: auto;overflow-y: scroll;">
                    <div class="" *ngFor="let passenger of item.travellers; let p = index;">
                        <ng-container  *ngIf="passenger.type != 'INF'">
                            <div class="traveller-name" [ngClass]="{'active': p == selectedPassenger}" (click)="selectPassenger(p)">{{passenger.title}} {{passenger.firstName}} {{passenger.lastName}}</div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-5 meal-select">
                    <div class="d-flex flex-dir-column" style="max-height: 200px;height: auto;overflow-y: scroll; word-break :break-all">
                        <ng-container *ngFor="let ancillary of item.ancillaries; let j = index" >
                          <div class="d-flex" style="border-bottom: 1px solid;">
                            <input style="margin-right: 10px; width: auto;" [checked]="getChecked(i,ancillary)" (change)="changeAncillary($event,j, selectedPassenger)" type="checkbox" id="{{selectedPassenger}}_{{ancillary.name}}_{{ancillary.type}}"/> 
                            <label for="{{selectedPassenger}}_{{ancillary.name}}_{{ancillary.type}}">
                              {{ancillary.type}} {{ancillary.name}} 
                              <strong>
                                &#8377; {{ancillary['totalFare']}}
                              </strong>
                            </label>
                          </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-4 meal-select">
                    <div 
                        class="" 
                        style="max-height: 200px;height: auto;overflow-y: scroll; word-break :break-all"
                        *ngIf="item.travellers[selectedPassenger] && item.travellers[selectedPassenger].selectedAncillary !=undefined">
                        <ng-container   *ngFor="let item of item.travellers[selectedPassenger].selectedAncillary" >
                            <label style="border-bottom: 1px solid;">
                                <ng-container *ngIf="item.name;else itemCode">
                                    {{item.name}} 
                                </ng-container>
                                <ng-template #itemCode>
                                    {{item.code}}
                                </ng-template>
                              </label>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
       </div>
    </div>
  </div>
</div>