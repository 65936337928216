<div class="container mt-4 mb-fix clearfix">
    <div class="header clearfix">
            <div class="logo" *ngIf="userDetail.logo_url != null">
                    <!-- <ng-container *ngIf="userDetail.logo_url != null">
                            <img *ngIf="userDetail.logo_url != null" [src]="userDetail.logo_url" alt="">
                            <span class="aff-name-alt" *ngIf="userDetail.logo_url == null">{{userDetail?.company_name}}</span>
                    </ng-container> -->
                    <img [src]="userDetail.logo_url" alt=""> 
                </div>

                <div class="company-name-header" *ngIf="userDetail.logo_url != null">
                    <span class="aff-name-alt">{{userDetail?.company_name}}</span>
                            <span class="aff-email">{{userDetail?.primary_email}}</span>
                            <span class="aff-phone">{{userDetail?.mobile}}</span>                    
                </div>

                <div class="company-name-header-full" *ngIf="userDetail.logo_url == null">
                    <span class="aff-name-alt-full">{{userDetail?.company_name}}</span>
                            <span class="aff-email">{{userDetail?.primary_email}}</span>
                            <span class="aff-phone">{{userDetail?.mobile}}</span>                   
                </div>
        
                <div class="book-detail">
                    <div class="booking-id">Booking ID <span>{{bookingArr?.booking_ref}}</span></div>
                    <div class="booking-date">Booking Date <span>{{bookingArr?.booked_timestamp | date : 'dd/MM/yyyy'}}</span></div>
                </div>
        <!-- <div class="clearfix"></div> -->
    </div>

    <ng-container *ngFor="let segments of bookingArr.segments">
        <div class="ticket-section col-12 pl-0 pr-0" *ngFor="let item of segments['legs']">
            <div class="route-details col-8 pl-0 pr-0">
                <div class="departs">
                    <table class="tg">
                        <tr class="field-info">
                            <td class="depart-field">Departs</td>
                            <td class="date">Date</td>
                            <td class="time">Time</td>
                            <td class="duration">Duration/Stop</td>
                            <td class="baggage">Baggage</td>
                            <td class="terminal">Terminal</td>
                        </tr>
                        <tr class="hilight">
                            <td class="depart-field"><span class="airport-origin">{{item?.origin_airport_city}} {{item?.origin_airport_country}} ({{item?.origin_airport_code}})</span><span class="airport-name">{{item?.origin_airport_name}}</span></td>
                            <td class="date">{{item?.origin_timestamp | dateFormat }}</td>
                            <td class="time">{{item?.origin_timestamp | timeFormat }}</td>
                            <td class="duration">{{item?.duration}}</td>
                            <td class="baggage"><span>{{item?.freeCheckInBaggage?.unit}}&nbsp;{{item?.freeCheckInBaggage?.unitTypeCode}} </span>&nbsp;</td>
                            <td class="terminal">{{item?.origin_terminal}}</td>
                        </tr>

                    </table>
                </div>
                <div class="arrive">
                    <table class="tg">
                        <tr class="arrival">
                            <td class="arrive-field">Arrive</td>
                            <td class="date">Date</td>
                            <td class="time">Time</td>
                            <td class="duration">Duration/Stop</td>
                            <td class="baggage">Baggage</td>
                            <td class="terminal">Terminal</td>
                        </tr>
                        <tr class="f-details">
                            <td class="arrive-field"><span class="airport-dest">{{item?.destination_airport_city}} {{item?.destination_airport_country}} ({{item?.destination_airport_code}})</span><span class="airport-name">{{item?.destination_airport_name}}</span></td>
                            <td>{{item?.destination_timestamp | dateFormat }}</td>
                            <td>{{item?.destination_timestamp | timeFormat }}</td>
                            <td class="duration">{{item?.duration}}</td>
                            <td class="baggage"><span>{{item?.freeCheckInBaggage?.unit}}&nbsp;{{item?.freeCheckInBaggage?.unitTypeCode}} </span>&nbsp;</td>
                            <td class="terminal">{{item?.destination_terminal}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="flight-details col-4">
                <div class="flight-name-col">
                    <span class="flight-icon"><img src="assets/images/flight/{{item.operating_airline_code}}.png" /></span>
                    <span class="flight-name">{{item?.marketing_airline_name}}</span>
                </div>
                <div class="booking-info">
                    <div class="booking-head">Booking Reference (PNR)</div>
                    <div class="pnr-num" *ngIf="item.airline_pnr == undefined">{{segments?.pnr}}</div>
                    <div class="pnr-num" *ngIf="item.airline_pnr != undefined">{{item?.airline_pnr}}</div>
                    <div class="flight-heading float-left">FLIGHT NO.<span class="flight-num">{{item?.flight_number}}</span></div>
                    <div class="aircraft-heading float-left">AIRCRAFT TYPE
                            <span class="aircraft-type" *ngIf="item.aircraft_type == undefined && item.aircraft_type == null">N/A</span>
                            <span class="aircraft-type" *ngIf="item.aircraft_type != undefined && item.aircraft_type != nul" >{{item?.aircraft_type}}</span>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
        </div>
    </ng-container>

    <div class="passenger-section clearfix mt-3">
        <h3 class="head-style">Passenger Details</h3>
        <div class="passenger-caption clearfix">
            <span class="pass-field">Passenger Name</span>
            <span class="type-field">Type</span>
            <span class="status-field">Status</span>
            <span class="seat-field">Seat no.</span>
            <span class="addon-field">Add. on service</span>
            <!-- <span class="aadhar-field">Aadhar no. #</span> -->
            <span class="eticket-field">E-Ticket #</span>
            <span class="ff-field">FF #</span>
        </div>

        <ng-container *ngFor="let traveller of bookingArr.travellers; let i =index;">
            <div class="passenger-details clearfix" *ngIf="traveller.status== 'TICKETED' || traveller.status== undefined">
                <span class="pass-field">{{traveller?.title}} {{traveller?.first_name}} {{traveller?.last_name}}</span>
                <span class="type-field"><span *ngIf="traveller.type == 'ADT'">ADULT</span><span *ngIf="traveller.type == 'CHD'">CHILD</span><span *ngIf="traveller.type == 'INF'">INFANT</span></span>
                <span class="status-field">{{traveller?.ticket?.status}}</span>
                <span class="seat-field" *ngIf="traveller?.ssr!=null">{{traveller?.ssr[0]?.seats?.name}}</span>
                <span class="seat-field" *ngIf="traveller?.ssr==null">N/A</span>
                <span class="addon-field" *ngIf="traveller?.ssr!=null"><span *ngIf="traveller?.ssr[0].baggages!=null"><i class="fa fa-suitcase mr-1" *ngIf="hasBaggage" aria-hidden="true"></i>{{traveller?.ssr[0]?.baggages?.name}}</span>
                <span class="meal-icon" *ngIf="traveller?.ssr!=null && traveller?.ssr[0]?.meals != null">
					<i class="fa fa-cutlery mr-1" *ngIf="hasMeal" aria-hidden="true"></i><span>{{traveller?.ssr[0]?.meals?.code?.code}}</span></span>
                </span>
                <span class="addon-field" *ngIf="traveller?.ssr==null">N/A</span>
                <!-- <span class="aadhar-field">N/A</span> -->
                <span class="eticket-field" *ngIf="bookingArr.segments[0]['legs'][0]['marketing_airline_code'] == 'SG' ||
                    bookingArr.segments[0]['legs'][0]['marketing_airline_code'] == '6E' ||
                    bookingArr.segments[0]['legs'][0]['marketing_airline_code'] == 'G8'">{{ bookingArr?.segments[0]?.pnr}}</span>
                <span class="eticket-field" *ngIf="bookingArr.segments[0]['legs'][0]['marketing_airline_code'] != 'SG' &&
                    bookingArr.segments[0]['legs'][0]['marketing_airline_code'] != '6E' &&
                    bookingArr.segments[0]['legs'][0]['marketing_airline_code'] != 'G8'">{{getTicket(traveller?.ticket?.number)}}</span>
                <span class="ff-field">N/A</span>
            </div>
        </ng-container>
        <!--
		    <div class="addons"><span class="addon-i"><span><img src="../../../../assets/images/meal.png"/></span>Meals</span><span class="addon-i"><span><img src="../../../../assets/images/wheelchair.png"/></span>Wheelchairs</span>
        </div>
	   -->
        <div class="addons" *ngIf="ticketArr.length > 0 ">
            <div class="meals-info" *ngIf="hasMeal">
                <span class="addon-i">
                <i class="fa fa-cutlery" aria-hidden="true"></i>Meals -
                <ng-container *ngFor="let pax of bookingArr.travellers; let i=index">
                  <ng-container *ngIf="pax?.ssr != null && pax?.ssr !=  ''">
                    <ng-container *ngIf="pax?.ssr[0].meals != null && pax?.ssr[0].meals != ''">
                      <span class="meal-names"
                        *ngIf="pax?.ssr[0]?.meals?.code != '' && pax?.ssr[0]?.meals?.code != null">
                        ({{pax?.ssr[0]?.meals?.code?.code}}) {{pax?.ssr[0]?.meals?.code?.name}}&nbsp;•&nbsp;
                      </span>
                </ng-container>
                </ng-container>
                </ng-container>
                </span>
            </div>

            <div class="baggage-info mt-2" *ngIf="hasBaggage">
                <span class="addon-i">
                <i class="fa fa-suitcase" aria-hidden="true"></i>Baggage -
                <ng-container *ngFor="let pax of bookingArr.travellers; let i=index">
                  <ng-container *ngIf="pax?.ssr != null && pax?.ssr !=  ''">
                    <ng-container *ngIf="pax?.ssr[0].baggages != null && pax?.ssr[0].baggages != ''">
                      <span class="meal-names"
                        *ngIf="pax?.ssr[0]?.baggages?.code != '' && pax?.ssr[0]?.baggages?.code != null">
                        ({{pax?.ssr[0]?.baggages?.code?.code}}) {{pax?.ssr[0]?.baggages?.code?.name}}&nbsp;•&nbsp;
                      </span>
                </ng-container>
                </ng-container>
                </ng-container>
                </span>
            </div>

        </div>
    </div>

    

    <div class="clearfix"></div>
 <ng-container *ngIf="isMulticity">
        <ng-container *ngFor="let flight of this.multicityData">
                <ng-container *ngFor="let segments of flight.json_doc.segments">
                        <div class="ticket-section col-12 pl-0 pr-0" *ngFor="let item of segments['legs']">
                            <div class="route-details col-8 pl-0 pr-0">
                                <div class="departs">
                                    <table class="tg">
                                        <tr class="field-info">
                                            <td class="depart-field">Departs</td>
                                            <td class="date">Date</td>
                                            <td class="time">Time</td>
                                            <td class="duration">Duration/Stop</td>
                                            <td class="baggage">Baggage</td>
                                            <td class="terminal">Terminal</td>
                                        </tr>
                                        <tr class="hilight">
                                            <td class="depart-field"><span class="airport-origin">{{item?.origin_airport_city}} {{item?.origin_airport_country}} ({{item?.origin_airport_code}})</span><span class="airport-name">{{item?.origin_airport_name}}</span></td>
                                            <td class="date">{{item?.origin_timestamp | dateFormat }}</td>
                                            <td class="time">{{item?.origin_timestamp | timeFormat }}</td>
                                            <td class="duration">{{item?.duration}}</td>
                                            <td class="baggage"><span>{{item?.freeCheckInBaggage?.unit}}&nbsp;{{item?.freeCheckInBaggage?.unitTypeCode}} </span>&nbsp;</td>
                                            <td class="terminal">{{item?.origin_terminal}}</td>
                                        </tr>
                
                                    </table>
                                </div>
                                <div class="arrive">
                                    <table class="tg">
                                        <tr class="arrival">
                                            <td class="arrive-field">Arrive</td>
                                            <td class="date">Date</td>
                                            <td class="time">Time</td>
                                            <td class="duration">Duration/Stop</td>
                                            <td class="baggage">Baggage</td>
                                            <td class="terminal">Terminal</td>
                                        </tr>
                                        <tr class="f-details">
                                            <td class="arrive-field"><span class="airport-dest">{{item?.destination_airport_city}} {{item?.destination_airport_country}} ({{item?.destination_airport_code}})</span><span class="airport-name">{{item?.destination_airport_name}}</span></td>
                                            <td>{{item?.destination_timestamp | dateFormat }}</td>
                                            <td>{{item?.destination_timestamp | timeFormat }}</td>
                                            <td class="duration">{{item?.duration}}</td>
                                            <td class="baggage"><span>{{item?.freeCheckInBaggage?.unit}}&nbsp;{{item?.freeCheckInBaggage?.unitTypeCode}} </span>&nbsp;</td>
                                            <td class="terminal">{{item?.destination_terminal}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="flight-details col-4">
                                <div class="flight-name-col">
                                    <span class="flight-icon"><img src="assets/images/flight/{{item.operating_airline_code}}.png" /></span>
                                    <span class="flight-name">{{item?.marketing_airline_name}}</span>
                                </div>
                                <div class="booking-info">
                                    <div class="booking-head">Booking Reference (PNR)</div>
                                    <div class="pnr-num" *ngIf="item.airline_pnr == undefined">{{segments?.pnr}}</div>
                                    <div class="pnr-num" *ngIf="item.airline_pnr != undefined">{{item?.airline_pnr}}</div>
                                    <div class="flight-heading float-left">FLIGHT NO.<span class="flight-num">{{item?.flight_number}}</span></div>
                                    <div class="aircraft-heading float-left">AIRCRAFT TYPE
                                            <span class="aircraft-type" *ngIf="item.aircraft_type == undefined && item.aircraft_type == null">N/A</span>
                                            <span class="aircraft-type" *ngIf="item.aircraft_type != undefined && item.aircraft_type != nul" >{{item?.aircraft_type}}</span>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </ng-container>
                
                    <div class="passenger-section clearfix mt-3">
                        <h3 class="head-style">Passenger Details</h3>
                        <div class="passenger-caption clearfix">
                            <span class="pass-field">Passenger Name</span>
                            <span class="type-field">Type</span>
                            <span class="status-field">Status</span>
                            <span class="seat-field">Seat no.</span>
                            <span class="addon-field">Add. on service</span>
                            <!-- <span class="aadhar-field">Aadhar no. #</span> -->
                            <span class="eticket-field">E-Ticket #</span>
                            <span class="ff-field">FF #</span>
                        </div>
                
                        <ng-container *ngFor="let traveller of flight.json_doc.travellers; let i =index;">
                            <div class="passenger-details clearfix" *ngIf="traveller.status== 'TICKETED' || traveller.status== undefined">
                                <span class="pass-field">{{traveller?.title}} {{traveller?.first_name}} {{traveller?.last_name}}</span>
                                <span class="type-field"><span *ngIf="traveller.type == 'ADT'">ADULT</span><span *ngIf="traveller.type == 'CHD'">CHILD</span><span *ngIf="traveller.type == 'INF'">INFANT</span></span>
                                <span class="status-field">{{traveller?.ticket?.status}}</span>
                                <span class="seat-field" *ngIf="traveller?.ssr!=null">{{traveller?.ssr[0]?.seats?.name}}</span>
                                <span class="seat-field" *ngIf="traveller?.ssr==null">N/A</span>
                                <span class="addon-field" *ngIf="traveller?.ssr!=null"><span *ngIf="traveller?.ssr[0].baggages!=null"><i class="fa fa-suitcase mr-1" *ngIf="hasBaggage" aria-hidden="true"></i>{{traveller?.ssr[0]?.baggages?.name}}</span>
                                <span class="meal-icon" *ngIf="traveller?.ssr!=null && traveller?.ssr[0]?.meals != null">
                                    <i class="fa fa-cutlery mr-1" *ngIf="hasMeal" aria-hidden="true"></i><span>{{traveller?.ssr[0]?.meals?.code?.code}}</span></span>
                                </span>
                                <span class="addon-field" *ngIf="traveller?.ssr==null">N/A</span>
                                <!-- <span class="aadhar-field">N/A</span> -->
                                <span class="eticket-field" *ngIf="flight.json_doc.segments[0]['legs'][0]['marketing_airline_code'] == 'SG' ||
                                    flight.json_doc.segments[0]['legs'][0]['marketing_airline_code'] == '6E' ||
                                    flight.json_doc.segments[0]['legs'][0]['marketing_airline_code'] == 'G8'">{{ flight.json_doc?.segments[0]?.pnr}}</span>
                                <span class="eticket-field" *ngIf="flight.json_doc.segments[0]['legs'][0]['marketing_airline_code'] != 'SG' &&
                                    flight.json_doc.segments[0]['legs'][0]['marketing_airline_code'] != '6E' &&
                                    flight.json_doc.segments[0]['legs'][0]['marketing_airline_code'] != 'G8'">{{getTicket(traveller?.ticket?.number)}}</span>
                                <span class="ff-field">N/A</span>
                            </div>
                        </ng-container>
                        <!--
                            <div class="addons"><span class="addon-i"><span><img src="../../../../assets/images/meal.png"/></span>Meals</span><span class="addon-i"><span><img src="../../../../assets/images/wheelchair.png"/></span>Wheelchairs</span>
                        </div>
                       -->
                        <div class="addons" *ngIf="ticketArr.length > 0 ">
                            <div class="meals-info" *ngIf="hasMeal">
                                <span class="addon-i">
                                <i class="fa fa-cutlery" aria-hidden="true"></i>Meals -
                                <ng-container *ngFor="let pax of bookingArr.travellers; let i=index">
                                  <ng-container *ngIf="pax?.ssr != null && pax?.ssr !=  ''">
                                    <ng-container *ngIf="pax?.ssr[0].meals != null && pax?.ssr[0].meals != ''">
                                      <span class="meal-names"
                                        *ngIf="pax?.ssr[0]?.meals?.code != '' && pax?.ssr[0]?.meals?.code != null">
                                        ({{pax?.ssr[0]?.meals?.code?.code}}) {{pax?.ssr[0]?.meals?.code?.name}}&nbsp;•&nbsp;
                                      </span>
                                </ng-container>
                                </ng-container>
                                </ng-container>
                                </span>
                            </div>
                
                            <div class="baggage-info mt-2" *ngIf="hasBaggage">
                                <span class="addon-i">
                                <i class="fa fa-suitcase" aria-hidden="true"></i>Baggage -
                                <ng-container *ngFor="let pax of bookingArr.travellers; let i=index">
                                  <ng-container *ngIf="pax?.ssr != null && pax?.ssr !=  ''">
                                    <ng-container *ngIf="pax?.ssr[0].baggages != null && pax?.ssr[0].baggages != ''">
                                      <span class="meal-names"
                                        *ngIf="pax?.ssr[0]?.baggages?.code != '' && pax?.ssr[0]?.baggages?.code != null">
                                        ({{pax?.ssr[0]?.baggages?.code?.code}}) {{pax?.ssr[0]?.baggages?.code?.name}}&nbsp;•&nbsp;
                                      </span>
                                </ng-container>
                                </ng-container>
                                </ng-container>
                                </span>
                            </div>
                
                        </div>
                    </div>
        </ng-container>
 </ng-container>

    <!-- <div style='page-break-after:always;position: relative;display: block'>&nbsp;</div> -->

    <div class="gst-section clearfix mt-5 page-break" [ngStyle]="{'page-break-before':always}" *ngIf="bookingArr.gstDetail != null">
        <ng-container *ngIf="bookingArr.gstDetail.gstNumber != ''">
            <h3 class="head-style">GST Detail</h3>
            <div class="gstin"><span class="gst-num"><span>GST Number: </span>{{bookingArr?.gstDetail?.gstNumber}}</span>
            </div>
            <div class="company"><span class="company-name"><span>Company Name: </span>{{bookingArr?.gstDetail?.companyName}}</span>
            </div>
        </ng-container>
    </div>

    <div class="important-rules clearfix mt-4">
        <h3 class="head-style">Important Information</h3>
        <ul class="clearfix">
            <li>A Email copy of this e-ticket must be presented at the time of check in along with photo identification (ID) for EVERY passenger.
            </li>
            <li>Check-in starts 2 hours before scheduled departure, and closes 45 minutes prior to the departure time.</li>
            <li>Please check the terminal of departure for flights</li>
        </ul>
        <h3 class="head-style mt-4">Cancellation and Date Change Rule</h3>
        <ul class="clearfix">
            <li>If you are cancelling or rescheduling flight within 6 hours of its departure time, kindly contact the airline directly.</li>
            <li>If you have cancelled your booking with the airline directly, kindly inform us to get your refunds from us.</li>
            <li>If any flight is cancelled or rescheduled by the airline authority, passenger is requested to get a stamped/endorsed copy of the ticket to process the refund.</li>
        </ul>
    </div>

    <div class="aff-details clearfix">
        <h3 class="head-style">AFFILIATE DETAILS</h3>
        <!-- <span class="aff-name">{{userDetail?.first_name}} {{userDetail?.last_name}}</span> -->
        <span class="aff-name">{{userDetail?.company_name}}</span>
        <div class="aff-address float-left">
            <span class="add1">{{userDetail?.address1}},&nbsp;</span>
            <span class="add2">{{userDetail?.address2}},&nbsp;</span>
            <span class="a-city">{{userDetail?.city_name}},&nbsp;</span>
            <!-- <span class="a-name">{{userDetail?.company_name}}&nbsp;</span> -->
            <span class="a-state">{{userDetail?.state_name}}&nbsp;</span>
            <div class="d-block">
                <span class="a-country">{{userDetail?.country_name}}&nbsp;</span>
                <span class="a-zip">{{userDetail?.pincode}}</span>
            </div>

        </div>
        <div class="float-right">
            <span class="aff-email"><i class="fa fa-envelope" aria-hidden="true"></i>
				{{userDetail?.primary_email}}</span>
            <span class="aff-phone"><i class="fa fa-phone" aria-hidden="true"></i>
				{{userDetail?.mobile}}</span>
        </div>

    </div>
    <div class="custom-btn-style clearfix mt-5 printBtn d-flex justify-content-center">
        <a class="float-left" href="javascript:void(0)" (click)="printTicket()">PRINT</a>
    </div>
</div>