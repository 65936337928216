import { Injectable } from '@angular/core';
import { UrlService } from './url.service';
import { PostService } from './post.service';

@Injectable({
	providedIn: 'root'
})
export class LoginService {
	
	constructor(private url: UrlService, private postService: PostService) { }

	checkLogin(postJson) {
		return this.postService.postMethod('', postJson);
	}
}
