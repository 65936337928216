import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Input() isLogin;
  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  moveToSection(sectionId){
    if(sectionId == 'registration-section-header' && window.location && window.location.hash && !window.location.hash.includes('#/login')){
      this.router.navigateByUrl(`login;sectionId=${sectionId}`);
    }else{
      if(document.querySelector(`#${sectionId}`)){
        document.querySelector(`#${sectionId}`).scrollIntoView()
      }
    }
  }
}