import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { throwError, of, empty } from 'rxjs';
import {Http, Headers, ResponseContentType, RequestOptions} from '@angular/http';

import * as moment from 'moment-timezone';
import { UrlService } from './url.service';
import { NgRedux } from '@angular-redux/store';
import { InitialState } from '../store/reducer';
import { SetGlobalMessage } from '../store/commonStore/actions';
// import undefined = require('firebase/empty-import');

@Injectable({
	providedIn: 'root'
})
export class CommonService {

	constructor(private http: HttpClient,
		private httpClient : Http,
		private ngRedux: NgRedux<InitialState>,) { }

		isDiAccount: boolean;
		public DOMESTIC_CITY_CODE = ["AGR","AGX","AIP","AJL","AMD","ATQ","AYJ","BBI","BDQ","BEK","BEP","BHJ","BHO","BHU","BKB","BLR","BOM","BUP","CCJ","CCU","CDP","CJB","CNN","COK","DBR","DED","DEL","DGH","DHM","DIB","DIU","DMU","GAU","GAY","GBI","GOI","GOP","GOX","GWL","HBX","HJR","HYD","HSR","IDR","IMF","ISK","IXA","IXB","IXC","IXD","IXE","IXG","IXH","IXI","IXJ","IXK","IXL","IXM","IXN","IXP","IXQ","IXR","IXS","IXT","IXU","IXV","IXW","IXY","IXZ","JAI","JDH","JGA","JGB","JLG","JLR","JRG","JRH","JSA","KBK","KDK","KJB","KLH","KNU","KQH","KTU","KUU","LDA","LKO","LTU","LUH","MAA","MOH","MYQ","MZA","MZU","NAG","NDC","NMB","NVY","OMN","PAB","PAT","PBD","PGH","PNQ","PNY","PUT","PYB","PYG","QJU","QNB","QNF","RAJ","RDP","REW","RGH","RJA","RJI","RMD","RPR","RRK","RTC","RUP","RQY","SAG","SHL","SLV","SSE","STV","SXR","SXV","TCR","TEI","TEZ","TIR","TJV","TNI","TRV","TRZ","UDR","VDY","VGA","VNS","VTZ"]
	

	private accessSource = new BehaviorSubject([]);
	accessStatus = this.accessSource.asObservable();

	private accessSearchId = new BehaviorSubject([]);
	accessSearchIdStatus = this.accessSearchId.asObservable();

	private accessLoader = new BehaviorSubject(false);
	accessLoaderStatus = this.accessLoader.asObservable();

	private logOutSource = new BehaviorSubject(false);
	logOutStatus = this.logOutSource.asObservable();

	private addonSideBar = new BehaviorSubject(false);
	isAddonSideBarActive = this.addonSideBar.asObservable();
	


	private viewOfferSource = new BehaviorSubject(false);
	viewOfferStatus = this.viewOfferSource.asObservable();
	private groupSearchHistorySource = new BehaviorSubject({});
	groupSearchHistoryStatus = this.groupSearchHistorySource.asObservable();
	private viewMenuSource = new BehaviorSubject(false);
	viewMenuStatus = this.viewMenuSource.asObservable();
	private activeSearchSource = new BehaviorSubject(false);
	activeSearchData = this.activeSearchSource.asObservable();

	private clearSearchFlightFilterSource = new BehaviorSubject(false);
	clearSearchFlightFilterStatus = this.clearSearchFlightFilterSource.asObservable();

	private clearSearchFormSoruce = new BehaviorSubject(false);
	clearSearchFormSourceStatus = this.clearSearchFormSoruce.asObservable();

	private bookingDetailsSource = new BehaviorSubject([]);
	currentMessage = this.bookingDetailsSource.asObservable();

	private showFooter = new BehaviorSubject(2);
	showFooterValue = this.showFooter.asObservable();

	private selectSectorTab = new BehaviorSubject(0);
	selectSector = this.selectSectorTab.asObservable();

	private filterLoaderStatus = new BehaviorSubject(false);
	isFilterCompleted = this.filterLoaderStatus.asObservable();
	public static selectedFareType = '';


	setFilterStatus(status){
		this.filterLoaderStatus.next(status)
	}

	changeDetailsSource(data: any) {
		this.bookingDetailsSource.next(data)
	  }
	createAuthorizationHeader(headers: Headers) {
		let token = localStorage.getItem('access_token');
		headers.append('Authorization', token); 
	}


	clearSearchFormFields(status: boolean) {
		this.clearSearchFormSoruce.next(status)
	}

	showFooterDesign(value:any){
		this.showFooter.next(value)
	}

	moveSectorTab(ind){
		this.selectSectorTab.next(ind)
	}

	toggleAddonSideBar(value:any){
		this.addonSideBar.next(value)
	}

	insertFlightData(data) {
		this.accessSource.next(data)
	}

	sendFightSearchId(id) {
		this.accessSearchId.next(id)
	}

	showLogout(status: boolean) {
		this.logOutSource.next(status)
	}

	showViewOffer(status: boolean) {
		this.viewOfferSource.next(status)
	}
	setSearchHistory(data) {
		this.groupSearchHistorySource.next(data)
	}
	showMenu(status: boolean) {
		this.viewMenuSource.next(status)
	}
	setActiveSearch(status: any) {
		this.activeSearchSource.next(status)
	}

	clearSearchFilter(status: boolean) {
		this.clearSearchFlightFilterSource.next(status)
	}

	getDiAccountStatus() {
		return this.isDiAccount;
	}
	setDiAccountStatus(status: boolean) {
		this.isDiAccount = status;
	}

	toggleLoader(status: boolean) {
		if(status == false){
			setTimeout(() => {
				this.accessLoader.next(status)
			}, 2000);
		}else{
			this.accessLoader.next(status)
		}
	}

	getCurrentDateTime(){
		return moment().tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ss');
	}
	getCurrentDate(){
		return moment().tz('Asia/Kolkata').format('YYYY-MM-DD');
	}
	getNextDate(){
		return moment().add(1, "days").tz('Asia/Kolkata').format('YYYY-MM-DD');
	}

	makeSearchRequest(data, isReturn, direction) {
		let dType = "";
		if(data.isDestinationDomestic && data.isOriginDomestic) {
			dType = "SITI";
		}
		else if(data.isDestinationDomestic && !data.isOriginDomestic){
			dType = "SOTI";
		}
		else if(!data.isDestinationDomestic && data.isOriginDomestic) {
			dType = "SITO";
		}
		else if(!data.isDestinationDomestic && !data.isOriginDomestic) {
			dType = "SOTO";
		}

		let destinationObj = data.destinationObj
		let departureObj = data.departureObj

		let depDate = this.dateFormat(data.depDate);
		let arrDate = this.dateFormat(data.depDate);
		let specialRoundTrip = false;
		if(isReturn) {
			// depDate = this.dateFormat(data.returnDate);
			arrDate = this.dateFormat(data.returnDate);
			// departureObj = data.destinationObj
			// destinationObj = data.departureObj
			// isReturn = false;
		}
		if(direction == "SPECIALROUNDTRIP") {
			arrDate = this.dateFormat(data.returnDate);	
			isReturn = true;	
			specialRoundTrip = true;	
		}

		let userData = JSON.parse(localStorage.getItem('user_details'));

		let request = {
			"currency": "INR",
			"isStudentFare": CommonService.selectedFareType == 'STUD' ? true : false,
			"isSeniorCitizenFare": CommonService.selectedFareType == 'SRCT' ? true : false,
			"destinationDetails": destinationObj,
			"directFlightRequired": true,
			"domestic": data.isDomestic,
			"guestNationality": "indian",
			"journeyReturnDate":arrDate,
			"journeyStartDate": depDate,
			"noOfAdults": data.noOfAdult,
			"noOfChild": data.noOfChild,
			"noOfInfants": data.noOfInfant,
			"originDetails": departureObj,
			"affiliate":userData.affiliateId,
			"roundTrip": isReturn,
			"specialRoundTrip" : specialRoundTrip,
			"serviceClass": data.serviceClass,
			"uuid": "",
			"dType" : dType,
			"groupName" : userData.groupName,
			"isAffiliatePromocode" : data.isAffiliatePromocode ? data.isAffiliatePromocode : false ,
			"groupMetaInfo": {
				"groupId": userData.groupId,
				"groupName": userData.groupName,
			}
		}

		if(data.noOfStops || data.airline || data.refundable)  {
			request['advancedSearchFilters'] = {
				noOfStops : data.noOfStops ? data.noOfStops : null,
				airline : data.airline ? data.airline : null,
				airlineName : data.airlineName && data.airlineName['Airline Name'] ? data.airlineName['Airline Name'] : data.airlineName ? data.airlineName : null,
				refundable: data.refundable ? data.refundable : false
			}
		}

		return request;
	}

	multiCitySearchRequest(data) {
		let dType = "";
		if(data.isDestinationDomestic && data.isOriginDomestic) {
			dType = "SITI";
		}
		else if(data.isDestinationDomestic && !data.isOriginDomestic){
			dType = "SITO";
		}
		else if(!data.isDestinationDomestic && data.isOriginDomestic) {
			dType = "SOTI";
		}
		else if(!data.isDestinationDomestic && !data.isOriginDomestic) {
			dType = "SOTO";
		}
		
	
		let specialRoundTrip = false;
		let sectors = [];
		for(const sec of data['city']) {
			let obj2 = {};
			let originDetails =  {
				"code": sec['from']['airport_CODE'],
				"city": sec['from']['result']
			};

			let destinationDetails =  {
				"code": sec['to']['airport_CODE'],
				"city": sec['to']['result']
			};

			obj2['originDetails'] = originDetails;
			obj2['destinationDetails'] =destinationDetails;
			obj2['journeyReturnDate'] = this.dateFormat(sec.depDate);
			obj2['journeyStartDate'] = this.dateFormat(sec.depDate);
			sectors.push(obj2);
		}

		let userData = JSON.parse(localStorage.getItem('user_details'));

		let request = {
			"currency": "INR",
			"sectors": sectors,
			"directFlightRequired": true,
			"domestic": data.isDomestic,
			"guestNationality": "indian",
			"noOfAdults": data.noOfAdult,
			"noOfChild": data.noOfChild,
			"noOfInfants": data.noOfInfant,
			"affiliate":userData.affiliateId,
			"roundTrip": false,
			"specialRoundTrip" : specialRoundTrip,
			"serviceClass": data.city[0].serviceClass,
			"uuid": "",
			"dType" : dType,
			"groupName" : userData.groupName,
			"isAffiliatePromocode" : data.isAffiliatePromocode ? data.isAffiliatePromocode : false ,
			"groupMetaInfo": {
				"groupId": userData.groupId,
				"groupName": userData.groupName,
			}
		}
		;
		return request;
	}


	getInsurancePackage(url){
		return this.http.get(`${environment.digit_url}/${url}`)
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}


	getOkount(url) {
		this.toggleLoader(true)
		return this.httpClient.get(`${environment.accountUrl}/${url}`)
			.pipe(map(res => {
				this.toggleLoader(false)
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false)
				return throwError(err);
		}))
		
	}

	getv3ocount(url){
		this.toggleLoader(true)
		return this.http.get(`${environment.v3accountUrl}/${url}`)
			.pipe(map(res => {
				this.toggleLoader(false)
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false)
				return throwError(err);
		}))
	}

	downloadOkountPDF(url){
		let access_token = localStorage.getItem('systacc_access_token')
		this.toggleLoader(true)

		const headers = new Headers({ 
			'Content-Type': 'application/json',
			'Accept' : 'application/pdf',
			'Authorization': access_token
		})

		let options = new RequestOptions({ headers: headers });
		options.responseType = ResponseContentType.Blob;

		return this.httpClient.get(`${environment.v3accountUrl}/${url}`, options)
			.pipe(map(res => {
				this.toggleLoader(false)
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false)
				return throwError(err);
			}))
	}

	dateFormat(value){
		if(value != "" && value != undefined){
			// let now = moment().tz('Asia/Kolkata')// add this 4of 4
			let dateValue = moment(value).format('MM/DD/YYYY');
			return dateValue;
		}
		else{
			return "";
		}		
	}

	getValue(url) {
		let token = localStorage.getItem('access_token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': token
		});
		this.toggleLoader(true);
		return this.httpClient.get(`${environment.adminUrl}/${url}`)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}

	getGeneralLedger(url) {
		let token = localStorage.getItem('access_token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': token
		});
		this.toggleLoader(true);
		return this.http.get(`${environment.adminUrl}/${url}`)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}

	getAffiliateValue(url) {
		let token = localStorage.getItem('access_token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': token
		});
		return this.http.get(`${environment.adminUrl}/${url}`)
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}

	getAffiliateValueWithLoader(url) {
		this.toggleLoader(true);
		let token = localStorage.getItem('access_token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': token
		});
		return this.http.get(`${environment.adminUrl}/${url}`)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}

	
	putAdminCall(url,data) {
		let token = localStorage.getItem('access_token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': token
		});
		return this.http.put(`${environment.adminUrl}/${url}`,data)
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}
	getTravellerValue(url,data) {
		let token = localStorage.getItem('access_token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': token
		});
		this.toggleLoader(true);
		return this.http.get(`${environment.adminUrl}/${url}`,data)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}

	postValue(url, data) {
		this.toggleLoader(true);
		return this.http.post(`${environment.adminUrl}/${url}`, data)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}

	postFormDataImage(url, data) {
		this.toggleLoader(true);
		let token = localStorage.getItem('access_token');
		const headers = new Headers({
			'Authorization': token
		});
		let options = new RequestOptions({ headers: headers });

		return this.httpClient.post(`${environment.adminUrl}/${url}`, data, options)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}

	postAccountService(url, data) {
		this.toggleLoader(true);
		return this.http.post(`${environment.accountUrl}/${url}`, data)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}

	getAccountService(url) {
		this.toggleLoader(true);
		return this.http.get(`${environment.accountUrl}/${url}`)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}

	getPreviewCreditNote(url,data) {
		this.toggleLoader(true);
		return this.http.post(`http://127.0.0.1:2228/${url}`,data)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}



	testpostValue(url, data) {
		this.toggleLoader(true);
		return this.http.post(`${environment.adminUrl}/${url}`, data)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}
	testgetValue(url,data) {
		let token = localStorage.getItem('access_token');
		// const headers = new HttpHeaders({
		// 	'Content-Type': 'application/json',
		// 	'Authorization': token
		// });
		this.toggleLoader(true);
		return this.http.get(`${environment.adminUrl}/${url}`,data)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}
	airPostValue(url, data) {
		// this.toggleLoader(true);
		return this.http.post(`${environment.url}/${url}`, data)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}


	airGetValue(url) {
		// this.toggleLoader(true);
		return this.http.get(`${environment.url}/${url}`)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
		}))
		// return this.httpClient.get(`${environment.IBEurl}/${url}`)
		// 	.pipe(map(res => {
		// 		return res;
		// 	}),
		// 	catchError((err) => {
		// 		return throwError(err);
		// 	}))
	}
	getBaggage(url, data) {
		// this.toggleLoader(true);
		return this.httpClient.post(`${environment.url}/${url}`, data)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}
	getSeat(url,data) {
		// this.toggleLoader(true);
		return this.http.post(`${environment.url}/${url}`,data)
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}
	getmeal(url,data) {
		// this.toggleLoader(true);
		return this.http.post(`${environment.url}/${url}`,data)
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}

	putValue(url, data) {
		this.toggleLoader(true);
		return this.http.put(`${environment.adminUrl}/${url}`, data)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}

	postMethod(url,data) {
		return this.http.post(`${url}`,data)
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
		}))
		
	}

	deleteMethod(url) {
		this.toggleLoader(true);
		return this.http.delete(url)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
		}))
		
	}

	onlinePayment(postData) {
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.httpClient.post(`${environment.ftCashPaymentUrl}/payment/makePayment`, postData, { headers: headers });
	}
	hdfcPayment(postData, url) {
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.httpClient.post(`${environment.hdfcPaymentUrl}/${url}`, postData, { headers: headers });
	}
	test(postData) {
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.httpClient.post(`${environment.ftCashPaymentUrl}/payment/test`, postData, { headers: headers });
	}
	returnDate(date) {
		if(date != null && date != undefined) {
			let d = new Date(date.split("+")[0]);
			return d.toDateString()
		}
		return "";

	}

	returnTime(date) {
		if(date != null && date != undefined) {
			let d = new Date(date.split("+")[0]);
			return (d.toTimeString().split(' ')[0]).split(':')[0]+":"+(d.toTimeString().split(' ')[0]).split(':')[1];
		}
		return "";
	}


	getTransactionPdf(url,data){
		let headers = new Headers({ 
			'Content-Type': 'application/json', 
			'Accept' : 'application/pdf'
		 });
		 
		 let options = new RequestOptions({ headers: headers });
		 options.responseType = ResponseContentType.Blob;
		// this.toggleLoader(true);
		return this.httpClient.post(`${url}`, data, options)
			.pipe(map(res => {
				// this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				// this.toggleLoader(false);
				return throwError(err);
			}))
	}

	postDownloadOkountPDF(url,data){
		let access_token = localStorage.getItem('systacc_access_token')
		let headers = new Headers({ 
			'Content-Type': 'application/json', 
			'Accept' : 'application/pdf',
			'Authorization': access_token
		 });
		 
		 let options = new RequestOptions({ headers: headers });
		 options.responseType = ResponseContentType.Blob;
		return this.httpClient.post(`${url}`, data, options)
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}

	timeDuration(dt2, dt1, returnType? : any) {
		dt1 = new Date(dt1);
		dt2 = new Date(dt2);
		var diff =(dt2.getTime() - dt1.getTime()) / 1000;
		diff /= 60;
		diff =  Math.abs(Math.round(diff));
		var hours = (diff / 60);
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		if (returnType == 'hrs') {
			return	""+rhours
		}
		else if (returnType == 'mins') {
			return	""+rminutes
		}
		else{
			return rhours +"h"+rminutes+"m";	
		}
	 }
	 getMinuteToHour(minute,returnType?:any){
		var hours = (minute / 60);
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		if (returnType == 'hrs') {
			return	""+rhours
		}
		else if (returnType == 'mins') {
			return	""+rminutes
		}
		else{
			return rhours +"h"+rminutes+"m";	
		}	
	 }
	timeDuration2(dt2, dt1) {
		dt1 = new Date(dt1);
		dt2 = new Date(dt2);
		var diff =(dt2.getTime() - dt1.getTime()) / 1000;
		diff /= 60;
		diff =  Math.abs(Math.round(diff));
		// diff =  Math.round(diff);
		var hours = (diff / 60);
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		return diff
		// return rhours +"."+rminutes;
	 }
	timeDurationforCancel(dt2, dt1) {
		dt1 = new Date(dt1);
		dt2 = new Date(dt2);
		var diff =(dt2.getTime() - dt1.getTime()) / 1000;
		diff /= 60;
		// diff =  Math.abs(Math.round(diff));
		diff =  Math.round(diff);
		var hours = (diff / 60);
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		return diff
		// return rhours +"."+rminutes;
	 }
	//  Accouting 
	getAccountValue(url) {
		this.toggleLoader(true);
		return this.http.get(`${environment.account}/${url}`)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}
	getAccountV2Value(url) {
		this.toggleLoader(true);
		return this.httpClient.get(`${environment.accountV2}/${url}`)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}
	getBooking(url) {
		this.toggleLoader(true);
		return this.httpClient.get(`${environment.booking}/${url}`)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
				catchError((err) => {
					this.toggleLoader(false);
					return throwError(err);
				}))
	}
	putBooking(url,data) {
		this.toggleLoader(true);
		return this.httpClient.put(`${environment.booking}/${url}`,data)
			.pipe(map(res => {
				this.toggleLoader(false)
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false)
				return throwError(err);
			}))
	}
	putApifBooking(url,data) {
		return this.httpClient.put(`${environment.apif_booking}/${url}`,data)
			.pipe(map(res => {
				this.toggleLoader(false)
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false)
				return throwError(err);
			}))
	}
	
	postBooking(url,data) {
		return this.httpClient.post(`${environment.booking}/${url}`,data)
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}

	getAffiliateDetailForTicket(url){
		// createAffliate/getAffiliate?userId=136
		return this.http.get(`${environment.adminUrl}/${url}`)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}

	getAirline(url) {
		return this.httpClient.get(`${environment.redisCommission}/${url}`)
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}
	
	postMail(data){
		return this.httpClient.post(`${environment.notisUrl}`, data)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}
	generatePDF(data){
		let headers = new Headers({ 
			'Content-Type': 'application/json', 
			'Accept' : 'application/pdf'
		 });
		 let options = new RequestOptions({ headers: headers });
		// Ensure you set the responseType to Blob.
		options.responseType = ResponseContentType.Blob;
		this.toggleLoader(true);
		return this.httpClient.post(`${environment.pdfGeneratorUrl}`, data, options)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}

	SMS(to, message) {
		return this.httpClient.get(`${environment.smsUrl}to=` + to + `&message=` + message)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}

	generateInvoiceNum(url, data) {
		return this.http.post(`${environment.url}/${url}`, data)
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}



	// 

	apiwGetRequest(url) {
		let token = localStorage.getItem('access_token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': token
		});
		this.toggleLoader(true);
		return this.http.get(`${environment.apif_url}/${url}`)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}

	apiwGetRequestWithQueryParams(url, queryPrams) {
		let token = localStorage.getItem('access_token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': token
		});
		this.toggleLoader(true);
		return this.http.get(`${environment.apif_url}/${url}`, {params : queryPrams})
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}

	apiwDeleteRequest(url) {
		let token = localStorage.getItem('access_token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': token
		});
		this.toggleLoader(true);
		return this.http.delete(`${environment.apif_url}/${url}`)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}

	apiwPutRequest(url, data) {
		let token = localStorage.getItem('access_token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': token
		});
		this.toggleLoader(true);
		return this.http.put(`${environment.apif_url}/${url}`, data)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}

	apiwPostRequest(url, data) {
		let token = localStorage.getItem('access_token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': token
		});
		this.toggleLoader(true);
		return this.http.post(`${environment.apif_url}/${url}`, data)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}
	getAffiliateSeries(url, data) {
		return this.http.post(`${environment.apif_url}/${url}`, data)
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}

	addAgentBalance(url, data) {
		let token = localStorage.getItem('access_token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': token
		});
		// https://apif.aadeshtravels.com/apiw/b2b-get-user/get-affiliate?xlCode=CS005D
		return this.http.post(`${environment.apif_account}/${url}`, data)
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}

	getB2BAffiliateLimit(url) {
		let token = localStorage.getItem('access_token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': token
		});
		// https://apif.aadeshtravels.com/apiw/b2b-get-user/get-affiliate?xlCode=CS005D
		this.toggleLoader(true);
		return this.http.get(`${environment.apif_account}/${url}`)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}

	getB2BAffiliateBalance(url) {
		let token = localStorage.getItem('access_token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': token
		});
		// https://apif.aadeshtravels.com/apiw/b2b-get-user/get-affiliate?xlCode=CS005D
		this.toggleLoader(true);
		return this.http.get(`${environment.apif_account}/${url}`)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}


	updateB2BAffiliateLimit(url, data) {
		let token = localStorage.getItem('access_token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': token
		});
		// https://apif.aadeshtravels.com/apiw/b2b-get-user/get-affiliate?xlCode=CS005D
		this.toggleLoader(true);
		return this.http.post(`${environment.apif_account}/${url}`, data)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}


	getAddOnsSSR(url){
		// this.toggleLoader(true);
		return this.httpClient.get(`${environment.url}/${url}`)
			.pipe(map(res => {
				this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false);
				return throwError(err);
			}))
	}
	postAddOnsSSR(url, data) {
		// this.toggleLoader(true);
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.url}/${url}`, data, { headers: headers })
			.pipe(map(res => {
				// this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				// this.toggleLoader(false);
				return throwError(err);
		}))
	}

	addUserBookingActivity(type,message,reference,pnr){
		if(localStorage.user_details != undefined && environment.name == 'prod') {
			let user = JSON.parse(localStorage.user_details);
			let time = Math.round(new Date().getTime() * 1000000)
			let data = {
				"streams": [
				{
					"stream": {
					"type": "log",
					"tx": type,
					"affilaite_name": user.companyName,
					"xl_code": user.xlAccountCode,
					"booking_ref_no": reference,
					"pnr": pnr,
					"source": "IBE",
					},
					"values": [
						[time, message]
					]
				}
				]
			}
			this.postMethod(`https://aadeshtravels.com/loki/loki/api/v1/push`,data).subscribe(res=> {
			},err => {
			})
		}
	}


	// control plane api request
	getVersionControl(source='') {
		// this.toggleLoader(true);
		return this.httpClient.get(`${environment.controlPlaneUrl}/version/?source=${source}`)
			.pipe(map(res => {
				// this.toggleLoader(false);
				return res;
			}),
			catchError((err) => {
				// this.toggleLoader(false);
				return throwError(err);
		}))
	}

	putCarMarkup(url,data) {	
		this.toggleLoader(true);
		return this.httpClient.put(`${environment.carMarkup}/${url}`,data)
			.pipe(map(res => {
				this.toggleLoader(false)
				return res;
			}),
			catchError((err) => {
				this.toggleLoader(false)
				return throwError(err);
			}))
	}




}
