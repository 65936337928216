import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DetailComponent } from '../flight-list/detail/detail.component';
import { PostService } from 'src/app/services/post.service';
import { UrlService } from 'src/app/services/url.service';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { CalculatePriceService } from 'src/app/services/calculate-price.service';

@Component({
	selector: 'app-flight-detail-popup',
	templateUrl: './flight-detail-popup.component.html',
	styleUrls: ['./flight-detail-popup.component.scss']
})
export class FlightDetailPopupComponent implements OnInit {
	flight;
	selectedFlightData;
	fareArr: any = [];
	keys = [];
	itenaryFareKey: any = [];
	itenaryFareValues: any = [];
	baggageArr: any = []
	fareCount: number = 0;
	airlineCode = "";
	fareRule : any = "";
	baggage: any;
	selectedPriceType: any;
	isLoading: boolean;
	showLegacyFareRule = false
	legacyFareRule: any;
	parentIndex: number;

	constructor(public dialogRef: MatDialogRef<DetailComponent>,
		private url: UrlService,
		private postService: PostService,
		private commonService: CommonService,
		private calculateFareService : CalculatePriceService,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		this.flight = data;
		this.selectedFlightData = JSON.parse(JSON.stringify(data))
		this.airlineCode = this.flight.airline.code;
		let count = 1;
		this.flight.fares.forEach(el => {
			let newObj = {};
			if (el.fareComponents != null) {
				newObj = this.calculateFareService.calculatePaxFareBreakup(el);
				let priceType = "Price " + count;
				if (el.priceType != "" && el.priceType != undefined) {
					priceType = el.priceType;
				}
				let obj = {
					'keys': Object.keys(newObj),
					'values': Object.values(newObj),
					'netPayable': 0,
					'tds': 0,
					'commission': 0,
					'priceType': priceType
				}
				this.fareArr.push(obj);
				count++;
			}

		});
	}

	ngOnInit() {
		let flightData = Object.assign({},this.flight);
		let fareType = flightData['priceType']
		this.getFareRule(fareType)
	}

	getFlightFromStateManage(key) {
		return new Promise((resolve, reject) => {
			this.postService.getMethod(`${environment.url}/state/${key}`).subscribe(res => {
				if(JSON.parse(res["_body"])) {
					resolve(JSON.parse(res["_body"]).data)
				}
				
			}, err => {
				console.log("Error in booking : ", err)
				resolve(err['error']);
			})

		})
	}
	onNoClick(): void {
		this.dialogRef.close();
	}
	convertKey(obj) {

		this.keys = Object.keys(obj);
		// return this.keys;
	}
	checkIfNUll = (value) => {
		// console.log(value)
		if (value == null || value == undefined || value == "") {

			return '-'
		}
		else {
			return value;
		}
	}
	checkValue(values) {
		console.log(values);
	}
	filterFare(data) {
		let mapArr = new Map();
		let total = 0;
		data.forEach(element => {
			if (mapArr.has(element.chargeCode)) {
				let value = mapArr.get(element.chargeCode);
				value = element.amount + value;
				mapArr.set(element.chargeCode, value)
			}
			else {
				mapArr.set(element.chargeCode, element.amount)
			}
			total += element.amount;
		});
		mapArr.set('Total', total)
		return mapArr;
		// console.log('mao arrr',mapArr);
	}

	returnDate(date) {
		return this.commonService.returnDate(date);
	}

	returnTime(date) {
		return this.commonService.returnTime(date);
	}

	// getDuration(dt1, dt2) {
	// 	return this.commonService.timeDuration(dt2, dt1);
	// }
	getDuration(dt1, dt2,duration) {
		if(!duration){
			duration = this.commonService.timeDuration(dt2,dt1);
		}
		return duration
	}

	calculateStopOverDuration(legs, index) {
		let fistDate = legs[index].arrivalDateTime;
		if (legs[index + 1] != undefined) {
			let secDate = legs[index + 1].departureDateTime;
			return this.getDuration(fistDate, secDate,'');
		}
		return null;
	}

	async getFareRule(fareType){
		try {
			this.showLegacyFareRule = false;
			this.legacyFareRule = ''
			this.selectedPriceType = fareType
		let flightData = JSON.parse(JSON.stringify(this.flight))
		this.fareRule = this.isString(this.fareRule) ? "" : []
		this.isLoading = true
		this.parentIndex = 0
		for(const fare of this.flight.fares) {
			if(fare.priceType == fareType) {
				let flightStateRes = await this.getFlightFromStateManage(fare.supplierInfo.ufid);
				if(flightStateRes) flightData = JSON.parse(JSON.stringify(flightStateRes))
				flightData['priceType'] = fare.priceType;
				this.selectedFlightData = flightData
			}
		}
		let obj = {
			"supplierName": flightData.fares[0].supplierInfo ? flightData.fares[0].supplierInfo.SUPPLIER : "",
			"classOfService": flightData.fares[0].supplierInfo ? flightData.fares[0].supplierInfo.ClassOfService : "",
			"fareBasisCode": flightData.fares[0].supplierInfo ? flightData.fares[0].supplierInfo.FareBasisCode : "",
			"ruleNumber": flightData.fares[0].supplierInfo ? flightData.fares[0].supplierInfo.RuleNumber : "",
			"signature": flightData.fares[0].supplierInfo ? flightData.supplierInfo.Signature : "",
			"supplierInfo": flightData.fares[0].supplierInfo,
			
		}
		if(flightData.supplierCode == "SABRE" || flightData.supplierCode == "GALILEO") {
			obj.supplierName = flightData.supplierCode;
			let supplierInfo = {
				"Origin" : flightData.segments[0].originAirport.code,
				"Destination" : flightData.segments[0].destinationAirport.code,
				"TravelDate" : flightData.segments[0].legs[0].departureDateTime
			}
			obj["supplierInfo"] = supplierInfo;
			obj["fareBasisCode"] = flightData.supplierInfo && flightData.supplierInfo.FareBasisCode  ? flightData.supplierInfo.FareBasisCode : "";
		}
		else if(flightData.supplierCode.includes('AMADEUS') || flightData.supplierCode == "VERTEIL"){
			let flightReq = JSON.parse(sessionStorage.ActiveSearchRequest)
			obj['supplierName']  = flightData.supplierCode
			let sectors = flightData.segments
			let supplierInfo = {
				"travellerInfo": {
				"ADT": flightReq['noOfAdults'],
				"CHD": flightReq['noOfChild'],
				"INF": flightReq['noOfInfants']
				},
				"sectors": sectors,
				"priceType" : flightData['priceType']
			}
			obj["supplierInfo"] = supplierInfo;
		}
		localStorage.removeItem("flightSearchId");	
		this.postService.postMethod(`${environment.url}/getFareRule`, obj).subscribe((data:any) => {
			try{
				
				this.fareRule = "No Fare Rule Found";
				this.baggage = "No Baggage Information Found";
				if(data && data['fareRule']){
					this.fareRule = data['fareRule'];
				}else if(data && data.mini){
					this.fareRule = data.mini
					if(data.legacy){
						this.legacyFareRule = data.legacy
					}
				}
				if(data && data['baggagemessage']){
					this.baggage = data['baggagemessage'];
				}
				this.isLoading = false
			}catch(Exception){
				console.log(Exception)
				this.isLoading = false
			}
			
		
		}, error => {
			
			if(flightData &&  flightData.fares[0] && flightData.fares[0]['supplierInfo'] && flightData.fares[0]['supplierInfo'].fareRule && flightData.fares[0].supplierInfo.fareRule.length>0){
				this.fareRule = flightData.fares[0]['supplierInfo'].fareRule
			}else{
				this.fareRule = "No Fare Rule Found";
			}
			this.isLoading = false
		})
		} catch (error) {
			this.isLoading = false
		}
	}
	
	isString(data){
		return typeof data == 'string'
	}
	getData(currentIndex,parentIndex){
		console.log('Index : ',currentIndex,parentIndex)
		this.parentIndex = currentIndex
	}
}
