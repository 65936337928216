import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { TravellerService } from 'src/app/services/traveller/traveller.service';
import { BookingRequestService } from 'src/app/services/booking-request.service';
import { ExcelService } from 'src/app/services/excel.service';
import { environment } from 'src/environments/environment';
import { PostService } from 'src/app/services/post.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import * as moment from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { FormGroup, Validators, FormBuilder, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/public_api';
import { NgRedux } from "@angular-redux/store";
import { InitializeSelectedSSR, ResetAddonSelection, SetSsr, ToggleAddonSummary } from "src/app/store/addons/actions";
import { InitialState } from "src/app/store/reducer";
import { NotificationService } from 'src/app/services/notification.service';

@Component({
	selector: 'app-detail',
	templateUrl: './detail.component.html',
	styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
	isBookingHistoryActive : boolean = false;
	isReAttemptSearchEnabled = false
	@Input() bookingArr;
	@Input() bookingHistoryArr;
	@Input() newBookingObj;
	@Input() totalAffiliateMarkup = 0;
	@Input() totalGstOnServiceCharges = 0;
	@Input() totalServiceCharges = 0;
	@Input() totalNetPayable = 0;
	@Input() invalidCredit;
	@Input() bookedWithAffiliateCreds;
	@Input() affiliateDetails;
	@Input() isLCC;
	@Output() calSSREvent: EventEmitter<any> = new EventEmitter();
	@Output() resetData: EventEmitter<any> = new EventEmitter();
	@ViewChild('openModal') openModal: ElementRef;
	@ViewChild('openSuccessModal') openSuccessModal: ElementRef;
	@ViewChild('openReIssuanceModal') openReIssuanceModal: ElementRef;
	@ViewChild('openReIssuanceErrorModal') openReIssuanceErrorModal: ElementRef;
	@ViewChild('closeReIssuanceErrorModal') closeReIssuanceErrorModal: ElementRef;
	@ViewChild('openSplitPnrModal') openSplitPnrModal: ElementRef;
	@ViewChild('closeSplitPnrModal') closeSplitPnrModal: ElementRef;
	@ViewChild('openCancelSectorModal') openCancelSectorModal: ElementRef;
	@ViewChild('closeCancelSectorModal') closeCancelSectorModal: ElementRef;
	@ViewChild('openMessageModal') openMessageModal: ElementRef;
	@ViewChild('closeMessageModal') closeMessageModal: ElementRef;
	@ViewChild('openFareRuleModal') openFareRuleModal: ElementRef;
	@ViewChild("showPriceChange") showPriceChange: ElementRef;
	fareRules;
	showLegacyFareRule: boolean = false
	parentIndex: number = 0;
	@ViewChild('openAddonMarkupModel') openAddonMarkupModel: ElementRef;



	// loading: boolean = false;

	isConfirmationChecked: boolean = false;
	// split travellers
	splitTravellersList1: boolean = true;
	splitTravellersList2: boolean = false;
	splitTravellersList3: boolean = false;

	// split travellers
	splitSectorModal1: boolean = true;
	splitSectorModal2: boolean = false;

	messageList: any;

	historyShow: boolean = true;
	isError: boolean = false;
	FailedPnr: any;
	invalidMsg = "";
	apiSuccessMessage = ""
	apiErrorMessage = ""
	sectorCancellationConfirmation: boolean = false
	isTravellerValidSelected: boolean = false
	showTravellerWarningMessage = ""
	showErrorMessage:boolean = false; // show error message on click viewDetail button

	xmlSearchLogsKey = ""



	// ADD-ONs
	isAffiliateAdonModelOpened : boolean = false;
	@Input() totalAffilateAdonMarkup;
	@Input() hasSSR;
	hasSeat: boolean = false;
	hasMeal: boolean = false;
	hasBaggage: boolean = false;
	allSSRData: any;
	ssrData: any;
	seatMap: any;
	showAddons: boolean;
	ssrAvailability: any = [];
	seatMapArr: any = [];
	showloadError: boolean;
	passengerArr = []
	selectedSeatArr: any;
	selectedBaggageArr: any;
	selectedAddtionalBaggageArr: any;
	selectedAncillaryArr: any;
	selectedMealArr: any;
	baggageCharges: number = 0;
	ancillaryCharges: number = 0;
	additionalBaggageCharges: number = 0;
	seatCharges: number = 0;
	mealCharges: number = 0;
	updateSSRRequest: any
	successAddonMessage: string;

	showSSRField: boolean = false
	showProceedToPay: boolean;
	showSeatError: boolean;
	showSSRError: boolean;
	showSSRMessage: boolean;
	responseData: any;
	showRequestLoader: boolean;
	showSelectedSeatError: boolean = false;
	mealBooked: boolean = false
	seatBooked: boolean = false
	baggageBooked: boolean = false
	ancillaryBooked: boolean = false
	additionalBaggageBooked: boolean = false
	baggageRequested: boolean = false
	ancillaryRequested: boolean = false
	additionalBaggageRequested: boolean = false
	mealRequested: boolean = false
	seatRequested: boolean = false
	@Input() bookingActivity;
	//RE_ISSUE VARIABLE

	destinationCity: any;
	departureCity: any;
	destinationObj = [];
	departureObj = [];
	destinationObjOriginal = [];
	departureObjOriginal = [];
	isDomestic: boolean;
	isOriginDomestic: boolean;
	isDestinationDomestic: boolean;
	searchForm: FormGroup;
	keyword = 'result';
	bsConfig: Partial<BsDatepickerConfig>;
	colorTheme = 'theme-purple'
	public departureMinDate: Date = new Date();
	cancellationWarningMessage: any = ``;

	city: FormArray;
	originalSearchForm: FormGroup | FormArray | FormControl;
	modalBodyMsg: any;
	cancellationTypeValue: string
	showError = false;
	errorMessage = "";
	showLoadingMessage: boolean = false;
	reIssueErrorMessage: any;
	reissuePossible: any;
	airlinePnrRetrieveUrl: any;
	isReissueErrorModalOpen: boolean;
	ImgPath: string;
	splitTravellersForm: FormGroup;
	allTravellers: FormArray;
	cancelSectorForm: FormGroup
	allSegments: FormArray;
	mergedCancelledSegmentFare: any = {}
	travellerFareOfCancelledSeg: any = []
	newSplitBooking: any;
	copyOfBooking: any;
	previewRefund: any;
	affiliateData: any;
	showSplitResponse: boolean;
	selectedSsrInRedux: any;
	toggleAddon:boolean = false
	
	activeAddonTab = 'seat-map';
	refundType='cancel' // ['cancel','void'] used for preview and process refund based on refund type, used in booking service
	showVoid = false;
	showExpandedSSRDetail = []
	bookingSupplier: string;
	userInfo: any;
	isEmulatedUser: boolean;

	showReattemptButton: boolean = false;
	priceChangedAmountObj: any
	copyFor: any;
	showHoldTTL = false
	holdTimelineMessage = '';
	isGfsSupplier = false;
	ssrFailMessage = ''
	constructor(
		private travellerService: TravellerService,
		private bookingRequestService: BookingRequestService,
		private excelServce: ExcelService,
		private postService: PostService,
		private router: Router, private commonService: CommonService,
		private bookingService: BookingService,
		private fb: FormBuilder,
		private ngRedux: NgRedux<InitialState>,
		private notificationService : NotificationService

	) {
		this.searchForm = this.fb.group({
			from: ["", Validators.required],
			to: ["", Validators.required],
			depDate: ["", Validators.required],
			depDateText: [""],
			returnDate: [""],
			serviceClass: ["ECONOMY"],
			noOfAdult: [1],
			passTotal: ["1 Traveller"],
			noOfChild: [0],
			noOfInfant: [0],
			flightClass: [0],
			newAddonMarkup: [0],
			"city": this.fb.array([])
		});

		this.ngRedux.dispatch(ResetAddonSelection({}))
		this.ngRedux
			.select('SSrReducers')
			.subscribe((items : any) => {
				this.selectedSsrInRedux = items.selectedSsr;
			});

		this.ngRedux
			.select('CommonReducers')
			.subscribe((items : any) => {
			if(items.globalMessage){
				this.messageList = items.globalMessage
			}		
	    });
	}

	ngOnInit() {
		if (localStorage.isLoaded == undefined) {
			localStorage.isLoaded = true;
			window.location.reload();
		}
		this.bsConfig = Object.assign({ dateInputFormat: 'DD/MM/YYYY' }, { containerClass: this.colorTheme }, { showWeekNumbers: false })
		this.affiliateData = JSON.parse(localStorage.user_details);
		if(this.bookingArr && this.bookingArr.length>0 && this.bookingArr[0].json_doc && this.bookingArr[0].json_doc.travellers){
			for (const iterator of this.bookingArr[0].json_doc.travellers) {
				this.showExpandedSSRDetail.push(false)
			}
		}
	}

	// ngOnChanges () {
	// }

	ngOnChanges(){



		let oneDayBeforeDate =  moment().subtract('24', 'hours').format('YYYY-MM-DD HH:mm:ss')
		if(this.bookingArr[0].json_doc.pnr_status == 'FAILED' && 
		(moment(this.bookingArr[0].json_doc.booked_timestamp, 'YYYY-MM-DD HH:mm:ss').isAfter(oneDayBeforeDate)))
		{
			this.showReattemptButton = true
		}

		let supplier = ""
		if(this.bookingArr && this.bookingArr.length > 0 && this.bookingArr[0].booking_date){
			supplier = this.bookingArr[0].json_doc.segments[0].booking_supplier
			if (this.bookingArr[0].json_doc.supplierInfo && this.bookingArr[0].json_doc.supplierInfo.SUPPLIER) {
				supplier = this.bookingArr[0].json_doc.supplierInfo.SUPPLIER
				if(supplier == 'gfs') {
					this.isGfsSupplier = true;
				}
			}
			if(
				(supplier == 'AMADEUS_V4' || supplier == 'AMADEUS' || supplier == 'AMADEUS_STUD' || supplier == 'VERTEIL') && 
					this.bookingArr[0].json_doc.is_domestic == false  &&
				 	moment(this.bookingArr[0].json_doc.booked_timestamp, 'YYYY-MM-DD HH:mm:ss').isSame(moment(new Date()),'day')
				  ){
				this.showVoid = true
			}
		}
		if(
			((supplier == 'GALILEO' && moment(this.bookingArr[0].json_doc.booked_timestamp, 'YYYY-MM-DD HH:mm:ss').isAfter('2023-05-03 19:00:00')) ||
			(supplier != 'GALILEO')) &&
			this.bookingArr[0].json_doc.pnr_status == 'HOLD' && this.bookingArr[0].json_doc.supplierInfo.hold_ttl != undefined
			){
			this.showHoldTTL = true
		}

		if((this.bookingArr[0].json_doc.supplierInfo.isSeatFail ===  true || this.bookingArr[0].json_doc.supplierInfo.isSsrFail === true) && 
			this.bookingArr[0].json_doc.supplierInfo.failMessage
			) {
			this.ssrFailMessage = this.bookingArr[0].json_doc.supplierInfo.failMessage;
		}

		this.bookingSupplier = supplier
		this.userInfo = JSON.parse(localStorage.getItem('user_details'));
		if (this.userInfo['emulated_by'] != undefined) {
			this.isEmulatedUser = true
		}
		else{
			this.isEmulatedUser = false
		}


		if((supplier == 'GALILEO' || supplier == 'AMADEUS_V4' || supplier == 'AMADEUS' || supplier == 'AMADEUS_STUD') && this.showHoldTTL)
			{
				this.holdTimelineMessage = `<span>We guarantee this fare until date <strong>${moment(this.bookingArr[0].json_doc.booked_timestamp).format('MMMM DD,YYYY')}</strong>, however please note that the fare (TST) Expires at 11:59 pm IST.</span>`
			}

	}

	showHistory() {
		if (this.historyShow) {
			this.historyShow = false;
		}
		else {
			this.historyShow = true;
		}
	}
	returnDate(date) {
		if (date != null && date != undefined) {
			let d = new Date(date.split("+")[0]);
			return d.toDateString()
		}
		return "";
	}
	returnTime(date) {
		if (date != null && date != undefined) {
			let d = new Date(date.split("+")[0]);
			return (d.toTimeString().split(' ')[0]).split(':')[0] + ":" + (d.toTimeString().split(' ')[0]).split(':')[1];
		}
		return "";
	}

	checkIfNull(value) {
		if (value == undefined) {
			return '0';
		}
		else {
			return value;
		}
	}
	printTicket() {
		;
		let bookingRef = this.bookingArr[0].json_doc.booking_ref;
		let searchId = this.bookingArr[0].json_doc.invoice_no;
		let v3 = window.location.pathname.includes("v3")?"/v3":""
		window.open(v3+"/#/template/print-ticket;searchId=" + searchId + ";booking_ref=" + bookingRef, "_blank");
		// this.router.navigate(['/print-ticket', { searchId }])
	}
	private openPriceChangeModal(): void {
		this.showPriceChange.nativeElement.click();
	}

	reAttemptBooking() {
		if(this.priceChangedAmountObj && this.priceChangedAmountObj['reAttemptBody']) {
			this.reAttemptTicket(this.priceChangedAmountObj['reAttemptBody'])
		}else {
			this.reAttemptTicket({"selectedFare":null})
		}
	}
	
	reAttemptTicket(values) {
		let bookingId = this.bookingArr[0].json_doc.booking_ref;
		this.commonService.toggleLoader(true)
		this.postService
				.putMethod(`${environment.url}/re-attempt/${bookingId}`, values)
				.subscribe((bookingRes: any) => {
					if(bookingRes && bookingRes['message'] && bookingRes['message'].toLowerCase() == 'failed') {
						this.commonService.toggleLoader(false)
						this.showError = true;
						this.errorMessage = `FAILED TO RE-ATTEMPT. PLEASE SEARCH AND RETRY AGAIN.`
						return false
					}
					if(bookingRes.hasOwnProperty("priceChanged") && bookingRes["priceChanged"]){
						this.isReAttemptSearchEnabled = bookingRes.enableSearch;
						if( bookingRes["bookingResponse"] &&  bookingRes["bookingResponse"]['selectFlight']) {
							this.priceChangedAmountObj = {
								"oldTtotalNetPayable": bookingRes["bookingResponse"]['selectFlight']['oldFare']['total'],
								"netTotalayable" : bookingRes["bookingResponse"]['selectFlight']['selectedFare']['total'],
								"reAttemptBody": {
									"selectedFare" : bookingRes["bookingResponse"]['selectFlight']['selectedFare']
								} 
							}
						}

						this.showReattemptButton = true;
						this.openPriceChangeModal()
						this.commonService.toggleLoader(false)
						return false;
				    }

					this.showReattemptButton = false;
					setTimeout(() => {
						this.resetData.emit()
					}, 5000)
					this.commonService.toggleLoader(false)
				});
	}

	async issueTicket() {
		if(this.bookingArr[0].json_doc.supplierInfo.hold_ttl){
			let hold_ttl = this.bookingArr[0].json_doc.supplierInfo.hold_ttl
			if(moment(hold_ttl).isBefore(moment())){
				window.scrollTo(0,0)
				this.showError = true;
				this.errorMessage = `Can't issue this booking : HOLD TTL EXPIRED`
				return false
			}
		}
		this.commonService.toggleLoader(true)
		let affiliateData = JSON.parse(localStorage.user_details);
		let updateObj = {
			"affiliateXLCode": this.bookingArr[0].json_doc.xl_code,
			"requested_from": "IBE",
			"requested_by": `${affiliateData.firstName} ${affiliateData.lastName}`,
			"booking_ref": this.bookingArr[0].json_doc.booking_ref,
		}
		try {
			this.commonService.addUserBookingActivity('hold_confirm','Hold to confirm request',this.bookingArr[0].json_doc.booking_ref,this.bookingArr[0].json_doc.gds_pnr);
		} catch (error) {
			
		}

		// try {
		// 	let updateSSRInHoldToConfirm = await this.bookingService.createSsrRequestForHoldtoConfirm(this.bookingArr[0].json_doc)
		// 	console.log('updateSSRInHoldToConfirm',updateSSRInHoldToConfirm);
		// 	updateObj['ssrs'] = updateSSRInHoldToConfirm;
		// } catch (error) {
		// 	console.log('error', error);
			
		// }

		this.commonService.postMethod(`${environment.baseUrl}/edge/gate-keeper/hold-confirm`, updateObj).subscribe(res => {
			this.commonService.toggleLoader(false)
			this.resetData.emit()
			this.showHoldTTL = false;
		}, err => {
			this.showError = true;
			this.errorMessage =`
				<span>
					<strong>Critical: Please be advised that ticket may be issued for this PNR, But we did not receive confirmed response from the airline. Please contact our support team ASAP!<strong>
				</span>`;
			this.commonService.toggleLoader(false)
		})

	}
	doAccountEntry(invoice_no) {
		let reqObj = {
			"invoice_no": invoice_no
		}
		this.commonService.postMethod(`${environment.baseUrl}/edge/gate-keeper/okount_entry`, reqObj).subscribe(res => {
		}, err => {
		})
	}

	saveInvoice(data, invoiceNo) {
		data[0]['json_doc']['booking_hold_date'] = new Date(data[0]['json_doc']['booked_timestamp']).toISOString()
		data[0]['json_doc']['booked_timestamp'] = new Date().toISOString()
		let invoiceRequest = {
			'invoice_no': invoiceNo,
			'booking_ref_no': data[0].json_doc.booking_ref,
			"updated_by_user_id": data[0].json_doc.affiliate_id,
			"updated_by_user_name": data[0].json_doc.affiliate_company_name,
			"json_doc": data[0].json_doc,
			"typhe": "flight",
			"booking_date": data[0].json_doc.booked_timestamp
		}
		//https://stage.aadeshtrips.com/booking

		//https://stage.aadeshtrips.com/booking
		this.commonService.postBooking(`invoice/saveInvoice`, invoiceRequest).subscribe(res => {
			try {
				this.doAccountEntry(invoiceNo)
			} catch (error) {
			}
		}, err => {
			alert('Something went while saving invoice')
		})
	}

	bookingLedger(bookingInvoice) {
		let originAirportCode = this.bookingArr[0].json_doc.segments[0].legs[0].origin_airport_code;
		let destinationAirportCode = this.bookingArr[0].json_doc.segments[0].legs[this.bookingArr[0].json_doc.segments[0].legs.length - 1].destination_airport_code;
		let userData = this.bookingArr[0].json_doc.travellers[0];
		let narration1 = 'Ticket Created :' + userData.first_name + ' ' + userData.last_name;
		let route = originAirportCode + '-' + destinationAirportCode;
		let travelDate = this.bookingArr[0].json_doc.segments[0].legs[0].origin_timestamp;;
		let flightNumber = this.bookingArr[0].json_doc.segments[0].legs[0].flight_number;
		let supplierCode = this.bookingArr[0].json_doc.segments[0].legs[0].marketing_airline_code;
		let lcc = this.travellerService.checkLCC(supplierCode);
		let obj = {
			bookingInvoice: bookingInvoice
		}
		let ledgerDetails = {
			narration1: narration1,
			narration2: route,
			narration3: 'Travel Date : ' + travelDate,
			narration4: 'Supplier :' + supplierCode + '-' + flightNumber,
		}
		obj['ledgerDetails'] = ledgerDetails;
		let totalPax = 0;
		let data = this.bookingArr[0];
		for (let i = 0; i < data.json_doc.travellers.length; i++) {
			if (data.json_doc.travellers[i].type != "INF") {
				totalPax++;
			}
		}
		let affiliateData = JSON.parse(localStorage.user_details);
		let excelObj = this.excelServce.getHoldExcelRequest(data.json_doc, data.json_doc.commission.customer, this.totalAffiliateMarkup, this.totalServiceCharges, false, affiliateData, totalPax, lcc)
		this.bookingRequestService.updateLedger(obj, lcc, Math.round(this.totalNetPayable));
		if (excelObj.hasOwnProperty('__zone_symbol__value')) {
			excelObj = excelObj['__zone_symbol__value'];
		}
		this.postService.postMethod(`${environment.excelAccountingUrl}/excelAccount`, excelObj).subscribe(res => {	//fucntion to run excel accounting request
			if (res['status'] == 200) {

			} else {

			}
		}, err => {

		})
		// return obj;
	}
	printInvoice() {
		let searchId = this.bookingArr[0].json_doc.invoice_no;
		localStorage.setItem('bookingArr', JSON.stringify(this.bookingArr))
		let systacc_access_token = localStorage.getItem('systacc_access_token')
		// window.open("#/template/print-invoice;searchId=" + searchId, "_blank");

		let isBeforeFinancialYear2020_21 = moment(this.bookingArr[0].json_doc.booked_timestamp, 'YYYY-MM-DDTHH:mm:ss').isBefore('2020-04-01T00:00:00')
		if (isBeforeFinancialYear2020_21 == true) {
			window.open("#/template/print-invoice;searchId=" + searchId, "_blank");
		} else {
			let prefix = "IW" + moment(this.bookingArr[0].json_doc.booked_timestamp, 'YYYY-MM-DDTHH:mm:ss').format('-YY-MM-');
			let invoice_no = prefix + searchId
			let url = `${environment.okountWebAppUrl}/v3/#/transactions/invoice-view;searchId=${invoice_no};type=flight?access_token=${systacc_access_token}&hide_party=true`
			window.open(url)
		}
	}



	dateFormat(value) {
		if (value != "") {
			let date = new Date(value);
			let formatedDate = date.toLocaleDateString()
			let nDate = formatedDate.split('/');
			let day = date.getDate();
			let year = date.getFullYear();
			let dMonth = date.getMonth() + 1;
			if (dMonth.toString().length == 1) {
				let month = "0" + dMonth;
				return day + '/' + month + '/' + year;
			}
			else {
				let month = dMonth;
				return day + '/' + month + '/' + year;
			}

		}
		else {
			return "";
		}
	}
		// Get WebCheck-In Value
		async getWebCheckIn(webCheckFalse, webCheckError){	
			this.commonService.toggleLoader(true)
			this.reIssueErrorMessage = `Please wait for system to check if PNR can be ${webCheckFalse}`
			this.reissuePossible = false
			this.modalBodyMsg = webCheckError	
			if(!this.isReissueErrorModalOpen){
				this.openReissueErrorModalFn()
				this.isReissueErrorModalOpen = true
			}
			let supplier = this.bookingArr[0].json_doc.segments[0].booking_supplier
			if (this.bookingArr[0].json_doc.supplierInfo && this.bookingArr[0].json_doc.supplierInfo.SUPPLIER) {
				supplier = this.bookingArr[0].json_doc.supplierInfo.SUPPLIER
			}
			let reqObj = {
				"pnr": this.bookingArr[0].json_doc.gds_pnr,
				"booking_ref_no" : this.bookingArr[0].json_doc.booking_ref,
				"supplierName": supplier,
				"action": this.modalBodyMsg
			}
			this.commonService.toggleLoader(false)	
			this.showLoadingMessage = true;
			let respVal;
			return new Promise((resolve, reject) => {
				this.commonService.postMethod(`${environment.url}/re-issue-preliminary-check`, reqObj).subscribe(res => {
				this.closeReissueErrorModalFn()
				resolve(res["webCheckedIn"])
				respVal = res["webCheckedIn"]
				// resolve(true)
				// respVal = true
				if(respVal === true){
					this.reIssueErrorMessage = true
					this.reissuePossible = true
					this.airlinePnrRetrieveUrl = res["airlinePnrRetrieveUrl"]
					this.commonService.toggleLoader(false)
				}	
				}, err => {
					this.reissuePossible = err.error.reissuePossible
					this.reIssueErrorMessage = err.error.message
					this.commonService.toggleLoader(false)	
					setTimeout(() => {
						if (err.error.airlinePnrRetrieveUrl) {
							this.airlinePnrRetrieveUrl = err.error.airlinePnrRetrieveUrl
						}
					}, 100);	
					
				})
	
			})
		}  		
		// finished Here Get webCheckin

	async cancelReq(cancellationType,refundType) {
		this.refundType = refundType
		this.cancellationTypeValue = cancellationType
		window.scrollTo(0, 0)
		let direction = this.bookingArr[0].json_doc.supplierInfo.direction;
		let supplierName = this.bookingArr[0].json_doc.supplierInfo.SUPPLIER;
		 if(environment.allowCancellation == true && (this.isLCC || refundType == 'void' || supplierName == 'EMIRATES') && direction != 'MULTICITY') {	
	
			let curr_date_time = await this.commonService.getCurrentDateTime()
			let curr_date = await this.commonService.getCurrentDate()
			let searchId = this.bookingArr[0].json_doc.booking_ref;
			// let inbetween = moment(curr_date_time).isBetween(curr_date + 'T08:00:00', curr_date + 'T20:00:00')

			let departureDate = ""

			for(let seg of this.bookingArr[0].json_doc.segments){
				if(seg.hasOwnProperty("isTravelled") && seg.isTravelled){
					continue;
				}
				departureDate = seg.legs[0].origin_timestamp
				break
			}
		
			// let departureDate = this.bookingArr[0].json_doc.segments[0].legs[0].origin_timestamp
			let depatureDurationInMin = this.commonService.timeDuration2(new Date(curr_date_time), new Date(departureDate))
			
			// const current_day = moment(curr_date_time).format('dddd')
			// if (this.affiliateData.xlAccountCode != 'CO000N' ||  !inbetween || !brefore4hr || current_day == 'Sunday') {
			let airline = this.bookingArr[0].json_doc.segments[0].legs[0].marketing_airline_code
			if (depatureDurationInMin < 180 && this.bookingArr[0].json_doc.is_domestic==true && ["SG","6E","G8"].includes(airline)) {

				window.scrollTo(0, 0)
				this.cancellationWarningMessage = ` The cancellation request service is only available  before  <strong>3 hours</strong> from journey date.`
				this.openModalDialog()
				return false
			}
			else {
				let isFullCancel = false			
				let result = await this.getWebCheckIn(cancellationType, 'cancellation')
				if(result === true){
		 		this.openReissueErrorModalFn()
			}	
			else{
				if(cancellationType == 'TICKET'){
					isFullCancel = true	
				}		
				this.openCancelSegmentModal(isFullCancel)
			
		}
	}}
		 else {
			this.cancellationWarningMessage = `The cancellation is not working , Please contact to back office team.`
			this.openModalDialog()
			return false
		}
	}
	private openModalDialog(): void {
		this.openModal.nativeElement.click()
	}
	private openSuccessModalDialog(): void {
		this.openSuccessModal.nativeElement.click()
	}
	private openReissueModalFn(): void {
		this.openReIssuanceModal.nativeElement.click()
	}

	private openReissueErrorModalFn(): void {
		this.openReIssuanceErrorModal.nativeElement.click()
	}

	public closeReissueErrorModalFn(): void {
		this.isReissueErrorModalOpen = false
		this.closeReIssuanceErrorModal.nativeElement.click()
	}

	// openReIssueModal() {
	// 	// this.setReIssueSearchFormData()
	// 	// this.openReissueModalFn()
	// 	// this.originalSearchForm = this.copyFormControl(this.searchForm)
	// 	// console.log('Original Form : ',this.originalSearchForm.value);
	// 	this.reissuePreliminaryCheck()

	// }

	openReIssueModal() {
		this.reIssueErrorMessage = 'Please wait for system to check if PNR can be re-issued'
		this.modalBodyMsg = 're-issuance'
		this.reissuePossible = false
		this.airlinePnrRetrieveUrl = null
		if (!this.isReissueErrorModalOpen) {
			this.openReissueErrorModalFn()
			this.isReissueErrorModalOpen = true
		}
		let supplier = this.bookingArr[0].json_doc.segments[0].booking_supplier
		if (this.bookingArr[0].json_doc.supplierInfo && this.bookingArr[0].json_doc.supplierInfo.SUPPLIER) {
			supplier = this.bookingArr[0].json_doc.supplierInfo.SUPPLIER
		}
		let reqObj = {
			"pnr": this.bookingArr[0].json_doc.gds_pnr,
			"booking_ref_no" : this.bookingArr[0].json_doc.booking_ref,
			"supplierName": supplier,
			"action": this.modalBodyMsg
		}
		this.showLoadingMessage = true;
		this.commonService.postMethod(`${environment.url}/re-issue-preliminary-check`, reqObj).subscribe(res => {
			this.closeReissueErrorModalFn()
			this.setReIssueSearchFormData()
			this.openReissueModalFn()
			this.originalSearchForm = this.copyFormControl(this.searchForm)
   			// this.reissuePossible = true
		}, err => {
			this.reIssueErrorMessage = err.error.message
			this.reissuePossible = err.error.reissuePossible
			setTimeout(() => {
				// var style = document.querySelector('li.second.active')['style'];
				// style.setProperty('--imgpath', `url(/assets/images/${this.bookingArr[0].json_doc.segments[0].legs[0].marketing_airline_code}.png) no-repeat`);
				if (err.error.airlinePnrRetrieveUrl) {
					this.airlinePnrRetrieveUrl = err.error.airlinePnrRetrieveUrl
				}
			}, 100);

		})
	}


	viewTicketDetail(){
			let searchId = this.bookingArr[0].json_doc.booking_ref;
		    this.router.navigate(['/booking-summary-detail', { searchId }]);
	}

	getYear() {
		let isBeforeFinancialYear2020_21 = moment(this.bookingArr[0].json_doc.booked_timestamp, 'YYYY-MM-DDTHH:mm:ss').isBefore('2020-04-01T00:00:00')
		let year = moment(this.bookingArr[0].json_doc.booked_timestamp, 'YYYY-MM-DDTHH:mm:ss').format('YY');
		if (isBeforeFinancialYear2020_21 == true) {
			year = "19"
		} else {
			year = moment(this.bookingArr[0].json_doc.booked_timestamp, 'YYYY-MM-DDTHH:mm:ss').format('-YY-MM');
		}
		// let yyear = new Date(this.bookingArr[0].json_doc.booked_timestamp).getFullYear().toString().split('').splice(2, 3).join('')
		return year
	}
	viewCreditNote() {

		let searchId = this.bookingArr[0].json_doc.invoice_no;
		let systacc_access_token = localStorage.getItem('systacc_access_token')
		// window.open("#/template/print-credit-receipt;searchId=" + searchId, "_blank");
		let isBeforeFinancialYear2020_21 = moment(this.bookingArr[0].json_doc.cancelled_timestamp, 'YYYY-MM-DDTHH:mm:ss').isBefore('2020-04-01T00:00:00')
		if (isBeforeFinancialYear2020_21 == true) {
			window.open("#/template/print-credit-receipt;searchId=" + searchId, "_blank");
		} else {
			let prefix = "IZ" + moment(this.bookingArr[0].json_doc.cancelled_timestamp, 'YYYY-MM-DDTHH:mm:ss').format('-YY-MM-');
			let invoice_no = prefix + searchId
			let url = `${environment.okountWebAppUrl}/v3/#/transactions/print-credit-note/${invoice_no};type=flight?access_token=${systacc_access_token}&hide_party=true`
			window.open(url)
		}
	}
	setAirlinePNR(event) {
		this.FailedPnr = event.target.value
		if (this.newBookingObj == undefined) {
			this.newBookingObj = Object.assign({}, this.bookingArr)
		}
	}
	addTicketNo(data) {
		let tcketTd = document.getElementsByClassName('ticketInput');
		let traveller = data['json_doc']['travellers'];
		for (let i = 0; i < tcketTd.length; i++) {
			if (traveller[i].ticket == null) {
				let currentTd = tcketTd[i];
				let nameTd = currentTd.parentElement.parentElement.previousElementSibling
				let name = nameTd.textContent.split(':')[1];
				let travellerName = traveller[i].title + " " + traveller[i].first_name + " " + traveller[i].last_name;
				if (name == travellerName) {
					let ticketNumber = currentTd['value'];
					if (ticketNumber == "" || ticketNumber == null) {
						window.scrollTo(0, 0)
						// this.missingTicketError = true
						return false;
					}
					let ticket = {
						"number": ticketNumber,
						"status": "CONF"
					}
					traveller[i]['ticket'] = ticket;
				}
			}
		}
		data['json_doc'].pnr_status = "TICKETED"
	}

	async issueFailedTicket(bookingData) {
		this.commonService.toggleLoader(true)
		try {
			this.invalidMsg = "";
			if (bookingData.json_doc.pnr_status == 'FAILED') {
				if (this.FailedPnr == undefined || this.FailedPnr == "" || this.FailedPnr == null) {
					this.invalidMsg = "Invalid PNR"
					return false
				}
			}
			let segment = bookingData.json_doc.segments
			for (let i = 0; i < segment.length; i++) {
				const seg = segment[i];
				seg.legs.forEach(leg => {
					leg.airline_pnr = this.FailedPnr;
				});
			}
			bookingData.json_doc['gds_pnr'] = this.FailedPnr
			segment.forEach(element => {
				element['pnr'] = this.FailedPnr
			});
			this.addTicketNo(bookingData)
			let invoiceNo = await this.generateInvoiceNum()
			bookingData.json_doc.invoice_no = invoiceNo

			//Update Booking in Apif DB
			try {
				await this.updateBookingInApifDB(bookingData)
			} catch (error) {
				this.commonService.toggleLoader(true)
				throw new Error(error);
			}


			//Update Booking aadesh db
			// try {
			// 	this.updateBookingInAadeshDB(this.bookingArr[0])
			// } catch (error) {
			// 	throw new Error(error);
			// }

		} catch (error) {
		}
	}

	updateBookingInAadeshDB(data) {
		this.commonService.putBooking('booking/update', data).subscribe(res => {
			const localThis = this;
		}, err => {
		})
	}
	updateBookingInApifDB(data) {
		this.commonService.putApifBooking('updateBooking', data).subscribe(res => {
			if (JSON.parse(res['_body'])["data"][0] != undefined) {
				data.json_doc.api_info.affiliate_booking_info = JSON.parse(res['_body'])["data"][0].json_doc.api_info.affiliate_booking_info
			}
			this.updateBookingInAadeshDB(data)
			const localThis = this;
		}, err => {
			this.commonService.toggleLoader(false)
		})
	}

	generateInvoiceNum() {
		return new Promise((resolve, reject) => {
			this.commonService.generateInvoiceNum('generateInvoiceNum', {}).subscribe(res => {
				resolve(res);
			}, err => {
				reject('')
			})
		})
	}

	toggleLoader(status: boolean) {
		let accessLoader = new BehaviorSubject(status);
		this.commonService.accessLoaderStatus = accessLoader.asObservable()
	}

	getSSR() {
		this.showSSRField = true
		this.allSSRData = {}
		this.ssrAvailability = []
		this.seatMapArr = []
		let ssr = {
			showPrice: false,
			baggage: 0,
			seats: 0,
			meals: 0,
			additionalBaggage: 0,
			anicllary: 0,
		}
		this.showProceedToPay = false
		this.calSSREvent.emit(ssr)
		this.showAddons = false
		let reference_no = this.bookingArr[0].json_doc.booking_ref
		let pnr = this.bookingArr[0].json_doc.gds_pnr
		let supplier = this.bookingArr[0].json_doc.supplierInfo.SUPPLIER
		let flightInfo = {
			ssrs: this.getFlightInfoWithSSR(this.bookingArr[0].json_doc)
		}
		this.showSSRError = false
		this.showSeatError = false


		let requestPayload = {
			"travellers":this.bookingArr[0].json_doc.travellers,
			"segments":this.bookingArr[0].json_doc.segments
		}
		this.initializeSelectedSsr(requestPayload)
		this.toggleAddon = false
		this.toggleAddonSideBar()
		this.commonService.getAddOnsSSR(`addon?pnr=${pnr}&bookingRef=${reference_no}&supplierName=${supplier}`).subscribe((res) => {
			this.showAddons = true
			this.allSSRData = JSON.parse(res['_body'])
			// this.ssrData = this.allSSRData['commonSSRResponse']
			let fullArr = this.allSSRData['commonSSRResponse']
			let commonSeatMapResponse = this.allSSRData['commonSeatMapResponse']
			
			this.xmlSearchLogsKey = commonSeatMapResponse.leg && commonSeatMapResponse.leg.length> 0 && commonSeatMapResponse.leg[0].supplierInfo && commonSeatMapResponse.leg[0].supplierInfo['BOOKING_REF_TS_KEY_FOR_LOGS']  ? 
			commonSeatMapResponse.leg[0]['supplierInfo']['BOOKING_REF_TS_KEY_FOR_LOGS'] :  fullArr.legs && fullArr.legs.length > 0 && fullArr.legs[0].supplierInfo && 
			fullArr.legs[0].supplierInfo['BOOKING_REF_TS_KEY_FOR_LOGS'] ? fullArr.legs[0].supplierInfo['BOOKING_REF_TS_KEY_FOR_LOGS'] :  ""
	
			if (fullArr != null) {
				let legs = [];
				for (let i = 0; i < fullArr['legs'].length; i++) {
					const meals = fullArr["legs"][i].meals ?  Object.values(fullArr["legs"][i].meals) : [];
					for (let j = 0; j < meals.length; j++) {
						const element = meals[j];
						element['status'] = 'notselected'
						const el = element['fare']
						if (el.hasOwnProperty("base")) {
							element['totalFare'] = Number(el.base);
						}
						else {
							element['totalFare'] = 0
						}


					}
					const baggages = fullArr["legs"][i].baggages ?  Object.values(fullArr["legs"][i].baggages) : [];
					for (let j = 0; j < baggages.length; j++) {
						const element = baggages[j];
						element['status'] = 'notselected'
						const el = element['fare']

						if (el.hasOwnProperty("base")) {
							// element['totalFare'] = Number(el.base) + Number(el.tax);
							element['totalFare'] = Number(el.base);
						}
						else {
							element['totalFare'] = 0
						}
					}
					const ancillaries = fullArr["legs"][i].ancillaries ?  Object.values(fullArr["legs"][i].ancillaries) : [];
					for (let j = 0; j < ancillaries.length; j++) {
						const element = ancillaries[j];
						const el = element['fare']

						if (el && el.hasOwnProperty("base")) {
							// element['totalFare'] = Number(el.base) + Number(el.tax);
							element['totalFare'] = Number(el.base);
						}
						else {
							element['fare'] = {
								base : 0,
								tax : 0
							}
							element['totalFare'] = 0
						}
					}
					let baggageAfterSorting = baggages.sort(function (a, b) {
						return a['totalFare'] - b['totalFare'];
					})
					let obj = {};
					obj["meals"] = meals;
					obj["baggages"] = baggageAfterSorting;
					obj["ancillaries"] = ancillaries;
					obj["arrival"] = fullArr['legs'][i].arrival;
					obj["totalSelected"] = 0;
					obj["travellerCount"] = this.bookingArr[0].json_doc.travellers.length;
					for (let i = 0; i < this.bookingArr[0].json_doc.travellers.length; i++) {
						this.setSSR(i)
					}
					obj["flightInfo"] = flightInfo

					let tempTravellerArr = [];
					for (let k = 0; k < this.bookingArr[0].json_doc.travellers.length; k++) {
						let tempTraveller = {};
						tempTraveller = Object.assign({}, this.bookingArr[0].json_doc.travellers[k]);
						tempTravellerArr.push(tempTraveller);
					}
					obj["travellers"] = tempTravellerArr;
					obj["departure"] = fullArr['legs'][i].departure;
					obj["flightCode"] = fullArr['legs'][i].airline;
					obj["flightNo"] = fullArr['legs'][i].flightNo;
					legs.push(obj);
				}

				this.ssrAvailability = this.parselegtoseg(legs)

				for(let segment in this.ssrAvailability) {
					if(this.ssrAvailability[segment]) {
						for(let item in this.ssrAvailability[segment]) {
							if(this.ssrAvailability[segment][item]['travellers']) {
								for(let passengerKey in this.ssrAvailability[segment][item]['travellers']) {
									let passenger = this.ssrAvailability[segment][item]['travellers'][passengerKey]
									if(passenger && passenger.hasOwnProperty('ssr')) {
										if(passenger.ssr[segment] && passenger.ssr[segment]['additionalBaggages'] && passenger.ssr[segment]['additionalBaggages'].name == "NA") {
											passenger.ssr[segment]['additionalBaggages'] = undefined
											passenger.ssr[segment]['hasAdditionalBaggage'] = false
										}
										
										for(let ssr of passenger['ssr'])  {
											if(ssr.baggages != undefined && ssr.baggages.name == "NA") {
												ssr.baggages = undefined
												ssr.hasBaggage = false
											}

											if(ssr.meals != undefined && ssr.meals.name == "NA") {
												ssr.meals = undefined
												ssr.hasMeal = false
											}	

											if(ssr.seats != undefined && ssr.seats.name == "NA") {
												ssr.seats = undefined
												ssr.hasSeat = false
											}	
										}
									}
								}
							}
						}
					}
				}

			} else {
				this.showSSRError = true
			}

			let seatMap = this.allSSRData['commonSeatMapResponse'];
			if (seatMap != null) {
				let seatLegs = [];
				for (let i = 0; i < seatMap['leg'].length; i++) {
					if (seatMap['leg'][i].seats.rows != null) {
						const values = Object.values(seatMap['leg'][i].seats.rows)
						for (let j = 0; j < values.length; j++) {
							const element = values[j];
							let charges = Object.values(element)
							for (let k = 0; k < charges.length; k++) {
								const ch = charges[k]
								ch['totalFare'] = 0;
								ch['status'] = 'notselected'
								const el = ch.charges
								if (el.hasOwnProperty("base")) {
									ch['totalFare'] = Number(el.base);
								}
								else {
									ch['totalFare'] = 0;
								}
							}
						}
						let obj = {};
						obj["keys"] = Object.keys(seatMap['leg'][i].seats.rows);
						obj["values"] = values;
						obj['cabinInfo'] = seatMap["leg"][i].seats.cabinInfo ? seatMap["leg"][i].seats.cabinInfo : null
						obj["arrival"] = seatMap['leg'][i].arrival;
						obj["totalSelected"] = 0;
						obj["travellerCount"] = this.bookingArr[0].json_doc.travellers.length;
						let tempTravellerArr = [];
						let associateTravellerInfoMap = new Map();
						for (let k = 0; k < this.bookingArr[0].json_doc.travellers.length; k++) {
							let tempTraveller = {};
							tempTraveller = Object.assign({}, this.bookingArr[0].json_doc.travellers[k]);
							tempTravellerArr.push(tempTraveller);
						}
						obj['associateTravellerInfoMap'] = associateTravellerInfoMap;
						obj["travellers"] = tempTravellerArr;
						obj["departure"] = seatMap['leg'][i].departure;
						obj["flightCode"] = seatMap['leg'][i].flightCode;
						obj["flightNo"] = seatMap['leg'][i].flightNo;
						// let flightInfo= {
						// 	ssrs : this.getFlightInfoWithSSR(this.bookingArr[0].json_doc)
						// }
						obj["flightInfo"] = flightInfo
						seatLegs.push(obj);
					}


				}
				this.seatMapArr = this.parselegtoseg(seatLegs);
			} else {
				this.showSeatError = true
			}
			if (this.ssrAvailability.length == 0) {
				this.showloadError = true
			}

			

		}, err => {
			this.showAddons = false;
			this.showSSRError = true
			this.showSeatError = true
		})
	}

	parselegtoseg(ssr) {
		let segMap = new Map();
		let segArr = []

		for (const segment of this.bookingArr[0].json_doc.segments) {
			for (const leg of segment.legs) {
				let key = `${leg.origin_airport_code}_${leg.destination_airport_code}`
				segMap.set(key, {
					segmentIndex: this.bookingArr[0].json_doc.segments.indexOf(segment),
				})
			}
			segArr.push([])
		}


		for (const leg of ssr) {
			let key = `${leg.departure}_${leg.arrival}`
			if (segMap.get(key)) {
				let val = segMap.get(key)
				segArr[val.segmentIndex].push(leg)
				// if (segArr[val.segmentIndex]) {
				// 	segArr[val.segmentIndex].push(leg)
				// } else {
				// 	segArr.push([])
				// 	segArr[val.segmentIndex].push(leg)
				// }
			}
		}
		return segArr
		// console.log(segMap.get('DEL_BOM'))

	}

	getFlightInfoWithSSR(data) {
		let segArr = [];
		for (let i = 0; i < data.segments.length; i++) {
			const segment = data.segments[i];
			let legArr = []
			for (let j = 0; j < segment.legs.length; j++) {
				let baggages = [];
				let additionalBaggages = [];
				let seats = [];
				let meals = [];
				let ancillaries = [];
				for (let p = 0; p < data.travellers.length; p++) {
					const traveller = data.travellers[p];
					if (traveller.ssr[j].baggages != undefined && traveller.ssr[j].baggages != null) {
						traveller.ssr[j].baggages['hasBaggage'] = true
						baggages.push(traveller.ssr[j].baggages)
					}
					if (traveller.ssr[j].additionalBaggages != undefined && traveller.ssr[j].additionalBaggages != null) {
						traveller.ssr[j].additionalBaggages['hasAdditionalBaggage'] = true
						additionalBaggages.push(traveller.ssr[j].additionalBaggages)
					}
					if (traveller.ssr[j].seats != undefined && traveller.ssr[j].seats != null) {
						traveller.ssr[j].seats['hasSeat'] = true
						seats.push(traveller.ssr[j].seats)
					}
					if (traveller.ssr[j].meals != undefined && traveller.ssr[j].meals != null) {
						traveller.ssr[j].meals['hasMeal'] = true
						meals.push(traveller.ssr[j].meals);
					}
					if (traveller.ssr[j].ancillaries != undefined && traveller.ssr[j].ancillaries != null) {
						traveller.ssr[j].ancillaries['hasAncillary'] = true
						ancillaries.push(traveller.ssr[j].ancillaries);
					}
				}
				if (baggages.length < 1) {
					baggages = null
				}
				if (seats.length < 1) {
					seats = null
				}
				if (meals.length < 1) {
					meals = null
				}
				if (additionalBaggages.length < 1) {
					additionalBaggages = null
				}
				const leg = segment.legs[j];
				let obj = {
					"supplierInfo": {
						"flightNo": leg.flight_number,
						"depatureDateTime": leg.origin_timestamp,
						"departureStation": leg.origin_airport_code,
						"arrivalStation": leg.destination_airport_code
					},
					"baggages": baggages,
					"additionalBaggages": additionalBaggages,
					"seats": seats,
					"meals": meals,
					"ancillaries": ancillaries,
				}
				legArr.push(obj)
			}
			segArr.push(legArr)
		}
		return segArr
	}
	setSSR(travellerIndex) {
		var traveller = this.bookingArr[0].json_doc.travellers[travellerIndex];
		var ssrs = traveller.ssr
		if (ssrs != null) {
			for (let i = 0; i < ssrs.length; i++) {
				var ssr = ssrs[i];
				if (ssr.meals != null) {
					ssr['hasMeal'] = true
				}
				if (ssr.baggages != null) {
					ssr['hasBaggage'] = true
				}
				if (ssr.baggages != null) {
					ssr['hasAdditionalBaggage'] = true
				}
				if (ssr.seats != null) {
					ssr['hasSeat'] = true
				}
				if (ssr.ancillaries != null) {
					ssr['hasAncillary'] = true
				}
			}
		}
	}

	public getSeatEvent(segment) {
		this.seatCharges = 0;
		segment.forEach(legs => {
			if (legs) {
				legs.forEach(v => {
					if (v.seats != null && v.seats != undefined) {
						v.seats.forEach(el => {
							if (el.price != undefined && el.hasSeat == undefined) {
								this.seatRequested = true
								this.responseData = undefined
								this.seatCharges += Number(el.price)
							}
						})
					}
				})
			}
		})
		this.selectedSeatArr = segment;
		this.ngRedux.dispatch(SetSsr(this.selectedSeatArr.flat()));
		let ssr = {
			showPrice: true,
			baggage: this.baggageCharges,
			seats: this.seatCharges,
			meals: this.mealCharges,
			ancillary: this.ancillaryCharges,
			additionalBaggage: this.additionalBaggageCharges,
		}
		this.showProceedToPay = true
		this.calSSREvent.emit(ssr)
	}
	public getBaggageEvent(segment) {
		this.baggageCharges = 0;
		segment.forEach(legs => {
			if (legs) {
				legs.forEach(v => {
					if (v.baggage != null && v.baggage != undefined) {
						v.baggage.forEach(el => {
							if (el.price != undefined && el.hasBaggage == undefined) {
								this.baggageRequested = true
								this.responseData = undefined
								this.baggageCharges += Number(el.price)
							}
						})
					}
				})
			}
		})
		this.selectedBaggageArr = segment;
		this.ngRedux.dispatch(SetSsr(this.selectedBaggageArr.map((seg)=>seg[0])));
		let ssr = {
			showPrice: true,
			baggage: this.baggageCharges,
			seats: this.seatCharges,
			meals: this.mealCharges,
			ancillary: this.ancillaryCharges,
			additionalBaggage: this.additionalBaggageCharges,
		}
		this.showProceedToPay = true
		this.calSSREvent.emit(ssr)
	}

	public getAdditionalBaggageEvent(segment) {
		this.additionalBaggageCharges = 0;
		segment.forEach(legs => {
			if (legs) {
				legs.forEach(v => {
					if (v.additionalBaggage != null && v.additionalBaggage != undefined) {
						v.additionalBaggage.forEach(el => {
							if (el.price != undefined && el.hasAdditionalBaggage == undefined) {
								this.additionalBaggageRequested = true
								this.responseData = undefined
								this.additionalBaggageCharges += Number(el.price)
							}
						})
					}
				})
			}
		})
		this.selectedAddtionalBaggageArr = segment;
		this.ngRedux.dispatch(SetSsr(this.selectedAddtionalBaggageArr.map((seg)=>seg[0])));
		let ssr = {
			showPrice: true,
			baggage: this.baggageCharges,
			seats: this.seatCharges,
			meals: this.mealCharges,
			ancillary: this.ancillaryCharges,
			additionalBaggage: this.additionalBaggageCharges,
		}
		this.showProceedToPay = true
		this.calSSREvent.emit(ssr)
	}

	public getAncillaryEvent(segment) {
		this.ancillaryCharges = 0;
		segment.forEach(legs => {
			if (legs) {
				legs.forEach(v => {
					if (v.ancillary != null && v.ancillary != undefined) {
						v.ancillary.forEach((el) => {
							if(el.selectedItems){
							  el.selectedItems.forEach(element => {
								this.ancillaryCharges += Number(element.totalFare);
							  });
							}
						  });
					}
				})
			}
		})
		this.selectedAncillaryArr = segment;
		this.ngRedux.dispatch(SetSsr(this.selectedAncillaryArr.map((seg)=>seg[0])));
		let ssr = {
			showPrice: true,
			baggage: this.baggageCharges,
			seats: this.seatCharges,
			meals: this.mealCharges,
			ancillary: this.ancillaryCharges,
			additionalBaggage: this.additionalBaggageCharges,
		}
		this.showProceedToPay = true
		this.calSSREvent.emit(ssr)
	}

	public getMealEvent(segment) {
		this.mealCharges = 0;
		segment.forEach(legs => {
			if (legs) {
				legs.forEach(v => {
					if (v.meals != null && v.meals != undefined) {
						v.meals.forEach(el => {
							if (el.price != undefined && el.hasBaggage == undefined) {
								this.mealRequested = true
								this.responseData = undefined
								this.mealCharges += Number(el.price)
							}
						})
					}
				})
			}
		})


		this.selectedMealArr = segment;
		this.ngRedux.dispatch(SetSsr(this.selectedMealArr.flat()));
		let ssr = {
			showPrice: true,
			baggage: this.baggageCharges,
			seats: this.seatCharges,
			meals: this.mealCharges,
			ancillary: this.ancillaryCharges,
			additionalBaggage: this.additionalBaggageCharges,
		}
		this.showProceedToPay = true
		this.calSSREvent.emit(ssr)
	}


	async updateSSR() {
		// this.loading = true;
		// this.commonService.toggleLoader(true)
		this.showRequestLoader = true
		let ssr = {
			baggage: this.selectedBaggageArr,
			seats: this.selectedSeatArr,
			meals: this.selectedMealArr,
			ancillaries: this.selectedAncillaryArr,
			additionalBaggages: this.selectedAddtionalBaggageArr,
		}
		this.updateSSRRequest = await this.bookingService.createSsrRequest(this.bookingArr[0].json_doc, ssr)
		try {
			if (this.updateSSRRequest['ssrs'].flat) {
				this.updateSSRRequest['ssrs'] = this.updateSSRRequest['ssrs'].flat()
			}
		} catch (error) {

		}
		// return false
		// this.openSuccessModalDialog()
		// this.successAddonMessage = "Add-On Added Successfully."
		this.updateSSRRequest['totalAmount'] = this.baggageCharges + this.mealCharges + this.seatCharges + this.ancillaryCharges + this.additionalBaggageCharges
		this.updateSSRRequest['supplierName'] = this.bookingArr[0].json_doc.supplierInfo.SUPPLIER

		if(this.updateSSRRequest && this.updateSSRRequest['ssrs']) {
			for(let singleSSr of this.updateSSRRequest['ssrs'] ) {
				singleSSr['supplierInfo']['BOOKING_REF_TS_KEY_FOR_LOGS'] = this.xmlSearchLogsKey
			}
		}

		this.showRequestLoader = true
		this.commonService.postAddOnsSSR(`addon`, this.updateSSRRequest).subscribe(res => {
			this.responseData = res
		
			if(this.responseData.status == "SUCCESS") {
				this.showGlobalErrorMessage("success-box", this.responseData['message'], undefined)
			}

			if(this.responseData.status == "FAILED") {
				this.showGlobalErrorMessage("error-box", this.responseData['message'], undefined)
			}
			
			// this.getSSR()
			this.mealBooked = this.checkIFFailed('meals', this.responseData)
			this.seatBooked = this.checkIFFailed('seats', this.responseData)
			this.baggageBooked = this.checkIFFailed('baggages', this.responseData)
			this.ancillaryBooked = this.checkIFFailed('ancillaries', this.responseData)
			this.additionalBaggageBooked = this.checkIFFailed('additionalBaggage', this.responseData)
			this.showRequestLoader = false
			this.showSSRMessage = true
			this.selectedSeatArr = [];
			this.selectedBaggageArr = [];
			this.selectedMealArr = [];
			this.selectedAddtionalBaggageArr = [];
			this.selectedAncillaryArr = [];
			this.ngRedux.dispatch(SetSsr(this.selectedSeatArr.map((seg)=>seg[0])));
			this.ngRedux.dispatch(SetSsr(this.selectedBaggageArr.map((seg)=>seg[0])));
			this.ngRedux.dispatch(SetSsr(this.selectedAddtionalBaggageArr.map((seg)=>seg[0])));
			this.ngRedux.dispatch(SetSsr(this.selectedAncillaryArr.map((seg)=>seg[0])));
			this.ngRedux.dispatch(SetSsr(this.selectedMealArr.map((seg)=>seg[0])));
			this.baggageCharges = 0
			this.seatCharges = 0
			this.mealCharges = 0
			this.ancillaryCharges = 0
			this.additionalBaggageCharges = 0
			let ssr = {
				showPrice: false,
				baggage: this.baggageCharges,
				seats: this.seatCharges,
				meals: this.mealCharges,
				ancillary: this.ancillaryCharges,
				additionalBaggage: this.additionalBaggageCharges,
			}
			this.showProceedToPay = false
			this.calSSREvent.emit(ssr)
			this.resetData.emit()
			this.ngRedux.dispatch(ToggleAddonSummary(false)); // close addon fare breakup
			// this.loading = false;
		}, err => {
			// this.loading = false;
			this.showRequestLoader = false
		})
	}

	showGlobalErrorMessage(type, message, detail) {
		if(!this.messageList){
		  this.messageList = {
			status : true,
			messageList : []
		  }
		}
		this.messageList["status"] = true;
		let obj = {
		  warningType: type,
		  message: message,
		  detail: detail,
		};
		this.messageList["messageList"].push(obj);
		this.notificationService.showMessage(this.messageList);
	}	

	closeSSRMessage() {
		this.showSSRMessage = false
	}
	reloadPage() {
		setTimeout(() => {
			this.resetData.emit()
			this.getSSR()
			this.mealBooked = this.checkIFFailed('meals', this.responseData)
			this.seatBooked = this.checkIFFailed('seats', this.responseData)
			this.baggageBooked = this.checkIFFailed('baggages', this.responseData)
			this.showRequestLoader = false
			this.showSSRMessage = true
			this.selectedSeatArr = [];
			this.selectedBaggageArr = [];
			this.selectedMealArr = [];
		}, 5000);
	}

	checkIFFailed(ssrName, ssrResponse) {
		let flag = true

		for (let i = 0; i < ssrResponse.ssrs.length; i++) {
			const ssrs = ssrResponse.ssrs[i];
			if (ssrs[ssrName] != undefined && ssrs[ssrName] != null) {
				for (let j = 0; j < ssrs[ssrName].length; j++) {
					const ssr = ssrs[ssrName][j];
					if (ssr.status != 'SUCCESS') {
						flag = false
					}
					if (ssrName == 'meals') {
						this.mealRequested = true
					}
					if (ssrName == 'seats') {
						this.seatRequested = true
					}
					if (ssrName == 'baggages') {
						this.baggageRequested = true
					}
					if (ssrName == 'ancillaries') {
						this.ancillaryRequested = true
					}
					if (ssrName == 'additionalBaggage') {
						this.additionalBaggageRequested = true
					}
				}
			}
		}
		return flag
	}


	async setReIssueSearchFormData() {
		// this.searchForm.reset()
		let travellers = this.bookingArr[0].json_doc.travellers
		
		let adultCount = 0
		let childCount = 0
		let infantCount = 0
		for (const traveller of travellers) {
			if (traveller.type == 'ADT') {
				adultCount++
			}
			if (traveller.type == 'CHD') {
				childCount++
			}
			if (traveller.type == 'INF') {
				infantCount++
			}
		}
		this.searchForm.controls.noOfAdult.setValue(adultCount)
		this.searchForm.controls.noOfChild.setValue(childCount)
		this.searchForm.controls.noOfInfant.setValue(infantCount)
		this.isOriginDomestic = this.bookingArr[0].json_doc.is_domestic
		this.isDestinationDomestic = this.bookingArr[0].json_doc.is_domestic
		// 	let departureAirport = this.bookingArr[0].json_doc.segments[0].legs[0].origin_airport_name
		// 	let departureAirportCode = this.bookingArr[0].json_doc.segments[0].legs[0].origin_airport_code
		// 	this.departureObj = {
		// 			code : departureAirportCode,
		// 			city : departureAirport
		// 	}
		// 	let segLength = this.bookingArr[0].json_doc.segments.length
		// 	let lastSegmentlegs = this.bookingArr[0].json_doc.segments[segLength-1].legs
		// 	let destinationAirport = lastSegmentlegs[lastSegmentlegs.length-1].destination_airport_name
		// 	let destinationAirportCode = lastSegmentlegs[lastSegmentlegs.length-1].destination_airport_code
		// 	this.destinationObj = {
		// 		code : destinationAirportCode,
		// 		city : destinationAirport
		// }
		// this.searchForm.controls.from.setValue(departureAirport)
		// this.searchForm.controls.to.setValue(destinationAirport)
		this.searchForm.controls.depDate.setValue(new Date(this.bookingArr[0].json_doc.segments[0].legs[0].origin_timestamp))
		this.searchForm.controls.depDateText.setValue(moment(new Date(this.bookingArr[0].json_doc.segments[0].legs[0].origin_timestamp)).format('DD/MM/YYYY'))

		this.city = this.searchForm.get('city') as FormArray
		this.city.controls = [];
		let pnrData;
		if((this.bookingSupplier == 'AMADEUS_V4' || this.bookingSupplier == 'AMADEUS' || this.bookingSupplier == 'AMADEUS_STUD')){
			pnrData = await this.postService.retrievePNR(`${environment.baseUrl}/edge/amadeus/amadeus/v4.0/retrieve/default/${this.bookingArr[0].json_doc.gds_pnr}`)
		}
		
		if(pnrData){

			let segments = JSON.parse(JSON.stringify(this.bookingArr[0].json_doc.segments))
			// let originCity = segments[0].legs[0].origin_airport_code
			// let lastSegment = segments[segments.length-1]
			// let destinationCity = lastSegment.legs[lastSegment.legs.length-1].destination_airport_code
			// if(originCity != destinationCity && segments.length > 0){
			// 	let legs = segments[0].legs;
			// 	legs = legs.concat(lastSegment.legs)
			// 	segments = [segments[0]]
			// 	segments[0].legs = legs
			// }
			for (const indx in segments) {
				if (Object.prototype.hasOwnProperty.call(segments, indx)) {
					const segment = segments[indx];
					for (const index in segment.legs) {
						if (Object.prototype.hasOwnProperty.call(segment.legs, index)) {
							const leg = segment.legs[index];
							let itemIndex = this.DynamicFormControls.controls.length-1
							let nextIndex = itemIndex+1
							this.AddCity(this.searchForm.value, Number(nextIndex));
							let depDate = leg.origin_timestamp
							let depDateText = leg.origin_timestamp
							if (depDate != "") {
								depDate = new Date(leg.origin_timestamp)
								depDateText = moment(new Date(leg.origin_timestamp)).format('DD/MM/YYYY')
							}
							this.DynamicFormControls.controls[Number(nextIndex)]['controls'].isTravelled.setValue(segments.isTravelled);
							this.DynamicFormControls.controls[Number(nextIndex)]['controls'].depDate.setValue(depDate);
							this.DynamicFormControls.controls[Number(nextIndex)]['controls'].depDateText.setValue(depDateText);
							let departureAirport = leg.origin_airport_city ? leg.origin_airport_city : leg.origin_airport_name
							let departureAirportCode = leg.origin_airport_code
							let departureObj = {
								code: departureAirportCode,
								city: departureAirport
							}
							let destinationAirport = leg.destination_airport_city ? leg.destination_airport_city : leg.destination_airport_name
							let destinationAirportCode = leg.destination_airport_code
							let destinationObj = {
								code: destinationAirportCode,
								city: destinationAirport
							}
							if (Number(nextIndex) == 0) {
							}
							let sectorDataFromPnr;
							if(pnrData){
								sectorDataFromPnr = pnrData.sector.filter(data=> {
									return data.name == `${departureAirportCode}/${destinationAirportCode}`
								})
								if(sectorDataFromPnr && sectorDataFromPnr.length > 0){
									let connectionType = sectorDataFromPnr[0].connectionType ? sectorDataFromPnr[0].connectionType : ''
									this.DynamicFormControls.controls[Number(nextIndex)]['controls'].originConnectionType.setValue(connectionType);
									if(this.DynamicFormControls.controls[Number(nextIndex-1)]){
										this.DynamicFormControls.controls[Number(nextIndex-1)]['controls'].destinationConnectionType.setValue(connectionType);
									}
									let isSelected = true
									if(moment(leg.origin_timestamp).isSameOrBefore(moment())){
										isSelected = false
									}
									this.DynamicFormControls.controls[Number(nextIndex)]['controls'].isSelected.setValue(isSelected);
									if(connectionType == 'X'){
										let currentIndex = pnrData.sector.indexOf(sectorDataFromPnr[0])
										let departureFilterData = pnrData.sector.filter((d,index)=> {
											return index < currentIndex && (d.connectionType == 'S' || d.connectionType == 'O')
										})
										let departureData = departureFilterData[departureFilterData.length-1]
										let depDate = departureData.departureDateTime
										let depDateText = departureData.departureDateTime
										if (depDate != "") {
											depDate = new Date(departureData.departureDateTime)
											depDateText = moment(new Date(departureData.departureDateTime)).format('DD/MM/YYYY')
										}
										this.DynamicFormControls.controls[Number(nextIndex)]['controls'].depDate.setValue(depDate);
										this.DynamicFormControls.controls[Number(nextIndex)]['controls'].depDateText.setValue(depDateText);
									}
								}
							}
							this.departureObj[nextIndex] = departureObj
							this.destinationObj[nextIndex] = destinationObj
	
							this.departureObjOriginal[nextIndex] = departureObj
							this.destinationObjOriginal[nextIndex] = destinationObj
							// this.changeDestination(departureObj, 'departure', Number(nextIndex))
							// this.changeDestination(destinationObj, 'destination', Number(nextIndex))
							if (this.DynamicFormControls.controls[nextIndex] != undefined) {
								this.DynamicFormControls.controls[nextIndex]['controls'].to.setValue(destinationObj['city']);
							}
							if (this.DynamicFormControls.controls[nextIndex] != undefined) {
								this.DynamicFormControls.controls[nextIndex]['controls'].from.setValue(departureObj['city']);
							}
							this.departureDateChange(this.searchForm.value, Number(nextIndex))
								}
							}
				}
			}
		}else{
			for (const index in this.bookingArr[0].json_doc.segments) {
				if (Object.prototype.hasOwnProperty.call(this.bookingArr[0].json_doc.segments, index)) {
					const segment = this.bookingArr[0].json_doc.segments[index];
					this.AddCity(this.searchForm.value, Number(index));
					let depDate = segment.legs[0].origin_timestamp
					let depDateText = segment.legs[0].origin_timestamp
					if (depDate != "") {
						depDate = new Date(segment.legs[0].origin_timestamp)
						depDateText = moment(new Date(segment.legs[0].origin_timestamp)).format('DD/MM/YYYY')
					}
					this.DynamicFormControls.controls[Number(index)]['controls'].depDate.setValue(depDate);
					this.DynamicFormControls.controls[Number(index)]['controls'].depDateText.setValue(depDateText);
					let departureAirport = segment.legs[0].origin_airport_city ? segment.legs[0].origin_airport_city : segment.legs[0].origin_airport_name
					let departureAirportCode = segment.legs[0].origin_airport_code
					let departureObj = {
						code: departureAirportCode,
						city: departureAirport
					}
					let legs = segment.legs
					let destinationAirport = legs[legs.length - 1].destination_airport_city ? legs[legs.length - 1].destination_airport_city : legs[legs.length - 1].destination_airport_name
					let destinationAirportCode = legs[legs.length - 1].destination_airport_code
					let destinationObj = {
						code: destinationAirportCode,
						city: destinationAirport
					}
					if (Number(index) == 0) {
					}
					this.departureObj[index] = departureObj
					this.destinationObj[index] = destinationObj
	
					this.departureObjOriginal[index] = departureObj
					this.destinationObjOriginal[index] = destinationObj
					// this.changeDestination(departureObj, 'departure', Number(index))
					// this.changeDestination(destinationObj, 'destination', Number(index))
					if (this.DynamicFormControls.controls[index] != undefined) {
						this.DynamicFormControls.controls[index]['controls'].to.setValue(destinationObj['city']);
					}
					if (this.DynamicFormControls.controls[index] != undefined) {
						this.DynamicFormControls.controls[index]['controls'].from.setValue(departureObj['city']);
					}
					this.departureDateChange(this.searchForm.value, Number(index))
				}
			}
	
		}
	}

	departureDateChange(value, i) {
		let deptDate = value.city[i].depDate
		if (deptDate == "") {
			deptDate = new Date()
		}
		let currentSelectionDate = new Date();
		let currentSelectionDateText = moment(deptDate).format('DD/MM/YYYY');


		if (this.DynamicFormControls.controls[Number(i)]) {
			currentSelectionDate = this.DynamicFormControls.controls[Number(i)]['controls'].depDate.value
			this.DynamicFormControls.controls[Number(i)]['controls'].depDateText.setValue(currentSelectionDateText);
		}
		if (this.DynamicFormControls.controls[Number(i) - 1]) {
			let previousDate = this.DynamicFormControls.controls[Number(i - 1)].value.depDate;
			if (moment(currentSelectionDate).isBefore(previousDate)) {
				this.DynamicFormControls.controls[Number(i) - 1]['controls'].isSelected.setValue(true)
				this.DynamicFormControls.controls[Number(i) - 1]['controls'].depDate.setValue(currentSelectionDate);
				this.DynamicFormControls.controls[Number(i)]['controls'].depDateText.setValue(currentSelectionDateText);

			}
		}
		if (this.DynamicFormControls.controls[Number(i) + 1]) {
			let nextDate = this.DynamicFormControls.controls[Number(i + 1)].value.depDate;
			if (moment(currentSelectionDate).isAfter(nextDate)) {
				this.DynamicFormControls.controls[Number(i) + 1]['controls'].isSelected.setValue(true)
				this.DynamicFormControls.controls[Number(i) + 1]['controls'].depDate.setValue(currentSelectionDate);
				this.DynamicFormControls.controls[Number(i)]['controls'].depDateText.setValue(currentSelectionDateText);

			}
		}
		if (this.originalSearchForm)
			console.log(`Original Form after date change of index : ${i} : `, this.originalSearchForm.value);
	}

	changeDestination(option, param, i) {
		let obj = {}
		if (option.airport_CODE != undefined) {
			obj = {
				code: option.airport_CODE,
				city: option.result
			};
		} else {
			obj = {
				code: option.code,
				city: option.city
			};
		}

		if (param == "destination") {
			this.destinationObj[i] = obj;
			localStorage.setItem('ROUTE_DESTINATION', JSON.stringify(obj));
			this.searchForm.controls.to.setValue(obj['city']);
			// if (this.DynamicFormControls.controls[i + 1]) {
			// 	this.DynamicFormControls.controls[i + 1]['controls'].from.setValue(obj['city']);
			// 	this.DynamicFormControls.controls[i + 1]['controls'].isSelected.setValue(true);

			// }

			if (option.result != undefined) {
				if (option.result.includes("IN")) {
					this.isDestinationDomestic = true;
				} else {
					this.isDestinationDomestic = false;
				}
			}
			else {
				if (option.city.includes("IN")) {
					this.isDestinationDomestic = true;
				} else {
					this.isDestinationDomestic = false;
				}
			}


		} else {
			this.departureObj[i] = obj;
			localStorage.setItem('ROUTE_ORIGIN', JSON.stringify(obj));
			this.searchForm.controls.from.setValue(obj['city']);
			// if (this.DynamicFormControls.controls[i + 1]) {
			// 	this.DynamicFormControls.controls[i + 1]['controls'].to.setValue(obj['city']);
			// 	this.DynamicFormControls.controls[i + 1]['controls'].isSelected.setValue(true);

			// }
			if (option.result != undefined) {
				if (option.result.includes("IN")) {
					this.isOriginDomestic = true;
				} else {
					this.isOriginDomestic = false;
				}
			}
			else {
				if (option.city.includes("IN")) {
					this.isOriginDomestic = true;
				} else {
					this.isOriginDomestic = false;
				}
			}
		}
	}

	bindDestination(val, param) {
		if (val.length >= 3) {
			this.postService
				.getMethod(`${environment.url}/suggest?in=${val}`)
				.subscribe(res => {
					let data = JSON.parse(res["_body"]);
					if (param == "destination") {
						this.destinationCity = data;
					} else {
						this.departureCity = data;
					}
				});
		}
	}

	onFocused(e) {
		event.currentTarget['value'] = "";
	}

	reIssueSectorSelection(event, i) {
		let currentControl = this.searchForm.get('city')["controls"][Number(i)]['controls']
		let currentOriginalControl = this.originalSearchForm.get('city')["controls"][Number(i)]['controls']
		let nextControl = this.searchForm.get('city')["controls"][Number(i)]['controls']
		if (this.searchForm.get('city')["controls"][Number(i) + 1]) {
			nextControl = this.searchForm.get('city')["controls"][Number(i) + 1]['controls']
		}
		let nextOriginalControl = this.originalSearchForm.get('city')["controls"][Number(i)]['controls']
		if (this.originalSearchForm.get('city')["controls"][Number(i) + 1]) {
			nextOriginalControl = this.originalSearchForm.get('city')["controls"][Number(i) + 1]['controls']
		}

		let previousControl = this.searchForm.get('city')["controls"][Number(i)]['controls']
		if (this.searchForm.get('city')["controls"][Number(i) - 1]) {
			previousControl = this.searchForm.get('city')["controls"][Number(i) - 1]['controls']
		}
		let previousOriginalControl = this.originalSearchForm.get('city')["controls"][Number(i)]['controls']
		if (this.originalSearchForm.get('city')["controls"][Number(i) - 1]) {
			previousOriginalControl = this.originalSearchForm.get('city')["controls"][Number(i) - 1]['controls']
		}

		if (!event.target.checked) {
			currentControl.depDate.setValue(currentOriginalControl.depDate.value)
			currentControl.depDateText.setValue(currentOriginalControl.depDateText.value);
			currentControl.from.setValue(currentOriginalControl.from.value)
			currentControl.to.setValue(currentOriginalControl.to.value)
			this.departureObj[i] = Object.assign({}, this.departureObjOriginal[i])
			this.destinationObj[i] = Object.assign({}, this.destinationObjOriginal[i])
			nextControl.from.setValue(nextOriginalControl.from.value)
			nextControl.to.setValue(nextOriginalControl.to.value)
			if (this.departureObj[i + 1] && this.departureObjOriginal[i + 1]) {
				this.departureObj[i + 1] = Object.assign({}, this.departureObjOriginal[i + 1])
				this.destinationObj[i + 1] = Object.assign({}, this.destinationObjOriginal[i + 1])
			}
			if (this.departureObj[i - 1] && this.departureObjOriginal[i - 1]) {
				this.departureObj[i - 1] = Object.assign({}, this.departureObjOriginal[i - 1])
				this.destinationObj[i - 1] = Object.assign({}, this.destinationObjOriginal[i - 1])
			}
			if (moment(nextControl.depDate.value).isBefore(moment(currentControl.depDate.value))) {
				nextControl.depDate.setValue(currentControl.depDate.value)
				nextControl.depDateText.setValue(currentControl.depDateText.value)
			}
			if (moment(previousControl.depDate.value).isAfter(moment(currentControl.depDate.value))) {
				previousControl.depDate.setValue(currentControl.depDate.value)
				previousControl.depDateText.setValue(currentControl.depDateText.value);
			}

			previousControl.from.setValue(previousOriginalControl.from.value)
			previousControl.to.setValue(previousOriginalControl.to.value)

		} else {

			// currentControl.from.setValue(previousControl.to.value)
			// currentControl.to.setValue(previousControl.from.value)
			if (moment(currentControl.depDate.value).isBefore(moment(previousControl.depDate.value))) {
				currentControl.depDate.setValue(previousControl.depDate.value)
				currentControl.depDateText.setValue(previousControl.depDateText.value)
			}
			if (moment(currentControl.depDate.value).isAfter(moment(nextControl.depDate.value))) {
				currentControl.depDate.setValue(nextControl.depDate.value)
				currentControl.depDateText.setValue(nextControl.depDateText.value)
			}
		}
	}



	resetAdonMarkupForm(){
		this.isAffiliateAdonModelOpened = false
		this.searchForm.controls.newAddonMarkup.setValue(this.totalAffilateAdonMarkup);
	}

	openAffiliateAdonMarkupModel(){
		this.isAffiliateAdonModelOpened = true
		this.openAddonMarkupModel.nativeElement.click()
		this.resetAdonMarkupForm()
	}

	issueTicketWithMarkup(values){
		let bookingId = this.bookingArr[0].json_doc.booking_ref
		this.isAffiliateAdonModelOpened = true
		localStorage.removeItem(`${bookingId}_additional_markup`)
		let additionalMarkup = this.searchForm.controls.newAddonMarkup.value
		localStorage.setItem(`${bookingId}_additional_markup`, additionalMarkup)
		this.submit(values)
	}

	issueTicketWithoutMarkup(values){
		this.isAffiliateAdonModelOpened = true
		let bookingId = this.bookingArr[0].json_doc.booking_ref
		localStorage.setItem(`${bookingId}_additional_markup`, "0")
		this.submit(values)
	}


	submit(values) {
		if(this.totalAffilateAdonMarkup>0 && this.isAffiliateAdonModelOpened==false){
			this.openAffiliateAdonMarkupModel()
		} else {
		localStorage.setItem("setSearchState", "false");
		let supplier = this.bookingArr[0].json_doc.segments[0].booking_supplier
		if (this.bookingArr[0].json_doc.supplierInfo && this.bookingArr[0].json_doc.supplierInfo.SUPPLIER) {
			supplier = this.bookingArr[0].json_doc.supplierInfo.SUPPLIER
		}

		values.isOriginDomestic = this.isOriginDomestic;
		values.isDestinationDomestic = this.isDestinationDomestic;
		if (!this.isOriginDomestic || !this.isDestinationDomestic) {
			values.isDomestic = false;
		}
		else {
			values.isDomestic = true;
		}
		let selectedSegments = values.city.filter(option => {
			return option.isSelected == true
		})
		let nonSelectedSegments = values.city.filter(option => {
			return option.isSelected == false
		})
		let nonCancellingSector = []
		for (const nonSelected of nonSelectedSegments) {
			nonCancellingSector.push(`${this.departureObj[nonSelected.itemIndex].code}-${this.destinationObj[nonSelected.itemIndex].code}`)
		}
		values['reissuanceSearchFilters'] = {
			supplier: supplier,
			priceType: this.bookingArr[0].json_doc.fare_type,
			"supplierInfo": {
				"pnr": this.bookingArr[0].json_doc.gds_pnr,
				"nonCancellingSectors": nonCancellingSector,
				"productClass":this.bookingArr[0].json_doc.supplierInfo && this.bookingArr[0].json_doc.supplierInfo.hasOwnProperty("productClass")?this.bookingArr[0].json_doc.supplierInfo.productClass:""
			}
		}

		if (selectedSegments.length > 0) {
			if(this.bookingSupplier == 'AMADEUS_V4' || this.bookingSupplier == 'AMADEUS' || this.bookingSupplier == 'AMADEUS_STUD' || this.bookingSupplier == 'VERTEIL'){
				values.departureObj = this.departureObj[0];
				values.destinationObj = this.destinationObj[this.destinationObj.length-1];
			}else{
				values.destinationObj = this.destinationObj[0];
				values.departureObj = this.departureObj[0];
			}
			
			values.depDate = values.city[0].depDate
			CommonService.selectedFareType = this.bookingArr[0].json_doc['fare_type'] == 'STUD' || this.bookingArr[0].json_doc['fare_type'] == 'SRCT' ? this.bookingArr[0].json_doc['fare_type'] : ''
			if ((values.departureObj.code != values.destinationObj.code && values.city.length == 1 && (this.bookingSupplier == 'AMADEUS_V4' || this.bookingSupplier == 'AMADEUS' || this.bookingSupplier == 'AMADEUS_STUD')) || this.bookingArr[0].json_doc.segments.length == 1) {
				if(this.bookingSupplier == 'AMADEUS_V4' || this.bookingSupplier == 'AMADEUS' || this.bookingSupplier == 'AMADEUS_STUD'){
					let originCity = values.city.filter(data => {
						return data.destinationConnectionType == 'O'
					})
					if(originCity && originCity.length > 0){
						values.depDate = originCity[0].depDate
					}else{
						values.depDate = values.city[values.city.length-1].depDate
					}
				}
				this.callGetAvailability(values, false, false, 'OUTBOUND');
			} else {
				if(this.bookingSupplier == 'AMADEUS_V4' || this.bookingSupplier == 'AMADEUS' || this.bookingSupplier == 'AMADEUS_STUD'){
					let originCity = values.city.filter(data => {
						return data.destinationConnectionType == 'O'
					})
					if(originCity && originCity.length > 0){
						values.depDate = originCity[0].depDate
					}
					let destinationCity = values.city.filter(data => {
						return data.originConnectionType == 'O'
					})
					if(destinationCity && destinationCity.length > 0){
						let destinationObj = this.destinationObj.filter(data=> {return destinationCity[0].from == data.city})
						values.destinationObj = destinationObj[0]
						
						let destinationCityDate = values.city[values.city.length-1]
						values.returnDate = destinationCityDate.depDate
					}
				}else{
					values.returnDate = values.city[1].depDate
				}
				values.isReturn = true
				this.callGetAvailability(values, true, true, 'INBOUND');
			}
			try {
				this.commonService.addUserBookingActivity('re_issue','Search request for re-issue',this.bookingArr[0].json_doc.booking_ref,this.bookingArr[0].json_doc.gds_pnr)

			} catch (error) {
				
			}
		} else {
			alert("Select Atleast one sector to re-issue")
	  }
	}
	}

	callGetAvailability(values, isReturn, trip, direction) {
		let request = this.commonService.makeSearchRequest(values, isReturn, direction);
		request['reissuanceSearchFilters'] = values.reissuanceSearchFilters
		let url = 'search-filter-for-reissuance';
		localStorage.removeItem("flightSearchId");
		this.commonService.toggleLoader(true)
		
		  
		this.postService.postMethod(`${environment.url}/${url}`, request).subscribe(data => {
			let jsonData = data;
			let searchId = jsonData['searchId'];
			let ref = this.bookingArr[0].json_doc.booking_ref
			if (direction == 'OUTBOUND') {
				localStorage.setItem('searchId', searchId);
				localStorage.setItem('OUTBOUND_SUPPLIER_COUNT', data["supplierCount"]);
				localStorage.setItem('INBOUND_SUPPLIER_COUNT', data["supplierCount"]);
				localStorage.setItem('SPECIALROUNDTRIP_SUPPLIER_COUNT', data["supplierCount"]);
				// this.outboundResponse = true;
			}
			else if (direction == 'INBOUND') {
				localStorage.setItem('returnSearchId', searchId);
				localStorage.setItem('OUTBOUND_SUPPLIER_COUNT', data["supplierCount"]);
				localStorage.setItem('INBOUND_SUPPLIER_COUNT', data["supplierCount"]);
				localStorage.setItem('SPECIALROUNDTRIP_SUPPLIER_COUNT', data["supplierCount"]);
				// this.inboundResponse = true;
			}

			this.moveToResultPage(trip, searchId, request, ref);
			this.commonService.toggleLoader(false)
		},
			error => {
				this.commonService.toggleLoader(false)
				this.showError = true
				if (error && error.error && error.error.statusMessage) {
					this.errorMessage = error.error.statusMessage;
				} else {
					this.errorMessage = 'Something went wrong. If this issue persists please contact back office. '
				}
				window.scrollTo(0, 0)
			});
	}

	moveToResultPage(direction, searchId, values, ref) {
		let mode = "re-issue"
		let flightDirection = "one-way"
		if (direction) {
			localStorage.setItem("flightSearchId", searchId);
			searchId = searchId + "_RETURN_" + searchId;
			sessionStorage.setItem("ActiveSearchRequest", JSON.stringify(values));
			flightDirection = "return";
			let isGDSSpecial = false
			if(this.bookingArr[0].json_doc.segments.length > 1 && (this.bookingSupplier == 'AMADEUS_V4' || this.bookingSupplier == 'AMADEUS' || this.bookingSupplier == 'AMADEUS_STUD')){
				isGDSSpecial = true
			}
			this.router.navigate(['pages/flight', { searchId, ref, mode, flightDirection,isGDSSpecial }]);
			this.commonService.sendFightSearchId(searchId);
		}
		else {
			localStorage.setItem("flightSearchId", searchId);
			sessionStorage.setItem("ActiveSearchRequest", JSON.stringify(values));
			this.router.navigate(["pages/flight", { searchId, ref, mode, flightDirection }]);
			this.commonService.sendFightSearchId(searchId);
			
		}
	}
	AddCity(value, i) {
		this.city = this.searchForm.get('city') as FormArray;
		if (this.city.controls.length < 5) {
			this.city.push(this.buildItem(i));
		}
	}

	buildItem(i) {
		return this.fb.group({
			from: ["", Validators.required],
			to: ["", Validators.required],
			depDate: ["", Validators.required],
			depDateText: ["", Validators.required],
			serviceClass: ["ECONOMY"],
			passAdults: [1],
			passTotal: ["1 Traveller"],
			passChildren: [0],
			passInfants: [0],
			flightClass: [0],
			isSelected: [false],
			itemIndex: [i],
			isTravelled : [false],
			originConnectionType : [''],
			destinationConnectionType : ['']
		})
	}

	get DynamicFormControls() {
		return <FormArray>this.searchForm.get('city');
	}

	copyFormControl(control: AbstractControl) {
		if (control instanceof FormControl) {
			return new FormControl(control.value);
		} else if (control instanceof FormGroup) {
			const copy = new FormGroup({});
			Object.keys(control.controls).forEach(key => {
				copy.addControl(key, this.copyFormControl(control.controls[key]));
			});
			return copy;
		} else if (control instanceof FormArray) {
			const copy = new FormArray([]);
			control.controls.forEach(control => {
				copy.push(this.copyFormControl(control));
			})
			return copy;
		}
	}

	async openSplitTravellerModal() {	
		let direction = this.bookingArr[0].json_doc.supplierInfo.direction;
		if (environment.allowCancellation == true && direction != 'MULTICITY') {
			let result =  await this.getWebCheckIn('split', 'split')
			if(result === true){
			this.openReissueErrorModalFn()
	 		}
			this.initialiseSplitTravellerForm()
			this.openSplitTravellerModalFn()
		}
		else {
			this.cancellationWarningMessage = `Please contact to back office team to Split travellers.`
			this.openModalDialog()
			return false
		} 
	}
	private openSplitTravellerModalFn(): void {
		this.openSplitPnrModal.nativeElement.click()
	}

	public closeSplitPnrModalFn(): void {
		this.closeSplitPnrModal.nativeElement.click()
	}

	initialiseSplitTravellerForm() {
		this.splitTravellersForm = this.fb.group({
			"travellers" : this.fb.array([])
		});
		this.allTravellers = this.splitTravellersForm.get('travellers') as FormArray;
		this.allTravellers.controls = []
		for (const traveller of this.bookingArr[0].json_doc.travellers) {
			this.allTravellers.push(this.buildSplitTravellersItem(traveller));
		}
		console.log(this.splitTravellersForm.value)
	}

	buildSplitTravellersItem(traveller) {
		return this.fb.group({
			title: [traveller.title, Validators.required],
			firstName: [traveller.first_name ? traveller.first_name : "", Validators.required],
			lastName: [traveller.last_name ? traveller.last_name : "", Validators.required],
			type: [traveller.type ? traveller.type : "", Validators.required],
			status: [traveller.status ? traveller.status : "", Validators.required],
			paxSelected: [traveller.paxSelected ? traveller.paxSelected : false, Validators.required],
			associateParent: [traveller.parent ? traveller.parent : null, Validators.required],
			ticketNo: [traveller.ticket && traveller.ticket.number ? traveller.ticket.number : "", Validators.required],
		})
	}
	selectTraveller(e, i) {
		let status = e.target.checked ? 'REQUESTED' : 'TICKETED'
		this.allTravellers.controls[i]["controls"].status.setValue(status)
		for (const traveller of this.bookingArr[0].json_doc.travellers) {
			let idx = this.bookingArr[0].json_doc.travellers.indexOf(traveller)
			if (traveller.parent != null && Number(traveller.parent) == i) {
				this.allTravellers.controls[idx]["controls"].paxSelected.setValue(e.target.checked)
				this.allTravellers.controls[idx]["controls"].status.setValue(status)
			}
		}
		console.log(this.splitTravellersForm.value)

		if (this.checkIsAllTravellerSelected()) {
			e.target.checked = false
			this.allTravellers.controls[i]['controls'].paxSelected.setValue(false)
			this.showTravellerWarningMessage = 'You cant split all the traveller'

			// this.isTravellerValidSelected = false
			this.allTravellers.controls[i]["controls"].status.setValue('TICKETED')

		} else {
			this.checkIsTravellerValidSelected()
		}


	}

	checkIsAllTravellerSelected() {
		let travellers = this.splitTravellersForm.value.travellers
		let count = 0
		for (let traveller of travellers) {
			if (traveller.status == 'REQUESTED') {
				count += 1
			}
		}
		return count == travellers.length ? true : false
	}
	get DynamicSplitTravellersFormControls() {
		if (this.splitTravellersForm) return <FormArray>this.splitTravellersForm.get('travellers');
	}

	submitSplitPnrRequest() {
		try {
			this.apiSuccessMessage = ""
			this.apiErrorMessage = ""

			this.commonService.toggleLoader(true)
			let requestTravellers = []
			for (const traveller of this.splitTravellersForm.value.travellers) {
				if (traveller.status == 'REQUESTED') requestTravellers.push(traveller)
			}
			let splitObj = {
				pnr: this.bookingArr[0].json_doc.gds_pnr,
				travellers: requestTravellers,
				isSplit: true
			}

			let reqObj = {
				splitObj: splitObj,
				bookingObj: this.bookingArr[0]
			}
			this.showSplitResponse = false;
			try {
				this.commonService.addUserBookingActivity('split',`Split Requested`,this.bookingArr[0].json_doc.booking_ref,this.bookingArr[0].json_doc.gds_pnr)

			} catch (error) {
				
			}
			this.commonService.postBooking(`booking/splitBooking`, reqObj).subscribe(res => {
				this.commonService.toggleLoader(false)
				this.resetData.emit()
				let splitBookingResp = JSON.parse(res['_body'])
				this.newSplitBooking = splitBookingResp['splitedBookingObj']
				this.showSplitResponse = true
				// this.closeSplitPnrModalFn()
				console.log(res)
			}, err => {
				this.showSplitResponse = true
				this.commonService.toggleLoader(false)
				console.log(err)
				// this.apiErrorMessage = err
				// this.apiErrorMessage = 'Traveller split failed'
				// this.openMessageModalFn()
			})
		} catch (error) {
			this.commonService.toggleLoader(false)

		}

	}



	//CANCEL REQUEST

	openCancelSegmentModal(isFullCancel) {

		this.initialiseCancelSectorForm(isFullCancel)
		if (isFullCancel) {
			this.moveSectorStep2()
		}
		this.openCancelSectorModalFn()


	}
	private openCancelSectorModalFn(): void {
		this.openCancelSectorModal.nativeElement.click()
	}

	public closeCancelSectorModalFn(): void {
		this.closeCancelSectorModal.nativeElement.click()
	}

	initialiseCancelSectorForm(isFullCancel) {
		this.cancelSectorForm = this.fb.group({
			"segments": this.fb.array([])
		});
		this.allSegments = this.cancelSectorForm.get('segments') as FormArray;
		this.allSegments.controls = []
		for (const segment of this.bookingArr[0].json_doc.segments) {
			let originLeg = segment.legs[0]
			let destinationLeg = segment.legs[segment.legs.length - 1]
			let flightNumber = originLeg.marketing_airline_code + " " + originLeg.flight_number
			let origin = originLeg.origin_airport_code;
			let originName = originLeg.origin_airport_city;
			let destination = destinationLeg.destination_airport_code;
			let destinationName = destinationLeg.destination_airport_city;
			let departureDateTime = moment(originLeg.origin_timestamp, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm');
			let arrivalDateTime = moment(destinationLeg.destination_timestamp, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm');
			let sector = { flightNumber, origin, originName, destination, destinationName, departureDateTime, arrivalDateTime }
			this.allSegments.push(this.buildCancelSectorItem(sector, isFullCancel));
		}
		console.log(this.cancelSectorForm.value)
	}

	buildCancelSectorItem(sector, isFullCancel) {
		return this.fb.group({
			flightNumber: [sector.flightNumber, Validators.required],
			origin: [sector.origin, Validators.required],
			originName: [sector.originName, Validators.required],
			destination: [sector.destination ? sector.destination : "", Validators.required],
			destinationName: [sector.destinationName ? sector.destinationName : "", Validators.required],
			departureDateTime: [sector.departureDateTime ? sector.departureDateTime : "", Validators.required],
			arrivalDateTime: [sector.arrivalDateTime ? sector.arrivalDateTime : "", Validators.required],
			status: [isFullCancel ? "REQUESTED" : "", Validators.required],
			sectorSelected: [isFullCancel ? isFullCancel : false, Validators.required],
		})
	}
	selectSegment(e, i) {
		// if (e.target.checked && (this.getRequestedSegmentCount() == this.cancelSectorForm.value.segments.length - 1)) {
		// 	if (confirm('Do You Want To Full Cancellation') == false) {
		// 		e.target.checked = false
		// 	}
		// }

		let status = e.target.checked ? 'REQUESTED' : 'TICKETED'
		this.allSegments.controls[i]["controls"].status.setValue(status)
		console.log(this.cancelSectorForm.value)
	}

	getRequestedSegmentCount() {
		let segments = this.cancelSectorForm.value.segments
		let count = 0
		for (let seg of segments) {
			if (seg.status == 'REQUESTED') {
				count += 1
			}
		}
		return count
	}
	get DynamicCancelSectorFormControls() {
		if (this.cancelSectorForm) return <FormArray>this.cancelSectorForm.get('segments');
	}

	async submitCancelSectorRequest() {
		try {
	

			this.apiErrorMessage = ""
			this.apiSuccessMessage = ""
			this.commonService.toggleLoader(true)
			let curr_date_time = await this.commonService.getCurrentDateTime()
			let requestedSegmentCount = await this.getRequestedSegmentCount()
			let isFullCancel = requestedSegmentCount == this.cancelSectorForm.value.segments.length ? true : false
			this.copyOfBooking[0].json_doc['isFullCancel'] = isFullCancel
			this.copyOfBooking[0].json_doc['refundType'] = this.refundType
			this.copyOfBooking[0].json_doc['totalCancelRequestedAmount'] = this.mergedCancelledSegmentFare.total
			this.copyOfBooking[0].json_doc['cancellation_request_date_time'] = curr_date_time
			this.copyOfBooking[0].json_doc['requested_from'] = "IBE"
			this.copyOfBooking[0].json_doc.pnr_status = "REQUESTED";
			try {
				this.commonService.addUserBookingActivity(isFullCancel ? 'cancellation' : 'partial_cancellation',`${isFullCancel ? 'Cancellation' : 'Partial cancellation'} requested`,this.copyOfBooking[0].json_doc.booking_ref,this.copyOfBooking[0].json_doc.gds_pnr)
			} catch (error) {
				
			}
			this.commonService.putBooking(`booking/update`, this.copyOfBooking[0]).subscribe(res => {
				this.closeCancelSectorModalFn()
				this.commonService.toggleLoader(false)
				this.resetData.emit()
				if (JSON.parse(res['_body']).message.toLowerCase() == 'success') {
					this.apiSuccessMessage = 'Requested sectors are cancelled at airline backend, Our backend team will process refund after a quick review shortly!'
					this.openMessageModalFn()
				} else if (JSON.parse(res['_body']).message.toLowerCase() == 'failed') {
					this.apiErrorMessage = 'Airline refused cancellation due to some technical issue, Please contact our backend team to process cancellation offline!'
					this.openMessageModalFn()
				}

			}, err => {
				this.closeCancelSectorModalFn()
				this.commonService.toggleLoader(false)
				console.log(err)
				this.apiErrorMessage = 'Airline refused cancellation due to some technical issue, Please contact our backend team to process cancellation offline!'
				this.openMessageModalFn()
			})

		   
		} catch (error) {
			this.commonService.toggleLoader(false)

		}

	}
	// split traveller
	moveStep1() {
		this.splitTravellersList1 = true;
		this.splitTravellersList2 = false;
		this.splitTravellersList3 = false;
		// this.initialiseSplitTravellerForm()
	}
	moveStep2() {
		this.showTravellerWarningMessage = ""
		this.splitTravellersList1 = false;
		this.splitTravellersList2 = true;
		this.splitTravellersList3 = false;
	}
	moveStep3() {
		this.submitSplitPnrRequest()
		this.splitTravellersList1 = false;
		this.splitTravellersList2 = false;
		this.splitTravellersList3 = true;
	}
	resetModal() {
		this.splitTravellersList1 = true;
		this.splitTravellersList2 = false;
		this.splitTravellersList3 = false;

	}


	// split sector
	moveSectorStep1() {
		this.splitSectorModal1 = true;
		this.splitSectorModal2 = false;
	}
	async moveSectorStep2() {
		let requestedSegmentCount = await this.getRequestedSegmentCount()
		let isFullCancel = requestedSegmentCount == this.cancelSectorForm.value.segments.length ? true : false
		if (this.bookingArr && this.bookingArr[0] && this.bookingArr[0]['json_doc'] && this.bookingArr[0]['json_doc'].segments && this.bookingArr[0]['json_doc'].segments[0]['travellerFares'] && this.validateTravellerFareOfSelectedSegment()) {
			let res = await this.bindCanceledSegmentFare()
			this.getPreviewRefund()
			this.splitSectorModal1 = false;
		} else if (this.bookingArr && this.bookingArr[0] && this.bookingArr[0]['json_doc'] && this.bookingArr[0]['json_doc'] && this.bookingArr[0]['json_doc']['travellers'] && this.bookingArr[0]['json_doc']['travellers'][0]['fare'] && isFullCancel) {
			this.bindAllTravellerFareByType(this.bookingArr[0]['json_doc']['travellers'])
			this.getPreviewRefund()
			this.splitSectorModal1 = false;
		}
		else {
			this.closeCancelSectorModalFn()
			this.cancellationWarningMessage = 'Unable to get refund details from airline, Please contact our backend team to process cancellation offline!'
			this.openModalDialog()
		}

	}

	async getPreviewRefund() {
		try {
			this.apiErrorMessage = ""
			this.apiSuccessMessage = ""
			this.commonService.toggleLoader(true)
			this.previewRefund = {}
			this.copyOfBooking = JSON.parse(JSON.stringify(this.bookingArr))
			this.setLegs(this.copyOfBooking[0].json_doc.travellers, this.copyOfBooking[0].json_doc.segments)
			let reqObj = await this.getSectorCancellationReq()
			let requestedSegmentCount = await this.getRequestedSegmentCount()
			let isFullCancel = requestedSegmentCount == this.cancelSectorForm.value.segments.length ? true : false
			reqObj['json_doc']['isFullCancel'] = isFullCancel
			reqObj['json_doc']['totalCancelRequestedAmount'] = this.mergedCancelledSegmentFare.total
			let refundUrl = this.refundType == 'cancel' ? 'previewRefund' : 'previewVoid'
			this.commonService.postBooking(`booking/${refundUrl}`, reqObj).subscribe(res => {
				
				this.previewRefund = JSON.parse(res['_body'])
				this.splitSectorModal2 = true;
				this.commonService.toggleLoader(false)
				if (JSON.parse(res['_body']).status == 404 || JSON.parse(res['_body']).status.toLowerCase() == 'failed') {
					this.closeCancelSectorModalFn()
					this.apiErrorMessage = 'Unable to get refund details from airline, Please contact our backend team to process cancellation offline!'
					this.openMessageModalFn()
				}
			}, err => {
				this.previewRefund = {}
				this.commonService.toggleLoader(false)
				this.apiErrorMessage = 'Unable to get refund details from airline, Please contact our backend team to process cancellation offline!'
				this.closeCancelSectorModalFn()
				this.openMessageModalFn()
			})
		} catch (error) {
			this.previewRefund = {}
			this.commonService.toggleLoader(false)
		}
	}

	setLegs(travellers, segments) {
		for (let i = 0; i < travellers.length; i++) {
			let segment = []
			const element = travellers[i];
			for (let s = 0; s < segments.length; s++) {
				const elm = segments[s];
				let sectorArr = []
				if (element['status'] == undefined || element['status'] == 'TICKETED') {
					for (let i = 0; i < elm.legs.length; i++) {
						const el = elm.legs[i];
						let obj = {
							origin: el.origin_airport_code,
							destination: el.destination_airport_code,
							sector: el.origin_airport_code + '-' + el.destination_airport_code,
							status: 'TICKETED'
						}
						sectorArr.push(obj);
					}
					if (element['sector'] == undefined) {
						element['sector'] = sectorArr;
					}
					segment.push(sectorArr);
					element['status'] = 'TICKETED';
				}
			}
			element['segment'] = segment
		}
	}
	async getSectorCancellationReq() {
		for (const segment of this.cancelSectorForm.value.segments) {
			let idx = this.cancelSectorForm.value.segments.indexOf(segment)
			if (segment.status == 'REQUESTED') {
				for (const passenger of this.copyOfBooking[0].json_doc.travellers) {
					for (const sector of passenger.segment[idx]) {
						if (sector.status == 'TICKETED') {
							sector.status = 'REQUESTED'
						} else {
							sector.status = 'TICKETED'
						}
					}
				}
			}

		}
		console.log(this.copyOfBooking)
		return this.copyOfBooking[0]

	}

	resetSectorModal() {
		this.splitSectorModal1 = true;
		this.splitSectorModal2 = false;
	}

	openMessageModalFn() {
		this.openMessageModal.nativeElement.click()
	}

	closeMessageModalFn() {
		this.closeMessageModal.nativeElement.click()
	}



	checkIsTravellerValidSelected() {
		let travellers = this.allTravellers.value
		let selectedAdtCount = 0
		let selectedChdCount = 0
		this.showTravellerWarningMessage = ""

		for (let trav of travellers) {
			if (trav.type == 'ADT' && trav.paxSelected) {
				selectedAdtCount += 1
			}

			if (trav.type == 'CHD' && trav.paxSelected) {
				selectedChdCount += 1
			}


		}

		if (selectedAdtCount == this.getTravellerCountByType(travellers).ADT && selectedChdCount != this.getTravellerCountByType(travellers).CHD) {
			this.isTravellerValidSelected = false
		}

		else if (selectedAdtCount == 0 && selectedChdCount > 0) {
			this.isTravellerValidSelected = false
		}
		else if (selectedAdtCount == 0 && selectedChdCount == 0) {
			this.isTravellerValidSelected = false
		}

		else {
			this.isTravellerValidSelected = true
		}



		if (selectedAdtCount == 0 && selectedChdCount == 0) {
			this.showTravellerWarningMessage = ''
		} else if (this.isTravellerValidSelected == false) {
			this.showTravellerWarningMessage = 'Unaccompanied Minor(s) in the booking you need to purchase additional services, Please contact our backoffice to proceed offline cancellation!'

		}

		console.log('isTravellerValidSelected: ', this.isTravellerValidSelected)

	}

	// merging the traveller fare of cancelled segements
	async bindCanceledSegmentFare() {
		let segments = this.cancelSectorForm.value.segments
		let segmentFareSumObjectByTravellerType = {
			'ADT': {
				'base': 0,
				'total': 0,
				'ADMIN_MARKUP': 0,
				'ADMIN_SERVICE_CHARGE': 0,
				'AFFILIATE_MARKUP': 0,
				'COMMISSION': 0,
				'GST': 0,
				'K3': 0,
				'OC': 0,
				'OT': 0,
				'TDS': 0,
				'YQ': 0,
				'YR': 0
			},
			'CHD': {
				'base': 0,
				'total': 0,
				'ADMIN_MARKUP': 0,
				'ADMIN_SERVICE_CHARGE': 0,
				'AFFILIATE_MARKUP': 0,
				'COMMISSION': 0,
				'GST': 0,
				'K3': 0,
				'OC': 0,
				'OT': 0,
				'TDS': 0,
				'YQ': 0,
				'YR': 0
			},
			'INF': {
				'base': 0,
				'total': 0,
				'ADMIN_MARKUP': 0,
				'ADMIN_SERVICE_CHARGE': 0,
				'AFFILIATE_MARKUP': 0,
				'COMMISSION': 0,
				'GST': 0,
				'K3': 0,
				'OC': 0,
				'OT': 0,
				'TDS': 0,
				'YQ': 0,
				'YR': 0
			}
		}
		for (let i = 0; i < segments.length; i++) {
			if (segments[i].status == 'REQUESTED') {

				let segment = this.bookingArr[0].json_doc.segments[i]
				for (let travellerType in segment.travellerFares) {
					travellerType = segment.travellerFares[travellerType].travellerType
					if (travellerType) {

						let totalFareObject = segment.travellerFares[travellerType]

						segmentFareSumObjectByTravellerType[travellerType].base += Number(totalFareObject.base)

						segmentFareSumObjectByTravellerType[travellerType].total += Number(totalFareObject.total)

						segmentFareSumObjectByTravellerType[travellerType].ADMIN_MARKUP += Number(totalFareObject.fareComponents.ADMIN_MARKUP)

						segmentFareSumObjectByTravellerType[travellerType].ADMIN_SERVICE_CHARGE += Number(totalFareObject.fareComponents.ADMIN_SERVICE_CHARGE)

						segmentFareSumObjectByTravellerType[travellerType].AFFILIATE_MARKUP += Number(totalFareObject.fareComponents.AFFILIATE_MARKUP)

						segmentFareSumObjectByTravellerType[travellerType].COMMISSION += Number(totalFareObject.fareComponents.COMMISSION ? totalFareObject.fareComponents.COMMISSION : 0)

						segmentFareSumObjectByTravellerType[travellerType].GST += Number(totalFareObject.fareComponents.GST?totalFareObject.fareComponents.GST:0)

						segmentFareSumObjectByTravellerType[travellerType].K3 += Number(totalFareObject.fareComponents.K3?totalFareObject.fareComponents.K3:0)

						segmentFareSumObjectByTravellerType[travellerType].OC += Number(totalFareObject.fareComponents.OC?totalFareObject.fareComponents.OC:0)

						segmentFareSumObjectByTravellerType[travellerType].OT += Number(totalFareObject.fareComponents.OT?totalFareObject.fareComponents.OT:0)

						segmentFareSumObjectByTravellerType[travellerType].TDS += Number(totalFareObject.fareComponents.TDS?totalFareObject.fareComponents.TDS:0)

						segmentFareSumObjectByTravellerType[travellerType].YQ += Number(totalFareObject.fareComponents.YQ?totalFareObject.fareComponents.YQ:0)

						segmentFareSumObjectByTravellerType[travellerType].YR += Number(totalFareObject.fareComponents.YR ? totalFareObject.fareComponents.YR : 0)
						
					}
				}



			}
		}
		this.mergedCancelledSegmentFare = await this.getFareSumOfAllTravType(segmentFareSumObjectByTravellerType)
		this.mergedCancelledSegmentFare['total'] = this.mergedCancelledSegmentFare['total'] - (this.mergedCancelledSegmentFare['ADMIN_SERVICE_CHARGE']+this.mergedCancelledSegmentFare['GST'])
		let resp = await this.bindTravellersFareOfCancelledSegment(segmentFareSumObjectByTravellerType)
		return null
	}


	// it will return the sum of all traveller fare by their type
	getFareSumOfAllTravType(segmentFareSumObjectByTravellerType) {
		let travellers = this.bookingArr[0]['json_doc']['travellers']
		let adtCount = this.getTravellerCountByType(travellers).ADT
		let childCount = this.getTravellerCountByType(travellers).CHD
		let infanctCount = this.getTravellerCountByType(travellers).INF

		return {

			'base': segmentFareSumObjectByTravellerType['ADT']['base'] * adtCount + segmentFareSumObjectByTravellerType['CHD']['base'] * childCount + segmentFareSumObjectByTravellerType['INF']['base'] * infanctCount,

			'total': segmentFareSumObjectByTravellerType['ADT']['total'] * adtCount + segmentFareSumObjectByTravellerType['CHD']['total'] * childCount + segmentFareSumObjectByTravellerType['INF']['total'] * infanctCount+this.getTotalSsrAmount(this.bookingArr[0]['json_doc']['travellers']),

			'ADMIN_MARKUP': segmentFareSumObjectByTravellerType['ADT']['ADMIN_MARKUP'] * adtCount + segmentFareSumObjectByTravellerType['CHD']['ADMIN_MARKUP'] * childCount + segmentFareSumObjectByTravellerType['INF']['ADMIN_MARKUP'] * infanctCount,

			'ADMIN_SERVICE_CHARGE': segmentFareSumObjectByTravellerType['ADT']['ADMIN_SERVICE_CHARGE'] * adtCount + segmentFareSumObjectByTravellerType['CHD']['ADMIN_SERVICE_CHARGE'] * childCount + segmentFareSumObjectByTravellerType['INF']['ADMIN_SERVICE_CHARGE'] * infanctCount,

			'AFFILIATE_MARKUP': segmentFareSumObjectByTravellerType['ADT']['AFFILIATE_MARKUP'] * adtCount + segmentFareSumObjectByTravellerType['CHD']['AFFILIATE_MARKUP'] * childCount + segmentFareSumObjectByTravellerType['INF']['AFFILIATE_MARKUP'] * infanctCount,

			'COMMISSION': segmentFareSumObjectByTravellerType['ADT']['COMMISSION'] * adtCount + segmentFareSumObjectByTravellerType['CHD']['COMMISSION'] * childCount + segmentFareSumObjectByTravellerType['INF']['COMMISSION'] * infanctCount,

			'GST': segmentFareSumObjectByTravellerType['ADT']['GST'] * adtCount + segmentFareSumObjectByTravellerType['CHD']['GST'] * childCount + segmentFareSumObjectByTravellerType['INF']['GST'] * infanctCount,

			'K3': segmentFareSumObjectByTravellerType['ADT']['K3'] * adtCount + segmentFareSumObjectByTravellerType['CHD']['K3'] * childCount + segmentFareSumObjectByTravellerType['INF']['K3'] * infanctCount,

			'OC': segmentFareSumObjectByTravellerType['ADT']['OC'] * adtCount + segmentFareSumObjectByTravellerType['CHD']['OC'] * childCount + segmentFareSumObjectByTravellerType['INF']['OC'] * infanctCount,

			'OT': segmentFareSumObjectByTravellerType['ADT']['OT'] * adtCount + segmentFareSumObjectByTravellerType['CHD']['OT'] * childCount + segmentFareSumObjectByTravellerType['INF']['OT'] * infanctCount,

			'TDS': segmentFareSumObjectByTravellerType['ADT']['TDS'] * adtCount + segmentFareSumObjectByTravellerType['CHD']['TDS'] * childCount + segmentFareSumObjectByTravellerType['INF']['TDS'] * infanctCount,

			'YQ': segmentFareSumObjectByTravellerType['ADT']['YQ'] * adtCount + segmentFareSumObjectByTravellerType['CHD']['YQ'] * childCount + segmentFareSumObjectByTravellerType['INF']['YQ'] * infanctCount,

			'YR': segmentFareSumObjectByTravellerType['ADT']['YR'] * adtCount + segmentFareSumObjectByTravellerType['CHD']['YR'] * childCount + segmentFareSumObjectByTravellerType['INF']['YR'] * infanctCount
		}
	}


	// bind all traveller fare by their type		
	async bindAllTravellerFareByType(travellers) {

		let travellerFareByType = {
			'ADT': {
				'base': 0,
				'total': 0,
				'ADMIN_MARKUP': 0,
				'ADMIN_SERVICE_CHARGE': 0,
				'AFFILIATE_MARKUP': 0,
				'COMMISSION': 0,
				'GST': 0,
				'K3': 0,
				'OC': 0,
				'OT': 0,
				'TDS': 0,
				'YQ': 0,
				'YR': 0
			},
			'CHD': {
				'base': 0,
				'total': 0,
				'ADMIN_MARKUP': 0,
				'ADMIN_SERVICE_CHARGE': 0,
				'AFFILIATE_MARKUP': 0,
				'COMMISSION': 0,
				'GST': 0,
				'K3': 0,
				'OC': 0,
				'OT': 0,
				'TDS': 0,
				'YQ': 0,
				'YR': 0
			},
			'INF': {
				'base': 0,
				'total': 0,
				'ADMIN_MARKUP': 0,
				'ADMIN_SERVICE_CHARGE': 0,
				'AFFILIATE_MARKUP': 0,
				'COMMISSION': 0,
				'GST': 0,
				'K3': 0,
				'OC': 0,
				'OT': 0,
				'TDS': 0,
				'YQ': 0,
				'YR': 0
			}
		}


		for (let trav of travellers) {

			let travellerType = trav.type
			let travellerFare = trav.fare

			travellerFareByType[travellerType].base = Number(travellerFare.base?travellerFare.base:0)

			travellerFareByType[travellerType].ADMIN_MARKUP = Number(travellerFare.taxes[0]['ADMIN_MARKUP']?travellerFare.taxes[0]['ADMIN_MARKUP']:0)

			travellerFareByType[travellerType].ADMIN_SERVICE_CHARGE = Number(travellerFare.taxes[0]['ADMIN_SERVICE_CHARGE']?travellerFare.taxes[0]['ADMIN_SERVICE_CHARGE']:0)

			travellerFareByType[travellerType].AFFILIATE_MARKUP = Number(travellerFare.taxes[0]['AFFILIATE_MARKUP']?travellerFare.taxes[0]['AFFILIATE_MARKUP']:0)

			travellerFareByType[travellerType].COMMISSION = Number(travellerFare.taxes[0]['COMMISSION']?travellerFare.taxes[0]['COMMISSION']:0)

			travellerFareByType[travellerType].GST = Number(travellerFare.taxes[0]['GST']?travellerFare.taxes[0]['GST']:0)

			travellerFareByType[travellerType].K3 = Number(travellerFare.taxes[0]['K3']? travellerFare.taxes[0]['K3']:0)

			travellerFareByType[travellerType].OC = Number(travellerFare.taxes[0]['OC']?travellerFare.taxes[0]['OC']:0)

			travellerFareByType[travellerType].OT = Number(travellerFare.taxes[0]['OT']?travellerFare.taxes[0]['OT']:0)

			travellerFareByType[travellerType].TDS = Number(travellerFare.taxes[0]['TDS']?travellerFare.taxes[0]['TDS']:0)

			travellerFareByType[travellerType].YQ = Number(travellerFare.taxes[0]['YQ']?travellerFare.taxes[0]['YQ']:0)

			travellerFareByType[travellerType].YR = Number(travellerFare.taxes[0]['YR'] ? travellerFare.taxes[0]['YR'] : 0)

			travellerFareByType[travellerType].total = this.getTotalSum(travellerFareByType[travellerType])

		}
		this.mergedCancelledSegmentFare = await this.getFareSumOfAllTravType(travellerFareByType)
		let resp = await this.bindTravellersFareOfCancelledSegment(travellerFareByType)

	}


	getTotalSum(travellerFare){
		let totalSum = 0
		totalSum+=travellerFare.base
		totalSum+=travellerFare['YQ'] ? travellerFare['YQ'] : 0
		totalSum+=travellerFare['YR'] ? travellerFare['YR'] : 0
		totalSum+=travellerFare['K3'] ? travellerFare['K3'] : 0
		totalSum+=travellerFare['OC'] ? travellerFare['OC'] : 0
		totalSum+=travellerFare['OT'] ? travellerFare['OT'] : 0
		return totalSum
	}



	getTotalSsrAmount(travellers){

		let totalSum = 0
		for(let traveller of travellers){
			totalSum+=this.getSSRPrice(traveller.ssr).total
		}
		return totalSum
	}


	getSSRPrice( ssr) {

		let selectedCancelSegmentHasSet = new Set()
		for(let segment of this.cancelSectorForm.value.segments){
			if(segment.sectorSelected && segment.status=="REQUESTED"){
				let seg = segment.origin+"-"+segment.destination
				selectedCancelSegmentHasSet.add(seg)
			}
		}

		let selectedSectors = new Set()
		for(let segment of  this.bookingArr[0]['json_doc'].segments){
			let seg = segment.legs[0].origin_airport_code+"-"+segment.legs[segment.legs.length-1].destination_airport_code
			if(selectedCancelSegmentHasSet.has(seg)){
				for(let sector of segment.legs){
					selectedSectors.add(sector.origin_airport_code+"-"+sector.destination_airport_code)
				}
			}
		}



		let meal = 0;
		let baggage = 0;
		let additionalBaggage = 0;
		let seat = 0;
		let ancillary = 0;
		if (ssr != null) {
			for (let i = 0; i < ssr.length; i++) {
				const element = ssr[i];
				let sec = element.origin+"-"+element.destination
				if(selectedSectors.has(sec)){
					if (element.meals != null && element.meals.code != null) {
						meal += Number(this.checkifNull(element.meals.code.totalFare));
					}
					if (element.baggages != null && element.baggages.code != null) {
						baggage += Number(this.checkifNull(element.baggages.code.totalFare));
					}
					if (element.additionalBaggages != null && element.additionalBaggages.code != null) {
						additionalBaggage += Number(this.checkifNull(element.additionalBaggages.code.totalFare));
					}
					if (element.seats != null && element.seats.code != null) {
						seat += Number(this.checkifNull(element.seats.code.totalFare));
					}
					if (element.ancillaries != null && element.ancillaries.code != null) {
						for (const item of element.ancillaries.code) {
							ancillary += Number(this.checkifNull(item.totalFare));
						}
					}
				}
				
			}
		}
		return  {
			"total":(Number(meal) + Number(baggage) + Number(additionalBaggage) + Number(seat) + Number(ancillary)),
			"meal":Number(meal),
			"baggage":Number(baggage),
			"additionalBaggage":Number(additionalBaggage),
			"seat":Number(seat),
			"ancillary":Number(ancillary),
		}
	}
	
	checkifNull(value){
		if (value == undefined) {
			return 0
		}
		else{
			return value
		}
	}




	getTravellerCountByType(travellers) {

		let travellerCountByType: any = { 'ADT': 0, 'CHD': 0, 'INF': 0 }

		for (let trav of travellers) {
			if (trav.type == 'ADT') {
				travellerCountByType.ADT += 1
			}
			if (trav.type == 'CHD') {
				travellerCountByType.CHD += 1
			}

			if (trav.type == 'INF') {
				travellerCountByType.INF += 1
			}
		}

		return travellerCountByType

	}
	//Travellers fare of cancelled segement
	bindTravellersFareOfCancelledSegment(segmentFareSumObjectByTravellerType) {
		let travellers = this.bookingArr[0]['json_doc']['travellers']
		let travellerFare = []
		for (let trav of travellers) {

			let traveller = {}
			let travellerType = trav.type
			// let count = this.getTravellerCountByType(travellers)[travellerType]
			traveller['base'] = segmentFareSumObjectByTravellerType[travellerType]['base']
			traveller['total'] = (segmentFareSumObjectByTravellerType[travellerType]['base']+segmentFareSumObjectByTravellerType[travellerType]['YQ']+segmentFareSumObjectByTravellerType[travellerType]['K3']+segmentFareSumObjectByTravellerType[travellerType]['OT']+segmentFareSumObjectByTravellerType[travellerType]['OC']+segmentFareSumObjectByTravellerType[travellerType]['YR']+this.getSSRPrice(trav.ssr).total)
			traveller['YQ'] = segmentFareSumObjectByTravellerType[travellerType]['YQ']
			traveller['K3'] = segmentFareSumObjectByTravellerType[travellerType]['K3']
			traveller['OT'] = segmentFareSumObjectByTravellerType[travellerType]['OT']
			traveller['OC'] = segmentFareSumObjectByTravellerType[travellerType]['OC']
			traveller['YR'] = segmentFareSumObjectByTravellerType[travellerType]['YR']
			traveller["ssr"] = this.getSSRPrice(trav.ssr)
			travellerFare.push(traveller)
		}

		this.travellerFareOfCancelledSeg = travellerFare
		return null
	}

	validateTravellerFareOfSelectedSegment() {
		let segments = this.cancelSectorForm.value.segments
		for (let i = 0; i < segments.length; i++) {
			if (segments[i].status == 'REQUESTED') {
				let segment = this.bookingArr[0].json_doc.segments[i]
				if (!segment.travellerFares) {
					return false
				}
			}
		}
		return true
	}

	confirmSectorCancellation(e) {
		if (e.target.checked) {
			this.sectorCancellationConfirmation = true;
			this.isConfirmationChecked = true;
		} else {
			this.sectorCancellationConfirmation = false
			this.isConfirmationChecked = false;
		}
	}

	toggleErrorMessage(){
		this.showErrorMessage = !this.showErrorMessage
	}

	openDetail(reference_no) {
		let url = `${environment.baseUrl}/#/booking-summary-detail;searchId=${reference_no}`
		window.open(url)
	}
	getSectorStatus(i) {
		let status = 'CONFIRMED'
		if (this.bookingArr[0].json_doc.pnr_status == 'REQUESTED') {
			let firstTraveler = this.bookingArr[0].json_doc.travellers[0]
			if (firstTraveler && firstTraveler.segment && firstTraveler.segment[i] && firstTraveler.segment[i][0]) {
				if (firstTraveler.segment[i][0].status != 'TICKETED') {
					status = firstTraveler.segment[i][0].status
				}
			}
		}
		if (status == 'REQUESTED' && this.bookingArr[0].json_doc['cancellation_response'] && this.bookingArr[0].json_doc['cancellation_response'].status == "CANCELLED") {
			status = this.bookingArr[0].json_doc['cancellation_response'].status
		}
		if(this.bookingArr[0].json_doc.pnr_status == 'REFUNDED'){
			status = 'REFUNDED'
		}

		if(this.bookingArr[0].json_doc.segments[i].hasOwnProperty("isTravelled") && this.bookingArr[0].json_doc.segments[i].isTravelled){
			status = "FLOWN"
		}
		return status
	}
	getSplittravellerInfo(traveler) {
		if (traveler.type == 'INF' && traveler.associateParent) {
			let parent = this.allTravellers.value[traveler.associateParent]
			let parentName = parent.title + " " + parent.firstName
			if (parent.lastName) {
				parentName = parentName + " " + parent.lastName
			}
			// console.log(traveler)
			return `To Split this infant, Please select it's parent ${parentName}`
		}
		return ""

	}
	
	toggleAddonSideBar(){
		console.log("clicked on addon")
		this.toggleAddon = !this.toggleAddon
		// this.commonService.toggleAddonSideBar(this.toggleAddon)
		this.ngRedux.dispatch(ToggleAddonSummary(this.toggleAddon));
	}
	
	initializeSelectedSsr(requestPayload) {
		let payLoad = {
			travellers : requestPayload.travellers,
			segments : requestPayload.segments
		}
		this.ngRedux.dispatch(InitializeSelectedSSR(payLoad));
	}
	selectAddonTab(tabName){
		this.activeAddonTab = tabName;
	}

	isString(data){
		return typeof data == 'string'
	}


	viewFareRules() {
		this.showLegacyFareRule = false
		this.parentIndex = 0
	    let bookingId = this.bookingArr[0].json_doc.booking_ref
		this.commonService.toggleLoader(true)
		this.commonService.getBooking(`booking/fare-rule?bookingId=${bookingId}`).subscribe(res => {
			let resBody = res['_body']
			resBody = JSON.parse(resBody)
			this.fareRules = resBody.data && resBody.data['fare_rules']
			this.openFareRuleModal.nativeElement.click()
			this.commonService.toggleLoader(false)
		}, err => {
			this.openFareRuleModal.nativeElement.click()
			this.commonService.toggleLoader(false)
		})
	}
	isArray(arr){
		// console.log(arr)
		// return true
		return arr && Array.isArray(arr)
	}
	start_and_end(data) {
		let str =''
		if(typeof data == 'string'){
			str = data
		}else{
			str = `${data.code ? data.code : ''} ${data.name ? data.name : ''}`
		}
		
		if (str.length > 25) {
		  return str.substr(0, 15) + '...' + str.substr(str.length-5, str.length);
		}
		return str;
	}
	decideToShowToggle(i){
		return true
	}
	getHeight(i){
		if(this.showExpandedSSRDetail[i]){
			return 
		}
	}


	isBeforeThanCurrentDate(date) {
        let currentDate = moment().format("YYYY-MM-DD HH:mm:ss")
        let requestedDate = moment(date).format("YYYY-MM-DD HH:mm:ss")
		
        if(requestedDate < currentDate) {
            return true
        } else {
            return false
        }
    }

	copyMessage(val,copyFor) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		this.copyFor = copyFor
		this.showSnackBar()
	}
	showSnackBar() {
		var x = document.getElementById("snackbar");
		x.className = "show";
		setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
	}
}
