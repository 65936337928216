<div class="summary bg-white card" style="position: sticky;top: 50px;">
    <div class="dark-green-bg p-2 text-center text-white text-bold " style="font-size: 20px;">Booking Summary</div>
    <div class="summary-inner">
        <div class="detail-toggle mt-3 mb-3">
            <a (click)="toggleFareDetails()">
                <i class="fa fa-plus-square" *ngIf="!toggleFare" aria-hidden="true"></i>&nbsp;<span *ngIf="!toggleFare" class="detail-toggle-text">Show
					Full Fare Details</span>
                <i class="fa fa-minus-square" *ngIf="toggleFare" aria-hidden="true"></i>&nbsp;<span *ngIf="toggleFare" class="detail-toggle-text">Hide
					Full Fare Details</span>
            </a>
        </div>
        <div class="flight-summary" *ngIf="bookingArr.length > 0">
           <!-- <div class="card  mb-2">
            <p class="card-header text-bold m-0 text-left text-pinkish-red">Flight Details</p>
            <div class="card-body p-2 pl-3 pr-3">
                <ng-container *ngFor="let booking of bookingArr">
                    <ng-container *ngFor="let segment of booking['json_doc']['segments']">
                <p class="text-bold mb-2 mt-0 f-head-2 d-flex align-items-center justify-content-between">
                    <span>Fare Type</span> 
                    <span>-</span> 
                    <ng-container *ngIf="segment?.supplierInfo?.priceType; else ">
                        <span>{{segment?.supplierInfo?.priceType}}</span>
                    </ng-container>
                    <ng-template #fareType>
                        <span>{{bookingArr && bookingArr[0].json_doc.displayPriceType?bookingArr[0].json_doc.displayPriceType:bookingArr[0]?.json_doc.fare_type}}</span>
                    </ng-template>
                </p>
    
                        <ng-container *ngFor="let leg of segment['legs']">
                            <div class="f-head-2 col-12 p-0">
                                <span class="col-left">{{leg?.origin_timestamp | dateFormat }}</span>
                                <span class="col-center">{{leg?.marketing_airline_code}}-{{leg?.flight_number}}</span>
                                <span class="col-right">{{leg?.cabin_class}} Class</span>
                                <div class="clearfix"></div>
                            </div>
                            <div class="f-info mb-3">
                                <span class="col-left">Departure</span>
                                <span class="col-center">{{leg?.origin_airport_code}}</span>
                                <span class="col-right">{{leg?.origin_timestamp | timeFormat}} hrs</span>
    
                                <span class="col-left">Arrival</span>
                                <span class="col-center">{{leg?.destination_airport_code}}</span>
                                <span class="col-right">{{leg?.destination_timestamp | timeFormat}} hrs</span>
    
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
           </div> -->
            <div class="fare card  mb-2" *ngFor="let fare of travellerFareArray">
                <p class="card-header text-bold m-0 text-left text-pinkish-red">Fare / Pax Type</p>
               <div class="card-body p-2 pl-3 pr-3">
                <div style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                    <span class="float-left" *ngIf="fare.type == 'ADT'">Adult</span>
                    <span class="float-left" *ngIf="fare.type == 'CHD'">Child</span>
                    <span class="float-left" *ngIf="fare.type == 'INF'">Infant</span>
                    <span class="float-right">{{fare.base | currency:"INR":"symbol-narrow"}}</span>
                    <div class="clearfix"></div>
                </div>
                <div style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                    <span class="float-left">Tax</span>
                    <span class="float-right">{{fare.tax | currency:"INR":"symbol-narrow"}}</span>
                    <div class="clearfix"></div>
                </div>
                <div style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                    <span class="float-left">K3</span>
                    <span class="float-right">{{fare.K3 | currency:"INR":"symbol-narrow"}}</span>
                    <div class="clearfix"></div>
                </div>
                <div style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                    <span class="float-left">T. Fee and S. Charges</span>
                    <span class="float-right">
                        {{fare.otherTax | currency:"INR":"symbol-narrow"}}</span>
                    <div class="clearfix"></div>
                </div>
               </div>
                <div class="t-fare dark-green-bg text-white text-bold pt-2 pb-2" style="border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
                    <span class="float-left pl-3">TOTAL</span>
                    <span class="float-right pr-3">{{fare.total | currency:"INR":"symbol-narrow"}}</span>
                    <div class="clearfix"></div>
                </div>
            </div>

            <div class="total-fare card  mb-2">
                <p class="card-header text-bold m-0 text-left text-pinkish-red">Total Fare</p>
                <div class="card-body p-2 pl-3 pr-3">
                <ng-container *ngFor="let fare of travellerFareArray">
                    <div  style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                        <span class="float-left" *ngIf="fare.type == 'ADT'"><span>Adult</span> x <span>{{fare.count}}</span></span>
                        <span class="float-left" *ngIf="fare.type == 'CHD'"><span>Child</span> x <span>{{fare.count}}</span></span>
                        <span class="float-left" *ngIf="fare.type == 'INF'"><span>Infant</span> x <span>{{fare.count}}</span></span>
                        <span class="float-right">{{(fare.count*(fare.total)-(fare.count-1)*(fare.ADMIN_SERVICE_CHARGE)) | currency:"INR":"symbol-narrow"}}</span>

                        <div class="clearfix"></div>
                    </div>
                </ng-container>

                <div style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                    <span class="float-left">Meal Charges</span>
                    <span class="float-right">{{mealCharges ? mealCharges : 0 | currency:"INR":"symbol-narrow"}}</span>
                    <div class="clearfix"></div>
                </div>
                <div style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                    <span class="float-left">Baggage Charges</span>
                    <span class="float-right">{{baggageCharges ? baggageCharges : 0 | currency:"INR":"symbol-narrow"}}</span>
                    <div class="clearfix"></div>
                </div>
                <div style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                    <span class="float-left">Addnl. Baggage  Charges</span>
                    <span class="float-right">{{additionalBaggageCharges ? additionalBaggageCharges : 0 | currency:"INR":"symbol-narrow"}}</span>
                    <div class="clearfix"></div>
                </div>
                <div style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                    <span class="float-left">Seat Charges</span>
                    <span class="float-right">{{seatCharges ? seatCharges : 0 | currency:"INR":"symbol-narrow"}}</span>
                    <div class="clearfix"></div>
                </div>
                <div style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                    <span class="float-left">Ancillaries Charges</span>
                    <span class="float-right">{{ancillaryCharges ? ancillaryCharges : 0 | currency:"INR":"symbol-narrow"}}</span>
                    <div class="clearfix"></div>
                </div>
                <div style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                   
                    <span class="float-left">Insurance Premium</span>
                    <span class="float-right">{{total_insured_person_amount_sum| currency:"INR":"symbol-narrow"}}</span>
                    <div class="clearfix"></div>
                    <!-- <span class="float-right">{{seatCharges ? seatCharges : 0 | currency:"INR":"symbol-narrow"}}</span> -->
                </div>
                <div style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                    <span class="float-left">Total GST</span>
                    <span class="float-right">&#8377; 0.00</span>
                    <div class="clearfix"></div>
                </div>
                <!-- <div *ngIf="resIssuanceCharges > 0">
                    <span class="float-left">Re-Issuance Charges (+)</span>
                    <span class="float-right">{{resIssuanceCharges | currency:"INR":"symbol-narrow"}}</span>
                    <div class="clearfix"></div>
                </div> -->


               
            </div>
            <div class="  dark-green-bg text-white text-bold pt-2 pb-2"  style="border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
                <span class="float-left pl-3">Total Pub. Fare</span>
                <span class="float-right pr-3">{{(totalPublishedFare+resIssuanceCharges) | number : '1.1-2'}}</span>
                <div class="clearfix"></div>
            </div>
            </div>

            <div *ngIf="toggleFare" class="full-fare-details">
                <!-- <span class="sum-head">Fare / Pax Type</span>

				<div class="pax-type">
					<span class="col-left">Type</span>
					<span class="col-center">Published</span>
					<span class="col-right">Offered</span>
					<div class="clearfix"></div>
				</div>

				<div>
					<span class="col-left">Adult:</span>
					<span class="col-center">&#8377; 13398.00</span>
					<span class="col-right">&#8377; 19,318.83</span>
					<div class="clearfix"></div>
				</div>

				<div>
					<span class="col-left">OT Tax:</span>
					<span class="col-center">&#8377; 1398.00</span>
					<span class="col-right">&#8377; 9,318.83</span>
					<div class="clearfix"></div>
				</div>

				<div>
					<span class="col-left">T.Fee and S.Charges</span>
					<span class="col-center">&#8377; 0.00</span>
					<span class="col-right">&#8377; 0.00</span>
					<div class="clearfix"></div>
				</div>

				<div class="full-total-fare">
					<span class="col-left">Total</span>
					<span class="col-center">&#8377; 13398.00</span>
					<span class="col-right">&#8377; 19,318.83</span>
					<div class="clearfix"></div>
				</div>

				<div class="mt-3 mb-3">
					<span class="float-left">Adults x 2</span>
					<span class="float-right">&#8377; 63,563.98</span>
					<div class="clearfix"></div>
				</div> -->

                <div class="total-fare-summary" style="background-color: #f2edff!important;margin-bottom: 0!important;
                border-bottom-left-radius: 0!important;
                border-bottom-right-radius: 0!important;">

                    <div  style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                        <span class="float-left">Total Pub. Fare</span>
                        <span class="float-right">{{(totalPublishedFare-totalServiceCharges-totalGstOnServiceCharges) | currency:"INR":"symbol-narrow"}}</span>
                        <div class="clearfix"></div>
                    </div>

                    <div style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                        <span class="float-left">Affiliate Markup (-)</span>
                        <span class="float-right">{{totalAffiliateMarkup | currency:"INR":"symbol-narrow"}}</span>
                        <div class="clearfix"></div>
                    </div>

                    <div style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                        <span class="float-left">Additional Markup (-)</span>
                        <span class="float-right">{{totalAffilateAdonMarkup | currency:"INR":"symbol-narrow"}}</span>
                        <div class="clearfix"></div>
                    </div>

                    <div style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                        <span class="float-left">Service Charges (+)</span>
                        <span class="float-right">{{totalServiceCharges | currency:"INR":"symbol-narrow"}}</span>
                        <div class="clearfix"></div>
                    </div>

                    <div  style="border-bottom: 1px dashed #ccc;padding: 3px 0;" *ngIf="resIssuanceCharges > 0">
                        <span class="float-left">Re-Issuance Charges (+)</span>
                        <span class="float-right">{{resIssuanceCharges | currency:"INR":"symbol-narrow"}}</span>
                        <div class="clearfix"></div>
                    </div>

                    <!-- <div *ngIf="resIssuanceFareDiffernceCharges>0">
                        <span class="float-left">Fare Diff (included)</span>
                        <span class="float-right">{{resIssuanceFareDiffernceCharges | currency:"INR":"symbol-narrow"}}</span>
                        <div class="clearfix"></div>
                    </div> -->

                    <div  style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                        <span class="float-left">Total GST (+)</span>
                        <span class="float-right">{{totalGstOnServiceCharges | currency:"INR":"symbol-narrow"}}</span>
                        <div class="clearfix"></div>
                    </div>

                    <div  style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                        <span class="float-left">Commission Earned (-)</span>
                        <span class="float-right">{{totalCommission | currency:"INR":"symbol-narrow"}}</span>
                        <div class="clearfix"></div>
                    </div>

                    <div  style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                        <span class="float-left">TDS (+)</span>
                        <span class="float-right">{{totalTds | currency:"INR":"symbol-narrow"}}</span>
                        <div class="clearfix"></div>
                    </div>

                    <div  style="border-bottom: 1px dashed #ccc;padding: 3px 0;">
                        <span class="float-left">Round off</span>
                        <span class="float-right">{{totalNetPayableRoundOff | currency:"INR":"symbol-narrow"}}</span>
                        <div class="clearfix"></div>
                    </div>

                </div>
                <div class="dark-green-bg text-white text-bold pt-2 pb-2 mb-2"  style="border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
                    <span class="float-left pl-3">Total Payable:</span>
                    <span class="float-right pr-3">&#8377;{{(totalNetPayable + resIssuanceCharges) | number : '1.0-0'}}</span>
                    <div class="clearfix"></div>
                </div>

                <ng-container *ngIf="bookingArr[0] && bookingArr[0].json_doc 
                && (bookingArr[0].json_doc.pnr_status == 'TICKETED' || bookingArr[0].json_doc.pnr_status == 'REQUESTED')
                && bookingArr[0].json_doc.isReissuance && bookingArr[0].json_doc.isReissuance">
                    <div class="total-fare-summary" style="background-color: #f2edff!important;margin-bottom: 0!important;
                    border-bottom-left-radius: 0!important;
                    border-bottom-right-radius: 0!important;">
                    <div>
                        <span class="float-left">Refund by CRN No. <strong class="cursor-pointer" (click)="openCreditNote(bookingArr[0].json_doc.reissuance_credit_note_code)">{{bookingArr[0].json_doc.reissuance_credit_note_code}}</strong> (-)</span>
                        <span class="float-right">{{bookingArr[0].json_doc.reissuance_credit_note_amount | currency:"INR":"symbol-narrow"}}</span>
                        <div class="clearfix"></div>
                    </div>

                </div>
                <div class="total-payable mt-2">
                    <span class="float-left">Net  Payable:</span>
                    <span class="float-right">&#8377;{{(totalNetPayable + resIssuanceCharges)-(bookingArr[0].json_doc.reissuance_credit_note_amount) | number : '1.0-0'}}</span>
                    <div class="clearfix"></div>
                </div>
                </ng-container>
                <!-- <div class="dark-green-bg text-white text-bold pt-2 pb-2 mb-2"  style="border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;background-color: #0000ff;">
                    <span class="float-left pl-3">Total Commission Earned:</span>
                    <span class="float-right pr-3">{{totalCommission | currency:"INR":"symbol-narrow"}}</span>
                    <div class="clearfix"></div>
                </div> -->
            </div>

            <ng-container *ngIf="bookingArr[0]['json_doc'].pnr_status == 'REFUNDED'">
                <div class="card p-2" style="background-color: #f2edff!important;margin-bottom: 0!important;
                border-bottom-left-radius: 0!important;
                border-bottom-right-radius: 0!important;">
                    <div>
                        <span class="float-left">Airline cancellation charge (-)</span>
                        <span class="float-right">{{totalAirlineCancellationCharges | currency:"INR":"symbol-narrow"}}</span>
                        <div class="clearfix"></div>
                    </div>
                    <div>
                        <span class="float-left">My charge (-)</span>
                        <span class="float-right">{{totalMyCancellationCharges | currency:"INR":"symbol-narrow"}}</span>
                        <div class="clearfix"></div>
                    </div>

                    <div>
                        <span class="float-left">Total GST (-)</span>
                        <span class="float-right">{{totalGstOncancellationCharges | currency:"INR":"symbol-narrow"}}</span>
                        <div class="clearfix"></div>
                    </div>

                    <div>
                        <span class="float-left">Commission Earned (-)</span>
                        <span class="float-right">{{totalRefundedCommission | currency:"INR":"symbol-narrow"}}</span>
                        <div class="clearfix"></div>
                    </div>

                    <div>
                        <span class="float-left">TDS (+)</span>
                        <span class="float-right">{{totalRefundedTds | currency:"INR":"symbol-narrow"}}</span>
                        <div class="clearfix"></div>
                    </div>

                    <!-- <div>
                        <span class="float-left">Travelled Fare (-)</span>
                        <span class="float-right">{{totalTravelledFare | currency:"INR":"symbol-narrow"}}</span>
                        <div class="clearfix"></div>
                    </div> -->
                </div>

                <div class=" text-white text-bold pt-2 pb-2"  style="border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;background-color: #fa1e74;position: sticky; bottom: 0;    margin-left: -7px; margin-right: -7px">
                    <span class="float-left pl-3" style="font-size: 18px; font-weight: 600;">Refunded Amt.:</span>
                    <span class="float-right pr-3" style="font-size: 18px; font-weight: 600;">₹{{totalRefundAmount | number : '1.0-0'}}</span>
                    <div class="clearfix"></div>
                </div>
            </ng-container>

        </div>

    </div>
</div>