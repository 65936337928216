<section class="content paymentpage">
    <div class="container mat-white-input-tab">

        <!-- <div class=" card">
            <div id="paymenttab">
                <mat-tab-group mat-align-tabs="left" (selectedTabChange)="getPaymentHistory($event)">
                    <mat-tab label="Payment">
                        <div class="alert alert-success msgSuccess mt-3" *ngIf="successMsg">
                            <strong>Success!</strong> {{successMessage}}
                        </div>
                        <div class="alert alert-warning msgSuccess mt-3" *ngIf="isWarning">
                            <strong>Warning!</strong> {{warningMessage}}
                        </div>
                        <div class="alert alert-danger msgError mt-3" *ngIf="errorMsg && isResponse">
                            <a href="#" class="alert-link"> Uh-oh! Your payment is failed due to {{paymentStatusResponse?.status_message}}</a>.
                            <button style="cursor: pointer;" *ngIf="!viewDetail && isResponse" (click)="showDetail()" class="btn-info">View Details</button>
                            <button style="cursor: pointer;" *ngIf="viewDetail && isResponse" (click)="showDetail()" class="btn-info">Hide Details</button>
                        </div>
                        <div class="alert alert-danger msgError mt-3" *ngIf="errorMsg && !isResponse">
                            <a href="#" class="alert-link"> Uh-oh! Your payment is failed due to wrong record </a>
                        </div>

                        <div *ngIf="viewDetail && isResponse" class="extra-details">
                            <span class="detailed-info">Order ID : <span class="detail-data">{{paymentStatusResponse?.order_id}}</span></span>
                            <span class="detailed-info">Transaction Reference Number: <span class="detail-data">{{paymentStatusResponse?.tracking_id}}</span></span>
                            <span class="detailed-info">Bank Reference Number: <span class="detail-data">{{paymentStatusResponse?.bank_ref_no}}</span></span>
                            <span class="detailed-info">Payment Status :<span class="detail-data">{{paymentStatusResponse?.order_status}}</span></span>
                            <span class="detailed-info">Amount :<span class="detail-data">{{paymentStatusResponse?.amount}}</span></span>
                            <span class="detailed-info">Response Code :<span class="detail-data">{{paymentStatusResponse?.response_code}}</span></span>
                            <span class="detailed-info">Payment Mode :<span class="detail-data">{{paymentStatusResponse?.payment_mode}}</span></span>
                        </div>


                        <div class="deposit container">
                            <form [formGroup]="paymentForm" id="offline" class="mat-white-input-form offpayment">
                                <div class="details">
                                    <div class="row col-12">
                                        <div class="w-100">
                                            <div class="form-group col-4 float-left">
                                                <label for="agencycode">Agency Name</label>
                                                <input autocomplete="off" class="form-control m-input" formControlName="txtAgencyName" type="text" id="txtAffliateName" placeholder="Enter Affiliate Name" id="example-text-input" disabled>
                                            </div>

                                            <div class="form-group col-4 float-left">
                                                <label for="agencycode">Agency Code</label>
                                                <input type="text" class="form-control agency-field" id="agencycode" formControlName="txtAgencyCode" placeholder="Agency Code" disabled>
                                            </div>

                                            <div class="form-group col-4 float-left">
                                                <label for="deposittype">Type of Deposit</label>
                                                <select class="form-control" id="deposittype" formControlName="selDepositType" (change)="changeDeposit($event.target.value)">
                                                    <option value="cash">Cash</option>
                                                    <option value="cheque">Cheque</option>
                                                    <option value="credit-card">Online payment</option>
                                                </select>
                                                <div class="error" *ngIf="(!selDepositType.valid && selDepositType.touched) || (!selDepositType.valid && hasError)">Please select deposit type</div>
                                            </div>

                                            <div class="form-group col-4 float-left">
                                                <div class="date date-picker">
                                                    <label for="selectdate">Date</label>
                                                    <input bsDatepicker autocomplete="off" #fDate formControlName="txtDepositDate" #dpYMD="bsDatepicker" [bsConfig]="bsConfig" type="text" class="custom-input form-control m-input" placeholder="Select Date" />
                                                    
                                                </div>
                                                <div class="error" *ngIf="(!txtDepositDate.valid && txtDepositDate.touched) || (!txtDepositDate.valid && hasError)">Please select deposit date</div>
                                            </div>

                                            <div class="form-group col-4 float-left">
                                                <label for="depositamount">Deposit Amount ( {{!creditBal ? 0 : creditBal}} )</label>
                                                <input type="number" class="form-control" (blur)="getCharge(txtDepositAmount.value)" formControlName="txtDepositAmount" id="depositamount" placeholder="Enter amount (in INR)">
                                                <div class="error" *ngIf="(!txtDepositAmount.valid && txtDepositAmount.touched)  || (!txtDepositAmount.valid && hasError)">Enter Amount</div>
                                                <div class="error" *ngIf="txtDepositAmount.value > 195000 && selDepositType.value == 'cash'">Amount must be less than 1,95,000 in case of <strong>Cash deposit</strong></div>
                                            </div>

                                            <div class="form-group col-4 float-left" *ngIf="!IsDebit">
                                                <label for="beneficiarybank">Beneficiary Bank</label>
                                                <select class="form-control" formControlName="selBeneficiaryBank" id="beneficiarybank">
                                                    <ng-container *ngFor="let list of generalLedger">
                                                        <option value="{{list.id}}">{{list.txtLedgerName}}</option>
                                                    </ng-container>
                                                </select>
                                                <div class="error" *ngIf="(!selBeneficiaryBank.valid && selBeneficiaryBank.touched) || (!selBeneficiaryBank.valid && hasError)">Please select beneficiary account</div>
                                            </div>

                                            <div class="form-group col-4 float-left" *ngIf="IsCheque">
                                                <label for="chequeddno">Cheque / DD No.</label>
                                                <input type="number" class="form-control" id="chequeddno" formControlName="txtChequeNo" placeholder="Cheque / DD No.">
                                                <div class="error" *ngIf="(!txtChequeNo.valid && txtChequeNo.touched) || (!txtChequeNo.valid && hasError)">Please select account type</div>
                                            </div>

                                            <div class="form-group col-4 float-left" *ngIf="IsCheque">
                                                <div class="date date-picker">
                                                    <label for="chequeddissue">Cheque / DD Issue Date</label>
                                                    <input bsDatepicker autocomplete="off" #fDate formControlName="txtChequeDate" #dpYMD="bsDatepicker" [bsConfig]="bsConfig" type="text" class="custom-input form-control m-input" placeholder="Select Date" />
                                                    <div class="error" *ngIf="(!txtChequeDate.valid && txtChequeDate.touched) || (!txtChequeDate.valid && hasError)">Please select account type</div>
                                                </div>
                                            </div>

                                            <div class="form-group col-4 float-left" *ngIf="IsCheque">
                                                <label for="depbankname">Depositor's Bank Name</label>
                                                <input type="text" class="form-control" formControlName="txtDepositorBankName" id="depbankname" placeholder="Depositor's Bank Name">
                                                <div class="error" *ngIf="(!txtDepositorBankName.valid && txtDepositorBankName.touched) || (!txtDepositorBankName.valid && hasError)">Please select account type</div>
                                            </div>

                                            <div class="form-group col-4 float-left" *ngIf="IsDebit">
                                                <label for="depbankname">Amount to be charged</label>
                                                <input type="text" class="form-control" formControlName="txtAmountCharged" placeholder="Amount to be charged" readonly>
                                            </div>

                                            <div class="clearfix"></div>

                                            <div class="row justify-content-center mb-4 payment-mode-container" *ngIf="IsDebit">
                                                <span class="section-head">Select payment mode</span>
                                                <div class="account-type-select">


                                                    <div class="custom-control custom-radio pmt-mode cc-card"  (click)="setPaymentMethod('credit-card')">
                                                        <input formControlName="cardType" value="OPTCRDC" name="cardType" type="radio" id="credit-card" class="custom-control-input" checked>
                                                        <label class="custom-control-label" for="credit-card"><span class="mode-type mr-3">Credit Card</span><img src="../../../assets/images/payment-options.png" alt=""></label>
                                                    </div>
                                                    <div class="custom-control custom-radio pmt-mode cc-card" (click)="setPaymentMethod('debit-card')">
                                                        <input formControlName="cardType" value="OPTDBCRD" name="cardType" type="radio"  id="debit" class="custom-control-input" checked>
                                                        <label class="custom-control-label" for="debit"><span class="mode-type mr-3">Debit Card</span><img src="../../../assets/images/payment-options.png" alt=""></label>
                                                    </div>
                                                    <div class="custom-control custom-radio pmt-mode cc-card" (click)="setPaymentMethod('netbanking')">
                                                        <input formControlName="cardType" value="OPTNBK" name="cardType" type="radio" id="netbnking" class="custom-control-input" checked>
                                                        <label class="custom-control-label" for="netbnking"><span class="mode-type mr-3">Net Banking</span></label>
                                                    </div>
                                                   
                                                </div>

                                                
                                            </div>

                                            <div class="row mt-3">
                                                <div class="col-12">
                                                    <label class="sr-only" for="remarks"></label>
                                                    <div class="input-group mb-2">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text hilight">Remarks</div>
                                                        </div>
                                                        <input type="text" class="form-control remarks-field" maxlength="35" formControlName="txtRemarks" id="remarks" placeholder="Enter remarks here">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field w100 text-center mt-3 mb-3">
                                                <button type="submit" class="btn btn-lg btn-success" *ngIf="!IsDebit" (click)="submitPaymentReq(paymentForm.value)">SAVE</button>
                                                <button type="submit" class="btn btn-green" (click)="submitPaymentReq(paymentForm.value)" *ngIf="IsDebit">PROCEED TO PAY</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </mat-tab>

                    <mat-tab label="Payment History" >
                       <div class="custom-table mt-3">
                        <table id="history" class="table table-hover ">
                            <thead >
                                <tr>
                                    <th class="beneficiary text-left">Beneficiary Bank</th>
                                    <th class="depositer">Depositor's Bank</th>
                                    <th class="type">Type</th>
                                    <th class="reference">Reference No</th>
                                    <th class="amount">Amount</th>
                                    <th class="date">Date</th>
                                    <th class="status">Status</th>
                                    <th class="remarks">Remarks</th>
                                </tr>
                            </thead>
                            <tbody class="data custom-table-body">
                                <tr *ngFor="let payment of paymentDataArr">
                                    <td class="beneficiary text-left">{{payment.name}}</td>
                                    <td class="depositer" *ngIf="payment.depositor_bank_name != ''">
                                        {{payment.depositor_bank_name}}</td>
                                    <td class="depositer" *ngIf="payment.depositor_bank_name == '' || payment.depositor_bank_name == null">
                                        N/A</td>
                                    <td class="type text-capitalize">{{payment.type}}</td>
                                    <td class="reference">
                                        <span *ngIf="payment.type != 'cheque'">N/A</span>
                                        <span *ngIf="payment.type == 'cheque'">{{payment.cheque_number}}</span>
                                    </td>
                                    <td class="amount">{{payment.amount}}</td>
                                    <td class="date">
                                        <div *ngIf="payment.deposited_at != null">
                                            {{payment.deposited_at | date: 'dd/MM/yyyy'}}</div>
                                    </td>
                                    <td class="status text-capitalize">{{payment.status}}</td>
                                    <td class="remarks">{{payment.remarks}}</td>
                                </tr>
                            </tbody>
                        </table>
                       </div>
                    </mat-tab>
                    <mat-tab label="Bank Detail">			

                        <span class="detail-heading">BANK DETAILS OF AADESH TRAVELS PVT. LTD.</span>
                        <div class="custom-table">
                        <table class="table  bank-detail-table mt-3">
                            <tr>
                              <th>NAME OF BANK</th>
                              <th>ICICI BANK</th>
                              <th>FOR CASH DEPOSIT.</th>
                              <th>HDFC BANK</th>
                              <th>Bank of Baroda</th>
                            </tr>
                            <tr>
                              <td>NAME OF ACCOUNT</td>
                              <td>AADESH TRAVELS PVT.LTD.</td>
                              <td>AADESH TRAVELS PVT.LTD.</td>
                              <td>AADESH TRAVELS PVT.LTD.</td>
                              <td>AADESH TRAVELS PVT.LTD.</td>
                            </tr>
                            <tr>
                              <td>ACCOUNT NO.</td>
                              <td>084805500444</td>
                              <td>084805500077</td>
                              <td>03788640000023</td>
                              <td>07930200000556</td>
                            </tr>
                            <tr>
                              <td>IFSC CODE</td>
                              <td>ICIC0000848</td>
                              <td>ICIC0000848</td>
                              <td>HDFC0009303</td>
                              <td>BARB0MANRAJ</td>
                            </tr>
                            <tr>
                              <td>MICR CODE</td>
                              <td>360229004</td>
                              <td>360229004</td>
                              <td>360240004</td>
                              <td>360012010</td>
                            </tr>
                            <tr>
                              <td>BRANCH</td>
                              <td>RAJKOT (RING ROAD) BR, RAJKOT</td>
                              <td>RAJKOT (RING ROAD) BR, RAJKOT</td>
                              <td>PALACE ROAD RAJKOT</td>
                              <td>MANDVI CHOWK RAJKOT</td>
                            </tr>
                          </table>
                        </div>
                          <div class="custom-table  ">
                          <table class="table  bank-detail-table">
                            <tr>
                              <th>NAME OF BANK</th>
                              <th>YES BANK</th>
                              <th>KOTAK BANK</th>
                              <th></th>
                              <th></th>
                            </tr>
                            <tr>
                              <td>NAME OF ACCOUNT</td>
                              <td>AADESH TRAVELS PVT.LTD.</td>
                              <td>AADESH TRAVELS PVT.LTD.</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>ACCOUNT NO.</td>
                              <td>47584900000023</td>
                              <td>6611610829</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>IFSC CODE</td>
                              <td>YESB0000475</td>
                              <td>KKBK0000831</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>MICR CODE</td>
                              <td>360532003</td>
                              <td>360485002</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>BRANCH</td>
                              <td>150 FT RING ROAD, RAJKOT</td>
                              <td>YAGNIK ROAD, RAJKOT</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                                <td style="max-width: 222px !important">Note</td>
                                <td colspan="4">Requested to send scan / photocopy of deposit slip on "<a href="mailto:accounts@aadeshtravels.com">accounts@aadeshtravels.com</a>"</td>
                            </tr>
                          </table>
                        </div>
                          <div class="custom-table">    
                          <table class="table  bank-detail-table">
                            <tr>
                              <th colspan="5">Contact Nos. for Accounts</th>
                            </tr>
                            <tr>
                              <td class="head-hilight">Designation</td>
                              <td class="head-hilight">Name</td>
                              <td class="head-hilight">Contact Nos.</td>
                              <td class="head-hilight" colspan="2">E-Mail ID</td>
                            </tr>
                            <tr>
                              <td>Accounts Manager</td>
                              <td>Mehul Kakkad</td>
                              <td>+91 7878856721</td>
                              <td colspan="2"><a href="mailto:mkakkad@aadeshtravels.com">mkakkad@aadeshtravels.com</a></td>
                            </tr>
                            <tr>
                              <td>Asst. Accts Manager</td>
                              <td>Amitsinh Padhiyar</td>
                              <td>+91 9712810101</td>
                              <td colspan="2"><a href="mailto:amit@aadeshtravels.com">amit@aadeshtravels.com</a></td>
                            </tr>
                            <tr>
                              <td>Account Executive</td>
                              <td>Mansi Selarka</td>
                              <td>+91 281 2411111</td>
                              <td colspan="2"><a href="mailto:accounts@aadeshtravels.com">accounts@aadeshtravels.com</a></td>
                            </tr>
                            <tr>
                              <td>Account Executive</td>
                              <td>Rucha Shukla</td>
                              <td>+91 281 2411111</td>
                              <td colspan="2"><a href="mailto:accounts@aadeshtravels.com">accounts@aadeshtravels.com</a></td>
                            </tr>
                            <tr>
                                <td>Account Executive</td>
                                <td>Mehul Vadher</td>
                                <td>+91 281 2411111</td>
                                <td colspan="2"><a href="mailto:accounts@aadeshtravels.com">accounts@aadeshtravels.com</a></td>
                              </tr>
                              <tr>
                                <td>Account Executive</td>
                                <td>Kandarp Rathod</td>
                                <td>+91 9824249908</td>
                                <td colspan="2"><a href="mailto:accounts@aadeshtravels.com">accounts@aadeshtravels.com</a></td>
                              </tr>
                          </table>
                        </div>

                    </mat-tab>
                </mat-tab-group>
            </div>
          
        </div> -->
        <div class="row">
            <div class="col-md-9 card">
                <div class="card-header">
                <h4>Payment</h4>
                </div>
                <div class="alert alert-success msgSuccess mt-3" *ngIf="successMsg">
                    <strong>Success!</strong> {{successMessage}}
                </div>
                <div class="alert alert-warning msgSuccess mt-3" *ngIf="isWarning">
                    <strong>Warning!</strong> {{warningMessage}}
                </div>
                <div class="alert alert-danger msgError mt-3" *ngIf="errorMsg && isResponse">
                    <a href="#" class="alert-link"> Uh-oh! Your payment is failed due to {{paymentStatusResponse?.status_message}}</a>.
                    <button style="cursor: pointer;" *ngIf="!viewDetail && isResponse" (click)="showDetail()" class="btn-info">View Details</button>
                    <button style="cursor: pointer;" *ngIf="viewDetail && isResponse" (click)="showDetail()" class="btn-info">Hide Details</button>
                </div>
                <div class="alert alert-danger msgError mt-3" *ngIf="errorMsg && !isResponse">
                    <a href="#" class="alert-link"> Uh-oh! Your payment is failed due to wrong record </a>
                </div>

                <div *ngIf="viewDetail && isResponse" class="extra-details">
                    <span class="detailed-info">Order ID : <span class="detail-data">{{paymentStatusResponse?.order_id}}</span></span>
                    <span class="detailed-info">Transaction Reference Number: <span class="detail-data">{{paymentStatusResponse?.tracking_id}}</span></span>
                    <span class="detailed-info">Bank Reference Number: <span class="detail-data">{{paymentStatusResponse?.bank_ref_no}}</span></span>
                    <span class="detailed-info">Payment Status :<span class="detail-data">{{paymentStatusResponse?.order_status}}</span></span>
                    <span class="detailed-info">Amount :<span class="detail-data">{{paymentStatusResponse?.amount}}</span></span>
                    <span class="detailed-info">Response Code :<span class="detail-data">{{paymentStatusResponse?.response_code}}</span></span>
                    <span class="detailed-info">Payment Mode :<span class="detail-data">{{paymentStatusResponse?.payment_mode}}</span></span>
                </div>
                <div class="card-body">
                <form [formGroup]="paymentForm" id="offline" class="mat-white-input-form offpayment">
                    <div class="details">
                        <div class="row ">
                            <div class="w-100">
                                <div class="form-group col-4 float-left">
                                    <label for="agencycode">Agency Name</label>
                                    <input autocomplete="off" class="form-control m-input" formControlName="txtAgencyName" type="text" id="txtAffliateName" placeholder="Enter Affiliate Name" id="example-text-input" disabled>
                                </div>

                                <div class="form-group col-4 float-left">
                                    <label for="agencycode">Agency Code</label>
                                    <input type="text" class="form-control agency-field" id="agencycode" formControlName="txtAgencyCode" placeholder="Agency Code" disabled>
                                </div>

                                <div class="form-group col-4 float-left">
                                    <label for="deposittype">Type of Deposit</label>
                                    <select class="form-control" id="deposittype" formControlName="selDepositType" (change)="changeDeposit($event.target.value)">
                                        <option value="cash">Cash</option>
                                        <option value="cheque">Cheque</option>
                                        <option value="credit-card">Online payment</option>
                                    </select>
                                    <div class="error" *ngIf="(!selDepositType.valid && selDepositType.touched) || (!selDepositType.valid && hasError)">Please select deposit type</div>
                                </div>

                                <div class="form-group col-4 float-left">
                                    <div class="date date-picker">
                                        <label for="selectdate">Date</label>
                                        <input bsDatepicker autocomplete="off" #fDate formControlName="txtDepositDate" #dpYMD="bsDatepicker" [bsConfig]="bsConfig" type="text" class="custom-input form-control m-input" placeholder="Select Date" />
                                        
                                    </div>
                                    <div class="error" *ngIf="(!txtDepositDate.valid && txtDepositDate.touched) || (!txtDepositDate.valid && hasError)">Please select deposit date</div>
                                </div>

                                <div class="form-group col-4 float-left">
                                    <label for="depositamount">Deposit Amount ( {{!creditBal ? 0 : creditBal}} )</label>
                                    <input type="number" class="form-control" (change)="getCharge(txtDepositAmount.value)" formControlName="txtDepositAmount" id="depositamount" placeholder="Enter amount (in INR)">
                                    <div class="error" *ngIf="(!txtDepositAmount.valid && txtDepositAmount.touched)  || (!txtDepositAmount.valid && hasError)">Enter Amount</div>
                                    <div class="error" *ngIf="txtDepositAmount.value > 195000 && selDepositType.value == 'cash'">Amount must be less than 1,95,000 in case of <strong>Cash deposit</strong></div>
                                </div>

                                <div class="form-group col-4 float-left" *ngIf="!IsDebit">
                                    <label for="beneficiarybank">Beneficiary Bank</label>
                                    <select class="form-control" formControlName="selBeneficiaryBank" id="beneficiarybank">
                                        <ng-container *ngFor="let list of generalLedger">
                                            <option value="{{list.id}}">{{list.txtLedgerName}}</option>
                                        </ng-container>
                                    </select>
                                    <div class="error" *ngIf="(!selBeneficiaryBank.valid && selBeneficiaryBank.touched) || (!selBeneficiaryBank.valid && hasError)">Please select beneficiary account</div>
                                </div>

                                <div class="form-group col-4 float-left" *ngIf="IsCheque">
                                    <label for="chequeddno">Cheque / DD No.</label>
                                    <input type="number" class="form-control" id="chequeddno" formControlName="txtChequeNo" placeholder="Cheque / DD No.">
                                    <div class="error" *ngIf="(!txtChequeNo.valid && txtChequeNo.touched) || (!txtChequeNo.valid && hasError)">Please select account type</div>
                                </div>

                                <div class="form-group col-4 float-left" *ngIf="IsCheque">
                                    <div class="date date-picker">
                                        <label for="chequeddissue">Cheque / DD Issue Date</label>
                                        <input bsDatepicker autocomplete="off" #fDate formControlName="txtChequeDate" #dpYMD="bsDatepicker" [bsConfig]="bsConfig" type="text" class="custom-input form-control m-input" placeholder="Select Date" />
                                        <div class="error" *ngIf="(!txtChequeDate.valid && txtChequeDate.touched) || (!txtChequeDate.valid && hasError)">Please select account type</div>
                                    </div>
                                </div>

                                <div class="form-group col-4 float-left" *ngIf="IsCheque">
                                    <label for="depbankname">Depositor's Bank Name</label>
                                    <input type="text" class="form-control" formControlName="txtDepositorBankName" id="depbankname" placeholder="Depositor's Bank Name">
                                    <div class="error" *ngIf="(!txtDepositorBankName.valid && txtDepositorBankName.touched) || (!txtDepositorBankName.valid && hasError)">Please select account type</div>
                                </div>

                                <div class="form-group col-4 float-left" *ngIf="IsDebit">
                                    <label for="depbankname">Amount to be charged</label>
                                    <input type="text" class="form-control" formControlName="txtAmountCharged" placeholder="Amount to be charged" readonly>
                                </div>

                                <div class="clearfix"></div>

                                <div class="row justify-content-center mb-4 payment-mode-container" *ngIf="IsDebit">
                                    <span class="section-head">Select payment mode</span>
                                    <div class="account-type-select">


                                        <div class="custom-control custom-radio pmt-mode cc-card"  (click)="setPaymentMethod('credit-card')">
                                            <input formControlName="cardType" value="OPTCRDC" name="cardType" type="radio" id="credit-card" class="custom-control-input" checked>
                                            <label class="custom-control-label" for="credit-card"><span class="mode-type mr-3">Credit Card</span><img src="../../../assets/images/payment-options.png" alt=""></label>
                                        </div>
                                        <div class="custom-control custom-radio pmt-mode cc-card" (click)="setPaymentMethod('debit-card')">
                                            <input formControlName="cardType" value="OPTDBCRD" name="cardType" type="radio"  id="debit" class="custom-control-input" checked>
                                            <label class="custom-control-label" for="debit"><span class="mode-type mr-3">Debit Card</span><img src="../../../assets/images/payment-options.png" alt=""></label>
                                        </div>
                                        <div class="custom-control custom-radio pmt-mode cc-card" (click)="setPaymentMethod('netbanking')">
                                            <input formControlName="cardType" value="OPTNBK" name="cardType" type="radio" id="netbnking" class="custom-control-input" checked>
                                            <label class="custom-control-label" for="netbnking"><span class="mode-type mr-3">Net Banking</span></label>
                                        </div>
                                       
                                    </div>

                                    
                                </div>

                                <div class="row mt-3">
                                    <div class="col-12">
                                        <label class="sr-only" for="remarks"></label>
                                        <div class="input-group mb-2">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text hilight">Remarks</div>
                                            </div>
                                            <input type="text" class="form-control remarks-field" maxlength="35" formControlName="txtRemarks" id="remarks" placeholder="Enter remarks here">
                                        </div>
                                    </div>
                                </div>

                                <div class="field w100 text-center mt-3 mb-3">
                                    <button type="submit" class="btn btn-lg btn-success" *ngIf="!IsDebit" (click)="submitPaymentReq(paymentForm.value)">SAVE</button>
                                    <button type="submit" class="btn btn-green" (click)="submitPaymentReq(paymentForm.value)" *ngIf="IsDebit">PROCEED TO PAY</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                </div>
            </div>
            <div class="col-md-3 ">
                <div class="card">
                   <div class="card-header">
                    <h4>Bank Details</h4>
                   </div>
                <div class="card-body ">
                    <ul class="m-0">
                        <li><span>Beneficiary Name:</span> <p class="text-bold">AADESH TRAVELS PVT LTD</p></li>
                        <li><span>Bank A/c Number:</span> <p class="text-bold">AADT{{this.affiliateXlCode}}</p></li>
                        <li><span>IFSC Code:</span> <p class="text-bold">ICIC0000104</p></li>
                    </ul>
                </div>
                </div>
            </div>
        </div>
    </div>
</section>