export enum ActionTypes {
    SetInsuranceTraveller = '[AddInsuranceTravller] Add Traveller',
 
  }
  
  export const SetInsuranceTraveller = payload => ({
    type : ActionTypes.SetInsuranceTraveller,
    payload
  })

  
  