  <!-- <div class="whatsapp-chat popup" *ngIf="isPopupVisible">
    <div class="popup-inner">
    <div class="popup-content">

      <div class="aadesh-logo">
        <img src="	https://aadeshtravels.com/v3/assets/v3images/logoes/aadesh-icon.svg" width="90px" alt="User Image" class="round-image">
      </div>
      
      <p>Hello, Got any questions? We’re here to help...</p>

      <div class="gdc-lcc-button">
        <button (click)="redirectToWhatsApp('GDS')" class="gds-btn mb-2">GDS</button>
        <button (click)="redirectToWhatsApp('LCC')" class="lcc-btn">LCC</button>
      </div>

    </div>
  </div>
  </div>

  <div class="message-popup" *ngIf="showMessagePopup">
    <div class="message-content">
      <p>{{ message }}</p>
      <button (click)="hideMessagePopup()">Close</button>
    </div>
  </div> -->


  <div class="whatsapp-chat popup" *ngIf="isPopupVisible">
    <div class="popup-inner">
      <div class="popup-content">
        <div class="aadesh-logo">
          <img src="https://aadeshtravels.com/v3/assets/v3images/logoes/aadesh-icon.svg" width="90px" alt="User Image" class="round-image">
        </div>
  
        <div *ngIf="!showMessagePopup">
          <p>Hello, Got any questions? We’re here to help...</p>
          <div class="gdc-lcc-button">
            <button (click)="redirectToWhatsApp('GDS')" class="gds-btn mb-2">GDS</button>
            <button (click)="redirectToWhatsApp('LCC')" class="lcc-btn">LCC</button>
          </div>
        </div>
  
        <div *ngIf="showMessagePopup">
          <div >
            <p>{{ message }}</p>
            <button class="close-btn  mb-2" (click)="hideMessagePopup()">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  