<div class="clearfix"></div>
<div class="container terms-box my-5">

  <div class="section-head">General Policy</div>

  <span class="para">Aadesh Travels Pvt. Ltd. (hereinafter “ATPL”) reserves the right, in its sole discretion, to
    terminate the
    access to any or all ATPL websites or its other sales channels and the related services or
    any portion thereof at any time, without notice, for general maintenance or any reason what so
    ever...</span>
    <span class="para">In addition to the cost of booking as charged by the Service Providers, ATPL reserves the right to charge certain fees in the nature of convenience fees or service fees. ATPL further reserves the right to alter any and all fees from time to time. Any such additional fees, including fee towards any modifications thereof, will be displayed to the User before confirming the booking or collecting the payment from such User.</span>
    <span class="para">In cases of short charging of the booking amount, taxes, statutory fee, convenience fee etc., owing to any technical error or other reason, ATPL shall reserve the right to deduct, charge or claim the balance amount from the User and the User shall pay such balance amount to ATPL. In cases where the short charge is claimed prior to the utilization of the booking, ATPL will be at liberty to cancel such bookings if the amount is not paid before the utilization date.</span>
    <span class="para">Any increase in the price charged by ATPL on account of change in rate of taxes or imposition of new taxes, levies by Government shall have to be borne by the User. Such imposition of taxes, levies may be without prior notice and could also be retrospective but will always be as per applicable law.</span>
    <span class="para">In the rare circumstance of a booking not getting confirmed for any reason whatsoever, ATPL will process the refund of the booking amount paid by the User and intimate the User about the same. ATPL is not under any obligation to provide an alternate booking in lieu of or to compensate or replace the unconfirmed booking. All subsequent bookings will be treated as new transactions. Any applicable refund will be processed as per the defined policies of the service provider and ATPL as the case may be.</span>
    <span class="para">The User shall be completely responsible for all charges, fees, duties, taxes, and assessments arising out of the use of the service, as per the applicable laws.</span>
    <span class="para">The User agrees and understands that all payments shall only be made to bank accounts of ATPL. ATPL or its agents, representatives or employees shall never ask a customer to transfer money to any private account or to an account not held in the name of ATPL. The User agrees that if that user transfers any amount against any booking or transaction to any bank account that is not legitimately held by ATPL or to any personal account of any person, ATPL shall not be held liable for the same. User shall not hold any right to recover from ATPL any amount which is transferred by the User to any third party.</span>
  <span class="para">Any dispute or claim arising out of or in connection with this website shall be
    governed and construed in accordance with the laws of Rajkot, India.</span>
  <span class="para">If you make a payment for our products or services on our website, the details
    you are asked to submit will be provided directly to our payment provider via a secured
    connection(The cardholder must retain a copy of transaction records and Merchant policies and
    rules).</span>
  <span class="para">All prices displayed are final payable amount to us however in event where
    government authorities, local governing bodies or service provider applies any additional tax, fees,
    service charges, resort fee or any other indirect charge without prior notification; these
    additional charge(s) will be levied by us on to the reservation and it will not be part of the
    reservation amount booked or paid. We will not be liable to pay this additional charges/taxes/resort
    fees/Vat as we have limited control on such additional charges.</span>

  <div class="action-btn text-center clearfix mt-4">
    <a class="" href="javascript:void(0)" routerLink="/">Back</a>
  </div>

</div>
