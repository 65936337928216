import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CalculatePriceService {

	constructor() { }

	calculatePaxFareBreakup(data) {
		let newObj = {};
		newObj["Base"] = data.base;
		if (data.fareComponents != null) {
			let b = Object.keys(data.fareComponents);
			let v = Object.values(data.fareComponents)
			let total = 0;
			let otherTax = 0;
			let base = 0
			let adminServiceCharge = 0;
			let gst = 0;
			let supplier = ''
			if(data.supplierInfo && data.supplierInfo.supplierCode) {
				supplier = data.supplierInfo.supplierCode
			}
			for (let i = 0; i < b.length; i++) {
				let k = b[i];
				let kv = v[i];
				//  k != "GST" && Remove GST 
				if (k != "MARKUP" && k != "COMMISSION" && k != "TOTAL" && k != "TDS" && k != "NET_PAYABLE" && 
					k != "ADMIN_MARKUP" && k != "OTHER_TAX" && k != "AFFILIATE_MARKUP"  && k != "ADMIN_SERVICE_CHARGE" && k != "REISSUANCE_ADMIN_MARKUP") {
					newObj[k] = kv;
					total += Number(kv);
				}
				if(k == "OTHER_TAX" ||  k == "ADMIN_MARKUP" || k == "REISSUANCE_ADMIN_MARKUP") {
					otherTax += Number(kv);
					total += Number(kv);
				}
				
				// if(k == "ADMIN_SERVICE_CHARGE") {
				// 	adminServiceCharge += Number(kv);
				// 	total += Number(kv);
				// }
				// if(k == "GST") {
				// 	gst += Number(kv);
				// 	total += Number(kv);
				// }
			}
			total += data.base;
			newObj["Other Taxes"] = otherTax;
			// newObj["Service Charge"] = adminServiceCharge;
			// newObj["GST On Service Charges"] = gst;
			newObj["Total"] = total;
		}
		return newObj;
	}

	calculateTax(data) {
		let otherTax = 0;
		if (data != null) {
			let b = Object.keys(data);
			let v = Object.values(data)
			for (let i = 0; i < b.length; i++) {
				let k = b[i];
				let kv = v[i];
				if (k != "MARKUP" && k != "COMMISSION" && k != "TOTAL" && k != "TDS" && k != "NET_PAYABLE") {
					otherTax += Number(kv);
				}
			}
		}
		return Math.round(otherTax);
	}
}
