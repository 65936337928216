import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { LedgerService } from 'src/app/services/ledger/ledger.service';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-statement',
	templateUrl: './statement.component.html',
	styleUrls: ['./statement.component.scss']
})
export class StatementComponent implements OnInit {

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('printBox') printBox : ElementRef;
	dataSource: any = [];
	openingBal: number = 0;

	navigationSubscription;
	ascSortRef: boolean = true;
	ascBookDateSort: boolean = true;
	ascJourneyDateSort: boolean = true;
	innerHeight: string;

	fromDate : Date;
	toDate : Date;
	showLogo = false
	printDiv: boolean  =false;

	constructor(private ledegrService: LedgerService,
		private commonService: CommonService,
		private router: Router) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
	}

	ngOnInit() {
		this.submit()
	}

	initialiseInvites() {
		this.submit()
	}

	ngOnDestroy() {

		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}
	invoiceSort() {
		this.ascSortRef = !this.ascSortRef;
		var testData = this.dataSource;
		this.dataSource = testData.sort((a, b) => {
			if (this.ascSortRef) {
				if (b.transaction_ref_no < a.transaction_ref_no) {
					return -1;
				}
				if (b.transaction_ref_no > a.transaction_ref_no) {
					return 1;
				}
				return 0;
			} else {
				if (a.transaction_ref_no < b.transaction_ref_no) {
					return -1;
				}
				if (a.transaction_ref_no > b.transaction_ref_no) {
					return 1;
				}
				return 0;
			}
		});
	}
	dateSort() {
		this.ascBookDateSort = !this.ascBookDateSort;
		var testData = this.dataSource;
		this.dataSource = testData.sort((a, b) => {
			if (this.ascBookDateSort) {
				if (b.timestamph < a.timestamph) { return -1; }
				if (b.timestamph > a.timestamph) { return 1; }
				return 0;
			}
			else {
				if (a.timestamph < b.timestamph) { return -1; }
				if (a.timestamph > b.timestamph) { return 1; }
				return 0;
			}
		});

	}

	submit() {
		let user = JSON.parse(localStorage.user_details)
		let value = {};
		const lThis = this
		value['xlCode'] = user.xlAccountCode
		this.ledegrService.viewStatement(value).subscribe(res => {
			if (JSON.parse(res['_body']).length > 0) {

				this.dataSource = JSON.parse(res['_body']);
				if (this.dataSource.length > 0) {
					lThis.fromDate = this.dataSource[0].timestamph
					lThis.toDate = this.dataSource[this.dataSource.length - 1].timestamph
				}
				this.printDiv = false
			}

			if (this.dataSource.length <= 3) {
				this.innerHeight = 'auto';
			}
			else {
				this.innerHeight = window.innerHeight - 400 + 'px';
			}
		})
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if (this.dataSource.length <= 3) {
			this.innerHeight = 'auto';
		}
		else {
			this.innerHeight = event.target.innerHeight - 350 + 'px';
		}
	}
	callPdfGeneratorApi() {
		window.scrollTo(0, 0)
		
		const lThis = this
		lThis.printDiv =true
		this.showPrintBox()
		setTimeout(() => {
			window.print()
			lThis.printDiv = false
			this.hidePrintBox()
		}, 100)
	}
	private showPrintBox(): void {
		this.printBox.nativeElement.offsetParent.classList.add('d-block')
		}
	private hidePrintBox(): void {
		this.printBox.nativeElement.offsetParent.classList.remove('d-block')
		}
	

}