import { NgRedux } from '@angular-redux/store';
import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { createStore } from 'redux';
import { CommonService } from 'src/app/services/common.service';
import { InitialState } from 'src/app/store/reducer';

@Component({
  selector: 'app-add-on-fare',
  templateUrl: './add-on-fare.component.html',
  styleUrls: ['./add-on-fare.component.scss']
})
export class AddOnFareComponent implements OnInit {
  @ViewChild('menu') modalContainer: ElementRef;
  isClickedOnAddonSideBarButton:boolean=false;
  selectedSsrInRedux;
  constructor(
    private commonService: CommonService,
    private ngRedux: NgRedux<InitialState>,) { 
    this.ngRedux
    .select('SSrReducers')
    .subscribe((items : any) => {
      this.selectedSsrInRedux = items.selectedSsr;
      this.isClickedOnAddonSideBarButton = items.showAddonSummary
    });
  }

  ngOnInit() {

    this.commonService.isAddonSideBarActive.subscribe(val=>{
      console.log(val)
      this.isClickedOnAddonSideBarButton = val
    })

    
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
     if (this.modalContainer  && this.modalContainer.nativeElement && !this.modalContainer.nativeElement.contains(event.target)) {
        // clicked outside => close dropdown list
     this.isClickedOnAddonSideBarButton = false;
     }
  }

    hideAddOnSidebar(){
      this.isClickedOnAddonSideBarButton = false;
    }

}
