import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SetGlobalMessage } from '../store/commonStore/actions';
import { InitialState } from '../store/reducer';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  messageObj ={
		status : false,
		messageList : []
	}
  private messageSource = new BehaviorSubject(this.messageObj);
	messageStatus = this.messageSource.asObservable();

  constructor(
    private ngRedux: NgRedux<InitialState>
  ) { }

  showMessage(obj) {
		this.ngRedux.dispatch(SetGlobalMessage(obj))
		this.messageSource.next(obj)
	}
}
