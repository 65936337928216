import { ActionTypes } from "./actions";

export const initialState = {
  globalMessage : {
    status : false,
    messageList : []
  },
};

export function CommonReducers(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SetGlobalMessage:
      return {
        ...state,
        globalMessage : action.payload
      }
    default:
      return state;
  }
}






