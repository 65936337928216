import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-booking-activity',
  templateUrl: './booking-activity.component.html',
  styleUrls: ['./booking-activity.component.scss']
})
export class BookingActivityComponent implements OnInit {


  @Input() bookingActivity;
  @Input() bookingActivityReference;
  constructor() { }

  ngOnInit() {
  }

    getRandomNumber(){
    return Math.floor((Math.random() * 4))
  }

  printInvoice(invoice_no,status) {
    let url = ''
    let systacc_access_token = localStorage.getItem('systacc_access_token')
    if (status == 'REFUNDED') {
       url = `${environment.okountWebAppUrl}/#/transactions/print-credit-note/${invoice_no};type=flight?access_token=${systacc_access_token}`
		}else{
			 url = `${environment.okountWebAppUrl}/#/transactions/invoice-view;searchId=${invoice_no};type=flight?access_token=${systacc_access_token}`
      }
			window.open(url)
  }
  

  openDetail(reference_no) {
    let url = `${environment.baseUrl}/#/booking-summary-detail;searchId=${reference_no}`
		window.open(url)
	}

}
