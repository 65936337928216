export enum ActionTypes {
    SetGlobalMessage = '[SetGlobalMessage] Set Global Message to show error/warning/success',
 
  }
  
  export const SetGlobalMessage = payload => ({
    type : ActionTypes.SetGlobalMessage,
    payload
  })

  
  