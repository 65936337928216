import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-addon-baggage',
  templateUrl: './addon-baggage.component.html',
  styleUrls: ['./addon-baggage.component.scss']
})
export class AddonBaggageComponent implements OnInit {
  	@Input() ssrAvailability;
  	@Input() responseData;
  	@Input() showRequestLoader;
	@Output() callBaggageEvent : EventEmitter<any> = new EventEmitter();
	@Output() callAdditionalBaggageEvent : EventEmitter<any> = new EventEmitter();

	constructor() { }

	ngOnInit() {
		for (const segment of this.ssrAvailability) {
			for (var ssr of segment) {
				for (var traveller of ssr.travellers) {
					if(ssr.baggages && ssr.baggages.length > 0 && ssr.baggages[0].additional_baggage){
						traveller['additional_baggage'] = ssr.baggages[0].additional_baggage
					}
				}
			}
		}
	}
	ngOnChanges(){
		console.log(`SSR in baggage : `,this.ssrAvailability)
		for (const segment of this.ssrAvailability) {
			for (var ssr of segment) {
				for (var traveller of ssr.travellers) {
					if(ssr.baggages && ssr.baggages.length > 0 && ssr.baggages[0].additional_baggage){
						traveller['additional_baggage'] = ssr.baggages[0].additional_baggage
					}
				}
			}
		}
	}
	changeBaggage(index, pax,segment){
		let leg = 0;
		const tempArr = Object.assign({},  this.ssrAvailability[segment][leg])
			if(index == "-1") {
				this.ssrAvailability[segment][leg].travellers[pax]['baggage'] = undefined;
				this.ssrAvailability[segment][leg].travellers[pax]['baggageFare'] = 0;
				this.makeSeatObject(pax, null, true, 0, leg,segment,tempArr,null);
			}
			else{
				let baggages = tempArr.baggages[index];
				this.ssrAvailability[segment][leg].travellers[pax]['baggage'] = baggages.code;		
				// if(baggages.name == null) {
				// 	this.ssrAvailability[segment][leg].travellers[pax]['baggage'] = baggages.code;
				// }
				this.ssrAvailability[segment][leg].travellers[pax]['baggageFare'] = baggages.totalFare;
				this.makeSeatObject(pax, baggages.code, false, baggages.totalFare, leg,segment,tempArr,baggages);
			}
			
	}
	

	makeSeatObject(passengerIndex, seatNumber, isRemove, price, leg,segment,tempArr,baggages){
		let flag = true;
		if(tempArr.flightInfo.ssrs[segment] && tempArr.flightInfo.ssrs[segment][leg]  && tempArr.flightInfo.ssrs[segment][leg].baggage != undefined && tempArr.flightInfo.ssrs[segment][leg].baggage != null) {
			tempArr.flightInfo.ssrs[segment][leg].baggage = tempArr.flightInfo.ssrs[segment][leg].baggage.filter(obj =>{
				if(isRemove) {
					flag = false;
					if(obj.travellerNumber != passengerIndex) {
						return obj;
					}
				}
				else{
					if(obj.travellerNumber == passengerIndex) {
						obj['code'] = seatNumber;
						obj['price'] = price;
						obj['item'] = baggages;
						flag = false;
					}
					return obj;
				}
				
			});
		}
		
		if(flag) {
			let obj = {
				"code": seatNumber,
				"travellerNumber": passengerIndex,
				"item": baggages,
				"price" : price,
				"title": this.ssrAvailability[segment][leg].travellers[passengerIndex].title,
				"firstName": this.ssrAvailability[segment][leg].travellers[passengerIndex].first_name,
				"lastName": this.ssrAvailability[segment][leg].travellers[passengerIndex].last_name,
			}
			if(tempArr.flightInfo.ssrs[segment] == undefined){
				tempArr.flightInfo.ssrs[segment] = []
			}
			if(tempArr.flightInfo.ssrs[segment][leg] == undefined){
				tempArr.flightInfo.ssrs[segment][leg] = {}
			}
			if(tempArr.flightInfo.ssrs[segment][leg].baggage == undefined || tempArr.flightInfo.ssrs[segment][leg].baggage == null) {
				tempArr.flightInfo.ssrs[segment][leg].baggage = [];				
			}
			tempArr.flightInfo.ssrs[segment][leg].baggage.push(obj)
		}
		this.callBaggageEvent.emit(tempArr.flightInfo.ssrs);
		
	}

	makeAdditionalBaggeObject(passengerIndex, pax, isRemove, leg,tempArr,segment){
		let flag = true;
		if(tempArr.flightInfo.ssrs[segment] && tempArr.flightInfo.ssrs[segment][leg]  && tempArr.flightInfo.ssrs[segment][leg].additionalBaggage != undefined && tempArr.flightInfo.ssrs[segment][leg].additionalBaggage != null) {
			tempArr.flightInfo.ssrs[segment][leg].additionalBaggage = tempArr.flightInfo.ssrs[segment][leg].additionalBaggage.filter(obj =>{
				if(isRemove &&  pax.additional_baggage_selected_count == 0) {
					flag = false;
					if(obj.travellerNumber != passengerIndex) {
						return obj;
					}
				}
				else{
					if(obj.travellerNumber == passengerIndex) {
						obj['code'] = pax.additional_baggage.code;
						obj['price'] = pax.additional_baggage_selected_count * pax.additional_baggage.price;
						obj['item'] = pax.additional_baggage;
						obj['count'] = pax.additional_baggage_selected_count;
						flag = false;
					}
					return obj;
				}
				
			});
		}
		
		if(flag) {
			let obj = {
				"code": pax.additional_baggage.code,
				"travellerNumber": passengerIndex,
				"item": pax.additional_baggage,
				"price" : pax.additional_baggage_selected_count * pax.additional_baggage.price,
				"count" : pax.additional_baggage_selected_count,
			}
			if(tempArr.flightInfo.ssrs[segment] == undefined){
				tempArr.flightInfo.ssrs[segment] = []
			}
			if(tempArr.flightInfo.ssrs[segment][leg] == undefined){
				tempArr.flightInfo.ssrs[segment][leg] = {}
			}
			if(tempArr.flightInfo.ssrs[segment][leg].additionalBaggage == undefined || tempArr.flightInfo.ssrs[segment][leg].additionalBaggage == null) {
				tempArr.flightInfo.ssrs[segment][leg].additionalBaggage = [];				
			}
			tempArr.flightInfo.ssrs[segment][leg].additionalBaggage.push(obj)
		}
		this.callAdditionalBaggageEvent.emit(tempArr.flightInfo.ssrs);
		
	}

	handleAdditionalSelect(isIncrement,pax,passengerIndex,segmentIndex){
		let leg = 0
		const tempArr = Object.assign({},  this.ssrAvailability[segmentIndex][leg])
		if(isIncrement){
			pax.additional_baggage_selected_count  = pax.additional_baggage_selected_count ? pax.additional_baggage_selected_count+1 : 1
			this.makeAdditionalBaggeObject(passengerIndex, pax, false, leg,tempArr,segmentIndex)
		}else{
			pax.additional_baggage_selected_count = pax.additional_baggage_selected_count-1
			this.makeAdditionalBaggeObject(passengerIndex, pax, true, leg,tempArr,segmentIndex)
		}
	}
}
