<section class="content afterbooking relative" *ngIf="bookingArr.length != 0">	
		<div class="actionbtn btnBacktolist">
			<a href="javascript:void(0)" routerLink="/bookingsummary">Back To List</a>
		</div>	
	<div class="container" *ngIf="valid">
		<div class="iddetails row">
			<p><span>Booking ID:</span> {{bookingData.booking_ref}}</p>
			<!--<p><span>Invoice No:</span> {{bookingData.invoice_no}}</p>-->
			<p><span>Booking Date:</span> {{bookingData.booked_timestamp | date}}</p>
		</div>
	
		<div class="bookingdetails pt-2 pb-0 row" *ngFor="let segment of bookingData['segments']">
				<h4 class="pb-0 d-flex">{{segment['legs'][0]['origin_airport_code']}} <div class="px-1 d-flex align-items-center justify-content-around">
					<img class="w-75" src="assets/images/icon10.png" alt="">
				</div>
					{{segment['legs'][segment['legs'].length-1]['destination_airport_code']}}</h4>
				<div *ngFor="let legs of segment.legs" class="legDetails pb-2 mt-2 w-100 d-flex">
					<div class="detailsbox col-1 ">
						<img src="assets/images/{{legs.operating_airline_code}}.png" alt="" onError="this.src='assets/images/icon7.png'">
					</div>
					<div class="detailsbox col-2">
						<p>{{legs.operating_airline_name}}</p>
						<span>{{legs.operating_airline_code}}-{{legs.flight_number}}</span>
			
					</div>
					
					<div class="detailsbox col-3">
						<p class="d-flex">Departs  &nbsp;&nbsp;<span class="time">{{legs.origin_timestamp | timeFormat }}</span></p>
						<p class="timedate">{{legs.origin_timestamp | dateFormat}}</p>
						<span>{{legs.origin_airport_city}}, <br> <small>{{legs.origin_airport_name}}</small></span>
					</div>
					<div class="midd col-1 row align-items-center">
						<img src="assets/images/icon10.png" alt="">
					</div>
					<div class="detailsbox col-3">
						<p class="d-flex">Arrives  &nbsp;&nbsp;<span class="time">{{legs.destination_timestamp | timeFormat }}</span></p>
						<p class="timedate">{{legs.destination_timestamp | dateFormat}}</p>
						<span>{{legs.destination_airport_city}}, <br> <small>{{legs.destination_airport_name}}</small></span>
					</div>
					<div class="detailsbox col">
						<p>Duration</p>
						<span>{{legs.duration}}</span>
					</div>
					<!-- <div class="detailsbox col">
							<p>Stop</p>
							<span>{{stop}}</span>
						</div> -->
				</div>
			</div>
			<div class="pricesummery row">
				<div class="col">
					<h4>Price Summary</h4>
					<table class="table">
						<thead class="thead-light">
							<tr>
								<th>S.No.</th>
								<th>Type</th>
								<th class="w-25">Traveler Name</th>
								<th>Status</th>
								<th>Base Fare</th>
								<th>YQ</th>
								<th>YR</th>
								<th>K3</th>
								<th>OC</th>
								<th>OT</th>
								<!-- <th>Tax</th> -->
								<th>Total Amount</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of  bookingData.travellers; let i = index;" class="table table-bordered">
								<td>{{i+1}}</td>
								<td>{{item.type}}</td>
								<td class="w-25"><span>{{item.first_name}} {{item.last_name}}</span></td>
								<td>{{item.ticket?.status}}</td>
								<td class="text-right"><span>{{checkIfNull(item.fare.base) | currency:"INR":"symbol-narrow"}}</span></td>
								<td class="text-right"><span>{{checkIfNull(item.fare.taxes[0].YQ) |currency:"INR":"symbol-narrow"}}</span></td>
								<td class="text-right"><span>{{checkIfNull(item.fare.taxes[0].YR) |currency:"INR":"symbol-narrow"}}</span></td>
								<td class="text-right"><span>{{checkIfNull(item.fare.taxes[0].K3) |currency:"INR":"symbol-narrow"}}</span></td>
								<td class="text-right"><span>{{checkIfNull(item.fare.taxes[0].OC) |currency:"INR":"symbol-narrow"}}</span></td>
								<td class="text-right"><span>{{checkIfNull(getOT(item)) |currency:"INR":"symbol-narrow"}}</span></td>
								<!-- <td class="text-right"><span>{{checkIfNull(getTax(item.fare.taxes[0].adminMarkup,item.fare.taxes[0].affiliate_markup)) |currency:"INR":"symbol-narrow"}}</span></td> -->
								<td class="text-right"><span> {{checkIfNull(getPerPrice(item)) |currency:"INR":"symbol-narrow"}}</span></td>
							</tr>
						</tbody> 	
					</table>					
					
					<div class="row">
						<div class="offset-7 col-1">
	
						</div>
						<div class="col-2 p-0">
							<b>Total</b>
						</div>
						<div class="col text-right">
							<span><b>{{getTotal(bookingData.travellers,total) | currency:"INR":"symbol-narrow"}}</b></span>
						</div>
					</div>
					<div class="row">
						<div class="offset-7 col-1">
							<p>Less</p>
						</div>
						<div class="col-2 p-0">
							<span>Affiliate Markup</span>
						</div>
						<div class="col text-right">
							<span>{{getPrice(bookingData.travellers,'AFFILIATE_MARKUP') |currency:"INR":"symbol-narrow"}}</span>
						</div>
					</div>
					<div class="row">
						<div class="offset-7 col-1">
							<p>Less</p>
						</div>
						<div class="col-2 p-0">
							<span>Affiliate Addon Markup 
								<span class="markupEdit">(<a href="javascript:void(0)" (click)="editMarkUp()">Edit</a>)</span>
							</span>
						</div>
						<div class="col text-right">
							<span>₹ {{affiliateAddOnMarkUpPrice}}</span>
						</div>
					</div>
					<form class="row" [formGroup]="addOnMarkupForm" *ngIf="isAddonMarkupEnable">
						<div class="offset-7 col text-right">
							<div class="row">
								<select class="form-control font-12 w-100" formControlName="showAs">
									<option value="">Show As</option>
									<option value="hidden markup">Hidden Markup</option>
									<option value="taxable markup">Taxable Markup</option>
								</select>
								<span *ngIf="!showAs.valid && (showAs.dirty || showAs.touched)" class="amt mb-2">
									Please select valid value
								</span>
							</div>						
						</div>
						&nbsp; &nbsp;
						<div class="col">
							<div class="row">
								<!-- <span class="col-3 ml-2 mr-1 mt-2">₹</span>  -->
								<input type="number" class="form-control col font-12" formControlName="amount" placeholder=" &#8377; Enter amount">
								<span *ngIf="!amount.valid && (amount.dirty || amount.touched)" class="amt mt-3">
									Please enter valid amount
								</span>
							</div>
						</div>
						<div class="col-1">
							<div class="row">
								<button  [disabled]="!addOnMarkupForm.valid" (click)="saveMarkup(addOnMarkupForm.value)"><i class="fa fa-check-circle" style="font-size:33px;color:#ff6600" ></i></button>
								
							</div>
						</div>						
					</form>
					<div class="row">
						<div class="offset-7 col text-center" *ngIf="successMsg">
							<span class="success">Add on markup saved successfully</span>
						</div>
					</div>
					<div class="row">
						<div class="offset-7 col-1">
							<p>Add</p>
						</div>
						<div class="col-2 p-0">
							<span>Service Charges</span>
						</div>
						<div class="col text-right">
							<span>{{getPrice(bookingData.travellers,'ADMIN_SERVICE_CHARGE') |currency:"INR":"symbol-narrow"}}</span>
						</div>
					</div>
					<div class="row">
						<div class="offset-7 col-1">
							<p>Add</p>
						</div>
						<div class="col-2 p-0">
							<span>GST <i>(On Service Charge)</i></span>
						</div>
						<div class="col text-right">
							<span>{{getPrice(bookingData.travellers,'GST') |currency:"INR":"symbol-narrow"}}</span>
						</div>
					</div>
					<div class="row">
						<div class="offset-7 col-1">
							<p>Less</p>
						</div>
						<div class="col-2 p-0">
							<span>Net Commission</span>
						</div>
						<div class="col text-right">
							<span>{{getPrice(bookingData.travellers,'COMMISSION') |currency:"INR":"symbol-narrow"}}</span>
						</div>
					</div>
					<div class="row">
						<div class="offset-7 col-1">
							<p>Add</p>
						</div>
						<div class="col-2 p-0">
							<span>TDS</span>
						</div>
						<div class="col text-right">
							<span>{{tds  | currency:"INR":"symbol-narrow"}}</span>
						</div>
					</div>
					<div class="row">
						<div class="offset-7 col-1">
							<p> &nbsp;</p>
						</div>
						<div class="col-2 p-0">
							<span>Net Payable</span>
						</div>
						<div class="col text-right">
							<span>₹{{netPayable |  number : '1.0-0'}}</span>
						</div>
					</div>
					<ng-container *ngIf="bookingData.pnr_status == 'REFUNDED'">
						<div class="row border-top pt-3">
							<div class="offset-7 col-1">
								<p>Less</p>
							</div>
							<div class="col-2 p-0">
								<span>TDS</span>
							</div>
							<div class="col text-right">
								<span>{{0 |  currency:"INR":"symbol-narrow"}}</span>
							</div>
						</div>
						<div class="row">
							<div class="offset-7 col-1">
								<p>Less</p>
							</div>
							<div class="col-2 p-0">
								<span>Airline cancellation charge</span>
							</div>
							<div class="col text-right">
								<span>{{getCancellationCharges('client_xxl_charges') |  currency:"INR":"symbol-narrow"}}</span>
							</div>
						</div>
						<div class="row">
							<div class="offset-7 col-1">
								<p>Less</p>
							</div>
							<div class="col-2 p-0">
								<span>My charge</span>
							</div>
							<div class="col text-right">
								<span>{{getCancellationCharges('client_raf_charges') |  currency:"INR":"symbol-narrow"}}</span>
							</div>
						</div>
						<!-- <div class="row">
							<div class="offset-7 col-1">
								<p>Less</p>
							</div>
							<div class="col-2 p-0">
								<span>Travelled Fare</span>
							</div>
							<div class="col text-right">
								<span>{{getCancellationCharges('travelled_fee') | currency:"INR":"symbol-narrow"}}</span>
							</div>
						</div> -->
						<div class="row">
							<div class="offset-7 col-1">
								<p>Less</p>
							</div>
							<div class="col-2 p-0">
								<span>GST <i>(on Cancellation charges)</i></span>
							</div>
							<div class="col text-right">
								<span>{{getRefunded('gst') | currency:"INR":"symbol-narrow"}}</span>
							</div>
						</div>
						<div class="row">
							<div class="offset-7 col-1">
								<p></p>
							</div>
							<div class="col-2 p-0">
								<span>Refunded Amount</span>
							</div>
							<div class="col text-right">
								<span>₹{{getRefunded('refunded') |  number : '1.0-0'}}</span>
							</div>
						</div>
					</ng-container>
				</div>	
			</div>
		<div class="actionbtn">
			<a href="javascript:void(0)" *ngIf="isTicketed">CREDIT NOTE PRINT</a>
			<!-- <a href="javascript:void(0)">CANCEL</a>
			<a href="javascript:void(0)">ISSUE</a>
			<a href="javascript:void(0)" routerLink="/traveller">MODIFY</a> -->
			<a href="javascript:void(0)" *ngIf="isTicketed" (click)="printTicket()">TICKET PRINT</a>
			<a href="javascript:void(0)" *ngIf="isTicketed" (click)="printInvoice()">INVOICE PRINT</a>
			<a href="javascript:void(0)" *ngIf="isTicketed" (click)="cancelReq(bookingArr)">CANCEL TICKET</a>
		</div>
	</div>
</section>