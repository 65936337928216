<section class="mat-yellow-bg summeryfilter searchBookingSummary">
    <div >
        <form action="" [formGroup]="searchForm" class="position-relative summeryfilter-search-form">
            <button  [ngClass]="isMinimized ? ' ' : 'rotateX180'" (click)="isMinimized = !isMinimized" title="Show/Hide Filter" class="min-max-btn btn-green btn-sm"><i class="fa fa-filter "></i></button>
                <div >                   
                    <h5 style="    font-size: 14px;" [ngClass]="isMinimized ? 'd-none' : 'd-block'" class="text-bold m-0 text-center text-black">Showing 100  recent results of your bookings </h5>
                    <div [ngClass]="isMinimized ? 'height-auto' : 'height-0'">
                        
                   
                <div class="row justify-content-center search-filter-responsive pt-3" >
                    

                    <div class="type-select pb-2 search-item-field  div-no-break">
                        <select formControlName="search_type" style="width: 100%;">
                            <option value="">Select Date Type</option>
                            <option class="option-style" value="bookingDate">Booking Date</option>
                            <option class="option-style" value="travelDate">Travel Date</option>
                        </select>
                    </div>
                    <div class="search-item-field  book-date  pb-2  div-no-break">
                        <div class="date date-picker">
                            <input bsDatepicker autocomplete="off" #fDate formControlName="fromDate" #dpYMD="bsDatepicker"
                                [bsConfig]="bsConfig" type="text" class="custom-input custom-input-filter m-input" placeholder=" " />
                            <span class="placeholder" (click)="focusOn('fromDate')">From</span>
                            <i class="fa fa-calendar customCalendar2 " (click)="dpYMD.toggle()"></i>
                        </div>
                    </div>

                    <div class="search-item-field travel-date  pb-2  div-no-break">
                        <div class="date date-picker">
                            <input bsDatepicker autocomplete="off" #tDate formControlName="toDate" #dpYMD2="bsDatepicker"
                                [bsConfig]="bsConfig" type="text" class="custom-input custom-input-filter m-input" placeholder=" " />
                            <span class="placeholder" (click)="focusOn('toDate')">To</span>
                            <i class="fa fa-calendar customCalendar2 " (click)="dpYMD2.toggle()"></i>
                        </div>
                    </div>

                    <div class="search-item-field book-refno  pb-2  div-no-break">
                        <input type="text" class="custom-input-filter " #ref_no formControlName="bookingRefNo"
                            placeholder=" " oninput="this.value = this.value.toUpperCase()" />
                        <span class="placeholder" (click)="focusOn('bookingRefNo')">Booking Ref.</span>
                        <i class="fa fa-hashtag" aria-hidden="true"></i>
                    </div>

                    <div class="search-item-field status-select  pb-2  div-no-break">
                        <select formControlName="status">
                            <option value="">Select Status</option>
                            <option class="option-style" value="TICKETED">Ticketed</option>
                            <option class="option-style" value="REQUESTED">Cancel Requested</option>
                            <option class="option-style" value="REFUNDED">Refunded</option>
                            <option class="option-style" value="HOLD">Hold</option>
                            <option class="option-style" value="FAILED">Failed</option>
                        </select>
                    </div>

                    <div class="search-item-field book-refno  pb-2  div-no-break">
                        <input type="text" class="custom-input-filter" #l_name formControlName="lastName" placeholder=" "
                            oninput="this.value = this.value.toUpperCase()" />
                        <span class="placeholder" (click)="focusOn('lastName')">Last name</span>
                        <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                    </div>

                    <div class="search-item-field book-refno  pb-2  div-no-break">
                        <input type="text" class="custom-input-filter" #pnr_no formControlName="pnr" placeholder=" "
                            oninput="this.value = this.value.toUpperCase()" />
                        <span class="placeholder" (click)="focusOn('pnr')">PNR</span>
                        <i class="fa fa-hashtag" aria-hidden="true"></i>
                    </div>

                    <div class="book-refno  pb-2  div-no-break">
                        <input type="button" class="flight-search-btn btn btn-sm w-100 btn-green shadow " (click)="submit(searchForm.value)" value="Search" />
                        <span class="flight-icon"></span>
                    </div>
                </div>
            </div>
            </div>
        </form>

    </div>
</section>
<div class="container-fluid mt-5" *ngIf="dataArr.length == 0" style="border-top: 1px solid #f60;">
    <div class="text-center mt-5">
        <img src="../../../assets/v3images/error-illustration.svg" alt="" width="300px" class="mt-3">
      <h4 class="text-pinkish-red">No Result Found</h4>
    </div>
</div>
<div class="clearfix"></div>

<div class="booking-table-container container-fluid" *ngIf="dataArr.length > 0">
    <div class="mobile-scroll-table custom-table">
        <table class="table  mt-3">
            <thead >
                <tr>
                    <th class=" text-left"><span  title="Booking Ref. No.">BR Number
                        <i class="fa fa-sort mt-1 ml-1" aria-hidden="true" (click)="refSort();"></i></span>
                    </th>
                    <th class="text-left">Traveller</th>
                    <th class="text-left" style="width: 120px;">Airline/Hotel</th>
                    <th  class="text-left">Route</th>
                    <th class="text-left">PNR</th>
                    <th class=" text-left"><span title="Travel Date">Travel Dt <i class="fa fa-sort mt-1 ml-1" aria-hidden="true" (click)="journeyDateSort();"></i></span></th>
                    <th class="text-left">Fare</th>
                    <th class="text-left">Fare Type</th>
                    <th class="text-left">Status</th>
                    <th class=" text-right"><span title="Booking Date">Booking Date <i class="fa fa-sort mt-1 ml-1" aria-hidden="true" (click)="bookDateSort();"></i></span></th>
                    <th class="text-right">Booked By</th>
                    <!-- <th class="text-right" style="max-width: 50px;">Action</th> -->
                </tr>
            </thead>
            <tbody class="custom-table-body" [ngStyle]="{height: innerHeight}">
                <tr class="custom-table-row"
                    *ngFor="let element of dataArr">

                <td class="text-left"><span><a class="book-ref-link"
                        (click)="modify(element)">{{element.json_doc.booking_ref}}</a> <i title="Copy Booking Ref. No." class="fa fa-copy text-grey  ml-2" (click)="copyMessage(element.json_doc.booking_ref,'Reference')"></i></span></td>
                        
                <ng-container *ngIf="element.typhe != 'hotel' else hotelTemp">
                        <td class="text-left">{{getName(element.json_doc.travellers)}}</td>
                        
                        <td class="text-left" style="width: 120px;">{{element.json_doc?.segments[0]?.booking_supplier  == '6E_CORPORATE' ? '6E' :
                            element.json_doc?.segments[0]?.booking_supplier  == 'AMADEUS_CORPORATE' ? 'AMADEUS' : 
                            element.json_doc?.segments[0]?.booking_supplier}}
                        </td>
                        <td class="text-left" >{{getOrigin(element.json_doc)}} - {{getDestination(element.json_doc)}}</td>

                        <td class="text-left" >
                            <p class="m-0">
                                <span>{{checkIfNull(element.json_doc.segments[0]?.pnr)}} </span><i title="Copy PNR" class="fa fa-copy text-grey ml-2" *ngIf="checkIfNull(element.json_doc.segments[0]?.pnr)" (click)="copyMessage(element.json_doc.segments[0]?.pnr,'PNR')"></i> 
                            </p>
                        </td>

                        <td class="text-left">{{element.json_doc.segments[0]?.legs[0]?.origin_timestamp | dateFormat }}</td>
                        <td class="text-left">&#8377; {{getTotal(element.json_doc.travellers,element.json_doc.pnr_status)}}</td>

                </ng-container>

                <ng-template #hotelTemp>
                        <td class="text-left ">{{element.guestName}}</td>
                        <td class="text-left text-uppercase" style="width: 120px;" >
                         <span class="ellipsis" title="{{element.hotel_name}}">
                            {{element.hotel_name}}
                         </span>
                        </td>

                         <td class="text-left text-uppercase">{{element.hotel_city}}</td>

                         <td class="text-left" >
                            <p class="m-0">
                                <span>{{checkIfNull(element.json_doc.segments[0]?.pnr)}} </span><i title="Copy PNR" class="fa fa-copy text-grey ml-2" *ngIf="checkIfNull(element.json_doc.segments[0]?.pnr)" (click)="copyMessage(element.json_doc.segments[0]?.pnr,'PNR')"></i> 
                            </p>
                        </td>

                        <td class="text-left">{{element.origin_timestamp | dateFormat }}</td>
                        <td class="text-left">&#8377; {{element.original_booking_total}}</td>


                </ng-template>
                    
                    <td class="text-left">{{element.json_doc.displayPriceType?element.json_doc.displayPriceType:element.json_doc.fare_type}}</td>
                    <td class="text-uppercase text-left" *ngIf="element && element.json_doc && element.json_doc.pnr_status">
                        <ng-container *ngIf="element.json_doc?.pnr_status != 'FAILED'">
                            {{(element.json_doc?.pnr_status)}}
                        </ng-container>
                        <ng-container *ngIf="element.json_doc?.pnr_status == 'FAILED'">
                            <ng-container *ngIf="element.json_doc.supplierInfo && element.json_doc.supplierInfo.groupFareInfo == undefined">
                                {{(element.json_doc?.pnr_status)}}
                            </ng-container>
                            <ng-container *ngIf="element.json_doc.supplierInfo && element.json_doc.supplierInfo.groupFareInfo">
                               IN-PROGRESS
                            </ng-container>
                        </ng-container>
                    </td>
                    <td class="text-right">{{element.json_doc.booked_timestamp | dateFormat }}</td>
                    <td class="text-right" *ngIf="element.json_doc.booked_by_user_name != undefined">{{element.json_doc?.booked_by_user_name}}</td>
                    <td class="text-right" *ngIf="element.json_doc.booked_by_user_name == undefined">{{element.json_doc?.affiliate_company_name}}</td>
                    <!-- <td class="text-right" style="max-width: 50px;"><span class="text-success"><a class="text-success" (click)="modify(element)"><i class="fa fa-eye"
                                    aria-hidden="true"></i> View</a></span>
                                </td> -->
                </tr>
            </tbody>
        </table>
    </div>
    <!-- <div class="row pagination-div mt-4 mb-5 d-flex justify-content-around">
        <pagination-controls (pageChange)="p = $event" *ngIf="dataArr != ''"></pagination-controls>
    </div> -->
    <div class="clear-fix"></div>
</div>
<button type="button" #openModal hidden class="btn btn-info btn-lg d-none" data-toggle="modal"
    data-target="#myModal"></button>

<div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="warn-icon"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></div>
            <!-- <div class="warning-con">
                The cancellation request service is only available on Mon-Sat between <strong>08:00 AM</strong> to
                <strong>08:00 PM</strong> and before <strong>4 hrs</strong> from journey date.
            </div> -->
            <div class="warning-con" [innerHtml]="cancellationWarningMessage"></div>
            <div class="modal-footer float-center">
                <button type="button" class="btn btn-red btn-close" data-dismiss="modal">Close</button>
            </div>
        </div>

    </div>
</div>

<div id="snackbar">{{copyFor}} copied..</div>