<div class="container-fluid" *ngIf="valid">
    <h3 class="text-uppercase text-center mt-3">Credit Note</h3>

    <div class="header">
        <div class="row book-details ml-0 mr-0 clearfix">


            <div class="col-4 float-left">
                <span class="label">Booking ID :&nbsp;</span><span class="detail">&nbsp;{{bookingArr.json_doc?.booking_ref}}</span>
            </div>

            <div class="col-4 text-center">
                <span class="label ml-2">CRN No. :&nbsp;</span><span class="detail">&nbsp;IZ19-{{bookingArr.json_doc?.invoice_no}}</span>
            </div>

            <div class="col-4 float-left text-right">
                <span class="label">CRN Date :&nbsp;</span><span class="detail mr-2">&nbsp;{{bookingArr.json_doc?.cancelled_timestamp | date : 'dd/MM/yyyy'}}</span>
            </div>
            <div class="clearfix"></div>
        </div>

        <div class="row mt-2 inv-details">
            <div class="col-6 float-left">
                <div class="left-detail">
                    <div class="name font-weight-bold">Aadesh Travels Pvt. Ltd.</div>
                    <div class="address1">Destination Plus, 3rd Floor</div>
                    <div class="address2">Amin Marg, Rajkot</div>
                    <div class="state-country">Gujarat, India</div>
                    <div class="phone"><span class="label-heads">Phone Number : </span><span class="label-info">+91-0281-2411111</span> </div>
                    <div class="email"><span class="label-heads">Email : </span><span class="label-info">gopal@aadeshtravels.com</span></div>
                    <div class="pannum"><span class="label-heads">PAN Number : </span><span class="label-info">AAGCA5682D</span></div>
                    <div class="gstin"><span class="label-heads">GSTIN : </span><span class="label-info">24AAGCA5682D1ZU (Gujrat)</span></div>
                </div>
            </div>
            <div class="col-6 float-right">
                <div class="right-detail">
                    <div class="name font-weight-bold">{{bookingArr.json_doc?.contactdetails?.affiliateAddress?.companyName}}</div>
                    <div class="address1">{{bookingArr.json_doc?.contactdetails?.affiliateAddress?.address1}}, {{bookingArr.json_doc?.contactdetails?.affiliateAddress?.address2}}</div>
                    <div class="address2">{{bookingArr.json_doc?.contactdetails?.affiliateAddress?.city}} - {{bookingArr.json_doc?.contactdetails?.affiliateAddress?.zip}}</div>
                    <div class="state-country">{{bookingArr.json_doc?.contactdetails?.affiliateAddress?.stateCode}}, {{bookingArr.json_doc?.contactdetails?.affiliateAddress?.countryCode}}</div>
                    <div class="phone"><span class="label-heads">Phone Number : </span><span class="label-info">{{bookingArr.json_doc?.contactdetails?.affiliateAddress?.phone}}</span> </div>
                    <!-- <div class="mobile"><span class="label-heads">Mobile Number : </span><span class="label-info">NA</span> </div>-->
                    <div class="email"><span class="label-heads">Email : </span><span class="label-info">{{bookingArr.json_doc?.contactdetails?.affiliateAddress?.email}}</span></div>
                    <div class="gstin"><span class="label-heads">GSTIN : </span>
                        <span class="label-info" *ngIf="(bookingArr.json_doc.gstDetail &&  bookingArr.json_doc.gstDetail.gstNumber != '' && bookingArr.json_doc.gstDetail.gstNumber != null) else defaultGst">{{bookingArr.json_doc?.gstDetail?.gstNumber}}</span>
                        <ng-template #defaultGst></ng-template>
                        <span class="label-info user_data">{{userData?.gst_number}}</span>
                    </div>
                    <div class="Place of Supply"><span class="label-heads">Place of Supply : </span><span class="label-info">Gujarat - (24)</span></div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>
<!-- container -->

<div class="container-fluid" *ngIf="valid">
    <div class="booking-details mt-2">
        <table class="table table-striped table-bordered text-center">
            <thead class="extended-table-head">
                <tr>
                    <th>S.No.</th>
                    <th>Ticket No.</th>
                    <th>Sectors</th>
                    <th>Pax Name</th>
                    <th>Type</th>
                    <th>Class</th>
                    <th>Base Fare</th>
                    <th>YQ</th>
                    <th>YR</th>
                    <th>K3</th>
                    <th>OC</th>
                    <th>OT</th>
                    <th>Bg Chg</th>
                    <th>Meal</th>
                    <th>Seat</th>
                    <th>Srv. Chg</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody class="extended-table-body" *ngFor="let detail of  bookingArr.json_doc['travellers']; let i =index;">
                <tr>
                    <td>{{i+1}}</td>
                    <td>{{detail?.ticket?.number}}</td>
                    <td>{{detail.segment[0][0]?.sector}}</td>
                    <td>{{detail?.title}} {{detail?.first_name}} {{detail?.last_name}} </td>
                    <td><span *ngIf="detail.type == 'ADT'">ADULT</span><span *ngIf="detail.type == 'CHD'">CHILD</span><span *ngIf="detail.type == 'INF'">INFANT</span></td>
                    <td>{{bookingArr.json_doc?.segments[0]?.legs[0]?.cabin_class}}</td>
                    <td>&#8377;{{detail.fare?.base}}</td>
                    <td>&#8377;{{detail.fare.taxes[0]?.YQ ? detail.fare.taxes[0]?.YQ : 0}}</td>
                    <td>&#8377;{{detail.fare.taxes[0]?.YR ? detail.fare.taxes[0]?.YR : 0}}</td>
                    <td>&#8377;{{detail.fare.taxes[0]?.K3 ? detail.fare.taxes[0]?.K3 : 0}}</td>
                    <td>&#8377;{{detail.fare.taxes[0]?.OC ? detail.fare.taxes[0]?.OC : 0}}</td>
                    <td>&#8377;{{detail.fare.taxes[0]?.OT ? detail.fare.taxes[0]?.OT : 0}}</td>
                    <td>&#8377;{{getssr(item?.ssr,'baggages')}}</td>
                    <td>&#8377;{{getssr(item?.ssr,'meals')}}</td>
                    <td>&#8377;{{getssr(item?.ssr,'seats')}}</td>
                    <td>&#8377;{{detail.fare?.taxes[0]?.ADMIN_SERVICE_CHARGE}}</td>
                    <td>&#8377;{{getPerPrice(detail,i)}}</td>
                </tr>

            </tbody>
        </table>

        <table class="table table-striped table-md table-bordered custom-table text-center">

            <tbody>
                <tr>
                    <td class="hilight">Less</td>
                    <td>Net Commission</td>
                    <td>{{netCommission | currency:"INR":"symbol-narrow"}}</td>
                </tr>
                <tr>
                    <td class="hilight">Less</td>
                    <td>TDS</td>
                    <td>{{tds | currency:"INR":"symbol-narrow"}}</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>Net Payable</td>
                    <td>&#8377;{{netPayable | number : '1.0-0'}}</td>
                </tr>

                <tr>
                    <td class="hilight">Less</td>
                    <td>Airline cancellation charge</td>
                    <td>{{getCancellationCharges('client_xxl_charges') |currency:"INR":"symbol-narrow"}}</td>
                </tr>

                <tr>
                    <td class="hilight">Less</td>
                    <td>Cancellation charge</td>
                    <td>{{getCancellationCharges('client_raf_charges') |currency:"INR":"symbol-narrow"}}</td>
                </tr>

                <tr>
                    <td class="hilight">Less</td>
                    <td>Travelled Fare</td>
                    <td>{{getCancellationCharges('travelled_fee') |currency:"INR":"symbol-narrow"}}</td>
                </tr>

                <tr>
                    <td class="hilight">Less</td>
                    <td>GST (on Cancellation charges)</td>
                    <td>{{getRefunded('gst') |currency:"INR":"symbol-narrow"}}</td>
                </tr>

                <tr class="grand">
                    <td>&nbsp;</td>
                    <td>Refunded Amount</td>
                    <td>&#8377; {{getRefunded('refunded') | number:'1.0-0'}}</td>
                </tr>

            </tbody>
        </table>

        <div class="clearfix"></div>

        <div class="actionbtn mt-3 mb-4">
            <a href="javascript:void(0)" class="btnPrint" (click)="print()">Print</a>
        </div>

    </div>
</div>