<div class="card-tab travel-insurance" *ngIf="insurancePackageData">
  <mat-expansion-panel class="relative m-0" [expanded]="stepIndex == componentIndex" (opened)="callStepButton(componentIndex)">
      <mat-expansion-panel-header (click)='toggleTnC()'>
          <mat-panel-title class="header-title">
              <div>
                  <h4>
                      Add Travel Insurance and Secure your Trip for ₹{{insurancePackageData.amount_per_person}}/Person  
                      </h4>
                      <small *ngIf="isTnC">
                          (Upon Selecting Travel Insurance ,You Accept The Terms And Conditions Of The Travel Insurance Policy-  Click here to see <a href="{{insurancePackageData.brochure_pdf_url}}" target="_blank" class="">Policy Coverage.</a>)
                         </small> 
              </div> 
          </mat-panel-title>
          <a class="editbtn text-leaf-green mt-2" >
              <img src="assets/v3images/digit.svg" alt="" srcset="" width="45px">
          </a>
      </mat-expansion-panel-header>
      <div class="mb-2">
          <div class="row m-0 justify-content-center">
              <ng-container *ngFor="let cover of insurancePackageData.covers let i =index;">
                  <ng-container *ngIf="i <= insuranceCoverViewCount">
                      <div class="bdr-card card  position-relative" >
                          <div class="insurance-img">
                            <img src="assets/v3images/icons/insurance.svg"  />
                          </div>
                         <div>
                            <h5>{{cover.name}}</h5>
                            <small class="insured-amt">Sum Insured: ₹ {{cover.sum_insured_in_inr}} </small>
                         </div>
                      </div>                                            
                  </ng-container>
              </ng-container>
              <div *ngIf="insurancePackageData.covers && insurancePackageData.covers.length > 2" (click)="toggleInsuranceCoverListView()"  class="bdr-card cursor-pointer card d-flex align-items-center justify-content-center show-more-less" style="    background: #f2efff;">
                  <small >Show <span *ngIf="!isInsuranceCoverExpanded">more</span><span *ngIf="isInsuranceCoverExpanded">less</span> 
                      <i *ngIf="!isInsuranceCoverExpanded" class="fa fa-angle-down ml-2"></i>
                      <i *ngIf="isInsuranceCoverExpanded" class="fa fa-angle-down ml-2"></i>  
                  </small>
              </div>
          </div>
          <div class="row m-0 mt-3">
              <div class="col-6 p-0">
                  <p (click)="toggleInsuranceSelection(true)" class="cursor-pointer mb-0"><img src="assets/v3images/icons/success-icon.png" alt="" srcset="" width="20px" [ngClass]="isInsuranceSelected ? '' : 'grayscale-filter'"> <span class="ml-2 text-black">Yes, I want to secure my trip with insurance.</span></p>
                  <small class=" pl-2 pr-2 pt-1 pb-1 ml-4" style="background: #E1FFF1;color: #05A357;font-size: 10px;">{{insurancePackageData.insurance_affirmative_message}}
                  </small>
              </div>
              <div class="col-6 p-0">
                  <p (click)="toggleInsuranceSelection(false)" class="cursor-pointer mb-0" ><img src="assets/v3images/icons/success-icon.png" alt="" srcset="" width="20px" [ngClass]="isInsuranceSelected ? 'grayscale-filter' : ''"> <span class="ml-2 text-black">No, I don't want to insure my trip.</span></p>
              </div>
          </div>
          

          <div *ngIf="isInsuranceSelected" class="traveller-insurance-date-container custom-table">
             <div [formGroup]="insuranceTravellerForm" >
                 <div formArrayName="insuranceTravellerList">
                     <table class="table">
                        <thead>
                                 <tr>
                                     <th>Traveller's Name</th>
                                     <th class="text-right">Traveller's DOB</th>
                                 </tr>
                        </thead>
                        <tbody >
                            <tr *ngFor="let traveller of travellersControls.controls; let i = index;">
                                <ng-container [formGroupName]="i">
                                <td><img src="assets/v3images/icons/green-tick.svg" alt="" width="15px" class="mr-1"> {{traveller.value.firstName}} {{traveller.value.lastName}} {{traveller.value.type && traveller.value.type.length>0? traveller.value.type.toUpperCase()=='ADULT'?'(ADT)':traveller.value.type.toUpperCase()=='CHILD'?'(CHD)':'(INF)':''}}</td>
                                <td class="text-right">
                                    <mat-form-field class="mb-0 pb-0">
                                        <input [max]="getCurrentDateTime()" min="1920-01-02" matInput [matDatepicker]="picker2" class="departure"
                                        (focus)="picker2.open()" placeholder="DOB"
                                        formControlName="dob" autocomplete="NoAutocomplete" (dateInput)="dobSelectionChange('input', $event,traveller)" (dateChange)="dobSelectionChange('change', $event,traveller)" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker2">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                    <mat-error class="matError text-danger"
                                        *ngIf="(!traveller['controls'].dob.valid && traveller['controls'].dob.touched) || (traveller['controls'].dob.errors != null)">
                                        {{traveller['controls'].dob.errors && traveller['controls'].dob.errors.length>0?traveller['controls'].dob.errors:"" }} </mat-error>
                                    </mat-form-field>
                                </td>
                                </ng-container>
                            </tr>
                        </tbody>
                     </table>
                    <!-- <ng-container *ngFor="let traveller of travellersControls.controls; let i = index;">
                        <ng-container [formGroupName]="i">
                            <span>
                                {{traveller.value.firstName}} {{traveller.value.lastName}} {{traveller.value.type}}
                            </span>
                            <div class="col-3">
                                <mat-form-field class="w-100">
                                    <input matInput [matDatepicker]="picker2" class="departure"
                                        (focus)="picker2.open()" placeholder="DOB"
                                        formControlName="dob" autocomplete="NoAutocomplete" (dateInput)="dobSelectionChange('input', $event,traveller)" (dateChange)="dobSelectionChange('change', $event,traveller)" >
                                    <mat-datepicker-toggle matSuffix [for]="picker2">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                    <mat-error class="matError"
                                        *ngIf="(!traveller['controls'].dob.valid && traveller['controls'].dob.touched) || (traveller['controls'].dob.errors != null)">
                                        {{traveller['controls'].dob.errors && traveller['controls'].dob.errors.length>0?traveller['controls'].dob.errors:"" }} </mat-error>
                                </mat-form-field>
                            </div>
                        </ng-container>
                    </ng-container> -->
                 </div>
            </div> 
          
          </div>
          <div class=" text-right mt-2">
            <button class="btn btn-green text-center pl-3 pr-3 btn-large" id="next" [ngClass] = "(disabledBtn && isInsuranceSelected)?'disableBtn':''"(click)="callStepButton(componentIndex+1)">NEXT</button>
        </div>
      </div>
  </mat-expansion-panel>
</div>