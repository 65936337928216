import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BookingService } from 'src/app/services/booking/booking.service';
import { AffiliateService } from 'src/app/services/affiliate.service';

@Component({
  selector: 'app-credit-note-reciept',
  templateUrl: './credit-note-reciept.component.html',
  styleUrls: ['./credit-note-reciept.component.scss']
})
export class CreditNoteRecieptComponent implements OnInit {
  userData: any;
  searchId: any;
  bookingArr: any= [];
  valid: boolean = false;
  userDetail: any;
  GSTonCancellation: number;
  totalFare: number;
  netPayable: number;
  gstAmount: number;
  tdsAmount: number;
  netCommission = 0;
	tds = 0;
  constructor(private router: Router, private route: ActivatedRoute,
		private bookingService: BookingService, private affiliateService: AffiliateService) { }

  ngOnInit() {
    this.gstAmount = 0
		this.tdsAmount = 0
		this.GSTonCancellation = 0
    this.valid = false
  	let searchId = this.route.snapshot.params['searchId'];
		this.userData = JSON.parse(localStorage.getItem('user_details'));
		this.searchId = searchId;
		this.bookingService.getBookingConfirmDetails(searchId,'credit_note', null).subscribe(res => {
			let data = JSON.parse(res["_body"])['data']
			let affiliateData = JSON.parse(res["_body"])['affiliateData']
			if (data != undefined && data.length > 0) {
				this.bookingArr = data[0];
				console.log('Booking', this.bookingArr)
				this.valid = true;
				this.getPrice(this.bookingArr.json_doc.travellers, '');
				this.getTotal(this.bookingArr.json_doc.travellers, '');
				if (affiliateData && affiliateData.xl_account_code != undefined) {
					this.userData = affiliateData
				}
			}
			else {
				this.valid = false
			}
		})
		// this.getLoginAffiliateDetails();
  }
  
  getCancellationCharges(type) {
		let data = this.bookingArr.json_doc.travellers
		let charges = 0
		let travelledFee :number = 0
		
		for (let i = 0; i < data.length; i++) {
			const element = data[i];
			
			if (element.status == 'CANCELLED' && element.refunded_fare!=undefined) {
				if (element.refunded_fare.cancellation_charges != null && type != 'travelled_fee') {
					let chargeArr = Object.keys(element.refunded_fare.cancellation_charges[0])
					for (let j = 0; j < chargeArr.length; j++) {
						const el = chargeArr[j];
						if (type == el) {
							if (type == 'client_raf_charges') {
								charges += element.refunded_fare.cancellation_charges[0][type]
								this.GSTonCancellation = (charges*18/100)
							}
							else{
								charges += element.refunded_fare.cancellation_charges[0][type]
							}
						}
					}
				}
				if (element.utilized_fare != undefined && element.utilized_fare != null && type == 'travelled_fee') {
					travelledFee = this.getTravelledFee(element.utilized_fare)
					travelledFee += Number(element.utilized_fare.base)
					return travelledFee

				}
			}
		}
		if (type != 'travelled_fee') {
			return charges
		}
  }
  getTravelledFee(fare) {
		let totalFee = 0
		let keys = Object.keys(fare.taxes[0]);
		let count = 0;
		const lThis = this;
		Object.values(fare.taxes[0]).forEach(function (v) {
			if (keys[count] != "MARKUP" && keys[count] != "COMMISSION" && keys[count] != "TOTAL" && keys[count] != "TDS" && keys[count] != "NET_PAYABLE" &&
				keys[count] != "ADMIN_MARKUP" && keys[count] != "OTHER_TAX" && keys[count] != "AFFILIATE_MARKUP" && keys[count] != "ADMIN_SERVICE_CHARGE" && keys[count] != "GST") {
				totalFee += Number(v);
			}
			if (keys[count] == "OTHER_TAX" || keys[count] == "AFFILIATE_MARKUP" || keys[count] == "ADMIN_SERVICE_CHARGE") {
				totalFee += Number(v);
			}
			count++;
		})
		return totalFee
  }
  getRefunded(type) {
		let data = this.bookingArr.json_doc.travellers
		let charges = 0
		for (let i = 0; i < data.length; i++) {
			const element = data[i];
			if (type == 'refunded' && element.refunded_fare != undefined) {
				charges += element.refunded_fare.refunded_total
				try {
					charges -= element.refunded_fare.gst
	
					if(element.refunded_fare.hasOwnProperty('cancellation_charges')) {
						for (let j = 0; j < element.refunded_fare['cancellation_charges'].length; j++) {
							charges -= Number(element.refunded_fare['cancellation_charges'][j]['client_raf_charges']) 
							charges -= Number(element.refunded_fare['cancellation_charges'][j]['client_xxl_charges']) 
						}
					}
					
				} catch (error) {
					
				}
			}
			else if (type == 'gst' && element.refunded_fare != undefined) {
				if (element.refunded_fare.gst != undefined) {
					charges += element.refunded_fare.gst
				}
			}
		}
		if (type == 'refunded') {
			return charges 
		}
		return charges
  }
  getPerPrice(data, index) {
		this.totalFare = 0
		const element = data;
		const lThis = this;
		let keys = Object.keys(element.fare.taxes[0]);
		let count = 0;
		Object.values(element.fare.taxes[0]).forEach(function (v) {
			if (keys[count] != "MARKUP" && keys[count] != "COMMISSION" && keys[count] != "TOTAL" && keys[count] != "TDS" && keys[count] != "NET_PAYABLE" &&
				keys[count] != "ADMIN_MARKUP" && keys[count] != "OTHER_TAX" && keys[count] != "AFFILIATE_MARKUP" && keys[count] != "GST") {
				lThis.totalFare += Number(v);
			}
			count++;
		})
		this.totalFare += Number(element.fare.base) + this.getSSRPrice(element.ssr);
		return this.totalFare;
  }
  getSSRPrice(ssr) {
		let meal = 0;
		let baggage = 0;
		let seat = 0;
		if (ssr != null) {
			for (let i = 0; i < ssr.length; i++) {
				const element = ssr[i];
				if (element.meals != null && element.meals.code != null) {
					meal += Number(this.checkifNull(element.meals.code.totalFare));
				}
				if (element.baggages != null && element.baggages.code != null) {
					baggage += Number(this.checkifNull(element.baggages.code.totalFare));
				}
				if (element.seats != null && element.seats.code != null) {
					seat += Number(this.checkifNull(element.seats.code.totalFare));
				}
			}
		}
		return Number(meal) + Number(baggage) + Number(seat)
  }

  getssr(data, type) {
		if (data != null) {
			if (data[0][type] != null) {
				if (data[0][type].code != null && data[0][type].code != undefined) {
					return data[0][type].code.totalFare
				}
				else{
					return 0
				}
			}
			else {
				return 0
			}
		}
		else {
			return 0
		}
  }
  getTotal(data, type) {
		this.netCommission = 0;
		this.tds = 0;
		for (let i = 0; i < data.length; i++) {
			const element = data[i];
			if (element.status == 'CANCELLED' || element.status == undefined) {
				const lThis = this;
				let keys = Object.keys(element.fare.taxes[0]);
				let count = 0;
				Object.values(element.fare.taxes[0]).forEach(function (v) {
					if (keys[count] == "COMMISSION") {
						lThis.totalFare -= Number(v);
						lThis.netCommission += Number(v);
					}
					if (keys[count] == "TDS") {
						lThis.tds += Number(v);
					}
					count++;
				})
				// this.totalFare += Number(element.fare.base) + this.getSSRPrice(element.ssr);
				this.netCommission = lThis.netCommission;
				this.tds = lThis.tds
			}
		}
		// this.totalFare = Math.round(this.totalFare)
		// return this.totalFare
	}

  getPrice(data,type) { //FIX ME [ Calculate Comission, TDS and markup implementation ]
		this.totalFare = 0

		let charge = 0
		let admin_markup = 0
		let admin_service_charge = 0
		let comission = 0
		let tds = 0
		let gst = 0
		for (let i = 0; i < data.length; i++) {
			const element = data[i];
			const lThis = this;
			let keys = Object.keys(element.fare.taxes[0]);
			let count = 0;
			Object.values(element.fare.taxes[0]).forEach(function (v) {
				if (keys[count] != "MARKUP" && keys[count] != "COMMISSION" && keys[count] != "TOTAL" && keys[count] != "TDS" && keys[count] != "NET_PAYABLE" &&
					keys[count] != "AFFILIATE_MARKUP" && keys[count] != "GST") {
					lThis.totalFare += Number(v);
				}
				if (keys[count] == type) {
					charge += Number(v);
				}
				if (keys[count] == "COMMISSION") {
					comission += Number(v);
				}
				if (keys[count] == "TDS") {
					tds += Number(v);
        }
        if (keys[count] == "GST") {
					gst += Number(v);
				}
				count++;
			})
			this.totalFare += Number(element.fare.base) + this.getSSRPrice(element.ssr);
		}
		// this.totalFare
		this.netPayable = this.totalFare
		
			this.gstAmount = gst
			this.tdsAmount = tds
		this.netPayable = this.totalFare + this.gstAmount
    this.netPayable = Number(this.totalFare + this.gstAmount + this.tdsAmount) - comission;
		return	charge
			
  }
  
  print() {
		window.print()
	}

  checkifNull(value){
		if (value == undefined) {
			return 0
		}
		else{
			return value
		}
	}
	getLoginAffiliateDetails() {
		this.affiliateService.getAffiliateDetails().subscribe(res => {
			this.userDetail = res[0];
		})
	}
}
