import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MultiCityLccService {

    currentPriceType;
    constructor() { }
  
    makeFlight() {
      const selectedFlights = JSON.parse(localStorage.getItem('selectedLccMultiCityFLight'));
      this.currentPriceType = selectedFlights[0].priceType;
      
      let totalFare = 0;
      const selectedFares = [];
      for(const item of selectedFlights) {
        selectedFares.push(item.fare[0]);
        totalFare += Number(item.fare[0].total ? item.fare[0].total : 0);
      }
      
      const outBoundData = selectedFlights[0].fare;
      
      const segement = this.makeSegment(selectedFlights)
      const sKeys = this.makeSkeys(selectedFlights);
      const commonFares = this.makeFare(selectedFares)

      for(let item of selectedFlights) {
        if(item.supplierInfo.hasOwnProperty('ValueBundledServiceIds')){	
              let outboundBundleKey = item.supplierInfo.ValueBundledServiceIds ? Object.keys(item.supplierInfo.ValueBundledServiceIds)[0] : null
              selectedFlights[0].supplierInfo.ValueBundledServiceIds = {
              [outboundBundleKey] : outboundBundleKey ? item.supplierInfo.ValueBundledServiceIds[outboundBundleKey] : null,
            }
        }
        if(item.supplierInfo.hasOwnProperty('ExtraBundledServiceIds')){	
            let outboundBundleKey = item.supplierInfo.ExtraBundledServiceIds ? Object.keys(item.supplierInfo.ExtraBundledServiceIds)[0] : null
            selectedFlights[0].supplierInfo.ExtraBundledServiceIds = {
            [outboundBundleKey] : outboundBundleKey ? item.supplierInfo.ExtraBundledServiceIds[outboundBundleKey] : null,
          }
        }
      }
      
      selectedFlights[0]['supplierInfo']['sKeys'] = sKeys;
  
      let durationInMinutes = 0;
      for(let i=0; i < selectedFlights.length; i++){
        durationInMinutes += Number(selectedFlights[i].durationInMinutes);
      }
  
      const supplierCode = outBoundData[0].supplierInfo && outBoundData[0].supplierInfo['SUPPLIER'] ? outBoundData[0].supplierInfo['SUPPLIER'] : outBoundData[0].supplierCode;
      
      const combinedData = {
        "uuid": null,
        "airline": selectedFlights[0].airline,
        "serviceClass": selectedFlights[0].serviceClass,
        "segments": segement,
        "baggageRules": selectedFlights[0].baggageRules,
        "passportRequired": selectedFlights[0].passportRequired,
        "handBaggageOnly": selectedFlights[0].handBaggageOnly,
        "fares": commonFares, 
        "selectedFare": totalFare,
        "supplierInfo" : selectedFlights[0].supplierInfo,
        "supplierCode": supplierCode,
        "durationInMinutes": durationInMinutes,
        "multiAirline": false, 
        "direction": "MULTICITY",
        "fare": commonFares,
        "time": selectedFlights[0].time,
        "index": selectedFlights[0].index,
        "selFlightPrice": totalFare,
        "priceType": this.currentPriceType 
      }
      return [combinedData];
    }
  
    makeSegment(selectedFlights) {
      let segement = []

      for(let i = 0; i < selectedFlights.length; i++){
        let outBoundFare = selectedFlights[i].fare;
        
        selectedFlights[i].segments.forEach(element => {
          if(!element['supplierInfo']){
            element['supplierInfo'] = {}
          }
          element['supplierInfo']["ufid"] = outBoundFare[0].supplierInfo && outBoundFare[0].supplierInfo.ufid ? outBoundFare[0].supplierInfo.ufid : null
          element['supplierInfo']['priceType'] =  outBoundFare[0] && outBoundFare[0].displayPriceType ? outBoundFare[0].displayPriceType : outBoundFare[0].priceType
          segement.push(element)
        });
      }
      
      return segement
    }

    makeFare(selectedFares) {
      let priceObject = {
          "base": 0,
          "total": 0,
          "netCommission": 0,
          "tds": 0,
          "adminMarkup": 0,
          "affiliateMarkup": 0,
          "affServiceFee": 0,
          "affAddOnMarkup": 0,
          "otherTax": 0,
          "comment": null,
          "priceType": this.currentPriceType,
      }

      let fares = []
      for(let item of selectedFares){
          priceObject.base += Number(item.base ? item.base : 0),
          priceObject.total += Number(item.total ? item.total : 0),
          priceObject.netCommission += Number(item.netCommission ? item.netCommission : 0),
          priceObject.tds += Number(item.tds ? item.tds : 0),
          priceObject.adminMarkup += Number(item.adminMarkup ? item.adminMarkup : 0),
          priceObject.affiliateMarkup += Number(item.affiliateMarkup ? item.affiliateMarkup : 0),
          priceObject.affServiceFee += Number(item.affServiceFee ? item.affServiceFee : 0),
          priceObject.affAddOnMarkup += Number(item.affAddOnMarkup ? item.affAddOnMarkup : 0),
          priceObject.otherTax += Number(item.otherTax ? item.otherTax : 0)
      }

      priceObject['fareComponents'] = this.getFareComponent(selectedFares);
      priceObject['travellerFares'] = this.getTravellerFare(selectedFares);
      priceObject['supplierInfo'] = this.makeSupplierInfo(selectedFares);
   
      fares.push(priceObject);
      return fares;
    }

    getFareComponent(selectedFares) {
      let fareComp = {};

      for (let i = 0; i < selectedFares.length; i++) {
          const component = selectedFares[i];
          const keys = Object.keys(component.fareComponents);
          for (let j = 0; j < keys.length; j++) {
              const key = keys[j];
              const value = Number(component.fareComponents[key]);

              if (!fareComp.hasOwnProperty(key)) {
                  fareComp[key] = value;
              } else {
                  fareComp[key] += value;
              }
          }
      }

      return fareComp;
    }
  
    getTravellerFare(selectedFares) {
      let travellerFares = {}
      let passengerType = ['ADT', 'CHD', 'INF']

      for (let i = 0; i < passengerType.length; i++) {
          const type = passengerType[i];
          if(selectedFares[0].travellerFares.hasOwnProperty(type)){
            travellerFares[type] = this.getTravellerFareByType(selectedFares, type)
          }
      }
      
      return travellerFares
    }
  
    getTravellerFareByType(selectedFares, travellerType){
      const obj = {
        "travellerType": travellerType,
        "base": 0,
        "total": 0
      }

      for (let i = 0; i < selectedFares.length; i++) {
          const fare = selectedFares[i];
          if (fare.travellerFares.hasOwnProperty(travellerType)) {
              obj['base'] += fare.travellerFares[travellerType].base;
              obj['total'] += fare.travellerFares[travellerType].total;
          }
      }
      
      obj['fareComponents'] = this.getFareComponentTravellerType(selectedFares, travellerType)
      obj["supplierInfo"] = this.getSupplierInfoTrvWise(selectedFares, travellerType)
      return obj
    }


    getFareComponentTravellerType(selectedFares, travellerType) { 
      let fareComp = {};
      for (let i = 0; i < selectedFares.length; i++) {
          const component = selectedFares[i].travellerFares[travellerType];
          
          if(component) {
            const keys = Object.keys(component.fareComponents);
            for (let j = 0; j < keys.length; j++) {
                const key = keys[j];
                const value = component.fareComponents[key];
  
                if (!fareComp.hasOwnProperty(key)) {
                    fareComp[key] = value;
                } else {
                    fareComp[key] += value;
                }
            }
          }
      }
      return fareComp;

    } 

    getSupplierInfoTrvWise(selectedFares, travellerType) {
      let iataCommission = 0;
      let plbCommission = 0;
      let cashbackCommission = 0;
      let outboundK3 = 0;
      let outboundIGST = 0;

      for(let item of selectedFares) {
        const supplierInfo = item.travellerFares[travellerType].supplierInfo;
        const travellerFareComp = item.travellerFares[travellerType].fareComponents;
        if (supplierInfo != null && supplierInfo.hasOwnProperty("IATA_COMMISSION")) {
            iataCommission +=  Number(supplierInfo['IATA_COMMISSION']);
        }
        if(supplierInfo != null && supplierInfo.hasOwnProperty("PLB_COMMISSION")){
            plbCommission += Number(supplierInfo['PLB_COMMISSION']);
        }
        if(supplierInfo != null && supplierInfo.hasOwnProperty("CASHBACK_COMMISSION")){
            cashbackCommission += Number(supplierInfo['CASHBACK_COMMISSION']);
        }

        if(supplierInfo != null && supplierInfo.hasOwnProperty("IGST")) {
           outboundIGST = Number(supplierInfo['IGST']);
        }

        if(travellerFareComp.hasOwnProperty("K3")) {
           outboundK3 += Number(travellerFareComp['K3']);
        }
      }

      const outboundSupp = selectedFares[0].travellerFares[travellerType].supplierInfo;

      let commonSupplierInfo = {
        "IATA_COMMISSION_TYPE": outboundSupp['IATA_COMMISSION_TYPE'],
        "IATA_COMMISSION_VALUE": outboundSupp['IATA_COMMISSION_VALUE'],
        "IATA_COMMISSION": Number(iataCommission ? iataCommission : 0),

        "PLB_COMMISSION_TYPE": outboundSupp['PLB_COMMISSION_TYPE'],
        "PLB_COMMISSION_VALUE": outboundSupp['PLB_COMMISSION_VALUE'],
        "PLB_COMMISSION": Number(plbCommission ? plbCommission : 0),
        
        "CASHBACK_COMMISSION_TYPE": outboundSupp['CASHBACK_COMMISSION_TYPE'],
        "CASHBACK_COMMISSION_VALUE": outboundSupp['CASHBACK_COMMISSION_VALUE'],
        "CASHBACK_COMMISSION": Number(cashbackCommission ? cashbackCommission : 0),

        "OUTBOUND_IGST" : Number(outboundIGST ? outboundIGST : 0),
				"OUTBOUND_K3" : Number(outboundK3 ? outboundK3 : 0),
			}
  
      return commonSupplierInfo;
    }
  
    makeSupplierInfo(selectedFares){
      let supplierObj = {};
      let supplierPayable = 0;
      let igstAmount = 0;

      for (let currentFare of selectedFares) {
          let supplierKeys = Object.keys(currentFare.supplierInfo);
          
          for (let j = 0; j < supplierKeys.length; j++) {
              const key = supplierKeys[j];
              supplierObj[key] = currentFare.supplierInfo[key];

              if(key === "supplierPayble") {
                  supplierPayable += Number(currentFare.supplierInfo[key]);
              }

              if(key === "IGST"){
                  igstAmount += Number(currentFare.supplierInfo[key] ? currentFare.supplierInfo[key] : 0);
              }
          }
      }

      if (supplierObj.hasOwnProperty('supplierPayble')) {
          try {
              supplierObj['supplierPayble'] = supplierPayable.toFixed(2);
          } catch (error) {
              supplierObj['supplierPayble'] = supplierPayable;
          }
      }

      if(supplierObj.hasOwnProperty('IGST')) {
        try {
            supplierObj['IGST'] = igstAmount.toFixed(2);
        } catch (error) {
            supplierObj['IGST'] = igstAmount;
        }
      }

      return supplierObj;
    }
  
    makeSkeys(selectedFlights) {
      let sKeys = [];
      for(let item of selectedFlights){
        let keyObj = {
          "fareSellKey": item.fare[0].supplierInfo ? item.fare[0].supplierInfo.FareSellKey : "",
          "journeyKey" : item.fare[0].supplierInfo ? item.fare[0].supplierInfo.JourneySellKey : "",
          "isBlocked" : item.segments[0].blockedSegment
        }
        sKeys.push(keyObj)
      }
      return sKeys;
    }

  }
  