import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { passiveSupport } from 'passive-events-support/src/utils'


if (environment.production) {
  enableProdMode();
}
passiveSupport({
  // debug : true,
  events: ['touchstart', 'touchmove', 'touchenter', 'touchend', 'touchleave'],
  listeners: [
    {
      event: 'touchstart',
    },
    {
      event: 'touchmove'
    }
  ]
})

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
