<div class="container mt-3">
  <section class="row">
    <div class="col-md-4">
      <div class="card blue">
        <div class="d-flex align-bottom justify-content-between">
         <div>
          <h4 class="text-bold mt-0 ">₹ {{(checkLimitData && checkLimitData['limit'])?checkLimitData['limit']:0}}</h4>
          <div class="d-flex align-items-center">
            <img src="assets/v3images/icons/blue-card-icon.svg" alt="" srcset="" width="20px">
            <p  class="mb-0 text-black ml-2">Credit Limit</p>
          </div>
         </div>
          <div class="text-right">
            <!-- <small class="text-grey">Settlement Date:</small> -->
            <!-- <p class="mb-0 text-black">01 April 2022</p> -->
          </div>
        </div>
      </div>
      <div class="card orange">
        <div class="d-flex align-bottom justify-content-between">
          <div>
            <h4 class="text-bold mt-0 ">₹ {{(checkLimitData && checkLimitData['balance'])?checkLimitData['balance']:0}} </h4>
          <div class="d-flex align-items-center">
            <!-- <img src="assets/v3images/icons/orange-card-icon.svg" alt="" srcset="" width="20px"> -->
            <p  class="mb-0 text-black ml-2">Amount Due</p>
          </div>
          </div>
          <div class="text-right">
            <small class="text-grey">On Today:</small>
            <p class="mb-0 text-black">{{currentDate}}</p>
            <!-- <p  class="mb-0 text-black ml-2">Amount Due</p> -->
          </div>
        </div>
      </div>
      <div class="card green">
        <div class="d-flex align-bottom justify-content-between">
         <div>
          <h4 class="text-bold mt-0 ">₹ {{(checkLimitData && checkLimitData['amount'])?checkLimitData['amount']:0}}</h4>
          <div class="d-flex align-items-center">
            <img src="assets/v3images/icons/green-card-icon.svg" alt="" srcset="" width="20px">
            <p  class="mb-0 text-black ml-2">Credit Limit Available</p>
          </div>
         </div>
          <!-- <div class="text-right bal-info-card bg-white text-black p-2 border-radius-5">
          <small>Tickets can be booked <br>upto this amount</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="col-md-4" [formGroup]="ledgerForm">
        <div class="card p-0">
          <div class="card-header d-flex align-items-center">
            <img src="assets/v3images/icons/purple-book-icon.svg" alt="" srcset="" width="20px">
            <h4 class="text-bold mb-0 ml-2 ">Ledger</h4>
          </div>
          <div class="card-body" style="    min-height: 141px;">
            <div class="form-group">
              <label for="current-billing-cycle" class="d-flex align-items-center disableField">
                <input type="radio" name="type" formControlName="type" id="">
                <span class="ml-2">Current Billing Cycle</span>
              </label>
            </div>
            <div class="form-group">
              <label for="current-billing-cycle" class="d-flex align-items-center">
                <input type="radio" name="type" formControlName="type" value='ledger'id="">
                <div>
                  <span  class="ml-2 mr-2 text-grey">From</span>
                  <span class="date date-picker div-no-break field position-relative">
                    <!-- <i class="fa fa-calendar customCalendar2 " (click)="dpYMD.toggle()"></i> -->
                    <input   bsDatepicker autocomplete="off" #fDate formControlName="from_date"
                        #dpYMD="bsDatepicker" [bsConfig]="bsConfig" type="text" name="from_date"
                        class="custom-input text-bold m-input" 
                        (ngModelChange)="setDate('ledgerForm','from_date',$event)"
                         />
                  </span>
                  <span  class="ml-2 mr-2 text-grey">To</span>
                  <span class="date date-picker div-no-break field position-relative">
                    <!-- <i class="fa fa-calendar customCalendar2 " (click)="dpYMD.toggle()"></i> -->
                    <input  bsDatepicker autocomplete="off" #fDate formControlName="to_date"
                        #dpYMD="bsDatepicker" [bsConfig]="bsConfig" type="text" name="to_date"
                        class="custom-input text-bold m-input" 
                        (ngModelChange)="setDate('ledgerForm','to_date',$event)"
                          />
                  </span>
                </div>
              </label>
            </div>
          </div>
          <div class="card-footer text-right bg-white">
            <a class="btn btn-sm border-btn purple-btn transition" (click)="searchLedger(ledgerForm.value)">View Ledger</a>
             
          </div>
        </div>
    </div>
    <div class="col-md-4" [formGroup]="invoiceCreditNoteForm">
        <div class="card p-0">
          <div class="card-header d-flex align-items-center">
            <img src="assets/v3images/icons/green-invoice.svg" alt="" srcset="" width="20px">
            <h4 class="text-bold mb-0 ml-2 ">Bulk Invoice/ Credit Note</h4>
          </div>
          <div class="card-body" style="    min-height: 141px;">
            <div class="form-group d-flex justify-content-between align-items-center">
              <label for="current-billing-cycle" class="d-flex align-items-center">
                <input type="radio" name="type" id="" formControlName="type" value="invoice">
                <span class="ml-2 text-grey">Invoice</span>
              </label>
              <label for="current-billing-cycle" class="d-flex align-items-center">
                <input type="radio" name="type" id="" formControlName="type" value="credit_note">
                <span class="ml-2" text-grey>Credit Note</span>
              </label>
              <label for="current-billing-cycle" class="d-flex align-items-center">
                <input type="radio" name="type" id="" formControlName="type" value="both">
                <span class="ml-2" text-grey>Both</span>
              </label>
            </div>
            <div class="form-group">
              <p class="text-bold text-black mt-3 mb-2">Date Range</p>
              <div>
                <span  class="ml-2 mr-2 text-grey">From</span>
                <span class="date date-picker div-no-break field position-relative">
                  <!-- <i class="fa fa-calendar customCalendar2 " (click)="dpYMD.toggle()"></i> -->
                  <input   bsDatepicker autocomplete="off" #fDate formControlName="from_date"
                      #dpYMD="bsDatepicker" [bsConfig]="bsConfig" type="text" name="depdate-round"
                      class="custom-input text-bold m-input" 
                      (ngModelChange)="setDate('invoiceCreditNoteForm','from_date',$event)"
                       />
                </span>
                <span  class="ml-2 mr-2 text-grey">To</span>
                <span class="date date-picker div-no-break field position-relative">
                  <!-- <i class="fa fa-calendar customCalendar2 " (click)="dpYMD.toggle()"></i> -->
                  <input   bsDatepicker autocomplete="off" #fDate formControlName="to_date"
                      #dpYMD="bsDatepicker" [bsConfig]="bsConfig" type="text" name="depdate-round"
                      class="custom-input text-bold m-input" 
                      (ngModelChange)="setDate('invoiceCreditNoteForm','to_date',$event)"
                        />
                </span>
              </div>
            </div>
          </div>
          <div class="card-footer text-right bg-white">
              <button class="btn btn-sm border-btn green-btn transition mr-0" (click)="downloadTransaction()">Download Pdf</button>
          </div>
        </div>
    </div>
  </section>
  <section class="row mt-3">
    <div class="col-sm-5">
      <div class="card p-0">
        <div class="card-header d-flex align-items-center">
          <!-- <img src="assets/v3images/icons/purple-book-icon.svg" alt="" srcset="" width="20px"> -->
          <h4 class="text-bold mb-0 ml-2 ">Unpaid Invoices</h4>
        </div>
        <div class="card-body" >
          <img *ngIf="isUnpaidInvoice == false"  src="../../../assets/v3images/no-invoice.svg" class="d-block ml-auto mr-auto" alt="" srcset="" width="250px">
          <div *ngIf="isUnpaidInvoice == true" class="mobile-scroll-table  custom-table">
            <table class="table ">
                    <thead  >
                        <tr>
                            <th class=" mt-2 text-left"><span class="d-flex align-items-center">Date <i class="fa fa-sort ml-1" aria-hidden="true" ></i></span></th>
                            <th class="mt-2 text-left"><span class="d-flex align-items-center">Invoice No.<i class="fa fa-sort ml-1" aria-hidden="true"></i></span></th>
                            <th class="text-right action-btn"><span >Amount</span></th>
                        </tr>
                    </thead>
                    <tbody class="custom-table-body" [ngStyle]="{height: innerHeight}">
                        <tr class="custom-table-row" >
                            <td  class="text-left">30 April 2022</td>
                            <td  class="text-left"><span><a  class="text-dark-green hover-text-pinkish-red">IW-21-03-3001793</a></span></td>
                            <td class="text-right "><span class="bold text-red">&#8377;17,610.00</span></td>
                        </tr>
                        <tr class="custom-table-row" >
                          <td  class="text-left">30 April 2022</td>
                          <td  class="text-left"><span><a  class="text-dark-green hover-text-pinkish-red">IW-21-03-3001793</a></span></td>
                          <td class="text-right "><span class="bold text-red">&#8377;17,610.00</span></td>
                      </tr>
                      <tr class="custom-table-row" >
                        <td  class="text-left">30 April 2022</td>
                        <td  class="text-left"><span><a  class="text-dark-green hover-text-pinkish-red">IW-21-03-3001793</a></span></td>
                        <td class="text-right "><span class="bold text-red">&#8377;17,610.00</span></td>
                    </tr>
                    <tr class="custom-table-row" >
                      <td  class="text-left">30 April 2022</td>
                      <td  class="text-left"><span><a  class="text-dark-green hover-text-pinkish-red">IW-21-03-3001793</a></span></td>
                      <td class="text-right "><span class="bold text-red">&#8377;17,610.00</span></td>
                  </tr>
                  <tr class="custom-table-row" >
                    <td  class="text-left">30 April 2022</td>
                    <td  class="text-left"><span><a  class="text-dark-green hover-text-pinkish-red">IW-21-03-3001793</a></span></td>
                    <td class="text-right "><span class="bold text-red">&#8377;17,610.00</span></td>
                </tr>
                    </tbody>
                </table>
    </div> 
        </div>
      </div>
    </div>
    <div class="col-sm-7">
      <div class="card p-0">
        <div class="card-header d-flex align-items-center">
          <!-- <img src="assets/v3images/icons/purple-book-icon.svg" alt="" srcset="" width="20px"> -->
          <h4 class="text-bold mb-0 ml-2 ">Recent Payments</h4>
        </div>
        <div class="card-body" >
          <img *ngIf="!paymentArray || (paymentArray && paymentArray.length == 0)"  src="../../../assets/v3images/no-payment.svg" class="d-block ml-auto mr-auto" alt="" srcset="" width="250px">
          <div *ngIf="paymentArray && paymentArray.length > 0"  class="mobile-scroll-table  custom-table">
            <table class="table ">
                    <thead  >
                        <tr>
                            <th class=" mt-2 text-left"><span class="d-flex align-items-center">Date <i class="fa fa-sort ml-1" aria-hidden="true" ></i></span></th>
                            <th class="mt-2 text-left"><span class="d-flex align-items-center">Payment Code<i class="fa fa-sort ml-1" aria-hidden="true"></i></span></th>
                            <!-- <th class="mt-2 text-left"><span class="d-flex align-items-center">Bank<i class="fa fa-sort ml-1" aria-hidden="true"></i></span></th> -->
                            <th class="text-right action-btn"><span >Amount</span></th>
                        </tr>
                    </thead>
                    <tbody class="custom-table-body" [ngStyle]="{height: innerHeight}" >
                        <tr class="custom-table-row" *ngFor="let payment of paymentArray">
                            <td  class="text-left">{{(payment && payment.payment_date)?(payment.payment_date | date:'longDate'):''}}</td>
                            <td  class="text-left"><span><a  class="text-dark-green hover-text-pinkish-red">{{(payment && payment.payment_code)?payment.payment_code:''}}</a></span></td>
                            <!-- <td  class="text-left">{{(payment && payment.account_code_from_name)?payment.account_code_from_name:''}}</td> -->
                            <td class="text-right "><span class="bold text-red">&#8377;{{(payment && payment.amount)?payment.amount:''}}</span></td>
                        </tr>
                      
                    </tbody>
                </table>
    </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div id="snackbar"> {{snackBarMessage}}</div>