<div class="clearfix"></div>
<div class="container terms-box my-5">

  <div class="section-head">Cards and Refunds <small>(Terms & conditions of using payment gateway)</small></div>
 
  <span class="para">Aadesh Travels Pvt. Ltd. (hereinafter “ATPL”) reserves the right, in its sole discretion, to
    terminate the
    access to any or all ATPL websites or its other sales channels and the related services or
    any portion thereof at any time, without notice, for general maintenance or any reason what so
    ever...</span>
  <span class="para">If you are using the payment gateway to pay online using Debit/Credit Card or Net banking, then you
    are doing so with the consultation/approval of the card owner/account holder (if in case you are not the
    card holder/account holder yourself).</span>
  <span class="para">If the payment is not processed because of any reasons, then you are supposed to deposit cash in
    our Bank
    Account within next 24 hours, otherwise the respective booking will be cancelled. In such scenarios the
    booking/Reservation will be subject to availability.</span>
  <span class="para">Due to loss of availability or technical error there could be a possibility that the booking might
    not go
    through while processing the payment through Credit/Debit card or Net banking account. During such
    circumstances our support team shall refund the full amount (the actual amount the said booking is
    processed for without deducting the applicable charges) within 24 working hours of the booking processed
    to the same Credit/Debit Card or Net banking account from which the booking is processed.</span>
  <span class="para">If transaction is found suspicious by our risk management team and to protect your payment from
    potential
    fraud, we are forced to identify the credit card holder before we proceed with the payment. You may be
    asked to submit identification proof along with front copy of your credit card by email to complete your
    transaction. If you failed to produce the said documents on time, then your transaction/Reservation will
    be cancelled with immediate effect (for more clarity on this, you can get in touch with your nearest
    ATPL office).</span>
  <span class="para">Transaction processing charge will be borne by the user for using the payment Gateway.</span>
  <span class="para">If the transaction has been cancelled by the user, processing charges will still apply to the
    Agent/card
    holder.</span>
  <span class="para">As a standard process if the booking is not processed because of some technical error or cancelled
    by the
    user, then the refund will be initiated in same Credit card / Debit card or Net banking account through
    which the booking has been originally processed. (Refund handling charges will be applicable in case of
    cancellation of booking from agents end over and above the cancellation charges as specified in the
    booking)</span>
  <span class="para">Payment Gateway is an additional Payment option available for the user and is not the only option
    for
    making Payments. You can contact your nearest ATPL office to make your reservation by paying cash
    or transferring funds via wire transfers.</span>
  <span class="para">For more details please write to us at <a
      href="mailto:fly@aadeshtravels.com">fly@aadeshtravels.com</a></span>




  <div class="section-head">Cancellation & Refund policy</div>
  <span class="para">Each hotel/service provider has its own refund and cancellation policy, as a result of which we
    adhere to
    the same. Also, refunds will be done only through the Original Mode of Payment.</span>
  <span class="para">Refund Policy and process:- The reservations which are applicable for refund as per the
    cancellation
    policy specified in each booking will be entitled for refund (if applicable) and will be refunded within
    15 working days from the date of cancellation request after the deduction of payment gateway processing
    charges.</span>


  <div class="section-head">Delivery Policy</div>
  <span class="para">Service of Hotel booking is confirmed as soon as payment is processed and we get the confirmation
    from
    respective hotel for booking made. ATPL will NOT deal or provide any services or products to any
    of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of UAE.</span>


  <div class="section-head">Dispute and Resolution policy</div>
  <span class="para">In case of any dispute agent can reach to local office over the mail with the details.
    ATPL will
    try to provide amicable solution to both the parties subject to availability of the booking. ATPL
    Decision will be final in case of any Dispute.</span>


  <div class="action-btn text-center clearfix mt-4">
    <a class="" href="javascript:void(0)" routerLink="/">Back</a>
  </div>

</div>
