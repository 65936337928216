import { Component, EventEmitter, Input, OnInit, Output ,SimpleChanges} from '@angular/core';
import { InitialState } from "src/app/store/reducer";
import { NgRedux } from "@angular-redux/store";
import { SetInsuranceTraveller } from 'src/app/store/travellerInsurance/actions';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment-timezone';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-travel-insurance',
  templateUrl: './travel-insurance.component.html',
  styleUrls: ['./travel-insurance.component.scss']
})

export class TravelInsuranceComponent implements OnInit {
  isTnC: boolean = false;
  @Input() insurancePackageData;
  @Input() stepIndex;
  @Input() componentIndex=5;
  @Input() travellers = []
  
  @Output() callToggleInsuranceSelection : EventEmitter<any> = new EventEmitter();
  @Output() callStepFunction : EventEmitter<any> = new EventEmitter();

  insuranceTravellerList: FormArray;
  insuranceTravellerForm: FormGroup;
  isInsuranceCoverExpanded = false;
  isInsuranceSelected = false;
  insuranceCoverViewCount = 2;
  disabledBtn = false
  constructor(private fb: FormBuilder,private commonService: CommonService, private ngRedux: NgRedux<InitialState>) { }

  ngOnInit() {
    this.insuranceTravellerForm = this.fb.group({
      insuranceTravellerList: this.fb.array([])
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
		if(changes && changes.travellers && changes.travellers['previousValue'] != changes.travellers['travellers']){
      if(this.isInsuranceSelected){
        this.toggleInsuranceSelection(true)
        
      }
		}

    if(this.isInsuranceSelected && changes && changes.stepIndex && changes.stepIndex['currentValue']==6 && this.disabledBtn==true){
      this.callStepFunction.emit(5)
    }
	}
  
  toggleInsuranceCoverListView(){
    this.isInsuranceCoverExpanded = !this.isInsuranceCoverExpanded
    if(this.isInsuranceCoverExpanded){
      this.insuranceCoverViewCount = this.insurancePackageData.covers.length
    }else{
      this.insuranceCoverViewCount = 2
    }
  }

  toggleInsuranceSelection(e){

    if( this.validateTravellersData(this.travellers)){
      this.insuranceTravellerList = this.insuranceTravellerForm.get("insuranceTravellerList") as FormArray;
      this.insuranceTravellerList.controls = [];
      if(e == true){
        this.isInsuranceSelected = true;
        this.callToggleInsuranceSelection.emit(this.isInsuranceSelected);
        console.log('Insurance is selected')
        console.log("travellers: ",this.travellers)
          this.travellers.forEach((traveller,ind)=>{
            this.insuranceTravellerList.push(this.buildItem(traveller,ind))
        })
  
        this.checkIsValidDobOfAllTraveller(this.insuranceTravellerList.value)
        this.ngRedux.dispatch(SetInsuranceTraveller(this.insuranceTravellerList))
        console.log("insuranceTravellerList: ",this.insuranceTravellerList)
      }
      else{
        this.isInsuranceSelected = false;
        console.log('Insurance not selected')
      }
    }else{
      this.isInsuranceSelected = false
      this.callStepFunction.emit(2)
    }
   
 
  }

  validateTravellersData(travellers){
    for(let traveller of travellers){
      if(!(traveller.title && traveller.title.length>0  && traveller.firstName && traveller.firstName.length>0 && traveller.lastName && traveller.lastName.length>0)){
        return false
      }
    }
    return true
  }

  get travellersControls() {
    return <FormArray>this.insuranceTravellerForm.get("insuranceTravellerList");
  }


  getCurrentDateTime(){
    return this.commonService.getCurrentDateTime()
	}

  


  buildItem(passenger, i) {
    
    return this.fb.group({
      title: [passenger['title']],
      firstName: [passenger['firstName']],
      lastName: [passenger['lastName']],
      gender:[passenger['gender']],
      dob: [passenger.hasOwnProperty('dob')?passenger.dob:"",Validators.required],
      type:[passenger['passengerType']]
    });
  }
  
  callStepButton(index){
    this.callStepFunction.emit(index);
  }
  toggleTnC(){
    this.isTnC = !this.isTnC;
  }

  dobSelectionChange(type: string, event: MatDatepickerInputEvent<Date>,traveller) {

      if(traveller && traveller.value && traveller.value.type){
        let age = this.getAge(event.value)
        let passType = traveller.value.type
        if(!this.checkIsValidAge(passType,age)){
          traveller['controls']['dob'].setErrors('Invalid date of birth given')
          this.disabledBtn = true
        }else{
          this.checkIsValidDobOfAllTraveller(this.insuranceTravellerList.value)
        }
      }else{
        traveller['controls']['dob'].setErrors('date of birth required')
        this.disabledBtn = true
      }
      this.ngRedux.dispatch(SetInsuranceTraveller(this.insuranceTravellerList))
  }



  checkIsValidDobOfAllTraveller(travellers){
    for(let traveller of travellers){
      if(traveller.hasOwnProperty('dob') && traveller.dob && traveller.hasOwnProperty('type') && traveller.type.length>0){
        let age = this.getAge(traveller.dob)
        if(!this.checkIsValidAge(traveller.type,age)){
          this.disabledBtn = true
          return false
        }
      }else{
        this.disabledBtn = true
        return false
      }
    }
    this.disabledBtn = false
    return true
  }

  getAge(dob){
    let currentDataTime = this.getCurrentDateTime()
    dob = moment(dob, 'YYYY-MM-DD').format('YYYY-MM-DD')
    let diff = moment(currentDataTime,"YYYY-MM-DD").diff(moment(dob,"YYYY-MM-DD"),'years')
    console.log(diff)
    return diff
  }

  checkIsValidAge(type,age){
    if(type.toLocaleLowerCase()=='adult' && !(age>=18)){
        return false
    }
    if(type.toLocaleLowerCase()=='child' && !(age>=7 && age<18)){
      return false
    }

    if(type.toLocaleLowerCase()=='infant' && !(age>=1 && age<7)){
      return false
    }
    return true

  }

}
