import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ancillary',
  templateUrl: './ancillary.component.html',
  styleUrls: ['./ancillary.component.css']
})
export class AncillaryComponent implements OnInit {

  @Input() ssrAvailability;
	@Input() segmentIndex = 0
	@Output() callAncillaryEvent : EventEmitter<any> = new EventEmitter();
	selectedPassenger = 0
	constructor() { }

	ngOnInit() {
	}
	changeAncillary(event,index, pax){
		let isChecked = event.target.checked
		let leg = 0; 
		const tempArr = Object.assign({},  this.ssrAvailability[leg])
		if(!this.ssrAvailability[leg].travellers[pax]['selectedAncillary']){
			this.ssrAvailability[leg].travellers[pax]['selectedAncillary'] = []
		}
		let ancillary = tempArr.ancillaries[index];
		if(!isChecked) {
			this.ssrAvailability[leg].travellers[pax]['ancillary'] = undefined;
			this.ssrAvailability[leg].travellers[pax]['ancillaryFare'] = 0;
			this.ssrAvailability[leg].travellers[pax]['selectedAncillary'] = this.ssrAvailability[leg].travellers[pax]['selectedAncillary'].filter(item=> {
				return item.code != ancillary.code
			} )
			this.makeSeatObject(pax, ancillary.code, true, ancillary.totalFare, leg,tempArr,ancillary)
		}
		else{
			
			this.ssrAvailability[leg].travellers[pax]['ancillary'] = ancillary.name;	
			this.ssrAvailability[leg].travellers[pax]['selectedAncillary'].push(ancillary);		
			if(ancillary.name == null) {
				this.ssrAvailability[leg].travellers[pax]['ancillary'] = ancillary.code;
			}
			this.ssrAvailability[leg].travellers[pax]['ancillaryFare'] = ancillary.totalFare;
			this.makeSeatObject(pax, ancillary.code, false, ancillary.totalFare, leg,tempArr,ancillary)
		}
	
			
	}
	
	makeSeatObject(passengerIndex, ancillaryCode, isRemove, price, leg,tempArr,ancillary){
		let flag = true;
		let segment = this.segmentIndex
		if(tempArr.flightInfo.ssrSRT[segment] && tempArr.flightInfo.ssrSRT[segment][leg]  && tempArr.flightInfo.ssrSRT[segment][leg].ancillary != undefined && tempArr.flightInfo.ssrSRT[segment][leg].ancillary != null) {
			tempArr.flightInfo.ssrSRT[segment][leg].ancillary = tempArr.flightInfo.ssrSRT[segment][leg].ancillary.filter(obj =>{
				if(isRemove) {
					flag = false;
					if(obj.travellerNumber == passengerIndex) {
						obj['selectedItems'] = obj['selectedItems'].filter(item=> {
							return item.code != ancillary.code
						})
						return obj;
					}
					return obj;
				}
				else{
					if(obj.travellerNumber == passengerIndex) {
						obj['code'] = ancillaryCode;
						obj['price'] = price;
						obj['item'] = ancillary;
						obj['selectedItems'].push(ancillary)
						flag = false;
					}
					return obj;
				}
				
			});
		}
		
		if(flag) {
			let obj = {
				"code": ancillaryCode,
				"travellerNumber": passengerIndex,
				"item": ancillary,
				"price" : price,
				"selectedItems": [ancillary],
				"title": this.ssrAvailability[leg].travellers[passengerIndex].title,
				"firstName": this.ssrAvailability[leg].travellers[passengerIndex].firstName,
				"lastName": this.ssrAvailability[leg].travellers[passengerIndex].lastName,
			}
			if(tempArr.flightInfo.ssrSRT[segment] == undefined){
				tempArr.flightInfo.ssrSRT[segment] = []
			}
			if(tempArr.flightInfo.ssrSRT[segment][leg] == undefined){
				tempArr.flightInfo.ssrSRT[segment][leg] = {}
			}
			if(tempArr.flightInfo.ssrSRT[segment][leg].ancillary == undefined || tempArr.flightInfo.ssrSRT[segment][leg].ancillary == null) {
				tempArr.flightInfo.ssrSRT[segment][leg].ancillary = [];				
			}
			tempArr.flightInfo.ssrSRT[segment][leg].ancillary.push(obj)
		}
		this.callAncillaryEvent.emit(tempArr.flightInfo.ssrSRT);
		
	}

	selectPassenger(index){
		this.selectedPassenger = index
	}

	getChecked(i,ancillary){
		let item = this.ssrAvailability[i]
		let flag = false
		if(item.travellers[this.selectedPassenger] && item.travellers[this.selectedPassenger].selectedAncillary){
			let selectedItem = item.travellers[this.selectedPassenger].selectedAncillary.filter(data=> {
				return ancillary.code == data.code
			})
			if(selectedItem.length > 0) {
				flag = true
			}
		}
		return flag
	}

}
