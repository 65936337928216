<header id="header" class="home row mx-0 mb-0" [ngStyle]="{'margin-top': heightAsPerMessage}">
<!-- messages -->
<!-- *ngIf="warningMessage['status'] == true" -->
<ng-container *ngIf="warningMessage && warningMessage['status'] == true" >
    <div class="message-wrapper bg-warning">
        <div
        *ngFor="let messageList of warningMessage['messageList'] let i = index;" 
            class="update-info animated  fadeInTop {{messageList['warningType']}}">
            <div class="container">
               <div class="row">
                <div class="col-11">
                    <b class="d-block">
                        <!-- Lorem Ipsum is simply dummy.  -->
                        {{messageList?.message}} 
                       <ng-container *ngIf="messageList.detail != undefined">
                        <small class="btn btn-sm btn-dark ml-2" *ngIf="selectedErrorIndex != i" (click)="viewDetails(i)">View Details</small>
                        <small class="btn btn-sm btn-dark ml-2" *ngIf="selectedErrorIndex == i" (click)="viewDetails(undefined)">Hide Details</small>
                       </ng-container>
                    </b>
                    <!--  -->
                    <small *ngIf="selectedErrorIndex == i && messageList.detail != undefined" class="warning-detail-area">
                        <!-- Lorem Ipsum is simply dummy text of the printing and typesetting industry.  -->
                        {{messageList?.detail}}
                    </small>
                </div>
                <div class="col-1">
                    <i class="fa fa-times cursor-pointer" (click)="hideError(i)" aria-label="Close"></i>
                    <!-- <button type="button" class="close" (click)="hideError(i)" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
                </div>
               </div>
            </div>
        </div>
    </div>
</ng-container>





    <!-- <ng-container *ngIf="showInfoMessage">
        <div class="message-wrapper">
            <div class="update-info animated fadeInTop ">
                <div class="container d-flex align-item-center justify-content-between">
                        <div>
                            <img src="assets/v3images/new-animated-tag.jpg" height="20px" width="30px" class="mr-2">
                        <span>Please check booking procedures and guidelines for International flight from our backend team.</span>
                        </div>
                        <i class="fa fa-times text-white" (click)="showHideInfoMessage()" aria-label="Close"></i>
                </div>
            </div>
        </div>
    </ng-container> -->

<!-- header -->
    <div class="container p-0">
        <!-- <div class="ham-nav" (click)="hamClick()">
            <ng-container *ngFor="let i of [].constructor(3)">
                <span></span>
            </ng-container>
        </div> -->
       <div class="d-flex align-items-center justify-content-between ">
            <div class="logo">
                <a routerLink="" (click)="resetSearchForm()">
                    <img src="assets/v3images/logoes/aadesh-icon.svg" width="50px" alt="" class="mt-2">

                    <!-- <img src="assets/v3images/logoes/full-logo-header.svg" width="150px" alt=""> -->
                </a>
            </div>
            <ul class="main-menues list-inline list-unstyled m-0 p-0">
                <li class="menue-item ">
                    <a routerLink="" class="active ">
                        <img src="assets/v3images/icons/purple-flight.svg" alt="" width="20px" class="mr-2" />
                        <span class="text-capitalize text-dark-green text-bold">flights</span>
                    </a>
                </li>
                <li class="menue-item ">
                    <a (click)="redirectTo('hotel')" >
                        <img src="assets/v3images/icons/dark-pink-hotel.svg" alt="" width="20px" class="mr-2" />
                        <span class="text-capitalize text-dark-pink text-bold">hotels</span>
                    </a>
                </li>
                <li class="menue-item ">
                    <a (click)="redirectTo('insurance')" >
                        <img src="assets/v3images/icons/sky-blue-insurance.svg" alt="" width="20px" class="mr-2" />
                        <span class="text-capitalize text-sky-blue text-bold">Insurance</span>
                    </a>
                </li>
                <li class="menue-item ">
                    <a (click)="redirectTo('parks')" >
                        <img src="assets/v3images/icons/green-park.svg" alt="" width="20px" class="mr-2" />
                        <span class="text-capitalize text-success text-bold">parks</span>
                    </a>
                </li>
                <li class="menue-item ">
                    <a (click)="redirectTo('car')" >
                        <img src="assets/v3images/icons/dark-blue-visa.svg" alt="" width="20px" class="mr-2" />
                        <span class="text-capitalize text-dark-blue text-bold">Car</span>
                    </a>
                </li>
                
            </ul>
            <ul class="action-btns align-content-center d-flex list-unstyled m-0 p-0">
                <!-- <li class="menue-item mr-2">
                    <select *ngIf="login" name="showFooterDesign" id="showFooter"  (change)="showFooterDesign($event.target.value)" class="border-0 m-0"  >
                        <option value="1">Productive</option>
                        <option value="2">Design</option>
                    </select>
                </li> -->
                <li *ngIf="!login" class="menue-item">
                    <a href="#registration-section" class="btn btn-green  ">
                        Register
                    </a>
                </li>
                <li *ngIf="login" class="menue-item user-account position-relative">
                   <div (click)="isAccDropDownShow=!isAccDropDownShow"   class="d-flex align-items-center justify-content-between cursor ">
                        <img  src="assets/v3images/icons/user-icon.svg" alt="" width="25px" height="25px" class="user-pic mr-2" />
                        <span class="user-name text-bold" style="font-size: 12px;">{{shortHandName}}</span>
                        <img src="assets/v3images/icons/black-angle-down.svg" alt="" width="10px" class="ml-2"  />
                   </div>  
                    <div  [ngClass]="isAccDropDownShow ? 'active' : ''" class="custom-modal-box top-right transition user-account-dropdown">
                        <div class="position-relative">
                            <img (click)="isAccDropDownShow=!isAccDropDownShow" src="assets/v3images/icons/red-close.svg" alt="" srcset="" class="custom-modal-close-btn position-absolute grayscale-filter no-grayscale-filter-hover cursor">
                            <div class="user-info">
                                <div class="text-center  ">
                                    <img  src="assets/v3images/icons/user-icon.svg" alt="" width="40px" height="40px" class="user-pic mb-2" />
                                    <div>
                                        
                                        <h4 class="user-name text-bold text-dark-green text-capitalize"><small class="text-bold text-black"><i>Hi,</i></small> {{loggedInUserName}}</h4>
                                        <small  class="comp-name  text-uppercase">{{userInfo.companyName}} <br> <span class="text-bold">({{userInfo.xlAccountCode}})</span></small>
                                            <small *ngIf="showEmulatedBy" class="text-capitalize text-grey d-block c-name" >Impersonated by :
                                                {{userInfo.emulated_by}}</small>
                                    </div>
                               </div>  
                            </div>
                            <div class="bal-cards d-flex align-items-center justify-content-between ">
                                <!-- <div class="card mr-3" *ngIf="cashLimit != undefined">
                                    <small class="text-bold text-grey">cash bal.</small>
                                    <h4 class="text-success text-bold">{{cashLimit?.creditLimit | currency:"INR":"symbol-narrow":"1.0-0"}}</h4>
                                </div> -->
                                <div class="card " >
                                        <small class="text-bold text-grey" *ngIf="creditLimit != undefined">Balance</small>
                                    <h4 class="text-bold" [ngClass]="creditLimit > '0' ? 'text-success' : 'text-danger'" *ngIf="creditLimit != undefined">{{creditLimit?.creditLimit  | currency:"INR":"symbol-narrow":"1.0-0"}}</h4>
                                </div>
                            </div>
                            <ul class="dropdown-menues list-unstyled">
                                <li class="menue-list"  *ngIf="!showOfferLi" (click)="viewOffer()">
                                    <a ref="javascript:void(0)" class="d-block">
                                    <div class="d-flex align-items-center justify-content-between ">  
                                        <div class="d-flex align-items-center ">
                                            <div class="menue-icon mr-2">
                                                <img  src="assets/v3images/icons/green-offer.svg" alt="" width="20px" height="20px"  />
                                            </div>
                                            <span class="text-bold">View Offers</span>
                                        </div>
                                            <div class="angle-right-icon">
                                                <img src="assets/v3images/icons/green-angle-right.svg" alt="" width="15px" class="ml-2"  />
                                            </div>
                                    </div>
                                    </a>
                                   
                                </li>
                                <li class="menue-list"  *ngIf="showOfferLi" (click)="hideOffer()">
                                    <a ref="javascript:void(0)" class="d-block">
                                    <div class="d-flex align-items-center justify-content-between ">  
                                        <div class="d-flex align-items-center ">
                                            <div class="menue-icon mr-2">
                                                <img  src="assets/v3images/icons/green-offer.svg" alt="" width="20px" height="20px"  />
                                            </div>
                                            <span class="text-bold text-danger">Hide Offers</span>
                                        </div>
                                            <div class="angle-right-icon">
                                                <img src="assets/v3images/icons/green-angle-right.svg" alt="" width="15px" class="ml-2"  />
                                            </div>
                                    </div>
                                    </a>
                                   
                                </li>
                                <li class="menue-list" (click)="isAccDropDownShow=!isAccDropDownShow">
                                    <a  routerLink="/payment"  class="d-block">
                                    <div class="d-flex align-items-center justify-content-between ">  
                                        <div class="d-flex align-items-center ">
                                            <div class="menue-icon mr-2">
                                                <img  src="assets/v3images/icons/green-payment.svg" alt="" width="20px" height="20px"  />
                                            </div>
                                            <span class="text-bold">Payment</span>
                                        </div>
                                            <div class="angle-right-icon">
                                                <img src="assets/v3images/icons/green-angle-right.svg" alt="" width="15px" class="ml-2"  />
                                            </div>
                                    </div>
                                    </a>
                                </li>
                                <li class="menue-list" (click)="isAccDropDownShow=!isAccDropDownShow">
                                    <a routerLink="/financials"  class="d-block">
                                    <div class="d-flex align-items-center justify-content-between ">  
                                        <div class="d-flex align-items-center ">
                                            <div class="menue-icon mr-2">
                                                <img  src="assets/v3images/icons/green-ledger.svg" alt="" width="20px" height="20px"  />
                                            </div>
                                            <span class="text-bold">Financials</span>
                                        </div>
                                            <div class="angle-right-icon">
                                                <img src="assets/v3images/icons/green-angle-right.svg" alt="" width="15px" class="ml-2"  />
                                            </div>
                                    </div>
                                    </a>
                                </li>
                                <!-- <li class="menue-list" (click)="isAccDropDownShow=!isAccDropDownShow">
                                    <a routerLink="/ledger"  class="d-block">
                                    <div class="d-flex align-items-center justify-content-between ">  
                                        <div class="d-flex align-items-center ">
                                            <div class="menue-icon mr-2">
                                                <img  src="assets/v3images/icons/green-ledger.svg" alt="" width="20px" height="20px"  />
                                            </div>
                                            <span class="text-bold">View Ledger</span>
                                        </div>
                                            <div class="angle-right-icon">
                                                <img src="assets/v3images/icons/green-angle-right.svg" alt="" width="15px" class="ml-2"  />
                                            </div>
                                    </div>
                                    </a>
                                </li> -->
                                <li class="menue-list" (click)="isAccDropDownShow=!isAccDropDownShow">
                                    <a routerLink="/bookingsummary"  class="d-block">
                                    <div class="d-flex align-items-center justify-content-between ">  
                                        <div class="d-flex align-items-center ">
                                            <div class="menue-icon mr-2">
                                                <img  src="assets/v3images/icons/green-booking.svg" alt="" width="20px" height="20px"  />
                                            </div>
                                            <span class="text-bold">Booking Summary</span>
                                        </div>
                                            <div class="angle-right-icon">
                                                <img src="assets/v3images/icons/green-angle-right.svg" alt="" width="15px" class="ml-2"  />
                                            </div>
                                    </div>
                                    </a>
                                </li>
                                <!-- <li class="menue-list" (click)="isAccDropDownShow=!isAccDropDownShow">
                                    <a routerLink="/statement" class="d-block">
                                    <div class="d-flex align-items-center justify-content-between ">  
                                        <div class="d-flex align-items-center ">
                                            <div class="menue-icon mr-2">
                                                <img  src="assets/v3images/icons/green-statement.svg" alt="" width="20px" height="20px"  />
                                            </div>
                                            <span class="text-bold">Statement</span>
                                        </div>
                                            <div class="angle-right-icon">
                                                <img src="assets/v3images/icons/green-angle-right.svg" alt="" width="15px" class="ml-2"  />
                                            </div>
                                    </div>
                                    </a>
                                </li> -->
                                <li class="menue-list" (click)="isAccDropDownShow=!isAccDropDownShow">
                                    <a routerLink="/my-account" class="d-block">
                                    <div class="d-flex align-items-center justify-content-between ">  
                                        <div class="d-flex align-items-center ">
                                            <div class="menue-icon mr-2">
                                                <img  src="assets/v3images/icons/green-account.svg" alt="" width="20px" height="20px"  />
                                            </div>
                                            <span class="text-bold">My Account</span>
                                        </div>
                                            <div class="angle-right-icon">
                                                <img src="assets/v3images/icons/green-angle-right.svg" alt="" width="15px" class="ml-2"  />
                                            </div>
                                    </div>
                                    </a>
                                </li>
                                <li class="menue-list" (click)="isAccDropDownShow=!isAccDropDownShow">
                                    <a routerLink="/group-fare" class="d-block">
                                    <div class="d-flex align-items-center justify-content-between ">  
                                        <div class="d-flex align-items-center ">
                                            <div class="menue-icon mr-2">
                                                <img  src="assets/v3images/icons/green-group-fare.svg" alt="" width="20px" height="20px"  />
                                            </div>
                                            <span class="text-bold">Group Fare</span>
                                        </div>
                                            <div class="angle-right-icon">
                                                <img src="assets/v3images/icons/green-angle-right.svg" alt="" width="15px" class="ml-2"  />
                                            </div>
                                    </div>
                                    </a>
                                </li>
                                <li class="menue-list" (click)="isAccDropDownShow=!isAccDropDownShow">
                                    <a routerLink="/white-label/portal-configuration" class="d-block">
                                    <div class="d-flex align-items-center justify-content-between ">  
                                        <div class="d-flex align-items-center ">
                                            <div class="menue-icon mr-2">
                                                <img  src="assets/v3images/icons/green-setting.svg" alt="" width="20px" height="20px"  />
                                            </div>
                                            <span class="text-bold">Portal Configration</span>
                                        </div>
                                            <div class="angle-right-icon">
                                                <img src="assets/v3images/icons/green-angle-right.svg" alt="" width="15px" class="ml-2"  />
                                            </div>
                                    </div>
                                    </a>
                                </li>
    
                                <li (click)="isAccDropDownShow=!isAccDropDownShow">
                                    <a href="#" class="d-block">
                                    <div class="d-flex align-items-center justify-content-between ">  
                                        <div (click)="logout()" class="d-flex align-items-center ">
                                            <div class="menue-icon mr-2">
                                                <img  src="assets/v3images/icons/red-logout.svg" alt="" width="20px" height="20px"  />
                                            </div>
                                            <span class="text-bold text-danger">Logout</span>
                                        </div>
                                    </div>
                                    </a>
                                </li>
                            </ul>
                            <small class="d-block mt-2 text-center text-grey">Aadesh Version 3.0</small>
                        </div>
                    </div>                
                </li>
                <li class="menue-item ml-2">
                    <a routerLink="/contact-us" class="text-bold text-black btn pt-1" >
                        <img src="assets/v3images/icons/black-phone-icon.svg" alt="" width="18px"  />
                       <span> Support</span>
                    </a>
                </li>
            </ul>
       </div>
        <!-- <div class="action-nav">
            <div class="bottommenu">
                <a routerLink="" class="active">FLIGHTS</a>
                <a (click)="redirectTo('hotel')">HOTELS</a>
                <a (click)="redirectTo('insurance')">INSURANCE</a>
                <a (click)="redirectTo('parks')">PARKS</a>
                <a (click)="redirectTo('visa')">VISA</a>
            </div>
            <div class="headerRight">
                <ul class="topmenu ml-0">
                    <li class="support-mob"><i class="fa fa-phone mr-1" aria-hidden="true"></i><a class="support-link"
                            routerLink="pages/support">Support</a></li>
                    <li class="user">
                        <a class="username" *ngIf="login">
                            <div class="dropdown">
                                <button class="dropbtn">
                                    <span class="text-uppercase">{{loggedInUserName}}</span><br>
                                    <span class="text-uppercase c-name">{{userInfo.companyName}}
                                        ({{userInfo.xlAccountCode}})</span>
                                    <span class="text-uppercase d-block c-name" *ngIf="showEmulatedBy">Impersonated by :
                                        {{userInfo.emulated_by}}</span>
                                </button>
                                <div class="dropdown-content">
                                    <a href="javascript:void(0)" *ngIf="!showOfferLi" (click)="viewOffer()"> View
                                        Offer</a>
                                    <a href="javascript:void(0)" *ngIf="showOfferLi" (click)="hideOffer()"> Hide
                                        Offer</a>
                                    <a routerLink="my-account"> My Account</a>
                                    <a style="cursor: text !important;">
                                        <span *ngIf="cashLimit != undefined">
                                            Cash Bal :
                                            <strong>
                                                {{cashLimit?.creditLimit | currency:"INR":"symbol-narrow":"1.0-0"}}
                                            </strong>
                                        </span>
                                        </a>
                                    <a style="cursor: text !important;" *ngIf="!isDiAccount">
                                         <span
                                            *ngIf="creditLimit != undefined">Credit Bal :
                                            <strong>
                                                {{creditLimit?.creditLimit  | currency:"INR":"symbol-narrow":"1.0-0"}}
                                            </strong>
                                        </span>
                                    </a>
                                    <a href="#" (click)="logout()">Logout</a>
                                    <a  *ngIf="appVersion" href="javascript:void(0)" >Version : {{appVersion}}</a>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div> -->
    </div>
</header>