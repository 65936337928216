<section class="content account">
	<div id="accountinfo" class="">
		<mat-tab-group class="mat-group">
			<mat-tab>
				<ng-template mat-tab-label>
					RESET PASSWORD
				</ng-template>
				<div class="alert alert-success msgSuccess" *ngIf="successMsg">
					<strong>Success!</strong> Password reset successfully.
				</div>
				<form class="m-form m-form--fit " [formGroup]="resetPassword">
					<div id="resetpass" class="commacc  respass">
						<div class="container">
							<div class="row mx-auto">
								<div class="field  col-md-4">
									<mat-form-field class="">
										<input matInput type="password" placeholder="PASSWORD"
											formControlName="currentPass" [ngStyle]="{'border-color': !currentPass.valid && 
												currentPass.touched ? 'red' : (!currentPass.valid && 
												currentPass.touched ? '' : null) }">
									</mat-form-field>
									<div>
										<span class="error txtPasswordError"
											*ngIf="!currentPass.valid && currentPass.touched">
											Enter new Password
										</span>
									</div>

								</div>
								<div class="field  col-md-4">
									<mat-form-field class="">
										<input matInput type="password" placeholder="CONFIRM PASSWORD" formControlName="newPass"
											[ngStyle]="{'border-color': !newPass.valid && 
												newPass.touched ? 'red' : (!newPass.valid && 
												newPass.touched ? '' : null) }">
									</mat-form-field>
									<div>
										<span class="error txtPasswordError" *ngIf="!newPass.valid && newPass.touched">
											Enter confirm Password
										</span>
									</div>
									<div>
										<span class="error txtPasswordError" *ngIf="confirmMsg">
											Password not matched
										</span>
									</div>
								</div>
							</div>

							<div class="field text-center">
								<button type="submit" class="submit" [disabled]="!resetPassword.valid"
									(click)="changePassword(resetPassword.value)">Submit</button>
									&nbsp;&nbsp;
								<button type="cancel" class="submit" routerLink="/login">
									Cancel
								</button>
							</div>
						</div>
					</div>
				</form>
			</mat-tab>
		</mat-tab-group>
	</div>
</section>