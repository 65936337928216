import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking/booking.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';

@Component({
	selector: 'app-booking-summary-details',
	templateUrl: './booking-summary-details.component.html',
	styleUrls: ['./booking-summary-details.component.scss']
})
export class BookingSummaryDetailsComponent implements OnInit {
	bookingArr: any = [];
	valid: boolean = false;
	pricePaxArr: any = [];
	affiliateMarkUpPrice: number = 0;
	totalFare;
	searchId: string;
	netCommission: number = 0;
	tds: number = 0;
	noOfAdult: number = 0;
	noOfChild: number = 0;
	noOfInfant: number = 0;


	addonMarkupShowAs: string = '';
	addonMarkupAmount: number = 0;
	addOnMarkupForm: FormGroup;
	successMsg;
	totalAddonMarkup: number = 0;
	isAddonMarkupEnable: boolean = false;
	netPayableAmount: number = 0;
	subscription: any;
	bookingData: any;
	affiliateAddOnMarkUpPrice: number = 0;
	adminMarkup: number = 0;
	netPayable: number = 0;
	isTicketed: boolean = false;
	gstAmount: number = 0;
	tdsAmount: number = 0;
	ismultiLeg: boolean = false;
	GSTonCancellation: number = 0;

	constructor(private route: ActivatedRoute, private fb: FormBuilder,
		private router: Router,
		private commonService: CommonService,
		private bookingService: BookingService) { }

	ngOnInit() {
		this.gstAmount = 0
		this.tdsAmount = 0
		this.GSTonCancellation = 0
		this.addOnMarkupForm = this.fb.group({
			'showAs': ['', Validators.required],
			'amount': ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
		})
		this.searchId = this.route.snapshot.params['searchId'];

		this.bookingService.getBookingWithBookingRef(this.searchId).subscribe(res=>{
			let data = JSON.parse(res["_body"])['data']
			this.bookingData = data[0]['json_doc'];
			if (this.bookingData.segments[0].legs.length > 1) {
				this.ismultiLeg = true;
			}
			else{
				this.ismultiLeg = false;
			}
			this.searchId = data[0]['json_doc']['invoice_no'];
			this.isTicketed = false;
			if(data[0]['json_doc'].pnr_status == "TICKETED") {
				this.isTicketed = true;
			}
			this.bookingArr = data
			this.valid = true;
			console.log('BookingData',this.bookingData)
		}) 
		
	}
	// ngOnDestroy() {
	// 	this.subscription.unsubscribe();
	// }
	getAirline(segment, type) {
		let code = segment.legs[0].operating_airline_code
		let name = segment.legs[0].operating_airline_name
		let flight = segment.legs[0].flight_number
		if (type == 'code') {
			return code;
		}
		if (type == 'name') {
			return name;
		}
		if (type == 'flight') {
			return flight;
		}
	}
	getFlight(segment) {
		return segment.legs[0].flight_number
	}
	getOrigin(data) {
		return data.segments[0].legs[0].origin_airport_code;
	}
	getCity(data) {
		 
		let city = data.split(', ').splice(1, 1).join('')
		return city
	}
	getDestination(data, type) {
		let segLength = data.segments.length
		let y = data.segments[segLength - 1]
		let z = y.legs.length
		if (type == 'name') {
			return y.legs[z - 1].destination_airport_code;
		}
		if (type == 'airport') {
			return y.legs[z - 1].destination_airport_name;
		}
		if (type == 'date') {
			return y.legs[z - 1].destination_timestamp;
		}
	}
	getTotal(data) {
		this.totalFare = 0
		this.tds = 0;
		this.netCommission = 0;
		for (let i = 0; i < data.length; i++) {
			const element = data[i];
			const lThis = this;
			let keys = Object.keys(element.fare.taxes[0]);
			let count = 0;
			Object.values(element.fare.taxes[0]).forEach(function (v) {
				if (keys[count] != "MARKUP" && keys[count] != "COMMISSION" && keys[count] != "TOTAL" && keys[count] != "TDS" && keys[count] != "NET_PAYABLE" &&
					keys[count] != "OTHER_TAX" && keys[count] != "AFFILIATE_MARKUP" && keys[count] != "GST" && keys[count] != "ADMIN_SERVICE_CHARGE" ) {
					lThis.totalFare += Number(v);
				}
				if(keys[count] == "COMMISSION") {
					lThis.netCommission +=  Number(v);
				}
				if(keys[count] == "TDS") {
					lThis.tds +=  Number(v);
				}
				count++;
			})
			this.totalFare += Number(element.fare.base) + this.getSSRPrice(element.ssr);
		}
		this.netPayable = this.totalFare - this.netCommission + this.tds;
		return this.totalFare
	}
	getOT(data) {
		let otherTax = 0
		const element = data;
		const lThis = this;
		let keys = Object.keys(element.fare.taxes[0]);
		let count = 0;
		Object.values(element.fare.taxes[0]).forEach(function (v) {
			if (keys[count] == "OTHER_TAX" || keys[count] == "OT" || keys[count] == "ADMIN_MARKUP") {
				otherTax += Number(v);
			}
			count++;
		})
		otherTax +=  this.getSSRPrice(element.ssr);
		return otherTax;
	}
	getSSRPrice(ssr) {
		let meal = 0;
		let baggage = 0;
		let seat = 0;
		if (ssr != null) {
			for (let i = 0; i < ssr.length; i++) {
				const element = ssr[i];
				if (element.meals != null) {
					meal += Number(element.meals.code.totalFare);
				}
				if (element.baggages != null) {
					baggage += Number(element.baggages.code.totalFare);
				}
				if (element.seats != null) {
					seat += Number(element.seats.code.totalFare);
				}
			}
		}
		return Number(meal) + Number(baggage) + Number(seat)
	}
	getPerPrice(data, index) {
		this.totalFare = 0
		const element = data;
		const lThis = this;
		let keys = Object.keys(element.fare.taxes[0]);
		let count = 0;
		Object.values(element.fare.taxes[0]).forEach(function (v) {
			if (keys[count] != "MARKUP" && keys[count] != "COMMISSION" && keys[count] != "TOTAL" && keys[count] != "TDS" && keys[count] != "NET_PAYABLE" &&
				keys[count] != "OTHER_TAX" && keys[count] != "AFFILIATE_MARKUP" && keys[count] != "ADMIN_SERVICE_CHARGE" && keys[count] != "GST") {
				lThis.totalFare += Number(v);
			}
			count++;
		})
		this.totalFare += Number(element.fare.base) + this.getSSRPrice(element.ssr);
		return this.totalFare;
	}
	getCancellationCharges(type) {
		let data = this.bookingData.travellers
		let charges = 0
		let travelledFee = 0
		for (let i = 0; i < data.length; i++) {
			const element = data[i];
			if (element.status == 'CANCELLED') {
				if (element.refunded_fare.cancellation_charges != null && type != 'travelled_fee') {
					let chargeArr = Object.keys(element.refunded_fare.cancellation_charges[0])
					for (let j = 0; j < chargeArr.length; j++) {
						const el = chargeArr[j];
						if (type == el) {
							if (type == 'client_raf_charges') {
								charges += element.refunded_fare.cancellation_charges[0][type]
								this.GSTonCancellation = (charges*18/100)
							}
							else{
								charges += element.refunded_fare.cancellation_charges[0][type]
							}
						}
					}
				}
				if (element.utilized_fare != undefined && element.utilized_fare != null && type == 'travelled_fee') {
					travelledFee = this.getTravelledFee(element.utilized_fare)
					travelledFee = travelledFee + Number(element.utilized_fare.base)

				}
			}
		}
		if (type != 'travelled_fee') {
			return charges
		}
		else {
			return travelledFee
		}
	}
	getTravelledFee(fare) {
		let totalFee = 0
		let keys = Object.keys(fare.taxes[0]);
		let count = 0;
		const lThis = this;
		Object.values(fare.taxes[0]).forEach(function (v) {
			if (keys[count] != "MARKUP" && keys[count] != "COMMISSION" && keys[count] != "TOTAL" && keys[count] != "TDS" && keys[count] != "NET_PAYABLE" &&
				keys[count] != "ADMIN_MARKUP" && keys[count] != "OTHER_TAX" && keys[count] != "AFFILIATE_MARKUP" && keys[count] != "ADMIN_SERVICE_CHARGE" && keys[count] != "GST") {
				totalFee += Number(v);
			}
			if (keys[count] == "OTHER_TAX" || keys[count] == "AFFILIATE_MARKUP" || keys[count] == "ADMIN_SERVICE_CHARGE") {
				totalFee += Number(v);
			}
			count++;
		})
		return totalFee
	}
	getTax(adminMarkup, affiliate_markup) {
		if (adminMarkup == undefined) {
			adminMarkup = 0
		}
		return Number(adminMarkup) + Number(affiliate_markup);
	}
	getPrice(data,type) { //FIX ME [ Calculate Comission, TDS and markup implementation ]
		this.totalFare = 0

		let charge = 0
		let admin_markup = 0
		let admin_service_charge = 0
		let comission = 0
		let tds = 0
		for (let i = 0; i < data.length; i++) {
			const element = data[i];
			const lThis = this;
			let keys = Object.keys(element.fare.taxes[0]);
			let count = 0;
			Object.values(element.fare.taxes[0]).forEach(function (v) {
				if (keys[count] != "MARKUP" && keys[count] != "COMMISSION" && keys[count] != "TOTAL" && keys[count] != "TDS" && keys[count] != "NET_PAYABLE" &&
					keys[count] != "AFFILIATE_MARKUP" && keys[count] != "GST") {
					lThis.totalFare += Number(v);
				}
				if (keys[count] == type) {
					charge += Number(v);
				}
				if (keys[count] == "COMMISSION") {
					comission += Number(v);
				}
				if (keys[count] == "TDS") {
					tds += Number(v);
				}
				count++;
			})
			this.totalFare += Number(element.fare.base) + this.getSSRPrice(element.ssr);
		}
		// this.totalFare
		this.netPayable = this.totalFare
		if (type == 'GST') {
			this.gstAmount = charge
		}
		if (type == 'TDS') {
			this.tdsAmount = charge
		}
		this.netPayable = this.totalFare + this.gstAmount
		this.netPayable = Number(this.totalFare + this.gstAmount + this.tdsAmount) - comission + tds;
		return	charge
			
	}
	getRefunded(type) {
		let data = this.bookingData.travellers
		let charges = 0
		for (let i = 0; i < data.length; i++) {
			const element = data[i];
			if (type == 'refunded') {
				charges += element.refunded_fare.refunded_total;
			}
			else if (type == 'gst') {
				if (element.refunded_fare.gst != undefined) {
					charges += element.refunded_fare.gst;
				}
			}
		}
		if (type == 'refunded') {
			charges = charges - this.getPrice(data,'ADMIN_SERVICE_CHARGE') - this.getPrice(data,'GST')
			return charges 
		}
		return charges
	}
	secondsToHms(d) {
		d = Number(d);
		var h = Math.floor(d / 3600);
		var m = Math.floor(d % 3600 / 60);
		var s = Math.floor(d % 3600 % 60);

		var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
		var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
		var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
		return hDisplay + mDisplay + sDisplay;
	}

	returnDate(date) {
		let d = new Date(date.split("+")[0]);
		return d.toDateString()
	}

	returnTime(date) {
		let d = new Date(date.split("+")[0]);
		return d.toTimeString().split(' ')[0];
	}

	checkIfNull(value) {
		if (value == undefined) {
			return '0';
		}
		else {
			return value;
		}
	}

	printTicket() {
		let searchId = this.searchId;
		window.open("/#/template/print-ticket;searchId="+searchId, "_blank");
		// this.router.navigate(['/print-ticket', { searchId }])
	}
	printInvoice() {
		let searchId = this.searchId;
		localStorage.setItem('bookingArr', JSON.stringify(this.bookingArr))
		window.open("#/template/print-invoice;searchId="+searchId, "_blank");
	}

	saveMarkup(values) {
		let perPaxMarkup = Number(values.amount) / this.bookingArr.paxRes.length;
		let addonMarkupArr = [];
		this.pricePaxArr.forEach(val => {
			let totalPrice = Number(val[1].price.total) + Number(perPaxMarkup);
			let obj = {
				'bookingId': val[1].booking_id,
				'paxId': val[1].pax_id,
				'taxName': values.showAs,
				'taxPrice': perPaxMarkup,
				'totalPrice': totalPrice
			};
			addonMarkupArr.push(obj);
		});
		this.bookingService.saveAddonMarkup(addonMarkupArr).subscribe(res => {
			if (res == 'saved') {
				this.showSuccess();
				this.editMarkUp()
				// this.getBookingDetails();
			}
		})
	}
	cancelReq(data) {
		let searchId = data[0].json_doc.booking_ref;
		this.commonService.changeDetailsSource(data)
		this.router.navigate(['/cancel-request', { searchId }]);
	}

	get showAs() {
		return this.addOnMarkupForm.controls.showAs;
	}
	get amount() {
		return this.addOnMarkupForm.controls.amount;
	}

	editMarkUp() {
		this.isAddonMarkupEnable = !this.isAddonMarkupEnable;
	}

	showSuccess() {
		window.scrollTo(0, 0);
		this.successMsg = true;
		setTimeout(() => {
			this.successMsg = false;
		}, 3000)
	}
}