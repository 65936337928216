import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { CommonService } from 'src/app/services/common.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { Router, NavigationEnd } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/public_api';
import * as moment from 'moment-timezone';
import { environment } from 'src/environments/environment';
import { TravellerService } from 'src/app/services/traveller/traveller.service';
import { PostService } from 'src/app/services/post.service';
@Component({
  selector: 'app-group-fare',
  templateUrl: './group-fare.component.html',
  styleUrls: ['./group-fare.component.scss']
})
export class GroupFareComponent implements OnInit {

 
	dataArr: any = [];
	dataSource: any = [];
	displayedColumns: any[] = ['bookingRefNo', 'pnr', 'lname', 'bDate', 'tDate', 'route', 'price', 'status', 'action']
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild('openModal') openModal: ElementRef;
	active: boolean;
	lastName;
	isRefundable: any;
	applyFilter: boolean;
	filterData: any = [];
	fromDate = "";
	toDate = "";
	lName = "";
	refNumber = "";
	searchForm: FormGroup;
	pageIndex: number = 0;
	affiliateMarkUpPrice: number = 0;
	affiliateAddOnMarkUpPrice: number = 0;
	adminMarkup: number = 0;
	netCommission: number = 0;
	totalFare: number = 0;
	netPayable: number = 0;
	navigationSubscription;
	bookingDate;
	ascSortRef: boolean = true;
	ascBookDateSort: boolean = true;
	ascJourneyDateSort: boolean = true;
	bsConfig: Partial<BsDatepickerConfig>;
	p;
	colorTheme = 'theme-purple'
	innerHeight: string;
	//Filter ids
	@ViewChild('fDate') fDate: ElementRef;
	@ViewChild('tDate') tDate: ElementRef;
	@ViewChild('ref_no') ref_no: ElementRef;
	@ViewChild('l_name') l_name: ElementRef;
	@ViewChild('pnr_no') pnr_no: ElementRef;
	showTimeError: boolean;
	cancellationWarningMessage: string;
  isLoaded: any;
	constructor(private fb: FormBuilder,
		private bookingService: BookingService,
		private commonService: CommonService,
		private router: Router,
    private postService : PostService
		) {
			this.navigationSubscription = this.router.events.subscribe((e: any) => {
				// If it is a NavigationEnd event re-initalise the component
				if (e instanceof NavigationEnd) {
					this.initialiseInvites();
				}
			});
		}
	ngOnInit() {
		this.searchForm = this.fb.group({
			'search_type': [''],
			'toDate': [''],
			'fromDate': [''],
			'travelDate': [''],
			'bookingDate': [''],
			'bookingRefNo': [''],
			'lastName': [''],
			'destination': [''],
			'number': [''],
			'status' : [''],
			'pnr' : [''],
		})
		this.showTimeError = false
		this.dataSource.paginator = this.paginator;
		this.bsConfig = Object.assign({ dateInputFormat: 'YYYY-MM-DD' }, { containerClass: this.colorTheme },{ showWeekNumbers:false })

		this.getBookings()
    this.isLoaded = true
	}
	initialiseInvites() {
    if(this.isLoaded){
      this.getBookings();

    }
	}
	ngOnDestroy() {
		// avoid memory leaks here by cleaning up after ourselves. If we  
		// don't then we will continue to run our initialiseInvites()   
		// method on every navigationEnd event.
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if (this.dataArr.length <= 3) {
			this.innerHeight = 'auto';
		}
		else {
			this.innerHeight = event.target.innerHeight - 350 + 'px';
		}
	}
	// dateFilter(fDate) {
	// 	this.fromDate = fDate;
	// 	this.checkOtherFilter(this.filterData);
	// 	console.log('FromDate', this.fromDate);
	// }

	
	getBookings() {
		let affiliate_code = JSON.parse(localStorage.getItem('user_details'))['xlAccountCode'];
		this.postService.getMethod(`${environment.baseUrl}/scrapers/group-fare?affiliate_code=${affiliate_code}`).subscribe(res => {
			let dataArr = JSON.parse(res['_body']);
			this.dataArr = dataArr.data;
			if (this.dataArr.length <= 3) {
				this.innerHeight = 'auto';
			}
			else {
				this.innerHeight = window.innerHeight - 350 + 'px';
			}
			if (localStorage.getItem('bookingSummaryPage') != undefined) {
				this.pageIndex = Number(localStorage.getItem('bookingSummaryPage'));
			}
		})
	}
	
	
	submit(values) {
		console.log(values)
		let toDate = ""
		let fromDate = ""
		
		if (values.toDate == "") {
			toDate = ""
		}
		else{
			toDate = this.bookingService.gmtDateParserTo(values.toDate)
		}
		if (values.fromDate == "") {
			fromDate = ""
		}else{
			fromDate = this.bookingService.gmtDateParserFrom(values.fromDate)
		}
		let booked_by = JSON.parse(localStorage.user_details).xlAccountCode
	
		this.postService.getMethod(`http://localhost:2221/group-fare?affiliate_code=${booked_by}`).subscribe(res => {
			let dataArr = JSON.parse(res["_body"]);
			this.dataArr = dataArr.data;
			this.filterData = dataArr.data;
			console.log('Data ARR', this.dataArr);
			this.dataSource = new MatTableDataSource(this.dataArr);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}, err => {

		})
	}

	resetFilter() {
		this.searchForm.reset();
		this.getBookings();
	}

	

	pageChanges(event) {
		localStorage.setItem('bookingSummaryPage', event.pageIndex)
	}
	checkIfNull(value) {
		if (value == undefined) {
			return ' ';
		}
		else {
			return value;
		}
	}

  getFlight(data){
    return data.flightNo.join("/")
  }

  getStatus(data){
      if(data.status == 'found'){
        let isValid = this.getIsValid(data)
        if(!isValid){
          return 'Expired'
        }
        return 'Fare Quoted'
      }
      if(data.status == 'no-found'){
        return 'Failed'
      }
      if(data.status == 'in-progress'){
        return 'In-progress'
      }
      if(data.status == 'new-request'){
        return 'New Request'
      }
  }

  getFare(data){
    if(data && data.response_json && data.response_json && data.response_json.fare){
      return data.response_json.fare.total
    }else{
      null
    }
  }

  getIsValid(data){
    if(data && data.response_json && data.response_json.validTill){
      let date = data.response_json.validTill.split("( Valid till : ")[1].split(")")[0].trim()
      return  moment(date,'DD-MMM-YYYY HH:mm').isAfter(moment())
    }
    return false
  }

  getValidity(data){
    if(data && data.response_json && data.response_json.validTill){
      return data.response_json.validTill.split("( Valid till : ")[1].split(")")[0].trim()
    }else{
      null
    }
  }

  openGroup(data){
    if(this.getIsValid(data)){
      this.router.navigate(['pages/traveller',{ ufid : data.ufid,channel_name : data.request_json.channel_name}]);	
    this.router.navigate(['pages/traveller',{ ufid : data.ufid,channel_name : data.request_json.channel_name}]);	
      this.router.navigate(['pages/traveller',{ ufid : data.ufid,channel_name : data.request_json.channel_name}]);	
    }else {

    }
  }

	
}
