import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';
import { promise } from 'protractor';

@Injectable({
	providedIn: 'root'
})
export class ExcelService {
	nonInfantPaxCount = 0;

	constructor(private commonService : CommonService) { }

	async getExcelRequest(dataObj, affMarkUp, adminMarkup, invalidCredit, selectedFare, isLcc) {
		let airlineCode = environment.supplier_code[dataObj.bookingResp.selectFlight.airline.code]
		if(airlineCode == undefined) {
			 airlineCode = await  this.getAirlineCodeFromAirlineMaster(dataObj.bookingResp.selectFlight.airline.code)
		}
		this.nonInfantPaxCount = 0;
		let airlineArr = [];
		dataObj.airlineData.forEach(el => {
			let obj = {
				"code": el.code,
				"flightNo": el.flight_number,
				"fClass": "E",
				"sec": el.departure_station_code+"/"+el.arrival_station_code
			}
			airlineArr.push(obj)
		});
		let SCODE = 'P  799';
		let invoiceType = "B";
		let creditLimit = true;
		let fareBasisCode = selectedFare['supplierInfo'] ? selectedFare['supplierInfo']['FareBasisCode']  : ""
		let dealCode = "";
		if(fareBasisCode != "") {
			fareBasisCode = fareBasisCode.replace(/[^a-zA-Z0-9 ]/g, "")
		}
		if(isLcc) {
			invoiceType = "X";
			SCODE = "",
			creditLimit = false;
			fareBasisCode =  fareBasisCode
			dealCode =    selectedFare['supplierInfo'] ? selectedFare['supplierInfo']['DEAL_CODE']  : ""
		}
		let paxArr = this.createAfterBookingPax(dataObj, selectedFare);	// create pax data for after booking request
		let userDetails = JSON.parse(localStorage.getItem('user_details'));
		let stateCode = 'OT';
		if (userDetails.state_name == 'Gujarat') {
			stateCode = 'GJ';
		}
		let crs_id = environment.crs_id[dataObj.bookingResp.selectFlight.supplierCode]
		let obj = {
			"invoiceNo": dataObj.bookingInvoice,
			"bookingDate": this.dateFormat(new Date()),
			"clientXlCode": dataObj.xlCode,
			"creditLimit": creditLimit,
			"employeeCode": "EO0000",
			"bookingRef": dataObj.bookingRefNo, //FIX ME
			"airlineCode": airlineCode,
			"remark": "",
			"supplierCode": SCODE,
			// "supplierCode": dataObj.supplier,
			"pnr": dataObj.pnrNo,
			"invoiceType": invoiceType,
			"crsId": crs_id,
			"sector": dataObj.sector,
			"travelDate": this.dateFormat(new Date(dataObj.bookingResp.selectFlight.segments[0].legs[0].departureDateTime)),
			"affMarkup": affMarkUp,
			"adminMarkup": adminMarkup,
			"airline": airlineArr,
			"pax": paxArr,
			"adminTaxableMarkup": 0,
			"state": stateCode,
			"typeOfPay": "CASH",
			"gmXlCode": "",
			"info": {
				"isCancel": false,
				"paxCount" : this.nonInfantPaxCount,
				"fareBasisCode" : fareBasisCode,
				"dealCode" : dealCode,
				"isSpecialRoundTrip" : true
			},
			"commission": this.getCommission(selectedFare, this.nonInfantPaxCount)
		}
		return obj;
	}

	getCommissionFromSaveBooking(dataObj, commissionObj) {
		let obj = {};
		if(dataObj.hasOwnProperty('commission') && commissionObj != null) {
			obj['customer'] =  commissionObj;
		}
		else{
			obj['customer'] =  {
				"iataType": "RB",
				"iataValue": 0,
				"iataAmount": 0,
				"plbType": "RB",
				"plbValue": 0,
				"plbAmount": 0,
				"cashbackType": "RB",
				"cashbackValue": 0,
				"cashbackAmount": 0,
				"supSrvCh": 0
			}
		}
		obj['supplier'] =  {
			"iataType": "RB",
			"iataValue": 0,
			"iataAmount": 0,
			"plbType": "RB",
			"plbValue": 0,
			"plbAmount": 0,
			"cashbackType": "RB",
			"cashbackValue": 0,
			"cashbackAmount": 0,
			"supSrvCh": 0
		}
		
		return obj;

	}

	getCommission(selectedFare, paxCount) {
		let obj = {};
		obj['supplier'] = {
			"iataType": "RB",
			"iataValue": 0,
			"iataAmount": 0,
			"plbType": "RB",
			"plbValue": 0,
			"plbAmount": 0,
			"cashbackType": "RB",
			"cashbackValue": 0,
			"cashbackAmount": 0,
			"supSrvCh": 0
		}
		if (selectedFare.travellerFares.ADT.supplierInfo != null && selectedFare.travellerFares.ADT.supplierInfo != undefined) {
			obj["customer"] = {
				"iataType": selectedFare.travellerFares.ADT.supplierInfo.IATA_COMMISSION_TYPE,
				"iataValue": selectedFare.travellerFares.ADT.supplierInfo.IATA_COMMISSION_VALUE,
				"iataAmount": selectedFare.travellerFares.ADT.supplierInfo.IATA_COMMISSION*paxCount,

				"plbType": selectedFare.travellerFares.ADT.supplierInfo.PLB_COMMISSION_TYPE,
				"plbValue": selectedFare.travellerFares.ADT.supplierInfo.PLB_COMMISSION_VALUE,
				"plbAmount": selectedFare.travellerFares.ADT.supplierInfo.PLB_COMMISSION*paxCount,

				"cashbackType": selectedFare.travellerFares.ADT.supplierInfo.CASHBACK_COMMISSION_TYPE,
				"cashbackValue": selectedFare.travellerFares.ADT.supplierInfo.CASHBACK_COMMISSION_VALUE,
				"cashbackAmount":selectedFare.travellerFares.ADT.supplierInfo.CASHBACK_COMMISSION,
				"supSrvCh": 0
			}
		}
		else {
			obj["customer"] = {
				"iataType": "RB",
				"iataValue": 0,
				"iataAmount": 0,
				"plbType": "RB",
				"plbValue": 0,
				"plbAmount": 0,
				"cashbackType": "RB",
				"cashbackValue": 0,
				"cashbackAmount": 0,
				"supSrvCh": 0
			}
		}
		return obj;
	}

	createAfterBookingPax(arr, selectedFare) {
		let dataArr = [];
		let count = 1;
		let passengerCount = 0;
		arr.passengerList.forEach(passVal => {
			let OT = 0;
			//Add meals in other tax 
			if (arr.meals != null) {
				arr.meals.forEach(el => {
					if (el.meals != null && el.meals[passengerCount] != undefined) {
						if (passengerCount == el.meals[passengerCount]['travellerNumber']) {
							OT += el.meals[passengerCount]['price'];
						}
					}


				})
			}
			//Add baggage in other tax		
			if (arr.baggage != null) {
				arr.baggage.forEach(el => {
					if (el.baggage != null && el.baggage[passengerCount] != undefined) {
						if (passengerCount == el.baggage[passengerCount]['travellerNumber']) {
							OT += el.baggage[passengerCount]['price'];
						}
					}


				})
			}
			//Add seats in other tax
			if (arr.seats != null) {
				arr.seats.forEach(el => {
					if (el.seats != null && el.seats[passengerCount] != undefined) {
						if (passengerCount == el.seats[passengerCount]['travellerNumber']) {
							OT += el.seats[passengerCount]['price'];
						}
					}


				})
			}
			let ticket_no = arr.bookingResp.travellers[passengerCount];
			passengerCount++;
			if (passVal.passengerType == 'Adult') {
				this.nonInfantPaxCount++;
				let YQ = selectedFare.travellerFares.ADT['fareComponents']['YQ'];
				let OC = selectedFare.travellerFares.ADT['fareComponents']['OC'];
				let YR = selectedFare.travellerFares.ADT['fareComponents']['YR'];
				OT += selectedFare.travellerFares.ADT['fareComponents']['OT'];
				if (selectedFare.travellerFares.ADT['fareComponents']['YQ'] == undefined) {
					YQ = 0;
				}
				if (selectedFare.travellerFares.ADT['fareComponents']['OC'] == undefined) {
					OC = 0;
				}
				if (selectedFare.travellerFares.ADT['fareComponents']['YR'] == undefined) {
					YR = 0;
				}
				if (selectedFare.travellerFares.ADT['fareComponents']['OT'] == undefined) {
					OT += selectedFare.travellerFares.ADT['fareComponents']['WO'];
				}
				let obj = {};
				if (OT.toFixed(2) == "NaN") {
					OT = 0;
				}
				let paxName = passVal.lastName + '/' + passVal.firstName + ' ' + passVal.title;
				obj['name'] = paxName;
				obj['ticketNo'] = ticket_no.ticketNumber
				obj['type'] = 'ADT';
				obj['sector'] = arr.sector;
				obj['Base Fare'] = selectedFare.travellerFares.ADT.base;
				obj['total'] = selectedFare.travellerFares.ADT.total;
				obj['K3'] = selectedFare.travellerFares.ADT['fareComponents']['K3'];
				obj['YQ'] = YQ;
				obj['OC'] = OC;
				obj['OT'] = OT;
				obj['YR'] = YR;
				obj['netCommission'] = selectedFare.travellerFares.ADT['fareComponents']['COMMISSION'];
				obj['tds'] = selectedFare.travellerFares.ADT['fareComponents']['TDS'];
				obj['serviceCharge1'] = selectedFare.travellerFares.ADT['fareComponents']['ADMIN_SERVICE_CHARGE'];
				obj['serviceCharge2'] = selectedFare.travellerFares.ADT['fareComponents']['ADMIN_MARKUP'];
				obj['cancellation_charges'] = null;

				dataArr.push(obj);
			}
			if (passVal.passengerType == 'Child') {
				this.nonInfantPaxCount++;
				let YQ = selectedFare.travellerFares.CHD['fareComponents']['YQ'];
				let OC = selectedFare.travellerFares.CHD['fareComponents']['OC'];
				let YR = selectedFare.travellerFares.CHD['fareComponents']['YR'];
				OT += selectedFare.travellerFares.CHD['fareComponents']['OT'];
				if (selectedFare.travellerFares.CHD['fareComponents']['YQ'] == undefined) {
					YQ = 0;
				}
				if (selectedFare.travellerFares.CHD['fareComponents']['YR'] == undefined) {
					YR = 0;
				}
				if (selectedFare.travellerFares.CHD['fareComponents']['OT'] == undefined) {
					OT += selectedFare.travellerFares.CHD['fareComponents']['WO'];
				}
				let obj = {};
				if (OT == null) {
					OT = 0;
				}
				let paxName = passVal.lastName + '/' + passVal.firstName + ' ' + passVal.title;
				obj['name'] = paxName;
				obj['ticketNo'] = ticket_no.ticketNumber
				obj['type'] = 'CHD';
				obj['sector'] = arr.sector;
				obj['Base Fare'] = selectedFare.travellerFares.CHD.base;
				obj['total'] = selectedFare.travellerFares.CHD.total;
				obj['K3'] = selectedFare.travellerFares.CHD['fareComponents']['K3'];
				obj['YQ'] = YQ;
				obj['OC'] = OC;
				obj['OT'] = OT;
				obj['YR'] = YR;
				obj['netCommission'] = selectedFare.travellerFares.CHD['fareComponents']['COMMISSION'];
				obj['tds'] = selectedFare.travellerFares.CHD['fareComponents']['TDS'];
				obj['serviceCharge1'] = selectedFare.travellerFares.CHD['fareComponents']['ADMIN_SERVICE_CHARGE'];
				obj['serviceCharge2'] = selectedFare.travellerFares.CHD['fareComponents']['ADMIN_MARKUP'];
				obj['cancellation_charges'] = null;
				dataArr.push(obj);
			}
			if (passVal.passengerType == 'Infant') {
				let obj = {};
				if (OT == null) {
					OT = 0;
				}
				let paxName = passVal.lastName + '/' + passVal.firstName + ' ' + passVal.title;
				if (passVal) {
					
				}
				obj['name'] = paxName;
				obj['ticketNo'] = ticket_no.ticketNumber
				obj['type'] = 'INF';
				obj['sector'] = arr.sector;
				obj['Base Fare'] = 0;
				obj['total'] = selectedFare.travellerFares.INF.total;
				obj['YQ'] = 0;
				obj['OC'] = 0;
				obj['K3'] = 0;
				obj['OT'] = selectedFare.travellerFares.INF.fareComponents.OT;
				obj['YR'] = 0;
				obj['netCommission'] = 0;
				obj['tds'] = 0;
				obj['serviceCharge1'] =selectedFare.travellerFares.INF['fareComponents']['ADMIN_SERVICE_CHARGE'];;
				obj['affiliateMarkup'] =  selectedFare.travellerFares.INF['fareComponents']['ADMIN_MARKUP'];
				obj['cancellation_charges'] = null;
				dataArr.push(obj);
			}
			count++;
		});
		return dataArr;
	}

	getExcelPaymentData(values,docNo,ledgerData) {
		let limitType = "CC001B";
		if(values.selDepositType == "cash") {
			limitType = "CC001A";
		}
		let obj = {
			"DOC_PRF": "RW",
			"DOC_NOS": docNo,
			"DOC_SRNO": "1",
			"IDM_FLAG": "R",
			"IL_REF": "",
			"CBCODE": "GA0331", // % Digit bank code 
			"CCODE": values.txtAgencyCode,
			"DCODE": limitType,
			"IDATE": this.dateFormat(null), //Voucher Date 
			"ADJ_DATE": this.dateFormat(null), //Adjustment Date
			"RDATE": this.dateFormat(null), //Bank Clear Date
			"Curcode": "INR",
			"R_O_E": "1",
			"LOC_CODE": "000",
			"CST_CODE": "000",
			"Chq_No": "",
			"Chq_Date": this.dateFormat(null),
			"REFR_KEY": "",
			"AMOUNT": ledgerData.amount,
			"NARR_1": values.transactionRef,
			"NARR_2": ledgerData.narration_1,
			"NARR_3": ledgerData.narration_2,
			"NARR_4": "",
			"NARR_5": "",
			"Bank_Dtls": ""
		}
		return obj;
	}

	

	dateFormat(date) {
		if(date == null) {
			date = new Date();
		}
		let month = (date.getMonth()+1).toString();
		let ddate = (date.getDate()).toString();
		if((date.getDate()).toString().length == 1) {
			ddate = "0"+(date.getDate()).toString();
		}
		if((date.getMonth()+1).toString().length == 1) {
			month = "0"+(date.getMonth()+1).toString();
		}
		return ddate+ "/" +month + "/" +date.getFullYear()
	}
	getAirlineCodeFromAirlineMaster(code) {
		return new Promise((resolve, reject) => {
			this.commonService.getAirline(`airline`).subscribe(res => {
				let response = JSON.parse(res['_body']);
				response.forEach(el=>{ 
					if(code == el["Airline 2LTR"]) { 
						resolve(el['xl code'])
					}
				})
				// resolve();
			}, error => {
				resolve();
			});
		})
	}



	

	async getHoldExcelRequest(dataObj,commissionObj,affMarkUp,adminMarkup,isCancel,affiliateData, totalPax,isLcc) {
		 
		let supplierCodeConfig = {
			"SG": "SG050",
			"SPICEJET": "SG050",
			"6E": "6E670",
			"G8": "GO008",
			"AI": "AI098",
			"UK": "UK228",
			"SG_COUPON" : "SG052",
			"SG_CORPORATE" : "SG051"
			
		}
		let airlineCode = environment.supplier_code[dataObj.segments[0].legs[0].marketing_airline_code]
		if (dataObj.segments[0].legs[0].marketing_airline_code == '6E') {
			airlineCode = "6E670"
		}
		if (dataObj.segments[0].legs[0].marketing_airline_code == 'SG') {
			airlineCode = supplierCodeConfig[dataObj.segments[0]['booking_supplier']]
			if (airlineCode == undefined) {
				airlineCode = "SG050"
			}
			
		}
		if (dataObj.segments[0].legs[0].marketing_airline_code == 'G8'){
			airlineCode = "GO008"
		}
		if (dataObj.segments[0].legs[0].marketing_airline_code == 'G9') {
			airlineCode = "G9514"
		}
		if (dataObj.segments[0].legs[0].marketing_airline_code == 'AI') {
			airlineCode = "AI098"
		}
		
		if(airlineCode != null || airlineCode != undefined) {
		}
		else{
			airlineCode = await  this.getAirlineCodeFromAirlineMaster(dataObj.segments[0].legs[0].marketing_airline_code)
		}
		let airlineArr = [];
		dataObj.segments[0].legs.forEach(el => {
			let obj = {
				"code": el.operating_airline_code,
				"flightNo": el.flight_number,
				"fClass": "E",
				"sec": el.origin_airport_code+"/"+el.destination_airport_code
			}
			airlineArr.push(obj)
		});
		let SCODE = 'P  799';
		let invoiceType = "B";
		let creditLimit = true;
		let fareBasisCode = dataObj['supplierInfo'] ? dataObj['supplierInfo']['FareBasisCode']  : ""
		let dealCode = "";
		if(fareBasisCode != "") {
			fareBasisCode = fareBasisCode.replace(/[^a-zA-Z0-9 ]/g, "")
		}
		if(isLcc) {
			invoiceType = "X";
			SCODE = "",
			creditLimit = false;
			fareBasisCode =  fareBasisCode
			dealCode =    dataObj['supplierInfo'] ? dataObj['supplierInfo']['DEAL_CODE']  : ""
		}
		let code ={}

		if(localStorage.user_details != undefined) {
			if(JSON.parse(localStorage.user_details)['is_di_account'] != null) {
				if((JSON.parse(localStorage.user_details)['is_di_account'] == 'true')) {
					creditLimit = false;
				}
			}
		}
		
		let uniqueSector  = this.createSectors(dataObj.segments[0].legs)
		let paxArr = this.createTravellerForHoldBooking(dataObj,uniqueSector);	// create pax data for after booking request
		// let userDetails = JSON.parse(localStorage.getItem('user_details'));
		let stateCode = 'OT';
		if (affiliateData.state_name == 'Gujarat') {
			stateCode = 'GJ';
		}
		let crs_id = "";
		if(dataObj['supplierInfo'] != undefined) {
			if(dataObj['supplierInfo']['SUPPLIER'] != undefined) {
				crs_id = environment.crs_id[dataObj['supplierInfo']['SUPPLIER']]
			}
		}
		// let removeSpecialCharacterRejex = .replace(/[^a-zA-Z0-9 ]/g, "")
		let obj = {
			"invoiceNo": dataObj.invoice_no,
			"bookingDate": this.dateFormat(new Date()),
			"info" : {
				"isCancel" : isCancel,
				"paxCount" : totalPax,
				"fareBasisCode" :fareBasisCode,
				"dealCode" :dealCode,
				"isSpecialRoundTrip" : false
			},
			"clientXlCode": dataObj.xl_code,
			"creditLimit": creditLimit,
			"employeeCode": "EO0000",
			"bookingRef": dataObj.booking_ref,
			"airlineCode": airlineCode,
			"remark": "",
			"supplierCode": SCODE,
			// "supplierCode": dataObj.supplier, //FIX ME for supplier code
			"pnr": dataObj.segments[0].pnr,
			"invoiceType": invoiceType,
			"crsId": crs_id,
			"sector": uniqueSector,
			"travelDate": this.dateFormat(new Date(dataObj.segments[0].legs[0].origin_timestamp)), 
			"affMarkup": affMarkUp,
			"adminMarkup": adminMarkup,
			"airline": airlineArr,
			"pax": paxArr,
			"adminTaxableMarkup": 0,
			"state": stateCode,
			"typeOfPay": "CASH",
			"gmXlCode": "",
			"commission": this.getCommissionFromSaveBooking(dataObj, commissionObj)
			
		}
		return obj;
	}

	createSectors(legs) {
		// let selectedFlight = JSON.parse(localStorage.getItem('selectedFlight'));
		// let legs = selectedFlight.segments[0].legs;
		let sectorArr = [];
		legs.forEach(element => {
			sectorArr.push(element['origin_airport_code']);
			sectorArr.push(element['destination_airport_code']);
		})
		let arr = sectorArr;
		let uniqueArr = [];
		arr.forEach(el => {
			if (uniqueArr.indexOf(el) < 0) {
				uniqueArr.push(el)
			}
		});
		return uniqueArr.join('/');
	}

	createTravellerForHoldBooking(arr,sector) {
		let dataArr = [];
		let count = 1;
		arr.travellers.forEach(passVal => {
			if (passVal.ticket==null) {
				passVal.ticket ={}
				passVal.ticket['number'] = arr.segments[0].pnr+"-"+count
			}
			let tax ;
			let totalPrice 
			if (passVal.refunded_fare != undefined) {
				tax  = passVal.refunded_fare.taxes[0]
				totalPrice  = Number(passVal.refunded_fare.base)+Number(tax.YQ)+Number(tax.K3)+this.getOT(passVal)
			}
			else{
				tax  = passVal.fare.taxes[0]
				totalPrice  = Number(passVal.fare.base)+Number(tax.YQ)+Number(tax.K3)+this.getOT(passVal)
			}
				let obj = {};
				let YQ = 0;
				let YR = 0;
				let fare = passVal.fare
				if (passVal.refunded_fare !=undefined) {
					fare = passVal.refunded_fare 
				}
				// let paxName = passVal.last_name + '/' + passVal.first_name + ' ' + passVal.title;
				if (tax.YQ == undefined) {
					YQ =0
				}
				else{
					YQ = tax.YQ
				}
				if (tax.YR == undefined) {
					YR =0
				}
				else{
					YR = tax.YR
				}
				let paxName = passVal.last_name + '/' + passVal.first_name + ' ' + passVal.title;
				obj['name'] = paxName;
				obj['ticketNo'] =  passVal.ticket.number;
				obj['type'] = passVal.type;
				obj['sector'] = sector;
				obj['Base Fare'] = fare.base;
				obj['total'] = totalPrice;
				obj['YQ'] = YQ;
				obj['YR'] = YR;
				obj['OC'] = tax.OC;
				obj['K3'] = tax.K3;
				obj['OT'] = this.getOT(passVal);
				obj['netCommission'] = tax.COMMISSION;
				obj['tds'] = tax.TDS;
				obj['adminMarkup'] = tax.ADMIN_MARKUP;
				obj['affiliateMarkup'] = 0;
				obj['cancellation_charges']  =fare.cancellation_charges
				obj['serviceCharge1'] = 0;
				obj['serviceCharge2'] = 0;
				obj['utilized_amount']  = fare.cancellation_charges
				dataArr.push(obj);
			count++;
		});
		return dataArr;
	}
	getOT(data) {
		let otherTax = 0
		const element = data;
		const lThis = this;
		let keys;
		let tax ;
		if (data.refunded_fare != undefined) {
			keys = Object.keys(element.refunded_fare.taxes[0]);
			tax  = data.refunded_fare.taxes[0]
		}
		else{
			keys = Object.keys(element.fare.taxes[0]);
			tax  = data.fare.taxes[0]
		}
		let count = 0;
		Object.values(tax).forEach(function (v) {
			if (keys[count] == "OTHER_TAX" || keys[count] == "OT") {
				otherTax += Number(v);
			}
			count++;
		})
		otherTax +=  this.getSSRPrice(element.ssr);
		return otherTax;
	}
	getSSRPrice(ssr) {
		let meal = 0;
		let baggage = 0;
		let seat = 0;
		if (ssr != null) {
			for (let i = 0; i < ssr.length; i++) {
				const element = ssr[i];
				if (element.meals != null && element.meals.code != null) {
					meal += Number(this.checkifNull(element.meals.code.totalFare));
				}
				if (element.baggages != null && element.baggages.code != null) {
					baggage += Number(this.checkifNull(element.baggages.code.totalFare));
				}
				if (element.seats != null && element.seats.code != null) {
					seat += Number(this.checkifNull(element.seats.code.totalFare));
				}
			}
		}
		return Number(meal) + Number(baggage) + Number(seat)
	}
	checkifNull(value){
		if (value == undefined) {
			return 0
		}
		else{
			return value
		}
	}
}
