import { Component, OnInit } from '@angular/core';
import { DateFormatPipe } from 'src/app/shared/pipes/date-format.pipe';
import { CommonService } from 'src/app/services/common.service';
import { AffiliateService } from 'src/app/services/affiliate.service';

@Component({
  selector: 'app-offline-receipt',
  templateUrl: './offline-receipt.component.html',
  styleUrls: ['./offline-receipt.component.scss']
})
export class OfflineReceiptComponent implements OnInit {
  receiptData: any;
	accountHead: any;
	description: any;
	narration_1: any;
	narration_2: any;
	narration_3: any;
	narration_4: any;
	narration_5: any;
	amount: any;
	transactionRefNo: any;
	date: string;
	receiptType: string;
	receiptSubType: string;
	showBox: boolean = false;
	lengthError: boolean;
	status: any;
	selectedAffiliate: any;
	searchForm: any;
	affiliateList: any;
	error: boolean;
	allselectAll = "";
	affiliate_detail: string;
	message: string;
	generalLedger: any;
	selectedBank: any;
	paymentReciept: any;
	showSuccess: boolean = false;
	messageError: string;
	showError: boolean;
	messageSuccess: string;
	affiliateId: string;
	valid: boolean;
  userDetail: any;
  constructor(
    private commonService:CommonService,
    private affiliateService : AffiliateService
  ) { }

  ngOnInit() {
    const transactionID = window.location.hash.split('?')[1].split('&')[0].split('=')[1];
		const affiliateId = window.location.hash.split('?')[1].split('&')[3].split('=')[1];
		this.affiliateId = window.location.hash.split('?')[1].split('&')[3].split('=')[1];
		this.receiptType = window.location.hash.split('?')[1].split('&')[1].split('=')[1];
		this.getReceipt(transactionID,affiliateId)
    
  }
  getReceipt(transactionID,affiliateId){
		this.receiptData = {}
		if (window.location.hash.split('?')[1].split('&')[2] != undefined) {
			this.receiptSubType = window.location.hash.split('?')[1].split('&')[2].split('=')[1];
			if(this.receiptSubType != undefined && this.receiptSubType != ""){
				this.receiptSubType = this.receiptSubType.charAt(0).toLocaleUpperCase() + this.receiptSubType.slice(1);
			}
		}
		// console.log('transactionID',transactionID);
		// console.log(this.receiptType);
		// console.log(this.receiptSubType);

		let id = transactionID.split('-')[1];
		if(transactionID.includes("MI")) {
			this.getDepositIncentive(id, transactionID,affiliateId);
		}
		else if(transactionID.includes("GI")) {
			this.getGIAccount(id, transactionID,affiliateId);
		}
		else if(transactionID.includes("JW")) {
			this.getCreditDebitNote(id, transactionID,affiliateId);
		}
		else if(transactionID.includes("RW") || transactionID.includes("MS")) {
			this.getPaymentReceipt(id, transactionID,affiliateId);
		}
		else if(transactionID.includes('IR') || transactionID.includes('AD') || transactionID.includes('IS') || transactionID.includes('IM') || transactionID.includes('AT') || transactionID.includes('PY') ) {
			this.getPaymentReceipt(id, transactionID,affiliateId);
		}
  }
  getGIAccount(id, transactionID,affiliateId) {
		this.commonService.getBooking(`gstClaimDetail/getGiAccountDetail?invoice_no=${id}&affiliate_id=${affiliateId}`).subscribe(res => {
			this.valid = true;
			this.receiptData = JSON.parse(res['_body'])["data"][0];
			console.log('Receipt GI', this.receiptData);
			this.transactionRefNo = transactionID;
			this.affiliate_detail = this.receiptData.affiliate_name;
			// this.description = this.receiptData.description;
			this.narration_1 = this.checkIfUndefined(this.receiptData.affiliate_name);
			this.narration_2 = this.checkIfUndefined(this.receiptData.general_ledger_xl_code);
			this.narration_3 = this.checkIfUndefined(this.receiptData.gst_input_credit_for_month);
			// this.narration_4 = this.checkIfUndefined(this.receiptData.narration_4);
			// this.narration_5 = this.checkIfUndefined(this.receiptData.narration_5);
			this.amount = this.receiptData.net_amount;
			// this.date = this.dateFormat(new Date(this.receiptData.date));
			this.date = new DateFormatPipe().transform(this.receiptData.date)
			// console.log("date is ====>>", this.date);
		},
		err => {
			this.valid = true;
			// alert('No Receipt Found with transaction id :'+transactionID)
		})
	}

	getDepositIncentive(id, transactionID,affiliateId) {
		this.commonService.getBooking(`depositIncentive/getDepositIncentive?invoice_no=${id}&affiliate_id=${affiliateId}`).subscribe(res => {
			this.valid = true;
			this.receiptData = JSON.parse(res['_body'])["data"][0];
			console.log('Receipt MI', this.receiptData);
			this.transactionRefNo = transactionID;
			this.affiliate_detail = this.receiptData.affiliate_name;
			// this.description = this.receiptData.description;
			this.narration_1 = this.checkIfUndefined(this.receiptData.narration_1);
			this.narration_2 = this.checkIfUndefined(this.receiptData.narration_2);
			this.narration_3 = this.checkIfUndefined(this.receiptData.narration_3);
			this.narration_4 = this.checkIfUndefined(this.receiptData.narration_4);
			this.narration_5 = this.checkIfUndefined(this.receiptData.narration_5);
			this.amount = this.receiptData.net_amount;

			// this.date = this.dateFormat(new Date(this.receiptData.receipt_date));
      this.date = new DateFormatPipe().transform(this.receiptData.receipt_date)
			// console.log("date is ====>>", this.date);

		},
		err => {
			this.valid = true;
			// alert('No Receipt Found with transaction id :'+transactionID)
		})
	}

	getCreditDebitNote(id, transactionID,affiliateId) {
		this.commonService.getBooking(`creditDebitNote/getCreditDebitNoteByTransactionRefNo?transactionRefNo=${id}&affiliate_id=${affiliateId}`).subscribe(res => {
			this.valid = true;
			this.receiptData = JSON.parse(res['_body'])[0];
			console.log('Receipt JV', this.receiptData);
			this.transactionRefNo = transactionID;
			this.accountHead = this.receiptData.account_head;
			this.description = this.receiptData.description;
			this.narration_1 = this.checkIfUndefined(this.receiptData.remark_narration_1);
			this.narration_2 = this.checkIfUndefined(this.receiptData.remark_narration_2);
			this.narration_3 = this.checkIfUndefined(this.receiptData.remark_narration_3);
			this.narration_4 = this.checkIfUndefined(this.receiptData.remark_narration_4);
			this.narration_5 = this.checkIfUndefined(this.receiptData.remark_narration_5);
			this.amount = this.receiptData.amount;

      // this.date = this.dateFormat(new Date(this.receiptData.date_generated));
      this.date = new DateFormatPipe().transform(this.receiptData.date_generated)

			// console.log("date is ====>>", this.date);

		},
		err => {
			this.valid = true;
			// alert('No Receipt Found with transaction id :'+transactionID)
		})
	}
	getPaymentReceipt(id, transactionID,affiliateId){
		console.log('t-id',transactionID)
		// return false
		this.commonService.getAccountValue(`ledger/getAffiliateLedgerbyinvoice?invoice=${transactionID}&affiliate_id=${affiliateId}`).subscribe(res => {
			this.valid = true;
			this.receiptData = JSON.parse(res['_body'])[0];
			 
			console.log('Receipt RW', this.receiptData);
			this.getLoginAffiliateDetails();
			this.transactionRefNo = this.receiptData['transaction_ref_no']	
		
			this.narration_1 = this.checkIfUndefined(this.receiptData.narration_1);
			this.narration_2 = this.checkIfUndefined(this.receiptData.narration_2);
			this.narration_3 = this.checkIfUndefined(this.receiptData.narration_3);
			this.narration_4 = this.checkIfUndefined(this.receiptData.narration_4);
			this.narration_5 = this.checkIfUndefined(this.receiptData.narration_5);
			this.amount = this.receiptData.amount;

      // this.date = this.dateFormat(new Date(this.receiptData.timestamph));
      this.date = new DateFormatPipe().transform(this.receiptData.timestamph)

			// console.log("date is ====>>", this.date);

		},
		err => {
			this.valid = true;
			// alert('No Receipt Found with transaction id :'+transactionID)
		})
	}
	
  checkIfUndefined(value) {
		if (value != undefined) {
			return value
		}
		else {
			return null
		}
  }
  getLoginAffiliateDetails() {
		this.affiliateService.getAffiliateDetails().subscribe(res => {
			console.log('affiliate details', res)
			this.userDetail = res[0];
		})
	}


}
