import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-re-attemp-meal',
  templateUrl: './re-attemp-meal.component.html',
  styleUrls: ['./re-attemp-meal.component.scss']
})
export class ReAttempMealComponent implements OnInit {
  @Input() ssrAvailability;
  @Output() callMealEvent : EventEmitter<any> = new EventEmitter();
  status : boolean = false;
  constructor() { }

  ngOnInit() {
  }

  toggleEvent() {
		this.status = false;
		let eventValue = event.currentTarget['parentElement']['nextElementSibling']['className'];
		if(event.currentTarget['parentElement']['nextElementSibling']['className'].includes('hide')) {
			this.status = false;
			event.currentTarget['parentElement']['nextElementSibling']['className'] = "meal-dropdown show";
		}
		else{
			event.currentTarget['parentElement']['nextElementSibling']['className'] = "meal-dropdown hide";
		}
		
	}

	changeMeal(meal, leg, pax, isRemove){
		const tempArr = Object.assign({},  this.ssrAvailability[leg])
		let selectedMeal = meal;
		if(isRemove) {
			event.currentTarget['parentElement']['parentElement']['parentElement']['firstElementChild']['firstElementChild'].value  = 'No Meal';
			this.ssrAvailability[leg].travellers[pax]['meal'] = undefined;
			this.makeSeatObject(pax, null, true, 0, leg,tempArr,null);
			event.currentTarget['parentElement'].parentElement.className = "meal-dropdown hide";
		}
		else{ 
			this.ssrAvailability[leg].travellers[pax]['meal'] = selectedMeal.name;		
			this.ssrAvailability[leg].travellers[pax]['mealType'] = selectedMeal.type;		
			if(selectedMeal.name == null) {
				this.ssrAvailability[leg].travellers[pax]['meal'] = selectedMeal.code;
			}
			this.ssrAvailability[leg].travellers[pax]['mealFare'] = selectedMeal.totalFare;
			this.makeSeatObject(pax, selectedMeal.code, false, selectedMeal.totalFare, leg,tempArr,meal)
			event.currentTarget['parentElement']['parentElement']['parentElement']['parentElement']['firstElementChild']['firstElementChild'].value  = selectedMeal.name + " "+selectedMeal.code;
			event.currentTarget['parentElement'].parentElement.parentElement.className = "meal-dropdown hide";

		
		}	
		
	}
	makeSeatObject(passengerIndex, seatNumber, isRemove, price, leg,tempArr,meal){
		 ;
		let flag = true;
		if(tempArr.flightInfo.ssrs[leg].meals != undefined && tempArr.flightInfo.ssrs[leg].meals != null) {
			tempArr.flightInfo.ssrs[leg].meals = tempArr.flightInfo.ssrs[leg].meals.filter(obj =>{
				if(isRemove) {
					flag = false;
					if(obj.travellerNumber != passengerIndex) {
						return obj;
					}
				}
				else{
					if(obj.travellerNumber == passengerIndex) {
						obj['code'] = seatNumber;
						obj['name'] = meal['name'];
						obj['price'] = price;
						obj['item'] = meal;
						flag = false;
					}
					return obj;
				}
				
			});
		}
		
		if(flag) {
			let obj = {
				"code": seatNumber,
				"name": meal['name'],
				"travellerNumber": passengerIndex,
				"item" : meal,
				"price" : price
			}
			if(tempArr.flightInfo.ssrs[leg].meals == undefined || tempArr.flightInfo.ssrs[leg].meals == null) {
				tempArr.flightInfo.ssrs[leg].meals = [];				
			}
			tempArr.flightInfo.ssrs[leg].meals.push(obj)
		}
		console.log(tempArr.flightInfo.ssrs);
		this.callMealEvent.emit(tempArr.flightInfo.ssrs);
	}

}
