import { ActionTypes } from './actions';

export const initialState = {
  showOfferPrice : false
};

export function flightReducers(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_OFFER_VISIBLE:
      console.log('SET_OFFER_VISIBLE : ',action)
      return {
        ...state,
        showOfferPrice : action.payload
      }
    default:
      return state;
  }
}



