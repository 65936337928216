
<div class="container-fluid mt-5" *ngIf="dataArr.length == 0" >
  <div class="text-center mt-5">
    <img src="../../../assets/v3images/error-illustration.svg" alt="" width="400px" class="mt-3">
      <h4 class="text-pinkish-red text-bold">No Result Found</h4>
  </div>
</div>
<div class="clearfix"></div>
<div class="   container-fluid" *ngIf="dataArr.length > 0">
  <h3 class="text-center mt-3 text-dark-green">Group fare </h3>
  <div class="mobile-scroll-table custom-table pt-2 card mt-2">
      <table class="table ">
          <thead  >
              <tr>
                  <th class="text-left" style="  padding-right: 20px !important;">Group</th>
                  <th class="text-left">Sector</th>
                  <th class="text-left">Flight</th>
                  <th class="text-left">Journey</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Amount Quoted</th>
                  <th class="text-right">Valid Till</th>
                  <th class="text-right">Created On</th>
                  <th class="text-right">Updated on</th>
                  <!-- <th>Action</th> -->
              </tr>
          </thead>
          <tbody class="custom-table-body" [ngStyle]="{height: innerHeight}">
              <tr class="custom-table-row"
                  *ngFor="let element of dataArr | paginate: { itemsPerPage: 50, currentPage: p };">
                  <td class="text-left"  style= "padding-right: 20px !important;"><span><a class="text-pinkish-red"
                              (click)="openGroup(element)">{{element.group_name}}</a></span></td>
                  <td class="text-left">{{element.request_json?.fromCity}}-{{element.request_json?.toCity}}</td>
                  <td class="text-left">{{element.request_json?.airlineCode}}-{{getFlight(element.request_json)}}</td>
                  <td class="text-left">{{element.request_json?.jouneyDate | date : 'dd/MM/yyyy'}}</td>
                  <td class="text-left">{{getStatus(element)}}</td>
                  <td class="text-left"> <ng-container *ngIf="getFare(element) != null">
                    &#8377; {{getFare(element)}}
                  </ng-container></td>
                  <td class="text-right">
                    <ng-container *ngIf="getValidity(element) != null">{{getValidity(element) | date : 'dd/MM/yyy HH:mm'}}</ng-container>
                  </td>
                  <td class="text-right">{{element.created_on | date : 'dd/MM/yyyy HH:mm' }}</td>
                  <td class="text-right">{{element.updated_on | date : 'dd/MM/yyyy HH:mm' }}</td>
                  <!-- <td></td> -->
                  
              </tr>
          </tbody>
      </table>
  </div>
  <div class=" text-center mt-2 mb-5   card pl-2 pr-2 pt-1 pb-1  ">
      <pagination-controls (pageChange)="p = $event" *ngIf="dataArr != ''"></pagination-controls>
  </div>
  <div class="clear-fix"></div>
</div>
<button type="button" #openModal hidden class="btn btn-info btn-lg d-none" data-toggle="modal"
  data-target="#myModal"></button>

<div id="myModal" class="modal fade" role="dialog">
  <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
          <div class="warn-icon"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></div>
          <!-- <div class="warning-con">
              The cancellation request service is only available on Mon-Sat between <strong>08:00 AM</strong> to
              <strong>08:00 PM</strong> and before <strong>4 hrs</strong> from journey date.
          </div> -->
          <div class="warning-con" [innerHtml]="cancellationWarningMessage"></div>
          <div class="modal-footer float-center">
              <button type="button" class="btn btn-red btn-close" data-dismiss="modal">Close</button>
          </div>
      </div>

  </div>
</div>