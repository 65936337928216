<div class="seat-container" [@.disabled]="true">
  <mat-tab-group>
      <ng-container *ngFor="let item of seatMapArr; let k = index;">
          <mat-tab label="{{item.flightCode}}-{{item.flightNo}} ({{item.departure}} - {{item.arrival}})">
              <div class="">
                  <div class="option2" style="width: 690px;overflow-x:scroll;">
                      <div class="seatmap2 row ml-0 mr-0" *ngFor="let key of item['keys']; let i = index">
                          <div class="seatrow2" *ngFor="let key of item['values'][i]; let j = index">
                              <div *ngIf="key.type == 'Reserved'" class="seat2 seat2-occupied">{{key['number']}}</div>
                              <div *ngIf="key.type == 'Restricted' || key.type == 'Unavailable'" class="seat2 seat2-unavailable">{{key['number']}}
                              </div>
                              <!-- CheckedIn -->
                              <div *ngIf="key.type == 'CheckedIn'" class="seat2 seat2-checked">{{key['number']}}
                              </div>
                              <div *ngIf="key.type == 'FleetBlocked'" class="seat2 seat2-occupied">{{key['number']}}
                              </div>
                              <div *ngIf="key.type == 'selected'" class="seat2 seat2-available">{{key['number']}}
                              </div>
                              <!-- {{key?.displayAttribute?.EXITROW}} -->
                              <div *ngIf="key.type == 'Open' && key?.displayAttribute?.EXITROW == 'True'" style="cursor: pointer;" (click)="selectSeat(key,item,k)" class="seat2 seat2-exit-seat" title=" &#8377; {{key.totalFare}}">{{key['number']}}</div>
                              <!-- displayAttribute  EXITROW -->
                              <!-- Unavailable -->
                              <!-- HeldForAnotherSession  Need to show some terms and condition-->
                              <div *ngIf="(key.type == 'Open' || key.type == 'HeldForAnotherSession') && key?.displayAttribute?.EXITROW != 'True'" style="cursor: pointer;" (click)="selectSeat(key,item,k)" class="seat2" title=" &#8377; {{key.totalFare}}">{{key['number']}}</div>
                          </div>
                          <ng-container *ngIf="item.keys.length/2 == i+1">
                              <ul>
                                  <li *ngFor="let key of item['values'][i]; let j = index">
                                      <label class="ml-4 fa-12p"></label>
                                  </li>
                              </ul>
                          </ng-container>
                      </div>
                      <div class="clearfix"></div>
                  </div>


                  <div class="seat2-options mb-2">
                      <div class="legends"><span>Legends</span></div>
                      <div class="d-flex justify-content-center legend-map mb-4">
                          <span class="col pl-0 float-left"><span class="seat2-selected seat2-style cursor-unset"></span><span class="legend-name">Available
                              Seats</span></span>
                          <span class="col pl-0 float-left"><span class="seat2-available seat2-style cursor-unset"></span><span class="legend-name">Selected
                                  Seats</span></span>
                          <span class="col pl-0 float-left"><span class="seat2-norecline seat2-style cursor-unset"></span><span class="legend-name">Non Reclining
                                          Seats</span></span>
                          <!-- <span class="col float-left"><span style="opacity: 0.4"
                                  class="seat2-unavailable seat2-style cursor-unset">X</span><span>Unavailable Seats</span></span> -->
                          <!-- <span class="col float-left"><span class="seat2-limited seat2-style cursor-unset"></span><span>Limited
                                  Seats</span></span>
                          <span class="col float-left"><span class="seat2-comfort seat2-style cursor-unset"></span><span>Comfort
                                  Seats</span></span> -->
                          <!-- <span class="col float-left"><span class="seat2-checked seat2-style cursor-unset"></span><span>Checked-in
                                  Seats</span></span> -->
                          <span class="col pl-0 float-left"><span class="seat2-occupied seat2-style cursor-unset"></span><span class="legend-name">Occupied
                                          Seats</span></span>
                          <span class="col pl-0 pr-0 float-left"><span class="seat2-exit-seat seat2-style cursor-unset"></span><span class="legend-name">Emergency Exit
                                  Seats</span></span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <ng-container *ngFor="let pass of item.travellers; let l = index">
                      <div class="col-12 selectedSeat selected-passenger clearfix m-1" *ngIf="pass?.isSelected">
                          <ng-container *ngIf="pass.type != 'INF'">
                              <span class="passName" (click)="selectPassenger(item.travellers,l)">
                                  <p style="text-transform: uppercase; font-size: 12px;">{{pass.title}} {{pass.firstName}}
                                      {{pass.lastName}}</p>
                              </span>
                              <div class="seatPos p-2" *ngIf="pass.seat !=undefined">
                                  <span class="mr-2 font-size: 12px;">{{pass.seat}} &nbsp;</span>
                                  <span class="font-size: 12px;">&#8377; {{pass.totalFare}}</span>
                                  <div class="pl-2 d-inline-block float-right" (click)="removeSeat(pass.seat,item,k,l)">
                                      <div class="delSeat"><img src="../../../../assets/images/x-button.png" alt="">
                                      </div>
                                  </div>
                              </div>
                          </ng-container>
                      </div>
                      <div class="col-12 selectedSeat clearfix m-1" *ngIf="!pass?.isSelected">
                          <ng-container *ngIf="pass.type != 'INF'">
                              <span class="passName" (click)="selectPassenger(item.travellers,l)">
                                  <p style="text-transform: uppercase; font-size: 12px;">{{pass.title}} {{pass.firstName}}
                                      {{pass.lastName}}</p>
                              </span>
                              <div class="seatPos p-2" *ngIf="pass.seat !=undefined">
                                  <span class="mr-2 font-size: 12px;">{{pass.seat}} &nbsp;</span>
                                  <span class="font-size: 12px;">&#8377; {{pass.totalFare}}</span>
                                  <div class="pl-2 d-inline-block float-right" (click)="removeSeat(pass.seat,item,k,l)">
                                      <div class="delSeat"><img src="../../../../assets/images/x-button.png" alt="">
                                      </div>
                                  </div>
                              </div>
                          </ng-container>
                      </div>
                  </ng-container>

              </div>
          </mat-tab>
      </ng-container>
  </mat-tab-group>

</div>