import { Component, OnInit } from '@angular/core';
import { environmentConstant } from '../Utility/environmentConstant';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {
  homeSlider: any = environmentConstant.homePageSlider;
  flightSlider: any = environmentConstant.flightSlider;
  isHeader = true;
  isLogin = false
  constructor() { 
    if(window.location.href.includes('template')) {
			this.isHeader = false;
		}
		else{
			this.isHeader = true;
		}
  }

  ngOnInit() {
    if(JSON.parse(localStorage.getItem('user_details'))!= undefined) {
			this.isLogin = true
		}else{
			this.isLogin = false
		}
  }

}
