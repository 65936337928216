import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoginComponent } from 'src/app/Login/login.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  requestEmail: FormGroup;
  emailId;
  successMsg;

  constructor(public dialogRef: MatDialogRef<LoginComponent>, private fb : FormBuilder,
    private router : Router ,private airAffiliate : AffiliateService) { }

  ngOnInit() {
    this.requestEmail = this.fb.group({
      "email": ['',Validators.required]
    });
  }

  get email() {
    return this.requestEmail.controls.email;
  }

  requestReset(values){
    var obj = {
      "email" : values.email,
      "type" : "affiliate",
    }
    
    this.airAffiliate.createRequest(obj).subscribe(
      res => {
		console.log(res);
    const localThis = this;
    localThis.showSuccess();
    localThis.requestEmail.reset();
    setTimeout(()=>{
    },3000)
      },
      err => {
        console.log(err);
	  }
    );
    
  }

  showSuccess() {
		window.scrollTo(0, 0);
		this.successMsg = true;
		setTimeout(() => {
      this.dialogRef.close();
			this.successMsg = false;
    }, 3000)
  }

}
