import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-addon-pricing',
  templateUrl: './addon-pricing.component.html',
  styleUrls: ['./addon-pricing.component.scss']
})
export class AddonPricingComponent implements OnInit {
  @Input() seatAmount
  @Input() mealAmount
  @Input() baggageAmount
  @Input() additionalBaggageAmount
  @Input() ancillaryAmount
  @Input() totalUpdatedAmount
  @Input() totalAddOnPrice
  
  constructor() { }

  ngOnInit() {
  }

}
