import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
	selector: 'app-cancel-request',
	templateUrl: './cancel-request.component.html',
	styleUrls: ['./cancel-request.component.scss']
})
export class CancelRequestComponent implements OnInit {
	@ViewChild('closeactiveBtn') closeactiveBtn: ElementRef;
	bookingArr: any;
	bookingData: any;
	searchId: string;
	sector: string;
	affiliateMarkUpPrice: number = 0;
	affiliateAddOnMarkUpPrice: number = 0;
	adminMarkup: number = 0;
	netCommission: number = 0;
	totalFare: number = 0;
	netPayable: number = 0;
	valid: boolean = false;
	showOtherReason: boolean = false;
	canProceed: boolean = false;
	showSelectCancelReason: boolean = false;
	messageList: { status: boolean; messageList: any[]; };
	selectedTravellers;
	sectorMap = new Map();
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private commonService: CommonService,
		private bookingService: BookingService,
		private notificationService : NotificationService
	) { }

	ngOnInit() {
		this.valid = false
		this.messageList = this.notificationService.messageObj
		let searchId = this.route.snapshot.params['searchId'];
		this.commonService.getBooking('booking/getBookingWithRef?booking_ref=' + searchId).subscribe(res => {
			let data = JSON.parse(res["_body"])['data']
			this.bookingData = data[0]['json_doc'];
			this.bookingArr = data[0]
			if (this.bookingData.pnr_status == 'TICKETED') {
				this.setLegs(this.bookingData.travellers, this.bookingData.segments)
			}
			this.createSectorMap(this.bookingData.segments)
			this.valid = true
		})
		console.log(searchId)
		this.searchId = searchId;
	}
	createSectorMap(segments) {
		for (const segment of segments) {
			let origin = segment.legs[0].origin_airport_code
			let destination = segment.legs[segment.legs.length-1].destination_airport_code
			let mapKey = origin +"-"+destination;
			this.sectorMap.set(mapKey,segment)
		}
	}
	private closeactiveModal(): void {
		this.closeactiveBtn.nativeElement.click();
	}
	setLegs(travellers, segments) {
		for (let i = 0; i < travellers.length; i++) {
			let segment = []
			const element = travellers[i];
			for (let s = 0; s < segments.length; s++) {
				const elm = segments[s];
				let sectorArr = []
				if (element['status'] == undefined || element['status'] == 'TICKETED') {
					for (let i = 0; i < elm.legs.length; i++) {
						const el = elm.legs[i];
						let obj = {
							origin : el.origin_airport_code,
							destination : el.destination_airport_code,
							sector: el.origin_airport_code + '-' + el.destination_airport_code,
							status: 'TICKETED'
						}
						sectorArr.push(obj);
					}
					if (element['sector'] == undefined) {
						element['sector'] = sectorArr;
					}
					segment.push(sectorArr);
					element['status'] = 'TICKETED';
				}
			}
			element['segment'] = segment
		}
		console.log('Booking Data', this.bookingData)
	}

	getTotal(data) {
		this.totalFare = 0
		for (let i = 0; i < data.length; i++) {
			const element = data[i];
			const lThis = this;
			let keys = Object.keys(element.fare.taxes[0]);
			let count = 0;
			Object.values(element.fare.taxes[0]).forEach(function (v) {
				if (keys[count] != "MARKUP" && keys[count] != "COMMISSION" && keys[count] != "TOTAL" && keys[count] != "TDS" && keys[count] != "NET_PAYABLE" &&
					keys[count] != "ADMIN_MARKUP" && keys[count] != "OTHER_TAX" && keys[count] != "AFFILIATE_MARKUP" && keys[count] != "ADMIN_SERVICE_CHARGE") {
					lThis.totalFare += Number(v);
				}
				if (keys[count] == "OTHER_TAX" || keys[count] == "AFFILIATE_MARKUP" || keys[count] == "ADMIN_SERVICE_CHARGE") {
					lThis.totalFare += Number(v);
				}
				count++;
			})
			this.totalFare += Number(element.fare.base) + this.getOT(element.ssr);
		}
		this.netPayable += this.totalFare;
		return this.totalFare
	}
	getOT(ssr) {
		let meal = 0;
		let baggage = 0;
		let seat = 0;
		let additionalBaggage = 0;
		let ancillaryCharges = 0;
		if (ssr != null) {
			for (let i = 0; i < ssr.length; i++) {
				const element = ssr[i];
				if (element.meals != null) {
					meal += Number(element.meals.code.totalFare);
				}
				if (element.baggages != null) {
					baggage += Number(element.baggages.code.totalFare);
				}
				if (element.additionalBaggages != null) {
					additionalBaggage += Number(element.additionalBaggages.code ? element.additionalBaggages.code.totalFare : 0);
				}
				if (element.seats != null) {
					seat += Number(element.seats.code ? element.seats.code.totalFare : 0);
				}
				if(element.ancillaries != null) {
					if(Array.isArray(element.ancillaries.code)){
						for (const ancillary of element.ancillaries.code) {
							ancillaryCharges += Number(ancillary.code ? ancillary.totalFare : 0);
						}
					}else{
						ancillaryCharges += Number(element.ancillaries.code ? element.ancillaries.code.totalFare : 0);
					}
				}
			}
		}
		return Number(meal) + Number(baggage) + Number(seat) + Number(ancillaryCharges) + Number(additionalBaggage)
	}
	getPerPrice(data, index) {
		this.totalFare = 0
		const element = data;
		const lThis = this;
		let keys = Object.keys(element.fare.taxes[0]);
		let count = 0;
		Object.values(element.fare.taxes[0]).forEach(function (v) {
			if (keys[count] != "MARKUP" && keys[count] != "COMMISSION" && keys[count] != "TOTAL" && keys[count] != "TDS" && keys[count] != "NET_PAYABLE" &&
				keys[count] != "ADMIN_MARKUP" && keys[count] != "OTHER_TAX" && keys[count] != "AFFILIATE_MARKUP" && keys[count] != "ADMIN_SERVICE_CHARGE") {
				lThis.totalFare += Number(v);
			}
			if (keys[count] == "OTHER_TAX" || keys[count] == "AFFILIATE_MARKUP" || keys[count] == "ADMIN_SERVICE_CHARGE") {
				lThis.totalFare += Number(v);
			}
			count++;
		})
		this.totalFare += Number(element.fare.base) + this.getOT(element.ssr);
		return this.totalFare;
	}
	getSector(dataObj) {
		let code = {}
		let sectorArr = [];
		for (let k = 0; k < dataObj.segments[0].legs.length; k++) {
			const element = dataObj.segments[0].legs[k];
			if (dataObj.segments[0].legs.length == 1) {
				this.sector = element.origin_airport_code + '-' + element.destination_airport_code
			}
			else {
				if (k == 0) {
					code = element.origin_airport_code
				}
				else {
					code = element.destination_airport_code
				}
			}
			sectorArr.push(code);

		}
		if (dataObj.segments[0].legs.length > 1) {
			this.sector = sectorArr.join('-');
		}
	}
	selectLegs(segment, segmentIndex, sectorIndex, passenger) {
		// if (passenger.segment[segmentIndex][sectorIndex].status == 'TICKETED') {
		// 	passenger.segment[segmentIndex][sectorIndex].status = 'REQUESTED';
		// }
		// else {
		// 	passenger.segment[segmentIndex][sectorIndex].status = 'TICKETED';
		// }
		for (const sector of passenger.segment[segmentIndex]) {
			if(sector.status == 'TICKETED'){
				sector.status = 'REQUESTED'
			}else{
				sector.status = 'TICKETED'
			}
		}
		
		// if (passenger.sector[sectorIndex].status == 'TICKETED') {
		// 	passenger.sector[sectorIndex].status = 'REQUESTED';
		// 	this.getStatus(segment,segmentIndex,sectorIndex, passenger);
		// }
		// else {
		// 	passenger.sector[sectorIndex].status = 'TICKETED';
		// 	this.getStatus(segment,segmentIndex,sectorIndex, passenger);
		// }

		// console.log(passenger.sector[sectorIndex].sector + ' leg of Passenger is ' + passenger.first_name + ' ' + passenger.segment[segmentIndex][sectorIndex].status)
	}
	getStatus(segment, segmentIndex, sectorIndex, passenger) {
		if (passenger.status == 'CANCELLED') {
			// if (passenger.segment[segmentIndex][sectorIndex].status == 'TICKETED') {
			// 	return  'TICKETED'
			// }
			// if (passenger.segment[segmentIndex][sectorIndex].status == 'CANCELLED') {
			// 	return  'CANCELLED'
			// }else{
			// }
			return 'TICKETED'
		}
		else {
			return 'TICKETED'
		}
	}
	selectPassenger(passenger) {
		if (passenger['status'] == 'TICKETED') {
			passenger['status'] = 'REQUESTED';
			for (let j = 0; j < this.bookingData.segments.length; j++) {
				const element = this.bookingData.segments[j];
				for (let i = 0; i < element.legs.length; i++) {
					passenger.segment[j][i].status = 'REQUESTED';
				}

			}
		}
		else {
			passenger['status'] = 'TICKETED'
			for (let j = 0; j < this.bookingData.segments.length; j++) {
				const element = this.bookingData.segments[j];
				for (let i = 0; i < element.legs.length; i++) {
					passenger.segment[j][i].status = 'TICKETED';
				}

			}
		}
	}

	setCancelReason(event) {
		if (event.target.value == 'Other') {
			this.showOtherReason = true;
			this.bookingArr.json_doc['cancellation_reason'] = ""
		}
		else {
			this.bookingArr.json_doc['cancellation_reason'] = event.target.value
			this.showOtherReason = false;
		}
	}
	
	setOtherCancelReason(event) {
		this.bookingArr.json_doc['cancellation_reason'] = event.target.value
	}

async	openModal() {
		let cancelCount = await this.getCancelCount(this.bookingArr.json_doc.travellers)
		if (cancelCount > 0) {
			this.canProceed = true
			this.selectedTravellers = this.returnSelectedTravellers(this.bookingArr)
			if (this.bookingArr.json_doc['cancellation_reason'] == undefined || this.bookingArr.json_doc['cancellation_reason'] == null || this.bookingArr.json_doc['cancellation_reason'] == "") {
				this.showSelectCancelReason = true
			}
			else {
				this.showSelectCancelReason = false
			}
		}
		else{
			this.canProceed = false
		}
	}
	getCancelCount(travellers){
		let count  = 0
		travellers.forEach(element => {
			for (let i = 0; i < element.segment.length; i++) {
				const segment = element.segment[i];
				for (let j = 0; j < segment.length; j++) {
					const legs = segment[j];
					if (legs.status == 'REQUESTED') {
						count ++
					}
				}
				
			}
		});
		return count
	}
	getCancelType(oldObj) {
		let paxCount = 0
		let segmentCount = 0
		let legCount = 0
		let data = oldObj.json_doc;
		let segment = oldObj.json_doc.segments
		for (let i = 0; i < data.travellers.length; i++) {
			const element = data.travellers[i];
			if (element.status == 'REQUESTED') {
				paxCount++
			}
			else if (element.status == 'TICKETED') {
				segmentCount = 0;
				for (let j = 0; j < element.segment.length; j++) {
					const el = element.segment[j];
					legCount = 0;
					for (let k = 0; k < el.length; k++) {
						const elem = el[k];
						if (elem.status == 'REQUESTED') {
							legCount++;
						}
					}
					if (legCount == segment[j].legs.length) {
						segmentCount++
					}
				}
				if (segmentCount == segment.length) {
					paxCount++
				}
			}
		}
		if (paxCount == data.travellers.length) {
			return true
		}
		else {
			return false
		}
	}

	returnSelectedTravellers(oldObj) {
		let data = oldObj.json_doc;
		let travellers = []
		for (let i = 0; i < data.travellers.length; i++) {
			const element = data.travellers[i];
			if (element.status == 'REQUESTED') {
				travellers.push(element)
			}
			else if (element.status == 'TICKETED') {
				let hasSelectedLeg = false
				for (let j = 0; j < element.segment.length; j++) {
					const el = element.segment[j];
					for (let k = 0; k < el.length; k++) {
						const elem = el[k];
						if (elem.status == 'REQUESTED') {
							hasSelectedLeg = true
						}
					}
				}
				if(hasSelectedLeg){
					travellers.push(element)
				}
			}
		}
		return travellers
	}

	cancelFromSupplier(){

	}
	async	submit() {	
		// let isFullCancel = await this.getCancelType(ob)
		let isFullCancel = await this.getCancelType(this.bookingArr)
		let curr_date_time = await this.commonService.getCurrentDateTime()
		this.bookingArr.json_doc['isFullCancel'] = isFullCancel
		this.bookingArr.json_doc['cancellation_request_date_time'] = curr_date_time
		this.bookingArr.json_doc['requested_from'] = "IBE"
		this.bookingArr.json_doc.pnr_status = "REQUESTED";
		// return false;
		this.commonService.putBooking('booking/update', this.bookingArr).subscribe(res => {
			console.log('Update Success', res)
			const localThis = this;
			setTimeout(() => {
				localThis.closeactiveModal();
				this.router.navigate(['/bookingsummary']);
			}, 1)
		}, err => {
			this.closeactiveModal();
			console.log('Update Error', err)
			let errorMessage = 'Cancellation request FAILED.'
			let errorDetails;
			try {
				let errorResponse = JSON.parse(err['_body'])
				if (errorResponse['message'] != undefined && errorResponse['messageDetails'] != undefined) {
					errorMessage = errorResponse['message']
					errorDetails = errorResponse['messageDetails']
				}
				this.showError('warning-box',errorMessage,errorDetails)
			} catch (error) {
				this.showError('warning-box',errorMessage,errorDetails)
			}
		})
	}
	showError(type,message,detail){
		this.messageList['status'] = true
		let obj ={
			warningType : type,
			message : message,
			detail : detail
		}
		this.messageList['messageList'].push(obj)
		this.notificationService.showMessage(this.messageList)
	}

	return() {
		this.router.navigate(['/bookingsummary']);
	}

}
