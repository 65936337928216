<section class="container oneway">
    <div class="row" *ngFor = "let flight of listOfFlightStatusObject;let i = index">
        <div class="alert alert-danger danger-msg w-100" role="alert" *ngIf="flight.status=='FAILED' && !flight.isGroupFareBooking">
            <strong>Booking failed! </strong>Your ticket<small>(<b>s</b>)</small>  can not be booked.
        </div>
        <div class="alert alert-warning warn-msg w-100" role="alert" *ngIf="flight.status=='FAILED' && flight.isGroupFareBooking">
            <strong>Your booking is in-progress! </strong>Our backend team is working on this.
        </div>
        <div class="alert alert-warning warn-msg w-100" role="alert" *ngIf="flight.status=='HOLD'">
            Your ticket<small>(<b>s</b>)</small>  is on hold. <span *ngIf="flight.bookingHoldMessage != undefined">{{flight.bookingHoldMessage }}</span>
        </div>

        <div class="alert alert-success   success-msg w-100" role="alert" [ngClass]="flight.insurancePolicy && flight.insurancePolicy.status=='failed' && flight.insurancePolicy.error_message ?'alert-insurance':'' " *ngIf="flight.status=='TICKETED' && somethingFailed === false">
            <div class="d-flex align-items-center">
                <img src="../../../assets/v3images/icons/success-icon.png" alt="" srcset="" class="mr-2" width="20px" height="20px"> 
                <span class="text-bold">Your reservation request<small>(<b>s</b>)</small> is successfull!</span>
            </div>
            <small class="error-msg d-block text-bold text-danger ml-4" *ngIf="flight.insurancePolicy && flight.insurancePolicy.status=='failed' && flight.insurancePolicy.error_message">{{flight.insurancePolicy.error_message}}
            </small>
        </div>

        <div class="alert alert-warning warn-msg w-100" role="alert" [ngClass]="flight.insurancePolicy && flight.insurancePolicy.status=='failed' && flight.insurancePolicy.error_message ?'alert-insurance':'' " *ngIf="flight.status=='TICKETED' && somethingFailed === true">
            <div class="d-flex align-items-center">
                <img src="../../../assets/v3images/icons/success-icon.png" alt="" srcset="" class="mr-2" width="20px" height="20px"> 
                <span class="text-bold">Your reservation request<small>(<b>s</b>)</small> is successfull!</span>
            </div>
            <small class="error-msg d-block text-bold text-danger ml-4" *ngIf="flight.insurancePolicy && flight.insurancePolicy.status=='failed' && flight.insurancePolicy.error_message">{{flight.insurancePolicy.error_message}}
            </small>
        </div>

        <div class=" card flight-detailsbox no-hover-shadow" style="    margin-bottom: 40px!important;">
            <div class="d-flex align-items-center justify-content-between pl-3 pr-3 pt-2">
                <div class="d-flex align-items-center ">
                    <img src="../../assets/v3images/icons/{{flight.status=='TICKETED' ? 'success-icon.png' : flight.status=='HOLD' ? 'hold.svg' : flight.status=='FAILED' && !flight.isGroupFareBooking ? 'cancel.svg' : flight.status=='FAILED' && flight.isGroupFareBooking ? 'dark-blue-visa.svg' : 'orange-info.svg'}}" class="mr-2 shadow" width="30px" height="30px" style="    border-radius: 50%;">

                    <h4 class="m-0 text-uppercase fa-1x text-bold " 
                    [ngClass]="flight.status=='TICKETED' ? 'text-success' : flight.status=='HOLD' ? 'text-warning' : flight.status=='FAILED' && !flight.isGroupFareBooking ? 'text-danger' : flight.status=='FAILED' && flight.isGroupFareBooking ? 'text-info' : 'text-black'"
                    style="font-size: 15px;">{{flight.status}}{{flight.status == ''? 'Undefined' : ''}} </h4>
                    <span *ngIf="flight.status=='HOLD' && flight.hold_ttl" class="text-bold ml-2 text-uppercase text-danger">
                       Till {{flight.hold_ttl | dateFormat}}  {{ flight.hold_ttl | timeFormat }}
                    </span>
                </div>
                <div class="d-flex align-items-center  justify-content-end">
                    <div class=" mr-2 " *ngIf="flight.booking_ref !=''">
                        <small class="text-grey d-block">Booking Ref. No.</small>
                        <span  style="font-size: 15px;" class=" text-bold " >{{flight.booking_ref}} <i title="Copy AT Number" class="fa fa-copy  ml-1 text-grey" (click) = "copyMessage(flight.booking_ref,'Reference')"></i></span>
                    </div>
                    <div class=" ml-2 " *ngIf="flight.pnr !=''">
                        <small class="text-grey d-block">PNR</small>
                        <span  style="font-size: 15px;" class=" text-bold" >{{flight.pnr}} <i title="Copy PNR Number" class="fa fa-copy ml-1  text-grey" (click) = "copyMessage(flight.pnr,'PNR')"></i></span> 
                    </div>                   
                </div>
                
            </div>
            <div class="card-body pt-3" >            
                <div class="flight-detailsbox card mb-3 "  *ngFor="let segment of flight.segments">
                    <div class="card-header ">
                        <div class=" airline-card-content">                                                   
                        <div class="d-flex justify-content-between align-items-center departure flight-info">
                            <h4 class="text-black m-0">
                                <img src="../../../assets/v3images/icons/purple-take-off-plan.svg" alt="" width="25px" height="25px" class="mr-2">
                                {{segment.origin}} - {{segment.destination}}
                            </h4>
                            <div class="flight-time-date ">
                                <p class="flight-time d-flex align-items-center  justify-content-end"><img src="../../../assets/v3images/icons/purple-clock.svg" alt="" width="15px" height="215px" class="mr-1"> 
                                    {{segment.departureDateTime | dateFormat}}
                                    <span class="ml-1 mr-1 text-grey">|</span>
                                    <ng-container >
                                    <span  class=" flight-date ml-2">{{segment.departureDateTime | timeFormat}}</span>
                                    <!-- <span class="fare-type-style d-flex  ml-3"><i class="fa fa-tags bg-success text-white mr-1"></i>{{flight.fareType}}</span> -->

                                    <ng-container *ngIf="segment?.supplierInfo?.priceType;else fareType">
                                        <span title="{{segment?.supplierInfo?.priceType}}" class="fare-type-style d-flex ml-3">
                                            
                                            <ng-container *ngIf="flight.fares[0].supplierInfo && flight.fares[0].supplierInfo.subPriceType && (flight.fares[0].supplierInfo.subPriceType =='STUD' || flight.fares[0].supplierInfo.subPriceType =='SRCT') ;else regularFareTag">
                                                <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='STUD'" src="assets/v3images/icons/white-student-cap.svg" alt="STUD">
                                                <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='SRCT'" src="assets/v3images/icons/white-senior-citizen.svg" alt="SRCT">
                                              </ng-container>
                                              <ng-template #regularFareTag>
                                                <i class="fa fa-tags bg-success text-white mr-1"></i>
                                              </ng-template>
                                            {{
                                                segment?.supplierInfo?.priceType
                                            }}
                                        </span>
            
                                    </ng-container>
                                    <ng-template #fareType>
                                        <span title="{{flight.fares[0] &&
                                            flight.fares[0].displayPriceType?flight.fares[0].displayPriceType:flight.fares[0].priceType}}" class="fare-type-style d-flex  ml-3">
                                            <ng-container *ngIf="flight && flight.fares && flight.fares[0].supplierInfo && flight.fares[0].supplierInfo.subPriceType && (flight.fares[0].supplierInfo.subPriceType =='STUD' || flight.fares[0].supplierInfo.subPriceType =='SRCT') ;else regularFareTag">
                                                <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='STUD'" src="assets/v3images/icons/white-student-cap.svg" alt="STUD">
                                                <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='SRCT'" src="assets/v3images/icons/white-senior-citizen.svg" alt="SRCT">
                                            </ng-container>
                                            <ng-template #regularFareTag>
                                            <i class="fa fa-tags bg-success text-white mr-1"></i>
                                            </ng-template>
                                            <!-- <i class="fa fa-tags bg-success text-white mr-1"></i> -->
                                            {{flight.fareType}}
                                        </span>
                                    </ng-template>

                                </ng-container>
                                </p>
                            </div> 
                                
                        </div> 
                        </div>                                          
                    </div>
                    <div class="card-body p-0">
                        <ng-container >
                                <div class="row m-0" style="
                                margin-bottom: 10px;
                                border-bottom: 1px dashed #e8e8e8;"  *ngFor="let item of segment['legs']; let j = index;">
                                        <div class=" col-lg-3   col-md-12 airline-box text-left d-flex align-items-center ">
                                            <div class="airline-logo-name mr-2">
                                                <div class="airlogo mb-1 m-0">
                                                    <img src="assets/images/{{item.marketingAirlineCode}}.png" alt=""
                                                        onError="this.src='assets/images/icon7.png'">
                                                </div>
                                            </div>
                                            <div>
                                                <p class="airline-name m-0  text-uppercase">{{item.marketingAirlineName}}</p>
                                            <div class="airline-flight-codes mr-auto ml-0">
                                                <span class="m-0">{{item.marketingAirlineCode}}-{{item.flightNumber}}</span>
                                                    <small class="terminal text-dark-pink ">{{flight.serviceClass}} 
                                                        <ng-container *ngIf="flight.fareClass!=''">| {{flight.fareClass}}</ng-container></small>
                                                    <small class="text-grey d-block text-uppercase" >OPERATED
                                                        BY : {{item.marketingAirlineCode}}</small>
                                            </div>
                                            </div>
                                        </div>
                                        <div class=" col-lg-9 pt-2 col-md-12 pl-0 pr-0">
                                            <div class="row m-0">
                                                <div class="col-md-4">
                                                    <div class="airline-card-content">
                                                        <div class="departure flight-info">
                                                            <h4>
                                                                <img src="../../../assets/v3images/icons/purple-take-off-plan.svg" alt="" width="20px" height="20px" class="mr-1">
                                                                <span
                                                                    >  {{item.originAirport.code}}</span>
                                                            </h4>
                                                        <div class="flight-time-date mt-2">
                                                            <p class="flight-time"><img src="../../../assets/v3images/icons/purple-clock.svg" alt="" class="mr-2" width="15px" height="15px"  > <span>{{item.departureDateTime|timeFormat}}</span></p>
                                                            <p style="font-size: 12px;" class="mt-1">{{item.departureDateTime
                                                                | dateFormat}}
                                                            </p>
                                                                    <small class="text-pinkish-red d-block ">Terminal : {{item.departureTerminal?item.departureTerminal:"NA"}}
                                                                    </small>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4" >
                                                    <div class="travelling-time">
                                                        <div class="box segment-info type text-center lay-tooltip" >                                            
                                                        <small class="mb-1 ">
                                                            {{item.duration}}
                                                            </small>
                                                        <div class="separator-line"></div>
                                                        <small class="text-grey"> Check-in Baggage</small> 
                                                        <div class="text-center   mt-1" *ngIf="item && item.freeCheckInBaggage">
                                                            <img src="../../../assets/v3images/icons/baggage.svg" alt="" srcset="" width="15px" >
                                                            <span class=" text-grey ml-2">{{item?.freeCheckInBaggage?.unit}} {{item?.freeCheckInBaggage?.unitTypeName}}</span>
                                                        </div>
                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="airline-card-content">
                                                        <div class="departure flight-info text-right">
                                                            <h4>
                                                                <img src="../../../assets/v3images/icons/purple-landing-plan.svg" alt="" width="20px" height="20px" class="mr-2">
                                                                <span
                                                                    >{{item.destinationAirport.code}}</span>
                                                            </h4>
                                                        <div class="flight-time-date mt-2 text-right">
                                                            <p class="flight-time"><img src="../../../assets/v3images/icons/purple-clock.svg" class="mr-2" alt="" width="15px" height="15px" > <span>{{item.arrivalDateTime | timeFormat}}</span></p>
                                                            <p style="font-size: 12px;" class="mt-1"> 
                                                                {{item.arrivalDateTime | dateFormat}}
                                                            </p>
                                                    <small class="text-pinkish-red d-block ">Terminal : {{item.arrivalTerminal?item.arrivalTerminal:"NA"}}</small>
                                                                    
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>  

                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center align-items-center mb-3" *ngIf="flight.status=='TICKETED'">
                <div class="shimmer  download-shimmer" *ngIf="flight.isInsuranceSelected && i==listOfFlightStatusObject.length-1">
                    <div class="wrapper">
                      <div class="stroke animate d-flex align-items-center justify-content-center title m-0" *ngIf="!(flight.insurancePolicy)"><span>Download Policy</span> </div>
                      <a class="btn pt-2 pb-2 pl-3 pr-3  text-white text-bold btn-sm  transition ticket " download [href]="flight.insurancePolicy.policy_docs.insurance_pdf_file_url" *ngIf="(flight.insurancePolicy)"  [ngClass] = "!(flight.insurancePolicy && flight.insurancePolicy.policy_docs && flight.insurancePolicy.policy_docs.insurance_pdf_file_url)?'disable_payment':''">Download Policy</a>
                      </div>
                     
                </div>
                <a class="btn btn-info pt-2 pb-2 pl-3 pr-3 text-white text-bold btn-sm  mr-3" href="javascript:void(0)" (click)="printInvoice(flight.invoice_no)" [ngClass]="flight.isInsuranceSelected && !(listOfFlightStatusObject[listOfFlightStatusObject.length-1].insurancePolicy)?' disable_payment':''" >View Invoice</a>
                <a class="btn btn-green pt-2 pb-2 pl-3 pr-3 text-white text-bold btn-sm " href="javascript:void(0)" (click)="printTicket(flight.invoice_no)" >View Ticket</a>
            </div>
        </div>
</div>
<div id="snackbar">{{copyFor}} copied..</div>

</section>