import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'src/app/services/auth.guard.service';
import { PnrRetrieveBookingSummaryComponent } from './pnr-retrieve-booking-summary.component';

const routes: Routes = [
	{
		path: '',redirectTo: 'pages/dashboard',pathMatch: 'full'
	  },
	{
		path: '',
		component: PnrRetrieveBookingSummaryComponent, canActivate: [AuthGuardService]
	}
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PnrRetrieveBookingSummaryRoutingModule { }
