<div class="container mb-fix" *ngIf="valid">
  <div class="row col-12 booking-header p-0 m-0">
    <div class="col-4 p-0">
      <span class="info-head booking">Booking Ref No - </span>
      <span class="info-no book-no">{{bookingData.booking_ref}}</span>
    </div>

    <div class="col-4 p-0">
      <span class="info-head">Invoice No - </span>
      <span class="info-no">{{bookingData.invoice_no}}</span>
    </div>

    <div class="col-4 p-0">
      <span class="info-head">Booking Date - </span>
      <span class="info-no">{{bookingData.booked_timestamp | date}}</span>
    </div>

  </div>
  <!-- row booking-header -->

  <div class="row col-12 pnr-info mt-3">
    <span class="pnr-no"> PNR : {{bookingData.segments[0].pnr}}</span>
    <!-- <span class="separator" *ngIf="bookingData.segments[0]?.airline_pnr != undefined">&nbsp;|&nbsp;</span>
    <span class="pnr-no airline" *ngIf="bookingData.segments[0].airline_pnr != undefined">Airline PNR :
      {{bookingData.segments[0].airline_pnr}}</span> -->
  </div>
  <!-- row pnr-info -->

  <div class="row col-12 w-100 passenger-section m-0 p-0 mt-3">
    <div class="col-12 w-100 m-0 p-0 thead-section">
      <span class="thead col">Name</span>
      <span class="thead col">Type</span>
      <span class="thead col">Status</span>
      <span class="thead col">Ticket</span>
      <span class="thead col">Fare</span>

    </div>

    <div class="thead-details-section col-12 w-100 p-0" *ngFor="let user of bookingData.travellers, let i = index">
      <div class="thead-container">
        <span class="thead-details col float-left">
          <div class="form-check col text-left  pax">
            <input class="form-check-input" *ngIf="user.status != 'CANCELLED'" type="checkbox" value="" (click)="selectPassenger(user)"
              id="passengerCheck{{i}}">
            <label class="form-check-label" for="passengerCheck{{i}}"> <span
                class="username">{{user.title}}&nbsp;{{user.first_name}}&nbsp;{{user.last_name}}</span></label>
          </div>
        </span>
        <span class="thead-details col">{{user.type}}</span>
        <span class="thead-details col" >{{user.status}}</span>
        <span class="thead-details col" *ngIf="user.ticket!= null">{{user.ticket.number}}</span>
        <span class="thead-details col" *ngIf="user.ticket == null">N/A</span>
        <span class="thead-details col">
          {{getPerPrice(user , i) | currency:"INR":"symbol-narrow"}}</span>
      </div>

      <div class="clearfix"></div>

      <div class="flight-section col-12 mt-2 p-0">
        <div class="flight-details clearfix col-12 p-0" *ngFor="let flight of bookingData.segments; let j=index;">
          <div class="d-flex f-col">
            <div class="form-check col sector text-left pl-5">
              <input class="form-check-input float-left" *ngIf="user?.sector[0]?.status != 'CANCELLED' && user?.status == 'TICKETED'" type="checkbox"
                (click)="selectLegs(flight,j,0,user)" value="" id="legCheck{{i}}{{j}}{{0}}">
              <label class="form-check-label " for="legCheck{{i}}{{j}}{{0}}">
                <div class="f-name float-left">{{flight.legs[0].origin_airport_code}} - {{flight.legs[flight.legs.length - 1].destination_airport_code}}
                </div>
              </label>
            </div>
            <div class="f-status col">{{getStatus(flight,j,0,user)}}</div>
            <div class="f-dep-date col">{{flight.legs[0].origin_timestamp | date: 'dd/MM/yyyy'}},
              {{flight.legs[0].origin_timestamp | date: 'HH:mm'}}</div>
            <div class="f-arr-date col">{{flight.legs[flight.legs.length - 1].destination_timestamp | date: 'dd/MM/yyyy'}},
              {{flight.legs[flight.legs.length - 1].destination_timestamp | date: 'HH:mm'}}</div>

            <div class="f-info col">
              <span class="f-dep">{{flight.legs.operating_airline_name}}</span>
              <span class="dep-flightnum">{{flight.legs.flight_number}}</span>
              <!--<span class="f-arr">China Southern Airlines</span>
                              <span class="arr-flightnum">3028</span>-->
            </div>
          </div>
        </div>
        <!-- flight details -->
      </div>
    </div>
    <!-- flight-section -->

  </div>
  <!-- row passenger-section -->
  <div class="row">
    <div class="form-group">
      <label for="cancelReason">Cancellation Reason <span class="text-danger">*</span></label>
      <select id="cancelReason" class="form-control" name="reason" (change)="setCancelReason($event)">
          <option value="">Select</option>
          <option value="Refund as per Fare rules">Refund as per Fare rules</option>
          <option value="Refund as per No Show">Refund as per No Show</option>
          <option value="Refund due to Flight Schedule Change">Refund due to Flight Schedule Change</option>
          <option value="Refund due to Flight XXLD">Refund due to Flight XXLD</option>
          <option value="Refund due to Death Case">Refund due to Death Case</option>
          <option value="Other">Others</option>
      </select>
      <div class="form-group" *ngIf="showOtherReason">
        <label for="otherReason">Other Reason (Please specify) :  <span class="text-danger">*</span></label>
        <textarea id="otherReason" class="form-control" name="" rows="3" (blur)="setOtherCancelReason($event)">

        </textarea>
      </div>
    </div>
    <!-- <select name="reason" id="cancelReson" (change)="setCancelReason($event)">
      <option value="Refund as per Fare rules">Refund as per Fare rules</option>
      <option value="Refund as per No Show">Refund as per No Show</option>
      <option value="Refund due to Flight Schedule Change">Refund due to Flight Schedule Change</option>
      <option value="Refund due to Flight XXLD">Refund due to Flight XXLD</option>
      <option value="Refund due to Death Case">Refund due to Death Case</option>
    </select> -->
  </div>
  <div class="row">
    <div class="cancel-btn">
      <a data-toggle="modal" data-target="#modalApprove" (click)="openModal()">Request Cancellation</a>
    </div>
    <div class="cancel-btn">
      <a (click)="return()">Back</a>
    </div>
  </div>
  <div class="modal fade" id="modalApprove" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" *ngIf="!canProceed">
            Error
          </h5>
          <h5 class="modal-title" *ngIf="canProceed">
            Confirmation
          </h5>
          <button type="button" class="close" data-dismiss="modal" #closeactiveBtn aria-label="Close">
            <span aria-hidden="true">
              ×
            </span>
          </button>
        </div>
        <div class="modal-body">
          <p *ngIf="!canProceed" class="text-danger"><strong>Please select atleast one traveller to cancel</strong></p>
          <p *ngIf="showSelectCancelReason" class="text-danger"><strong>Please select cancellation reason to proceed</strong></p>
          <h6 *ngIf="canProceed && !showSelectCancelReason" class="text-danger"> 
            Reason : {{bookingData.cancellation_reason}}
          </h6>
          <h6>Selected Travellers : </h6>
          <table class="table table-bordered" *ngIf="canProceed && !showSelectCancelReason && selectedTravellers && selectedTravellers.length > 0">
            <tr>
              <th>Name</th>
              <th>Sectors</th>
            </tr>
            <ng-container *ngFor="let traveller of selectedTravellers">
              <tr>
                <td>{{traveller.first_name}} {{traveller.last_name}}</td>
                <td>
                  <div>
                    <ng-container *ngFor="let segment of traveller.segment">
                      <span class="d-block" *ngIf="segment[0].status == 'REQUESTED'">{{segment[0].origin}}-{{segment[segment.length-1].destination}}</span>
                      
                    </ng-container>
                  </div>
              </td>
              </tr>
            </ng-container>
          </table>
          <p *ngIf="canProceed && !showSelectCancelReason">Are you sure you want to Request For cancellation for Booking Reference no- {{bookingData.booking_ref}} ?
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn submitBtn btn-info" id="btnConfirm" (click)="submit()" *ngIf="canProceed && !showSelectCancelReason">
            Confirm
          </button>
          <button type="button" class="btn btn-danger" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!--container end -->