import { Injectable } from '@angular/core';
import { Http, Headers } from "@angular/http";
import { HttpClient } from "@angular/common/http";
import { UrlService } from './url.service';

@Injectable({
	providedIn: 'root'
})
export class PostService {

	headers;
	constructor(private http: Http, private httpClient: HttpClient) { }

	postMethod(url,postJson) {

		this.headers = new Headers({ 'Content-Type': 'application/json'});
		return this.httpClient.post(url, JSON.stringify(postJson), { headers: this.headers });

	}

	getMethod(url) {
		this.headers = new Headers({ 'Content-Type': 'application/json'});
		return this.http.get(url, { headers: this.headers });

	}

	getMethodWithHttpClient(url) {
		this.headers = new Headers({ 'Content-Type': 'application/json'});
		return this.httpClient.get(url, { headers: this.headers });

	}

	postMethodWithHttpClient(url, postJson) {
		this.headers = new Headers({ 'Content-Type': 'application/json'});
		return this.httpClient.post(url, JSON.stringify(postJson), { headers: this.headers });
	}

	putMethod(url, putJson) {
		this.headers = new Headers({ 'Content-Type': 'application/json'});
		return this.httpClient.put(url,JSON.stringify(putJson), { headers: this.headers });
	}

	setFlightStateManage(url,data) {
		this.headers = new Headers({ 'Content-Type': 'application/json'});
		return new Promise((resolve, reject) => {
			this.httpClient.post(url, JSON.stringify(data), { headers: this.headers }).subscribe(res => {
				if(JSON.parse(res["_body"])) {
					resolve(JSON.parse(res["_body"]).data)
				}
				
			}, err => {
				console.log("Error while saving fareinfo : ", err)
				resolve(err['error']);
			})

		})
	}

	retrievePNR(url){
		return new Promise((resolve, reject) => {
			this.httpClient.get(url).subscribe(res=> {
				resolve(res)
			},err=> {
				resolve(null)
			})
		})
	}
}
