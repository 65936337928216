<div class="clearfix"></div>
<div class="page-container">
  <div class="container">
    <div class="card p-4">
      <small class="text-bold text-pinkish-red">About</small>
      <h4 class="text-bold text-dark-green">
        Aadesh Travels Pvt. Ltd
      </h4>     

        
        <p class="text-muted hover-text-grey transition">
          The company was formed and incorporated on 21st April 2006 by Mr Gopal
          Unadkat (MD) out of Rajkot, Gujarat with 3 employees.
        </p>
      <p class="text-muted hover-text-grey transition"
        >Company today boost of 35 dedicated employees who efficiently handle
        our loyal clients base in all areas such as Sales, Operations & Accounts
        and are also steadily expanding the base all across.</p
      >

      <p class="text-muted hover-text-grey transition"
        >Company is firmly rooted with an idea of offering its clients Quality
        service, the Best Travel Option at Best Value with a transparent and
        seamless accounting system.</p
      >

      <p class="text-muted hover-text-grey transition"
        >Aadesh Travels Pvt Ltd is today one of the leading names in travel
        fraternity and is still consistently growing by the day. Company is IATA
        accredited since inception and is a member of various industry
        organisation such TAAI, TAG, GTAA etc.</p
      >

      <p class="text-muted hover-text-grey transition"
        >Envisaging early on that future is going to be digitally and technology
        driven, the company invested in a Travel Portal and launched it on 15th
        JUN 2014 for its B2B clients who are well established and respected
        names for offering authentic travel solutions. Recently a revised
        version of portal was also launched with enhanced features on 16th JUL
        2019. Website: www.aadeshtravels.com A mobile App of revised version is
        soon to be launched.
      </p>

      <p class="text-muted hover-text-grey transition"
        >Portal offers Online travel solution under various verticals like
        Domestic and International Air Ticket, Hotels, Insurance and Parks apart
        from MIS (Accounting and Reports ) to its B2B users to take their
        business to new heights. Company takes pride in having about 1400+
        registered travel agents.</p
      >

      <p class="text-muted hover-text-grey transition"
        >The company has consistently retained its position of being amongst TOP
        10 agents of Gujarat's most preferred airlines namely Air India, Jet
        Airways, Indigo, Spicejet, GoAir, Etihad Airways, Emirates, Qatar
        Airways, Kuwait Airways, Air Arabia and Singapore Airlines on a year on
        year basis. Apart from above, business and growth is also remarkable on
        airlines like Kenya Airways, Cathay Pacific, Air Canada, Ethopian
        Airlines etc. to name a few.</p
      >

      <p class="text-muted hover-text-grey transition"
        >Continued support from our partners and dedicated team has earned us
        lots of accolades from time to time.
      </p>

      <p class="text-muted hover-text-grey transition"
        >Mr Gopal Unadkat is a second generation entrepreneur with interests in
        Construction, FMCG and Cargo apart from Travel Agency.</p
      >
      <blockquote style="    border-left: 5px solid #7c68d4;" class="shadow">
        <p class="text-bold mb-0 text-black">
          <b class="text-dark-green">Address: </b>Destination Plus, 3rd Floor,
          Amin Marg, Rajkot, Gujarat, INDIA, 360005.
        </p>
        <p class="text-bold mt-0 text-black">
          <span class="text-dark-green">Registration No: </span> 048172
        </p>
      </blockquote>
    </div>
  </div>
</div>
