<div style="margin-top: 72px;" class="register">
    <div class="form-container clearfix">
        <div class="col-md-3 register-left">
            <img src="assets/images/flight-ico.png" alt="" />
            <h3>Welcome to our Affiliate Program</h3>
            <p>You are only 3 steps away from brighter future!</p>
        </div>
        <div class="col-md-9 register-right pr-2">
            <div class="scroll-box">
                <div class="contact-details" id="topDiv">
                    <div class="alert alert-success float-right msgSuccess" *ngIf="successMsg">
                        <strong>Your application is under process and will be reviewed for Registration by one of the our Registration Team.</strong>
                    </div>
                    <div class="alert alert-danger msgError float-right  w-75" *ngIf="errorMsg">
                        <strong>Error! </strong>
                        <a href="#" class="alert-link">{{errorMessage}}</a>.
                    </div>
                    <h3 class="register-heading">Contact Details</h3>
                    <form [formGroup]="contactForm">
                        <div class="row register-form">
                            <div class="col-12">
                                <div>
                                    <div class="form-group col-6 float-left pl-0 pr-3">
                                        <input type="text" class="form-control mb-1 mr-2" oninput="this.value = this.value.toUpperCase()" placeholder="Company Name *" formControlName="txtCompanyName" value="" required>
                                        <div class="invalid-feedback" *ngIf="!txtCompanyName.valid && txtCompanyName.touched">
                                            Please enter Company Name.
                                        </div>
                                    </div>
                                    <div class="form-group col-6 float-left pl-0 pr-0">
                                        <input type="text" formControlName="txtWebsite" class="form-control mb-1" placeholder="Website" value="" />
                                    </div>
                                </div>

                                <div class="clearfix"></div>

                                <div>
                                    <div class="form-group col-6 float-left pl-0 pr-3">
                                        <textarea class="form-control mb-1" formControlName="txtAddress1" oninput="this.value = this.value.toUpperCase()" rows="1" placeholder="Address *" required></textarea>
                                        <div class="invalid-feedback" *ngIf="!txtAddress1.valid && txtAddress1.touched">
                                            Please enter Address.
                                        </div>
                                    </div>
                                    <div class="form-group col-6 float-left pl-0 pr-0">
                                        <textarea class="form-control mb-1" formControlName="txtAddress2" oninput="this.value = this.value.toUpperCase()" rows="1" placeholder="Address Line 2"></textarea>
                                    </div>
                                </div>

                                <div class="clearfix"></div>

                                <div>
                                    <div class="form-group col-6 float-left pl-0 pr-3">
                                        <input type="text" class="form-control mb-1" formControlName="txtPincode" placeholder="Pincode *" value="" required>
                                        <div class="invalid-feedback" *ngIf="!txtPincode.valid && txtPincode.touched">
                                            Please enter Pincode.
                                        </div>
                                    </div>
                                    <div class="form-group col-6 float-left pl-0 pr-0">
                                        <select class="form-control mb-1" formControlName="selCountry" (ngModelChange)="getStates(selCountry.value)">
											<option value="" class="hidden">Country *</option>
											<option  *ngFor="let data of countriesArr" [value]="data.location_id">{{data.name}}</option>
										</select>
                                        <div class="invalid-feedback" *ngIf="!selCountry.valid && selCountry.touched">
                                            Please select Country.
                                        </div>
                                    </div>
                                </div>

                                <div class="clearfix"></div>

                                <div>
                                    <div class="form-group col-6 float-left pl-0 pr-3">
                                        <select class="form-control mb-1" formControlName="selState" (ngModelChange)="getCities(selState.value)">
											<option value="" class="hidden">State *</option>
											<option   *ngFor="let data of statesArr" [value]="data.location_id">{{data.name}}</option>
										</select>
                                        <div class="invalid-feedback" *ngIf="!selState.valid && selState.touched">
                                            Please select State.
                                        </div>
                                    </div>
                                    <div class="form-group col-6 float-left pl-0 pr-0">
                                        <select class="form-control mb-1" formControlName="selCity">
											<option value="" class="hidden">City *</option>
											<option  *ngFor="let data of citiesArr" [value]="data.location_id">{{data.name}}</option>
										</select>
                                        <div class="invalid-feedback" *ngIf="!selCity.valid && selCity.touched">
                                            Please select City.
                                        </div>
                                    </div>
                                </div>

                                <div class="clearfix"></div>

                            </div>

                        </div>
                    </form>
                </div>
                <div class="clearfix"></div>
                <div class="contact-details">
                    <h3 class="register-heading">Company Details</h3>
                    <form [formGroup]="companyDetailForm">
                        <div class="row register-form">
                            <div class="col-12">
                                <div>
                                    <div class="form-group col-6 float-left pl-0 pr-3">
                                        <select class="form-control mb-1" formControlName="chkCompanyType" (ngModelChange)="companyType(chkCompanyType.value)">				
											<option  value="Proprietorship">PROPRIETORSHIP</option>
											<option value="Partnership">PARTNERSHIP</option>
										</select>
                                        <div class="invalid-feedback" *ngIf="!chkCompanyType.valid && chkCompanyType.touched">
                                            Please select Company Type.
                                        </div>
                                    </div>
                                    <div class="form-group col-6 float-left pl-0 pr-0">
                                        <input type="text" class="form-control mb-1" placeholder="PAN Number *" formControlName="txtPanNumber" oninput="this.value = this.value.toUpperCase()" value="" required>
                                        <div class="invalid-feedback" *ngIf="!txtPanNumber.valid && txtPanNumber.touched ">
                                            Please enter PAN Number.
                                        </div>
                                        <!-- <div class="invalid-feedback" *ngIf="txtPanNumber.valid && !txtPanNumber.hasError('pattern')">
												Please enter valid PAN Number.
										</div> -->

                                    </div>
                                </div>

                                <div class="clearfix"></div>

                                <div>
                                    <div class="form-group col-6 float-left pl-0 pr-3">
                                        <input type="text" class="form-control mb-1" placeholder="TDS Exemption" formControlName="txtTdsExemption" oninput="this.value = this.value.toUpperCase()" />
                                    </div>
                                    <div class="form-group col-6 float-left pl-0 pr-0" *ngIf="enablePanholdeName">
                                        <input type="text" class="form-control mb-1" placeholder="PAN Holder Name *" formControlName="txtPanHolderName" oninput="this.value = this.value.toUpperCase()">
                                        <div class="invalid-feedback" *ngIf="!txtPanHolderName.valid && txtPanHolderName.touched ">
                                            Please enter PAN Holder Name.
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div>
                                    <div class="form-group col-12 pl-0 pr-0">
                                        <input type="text" class="form-control mb-1" placeholder="GST Number" formControlName="txtGstNum" oninput="this.value = this.value.toUpperCase()" />
                                        <div class="invalid-feedback" *ngIf="!txtGstNum.valid && txtGstNum.touched ">
                                            Please enter Valid GST number
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>

                </div>
                <div class="clearfix"></div>

                <div class="contact-details">
                    <h3 class="register-heading">Personal Details</h3>
                    <form [formGroup]="personalDetailForm">
                        <div class="row register-form">
                            <div class="col-12">
                                <div>
                                    <div class="form-group col-6 float-left pl-0 pr-3">
                                        <select class="form-control mb-1" formControlName="selTitle">
											<option value="" class="hidden">Title *</option>
											<option value="Mr.">Mr.</option>
											<option value="Mrs.">Mrs.</option>
											<option value="Ms.">Ms.</option>
										</select>
                                        <div class="invalid-feedback" *ngIf="!selTitle.valid && selTitle.touched">
                                            Please select Title.
                                        </div>
                                    </div>
                                    <div class="form-group col-6 float-left pl-0 pr-0">
                                        <input type="text" class="form-control mb-1" placeholder="First Name *" oninput="this.value = this.value.toUpperCase()" formControlName="txtFirstName" required>
                                        <div class="invalid-feedback" *ngIf="!txtFirstName.valid && txtFirstName.touched">
                                            Please enter First Name.
                                        </div>
                                    </div>
                                </div>

                                <div class="clearfix"></div>

                                <div>
                                    <div class="form-group col-6 float-left pl-0 pr-3">
                                        <input type="text" class="form-control mb-1" placeholder="Last Name *" oninput="this.value = this.value.toUpperCase()" formControlName="txtLastName" required>
                                        <div class="invalid-feedback" *ngIf="!txtLastName.valid && txtLastName.touched">
                                            Please enter Last Name.
                                        </div>
                                    </div>
                                    <div class="form-group col-6 float-left pl-0 pr-0">
                                        <input type="text" class="form-control mb-1" placeholder="Email *" oninput="this.value = this.value.toUpperCase()" formControlName="txtPrimaryEmail" required>
                                        <div class="invalid-feedback" *ngIf="!txtPrimaryEmail.valid && txtPrimaryEmail.touched">
                                            Please enter Email.
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div>
                                    <div class="form-group col-6 float-left pl-0 pr-3">
                                        <input type="text" class="form-control mb-1" maxlength="10" placeholder="Contact Number" oninput="this.value = this.value.toUpperCase()" formControlName="txtPhone">
                                        <!-- <div class="invalid-feedback">
											Please enter Contact Number.
										</div> -->
                                    </div>
                                    <div class="form-group col-6 float-left pl-0 pr-0">
                                        <input type="text" class="form-control mb-1" maxlength="10" placeholder="Mobile Number *" oninput="this.value = this.value.toUpperCase()" formControlName="txtMobile" required>
                                        <div class="invalid-feedback" *ngIf="!txtMobile.valid && txtMobile.touched">
                                            Please enter Mobile Number.
                                        </div>
                                    </div>
                                    <div class="form-group col-6 float-left pl-0 pr-3">
                                        <input type="password" class="form-control mb-1" minlength="8" maxlength="20" placeholder="Password *" formControlName="txtEnterPassword" required>
                                        <div class="invalid-feedback" *ngIf="!txtEnterPassword.valid && txtEnterPassword.touched">
                                            Please enter your password.
                                        </div>
                                    </div>
                                    <div class="form-group col-6 float-left pl-0 pr-0">
                                        <input type="password" class="form-control mb-1" (keyup)="confirmPassword($event.value)" minlength="8" maxlength="20" placeholder="Confirm Password *" formControlName="txtConfirmPassword" required>
                                        <div class="invalid-feedback" *ngIf="!txtConfirmPassword.valid && !isPasswordError && txtConfirmPassword.touched">
                                            Please confirm your password.
                                        </div>
                                        <div class="invalid-feedback" *ngIf="isPasswordError">
                                            Password does not match.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="text-center mb-4">
                    <!-- <input type="button" class="btnRegister" (click)="saveData(contactForm.value, companyDetailForm.value, personalDetailForm.value)" value="Sign up now" /> -->
                    <span class="btnRegister" (click)="saveData(contactForm.value, companyDetailForm.value, personalDetailForm.value)">Sign up now</span>
                </div>
            </div>
        </div>
    </div>

</div>