import { Component, OnInit, ViewChild } from '@angular/core';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource, MatTabChangeEvent } from '@angular/material';
import { CommonService } from 'src/app/services/common.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { environment } from 'src/environments/environment';
import { PostService } from 'src/app/services/post.service';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { ExcelService } from 'src/app/services/excel.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment'

@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
	dataSource: any;
	generalLedger: any;
	cashBal: any;
	creditBal: any;
	successMessage: string;
	errorMessage: string;
	colorTheme = 'theme-purple'
	bsConfig: Partial<BsDatepickerConfig>;
	public max = new Date();
	hasError: boolean;
	isDiAccount: boolean;
	isWarning : boolean = false;
	warningMessage = '';
	affiliateCode: any;
	viewDetail: boolean = false;
	paymentStatusResponse: any;
	isResponse: boolean;
	selectedPaymentMethod: any;
	affiliateMobile: any;
	affiliateEmail: any;

	constructor(private affiliateService: AffiliateService, private fb: FormBuilder,
		private commonService: CommonService,
		private bookingService: BookingService,
		private postService: PostService,
		private excelService: ExcelService,
		private activatedRoute: ActivatedRoute
	) { }
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	paymentForm: FormGroup;
	onlinePaymentForm: FormGroup;
	IsCheque: boolean = false;
	IsInterBank: boolean = false;
	IsDebit: boolean = false;
	IsCredit: boolean = false;
	affiliateId: string;
	affiliateXlCode: string;
	successMsg: boolean = false;
	errorMsg: boolean = false;
	paymentDataArr: any = []
	displayedColumns: any[] = ['beneficiary_bank_name', 'depositor_bank_name', 'mode_of_payment', 'amount', 'timastamp', 'payment_status', 'remarks']

	
	
	ngOnInit() {

		this.bsConfig = Object.assign({ dateInputFormat: 'DD-MM-YYYY' }, { containerClass: this.colorTheme }, { showWeekNumbers: false })
		this.paymentForm = this.fb.group({
			'txtAccountType': ['cash', Validators.required],
			'txtAgencyName': [''],
			'txtAgencyCode': [''],
			'selDepositType': ['cash', Validators.required],
			'txtDepositDate': ['', Validators.required],
			'txtDepositAmount': ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
			'selBeneficiaryBank': ['', Validators.required],
			'txtChequeNo': [''],
			'cardType': ['OPTCRDC'],
			'txtAmountCharged': [''],
			'txtChequeDate': [''],
			'txtDepositorBankName': [''],
			'txtDepositorBankAccount': [''],
			'txtDepositorBankBranch': [''],
			'txtRemarks': ['']
		})
		// this.dataSource.paginator = this.paginator;	

		

		this.paymentForm.controls.txtDepositDate.setValue(new Date())

		if(localStorage.user_details != undefined) {
			this.affiliateCode = JSON.parse(localStorage.user_details)['xlAccountCode'];
			if(JSON.parse(localStorage.user_details)['is_di_account'] != null) {
				this.isDiAccount = (JSON.parse(localStorage.user_details)['is_di_account'] == 'true')
			}
			else{
				this.isDiAccount = false;
			}
		}
		
		this.getAffiliate();
		this.getGeneral();
		var url = window.location.href.slice(window.location.href.indexOf('?') + 1);
		url = url.replace(/&amp;/g, "&")
		if (url.includes("paymentIndex=1")) {	
			this.activatedRoute.queryParams.subscribe(params => {	
				 
				let amount = params['amount'];
				let orderID = params['orderId'];
				let transId = params['referenceNumber'];
				let hasKey = params['key'];
				let paymentRef = params['payment_ref'];
	
				var creditData = JSON.parse(localStorage.getItem('makeOnlinePayment'));
				if (!(location.href.includes('error=true')) && creditData != null) {
	
					let obj = {
						"orderId" : orderID,
						"trackingId" : transId,
						"amount" : amount,
						"key" : hasKey,
						"status" : "success"
					}	
					 
					if(!params.hasOwnProperty('ft-cash')) {
						this.commonService.hdfcPayment(obj, 'getPaymentStatus').subscribe(data=>{
							if( data['_body'] != "") {
								  let response = JSON.parse(data['_body'])	
								if(response.length > 0 ) {	
									this.isResponse = true;
									this.paymentStatusResponse = response[0].json_doc_response;		
									creditData['chequeNumber'] = orderID;
									creditData['transactionRef'] = transId;
									this.successMessage = "Your payment of INR " + amount + " has been processed successfully. Transaction Id -  " + transId + " & Ref No. - " + orderID + "."
									this.showSuccess();
									this.getAffiliate();
								}
								else{
									this.isResponse = false;
									this.errorMessage = "Sorry ! Unable to process now, please try again.";					
									this.showError();
								}
							   
							 
							}
							else{
								this.errorMessage = "Sorry ! Unable to process now, please try again.";
								this.showError();
							}
						},error=> {
							console.log(error);
							this.errorMessage = "Sorry ! Unable to process now, please try again.";	
							this.showError();
						})
					}
					else{
						 
						creditData['invoice_no'] = paymentRef
						creditData['bank_code'] = "GA0331"
						// this.doAccountEntry(creditData)
						this.successMessage = "Your payment of INR " + amount + " has been processed successfully. Transaction Id -  " + transId + " & Ref No. - " + orderID + "."
						this.showSuccess();
						this.getAffiliate();
					}
					
					
				}
				else {
					this.errorMessage = "Sorry ! Unable to process now, please try again.";
					this.showError();
				}
				console.log(params); // Print the parameter to the console. 
			});																																																																																																																																																																																																																																																																				
		}

		else if (url.includes("paymentIndex=0")) {

			this.activatedRoute.queryParams.subscribe(params => {	
	
				let amount = params['amount'];
				let orderID = params['orderId'];
				let transId = params['referenceNumber'];
				let hasKey = params['key'];		
				this.errorMsg = true;
				let obj = {
					"orderId" : orderID,
					"trackingId" : transId,
					"amount" : amount,
					"key" : hasKey,
					"status" : "failed"
				}	
		
				this.commonService.hdfcPayment(obj, 'getPaymentStatus').subscribe(data=>{
					// console.log("payment response ",data)
					 ;
					if( data['_body'] != "") {
						let response = JSON.parse(data['_body'])
						if(response.length > 0) {
							this.isResponse = true;
							this.paymentStatusResponse = response[0].json_doc_response;
						}
						else{
							this.isResponse = false;
						}
					}
					else{
						this.isResponse = false;
					}
				},error=> {
					console.log(error)
					 ;
				})
			})
		}
	}
	doAccountEntry(accountEntryReq) {
		let requestObj = []
		requestObj.push(accountEntryReq)
		
		console.log('Accounting Receipt Entry Request : ',requestObj)
		if (environment.name == 'stage' || environment.name == 'default') {
			this.commonService.postMethod(`${environment.baseUrl}/account-portal-integration/receipt`,requestObj).subscribe(res=>{
				console.log(res)
			},err=>{
				console.log(err)
			})
		}
	}

	showDetail() {
		this.viewDetail = !this.viewDetail;
	}

	onlinePaymentResponse() {

	}

	getAffiliate() {
		this.affiliateService.getAffiliateDetails().subscribe(res => {
			this.paymentForm.controls.txtAgencyName.setValue(res[0]['company_name'])
			this.paymentForm.controls.txtAgencyCode.setValue(res[0]['xl_account_code'])
			 
			this.affiliateId = res[0]['id'];
			this.affiliateMobile = res[0]['mobile'];
			this.affiliateEmail = res[0]['primary_email'];
			this.affiliateXlCode = res[0]['xl_account_code'];

			this.affiliateService.getBalance(this.affiliateId,this.affiliateXlCode).subscribe(res => {
				let resp = res
				this.creditBal = resp[0] && resp[0]['total_balance'] ? resp[0]['total_balance'] : 0
			},
			err => {
				this.creditBal = 0
			})
			
		})

	}
	submitPaymentReq(values) {		
		values.deposited_by = this.affiliateId;
		if(values.selDepositType == "debit-card" || values.selDepositType == "credit-card"){			
			this.onlinePay(values);
		}
		else{
			this.hasError = false
			if (values.selDepositType == 'cash' && values.txtDepositAmount > 195000) {
				return false
			}
			window.scroll(0, 0)
			const invalid = [];
			const controls = this.paymentForm.controls;
			for (const name in controls) {
				if (controls[name].invalid) {
					if (name == 'txtDepositAmount') {
						console.log('txtDepositAmount')
						 
					}
					invalid.push(name);
				}
			}
			if (invalid.length > 0) {
				this.hasError = true
				console.log('invalid fields', invalid)
				return false
			}
			window.scroll(0, 0)	
			 ;
			this.saveAccountEntry(values);
			this.affiliateService.saveAffiliatePayment(values).subscribe(res => {
				this.paymentForm.reset();
				this.getAffiliate();
				this.successMessage = "Saved successfully";
				this.showSuccess();
				this.paymentForm.controls.txtDepositDate.setValue(new Date())
			}, err => {
				this.showError();
			})
		}
		
		
	}

	changeDeposit(value) {
		this.selectedPaymentMethod = 'other'
		if (value == 'cash') {
			this.IsCheque = false;
			this.IsInterBank = false;
			this.IsDebit = false;
			this.IsCredit = false;
			this.paymentForm.controls.selBeneficiaryBank.setValidators([Validators.required])
			this.paymentForm.updateValueAndValidity();
		}
		else if (value == 'cheque') {
			this.IsCheque = true;
			this.IsInterBank = false;
			this.IsDebit = false;
			this.IsCredit = false;
			this.paymentForm.controls.selBeneficiaryBank.setValidators([Validators.required])
			this.paymentForm.updateValueAndValidity();
		}
		else if (value == 'interbank-transfer') {
			this.IsCheque = false;
			this.IsInterBank = true;
			this.IsDebit = false;
			this.IsCredit = false;
			this.paymentForm.controls.selBeneficiaryBank.setValidators([Validators.required])
			this.paymentForm.updateValueAndValidity();
		}
		else if (value == 'debit-card') {
			this.IsCheque = false;
			this.IsInterBank = false;
			this.IsDebit = true;
			this.IsCredit = false;
		}
		else if (value == 'credit-card') {
			this.IsCheque = false;
			this.IsInterBank = false;
			this.IsDebit = true;
			this.IsCredit = true;
			this.selectedPaymentMethod = 'credit-card'
		}
		else {
			this.IsInterBank = false;
			this.IsCheque = true;
			this.IsDebit = false;
			this.IsCredit = false;
			this.paymentForm.controls.selBeneficiaryBank.setValidators(null)
			this.paymentForm.controls.selBeneficiaryBank.updateValueAndValidity();
		}
	}
	getCharge(txtDepositAmount) {
		let amount_charged = Math.round(Number(txtDepositAmount) + (Number(txtDepositAmount) * Number(environment.payment_gateway_charges)) / 100)
		this.paymentForm.controls.txtAmountCharged.setValue(amount_charged);
	}
	showSuccess() {
		window.scrollTo(0, 0);
		this.successMsg = true;
		// setTimeout(() => {
		// 	this.successMsg = false;
		// }, 3000)
	}

	showError() {
		window.scrollTo(0, 0);
		this.errorMsg = true;
		// setTimeout(() => {
		// 	this.errorMsg = false;
		// }, 7000)
	}

	showWarning() {
		this.isWarning = true;
		setTimeout(() => {
			this.isWarning = false;
		}, 10000)
	}

	

	getPaymentHistory(event: MatTabChangeEvent) {
		if (event.index == 1) {
			this.affiliateService.getPayementHistory(this.affiliateId).subscribe(res => {
				console.log(res)
				this.paymentDataArr = res['data'];
				this.dataSource = new MatTableDataSource(this.paymentDataArr);
				console.log('payment history', this.paymentDataArr)
				// this.dataSource.paginator = this.paginator;
				// this.dataSource.sort = this.sort;
			}, err => {

			})
		}
	}
	getGeneral() {
		this.commonService.getGeneralLedger('general-ledger/viewAllGeneralLedger?type=bank').subscribe(res => {
			this.generalLedger = res;
			console.log('this.generalLedger', this.generalLedger)
		},
			error => {
			})
	}
	get selDepositType() {
		return this.paymentForm.controls.selDepositType
	}
	get txtDepositDate() {
		return this.paymentForm.controls.txtDepositDate
	}
	get txtDepositAmount() {
		return this.paymentForm.controls.txtDepositAmount
	}
	get selBeneficiaryBank() {
		return this.paymentForm.controls.selBeneficiaryBank
	}
	get txtAccountType() {
		return this.paymentForm.controls.txtAccountType
	}
	get txtChequeNo() {
		return this.paymentForm.controls.txtChequeNo
	}
	get txtChequeDate() {
		return this.paymentForm.controls.txtChequeDate
	}
	get txtDepositorBankName() {
		return this.paymentForm.controls.txtDepositorBankName
	}

	onlinePay(values) {	
		// values.txtDepositAmount = values.txtAmountCharged;
		localStorage.setItem('makeOnlinePayment', JSON.stringify(values));
		// 
		let redirectUrl = "http://localhost:4200/#/payment"
		if (environment.name != 'default') {
			redirectUrl = `${environment.baseUrl}/#/payment`
		}
		 
		if (this.selectedPaymentMethod != 'credit-card') {
			let payment_mode = "debit";
			if(this.selectedPaymentMethod == "netbanking") {
				payment_mode = 'netbanking'
			}
			this.doFTCashPayment(values,redirectUrl,payment_mode)
		}
		else {
			this.doHDFCPayment(values,redirectUrl)
		}
	}

	doHDFCPayment(values,redirectUrl){
	let obj = {
		"affiliateCode" : this.affiliateCode,
		"affiliateId" : this.affiliateId,
		"redirectUrl" : redirectUrl,
		"amount" : values.txtAmountCharged,
		"order_id" : "PT500069",
		"mobile" : "8470008670",
		"payment_mode" : values.cardType,
		"limit_type" : values.txtAccountType,
		"original_amount" : values.txtDepositAmount
	}

	this.commonService.hdfcPayment(obj, 'ccavRequestHandler/payment-request').subscribe(data=>{
		console.log("payment response ",data)
		if( data['_body'] != "") {
			 ;
			let response = JSON.parse(data['_body']) 
			var url = environment.hdfcPaymentUrl+'/ccavRequestHandler/make-payment?key='+response['encRequest']+'&orderId='+response['orderId'];
			window.location.href = url;
		}
		else{

		}
	},error=> {
		console.log(error)
		 ;
	})
	}

	doFTCashPayment(values,redirectUrl,payment_mode){
	let obj = {
		"affiliateCode" : this.affiliateCode,
		"affiliateId" : this.affiliateId,
		"redirectUrl" : redirectUrl,
		"amount" : values.txtAmountCharged,
		"order_id" : "PT500069",
		"mobile" : this.affiliateMobile,
		"email" : this.affiliateEmail,
		"limit_type" : values.txtAccountType,
		"payment_mode" : payment_mode,
		"original_amount" : values.txtDepositAmount
	}
	this.commonService.onlinePayment(obj).subscribe(data=>{
		console.log("payment response ",data)
		if( data['_body'] != "") {
		var url = data['_body'];
		console.log(url);
		window.location.href = url;
		}
		else{

		}
	},error=> {
	console.log(error)
	 ;
	})
	}

	setPaymentMethod(paymentMethod){
	this.selectedPaymentMethod = paymentMethod
		
	}


	saveAccountEntry(values) {

		let subLedger = 'CC001B';
		if(values.txtAccountType == 'cash') {
			subLedger = 'CC001A';
		}

		let account_code_to = '';
		let generalLedgerArr = this.generalLedger
		for(const item of generalLedgerArr) { 
			 ; 
			if(item['id']  == values['selBeneficiaryBank']) {
				account_code_to = item['txtXLCode'];
			}
		}


		let obj = {
			"account_code_to": account_code_to, //mandatory
			"account_code_to_type": "general",
			"receipt_timestamp": moment(values.txtDepositDate).format('YYYY-MM-DD HH:mm:ss'),
			"last_modified_by": 16,
			"last_modified_by_name": "ADMIN", 
			"entries": [
				{
					"account_code_from": values.txtAgencyCode, 
					"account_code_from_type": "client", 
					"account_code_to": account_code_to, 
					"account_code_to_type": "general", 
					"cheque_no": values.txtChequeNo,
					"cheque_date": values.txtChequeDate,
					"cheque_bank_name": "",
					"amount": values.txtDepositAmount, 
					"narration_1": values.txtRemarks,
					"narration_2": "",
					"narration_3": "",
					"narration_4": "",
					"narration_5": "",
					"remark_1": "",
					"remark_2": "",
					"remark_3": "",
					"remark_4": "",
					"remark_5": "",
					"sub_ledger_code_1_type": "client",
					"sub_ledger_code_2_type": "",
					"sub_ledger_code_3_type": "",
					"sub_ledger_code_4_type": "",
					"sub_ledger_code_5_type": "",
					"sub_ledger_code_1": subLedger,
					"sub_ledger_code_2": "",
					"sub_ledger_code_3": "",
					"sub_ledger_code_4": "",
					"sub_ledger_code_5": "",
					"other_transaction_type": "", 
					"other_account": "", 
					"other_amount": "", 
					"last_modified_by": 16,
					"last_modified_by_name": "ADMIN" 
				}
			]
		}

		this.affiliateService.saveAccountEntry(obj).subscribe(res => {
			 ;
		}, err => {
			 ;
		})
	}
}
