import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class WebsocketService {

	websocket: WebSocket;

	constructor() { }


	init() {
		try{
			const lThis = this;
			let uri = `${environment.heimdallUrl}`;
			this.websocket = new WebSocket(uri);
			this.websocket.onerror = function (error) {
				console.log('Web Socket Connection Error', error);
			};
			this.websocket.onclose = function (event) {
				console.log('Web Socket Connection Closed', event);
			};
			this.websocket.onmessage = (message) => {
				console.log(message);
				window.location.reload();
			};
			if(localStorage.user_details != undefined) {
				let loginUserDetails = JSON.parse(localStorage.user_details);
				this.websocket.onopen = function() {
					lThis.websocket.send(loginUserDetails['xlAccountCode'])
				}
			}
			
		}
		catch(Exception) {
			console.log(Exception);
		}
	
		
	}





	
}
