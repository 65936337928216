import { Component, Input, OnInit, Output ,EventEmitter} from '@angular/core';
@Component({
  selector: 'app-wheel-chair',
  templateUrl: './wheel-chair.component.html',
  styleUrls: ['./wheel-chair.component.scss']
})
export class WheelChairComponent implements OnInit {

  @Input() ssrAvailability;
	@Input() segmentIndex = 0
	@Output() callWheelChairEvent : EventEmitter<any> = new EventEmitter();
	constructor() { }

	ngOnInit() {
	}
	changeWheelChair(index, pax){
		let leg = 0; 
		const tempArr = Object.assign({},  this.ssrAvailability[leg])
		
		if(index == "-1") {
			this.ssrAvailability[leg].travellers[pax]['wheelChair'] = undefined;
			this.ssrAvailability[leg].travellers[pax]['wheelChairFare'] = 0;
			this.makeSeatObject(pax, null, true, 0, leg,tempArr,null);
		}
		else{
			let wheelChairs = tempArr.wheelChairs[index];
			this.ssrAvailability[leg].travellers[pax]['wheelchair'] = wheelChairs.name;		
			if(wheelChairs.name == null) {
				this.ssrAvailability[leg].travellers[pax]['wheelchair'] = wheelChairs.code;
			}
			this.ssrAvailability[leg].travellers[pax]['wheelChairFare'] = wheelChairs.totalFare;
			this.makeSeatObject(pax, wheelChairs.code, false, wheelChairs.totalFare, leg,tempArr,wheelChairs)
		}
	}
	

	makeSeatObject(passengerIndex, seatNumber, isRemove, price, leg,tempArr,wheelChairs){
		let flag = true;
		let segment = this.segmentIndex
		if(tempArr.flightInfo.ssrSRT[segment] && tempArr.flightInfo.ssrSRT[segment][leg]  && tempArr.flightInfo.ssrSRT[segment][leg].wheelChairs != undefined && tempArr.flightInfo.ssrSRT[segment][leg].wheelChairs != null) {
			tempArr.flightInfo.ssrSRT[segment][leg].wheelChairs = tempArr.flightInfo.ssrSRT[segment][leg].wheelChairs.filter(obj =>{
				if(isRemove) {
					flag = false;
					if(obj.travellerNumber != passengerIndex) {
						return obj;
					}
				}
				else{
					if(obj.travellerNumber == passengerIndex) {
						obj['code'] = seatNumber;
						obj['price'] = price;
						obj['item'] = wheelChairs;
						flag = false;
					}
					return obj;
				}
				
			});
		}
		
		if(flag) {
			let obj = {
				"code": seatNumber,
				"travellerNumber": passengerIndex,
				"item": wheelChairs,
				"price" : price,
				"title": this.ssrAvailability[leg].travellers[passengerIndex].title,
				"firstName": this.ssrAvailability[leg].travellers[passengerIndex].firstName,
				"lastName": this.ssrAvailability[leg].travellers[passengerIndex].lastName,
			}
			if(tempArr.flightInfo.ssrSRT[segment] == undefined){
				tempArr.flightInfo.ssrSRT[segment] = []
			}
			if(tempArr.flightInfo.ssrSRT[segment][leg] == undefined){
				tempArr.flightInfo.ssrSRT[segment][leg] = {}
			}
			if(tempArr.flightInfo.ssrSRT[segment][leg].wheelChairs == undefined || tempArr.flightInfo.ssrSRT[segment][leg].wheelChairs == null) {
				tempArr.flightInfo.ssrSRT[segment][leg].wheelChairs = [];				
			}
			tempArr.flightInfo.ssrSRT[segment][leg].wheelChairs.push(obj)
		}
		this.callWheelChairEvent.emit(tempArr.flightInfo.ssrSRT);
		
	}

 

}
