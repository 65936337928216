<div class="container contact-table-container pt-5">
<div class="card mb-4">
<div class="card-header d-flex justify-content-between align-items-center">
    <h5 class="text-dark-green m-0 text-bold ">
        <i class="fa fa-plane mr-2" aria-hidden="true"></i>GDS Airlines   
      </h5>
      <a class="text-dark-green" href="mailto:international@aadeshtravels.com"
      >international@aadeshtravels.com</a
    >
</div>
      <div class="card-body pt-0 pb-0 custom-table">
        <table class="table table-hover w-100">
            <thead>
          <tr>
              <th>NAME</th>
              <th>DESIGNATION</th>
              <th>CONTACT NUMBER</th>
              <th>EMAIL</th>
            </tr>
        </thead>
         <tbody>
            <tr>
                <td>Kannan Iyengar</td>
                <td>Manager – Operations</td>
                <td>+91 9909962679</td>
                <td>
                  <a href="mailto:kannan@aadeshtravels.com">kannan@aadeshtravels.com</a>
                </td>
              </tr>
              <tr>
                <td>Ajay Sanchaniya</td>
                <td>Supervisor - Domestic Counter</td>
                <td>+91 9824249946</td>
                <td>
                  <a href="mailto:domestic@aadeshtravels.com"
                    >domestic@aadeshtravels.com</a
                  >
                </td>
              </tr>
              <tr>
                <td>Mital Sanchaniya</td>
                <td>Supervisor - International Counter</td>
                <td>+91 281 2411111</td>
                <td>
                  <a href="mailto:international@aadeshtravels.com"
                    >international@aadeshtravels.com</a
                  >
                </td>
              </tr>
              <tr>
                <td>Amee Makwana</td>
                <td>Executive – Ticketing</td>
                <td>+91 281 2411111</td>
                <td>
                  <a href="mailto:international@aadeshtravels.com"
                    >international@aadeshtravels.com</a
                  >
                </td>
              </tr>
              <tr>
                <td>Anand Sachania</td>
                <td>Executive – Ticketing</td>
                <td>+91 95748 77077</td>
                <td>
                  <a href="mailto:international@aadeshtravels.com"
                    >international@aadeshtravels.com</a
                  >
                </td>
              </tr>
              <tr>
                <td>Priyanka Vania</td>
                <td>Executive – Ticketing</td>
                <td>+91 98799 48302</td>
                <td>
                  <a href="mailto:international@aadeshtravels.com"
                    >international@aadeshtravels.com</a
                  >
                </td>
              </tr>
         </tbody>
        </table>
      </div>
</div>
<div class="card mb-4">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h5 class="text-dark-green m-0 text-bold ">
            <i class="fa fa-plane mr-2" aria-hidden="true"></i>LCC AIRLINES   
          </h5>
          <a class="text-dark-green" href="mailto:domestic@aadeshtravels.com"
          >domestic@aadeshtravels.com</a
        >
    </div>
          <div class="card-body pt-0 pb-0 custom-table">
            <table class="table table-hover w-100">
                <thead>
              <tr>
                  <th>NAME</th>
                  <th>DESIGNATION</th>
                  <th>CONTACT NUMBER</th>
                  <th>EMAIL</th>
                </tr>
            </thead>
             <tbody>
                <tr>
                    <td>Diptesh Bhesaniya</td>
                    <td>Head – Portal Management</td>
                    <td>+91 99099 88860</td>
                    <td>
                      <a href="mailto:diptesh@aadeshtravels.com">diptesh@aadeshtravels.com</a>
                    </td>
                  </tr>
                  <tr>
                    <td>Mitesh Joshi</td>
                    <td>Executive – Ticketing</td>
                    <td>+91 281 2411111</td>
                    <td>
                      <a href="mailto:domestic@aadeshtravels.com"
                        >domestic@aadeshtravels.com</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>Ankur Pokiya</td>
                    <td>Executive – Ticketing</td>
                    <td>+91 281 2411111</td>
                    <td>
                      <a href="mailto:domestic@aadeshtravels.com"
                        >domestic@aadeshtravels.com</a
                      >
                    </td>
                  </tr>
             </tbody>
            </table>
          </div>
    </div>

    <div class="card mb-4">
        <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="text-dark-green m-0 text-bold ">
                <i class="fa fa-users mr-2" aria-hidden="true"></i>Group   
              </h5>
              <a class="text-dark-green" href="mailto:groups@aadeshtravels.com"
              >groups@aadeshtravels.com</a
            >
        </div>
              <div class="card-body pt-0 pb-0 custom-table">
                <table class="table table-hover w-100">
                    <thead>
                  <tr>
                      <th>NAME</th>
                      <th>DESIGNATION</th>
                      <th>CONTACT NUMBER</th>
                      <th>EMAIL</th>
                    </tr>
                </thead>
                 <tbody>
                    <tr>
                        <td>Heema Lathiya</td>
                        <td>Executive – Groups</td>
                        <td>+91 281 2411111</td>
                        <td>
                          <a href="mailto:groups@aadeshtravels.com">groups@aadeshtravels.com</a>
                        </td>
                      </tr>
                 </tbody>
                </table>
              </div>
        </div>
    <div class="card mb-4">
        <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="text-dark-green m-0 text-bold ">
                <i class="fa fa-users mr-2" aria-hidden="true"></i>Hotels   
              </h5>
              <a class="text-dark-green" href="mailto:hotels@aadeshtravels.com"
              >hotels@aadeshtravels.com</a
            >
        </div>
              <div class="card-body pt-0 pb-0 custom-table">
                <table class="table table-hover w-100">
                    <thead>
                  <tr>
                      <th>NAME</th>
                      <th>DESIGNATION</th>
                      <th>CONTACT NUMBER</th>
                      <th>EMAIL</th>
                    </tr>
                </thead>
                 <tbody>
                    <tr>
                        <td>Disha Chandarana</td>
                        <td>Executive – Hotel</td>
                        <td>+91 281 2411111</td>
                        <td>
                          <a href="mailto:hotels@aadeshtravels.com">hotels@aadeshtravels.com</a>
                        </td>
                      </tr>
                 </tbody>
                </table>
              </div>
        </div>


        <div class="card mb-4">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h5 class="text-dark-green m-0 text-bold ">
                    <i class="fa fa-money mr-2" aria-hidden="true"></i>Refund GDS   
                  </h5>
                  <a class="text-dark-green" href="mailto:refunds@aadeshtravels.com"
                  >refunds@aadeshtravels.com</a
                >
            </div>
                  <div class="card-body pt-0 pb-0 custom-table">
                    <table class="table table-hover w-100">
                        <thead>
                      <tr>
                          <th>NAME</th>
                          <th>DESIGNATION</th>
                          <th>CONTACT NUMBER</th>
                          <th>EMAIL</th>
                        </tr>
                    </thead>
                     <tbody>
                        <tr>
                            <td>Vidhi Chag</td>
                            <td>GDS Airlines</td>
                            <td>+91 281 2411111</td>
                            <td>
                              <a href="mailto:refunds@aadeshtravels.com">refunds@aadeshtravels.com</a>
                            </td>
                          </tr>
                          <tr>
                            <td>Ankur Pokiya</td>
                            <td>LCC Airlines</td>
                            <td>+91 281 2411111</td>
                            <td>
                              <a href="mailto:refunds@aadeshtravels.com">refunds@aadeshtravels.com</a>
                            </td>
                          </tr>
                     </tbody>
                    </table>
                  </div>
            </div>

            <div class="card mb-4">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <h5 class="text-dark-green m-0 text-bold ">
                        <i class="fa fa-clock-o mr-2" aria-hidden="true"></i>AOH - After office hours   
                      </h5>
                      <!-- <a class="text-dark-green" href="mailto:refunds@aadeshtravels.com"
                      >refunds@aadeshtravels.com</a -->
                </div>
                      <div class="card-body pt-0 pb-0 custom-table">
                        <table class="table table-hover w-100">
                            <thead>
                          <tr>
                              <th>NAME</th>
                              <th>DESIGNATION</th>
                              <th>CONTACT NUMBER</th>
                              <th>EMAIL</th>
                            </tr>
                        </thead>
                         <tbody>
                            <tr>
                                <td>Dhruv Shah</td>
                                <td>Executive – Ticketing</td>
                                <td>+91 99099 62461</td>
                                <td>
                                  <a href="mailto:international@aadeshtravels.com"
                                    >international@aadeshtravels.com</a
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td>Aarzoo Depani</td>
                                <td>Executive – Ticketing</td>
                                <td>+91 99099 62680</td>
                                <td>
                                  <a href="mailto:domestic@aadeshtravels.com"
                                    >domestic@aadeshtravels.com</a
                                  >
                                </td>
                              </tr>
                         </tbody>
                        </table>
                      </div>
                </div>

                <div class="card mb-4">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h5 class="text-dark-green m-0 text-bold ">
                            <i class="fa fa-line-chart mr-2" aria-hidden="true"></i>Sales   
                          </h5>
                          <!-- <a class="text-dark-green" href="mailto:refunds@aadeshtravels.com"
                          >refunds@aadeshtravels.com</a -->
                       
                    </div>
                          <div class="card-body pt-0 pb-0 custom-table">
                            <table class="table table-hover w-100">
                                <thead>
                              <tr>
                                  <th>NAME</th>
                                  <th>DESIGNATION</th>
                                  <th>CONTACT NUMBER</th>
                                  <th>EMAIL</th>
                                </tr>
                            </thead>
                             <tbody>
                                <tr>
                                    <td>Siddharth Vaishnav</td>
                                    <td>Sales Manager - Gujarat</td>
                                    <td>+91 98980 02462</td>
                                    <td>
                                      <a href="mailto:siddharth@aadeshtravels.com"
                                        >siddharth@aadeshtravels.com</a
                                      >
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Digvijaysinh Chudasama</td>
                                    <td>Sales Manager – Saurashtra Kutch</td>
                                    <td>+91 98795 51212</td>
                                    <td>
                                      <a href="mailto:digvijay@aadeshtravels.com"
                                        >digvijay@aadeshtravels.com</a
                                      >
                                    </td>
                                  </tr>
                             </tbody>
                            </table>
                          </div>
                    </div>

                    <div class="card mb-4">
                        <div class="card-header d-flex justify-content-between align-items-center">
                            <h5 class="text-dark-green m-0 text-bold ">
                                <i class="fa fa-inr mr-2" aria-hidden="true"></i>Accounts   
                              </h5>
                              <a class="text-dark-green"  href="mailto:accounts@aadeshtravels.com"
                              >accounts@aadeshtravels.com</a>
                           
                        </div>
                              <div class="card-body pt-0 pb-0 custom-table">
                                <table class="table table-hover w-100">
                                    <thead>
                                  <tr>
                                      <th>NAME</th>
                                      <th>DESIGNATION</th>
                                      <th>CONTACT NUMBER</th>
                                      <th>EMAIL</th>
                                    </tr>
                                </thead>
                                 <tbody>
                                    <tr>
                                        <td>Mehul Kakkad</td>
                                        <td>Manager – Accounts</td>
                                        <td>+91 7878856721</td>
                                        <td>
                                          <a href="mailto:mkakkad@aadeshtravels.com">mkakkad@aadeshtravels.com</a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Mansi Selarka</td>
                                        <td>Executive – Accounts</td>
                                        <td>+91 281 2411111</td>
                                        <td>
                                          <a href="mailto:acct@aadeshtravels.com">acct@aadeshtravels.com</a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Rucha Sanchaniya</td>
                                        <td>Executive – Accounts</td>
                                        <td>+91 281 2411111</td>
                                        <td>
                                          <a href="mailto:acct@aadeshtravels.com">acct@aadeshtravels.com</a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Amit Padhiyar</td>
                                        <td>Executive – Accounts</td>
                                        <td>+91 281 2411111</td>
                                        <td>
                                          <a href="mailto:acct@aadeshtravels.com">acct@aadeshtravels.com</a>
                                        </td>
                                      </tr>
                                 </tbody>
                                </table>
                              </div>
                        </div>
 

                        <div class="card mb-4">
                            <div class="card-header d-flex justify-content-between align-items-center">
                                <h5 class="text-dark-green m-0 text-bold ">
                                    <i class="fa fa-at mr-2" aria-hidden="true"></i>Email   
                                  </h5>
                                  <!-- <a class="text-dark-green"  href="mailto:accounts@aadeshtravels.com"
                                  >accounts@aadeshtravels.com</a> -->
                               
                            </div>
                                  <div class="card-body pt-0 pb-0 custom-table">
                                    <table class="table table-hover w-100">            
                                     <tbody>
                                        <tr>
                                            <td>International Ticketing &amp; Reissue</td>
                                            <td>
                                              <a href="mailto:international@aadeshtravels.com"
                                                >international@aadeshtravels.com</a
                                              >
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Domestic Ticketing &amp; Reissue</td>
                                            <td>
                                              <a href="mailto:domestic@aadeshtravels.com"
                                                >domestic@aadeshtravels.com</a
                                              >
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Groups-Domestic &amp; International</td>
                                            <td>
                                              <a href="mailto:groups@aadeshtravels.com">groups@aadeshtravels.com</a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Hotel Bookings – Domestic &amp; International</td>
                                            <td>
                                              <a href="mailto:hotels@aadeshtravels.com">hotels@aadeshtravels.com</a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>International / Domestic Refunds (GDS)</td>
                                            <td>
                                              <a href="mailto:refunds@aadeshtravels.com">refunds@aadeshtravels.com</a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Accounts Related Queries</td>
                                            <td>
                                              <a href="mailto:accounts@aadeshtravels.com"
                                                >accounts@aadeshtravels.com</a
                                              >
                                            </td>
                                          </tr>
                                     </tbody>
                                    </table>
                                  </div>
                            </div>
</div>
