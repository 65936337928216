import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CommonModule } from './Common/common.module';
import { routing } from './app.routing';
import { MatNativeDateModule, MatInputModule, MatIconModule } from '@angular/material';
import { PagesModule } from './pages/pages.module';
import { UrlService } from './services/url.service';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { AuthGuardService } from './services/auth.guard.service';
import { CommonService } from './services/common.service';
import { PostService } from './services/post.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './Login/login.component';
import { NgxLoadingModule,ngxLoadingAnimationTypes,NgxLoadingConfig } from 'ngx-loading';
import { AffiliateService } from './services/affiliate.service';
import { BookingService } from './services/booking/booking.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireDatabaseModule  } from '@angular/fire/database';
import {environment} from '../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { MatDatepickerModule, DateAdapter } from "@angular/material";
import { DateFormat } from './services/date-format';
import { TemplateModule } from './template/template.module';
import { VersionCheckService } from './version-check.service';
import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SharedModule } from './shared/shared.module';
import { CentrifugeService } from './services/centrifuge.service';
import { CookiesService } from './services/cookie.service';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store/reducers';
import { TravellerModule } from './pages/traveller/traveller.module';
import { NotificationService } from './services/notification.service';




// if(localStorage.user_details != undefined) {
// 	let userDetails = JSON.parse(localStorage.user_details);

// 	Sentry.init({
// 		dsn: "https://72bfca48f6954eb89a1790d2a457d3d1@sentry.io/1846143",
// 		environment: environment.name
	  
// 	});

// 	Sentry.configureScope(function(scope) {
// 		scope.setTag("username" , userDetails.xlAccountCode);
// 	});

// 	Sentry.configureScope(scope => {
// 		scope.setUser({
// 		  id: userDetails.xlAccountCode,
// 		  username: userDetails.xlAccountCode,
// 		  email: userDetails.primaryEmail
// 		})
// 	})
// }



// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//   constructor() {}
  
//   handleError(error) {
//     // const eventId = Sentry.captureException(error.originalError || error);
//     // Sentry.showReportDialog({ eventId });
//   }
// }


// OLD HAMMERJS CONFIG FOR SWIPE ACTIONS ON RETURN FLIGHT COMPONENT
// export class MyHammerConfig extends HammerGestureConfig {
// 	overrides = <any> {
// 	  swipe: { direction: Hammer.DIRECTION_ALL, velocity:0.5, threshold: 2 },
// 	};
// }


// NEW HAMMERJS FIX FOR IPAD SCROLLING
export class MyHammerConfig extends HammerGestureConfig  {
	buildHammer(element: HTMLElement) {
	  let mc = new Hammer(element, {
		touchAction: "auto",
	  });
	  return mc;
	}
  }


@NgModule({
	declarations: [
		AppComponent,LoginComponent, 
	],
	imports: [
		RouterModule,
		BrowserModule,
		CarouselModule,
		BrowserAnimationsModule,
		routing,
		CommonModule,
		PagesModule,
		MatNativeDateModule,
		MatDatepickerModule,
		NgxCaptchaModule,
		HttpModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule, AngularFireAuthModule,AngularFireStorageModule,AngularFireDatabaseModule,
		NgxLoadingModule.forRoot({
			animationType: ngxLoadingAnimationTypes.circle,
			backdropBackgroundColour: 'rgba(0,0,0,0.9)', 
			backdropBorderRadius: '4px',
			primaryColour: 'white', 
			secondaryColour: 'orange', 
			tertiaryColour: 'orange',
			fullScreenBackdrop: true
		}),
		HttpClientModule,FormsModule,ReactiveFormsModule,MatInputModule,MatIconModule,
		TemplateModule,
		SharedModule,
		TravellerModule,
		StoreModule.forRoot(reducers, { metaReducers }),
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpInterceptorService,
			multi: true
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: MyHammerConfig,
		  },
		{ provide: DateAdapter, useClass: DateFormat },
		// { provide: ErrorHandler, useClass: SentryErrorHandler },
		UrlService,
		HttpInterceptorService,
		AuthGuardService,
		CommonService,
		PostService,AffiliateService,BookingService,
		VersionCheckService,
		CentrifugeService,
		CookiesService,
		NotificationService
	],
	bootstrap: [AppComponent]
})
export class AppModule { 
	constructor(private dateAdapter: DateAdapter<Date>) {
		dateAdapter.setLocale("en-in"); // DD/MM/YYYY
	  }
}