import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking/booking.service';
import { AffiliateService } from 'src/app/services/affiliate.service';

@Component({
	selector: 'app-print-invoice',
	templateUrl: './print-invoice.component.html',
	styleUrls: ['./print-invoice.component.scss']
})
export class PrintInvoiceComponent implements OnInit {
	data: any = [];
	bookingData: any = [];
	userData: any = [];
	flightRequest: any = [];
	fareCharges: any = [];
	legs: any = [];
	statesArr: any = [];
	segment: any;
	flightFare: any = [];
	bookingArr: any = [];
	valid: boolean
	pricePaxArr: any = [];
	affiliateMarkUpPrice: number = 0;
	totalFare;
	searchId: string;
	userDetail: any = {};
	isPrint: boolean = true
	netCommission = 0;
	tds = 0;
	transactionID: string;
	affiliateID: number;
	LedgerAmount: number;
	showPriceWarning: boolean = false;
	ledgerData: any;
	constructor(private router: Router, private route: ActivatedRoute,
		private bookingService: BookingService, private affiliateService: AffiliateService) { }

	ngOnInit() {
		// this.netCommission = 0
		let searchId = this.route.snapshot.params['searchId'];
		console.log(searchId)
		this.userData = JSON.parse(localStorage.getItem('user_details'));
		this.searchId = searchId;
		if (window.location.hash.split('?')[1]!= undefined) {
			this.transactionID = window.location.hash.split('?')[1].split('=')[1].split('&')[0]
			if (window.location.hash.split('?')[1].split('&')[1] != undefined) {
				if (window.location.hash.split('?')[1].split('&')[1].split('=')[0] == 'affiliate') {
					this.affiliateID = Number(window.location.hash.split('?')[1].split('&')[1].split('=')[1])
					console.log('Affiliate id : ',this.affiliateID)
				}
				if (window.location.hash.split('?')[1].split('&')[2].split('=')[0] == 'amount') {
					this.LedgerAmount = Number(window.location.hash.split('?')[1].split('&')[2].split('=')[1])
					console.log('Ledger Amount is : ',this.LedgerAmount)
				}
			}
		}
		if (this.affiliateID != undefined) {
			searchId = searchId+'&affiliate_id='+this.affiliateID
		}
		this.bookingService.getBookingConfirmDetails(searchId,'invoice', null).subscribe(res => {
			console.log('booking confirm', res)
			let data = JSON.parse(res["_body"])['data']
			this.bookingArr = data[0];
			console.log(data);
			if (this.LedgerAmount == undefined) {
				this.ledgerData = JSON.parse(JSON.parse(res["_body"])['ledgerData'])
				if (this.ledgerData.length > 0) {
					this.LedgerAmount = this.ledgerData[0].amount
				} 
			}
			console.log(this.ledgerData)
			if (this.bookingArr !=undefined) {
				this.valid = true;
				this.getTotal(this.bookingArr.json_doc,'')
			}
			else{
				this.valid = false
			}
		})
		this.getLoginAffiliateDetails();
	}

	getLoginAffiliateDetails() {
		this.affiliateService.getAffiliateDetails().subscribe(res => {
			console.log('affiliate details', res)
			this.userDetail = res[0];
		})
	}

	returnDate(date) {
		let d = new Date(date.split("+")[0]);
		return d.toDateString()
	}

	returnTime(date) {
		let d = new Date(date.split("+")[0]);
		return d.toTimeString().split(' ')[0];
	}

	getSSRPrice(ssr) {
		let meal = 0;
		let baggage = 0;
		let seat = 0;
		if (ssr != null) {
			for (let i = 0; i < ssr.length; i++) {
				const element = ssr[i];
				if (element.meals != null) {
					meal += Number(element.meals.code.totalFare);
				}
				if (element.baggages != null) {
					baggage += Number(element.baggages.code.totalFare);
				}
				if (element.seats != null) {
					seat += Number(element.seats.code.totalFare);
				}
			}
		}
		return Number(meal) + Number(baggage) + Number(seat)
	}

	getPerPrice(data) {
		let totalFare = 0
		const element = data;
		let keys = Object.keys(element.fare.taxes[0]);
		let count = 0;
		Object.values(element.fare.taxes[0]).forEach(function (v) {
			if (keys[count] != "MARKUP" && keys[count] != "COMMISSION" && keys[count] != "TOTAL" && keys[count] != "TDS" && keys[count] != "NET_PAYABLE" &&
				keys[count] != "ADMIN_MARKUP" && keys[count] != "OTHER_TAX" && keys[count] != "AFFILIATE_MARKUP" && keys[count] != "ADMIN_SERVICE_CHARGE") {
				totalFare += Number(v);
			}
			if (keys[count] == "OTHER_TAX" || keys[count] == "ADMIN_MARKUP" || keys[count] == "ADMIN_SERVICE_CHARGE") {
				totalFare += Number(v);
			}
			count++;
		})
		totalFare += Number(element.fare.base) + this.getSSRPrice(element.ssr);
		return totalFare;
	}


	getTotal(data, type) {
		this.totalFare = 0
		this.netCommission = this.getCommission(data);
		this.tds = this.getTDS(data);
		let total = 0;
		data = data.travellers;
		for (let i = 0; i < data.length; i++) {
			const element = data[i];
			if (element.status == 'TICKETED' || element.status == undefined) {
				let keys = Object.keys(element.fare.taxes[0]);
				let count = 0;
				Object.values(element.fare.taxes[0]).forEach(function (v) {
					if (keys[count] != "MARKUP" && keys[count] != "COMMISSION" && keys[count] != "TOTAL" && keys[count] != "NET_PAYABLE" &&
						keys[count] != "TDS" && keys[count] != "AFFILIATE_MARKUP") {
						total += Number(v);
					}
					// if (keys[count] == "OTHER_TAX"  || keys[count] == "ADMIN_SERVICE_CHARGE") {
					// 	total += Number(v);
					// }
					count++;
				})
				total += Number(element.fare.base) + this.getSSRPrice(element.ssr);
			
			}
		}
		total = total - this.netCommission + this.tds; 
		this.totalFare = Math.round(total)
		if (this.LedgerAmount != undefined) {
			let priceDiff = Number(this.LedgerAmount) - Math.round(Number(this.totalFare))
				if (priceDiff <= 2 && priceDiff >= -2) {
					this.showPriceWarning = false
				}
				else{
					this.showPriceWarning = true
				}
		}
		return this.totalFare
	}


	check(val) {
	}
	getssr(data, type) {
		if (data != null) {
			if (data[0][type] != null) {
				if (data[0][type].code != null && data[0][type].code != undefined) {
					return data[0][type].code.totalFare
				}
				else{
					return 0
				}
			}
			else {
				return 0
			}
		}
		else {
			return 0
		}
	}
	getTicket(data) {
		if (data.ticket != undefined) {
			if (data.ticket.number != null && data.ticket.number != null) {
				return data.ticket.number
			}
		}
	}
	checkIfNull(value) {
		if (value == undefined) {
			return '0';
		}
		else {
			return value;
		}
	}

	print() {
		if (this.showPriceWarning == true) {
			this.showPriceWarning = false;
			setTimeout(() => {
				window.print()
				this.showPriceWarning = true;
			}, 100)
		} else {
			window.print()
		}
	}


	getCommission(data){
		let totalComission = 0
		let totalPax = 0
		data.travellers.forEach(element => {
			if (element.type == 'ADT' || element.type == 'CHD') {
				totalPax++
			}
		});
		if (data.commission != undefined) {
			let commissionObj = data.commission
			totalComission = Number(commissionObj['customer'].cashbackAmount) + Number(commissionObj['customer'].iataAmount) + Number(commissionObj['customer'].plbAmount)
			totalComission = Number((totalComission - (totalComission * (18 / 100))).toFixed(2))

		}
		totalComission = (totalComission ? totalComission : 0);
		return totalComission
		
	}
	getTDS(data){
		let totalComission = 0
		let nettotalComission = 0
		let TDS = 0
		let totalPax = 0
		data.travellers.forEach(element => {
			if (element.type == 'ADT' || element.type == 'CHD') {
				totalPax++
			}
		});
		if (data.commission != undefined) {
			let commissionObj = data.commission
			totalComission = Number(commissionObj['customer'].cashbackAmount) + Number(commissionObj['customer'].iataAmount)+ Number(commissionObj['customer'].plbAmount)
			nettotalComission =  totalComission - (totalComission*(18/100))
			TDS =  Number((nettotalComission*(5/100)).toFixed(2))		
		}
		TDS = (TDS ? TDS : 0);
		return TDS;
	}

}