<!--   active-loader-style  -->
<!-- <div [ngStyle]="{'display': active ? 'block' : 'none' }" class="spinner">
  <mat-progress-spinner class="example-margin" [color]="color" [mode]="mode" [value]="value">
  </mat-progress-spinner>
</div> -->
<div class="page-loader" [ngClass]="active ? 'd-block' : 'd-none'">
    <div class="loader">
      <div
      class=" text-center" >
      <div class="img-container">
        <img
          src="../assets/v3images/animated-rocket.gif"
          alt=""
        />
      </div>
      <div
        class="animated-loader-text d-flex align-items-center justify-content-center"
      >
        <p class="text-white text-center" ><span class="text-bold d-block">Please Wait!</span><small>we are processsing data </small></p>
        <!-- <div id="wave">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div> -->
      </div>
    </div>
    </div>
  </div>
<section *ngIf="valid" [ngClass]="active ? 'content bookingsummary row active-loader-style content ' : 'content bookingsummary row'">
  <div class="container mat-white-input-form mat-white-input-tab">
      <form class="example-form travellerForm" [formGroup]="travellerForm">
          <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="bookingData.booking_res_message != undefined">
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                  <span class="sr-only">Close</span>
              </button>
              <strong>Failed due to : </strong> {{bookingData?.booking_res_message}}
          </div>
          <div class="bookingform col-8 p-0">
              <mat-accordion>
                  <mat-expansion-panel class="relative m-0" [expanded]="step == 0" (opened)="setStep(0)">
                      <mat-expansion-panel-header>
                          <mat-panel-title class="header-title">
                              Confirm Flight Details
                          </mat-panel-title>
                          <a class="editbtn" aria-controls="u804re-accordion" role="tab" id="u804re-accordion-label" aria-expanded="true" aria-selected="true">Edit</a>
                      </mat-expansion-panel-header>
                      <div class="">
                          <ng-container *ngFor="let flight of data">
                              <ng-container *ngFor="let segment of  flight['segments']; let i = index;">
                                  <div class="out-heading mt-4"><i class="fas fa-plane-departure"></i> {{segment?.originAirport?.code }}<i class="fas fa-plane"></i>{{segment?.destinationAirport?.code}} ({{segment?.legs[0]?.departureDateTime | date}})<span></span></div>
                                  <!-- <div *ngIf="i==1" class="in-heading"><span class="inbound-fix"></span><i class="fas fa-plane-arrival"></i> {{segment?.originAirport?.code }}<i class="fas fa-plane"></i>{{segment?.destinationAirport?.code}} ({{segment?.legs[0]?.departureDateTime | date}})<span></span></div> -->
                                  <div class="col-12">
                                      <div class="row border-bottom">
                                          <ng-container *ngFor="let leg of segment.legs;">
                                              <div class="col-2 flight-info">
                                                  <div class="row pb-4 flight-inner-info">
                                                      <div class="col-4 lf">

                                                          <img src="assets/images/{{leg.marketingAirlineCode}}.png" alt="" onError="this.src='assets/images/icon7.png'">
                                                      </div>
                                                      <div class="col p-0 rg">
                                                          <p>{{leg.marketingAirlineName}}</p>
                                                          <span>{{leg.marketingAirlineCode}} - {{leg.flightNumber}}</span><br>
                                                          <span class="operated-code" *ngIf="flight.airline?.code != 'SG' && flight.airline?.code != '6E' &&
                            leg.operatingAirlineCode != leg.marketingAirlineCode">OPERATED
                              BY : {{leg.operatingAirlineCode}}</span>
                                                      </div>
                                                  </div>
                                                  <p class="terminal">{{flight.serviceClass}} </p>
                                              </div>
                                              <div class="col-6 flight-detail-section">
                                                  <div class="row flight-detail-row">
                                                      <div class="col-4 dep-info">
                                                          <div class="minhight pb-1">
                                                              <p>{{leg.departureDateTime |  timeFormat}} {{leg.originAirport.code}}</p>
                                                              <p>{{leg.departureDateTime | dateGmtFormat}}</p>
                                                              <p class="terminaladd">{{leg.originAirport.city}}, {{leg.originAirport.name}}
                                                              </p>
                                                          </div>
                                                          <p class="terminal">Terminal {{leg.originAirport.terminal}}</p>
                                                      </div>
                                                      <div class="midd col-1">
                                                          <img src="assets/images/icon10.png" alt="">
                                                      </div>
                                                      <div class="col-4">
                                                          <div class="minhight pb-1">
                                                              <p>{{leg.arrivalDateTime | timeFormat}} {{leg.destinationAirport.code}} </p>
                                                              <p>{{leg.arrivalDateTime | dateGmtFormat}}</p>
                                                              <p class="terminaladd">{{leg.destinationAirport.city}}, {{leg.destinationAirport.name}}
                                                              </p>
                                                          </div>
                                                          <p class="terminal">Terminal {{leg.destinationAirport.terminal}}</p>
                                                      </div>
                                                  </div>

                                              </div>
                                             <!-- to show only one baggage -->
                                             <div class="col text-center  baggage-info" >
                                                  <ng-container *ngIf="leg.freeCheckInBaggage != null">
                                                    <span class="text-uppercase d-block checkin-baggage">Check-in
                                                      Baggage</span>
                                                  <span class="baggage-weight"><img class="baggage-icon"
                                                          src="../../../../assets/images/briefcase.png" /><span
                                                          class="weight d-block">{{leg?.freeCheckInBaggage?.unit}}&nbsp;{{leg?.freeCheckInBaggage?.unitTypeCode}}</span></span>
                                                  </ng-container>
                                              </div>

                                            <!-- uncomment below code to show all baggage -->
                                            <!-- <div class="col">
                                              <ng-container *ngFor="let item of data[0].baggageRules">
                                                <p class="kg">{{item.description}}</p>
                                              </ng-container>
                                            </div> -->
                                          </ng-container>
                                      </div>
                                  </div>
                              </ng-container>
                          </ng-container>
                          <div class="commnextbtn">
                              <button class="next" id="next" (click)="setStep(1)">NEXT</button>
                          </div>
                          <div style="clear: both;"></div>
                      </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel class="relative m-0" [expanded]="step === 1" (opened)="setStep(1)">
                      <mat-expansion-panel-header>
                          <mat-panel-title class="header-title">
                              Traveller Details
                          </mat-panel-title>
                          <a class="editbtn" aria-controls="u804re-accordion" role="tab" id="u804re-accordion-label" aria-expanded="true" aria-selected="true">Edit</a>
                      </mat-expansion-panel-header>
                      <div class="" formArrayName="passengerList">
                          <div class="passenger" *ngFor="let passenger of DynamicFormControls.controls; let i = index;">
                              <ng-container [formGroupName]="i">
                                  <div class="col-12">
                                      <input type="hidden" formControlName="travellerId">
                                      <div class="row">
                                          <div class="col">
                                             <p class="text-dark-purple text-bold ">Passenger {{i+1}} - {{passenger.value.passengerType}}</p>
                                          </div>
                                      </div>
                                      <div class="row  passenger-detail">
                                          <div class="col-1">
                                              <mat-form-field class="w-100">
                                                  <mat-select placeholder="Title" formControlName="title" required>
                                                      <mat-option value="Mr" (click)="setGender('Mr',i)">Mr.</mat-option>
                                                      <mat-option value="Ms" (click)="setGender('Ms',i)">Ms.</mat-option>
                                                      <mat-option value="Mrs" (click)="setGender('Mrs',i)">Mrs.</mat-option>
                                                  </mat-select>
                                                  <mat-error class="matError" *ngIf="(!paxSubmitted && !passenger.controls.title.valid && passenger.controls.title.touched) || (paxSubmitted && passenger.controls.title.errors != null)">
                                                          Required </mat-error>
                                              </mat-form-field>
                                          </div>
                                          <div class="col-3">
                                              <mat-form-field class="example-full-width w-100">
                                                  <!-- <input matInput placeholder="First Name" value="" formControlName="firstName" required> -->
                                                  <input type="text" placeholder="First Name" aria-label="Number" matInput formControlName="firstName" [formControl]="myDepControl" (keyup)="getTravellers($event)" (keyup)="bindTravellerFirstName($event,i, passenger.value.passengerType,passengerDetails)"
                                                      [matAutocomplete]="depAuto" oninput="this.value = this.value.toUpperCase()" autocomplete="NoAutocomplete"
                                                      pattern="^[a-zA-Z]+(([ ][a-zA-Z])?[a-zA-Z]*)*$">
                                                  <mat-autocomplete #depAuto="matAutocomplete">
                                                      <mat-option *ngFor="let option of passengerDetails" (onSelectionChange)="from !=undefined && setPaxDetails(option, i)" (focusout)="setPaxDetails(option,i)" [value]="option.firstname">
                                                          {{option.firstname}} {{option.lastname}}
                                                      </mat-option>
                                                  </mat-autocomplete>
                                                  <mat-error class="matError" *ngIf="((!passenger.controls.firstName.valid && passenger.controls.firstName.touched) || (paxSubmitted && passenger.controls.firstName.errors != null)) && !passenger.controls.firstName.hasError('pattern')">
                                                    First Name required </mat-error>
                                                <mat-error class="matError" *ngIf="passenger.controls.firstName.hasError('pattern')">
                                                    Invalid first name <i class="fas fa-info-circle" title="First Name must not contain any spacial character(s) or number(s)"></i> </mat-error>  
                                              </mat-form-field>
                                          </div>
                                          <div class="col-3">
                                              <mat-form-field class="example-full-width w-100">
                                                  <input matInput placeholder="Last Name" value="" formControlName="lastName" oninput="this.value = this.value.toUpperCase()" required autocomplete="NoAutocomplete"
                                                  pattern="^[a-zA-Z]+(([ ][a-zA-Z])?[a-zA-Z]*)*$" maxlength="35">
                                                  <mat-error class="matError" *ngIf="((!passenger.controls.lastName.valid && passenger.controls.lastName.touched) || (paxSubmitted && passenger.controls.lastName.errors != null)) && !passenger.controls.lastName.hasError('pattern')">
                                                    Last Name required </mat-error>
                                                <mat-error class="matError" *ngIf="passenger.controls.lastName.hasError('pattern')">
                                                    Invalid last name <i class="fas fa-info-circle" title="Last Name must not contain any spacial character(s) or number(s)"></i>  </mat-error>
                                              </mat-form-field>
                                          </div>
                                          <div class="col-2">
                                              <mat-form-field class="w-100">
                                                  <mat-select placeholder="Gender" formControlName="gender" required>
                                                      <mat-option value="Male">Male</mat-option>
                                                      <mat-option value="Female">Female</mat-option>
                                                  </mat-select>
                                                  <mat-error class="matError" *ngIf="(!passenger.controls.gender.valid && passenger.controls.gender.touched) || (paxSubmitted && passenger.controls.gender.errors != null)"> Gender required
                                                  </mat-error>
                                              </mat-form-field>
                                          </div>
                                      </div>
                                      <div class="row dom-traveler-details" *ngIf="!isDomestic">
                                        <div class="col-3">
                                          <mat-form-field class="w-100">
                                            <input matInput [matDatepicker]="picker2" class="departure"
                                              (focus)="picker2.open()" placeholder="DOB" formControlName="dob"
                                              autocomplete="NoAutocomplete">
                                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
                                            <mat-error class="matError" *ngIf="(!passenger.controls.dob.valid && passenger.controls.dob.touched) || (paxSubmitted && passenger.controls.dob.errors != null)">
                                              DOB is required </mat-error>
                                          </mat-form-field>
                                        </div>
                                        <div class="col-2">
                                              <mat-form-field class="example-full-width w-100">
                                                  <input matInput placeholder="Passport No." value="" pattern="^[a-zA-Z0-9]{1,9}" autocomplete="NoAutocomplete" formControlName="passportNo" oninput="this.value = this.value.toUpperCase()" required>
                                                  <mat-error class="matError" *ngIf="passenger.controls.passportNo.hasError('pattern') && passenger.controls.passportNo.touched">
                                                          Please enter valid passport number </mat-error>
                                              </mat-form-field>
                                          </div>
                                          <div class="col-2">
                                              <mat-form-field class="w-100">
                                                  <mat-select placeholder="Nationality" autocomplete="NoAutocomplete" formControlName="nationality" required>
                                                      <mat-option *ngFor="let country of nationalityArr" [value]="country.name">{{country.name}}</mat-option>
                                                  </mat-select>
                                                  <!-- <mat-error class="matError" *ngIf="(paxSubmitted && passenger.controls.nationality.errors != null)">
                                                      nationality is required </mat-error> -->
                                              </mat-form-field>
                                          </div>
                                          <div class="col-2">
                                              <mat-form-field class="w-100">
                                                  <mat-select placeholder="Issuing Country" autocomplete="NoAutocomplete" formControlName="issuingCountry" required>
                                                      <mat-option *ngFor="let country of nationalityArr" [value]="country.name">{{country.name}}</mat-option>
                                                  </mat-select>
                                                  <!-- <mat-error class="matError" *ngIf="(paxSubmitted && passenger.controls.issuingCountry.errors != null)">
                                                          issuing country is required </mat-error> -->
                                              </mat-form-field>
                                          </div>
                                          <div class="col-3">
                                              <mat-form-field class="w-100">
                                                  <input matInput [matDatepicker]="picker" autocomplete="off" class="departure" [min]="minDate" (focus)="picker.open()" placeholder="Expiry Date" formControlName="expiryDate" required>
                                                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                  <mat-datepicker #picker></mat-datepicker>
                                                  <mat-error class="matError" *ngIf="!paxSubmitted && !passenger.controls.expiryDate.valid && passenger.controls.expiryDate.touched">
                                                          expiry date is required </mat-error>
                                                          <mat-error class="matError" *ngIf="paxSubmitted && passenger.controls.expiryDate.errors != null && passenger.controls.passportNo.value != ''">
                                                          Invalid Date</mat-error>
                                              </mat-form-field>
                                          </div>
                                      </div>
                                      <div class="row" *ngIf="passenger.value.passengerType == 'Infant'">
                                          <div class="col-2">
                                              <mat-form-field class="w-100">
                                                  <mat-select placeholder="Associate Adult" formControlName="associateInfo" required>
                                                      <mat-option value="">Select Adult</mat-option>
                                                      <mat-option *ngFor="let value of dummyAdultNameArr  let i = index" [value]="i">{{value}}</mat-option>
                                                  </mat-select>
                                                  <!-- <mat-error *ngIf="!passenger.controls.lastName.valid && passenger.controls.lastName.touched" autocomplete="NoAutocomplete">
                                                    lastName required </mat-error> -->
                                              </mat-form-field>
                                          </div>
                                      </div>
                                  </div>
                                  <mat-expansion-panel class="m-0 mb-4" *ngIf="!isLCC || !inInBoundLCC">
                                      <mat-expansion-panel-header>
                                          <mat-panel-title class="subheader-title">
                                              Add Prefence & frequent Flyer <small>(optional)</small>
                                          </mat-panel-title>
                                      </mat-expansion-panel-header>
                                      <div class="">
                                          <div class="row">
                                             
                                              <div class="col-2 mealPreferences">
                                                  <mat-form-field class="w-100">
                                                      <mat-select placeholder="Meal Preferences" formControlName="mealServices">
                                                          <mat-option value="NP">No Preference</mat-option>
                                                          <mat-option value="VGML">Vegetarian Meal(INR 200)</mat-option>
                                                          <mat-option value="NVML">Non-Vegetarian Meal(INR 250)</mat-option>
                                                      </mat-select>
                                                      <!-- <mat-error *ngIf="!passenger.controls.issuingCountry.valid && passenger.controls.issuingCountry.touched"> issuing country is required </mat-error> -->
                                                  </mat-form-field>
                                              </div>
                                              <div class="col-2 specialServices">
                                                      <mat-form-field class="w-100">
                                                          <mat-select placeholder="Special Services" formControlName="specialServices">
                                                              <mat-option value="NP">No Preference</mat-option>
                                                              <mat-option value="BLND">Blind,specify whether or not accompained by guide dog</mat-option>
                                                              <mat-option value="DEAF">Deaf, specify if accompained by a guide dog</mat-option>
                                                              <mat-option value="STCR">Stretcher Passenger</mat-option>
                                                              <mat-option value="WCHR">Wheelchair - all the way to seat</mat-option>
                                                              <mat-option value="WCBD">Wheelchair - dry cell battery</mat-option>
                                                              <mat-option value="WCHC">Wheelchair - for ramp</mat-option>
                                                              <mat-option value="WCMP">Wheelchair - manual power(US carriers only)</mat-option>
                                                              <mat-option value="WCOB">Wheelchair - on board</mat-option>
                                                              <mat-option value="WCBW">Wheelchair - wet cell battery</mat-option>
                                                              <mat-option value="WCHS">Wheelchair - up and down steps</mat-option>
  
                                                          </mat-select>
                                                          <!-- <mat-error class="matError" *ngIf="!passenger.controls.issuingCountry.valid && passenger.controls.issuingCountry.touched"> issuing country is required </mat-error> -->
                                                      </mat-form-field>
                                                  </div>
                                              <div class="col-2 baggageSevices">
                                                  <mat-form-field class="w-100" *ngIf="airlineCode == '6E' || airlineCode == 'SG'">
                                                      <mat-select placeholder="Baggages Services" formControlName="baggageServices" (selectionChange)="baggageOnChange($event)">
                                                          <mat-option value="">No Preference</mat-option>
                                                          <mat-option *ngFor="let value of baggageArr" [value]="value.id">{{value.NAME}} {{value.UNIT}}{{value.UNIT_CODE}} - Rs {{value.PRICE}}</mat-option>
                                                      </mat-select>
                                                      <!-- <mat-error > issuing country is required </mat-error> -->
                                                  </mat-form-field>
                                              </div>
                                              <div class="col-2 frequentflyers" *ngIf="airlineCode != '6E' && airlineCode != 'SG'">
                                                      <mat-form-field class="w-100">
                                                          <mat-select placeholder="Frequent Flyers" formControlName="frequentflyers">
                                                              <mat-option value="NP">No Preference</mat-option>
                                                              <mat-option *ngFor="let value of airlineArr" [value]="value['Airline 2LTR']">{{value['Airline Name']}}</mat-option>
                                                          </mat-select>
                                                      </mat-form-field>
                                                  </div>
                                                  <div class="col-2 frequentflyersNumber" *ngIf="airlineCode != '6E' && airlineCode != 'SG'">
                                                      <mat-form-field class="w-100">
                                                              <input matInput maxlength="10" placeholder="FF Number" value="" formControlName="frequentflyersNumber" oninput="this.value = this.value.toUpperCase()">
                                                      </mat-form-field>
                                                  </div>

                                          </div>
                                          <div style="clear: both;"></div>
                                      </div>
                                  </mat-expansion-panel>
                              </ng-container>
                          </div>
                          <div class="commnextbtn">
                              <button class="next" id="next" (click)="setStep(2)">NEXT</button>
                          </div>
                          <div style="clear: both;"></div>
                      </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel class="relative m-0" [expanded]="step == 2" (opened)="setStep(2)">
                      <mat-expansion-panel-header>
                          <mat-panel-title class="header-title">
                              Personal Contact Details
                          </mat-panel-title>
                          <a class="editbtn" aria-controls="u804re-accordion" role="tab" id="u804re-accordion-label" aria-expanded="true" aria-selected="true">Edit</a>
                      </mat-expansion-panel-header>
                      <div class="">
                          <div class="row">
                              <div class="col">
                                  <mat-form-field class="example-full-width w-100">
                                      <input matInput placeholder="Email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$" oninput="this.value = this.value.toUpperCase()" formControlName="email" value="" autocomplete="NoAutocomplete">
                                      <mat-error class="matError" class="error" *ngIf="(!email.valid && email.touched) || (contactSubmitted && email.errors != null)">
                                              please enter valid email </mat-error>
                                  </mat-form-field>
                              </div>
                              <div class="col">
                                  <mat-form-field class="example-full-width w-100">
                                      <input matInput placeholder="Mobile" value="" oninput="this.value = this.value.toUpperCase()" formControlName="mobile" autocomplete="NoAutocomplete">
                                      <mat-error class="matError" class="error" *ngIf="(!mobile.valid && (mobile.dirty || mobile.touched)) ||  (contactSubmitted && mobile.errors != null)">
                                              please enter valid mobile
                                          </mat-error>
                                  </mat-form-field>
                              </div>
                          </div>
                          <div style="clear: both;"></div>
                      </div>
                      <div class="commnextbtnCustom text-right">
                          <button class="next" id="next" (click)="setStep(3)">NEXT</button>
                      </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel class="relative m-0" [expanded]="step == 3" (opened)="setStep(3)">
                      <mat-expansion-panel-header>
                          <mat-panel-title class="header-title" *ngIf="isSME">
                                <span>GST Details </span><small class="ml-2">(Mandatory)</small>
                            </mat-panel-title>
                            <mat-panel-title class="header-title" *ngIf="!isSME">
                                <span>GST Details</span> <small class="ml-2">(Optional)</small>
                            </mat-panel-title>
                          <a class="editbtn" aria-controls="u804re-accordion" role="tab" id="u804re-accordion-label" aria-expanded="true" aria-selected="true">Edit</a>
                      </mat-expansion-panel-header>
                      <div class="">
                          <div class="row">
                              <div class="col">
                                  <mat-form-field class="example-full-width w-100">
                                      <input type="text" placeholder="Corporate Name" aria-label="Number" matInput formControlName="corporateName" (keyup)="bindGSTInfo($event)" [formControl]="myGstControl" (blur)="bindTravellerGstName($event)" [matAutocomplete]="gstAuto" oninput="this.value = this.value.toUpperCase()"
                                          autocomplete="NoAutocomplete">
                                      <mat-autocomplete #gstAuto="matAutocomplete">
                                          <mat-option *ngFor="let option of gstArr" (onSelectionChange)="from !=undefined && setGstDetails(option)" (focusout)="setGstDetails(option)" [value]="option.name">
                                              {{option.name}}
                                          </mat-option>
                                      </mat-autocomplete>
                                  </mat-form-field>
                              </div>
                              <div class="col">
                                  <mat-form-field class="example-full-width w-100">
                                      <input matInput placeholder="Corporate GST Number" value="" formControlName="corporateGstNumber" oninput="this.value = this.value.toUpperCase()" autocomplete="NoAutocomplete">
                                  </mat-form-field>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col">
                                  <mat-form-field class="example-full-width w-100">
                                      <input matInput placeholder="Mobile Number" value="" formControlName="corporateMobile" oninput="this.value = this.value.toUpperCase()" autocomplete="NoAutocomplete">
                                  </mat-form-field>
                                  <mat-error class="error" *ngIf="!corporateMobile.valid && (corporateMobile.dirty || corporateMobile.touched)">
                                      please enter valid mobile
                                  </mat-error>
                              </div>
                              <div class="col">
                                  <mat-form-field class="example-full-width w-100">
                                      <input matInput placeholder="Corporate Email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$" value="" formControlName="corporateEmail" oninput="this.value = this.value.toUpperCase()" autocomplete="NoAutocomplete">
                                  </mat-form-field>
                                  <mat-error class="error" *ngIf="!corporateEmail.valid && corporateEmail.touched">
                                      please enter valid email </mat-error>
                              </div>
                          </div>
                          <div style="clear: both;"></div>
                      </div>
                      <div class="row justify-content-between mx-0">
                          <div class="commnextbtnCustom">
                              <button class="next" id="next" *ngIf="allgstArr.length > 0" data-toggle="modal" data-target="#modalGST">View
                                  Saved</button>
                          </div>
                          <div class="commnextbtnCustom">
                              <button class="next" id="next" (click)="setStep(4)">NEXT</button>
                          </div>
                      </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel class="relative m-0" [expanded]="step == 4" (opened)="setStep(4)">
                      <mat-expansion-panel-header>
                          <mat-panel-title class="header-title">
                              <span>Add-Ons</span> <small class="ml-2">(Optional)</small> <span *ngIf="ssrAvailability != undefined" class="customAnimated ml-3  tada infinite" [ngClass]="{'text-warning': data[0].priceType =='SPICEMAX','text-success' : data[0].priceType !='SPICEMAX'}">{{warningMessage}}</span>
                          </mat-panel-title>
                          <a class="editbtn" aria-controls="u804re-accordion" role="tab" id="u804re-accordion-label" aria-expanded="true" aria-selected="true">Edit</a>
                      </mat-expansion-panel-header>
                      <div class="">

                          <mat-tab-group *ngIf="airlineCode != '2T'">
                              <mat-tab label="Add Meals" *ngIf="isLCC">
                                  <ng-container>
                                      <div class="mt-5 seat-loader" *ngIf="ssrAvailability.length == 0">
                                          <img *ngIf="!showloadError" src="../../../assets/images/loader2.gif" alt="">
                                          <span *ngIf="showloadError">Unable to fetch Meal</span>
                                      </div>
                                  </ng-container>
                                  <app-re-attemp-meal [ssrAvailability]="ssrAvailability" (callMealEvent)="getMealEvent($event)"></app-re-attemp-meal>
                              </mat-tab>
                              <mat-tab label="Add Baggage">
                                  <ng-container>
                                      <div class="mt-5 seat-loader" *ngIf="ssrAvailability.length == 0">
                                          <img *ngIf="!showloadError" src="../../../assets/images/loader2.gif" alt="">
                                          <span *ngIf="showloadError">Unable to fetch Baggage</span>
                                      </div>
                                  </ng-container>
                                  <app-re-attemp-baggage [ssrAvailability]="ssrAvailability" (callBaggageEvent)="getBaggageEvent($event)"></app-re-attemp-baggage>
                              </mat-tab>
                              <mat-tab label="Add Seats" *ngIf="isLCC">
                                  <ng-container>
                                      <div class="mt-5 seat-loader" *ngIf="seatMapArr.length == 0">
                                          <img *ngIf="!showseatloadError" src="../../../assets/images/loader2.gif" alt="">
                                          <span *ngIf="showseatloadError">Unable to fetch Seats</span>
                                      </div>
                                  </ng-container>
                                  <app-re-attemp-seat [seatMapArr]="seatMapArr" [passengerArr]="passengerArr" (callSeatEvent)="getSeatEvent($event)">
                                  </app-re-attemp-seat>
                              </mat-tab>
                          </mat-tab-group>
                        
                          <div class="commnextbtn">
                              <button class="next" id="next" (click)="setStep(5)">NEXT</button>
                          </div>
                          <div style="clear: both;"></div>
                      </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel class="relative m-0" [expanded]="step == 5" (opened)="setStep(5)">
                      <mat-expansion-panel-header>
                          <mat-panel-title class="header-title">
                              PAYMENT
                          </mat-panel-title>
                          <a class="editbtn" aria-controls="u804re-accordion" role="tab" id="u804re-accordion-label" aria-expanded="true" aria-selected="true">Edit</a>
                      </mat-expansion-panel-header>
                      <div class="" *ngFor="let flight of data let i = index;">
                        <div [ngClass]="paymentIndex == i   ? 'outbound mt-4' : 'disable_payment outbound mt-4'">
                          <div class="out-heading"><i class="fas fa-plane-departure"></i>{{flight.segments[0]?.originAirport?.code }}<i class="fas fa-plane"></i>{{flight.segments[0]?.destinationAirport?.code}}
                              <strong class="hold-btn"
                                  *ngIf="flight['status'] == 'HOLD'">{{flight['status']}}</strong>
                              <strong class="ticketed-btn"
                                  *ngIf="flight['status'] == 'TICKETED'">{{flight['status']}}</strong>
                              <strong class="failed-btn"
                                  *ngIf="flight['status'] == 'FAILED'">{{flight['status']}}</strong>
                              <span></span>
                          </div>
                          <mat-radio-group class="">
                              <div class="row">
                                  <div class="col-3" [ngClass]="{cannotSelect : invalidCredit}">
                                      <mat-radio-button *ngIf="isLCC || isDiAccount" (click)="setPaymentMethod('agent-balance',true,i)" [checked]="!invalidCredit"
                                          value="creditbalance"> Balance
                                      </mat-radio-button>
                                      <!-- <mat-radio-button *ngIf="!isLCC && !isDiAccount"  (click)="setPaymentMethod('agent-balance',true,i)" [checked]="!invalidCredit"
                                          value="creditbalance">Credit Balance
                                      </mat-radio-button> -->
                                      <div _ngcontent-c15="" class="w-100 payImg">
                                          <span *ngIf="!invalidCredit" class="balance">Available balance: <span
                                                  class="amount">
                                                  &#8377;{{creditLimit | number : '1.0-0'}}</span></span>
                                          <span *ngIf="invalidCredit" class="low-balance">Low balance: <span
                                                  class="amount">
                                                  &#8377;{{creditLimit | number : '1.0-0'}}</span></span>

                                      </div>
                                  </div>
                              </div>
                              <div class="row m-0">
                                  <div _ngcontent-c15="" class="w-100 payImg col-2">
                                      <span class="refresh-btn btn btn-sm  light-sky-blue-bg text-center"><span class="ref-icon"><img
                                                  src="../../../assets/images/refresh-button.png"></span><a
                                              (click)="refreshCreditLimit(i)">Refresh</a></span>
                                  </div>
                              </div>
                          </mat-radio-group>


                          <div class="d-flex mt-3" *ngIf="flight.fares[0]?.fareType == 'SME'">
                              <span class="et-info"><b>You did not opt for free meal (s) available</b>, Please
                                  choose one as per your traveller’s preference under ADD ONS ( Optional)
                                  section!</span>
                              <br>
                          </div>

                          <div class="row">
                              <div class="col">
                                  <div class="commnextbtn p-1" *ngIf="paymentIndex == i">
                                      <button type="submit"
                                      *ngIf="!invalidCredit && airlineCode != 'TG'"
                                          (click)="issueTicket(travellerForm.value, false,i,false,true)">Issue
                                          Ticket</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                      </div>
                      <div style="clear: both;"></div>
                  </mat-expansion-panel>
              </mat-accordion>
          </div>
      </form>
      <div class="right-side float-right">
          <ng-container *ngFor="let flight of data; let i = index;">
            <ng-container *ngIf="fareData[i]">
                <app-traveller-fare [direction]="'Departure'" [flightData]="getFlightData(flight)" [legs]="legs" [adultBaseFare]="fareData[i]['adultBaseFare']" [adultTaxFare]="fareData[i]['adultTaxFare']" [adultTotalFare]="fareData[i]['adultTotalFare']" [childBaseFare]="fareData[i]['childBaseFare']" [childTaxFare]="fareData[i]['childTaxFare']" [childTotalFare]="fareData[i]['childTotalFare']"
                [infBaseFare]="fareData[i]['infBaseFare']" [infTaxFare]="fareData[i]['infTaxFare']" [infTotalFare]="fareData[i]['infTotalFare']" [noOfAdult]="noOfAdult" [noOfChild]="noOfChild" [noOfInfant]="noOfInfant" [seatCharges]="seatCharges" [mealCharges]="mealCharges" [baggageCharges]="baggageCharges"
                [netTotalayable]="fareData[i]['netTotalayable']" [netCommission]="flight.fares[0].fareComponents.COMMISSION" [tds]="flight.fares[0].fareComponents.TDS" [netPayable]="fareData[i]['netPayable']" [airlineCode]="airlineCode" [isOffer]="isOffer" [isRoundTrip]="false" [isSpecialRoundTrip]="false" [affiliateMarkup]="affiliateMarkup"></app-traveller-fare>
              </ng-container>
        </ng-container>

          <app-total-traveller-fare [totalPayable]="netTotalayable">
          </app-total-traveller-fare>

      </div>
  </div>
</section>
<div class="modal fade" id="modalGST" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
          <div class="modal-header">
              <!-- <h5 class="modal-title">
      Confirmation
    </h5> -->
              <button type="button" class="close" data-dismiss="modal" #closeactiveBtn aria-label="Close">
      <span aria-hidden="true">
        ×
      </span>
    </button>
          </div>
          <div class="modal-body">
              <table class="table table-light table-responsive">
                  <thead class="thead-light">
                      <tr>
                          <th>#</th>
                          <th>Corporate Name</th>
                          <th>Gst Number</th>
                          <th>Mobile Number</th>
                          <th>Email</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of allgstArr ; let i = index" class="radio-group">
                          <td><label for="radio1"><input type="radio" name="radio" id="radio1" (click)="selectGST(item)"></label></td>
                          <td>{{item.name}}</td>
                          <td>{{item.gst_number}}</td>
                          <td>{{item.mobile}}</td>
                          <td>{{item.email}}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-info" id="btnConfirm" (click)="setGST(selectedGST)">
      Select
    </button>
              <button type="button" class="btn btn-danger" data-dismiss="modal">
      Close
    </button>
          </div>
      </div>
  </div>
</div>


<!-- Button trigger modal -->
<button type="button" hidden #showPaymentInfo class="btn btn-primary btn-lg" data-toggle="modal" data-target="#paymentModal"></button>

<!-- Modal -->
<div id="paymentModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="warn-icon"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></div>
            <div class="warning-con">
                Your account will be debited &nbsp; <strong> &#8377;  {{onlinePaymentAmount[selectedFlightDirectionForPayment]}}</strong> which includes {{environment.payment_gateway_charges}}% of transaction charge.
            </div>
            <div class="modal-footer float-center">
                <button type="button" class="btn btn-red btn-close" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-default btn-info" (click)="doOnlinePayment()">Proceed to pay</button>
            </div>
        </div>
    </div>
</div>



<!-- Button to trigger duplicate booking modal -->
<button type="button" hidden #showDuplicateBooking class="btn btn-primary btn-lg" data-toggle="modal" data-target="#duplicateBookingModal"></button>

<div id="duplicateBookingModal" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="warn-icon"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></div>
            <div class="warning-con alert-danger">
                <strong>Alert -</strong> System detected that you already booked for same criteria under booking no. <strong (click)="viewBooking(duplicateBookingMsg?.reference_no)" title="Click here to view booking" class="cursor-pointer" >{{duplicateBookingMsg?.reference_no}}</strong>.
            </div>
            <div class="modal-footer float-center">
                <button type="button" class="btn btn-default btn-success " routerLink=""   data-dismiss="modal">Cancel <small>(Stop Duplicate Booking)</small></button>
                <button type="button" class="btn btn-red btn-close" data-dismiss="modal" (click)="issueTicket(travellerForm.value, duplicateBookingMsg?.isHold,duplicateBookingMsg?.direction,true,false)">Continue <small>(No, I would like to book duplicate)</small></button>
            </div>
        </div>
    </div>
</div>