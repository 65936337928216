<!-- <div class="container-fluid header-height">
	<div class="row section-header">
		<app-menu></app-menu>
	</div>
</div> -->
<div class="container-fluid mt-2">
	<div class="inner-container row m-0">
		<div class="left-container float-left col-lg-8 pl-0">
			<app-detail
			[totalNetPayable]="totalNetPayable"
			[totalServiceCharges]="totalServiceCharges"
			[totalAffiliateMarkup]="totalAffiliateMarkup"
			[invalidCredit]="invalidCredit"
			[bookingArr]="bookingArr"
			[bookingHistoryArr]="bookingHistoryArr"
			[bookedWithAffiliateCreds]="bookedWithAffiliateCreds"
			[affiliateDetails]="affiliateDetails"
			[isLCC]="isLCC"
			(calSSREvent)="calculateAddOnPrice($event)"
			(resetData)="getData()"
			[bookingActivity]="bookingHistoryArr"
			[totalAffilateAdonMarkup] = "totalAffilateAdonMarkup"
			></app-detail>
		</div>
		<div id="sidebar" class="right-container float-right col-lg-4">
			<app-pricing
			[bookingArr]="bookingArr"
			[travellerFareArray]="travellerFareArray"
			[totalNetPayable]="totalNetPayable"
			[totalServiceCharges]="totalServiceCharges"
			[totalGstOnServiceCharges]="totalGstOnServiceCharges"
			[totalAffiliateMarkup]="totalAffiliateMarkup"
			[totalAffilateAdonMarkup] = "totalAffilateAdonMarkup"
			[totalCommission]="totalCommission"
			[totalTds]="totalTds"
			[totalPublishedFare]="totalPublishedFare"
			[resIssuanceCharges]="resIssuanceCharges"
			(updatedBookingArr) = "updateBookingArr($event)"
			[totalNetPayableRoundOff] = "totalNetPayableRoundOff"
			[resIssuanceFareDiffernceCharges] = "resIssuanceFareDiffernceCharges"

			[totalAirlineCancellationCharges]="totalAirlineCancellationCharges"
			[totalMyCancellationCharges]="totalMyCancellationCharges"
			[totalGstOncancellationCharges]="totalGstOncancellationCharges"
			[totalRefundAmount]="totalRefundAmount"
			[totalTravelledFare]="totalTravelledFare"
			[totalRefundedCommission]="totalRefundedCommission"
			[totalRefundedTds]="totalRefundedTds"
			[mealCharges]="mealCharges"
			[seatCharges]="seatCharges"
			[baggageCharges]="baggageCharges"
			[additionalBaggageCharges]="additionalBaggageCharges"
			[ancillaryCharges]="ancillaryCharges"
			[total_insured_person_amount_sum] = "total_insured_person_amount_sum"
			></app-pricing>

			<app-addon-pricing  *ngIf="addOnsSelected"
			[seatAmount] = "seatAmount"
			[mealAmount] = "mealAmount"
			[baggageAmount] = "baggageAmount"
			[additionalBaggageAmount] = "additionalBaggageAmount"
			[ancillaryAmount] = "ancillaryAmount"
			[totalUpdatedAmount] = "totalUpdatedAmount"
			[totalAddOnPrice] = "totalAddOnPrice"
			></app-addon-pricing>

		</div>
		<!-- <div>
			<button type="button" class="btn btn-primary" (click)="toggleStrip()">Cabs Slick</button>
		</div> -->
		<div class="strip cabSlick" [ngClass]="{ 'show': isVisible }">
			<div class="car-road">
				<div class="car">
				  <div class="car-top">
					<div class="window">
					</div>  
				  </div>  
				  <div class="car-base">
				  </div>
				  <div class="wheel-left wheel">
					 <div class="wheel-spike">
					 </div> 
					 <div class="wheel-center">
					 </div>
				  </div>
				  <div class="wheel-right wheel">
					 <div class="wheel-spike">
					 </div> 
					<div class="wheel-center">
					 </div>
				  </div>
				  <span class="light"></span>
				  <div class="head-light"></div>
				</div> 
				<div class="road">
				</div>
			  </div> 
			<div class="container" style="padding: 0;width: 100%!important;">
				<mat-tab-group class="mat-group">
					<mat-tab>
						<div>
							<form class="">
								<div class="">
									<div class="row mx-auto align-items-center">
										<div class="field col-lg-1 col-md-2"> 
											<img src="../../../assets/images/aadeshCabs.png" width="150px" height="" alt="" />
										</div>
										<div class="field col-lg-9  col-md-7">
											<p class="car-message ">Experience our Seamless Airport Pickup Service</p>
											<p class="car-message" style="color: #FF0062!important;"> the ultimate convenience for your customer's journey ahead!</p>
										</div>
										<div class="field col-lg-2  col-md-3">
											<button (click)="submitCar()" type="submit" class="submit btn btn-primary">Search Cabs</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</mat-tab>
				</mat-tab-group> 
		</div>
		</div>
	</div>
</div>