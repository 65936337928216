<div class="page-loader" [ngClass]="active ? 'd-block' : 'd-none'">
    <div class="loader">
      <div
      class=" text-center" >
      <div class="img-container">
        <img
          src="../assets/v3images/animated-rocket.gif"
          alt=""
        />
      </div>
      <div
        class="animated-loader-text d-flex align-items-center justify-content-center"
      >
        <p class="text-white text-center" ><span class="text-bold d-block">Please Wait!</span><small>we are processsing data </small></p>
      </div>
    </div>
    </div>
  </div>

<div class="alert alert-danger" role="alert"
	*ngIf="showError == true" [innerHtml]="errorMessage">
</div>

<ng-container *ngIf="retrievalErrorMessage else dataTemp">
	<div class="found-data">
		<img src="../../../assets/v3images/found.gif" alt="found Data" />
		<p class="error-message" role="alert"
			[innerHtml]="retrievalErrorMessage">
		</p>
		<button type="button" class="btn text-white bg-success" (click)="getData()">Refresh</button>
	</div>
</ng-container>

<ng-template #dataTemp>
	<div class="container-fluid mt-2">
		<div class="inner-container row m-0">
			<div class="left-container float-left col-lg-8 pl-0">
				<app-pnr-retrieve-detail
				[totalNetPayable]="totalNetPayable"
				[totalServiceCharges]="totalServiceCharges"
				[totalAffiliateMarkup]="totalAffiliateMarkup"
				[invalidCredit]="invalidCredit"
				[bookingArr]="bookingArr"
				[bookingHistoryArr]="bookingHistoryArr"
				[bookedWithAffiliateCreds]="bookedWithAffiliateCreds"
				[affiliateDetails]="affiliateDetails"
				[isLCC]="isLCC"
				[selectedFlightDataIndex]="selectedFlightDataIndex"
				[upsellFaresLoading]="upsellFaresLoading"
				(calSSREvent)="calculateAddOnPrice($event)"
				(resetData)="getData()"
				[bookingActivity]="bookingHistoryArr"
				[totalAffilateAdonMarkup] = "totalAffilateAdonMarkup"
				(selectBookingData)="selectBookingData($event)"
				></app-pnr-retrieve-detail>
			</div>
			<div id="sidebar" class="right-container float-right col-lg-4">
				<app-pnr-retrieve-pricing
				[bookingArr]="bookingArr"
				[travellerFareArray]="travellerFareArray"
				[totalNetPayable]="totalNetPayable"
				[totalServiceCharges]="totalServiceCharges"
				[totalGstOnServiceCharges]="totalGstOnServiceCharges"
				[totalAffiliateMarkup]="totalAffiliateMarkup"
				[totalAffilateAdonMarkup] = "totalAffilateAdonMarkup"
				[totalCommission]="totalCommission"
				[totalTds]="totalTds"
				[totalPublishedFare]="totalPublishedFare"
				[resIssuanceCharges]="resIssuanceCharges"
				(updatedBookingArr) = "updateBookingArr($event)"
				[totalNetPayableRoundOff] = "totalNetPayableRoundOff"
				[resIssuanceFareDiffernceCharges] = "resIssuanceFareDiffernceCharges"
				[totalAirlineCancellationCharges]="totalAirlineCancellationCharges"
				[totalMyCancellationCharges]="totalMyCancellationCharges"
				[totalGstOncancellationCharges]="totalGstOncancellationCharges"
				[totalRefundAmount]="totalRefundAmount"
				[totalTravelledFare]="totalTravelledFare"
				[totalRefundedCommission]="totalRefundedCommission"
				[totalRefundedTds]="totalRefundedTds"
				[mealCharges]="mealCharges"
				[seatCharges]="seatCharges"
				[baggageCharges]="baggageCharges"
				[additionalBaggageCharges]="additionalBaggageCharges"
				[ancillaryCharges]="ancillaryCharges"
				[total_insured_person_amount_sum] = "total_insured_person_amount_sum"
				></app-pnr-retrieve-pricing>
	
				<app-pnr-retrieve-selected-flight 
				[invalidCredit]="invalidCredit"
				[totalCommission]="totalCommission"
				(callDoBooking)="issueTicket()"
				[selectedFlightData]="selectedFlightData"
				></app-pnr-retrieve-selected-flight>
	
			</div>
		</div>
	</div>
</ng-template>

