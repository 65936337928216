import { Component, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-whatsapp-popup',
  templateUrl: './whatsapp-popup.component.html',
  styleUrls: ['./whatsapp-popup.component.scss']
})
export class WhatsappPopupComponent {
  @Input() isPopupVisible: boolean = false;
  showMessagePopup: boolean = false;
  message: string = '';


  constructor() { }

  ngOnInit() {
    }
 
  redirectToWhatsApp(type: string) {
    const now = moment();
    const dayOfWeek = now.day(); // 0 (Sunday) to 6 (Saturday)
    const hours = now.hours();

    let isOpen = false;
  
    let phoneNumber: string;
    let defaultMessage: string;

    const workingHours = [
      { start: 10, end: 20 }, // Monday to Thursday
      { start: 5, end: 20 },  // Friday
      { start: 10, end: 20 }, // Saturday
      { start: 10, end: 18 } // Sunday 
    ];

    if (dayOfWeek >= 1 && dayOfWeek <= 4) { // Monday to Thursday
      if ((hours === workingHours[0].start && now.minutes() >= 15) || (hours > workingHours[0].start && hours < workingHours[0].end) || (hours === workingHours[0].end && now.minutes() <= 0)) {
        isOpen = true;
      }
    }else if (dayOfWeek === 5) { // Friday
      if ((hours === workingHours[1].start && now.minutes() >= 30) || (hours > workingHours[1].start && hours < workingHours[1].end) || (hours === workingHours[1].end && now.minutes() <= 0)) {
        isOpen = true;
      }
    }else if (dayOfWeek === 6) { // Saturday
      if ((hours === workingHours[2].start && now.minutes() >= 15) || (hours > workingHours[2].start && hours < workingHours[2].end) || (hours === workingHours[2].end && now.minutes() <= 0)) {
        isOpen = true;
      }
    }else if (dayOfWeek === 7) { // Sunday
      if ((hours === workingHours[3].start && now.minutes() >= 30) || (hours > workingHours[3].start && hours < workingHours[3].end) || (hours === workingHours[3].end && now.minutes() <= 30)) {
        isOpen = true;
      }
    }    

    if (type === 'GDS') {
      phoneNumber = '912812411111';
      defaultMessage = "Hello GDS team, I have a few questions regarding your services.";
    } else if (type === 'LCC') {
      phoneNumber = '912812411111';
      defaultMessage = "Hello LCC team, I need some information about your services.";
    }

    // if (!isOpen) {
    //   this.showMessage("Office is Closed, Pls Contact for GDS on 9909962461 and for LCC on 9909962680")
    //   return;
    // }

    window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(defaultMessage)}`, '_blank');
  }

  showMessage(message: string) {
    console.log(message,'message');
    
    this.message = message; 
    this.showMessagePopup = true;
  }

  hideMessagePopup() {
    this.showMessagePopup = false;
  }


}


