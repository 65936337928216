import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/public_api';
import { Router } from '@angular/router';
import * as moment from 'moment'
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import { BookingRequestService } from 'src/app/services/booking-request.service';


@Component({
  selector: 'app-financials',
  templateUrl: './financials.component.html',
  styleUrls: ['./financials.component.scss']
})
export class FinancialsComponent implements OnInit {
  isUnpaidInvoice: boolean = false; 
  isRecentPayment: boolean = true; 
  innerHeight: string = 150 + 'px';
	bsConfig: Partial<BsDatepickerConfig>;
  ledgerForm:FormGroup
  invoiceCreditNoteForm:FormGroup
  snackBarMessage = ''
  paymentArray = []
  checkLimitData = {}
  currentDate = moment().format("LL")
  ledgerDateDiff = 31;
  transactionsDateDiff = 7
  constructor(private fb: FormBuilder,private router: Router,private commonService:CommonService,private bookingRequestService:BookingRequestService) { }

  ngOnInit() {
    let ledgerFromDate = new Date()
		let day = ledgerFromDate.getDate() - this.ledgerDateDiff;
    ledgerFromDate.setDate(day);
    let ledgerToDate = new Date()

    let fromDate = new Date()
		day = fromDate.getDate() - this.transactionsDateDiff;
    fromDate.setDate(day);
    let toDate = new Date()

    
    this.ledgerForm = this.fb.group({
      "type":['ledger'],
      "from_date":[ledgerFromDate],
      "to_date":[ledgerToDate]
    })

    this.invoiceCreditNoteForm = this.fb.group({
      "type":['invoice'],
      "from_date":[fromDate],
      "to_date":[toDate]
    })

    this.getRecentPayment()
    this.bookingRequestService.getCheckLimit.subscribe((res)=>{
       this.checkLimitData = res
    })
    this.bsConfig = Object.assign({ dateInputFormat: 'DD/MM/YYYY' },{ showWeekNumbers:false })

    
  }

  setDate(FormGroupName,controlName,value){
      if(FormGroupName=='ledgerForm'){
        let fromDate = moment(this.ledgerForm.value.from_date)
        let toDate =   moment(this.ledgerForm.value.to_date)
        if(controlName=='from_date'){
            fromDate = moment(value)
        }else{
          toDate = moment(value)
        }

        let diff = toDate.diff(fromDate,'days')  
        setTimeout(()=>{
            if(diff>this.ledgerDateDiff || diff<0){
              if(controlName=='from_date'){
                let from_date = fromDate.add(this.ledgerDateDiff,'days').format("MM-DD-YYYY")
                this.ledgerForm.controls['to_date'].setValue(new Date(from_date))
              }else{
                  let to_date = moment(toDate).subtract(this.ledgerDateDiff,'days').format("MM-DD-YYYY")
                  this.ledgerForm.controls['from_date'].setValue(new Date(to_date))
              }
            }
           
        },0)
       
      }else{
        let fromDate = moment(this.invoiceCreditNoteForm.value.from_date)
        let toDate =   moment(this.invoiceCreditNoteForm.value.to_date)
        if(controlName=='from_date'){
            fromDate = moment(value)
        }else{
          toDate = moment(value)
        }

        let diff = toDate.diff(fromDate,'days')  
        setTimeout(()=>{
          if(diff>this.transactionsDateDiff || diff<0){
            if(controlName=='from_date'){
              let from_date = fromDate.add(this.transactionsDateDiff,'days').format("MM-DD-YYYY")
              this.invoiceCreditNoteForm.controls['to_date'].setValue(new Date(from_date))
            }else{
                let to_date = moment(toDate).subtract(this.transactionsDateDiff,'days').format("MM-DD-YYYY")
                this.invoiceCreditNoteForm.controls['from_date'].setValue(new Date(to_date))
            }
          }
        },0)
      }
  }
  searchLedger(values){
    console.log(values)
    values.from_date = moment(values.from_date).format('YYYY-MM-DD')
    values.to_date = moment(values.to_date).format('YYYY-MM-DD')
    let url = `/ledger?from_date=${values.from_date}&to_date=${values.to_date}`

		this.router.navigateByUrl(url)

  }

  getRecentPayment(){
    let userInfo = JSON.parse(localStorage.getItem('user_details'));
    let accountCode = userInfo && userInfo.xlAccountCode?userInfo.xlAccountCode:''
    if(accountCode){
      let url = `paymententryv3/?limit=20&account_code=${accountCode}`
      this.commonService.getv3ocount(url).subscribe((res : any)=>{
        let resp = res
        if(resp &&  resp.length>0){
          this.paymentArray = resp
        }else{
          this.paymentArray = []
        }
      },(error)=>{
        this.paymentArray = []
      })
    }
  }


  downloadTransaction(){
    this.snackBarMessage = "download started..."
    this.showSnackBar()
     let userInfo = JSON.parse(localStorage.getItem('user_details'));
     let url = `${environment.v3accountUrl}/bulk_email/download`
     let data = {
        "start_date": moment(this.invoiceCreditNoteForm.value.from_date).format("YYYY-MM-DD"),
        "end_date": moment(this.invoiceCreditNoteForm.value.to_date).format("YYYY-MM-DD"),
        "transaction_types": this.invoiceCreditNoteForm.value.type=='both'?['invoice','credit_note']:[this.invoiceCreditNoteForm.value.type],
        "client_code":userInfo && userInfo.xlAccountCode?userInfo.xlAccountCode:''
    }
      this.commonService.postDownloadOkountPDF(url,data).subscribe((res)=>{
          let fileBlob = res.blob();
          let blob = new Blob([fileBlob], { 
            type: 'application/pdf'
          });
          FileSaver.saveAs(blob, "transaction");
          this.snackBarMessage = "download completed..."
          this.showSnackBar()
      },(error)=>{
        this.snackBarMessage = `Error ocuured while donwloading pdf due to :${error}`
        this.showSnackBar()
        console.log("error",error)
      })
  }


  showSnackBar() {
		var x = document.getElementById("snackbar");
		x.className = "show";
		setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
	}

}
