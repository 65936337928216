<div   *ngFor="let flight of flightInfo">
  <div class="flight-detailsbox card mb-2" *ngFor="let segment of flight['segments'];">
      <div class="card-header ">
          <div class=" airline-card-content">                                                   
           <div class="d-flex justify-content-between align-items-center departure flight-info">
              <h4 class="text-black">
                  <img src="../../../assets/v3images/icons/purple-take-off-plan.svg" alt="" width="25px" height="25px" class="mr-2">
                  {{segment?.originAirport?.code }} - {{segment?.destinationAirport?.code}}
              </h4>
              <div class="flight-time-date mt-2">
                  <p class="align-items-center d-flex flight-time justify-content-end"><img src="../../../assets/v3images/icons/purple-clock.svg" alt="" width="15px" height="215px" class="mr-1"> 
                    {{segment?.legs[0]?.departureDateTime | dateGmtFormat}}
                      <span class="ml-1 mr-1 text-grey">|</span>
                      <span  class=" flight-date ml-2">{{segment.legs[0].departureDateTime | timeFormat}}</span>
<!--                       
                      <span title="{{flight.fares[0] &&
                        flight.fares[0].displayPriceType?flight.fares[0].displayPriceType:flight.fares[0].priceType}}" class="fare-type-style d-flex ml-3"><i class="fa fa-tags bg-success text-white mr-1"></i>{{flight.fares[0] &&
                        flight.fares[0].displayPriceType?flight.fares[0].displayPriceType:flight.fares[0].priceType}}</span> -->
                        <ng-container *ngIf="segment?.supplierInfo?.priceType;else fareType">
                            <span title="{{segment?.supplierInfo?.priceType}}" class="fare-type-style d-flex ml-3">
                                
                                <ng-container *ngIf="flight.fares[0].supplierInfo && flight.fares[0].supplierInfo.subPriceType && (flight.fares[0].supplierInfo.subPriceType =='STUD' || flight.fares[0].supplierInfo.subPriceType =='SRCT') ;else regularFareTag">
                                    <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='STUD'" src="assets/v3images/icons/white-student-cap.svg" alt="STUD">
                                    <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='SRCT'" src="assets/v3images/icons/white-senior-citizen.svg" alt="SRCT">
                                  </ng-container>
                                  <ng-template #regularFareTag>
                                    <i class="fa fa-tags bg-success text-white mr-1"></i>
                                  </ng-template>
                                {{
                                    segment?.supplierInfo?.priceType
                                }}
                            </span>

                        </ng-container>
                        <ng-template #fareType>
                            <span title="{{flight.fares[0] &&
                                flight.fares[0].displayPriceType?flight.fares[0].displayPriceType:flight.fares[0].priceType}}" class="fare-type-style d-flex ml-3">
                                
                                <ng-container *ngIf="flight.fares[0].supplierInfo && flight.fares[0].supplierInfo.subPriceType && (flight.fares[0].supplierInfo.subPriceType =='STUD' || flight.fares[0].supplierInfo.subPriceType =='SRCT') ;else regularFareTag">
                                    <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='STUD'" src="assets/v3images/icons/white-student-cap.svg" alt="STUD">
                                    <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='SRCT'" src="assets/v3images/icons/white-senior-citizen.svg" alt="SRCT">
                                  </ng-container>
                                  <ng-template #regularFareTag>
                                    <i class="fa fa-tags bg-success text-white mr-1"></i>
                                  </ng-template>
                                {{
                                    flight.fares[0] &&
                                    flight.fares[0].displayPriceType?flight.fares[0].displayPriceType:flight.fares[0].priceType
                                }}
                            </span>
                        </ng-template>
                       
    
                  </p>
              </div> 
                     
           </div> 
          </div>                                          
      </div>
      <!-- <div class="row">
          <div class="form-group">
              <h2>{{arrival}} - {{departure}}</h2>
              <h3>{{date}}</h3>
          </div>
      </div> -->
      <div class="card-body p-0">
          <ng-container *ngFor="let leg of segment.legs; let i = index;">
                  <div class="row m-0" style="
                  margin-bottom: 10px;
                  border-bottom: 1px dashed #e8e8e8;">
                          <div class=" col-lg-3   col-md-12 airline-box text-left d-flex align-items-center ">
                              <div class="airline-logo-name mr-2">
                                  <div class="airlogo mb-1 m-0">
                                      <img src="assets/images/{{leg.marketingAirlineCode}}.png" alt=""
                                          onError="this.src='assets/images/icon7.png'">
                                  </div>
                              </div>
                              <div>                                  
                                  <p class="airline-name m-0  text-uppercase">{{leg.marketingAirlineName}}</p>
                              <div class="airline-flight-codes mr-auto ml-0">
                                  <span class="m-0">{{leg.marketingAirlineCode}} -
                                      {{leg.flightNumber}}</span>
                                      <small class="terminal text-dark-pink ">{{flight.serviceClass}} {{leg.cabinClass}}
                                          <ng-container *ngIf="fareClass && fareClass.length > 0 ">| {{fareClass}}</ng-container></small>
                                      <span class="operated-code" *ngIf="flight.airline?.code != 'SG' && flight.airline?.code != '6E' &&
                                      leg.operatingAirlineCode != leg.marketingAirlineCode">OPERATED
                                          BY : {{leg.operatingAirlineCode}}</span>
                              </div>
                              </div>
                          </div>
                          <div class=" col-lg-9 pt-2 col-md-12">
                              <div class="row m-0">
                                  <div class="col-md-4">
                                      <div class="airline-card-content">
                                          <div class="departure flight-info">
                                              <h4>
                                                  <img src="../../../assets/v3images/icons/purple-take-off-plan.svg" alt="" width="25px" height="25px" class="mr-1">
                                                  <span
                                                       >  {{leg.originAirport.code}}</span>
                                              </h4>
                                             <div class="flight-time-date mt-2">
                                              <p class="flight-time"><img src="../../../assets/v3images/icons/purple-clock.svg" alt="" class="mr-1" width="20px" height="20px"  > <span>{{leg.departureDateTime | timeFormat}}</span></p>
                                              <p style="font-size: 12px;" class="mt-1">{{leg.departureDateTime |
                                                      dateGmtFormat}}</p>
                                                      <!-- <span class="terminaladd  flight-date"  style="font-size: 12px;">
                                                          {{leg.originAirport.name}}, {{leg.originAirport.city}}</span> -->
                                                      <small class="text-pinkish-red d-block ">Terminal :
                                                          {{leg.originAirport.terminal}}</small>
                                             </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-4" >
                                      <div class="travelling-time">
                                          <div class="box segment-info type text-center lay-tooltip" >                                            
                                          <small class="mb-1 ">
                                              {{getDuration(leg.departureDateTime,
                                              leg.arrivalDateTime,leg.duration)}}
                                              </small>
                                          <div class="separator-line"></div>
                                          <small class="text-grey"> Check-in Baggage</small> 

                                          <div class="text-center   mt-1">
                                            <img src="../../../assets/v3images/icons/baggage.svg" alt="" srcset="" width="14px" >
                                            <small class=" text-grey ml-1">{{leg?.freeCheckInBaggage?.unit}}&nbsp;{{leg?.freeCheckInBaggage?.unitTypeCode}}</small>
                                        </div>


                                          <!-- <div class="text-center mt-2">
                                              <i class="fa fa-suitcase "></i>
                                              <small class="text-bold ml-2">{{leg?.freeCheckInBaggage?.unit}}&nbsp;{{leg?.freeCheckInBaggage?.unitTypeCode}}</small>
                                          </div> -->
                          </div>
                                      </div>
                                  </div>

                                  <div class="col-md-4">
                                      <div class="airline-card-content">
                                          <div class="departure flight-info text-right">
                                              <h4>
                                                  <img src="../../../assets/v3images/icons/purple-landing-plan.svg" alt="" width="25px" height="25px" class="mr-1">
                                                  <span
                                                       >{{leg.destinationAirport.code}}</span>
                                              </h4>
                                             <div class="flight-time-date mt-2 text-right">
                                              <p class="flight-time"><img src="../../../assets/v3images/icons/purple-clock.svg" class="mr-1" alt="" width="20px" height="20px" > <span>{{leg.arrivalDateTime | timeFormat}}</span></p>
                                              <p style="font-size: 12px;" class="mt-1">{{leg.arrivalDateTime | dateGmtFormat}}</p>
                                                      <!-- <span class="terminaladd  flight-date"  style="font-size: 12px;">                                                                                             
                                      {{leg.destinationAirport.name}},  {{leg.destinationAirport.city}}</span> -->
                                      <small class="text-pinkish-red d-block ">Terminal :
                                          {{leg.destinationAirport.terminal}}</small>
                                                      
                                             </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                  </div>  
                  <div [ngClass]="{'layover-bar': !leg.technicalStop}" class="text-center" *ngIf="segment.legs.length > i+1 ">
                   
                    <ng-container *ngIf="leg.technicalStop; else nonTechnicalStop">
                        <p class="text-bold m-0">
                            <span class="LatoBold text-pinkish-red append_bottom5">
                                <strong>Technical Stop: <strong>{{calculateStopOverDuration(segment.legs, i)}}</strong></strong>
                            </span> layover in <strong>{{leg.destinationAirport.name}}, {{leg.destinationAirport.city}} </strong>
                            <span class="LatoBold text-pinkish-red">
                                <strong>Deboarding may not be allowed.</strong>
                            </span><br>
                        </p>
                    </ng-container>
                    
                    <ng-template #nonTechnicalStop>
                        <p class="text-bold m-0">
                            <span class="LatoBold text-pinkish-red append_bottom5">
                                <strong>{{calculateStopOverDuration(segment.legs, i)}}</strong>
                            </span> layover in
                            <span class="LatoBold text-pinkish-red">
                                <strong>{{leg.destinationAirport.name}},{{leg.destinationAirport.city}}</strong>
                            </span><br>
                        </p>
                    </ng-template>
                    
                </div> 
          </ng-container>
      </div>
  </div>
</div>