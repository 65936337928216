<form class="m-login__form m-form" [formGroup]="requestEmail">
  <div id="forgotpassword">
    <div class="mat-white-input-form">
      <div class="head">
        <h1 class="mb-0">Forgot Password</h1>
        <p class="mt-0">Please enter your registered email address</p>
      </div>

      <div class="contentbox m-0 pl-5 pr-5 pb-3" mat-dialog-content>
        <div class="alert alert-success msgSuccess" *ngIf="successMsg">
          <strong>Success!</strong> Password is send to your mail id.
        </div>
        <div class="d-flex justify-content-between">
          <mat-form-field class="w-100">
            <input
              matInput
              placeholder="Email address"
              formControlName="email"
              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$"
              [ngStyle]="{
                'border-color':
                  !email.valid && email.touched
                    ? 'red'
                    : !email.valid && email.touched
                    ? ''
                    : null
              }"
            />
            <small
              style="position: absolute; left: 0; top: 30px"
              class="error text-danger emailError"
              *ngIf="!email.valid && email.touched"
            >
              Please enter a Valid Email
            </small>
            <div></div>
          </mat-form-field>
          <div class="field">
            <button
              type="submit"
              class="text-white btn btn-green"
              [disabled]="!requestEmail.valid"
              (click)="requestReset(requestEmail.value)"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
