<div class="container mt-4 mb-fix clearfix" >
    <div class="header clearfix">
            <div class="logo" *ngIf="userDetail.logo_url != null">
                    <!-- <ng-container *ngIf="userDetail.logo_url != null">
                            <img *ngIf="userDetail.logo_url != null" [src]="userDetail.logo_url" alt="">
                            <span class="aff-name-alt" *ngIf="userDetail.logo_url == null">{{userDetail?.company_name}}</span>
                    </ng-container> -->
                    <img [src]="userDetail.logo_url" alt=""> 
                </div>

                <div class="company-name-header" *ngIf="userDetail.logo_url != null">
                    <span class="aff-name-alt">{{userDetail?.company_name}}</span>
                            <span class="aff-email">{{userDetail?.primary_email}}</span>
                            <span class="aff-phone">{{userDetail?.mobile}}</span>                    
                </div>

                <div class="company-name-header-full" *ngIf="userDetail.logo_url == null">
                    <span class="aff-name-alt-full">{{userDetail?.company_name}}</span>
                            <span class="aff-email">{{userDetail?.primary_email}}</span>
                            <span class="aff-phone">{{userDetail?.mobile}}</span>                   
                </div>
        
                <div class="book-detail">
                    <div class="booking-id">Booking ID <span>{{bookingArr?.booking_ref}}</span></div>
                    <div class="booking-date">Booking Date <span>{{bookingArr?.booked_timestamp | date : 'dd/MM/yyyy'}}</span></div>
                </div>
        <!-- <div class="clearfix"></div> -->
    </div>

    <ng-container *ngFor="let segments of bookingArr.segments">
        <div class="ticket-section col-12 pl-0 pr-0" *ngFor="let item of segments['legs']">
            <div class="route-details col-8 pl-0 pr-0">
                <div class="departs">
                    <table class="tg">
                        <tr class="field-info">
                            <td class="depart-field">Departs</td>
                            <td class="date">Date</td>
                            <td class="time">Time</td>
                            <td class="duration">Duration/Stop</td>
                            <td class="baggage">Baggage</td>
                            <td >Terminal</td>
                        </tr>
                        <tr class="hilight">
                            <td class="depart-field"><span class="airport-origin">{{item?.origin_airport_city}} {{item?.origin_airport_country}} ({{item?.origin_airport_code}})</span><span class="airport-name">{{item?.origin_airport_name}}</span></td>
                            <td class="date">{{item?.origin_timestamp | dateFormat }}</td>
                            <td class="time">{{item?.origin_timestamp | timeFormat }}</td>
                            <td class="duration">{{item?.duration}}</td>
                            <td class="baggage" *ngIf="item && item.freeCheckInBaggage && item.freeCheckInBaggage.unit"><span>{{item?.freeCheckInBaggage?.unit}}&nbsp;{{item?.freeCheckInBaggage?.unitTypeCode}} </span>&nbsp;</td>
                            <td class="baggage" *ngIf="item && item.freeCheckInBaggage && !item.freeCheckInBaggage.unit"><span>{{item?.freeCheckInBaggage}}</span>&nbsp;</td> 
                            <td *ngIf="item && !item.freeCheckInBaggage ">NA</td>
                            <td class="terminal">{{item?.origin_terminal}}</td>
                        </tr>

                    </table>
                </div>
                <div class="arrive">
                    <table class="tg">
                        <tr class="arrival">
                            <td class="arrive-field">Arrive</td>
                            <td class="date">Date</td>
                            <td class="time">Time</td>
                            <td class="duration">Duration/Stop</td>
                            <td class="baggage">Baggage</td>
                            <td class="terminal">Terminal</td>
                        </tr>
                        <tr class="f-details">
                            <td class="arrive-field"><span class="airport-dest">{{item?.destination_airport_city}} {{item?.destination_airport_country}} ({{item?.destination_airport_code}})</span><span class="airport-name">{{item?.destination_airport_name}}</span></td>
                            <td>{{item?.destination_timestamp | dateFormat }}</td>
                            <td>{{item?.destination_timestamp | timeFormat }}</td>
                            <td class="duration">{{item?.duration}}</td>
                            <td class="baggage" *ngIf="item && item.freeCheckInBaggage && item.freeCheckInBaggage.unit"><span>{{item?.freeCheckInBaggage?.unit}}&nbsp;{{item?.freeCheckInBaggage?.unitTypeCode}} </span>&nbsp;</td>
                            <td class="baggage" *ngIf="item && item.freeCheckInBaggage && !item.freeCheckInBaggage.unit"><span>{{item?.freeCheckInBaggage}}</span>&nbsp;</td>
                            <td *ngIf="item && !item.freeCheckInBaggage">NA</td>
                            <td class="terminal">{{item?.destination_terminal}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="flight-details col-4">
                <div class="flight-name-col">
                    <span class="flight-icon"><img src="assets/images/flight/{{item.operating_airline_code}}.png" /></span>
                    <span class="flight-name">{{item?.marketing_airline_name}}</span>
                </div>
                <div class="booking-info">
                    <div class="booking-head">Booking Reference (PNR)</div>
                    <!-- <div class="pnr-num" >{{segments?.pnr}}</div> -->
                    <div class="pnr-num" *ngIf="item.airline_pnr == undefined">{{segments?.pnr}}</div>
                    <div class="pnr-num" *ngIf="item.airline_pnr != undefined">{{item?.airline_pnr}}</div>
                    <div class="flight-heading float-left">FLIGHT NO.<span class="flight-num">{{item?.flight_number}}</span></div>
                    <div class="aircraft-heading float-left">AIRCRAFT TYPE
                            <span class="aircraft-type" *ngIf="item.aircraft_type == undefined && item.aircraft_type == null">N/A</span>
                            <span class="aircraft-type" *ngIf="item.aircraft_type != undefined && item.aircraft_type != null" >{{item?.aircraft_type}}</span>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
        </div>
    </ng-container>

    <div class="passenger-section clearfix mt-3">
        <h3 class="head-style">Passenger Details</h3>
        <div class="passenger-caption clearfix">
            <span class="pass-field">Passenger Name</span>
            <span class="type-field">Type</span>
            <span class="status-field">Status</span>
            <span class="seat-field">Seat</span>
            <span class="addon-field text-center">Add. on service</span>
            <!-- <span class="aadhar-field">Aadhar no. #</span> -->
            <span class="ff-field">FF #</span>
        </div>

        <ng-container *ngFor="let traveller of bookingArr.travellers; let i =index;">
            <div class="passenger-details clearfix" *ngIf="traveller.status== 'TICKETED' || traveller.status== undefined">
                <span class="pass-field">
                    <!-- <ng-container *ngIf="!showBarCode">
                        {{traveller?.title}} {{traveller?.first_name}} {{traveller?.last_name}}
                    </ng-container> -->
                    <ng-container *ngIf="showBarCode && getBarcode(traveller);else travellerName">
                        <div class="align-center-middle d-flex">
                            <div class="col-5 p-0 d-flex flex-dir-column">
                                <span class="font-weight-bold">
                                    {{traveller?.title}} {{traveller?.first_name}} {{traveller?.last_name}}
                                </span>
                                <span>
                                  TKT : {{getTicket(traveller?.ticket?.number, bookingArr?.segments[0]?.pnr)}}
                                </span>
                            </div>
                            <div class="col-7 p-0">
                                <div class="border border-dark" *ngFor="let item of getBarcode(traveller)">
                                    <span class="font-weight-bold" style="font-size: 12px;">{{item.sector}}: </span>
                                    <img class="pb-3 pt-3" style="width: 200px !important" [src]="getSanitizedUrl(item.barcode)" alt="">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #travellerName>
                        <div class="p-0 d-flex flex-dir-column">
                            <span class="font-weight-bold">
                                {{traveller?.title}} {{traveller?.first_name}} {{traveller?.last_name}}
                            </span>
                            <span>
                                TKT : {{getTicket(traveller?.ticket?.number, bookingArr?.segments[0]?.pnr)}}
                            </span>
                        </div>
                    </ng-template>
                </span>
                <span class="type-field"><span *ngIf="traveller.type == 'ADT'">ADULT</span><span *ngIf="traveller.type == 'CHD'">CHILD</span><span *ngIf="traveller.type == 'INF'">INFANT</span></span>
                <span class="status-field">{{traveller?.ticket?.status}}</span>

                <span class="seat-field" *ngIf="traveller?.ssr!=null && traveller?.ssr[0]?.seats?.status == 'SUCCESS' && traveller?.ssr[0]?.seats?.name && traveller?.ssr[0]?.seats?.name != 'NA'  ">{{traveller?.ssr[0]?.seats?.name}}</span>
                <span class="seat-field" *ngIf="(traveller?.ssr==null) || (traveller?.ssr[0]?.seats?.name && traveller?.ssr[0]?.seats?.name == 'NA') ">N/A</span>

                <span class="addon-field ml-3 text-left" *ngIf="traveller?.ssr!=null">
                    <span *ngIf="traveller?.ssr[0].baggages!=null && traveller?.ssr[0]?.baggages?.status == 'SUCCESS' && traveller?.ssr[0].baggages.name && traveller?.ssr[0].baggages.name != 'NA' ">
                        <i class="fa fa-suitcase mr-2" *ngIf="hasBaggage" aria-hidden="true"></i>
                        {{traveller?.ssr[0]?.baggages?.name}}
                    </span>
                    <span *ngIf="traveller?.ssr[0].additionalBaggages!=null && traveller?.ssr[0]?.additionalBaggages?.status == 'SUCCESS' && traveller?.ssr[0].additionalBaggages.name && traveller?.ssr[0].additionalBaggages.name != 'NA' ">
                        <ng-container *ngIf="hasAdditionalBaggage && !hasBaggage;else comma">
                            <i class="fa fa-suitcase mr-2"  aria-hidden="true"></i>
                        </ng-container>
                        <ng-template #comma>,</ng-template>
                        {{traveller?.ssr[0]?.additionalBaggages?.name}}
                    </span>
                    <ng-container *ngIf="hasAncillaryServices && traveller?.ssr[0].ancillaries && traveller?.ssr[0].ancillaries.name && traveller?.ssr[0].ancillaries.name != 'NA' ">
                        <ng-container *ngIf="isArray(traveller?.ssr[0].ancillaries.code)">
                                | <span>
                                <i class="fa fa-cart-plus mr-1" aria-hidden="true"></i>
                                <ng-container *ngFor="let ancillary of traveller?.ssr[0].ancillaries.code; let a = index">
                                    <ng-container *ngIf="!ancillary.status || (ancillary.status && (ancillary.status == 'SUCCESS'))">
                                        {{ancillary.code}} <ng-container *ngIf="traveller?.ssr[0].ancillaries.code.length-1 != a">,</ng-container>
                                    </ng-container>
                                </ng-container>
                            </span> 
                        </ng-container>
                    </ng-container>
                    <span class="addon-field ml-3" *ngIf="traveller?.ssr!=null">
                        <span *ngIf="traveller?.ssr[0].wheelChairs!=null && traveller?.ssr[0].wheelChairs.name &&  traveller?.ssr[0].wheelChairs.name != 'NA' ">
                            |
                            <img src="assets/v3images/icons/wheelchair.png" width="15px"  *ngIf="hasWheelChair" aria-hidden="true" />
                            {{traveller?.ssr[0]?.wheelChairs?.name}}
                        </span>
                    </span>
                    <span class="meal-icon ml-3" *ngIf="traveller?.ssr!=null && traveller?.ssr[0]?.meals != null && traveller?.ssr[0]?.meals?.status == 'SUCCESS' && traveller?.ssr[0]?.meals.name && traveller?.ssr[0]?.meals.name != 'NA' ">
                        |
                        <i class="fa fa-cutlery mr-1" *ngIf="hasMeal" aria-hidden="true"></i>
                        <span>{{traveller?.ssr[0]?.meals?.code?.code}}</span>
                    </span>
                </span>
                <span class="addon-field" *ngIf="traveller?.ssr==null">N/A</span>
                <!-- <span class="aadhar-field">N/A</span> -->
                <span class="ff-field">N/A</span>
            </div>
        </ng-container>
        <!--
		    <div class="addons"><span class="addon-i"><span><img src="../../../../assets/images/meal.png"/></span>Meals</span><span class="addon-i"><span><img src="../../../../assets/images/wheelchair.png"/></span>Wheelchairs</span>
        </div>
	   -->
        <div class="addons" *ngIf="ticketArr.length > 0 ">
            <div class="meals-info" *ngIf="hasMeal">
                <span class="addon-i">
                <i class="fa fa-cutlery" aria-hidden="true"></i>Meals -
                <ng-container *ngFor="let pax of bookingArr.travellers; let i=index">
                  <ng-container *ngIf="pax?.ssr != null && pax?.ssr !=  ''">
                    <ng-container *ngFor="let ssr of pax.ssr">
                        <ng-container *ngIf="ssr.meals != null && ssr.meals != ''">
                            <span class="meal-names"
                              *ngIf="ssr?.meals?.code != '' && ssr?.meals?.code != null && ssr?.meals?.status == 'SUCCESS'">
                              ({{ssr?.meals?.code?.code}}) {{ssr?.meals?.code?.name}} ({{ssr.origin}}-{{ssr.destination}})&nbsp;•&nbsp;
                            </span>
                      </ng-container>
                    </ng-container>
                </ng-container>
                </ng-container>
                </span>
            </div>

            <div class="baggage-info mt-2" *ngIf="hasBaggage">
                <span class="addon-i">
                <i class="fa fa-suitcase" aria-hidden="true"></i>Baggage -
                <ng-container *ngFor="let pax of bookingArr.travellers; let i=index">
                  <ng-container *ngIf="pax?.ssr != null && pax?.ssr !=  ''">
                    <ng-container *ngFor="let ssr of pax.ssr">
                        <ng-container *ngIf="ssr.baggages != null && ssr.baggages != ''">
                            <span class="meal-names"
                              *ngIf="ssr?.baggages?.code != '' && ssr?.baggages?.code != null && ssr?.baggages?.status == 'SUCCESS'">
                              ({{ssr?.baggages?.code?.code}}) {{ssr?.baggages?.code?.name}} ({{ssr.origin}}-{{ssr.destination}})&nbsp;•&nbsp;
                            </span>
                      </ng-container>
                    </ng-container>
                </ng-container>
                </ng-container>
                </span>
            </div>

            <div class="baggage-info mt-2" *ngIf="hasAdditionalBaggage">
                <span class="addon-i">
                <i class="fa fa-suitcase" aria-hidden="true"></i>Addnl. Baggage -
                <ng-container *ngFor="let pax of bookingArr.travellers; let i=index">
                  <ng-container *ngIf="pax?.ssr != null && pax?.ssr !=  ''">
                    <ng-container *ngFor="let ssr of pax.ssr">
                        <ng-container *ngIf="ssr.additionalBaggages != null && ssr.additionalBaggages != ''">
                            <span class="meal-names"
                              *ngIf="ssr?.additionalBaggages?.code != '' && ssr?.additionalBaggages?.code != null && ssr?.additionalBaggages?.status == 'SUCCESS'">
                              ({{ssr?.additionalBaggages?.code?.code}}) {{ssr?.additionalBaggages?.code?.name}} ({{ssr.origin}}-{{ssr.destination}})&nbsp;•&nbsp;
                            </span>
                      </ng-container>
                    </ng-container>
                </ng-container>
                </ng-container>
                </span>
            </div>

            <div class="baggage-info mt-2" *ngIf="hasAncillaryServices">
                <span class="addon-i">
                <i class="fa fa-cart-plus" aria-hidden="true"></i>Ancillaries -
                <ng-container *ngFor="let pax of bookingArr.travellers; let i=index">
                  <ng-container *ngIf="pax?.ssr != null && pax?.ssr !=  ''">
                    <ng-container *ngFor="let ssr of pax.ssr">
                        <ng-container *ngIf="ssr.ancillaries != null && ssr.ancillaries != ''">
                            <span class="meal-names"
                              *ngIf="ssr.ancillaries && ssr?.ancillaries?.code != '' && ssr?.ancillaries?.code != null && isArray(ssr.ancillaries.code) && ssr?.ancillaries?.status == 'SUCCESS'">
                            
                              (<ng-container *ngFor="let ancillary of ssr.ancillaries.code">
                                <ng-container *ngIf="!ancillary?.status || ancillary?.status == 'SUCCESS'">
                                    {{ancillary?.code}},
                                </ng-container>
                              </ng-container>) ({{ssr.origin}}-{{ssr.destination}})&nbsp;•&nbsp;
                            </span>
                      </ng-container>
                    </ng-container>
                </ng-container>
                </ng-container>
                </span>
            </div>

            <div class="baggage-info mt-2" *ngIf="hasSeat">
                <span class="addon-i">
                <i class="fas fa-chair" aria-hidden="true"></i>Seat -
                <ng-container *ngFor="let pax of bookingArr.travellers; let i=index">
                  <ng-container *ngIf="pax?.ssr != null && pax?.ssr !=  ''">
                    <ng-container *ngFor="let ssr of pax.ssr">
                        <ng-container *ngIf="ssr.seats != null && ssr.seats != ''">
                            <span class="meal-names"
                              *ngIf="ssr.seats.code != '' && ssr.seats.code != null  && ssr?.seats?.status == 'SUCCESS'">
                            {{ssr.seats.name}} ({{ssr.origin}}-{{ssr.destination}})&nbsp;•&nbsp;
                            </span>
                      </ng-container>
                    </ng-container>
                </ng-container>
                </ng-container>
                </span>
            </div>

            <div class="baggage-info mt-2" *ngIf="hasWheelChair">
                <span class="addon-i">
                   <img src="assets/v3images/icons/wheelchair.png" width="23px" class="mr-1" /> Wheel Chair -
                <ng-container *ngFor="let pax of bookingArr.travellers; let i=index">
                  <ng-container *ngIf="pax?.ssr != null && pax?.ssr !=  ''">
                    <ng-container *ngFor="let ssr of pax.ssr">
                        <ng-container *ngIf="ssr.wheelChairs != null && ssr.wheelChairs != ''">
                            <span class="meal-names"
                              *ngIf="ssr?.wheelChairs?.code != '' && ssr?.wheelChairs?.code != null">
                              ({{ssr?.wheelChairs?.code?.code}}) {{ssr?.wheelChairs?.code?.name}} ({{ssr.origin}}-{{ssr.destination}})&nbsp;•&nbsp;
                            </span>
                      </ng-container>
                    </ng-container>
                </ng-container>
                </ng-container>
                </span>
            </div>

        </div>
    </div>

    <div class="fare-section" *ngIf="!status">
        <h3 class="head-style">Fare Details <span>(Includes Base Fare, Taxes, Fees and Charges)</span></h3>
        <div class="fare-caption clearfix">
            <span class="traveller-field">Traveler Name</span>
            <span class="serivce-class-field">Service Class</span>
            <span class="fare-field">Base Fare</span>
            <span class="tax-field">Tax</span>
            <span class="total-field text-right">Total Amount</span>
        </div>

        <ng-container *ngFor="let item of bookingArr.travellers">
            <div class="fare-details  clearfix" *ngIf="item.status== 'TICKETED' || item.status== undefined">
                <span class="traveller-field">{{item?.title}} {{item?.first_name}} {{item?.last_name}}</span>
                <span class="serivce-class-field">{{bookingArr?.supplierInfo?.serviceClass}}</span>
                <span class="fare-field">&#8377; {{item?.fare.base  | number:'1.0-0'}}</span>
                <span class="tax-field">&#8377; {{totalTax(item) | number:'1.0-0'}}</span>
                <span class="total-field  text-right">&#8377; {{getTotal(item)  | number:'1.0-0'}}</span>
            </div>
        </ng-container>
        <div class="final-total"><span class="total-head text-right">Total</span> <span class="grand-total-amt">&#8377; {{grandTotal(bookingArr?.travellers) | number:'1.0-0'}}</span> </div>

    </div>

    <div class="clearfix"></div>

    <!-- <div style='page-break-after:always;position: relative;display: block'>&nbsp;</div> -->

    <div class="gst-section clearfix mt-5 page-break" [ngStyle]="{'page-break-before':always}" *ngIf="bookingArr.gstDetail != null">
        <ng-container *ngIf="bookingArr.gstDetail.gstNumber != ''">
            <h3 class="head-style">GST Detail</h3>
            <div class="gstin"><span class="gst-num"><span>GST Number: </span>{{bookingArr?.gstDetail?.gstNumber}}</span>
            </div>
            <div class="company"><span class="company-name"><span>Company Name: </span>{{bookingArr?.gstDetail?.companyName}}</span>
            </div>
        </ng-container>
    </div>

    <div class="important-rules clearfix" style="margin-top: 0px !important;" *ngIf="ancillaryItem && ancillaryItem.size > 0">
        <h4 class="head-style">Ancillary Information </h4>
        <ul class="clearfix d-flex flex-column">
            <ng-container *ngFor="let item of ancillaryItem | keyvalue">
                <li> <strong>{{item.key}}</strong> : {{item.value}}</li>
            </ng-container>
        </ul>
    </div>

    <div class="important-rules clearfix mt-4">
        <h3 class="head-style">Important Information</h3>
        <ul class="clearfix">
            <li>A Email copy of this e-ticket must be presented at the time of check in along with photo identification (ID) for EVERY passenger.
            </li>
            <li>Check-in starts 2 hours before scheduled departure, and closes 1 hour prior to the departure time.</li>
            <li>Please check the terminal of departure for flights</li>
        </ul>
        <h3 class="head-style mt-4">Cancellation and Date Change Rule</h3>
        <ul class="clearfix">
            <li>If you are cancelling or rescheduling flight within 6 hours of its departure time, kindly contact the airline directly.</li>
            <li>If you have cancelled your booking with the airline directly, kindly inform us to get your refunds from us.</li>
            <li>If any flight is cancelled or rescheduled by the airline authority, passenger is requested to get a stamped/endorsed copy of the ticket to process the refund.</li>
        </ul>
    </div>

    <div class="aff-details clearfix">
        <h3 class="head-style">AFFILIATE DETAILS</h3>
        <!-- <span class="aff-name">{{userDetail?.first_name}} {{userDetail?.last_name}}</span> -->
        <span class="aff-name">{{userDetail?.company_name}}</span>
        <div class="aff-address float-left">
            <span class="add1">{{userDetail?.address1}},&nbsp;</span>
            <span class="add2">{{userDetail?.address2}},&nbsp;</span>
            <span class="a-city">{{userDetail?.city_name}},&nbsp;</span>
            <!-- <span class="a-name">{{userDetail?.company_name}}&nbsp;</span> -->
            <span class="a-state">{{userDetail?.state_name}}&nbsp;</span>
            <div class="d-block">
                <span class="a-country">{{userDetail?.country_name}}&nbsp;</span>
                <span class="a-zip">{{userDetail?.pincode}}</span>
            </div>

        </div>
        <div class="float-right">
            <span class="aff-email"><i class="fa fa-envelope" aria-hidden="true"></i>
				{{userDetail?.primary_email}}</span>
            <span class="aff-phone"><i class="fa fa-phone" aria-hidden="true"></i>
				{{userDetail?.mobile}}</span>
        </div>

    </div>

    <div class="emailPopup">
        <!-- Modal -->
        <div class="modal fade" id="emailPopup" tabindex="-1" role="dialog" aria-labelledby="emailPopupLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="emailPopupLabel">Email Ticket</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
                    </div>
                    <form [formGroup]="emailForm">
                        <div class="modal-body">
                            <input type="email" formControlName="txtEmail" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$" class="form-control" placeholder="Enter email id" required>
                            <!-- <span class="error txtPrimaryEmailError" *ngIf="
                            !txtEmail.valid && txtEmail.touched
                          ">
                                                  Please Enter a Valid Email
                                              </span> -->
                            <span *ngIf="!txtEmail.valid && txtEmail.touched" class="if-error font-weight-bold">Please enter valid email id</span>
                            <span *ngIf="successMsg" class="if-success font-weight-bold">Email sent successfully</span>
                            <span *ngIf="errorMsg" class="if-error font-weight-bold">Something went wrong</span>
                        </div>
                        <div class="modal-footer clearfix">
                            <span class="float-left without-price">
                                    <input type="checkbox" formControlName="nopriceCheck" class="form-check-input" id="withoutPrice">
                                    <label class="form-check-label" for="withoutPrice">Send without price</label>
                            </span>
                            <span class="float-right">
                                <button type="button" class="custom-btn-style2" [disabled]="!emailForm.valid" (click)="emailTicket(emailForm.value)">Send email</button>
                            <!-- <button type="button" class="custom-btn-style2">Send without price</button> -->
                            <button type="button" class="custom-btn-style2" #close data-dismiss="modal" (click)="resetForm()">Close</button>
                           </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-btn-style clearfix mt-5 printBtn d-flex justify-content-center">
        <a class="btn btn-sm btn-green mr-2 d-flex align-items-center pl-3 pr-3" href="javascript:void(0)" (click)="printTicket()">PRINT</a>
        <a class="btn btn-sm btn-info mr-2 d-flex align-items-center pl-3 pr-3" href="javascript:void(0)" (click)="checkStatus()">PRINT WITHOUT PRICE</a>
        <a class="btn btn-sm btn-success mr-2 d-flex align-items-center pl-3 pr-3" href="javascript:void(0)" (click)="callPdfGeneratorApi(bookingArr.invoice_no,bookingArr.booking_ref,false)">Save PDF with price</a>
        <a class="btn btn-sm btn-success mr-2 d-flex align-items-center pl-3 pr-3" href="javascript:void(0)" (click)="callPdfGeneratorApi(bookingArr.invoice_no,bookingArr.booking_ref,true)">Save PDF w/o price</a>
        <a class="btn btn-sm btn-orange mr-2 d-flex align-items-center pl-3 pr-3" data-toggle="modal" data-target="#emailPopup" href="javascript:void(0)">Email Ticket</a>
        <a class="btn btn-sm btn-primary d-flex align-items-center pl-3 pr-3" *ngIf="showMulticityBtn"  href="javascript:void(0)"  (click)="viewAllTicket()">Print All Ticket</a>
    </div>
</div>