import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment-timezone';

@Pipe({
	name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

	transform(value: any, args?: any): any {
		// if (value != null && value != undefined) {
		// 	let now = moment().tz('Asia/Kolkata')// add this 4of 4
		// 	if (value.includes("+")) {
		// 		value = value.split("+")[0]
		// 	}
		// 	//  ;
		// 	value = now.format(value);
		// 	if (value.includes("+")) {
		// 		value = value.split("+")[0]
		// 	}
		// 	let b = value.split("T")[1]
		// 	b= b.split(':');
		// 	return b[0]+":"+b[1];
		// }
		// return 'N/A';

		if (value != null && value != undefined) {
			if (value.includes("+")) {
				value = value.split("+")[0]
			}
			if(value.includes("Z")) {
				value = new Date(value);
				let b = value.toTimeString().split(' ')[0];
				b= b.split(':');
				return b[0]+":"+b[1];
			}
			else if(value.includes("T")){
				let b = value.split('T')[1];
				b = b.split(':');
				return b[0]+":"+b[1];
			}
			else{
				value = new Date(value);
				 
				let b = value.toTimeString().split(' ')[0];
				b= b.split(':');
				return b[0]+":"+b[1];
			}
			//  ;
			
			// value.toTimeString().split(' ')[0];
			
		}
		return 'N/A';
	}

}
