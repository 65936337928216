import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AffiliateService } from 'src/app/services/affiliate.service';

@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
	// personalDetailForm : FormGroup;
	contactForm : FormGroup;
	companyDetailForm : FormGroup;
	personalDetailForm : FormGroup;
	countriesArr : any = [];
	statesArr : any = [];
	citiesArr : any = [];
	successMsg: any;
	errorMsg: boolean = false;
	enablePanholdeName: boolean = false;
	errorMessage: any;
	isPasswordError = false;
;

	constructor(private fb : FormBuilder, private affiliateService : AffiliateService) { }

	ngOnInit() {
		
		this.contactForm = this.fb.group({
			'txtCompanyName' : ['', Validators.required],
			'txtWebsite' : [''],
			'txtAddress1' : ['', Validators.required],
			'txtAddress2' : [''],
			'selCountry' : ['', Validators.required],
			'selState' : ['', Validators.required],
			'selCity' : ['', Validators.required],
			'txtPincode' : ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
		})

		this.companyDetailForm = this.fb.group({
			'chkCompanyType' : ['Proprietorship',Validators.required],
			'txtPanNumber' : ['', [Validators.required,Validators.pattern("[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}"), Validators.minLength(10),Validators.maxLength(10)]],
			'txtTdsExemption' : [''],
			'txtPanHolderName' : [''],
			'txtXlAccountCode' : [''],
			'txtGstNum' : ['',Validators.pattern("^([0][1-9]|[1-2][0-9]|[3][0-7])([A-Z]{5})([0-9]{4})([A-Z]{1}[1-9A-Z]{1})([Z]{1})([0-9A-Z]{1})+$")],
		})

		this.personalDetailForm = this.fb.group({
			'selTitle' : ['', Validators.required],
			'txtFirstName' : ['', Validators.required],
			'txtLastName' : ['', Validators.required],
			'txtPrimaryEmail' : ['', [Validators.required, Validators.email]],
			'txtSecondaryEmail' : ['',Validators.pattern],
			'txtPhone' : ['', [Validators.minLength(10),Validators.maxLength(10)]],
			'txtMobile' : ['', [Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
			'txtEnterPassword' : ['', [Validators.required,Validators.minLength(8),Validators.maxLength(20)]],
			'txtConfirmPassword' : ['', [Validators.required,Validators.minLength(8),Validators.maxLength(20)]],
			'selStatus' : ['Inactive'],			
			'chkConfiguration' : ['']
		})
		
		console.log("Pop up enter")
		this.affiliateService.getCountries().subscribe(res=>{
			this.countriesArr = JSON.parse(res['_body']);
			this.contactForm.controls.selCountry.setValue('101');
			this.getStates('101')
		})
	}

	getStates(value){	
		this.affiliateService.getStates(value).subscribe(res=>{
		  this.statesArr = JSON.parse(res['_body']);
		})
	}

	getCities(value){
		this.affiliateService.getCities(value).subscribe(res=>{
		  this.citiesArr = JSON.parse(res['_body']);
		})
	}

	companyType(value) {
		if(value == "Proprietorship") {
			this.enablePanholdeName = false;
		}
		else{
			this.enablePanholdeName = true;
		}
	}

	saveData(contactForm, companyDetailForm, personalDetailForm){
		if(this.isPasswordError){
			return false;
		}
		this.errorMsg = false;
		this.successMsg = false;
		let tabScroll = document.getElementById("topDiv");
		let values = Object.assign(contactForm, companyDetailForm, personalDetailForm)
		// console.log(values)
		this.affiliateService.createAffiliate(values).subscribe(res=>{
		if (tabScroll !== null) {
			tabScroll.scrollIntoView();
			tabScroll = null;
		  }
		  this.successMsg = true;		  
		},err=>{
			if (tabScroll !== null) {
				tabScroll.scrollIntoView();
				tabScroll = null;
			}
			this.errorMessage = err.error.msg;
			this.errorMsg = true;
		})    
	  }


	get txtCompanyName(){
		return this.contactForm.controls.txtCompanyName;
	}
	get txtWebsite(){
		return this.contactForm.controls.txtWebsite;
	}
	get txtAddress1(){
		return this.contactForm.controls.txtAddress1;
	}
	get selCountry(){
		return this.contactForm.controls.selCountry;
	}
	get selState(){
		return this.contactForm.controls.selState;
	}
	get selCity(){
		return this.contactForm.controls.selCity;
	}
	get txtPincode(){
		return this.contactForm.controls.txtPincode;
	}
	get txtPanNumber(){
		return this.companyDetailForm.controls.txtPanNumber;
	}
	get txtPanHolderName(){
		return this.companyDetailForm.controls.txtPanHolderName;
	}
	get chkCompanyType(){
		return this.companyDetailForm.controls.chkCompanyType;
	}
	get txtGstNum(){
		return this.companyDetailForm.controls.txtGstNum;
	}
	get selTitle(){
		return this.personalDetailForm.controls.selTitle;
	}
	get txtFirstName(){
		return this.personalDetailForm.controls.txtFirstName;
	}
	get txtLastName(){
		return this.personalDetailForm.controls.txtLastName;
	}
	get txtPrimaryEmail(){
		return this.personalDetailForm.controls.txtPrimaryEmail;
	}
	get txtSecondaryEmail(){
		return this.personalDetailForm.controls.txtSecondaryEmail;
	}
	get txtPhone(){
		return this.personalDetailForm.controls.txtPhone;
	}
	get txtMobile(){
		return this.personalDetailForm.controls.txtMobile;
	}
	get txtEnterPassword() {
		return this.personalDetailForm.controls.txtEnterPassword
	}
	get txtConfirmPassword() {
		return this.personalDetailForm.controls.txtConfirmPassword
	}
	
	get chkConfiguration(){
		return this.personalDetailForm.controls.chkConfiguration;
	}
	get txtXlAccountCode(){
		return this.companyDetailForm.controls.txtXlAccountCode;
	}

	confirmPassword(value){
		value = event.currentTarget['value'];
		let password = this.personalDetailForm.controls.txtEnterPassword.value;
		if(value != password) {
			this.isPasswordError = true;
		}
		else{
			this.isPasswordError = false;
		}
	}

	
}
