<!-- <img src="../../assets/v3images/shapes/top-shape1.svg" class="cursvy-shape"  alt="" srcset=""> -->
<!-- <section >
<img src="../../assets/v3images/banner1.png" alt="" srcset="" width="100%">
</section> -->
<!-- The Modal -->
<div class="modal fade" id="OTPModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <!-- <div class="modal-content" *ngIf="isOTPVerfied">  
            <div class="modal-body text-center"> 
                <img src="../../assets/v3images/icons/success-icon.png" width="80px" >
                <h5 class="text-success text-bold mt-3">Verified Successfully!</h5>
                <p>Redirecting you to affiliate home page</p> 
            </div>
        </div> -->


        <div class="modal-content" style="    max-width: 400px;    margin: 0 auto;">
            <div class="modal-header">
                <h4 class="modal-title">OTP Verification</h4>
                <button type="button" class="close" #closeOtpModal data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form class=" otp-form" [formGroup]="otpForm">
                <div class="modal-body">
                    <div class="alert alert-success text-bold">
                        <p class="m-0 "><span class=" text-black">We have a verification code to your Mobile No:
                            </span>XXXXXX{{userInfo?.mobile | slice: (userInfo?.mobile.length -4)}}</p>
                    </div>
                    <!-- <div class="alert alert-danger text-bold">
                <p class="m-0 "><span class=" text-black">You have entered wrong OTP, you have</span> 2 attempts left.</p>                
            </div>         -->
                    <div class="form-group  mt-4">
                        <input type="text" formControlName="otp" placeholder="Enter Verification Code" />
                    </div>
                    <span
                        [ngClass]="{'text-danger': respType=='error','text-success': respType=='success'}">{{respMessage}}</span>
                </div>

                <div class="modal-footer">
                    <button type="button" (click)="submit(loginForm.value,true)"
                        class="btn btn-lg btn-white text-dark-pink ml-3" *ngIf="!isOTPVerfing"><i
                            class="fa fa-refresh mr-1"></i> Resend OTP</button>
                    <button type="button" (click)="validateOtp()" class="btn btn-lg btn-green ml-3"
                        *ngIf="!isOTPVerfing">Submit & Verify OTP
                    </button>
                    <button type="button"
                        class="btn btn-lg btn-green w-100 justify-content-center d-flex align-items-center"
                        *ngIf="isOTPVerfing">Verifying OTP <div id="wave">
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <span class="dot"></span>
                        </div>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="position-relative">
    <button type="hidden" #otpModal data-toggle="modal" data-target="#OTPModal"></button>
    <div class="login-card-container position-sticky">
        <section id="filterselection" class="login card position-relative">
            <img src="../../assets/v3images/icons/green-flight-tag.svg" class="card-tag" width="20px" alt="" srcset="">
            <!-- <img src="../../assets/v3images/shapes/dotted-shape2.svg" class="dotted-shape" width="20px" alt="" srcset=""> -->
            <div>
                <div class="login-container">
                    <div>
                        <form [formGroup]="loginForm" (ngSubmit)="submit(loginForm.value,false)" class="row m-0 p-0">
                            <div class="col-sm-4 ">
                                <span class="textbox email w-100">
                                    <div class="form-group d-flex align-items-center">
                                        <div class="position-relative w-100">
                                            <!-- <img src="../../assets/v3images/icons/black-email.svg" *ngIf="(email.valid || !email.touched) && !emailNotFound" class="input-icon" width="20px" alt="" srcset="" > -->
                                            <!-- <img src="../../assets/v3images/icons/red-warning-alert.svg" class="input-icon" width="20px" alt="" *ngIf="!email.valid && email.touched || emailNotFound" srcset=""> -->
                                            <img src="../../assets/v3images/icons/{{!email.valid && email.touched || emailNotFound ?'red-warning-alert.svg': 'black-email.svg'}}"
                                                class="input-icon" width="20px" alt="" srcset="">

                                            <label for="" class="required">
                                                {{(email.valid || !email.touched) && !emailNotFound ? 'Your Email Id':
                                                ''}}
                                                <span class="text-danger">{{ !email.valid && email.touched ? 'Email
                                                    Required ' : emailNotFound ? 'Email Not Found' : ''}}</span>
                                            </label>

                                            <!-- <label for="" class="required" *ngIf="(email.valid || !email.touched) && !emailNotFound">Your Email Id</label>
                                        <label for="" style="    color: #de404f!important;" *ngIf="!email.valid && email.touched"> Email Required </label>
                                        <label for="" style="    color: #de404f!important;" *ngIf="emailNotFound"> Email Not Found </label> -->
                                            <input
                                                [ngClass]="!email.valid && email.touched || emailNotFound ? 'red-border-bottom' : ''"
                                                class="form-control" placeholder="Email OR Agent Code"
                                                formControlName="email" required (keyup)="removeError()">
                                        </div>
                                    </div>
                                    <!-- <span class="errorLoginColor text-danger" *ngIf="!email.valid && email.touched"> Email Required </span> -->
                                </span>
                                <!-- <span *ngIf="emailNotFound" class="errorLoginColor text-danger"> Email Not Found </span> -->
                                <span *ngIf="EmulationError" class="errorLoginColor text-danger"> {{EmulationErrorMsg}}
                                </span>
                            </div>
                            <div class="col-sm-4  ">
                                <span class="textbox password showPasswrdIcon w-100">
                                    <div class="form-group d-flex align-items-center position-relative">
                                        <div class="position-relative w-100">
                                            <img src="../../assets/v3images/icons/{{!password.valid && password.touched  ?'red-warning-alert.svg': 'black-lock.svg'}}"
                                                class="input-icon" width="20px" alt="" srcset="">

                                            <!-- <img src="../../assets/v3images/icons/black-lock.svg" class="input-icon" width="20px" alt="" srcset=""> -->
                                            <!-- <label for="" class="required">Your Password</label> -->

                                            <label for="" class="required">
                                                {{(password.valid || !password.touched) && !pwdNotFound ? 'Your
                                                Password': ''}}
                                                <span class="text-danger">{{ !password.valid && password.touched ?
                                                    'Password Required ' : pwdNotFound ? 'Password Not Found' :
                                                    ''}}</span>
                                            </label>
                                            <div class="d-flex align-items-center">

                                                <input
                                                    [ngClass]="!password.valid && password.touched  ? 'red-border-bottom' : ''"
                                                    class=" form-control" placeholder="Password"
                                                    [type]="hide ? 'text' : 'password'" formControlName="password"
                                                    required (keyup)="removeError()">

                                                <img src="../../assets/v3images/icons/{{!hide ? 'black-opened-eye.svg' : 'closed-eye.png'}}"
                                                    class="cursor-pointer" style="border-bottom: 1px solid #7c68d4;
                                                padding-bottom: 6px;" width="20px" alt="" srcset=""
                                                    (click)="hide = !hide"
                                                    [ngClass]="!password.valid && password.touched  ? 'red-border-bottom' : ''">

                                                <!-- <img *ngIf="!hide" src="../../assets/v3images/icons/" class="cursor-pointer" style="border-bottom: 1px solid #7c68d4;
                                                padding-bottom: 10px;" width="20px" alt="" srcset="" (click)="hide = !hide"> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <mat-icon class="customIcon" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon> -->
                                    <!-- <span class="errorLoginColor text-danger" *ngIf="!password.valid && password.touched">Password Required</span> -->
                                </span>
                                <!-- <span *ngIf="pwdNotFound" class="errorLoginColor text-danger"> Password is not correct</span> -->
                            </div>
                            <div class="col-sm-4 action-btns">
                                <button type="submit" [disabled]="!loginForm.valid"
                                    class="submit d-flex align-items-center justify-content-center  w-100 btn btn-green"><span
                                        class="mr-2">Login</span>
                                    <!-- <div id="wave" *ngIf="isLogin == true">
                                        <span class="dot"></span>
                                        <span class="dot"></span>
                                        <span class="dot"></span>
                                    </div> -->
                                </button>
                                <a (click)="forgetPassword()"
                                    class="mt-2 text-grey float-right text-dark-green-hover text-right">Forgot
                                    Password?</a>
                            </div>
                        </form>
                    </div>
                    <!-- <div class="loginright">
                        <div class="row ml-0 mr-0 d-flex justify-content-center mobile-gutter-fix">
                            <div class="no-account-head">
                                <p class="creacc">No Account? <span>Sign Up</span></p>
                            </div>
                            <div class="signup-btn">
                                <div class="signupbtn">
                                    <a routerLink="/signup"><button type="button" class="submit" >Sign Up</button></a>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </section>
    </div>
    <div class="login-main-banner">
        <owl-carousel-o [options]="mainBanners">
            <ng-container *ngFor="let slide of mainBannersSlides">
                <ng-template carouselSlide [id]="slide.id">
                    <img [src]="slide.img">
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>

    <section class="section app-intro position-relative">
        <div class="container position-relative z-index2">
            <div class="row">
                <div class="col-lg-7">
                    <small class="text-dark-orange text-bold">Application</small>
                    <h3>App Features</h3>
                    <div class="row">
                        <div class="col-sm-6">
                            <ul class="list-unstyled app-features ml-0 p-0">
                                <li>
                                    <img src="../../assets/v3images/icons/app-feature1.svg" alt="" srcset=""
                                        class="app-icon">
                                    <span>Lightening Fast Booking</span>
                                </li>
                                <li>
                                    <img src="../../assets/v3images/icons/app-feature2.svg" alt="" srcset=""
                                        class="app-icon">
                                    <span>Agent Friendly Interface</span>
                                </li>
                                <li>
                                    <img src="../../assets/v3images/icons/app-feature3.svg" alt="" srcset=""
                                        class="app-icon">
                                    <span>Easy To Monitor Your Bookings</span>
                                </li>
                            </ul>
                        </div>
                        <div class="col-sm-6">
                            <ul class="list-unstyled app-features ml-0 p-0">
                                <li>
                                    <img src="../../assets/v3images/icons/app-feature4.svg" alt="" srcset=""
                                        class="app-icon">
                                    <span>Easily Accessible</span>
                                </li>
                                <li>
                                    <img src="../../assets/v3images/icons/app-feature5.svg" alt="" srcset=""
                                        class="app-icon">
                                    <span>Easy & secured Payment</span>
                                </li>
                                <li>
                                    <img src="../../assets/v3images/icons/app-feature6.svg" alt="" srcset=""
                                        class="app-icon">
                                    <span>Easy To Work</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class=" qr-code-section mt-5  d-flex align-items-center">
                        <div class="qr-box">
                            <img src="../../assets/v3images/qr-code.svg" alt="">
                        </div>
                        <div>
                            <h4 class="text-color text-bold m-0">Enjoy Seamless Booking with our App</h4>
                            <p class="m-0">Scan the QR code and install it</p>
                            <small class="text-grey">Booking Travel Services Just Got Easier with the Aadesh App.
                                <br>Download the Latest Version TODAY
                            </small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class=" app-screens text-center mt-4">
                        <img src="../../assets/v3images/mobile-app-screens.png" alt="" />
                        <div class="mt-2 d-flex justify-content-center align-items-center app-stores">
                            <a href="https://play.google.com/store/apps/details?id=com.aadeshtravels.app"
                                target="_blank"> <img src="../../assets/v3images/play-store.png" alt="" srcset=""
                                    class="mr-2 hover-shadow" /></a>
                            <a href="https://apps.apple.com/in/app/aadesh-travel/id1529118394#?platform=iphone"
                                target="_blank"> <img src="../../assets/v3images/app-store.png" alt="" srcset=""
                                    class="hover-shadow" /></a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="row">
                <div class="col-sm-6 qr-code-section  d-flex align-items-center">
                    <div  class="qr-box">
                        <img src="../../assets/v3images/qr-code.svg" alt="">
                    </div>
                    <div>
                        <h4 class="text-color text-bold m-0">Enjoy Seamless Booking with our App</h4>
                        <p class="m-0">Scan the QR code and install it</p>
                        <small class="text-grey">Booking Travel Services Just Got Easier with the Aadesh App Download the Latest Version TODAY
                        </small>
                    </div>
                </div>
                <div class="col-sm-6 d-flex justify-content-center align-items-center app-stores">
                    <a href="#"> <img src="../../assets/v3images/play-store.png" alt="" srcset="" class="mr-2"  /></a>
                    <a href="#"> <img src="../../assets/v3images/app-store.png" alt="" srcset=""  /></a>
                </div>
            </div> -->
        </div>
    </section>

    <section class="section pt-0">
        <div class="why-aadesh position-relative bg-white container">
            <h3 class="text-capitalize text-bold mt-2 mb-2">Why Prefer aadesh Travels ?</h3>
            <div class="row p-2">
                <div class="col-lg-8 pb-3">
                    <div class="row pl-3 ">
                        <div class="col-sm-6">
                            <div class="d-flex align-items-center">
                                <div class="icon-box">
                                    <img src="../../assets/v3images/icons/competetive.svg" alt="" srcset="">
                                </div>
                                <div>
                                    <h5 class="mb-0 text-bold">Competitive Price</h5>
                                    <small class="text-grey mt-0 line-clamp line-clamp-3">Lorem ipsum dolor sit amet
                                        consectetur, adipisicing elit. Laborum inventore alias commodi expedita quasi
                                        perspiciatis.</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="d-flex align-items-center">
                                <div class="icon-box">
                                    <img src="../../assets/v3images/icons/booking-experience.svg" alt="" srcset="">
                                </div>
                                <div>
                                    <h5 class="mb-0 text-bold">Seamless Booking Experience</h5>
                                    <small class="text-grey mt-0 line-clamp line-clamp-3">Lorem ipsum dolor sit amet
                                        consectetur, adipisicing elit. Laborum inventore alias commodi expedita quasi
                                        perspiciatis.</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="d-flex align-items-center">
                                <div class="icon-box">
                                    <img src="../../assets/v3images/icons/headphone.svg" alt="" srcset="">
                                </div>
                                <div>
                                    <h5 class="mb-0 text-bold">24/7 Customer Service</h5>
                                    <small class="text-grey mt-0 line-clamp line-clamp-3">Lorem ipsum dolor sit amet
                                        consectetur, adipisicing elit. Laborum inventore alias commodi expedita quasi
                                        perspiciatis.</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="d-flex align-items-center">
                                <div class="icon-box">
                                    <img src="../../assets/v3images/icons/agent-return.svg" alt="" srcset="">
                                </div>
                                <div>
                                    <h5 class="mb-0 text-bold">Great Agent Return</h5>
                                    <small class="text-grey mt-0 line-clamp line-clamp-3">Lorem ipsum dolor sit amet
                                        consectetur, adipisicing elit. Laborum inventore alias commodi expedita quasi
                                        perspiciatis.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="baggage-img">
                        <img src="../../assets/v3images/plane-baggage.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section pb-0">
        <div class="testimonials">
            <div class="row">
                <div class="col-lg-4">
                    <div>
                        <small class="text-dark-orange text-bold">TESTIMONIALS</small>
                        <h3 class="mt-0">What People Say
                            <span class="d-block">About Us</span>
                        </h3>
                    </div>
                    <img src="../../assets/v3images/flying-plan.png" alt="" class="flying-img">
                </div>
                <div class="col-lg-8 pr-0 ">
                    <div id="testimonial-slider">
                        <owl-carousel-o [options]="testimonialSlider">
                            <ng-container *ngFor="let slide1 of testimonialSlides">
                                <ng-template carouselSlide>
                                    <div [id]="slide1.id" class="pt-3 pb-3">
                                        <div class="client-card active position-relative mr-4">
                                            <div class="client-pic">
                                                <img [src]="slide1.pic" alt="">
                                            </div>
                                            <div class="client-says p-4">
                                                <p class="text-grey">{{slide1.comment}}.</p>
                                                <h5 class="text-capitalize mt-2">{{slide1.name}}</h5>
                                                <small
                                                    class="text-light-grey text-uppercase">{{slide1.designation}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                        <!-- <div class=" d-flex">
                        <div class="client-card active position-relative mr-4">
                            <div class="client-pic">
                                <img src="../../assets/v3images/client1.png" alt="">
                            </div>
                            <div class="client-says p-4">
                                <p class="text-grey">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sed eos quas neque distinctio nulla quam voluptatibus eum assumenda culpa sunt natus cupiditate architecto, nesciunt aliquid similique nisi eaque. Dolore, enim.</p>
                                <h5 class="text-capitalize mt-2">harish sharma</h5>
                                <small class="text-light-grey">CEO at OPKUBE</small>
                            </div>
                        </div>
                        <div class="client-card active position-relative mr-4">
                          <div class="client-pic">
                              <img src="../../assets/v3images/client2.png" alt="">
                          </div>
                          <div class="client-says p-4">
                              <p class="text-grey">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sed eos quas neque distinctio nulla quam voluptatibus eum assumenda culpa sunt natus cupiditate architecto, nesciunt aliquid similique nisi eaque. Dolore, enim.</p>
                              <h5 class="text-capitalize mt-2">Prince Verma</h5>
                              <small class="text-light-grey">Sr. UI UX Designer at OPKUBE</small>
                          </div>
                      </div>      
                    </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>

<section id="registration-section" class="section ">
    <div class="registration-section position-relative">
        <img src="../../assets/v3images/shapes/dotted-shape3.svg" alt="" srcset="" class="dotted-img position-absolute">
        <div class="gredient-border">
            <div class="registration-card  p-4">
                <img src="../../assets/v3images/shapes/green-curvy-shape.svg" class="shape-img" alt="" srcset="">
                <div class="row m-0 p-0">
                    <div class="col-lg-6 text-center" id="registration-section-header">
                        <h3 class="text-bold">Register Yourself <br>As An Affiliate</h3>
                        <div class="register-img">
                            <img src="../../assets/v3images/famous-monuments.png" alt="">
                        </div>
                    </div>
                    <div class="col-lg-6 registration-form ">
                        <!-- <p class="registration-msg shadow-lg pl-3 pr-3 pt-2 pb-2 border-radius-5">Reistered successfully!!!</p> -->
                        <p class="shadow-lg pl-3 pr-3 pt-2 pb-2 border-radius-5" [ngClass]="{
                            'bg-success': dataofRegisterForm?.status == 'success',
                            'bg-danger': dataofRegisterForm?.status == 'failed'}"
                            *ngIf="dataofRegisterForm && dataofRegisterForm.message">
                            {{dataofRegisterForm.message}}
                        </p>
                        <form [formGroup]="registerFormData" class="row">
                            <!-- (ngSubmit)="onRegisterSubmit()" calling the registration form-->
                            <div class="col-md-4 col-xl-3">
                                <div class="form-group d-flex align-items-center">
                                    <img src="../../assets/v3images/icons/green-user.svg" class="input-icon"
                                        width="20px" alt="" srcset="">
                                    <div class="w-100">
                                        <label class="required" for="title">Title</label>
                                        <select formControlName="selTitle" id="title" class="form-control mb-0">
                                            <option default value="Mr.">MR.</option>
                                            <option value="Mrs.">MRS.</option>
                                            <option value="Miss.">MISS.</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-xl-5">
                                <div class="form-group d-flex align-items-center">
                                    <!-- <img src="../../assets/v3images/icons/green-user.svg" class="input-icon" width="20px" alt="" srcset=""> -->
                                    <img src="../../assets/v3images/icons/{{!txtFirstName.valid && txtFirstName.touched  ?'red-warning-alert.svg': 'green-user.svg'}}"
                                        class="input-icon" width="20px" alt="" srcset="">

                                    <div class="w-100">
                                        <label class="required" for=""
                                            [ngClass]="txtFirstName.invalid && txtFirstName.touched ? 'text-danger': ''">First
                                            Name</label>
                                        <input formControlName="txtFirstName" maxlength="50" class="form-control"
                                            oninput="this.value = this.value.toUpperCase()">
                                    </div>
                                </div>
                                <!-- <span *ngIf="txtFirstName.invalid && txtFirstName.touched" class="errorLoginColor text-danger" >First Name is Required* </span> -->
                            </div>
                            <div class="col-md-4 col-xl-4">
                                <div class="form-group d-flex align-items-center">
                                    <img src="../../assets/v3images/icons/{{!txtLastName.valid && txtLastName.touched  ?'red-warning-alert.svg': 'green-user.svg'}}"
                                        class="input-icon" width="20px" alt="" srcset="">
                                    <div class="w-100">
                                        <label class="required" for=""
                                            [ngClass]="txtLastName.invalid && txtLastName.touched ? 'text-danger': ''">Last
                                            Name</label>
                                        <input formControlName="txtLastName" maxlength="50"
                                            oninput="this.value = this.value.toUpperCase()" class="form-control">
                                    </div>
                                </div>
                                <!-- <span *ngIf="txtLastName.invalid && txtLastName.touched" class="errorLoginColor text-danger" > Invalid lastname* </span> -->
                            </div>
                            <div class="col-md-6">
                                <div class="form-group d-flex align-items-center">
                                    <img src="../../assets/v3images/icons/{{!txtMobile.valid && txtMobile.touched ?'red-warning-alert.svg': 'green-phone.svg'}}"
                                        class="input-icon" width="20px" alt="" srcset="">

                                    <!-- <img src="../../assets/v3images/icons/green-phone.svg" class="input-icon" width="20px" alt="" srcset=""> -->
                                    <div class="w-100">
                                        <label class="required" for=""
                                            [ngClass]="txtMobile.invalid && txtMobile.touched ? 'text-danger': ''">Mobile
                                            Number</label>
                                        <input type="text" formControlName="txtMobile" class="form-control">
                                    </div>
                                </div>
                                <!-- <span *ngIf="txtMobile.invalid && txtMobile.touched" class="errorLoginColor text-danger" > Enter valid Mobile Number</span> -->
                            </div>
                            <div class="col-md-6">
                                <div class="form-group  d-flex align-items-center">
                                    <img src="../../assets/v3images/icons/{{!Regemail.valid && Regemail.touched  ?'red-warning-alert.svg': 'green-email.svg'}}"
                                        class="input-icon" width="20px" alt="" srcset="">
                                    <!-- <img src="../../assets/v3images/icons/green-email.svg" class="input-icon" width="20px" alt="" srcset=""> -->
                                    <div class="w-100">
                                        <label class="required" for=""
                                            [ngClass]="Regemail.invalid && Regemail.touched ? 'text-danger': ''">Email
                                            Id</label>
                                        <input type="email" formControlName="txtPrimaryEmail" maxlength="50"
                                            oninput="this.value = this.value.toUpperCase()" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$" class="form-control">
                                    </div>
                                </div>
                                <!-- <span *ngIf="Regemail.invalid && Regemail.touched"  class="errorLoginColor text-danger"> Email Id Required* </span>  -->
                            </div>
                            <div class="col-md-12">
                                <div class="form-group  d-flex align-items-center">
                                    <img src="../../assets/v3images/icons/green-suitcase.svg" class="input-icon"
                                        width="20px" alt="Something" srcset="">
                                    <div class="w-100">
                                        <label for="">Company Name</label>
                                        <input formControlName="txtCompanyName"
                                            oninput="this.value = this.value.toUpperCase()" maxlength="100"
                                            class="form-control">
                                    </div>
                                </div>
                                <!--    <span class="errorLoginColor text-danger" > Company Name Required </span>  -->
                            </div>
                            <div class="col-md-12 d-flex justify-content-center pt-2">
                                <!-- -->
                                <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                                </ngx-recaptcha2>
                            </div>
                            <div class="col-md-12">
                                <button type="submit" (click)="onRegisterSubmit()" [disabled]="!registerFormData.valid"
                                    class="btn btn-green cursor w-100 pt-2 pb-2 mt-3">Register</button>
                            </div>
                            <!-- <div *ngIf="dataofRegisterForm && dataofRegisterForm.message">
                        {{dataofRegisterForm.message}}
                     </div>    -->

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <section class="section">
<div class="airline-logoes container">
<owl-carousel-o [options]="airlinesLogo">  
    <ng-container *ngFor="let logoslide of airlinesLogoSlides">
      <ng-template carouselSlide [id]="logoslide.id">
        <div class="logo-container">
            <img [src]="logoslide.img" >
        </div>
      </ng-template>
    </ng-container>              
</owl-carousel-o>
</div>
</section> -->
<section class="section">
    <div id="logo-slider">
        <div class="logo-container">
            <img src="../../assets/v3images/logoes/air-india-logo.png" alt="" srcset="">
        </div>
        <div class="logo-container">
            <img src="../../assets/v3images/logoes/indigo-logo.png" alt="" srcset="">
        </div>
        <div class="logo-container">
            <img src="../../assets/v3images/logoes/jet-airways-logo.png" alt="" srcset="">
        </div>
        <div class="logo-container">
            <img src="../../assets/v3images/logoes/spice-jet-logo.png" alt="" srcset="">
        </div>
    </div>
</section>

<!-- <section id="slider">
    <app-banners [images]="homeSlider" [itemsToDisplay]="1" [showNavigation]="false" [isLoop]="true" [styleClass]="'homeslider'"></app-banners>
</section>  -->

<!-- <app-app-download></app-app-download> -->

<!-- <section id="airlineslide">
    <div class="container">
        <app-banners [images]="flightSlider" [itemsToDisplay]="5" [showNavigation]="true" [styleClass]="'carour3'" [isLoop]="true"></app-banners>
    </div>
</section> -->
<!-- 
<section id="awards">
    <div class="container">
        <div class="gujarat">
            <img src="assets/images/b2bagent.jpg">
        </div>
    </div>
    <div class="awardbg">
        <img src="assets/images/award_bg.png" alt="" class="awabg">
    </div>
    <div class="iatapayment">
        <p class="iata"><img src="assets/images/iata.png" alt=""></p>
        <p class="payment"><img src="assets/images/icon_payment.png" alt=""></p>
    </div>
</section> -->