<div class="Container search-error">

  <div class="MainGraphic">
    <svg id="Gear" class="Cog" xmlns="http://www.w3.org/2000/svg" fill="#eeeeee" viewBox="0 0 32 32"><path d="M29.18 19.07c-1.678-2.908-.668-6.634 2.256-8.328L28.29 5.295c-.897.527-1.942.83-3.057.83-3.36 0-6.085-2.743-6.085-6.126h-6.29c.01 1.043-.25 2.102-.81 3.07-1.68 2.907-5.41 3.896-8.34 2.21L.566 10.727c.905.515 1.69 1.268 2.246 2.234 1.677 2.904.673 6.624-2.24 8.32l3.145 5.447c.895-.522 1.935-.82 3.044-.82 3.35 0 6.066 2.725 6.083 6.092h6.29c-.004-1.035.258-2.08.81-3.04 1.676-2.902 5.4-3.893 8.325-2.218l3.145-5.447c-.9-.515-1.678-1.266-2.232-2.226zM16 22.48c-3.578 0-6.48-2.902-6.48-6.48S12.423 9.52 16 9.52c3.578 0 6.48 2.902 6.48 6.48s-2.902 6.48-6.48 6.48z"/></svg>
  </div>
  <br>
  <h1 class="MainTitle ccontent__header ccontent__header--strong">
        500</h1>
  <span class="MainDescription">
         
        Internal Server Error</span>
        <span><br>
          <div class="actionbtn mt-3">
              <a routerLink="/dashboard" class="btnPrint">
                Visit Dashboard
              </a>
            </div>
        </span>

</div>

<!-- <table cellspacing="0" cellpadding="0" border="0" width="90%" style="margin:20px auto;border:1px solid rgb(215,215,215)">
	<tbody>
		 <tr>
				<td>
					 <table cellspacing="0" cellpadding="0" border="0" width="100%" style="background:none repeat scroll 0% 0% rgb(243,244,244)">
							<tbody>
								 <tr>
										<td height="50" style="padding-left:15px">
											 <p><strong>Dear Partner,</strong></p>
											 <p style="padding-top:6px">As per your request, we are forwarding itineraries from 
													DEL to BOM on       	Friday , May 31,2019
											 </p>
										</td>
								 </tr>
							</tbody>
					 </table>
				</td>
		 </tr>
		 <tr>
				<td>
					 <table cellspacing="0" cellpadding="0" border="0" width="100%">
							<tbody>
								 <tr>
										<td height="60" style="padding-left:30px">
											 <h3>
													DEL to BOM
											 </h3>
											 <p>
													Economy 
													class | 1 Adult
											 </p>
										</td>
								 </tr>
							</tbody>
					 </table>
				</td>
		 </tr>
		 <tr>
				<td align="center">
					 <div>
							<table cellspacing="0" cellpadding="0" border="0" width="100%" style="background:#eeeeee;border:1px solid #c6c6c6;color:#666666;margin:0px 0 0 0;height:25px">
								 <tbody>
										<tr>
											 <td align="left" height="25" width="10%" style="padding-left:10px"><strong>Airline</strong></td>
											 <td width="22%"><strong>Stops</strong></td>
											 <td width="19%"><strong>Departs</strong></td>
											 <td width="19%"><strong>Arrives</strong></td>
											 <td width="12%"><strong>Duration</strong></td>
											 <td width="25%"><strong>Price</strong></td>
										</tr>
								 </tbody>
							</table>
							<table cellspacing="0" cellpadding="0" border="0" width="100%" style="background:#e4f5ff;border:1px solid #ccc;margin:5px 0px 0px">
								 <tbody>
										<tr>
											 <td>
													<table width="100%" border="0" cellpadding="0" cellspacing="0">
														 <tbody>
																<tr>
																	 <td valign="top" width="10%" align="left">
																			<table cellspacing="0" cellpadding="0" border="0" width="100%" style="float:left">
																				 <tbody>
																						<tr>
																							 <td align="left" valign="top" height="80" style="padding:4px 0 0 8px">
																									<p style="margin:3pt"><img alt="6E" src="https://ci5.googleusercontent.com/proxy/avWe8oBex_K4pEcuPfOol4MU2hUQhfeoabjgubOvzZD-EyAH1wsFu2c0SF0u-BMP6-9lM92D4vs8zaslIT10SlBIJC9M=s0-d-e1-ft#http://www.aadeshtravels.com/images/airline/6E.gif" class="CToWUd"></p>
																									<p style="margin:3pt">Indigo</p>
																							 </td>
																						</tr>
																				 </tbody>
																			</table>
																	 </td>
																	 <td valign="top" width="72%" align="left">
																			<table cellspacing="0" cellpadding="0" border="0" width="100%" style="float:left;padding:0 0 5px 0">
																				 <tbody>
																						<tr>
																							 <td>
																									<table cellspacing="0" cellpadding="0" border="0" width="100%" style="float:left;margin-top:2px">
																										 <tbody>
																												<tr>
																													 <td>
																															<table cellspacing="0" cellpadding="0" border="0" width="100%" style="border-bottom:1px solid #ccc;margin-top:10px">
																																 <tbody>
																																		<tr>
																																			 <td width="29%" style="padding-bottom:4px">																	
																																					<span>
																																					DEL - 
																																					AMD - 
																																					GOI - BOM 
																																					</span> 
																																					<span style="color:#aaaaaa;text-decoration:none">(2)</span>
																																			 </td>
																																			 <td width="26%" style="padding-bottom:4px">
																																					<span style="padding-left:2px">11:05,31 May 2019</span>
																																			 </td>
																																			 <td width="30%" style="padding-bottom:4px">
																																					18:30,31 May 2019
																																			 </td>
																																			 <td width="15%" style="padding-bottom:4px">7h 25m</td>
																																		</tr>
																																 </tbody>
																															</table>
																													 </td>
																												</tr>
																										 </tbody>
																									</table>
																							 </td>
																						</tr>
																				 </tbody>
																			</table>
																	 </td>
																	 <td valign="top" width="18%" align="left">
																			<table cellspacing="0" cellpadding="0" border="0" width="100%" style="float:left;text-align:center;border:1px solid #c2c2c2;background:#f8f8f8;margin:5px 0 10px 0">
																				 <tbody>
																						<tr>
																							 <td>
																									<h3 style="color:#ec951f;margin:3pt"><strong>Total
																										 Price </strong>
																									</h3>
																									<h3 style="color:#ec951f;margin:3pt"><strong>Rs
																										 6,062				
																										 </strong>
																									</h3>
																							 </td>
																						</tr>
																				 </tbody>
																			</table>
																	 </td>
																</tr>
														 </tbody>
													</table>
											 </td>
										</tr>
										<tr>
											 <td bgcolor="#e4f5ff" style="padding:5px">
													<table cellspacing="0" cellpadding="0" border="0" width="100%" style="background:none repeat scroll 0% 0% rgb(238,238,238);border:1px solid rgb(198,198,198);color:rgb(102,102,102);margin:0px 0pt 0pt">
														 <tbody>
																<tr>
																	 <td align="left" width="60%" height="25" style="padding-left:10px"><strong>
																			Going There :
																			</strong>
																			Friday, 31 May, 2019
																	 </td>
																	 <td width="25%"><strong>Total Duration :</strong> 7h 25m</td>
																	 <td width="15%"><strong>
																			Economy 
																			</strong>
																	 </td>
																</tr>
														 </tbody>
													</table>
													<div style="border-right:1px solid rgb(204,204,204);border-width:medium 1px 1px;border-style:none solid solid;border-color:-moz-use-text-color rgb(204,204,204) rgb(204,204,204)">
														 <table cellspacing="0" border="0" width="100%">
																<tbody>
																	 <tr>
																			<td bgcolor="#ffffff" width="6%" rowspan="2"><img alt="6E" src="https://ci5.googleusercontent.com/proxy/avWe8oBex_K4pEcuPfOol4MU2hUQhfeoabjgubOvzZD-EyAH1wsFu2c0SF0u-BMP6-9lM92D4vs8zaslIT10SlBIJC9M=s0-d-e1-ft#http://www.aadeshtravels.com/images/airline/6E.gif" class="CToWUd"></td>
																			<td bgcolor="#ffffff" width="8%" height="25">
																				 <p>Indigo </p>
																			</td>
																			<td bgcolor="#ffffff" width="13%">
																				 <p><strong>Dep :</strong>  
																						11:05 ,
																				 </p>
																			</td>
																			<td bgcolor="#ffffff" width="39%">
																				 <p>
																						Delhi, IN (DEL) 
																						,Indiragandhi Intl Airport
																				 </p>
																			</td>
																			<td bgcolor="#ffffff" width="10%">
																				 <p> </p>
																			</td>
																			<td bgcolor="#ffffff" width="11%">
																				 <p><strong>Class</strong> - M</p>
																			</td>
																			<td bgcolor="#ffffff" width="14%">
																				 <p> </p>
																			</td>
																	 </tr>
																	 <tr>
																			<td bgcolor="#ffffff" height="25">161 </td>
																			<td bgcolor="#ffffff"><strong>Arr :</strong> 
																				 12:35 ,
																			</td>
																			<td bgcolor="#ffffff">
																				 Ahmedabad, IN (AMD) 
																				 ,Ahmedabad Airport
																			</td>
																			<td bgcolor="#ffffff"> </td>
																			<td bgcolor="#ffffff"> </td>
																			<td bgcolor="#ffffff"> </td>
																	 </tr>
																	 <tr>
																			<td bgcolor="#ffffff"> </td>
																			<td bgcolor="#ffffff"> </td>
																			<td bgcolor="#ffffff"> </td>
																			<td bgcolor="#ffffff" align="right" colspan="4">
																				 <table cellspacing="0" border="0" width="50%" style="font-size:11px">
																						<tbody>
																							 <tr>
																									<td bgcolor="#faf9a4" align="left" width="11%" valign="top"><span><img vspace="4" hspace="0" src="https://ci3.googleusercontent.com/proxy/0-EGxxnOLjVlcv2Sda9SyrQGNdA02TjWbSdymnd0BBrN6aaaaWTyeTrn2zIvH3HCMTGSWrpJw9PAjyUEfCqI4w=s0-d-e1-ft#http://www.aadeshtravels.com/images/clock.png" class="CToWUd"></span></td>
																									<td bgcolor="#faf9a4" width="42%"><span class="m_8874592731084532778leftfloat"><strong>Layover : 
																										 0h 35m </strong></span>
																									</td>
																							 </tr>
																						</tbody>
																				 </table>
																			</td>
																	 </tr>
																</tbody>
														 </table>
													</div>
											 </td>
										</tr>
								 </tbody>
							</table>
					 </div>
				</td>
		 </tr>
		 <tr>
				<td> </td>
		 </tr>
	</tbody>
</table> -->