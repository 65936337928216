<div class="container-fluid mt-3 mb-70 p-0" id="printTicket" *ngIf="valid">
        <div class="alert alert-warning" *ngIf="showPriceWarning">
          This document seems to be updated offline.
          Please connect to our accounts team to get latest document.
        </div>
    <div class="col-12" id="print-section">
        <div class="row border-bottom text-center inv-header">
            <div class="col-md-4 logo-col">
                <img class="logo" src="../../../../assets/images/logo2.png">
            </div>
            <div class="col-md-4 txt-head">
                <h3 class="text-uppercase">Invoice</h3>
            </div>
            <div class="col-md-4 aff-addr">
                <span>{{userData?.companyName}}</span> <br>
                <span class="w-100">{{userData?.address1}} {{userData?.city}}-{{userData?.pincode}}<br> {{userData?.state_name}},
          {{userData?.country_name}}</span> <br>
            </div>
        </div>
        <div class="row inv-col">
            <div class="col-md-6 pb-2 pt-2"><span class="float-left"><span class="inv-num-head">Invoice Number :</span><span class="inv-num">{{bookingArr?.json_doc?.invoice_no}}</span></span>
            </div>
            <div class="col-md-6 pb-2 pt-2"><span class="float-right"><span class="inv-num-head">Invoice Date :</span> <span class="inv-num">{{bookingArr?.json_doc?.booked_timestamp | dateFormat}}</span></span>
            </div>
        </div>
        <div class="row align-items-center h-50 det-col">
            <div class="col-md-4 pb-2">
                <span class=""> <span class="inv-num-head">Email :</span><span class="inv-num">{{bookingArr?.json_doc?.contactdetails?.email}}</span></span>
            </div>
            <div class="col-md-4 pb-2 text-center"> <span class="mt-2"><span class="inv-num-head">Booking ID :</span><span class="inv-num">{{bookingArr?.json_doc?.booking_ref}}</span></span>
            </div>
            <div class="col-md-4 pb-2 text-right"> <span><span class="inv-num-head">Booking Date :</span><span class="inv-num">{{bookingArr?.json_doc?.booked_timestamp | dateFormat }}, {{bookingArr?.json_doc?.booked_timestamp | timeFormat}}</span></span>
            </div>
        </div>
        <!--
		    <div class="row w-100 m-0">
            <table class="table table-light pax-info-table">
                <thead class="pax-info-thead">
                    <tr>
                        <th>S no.</th>
                        <th>Ticket No</th>
                        <th>Sectors</th>
                        <th>Pax Name</th>
                        <th>Type</th>
                        <th>Class</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of bookingArr.json_doc.travellers; let i = index;">
                        <td>{{i+1}}</td>
                        <td>{{item?.ticket.number}}</td>
                        <td>{{bookingArr.json_doc.segments[0].legs[0].origin_airport_code}} - {{bookingArr.json_doc.segments[0].legs[0].destination_airport_code}}
                        </td>
                        <td>{{item?.title}} {{item?.first_name}} {{item?.last_name}}</td>
                        <td>{{item?.type}}</td>
                        <td>{{bookingArr.json_doc.segments[0].legs[0].cabin_class}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
	   -->
        <div class="flight-details clearfix" *ngFor="let segment of bookingArr.json_doc.segments ; let j=index">
            <h3 class="head-style mb-3" [ngClass]="{noheight: j > 0}"><span *ngIf="j == 0">Flight Details</span></h3>
            <ng-container *ngFor="let legs of segment.legs; let i = index;">
                <div class="flight-inner mb-4">
                    <span class="sno">{{i+1}}.</span>
                    <span class="air-info">{{legs?.operating_airline_name}} {{legs?.flight_number}} -  
									</span>
                    <span class="airport-origin">{{legs?.origin_airport_city}}, {{legs?.origin_airport_country}}
							({{legs?.origin_airport_code}})</span> to
                    <span class="airport-dest">{{legs?.destination_airport_city}}, {{legs?.destination_airport_country}}
							({{legs?.destination_airport_code}})</span>
                    <span class="origin-date">{{legs?.origin_timestamp | dateFormat}}, {{legs?.origin_timestamp | timeFormat }}</span>
                </div>
            </ng-container>
        </div>

        <div class="row bg-light m-0 align-items-center clearfix responsive-table">
            <table class="pax-table table table-sm table-light table-striped">
                <thead>
                    <tr>
                        <th>S no.</th>
                        <th>Ticket No</th>
                        <th>Sectors</th>
                        <th>Pax Name</th>
                        <th>Type</th>
                        <th>Class</th>
                        <th>Base Fare</th>
                        <th>YQ</th>
                        <th>YR</th>
                        <th>K3</th>
                        <th>OC</th>
                        <th>OT</th>
                        <th>Baggage</th>
                        <th>Meal</th>
                        <th>Seat</th>
                        <th>S.Srv.Chg.</th>
                        <th>S.Charges</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of  bookingArr.json_doc.travellers; let i =index;">
                        <ng-container *ngIf="item.status== 'TICKETED' || item.status== undefined">
                            <td>{{i+1}}</td>
                            <td>{{getTicket(item)}}</td>
                            <td>{{bookingArr?.json_doc?.segments[0]?.legs[0]?.origin_airport_code}} - {{bookingArr?.json_doc?.segments[0]?.legs[0]?.destination_airport_code}}
                            </td>
                            <td>{{item?.title}} {{item?.first_name}} {{item?.last_name}}</td>
                            <td>{{item?.type}}</td>
                            <td>{{bookingArr?.json_doc?.segments[0]?.legs[0]?.cabin_class}}</td>
                            <td>{{item?.fare?.base | currency:"INR":"symbol-narrow"}}</td>
                            <td>{{checkIfNull(item.fare.taxes[0].YQ) | currency:"INR":"symbol-narrow"}}</td>
                            <td>{{checkIfNull(item.fare.taxes[0].YR) | currency:"INR":"symbol-narrow"}}</td>

                            <td>{{item?.fare?.taxes[0]?.K3 | currency:"INR":"symbol-narrow"}}</td>

                            <td>{{item?.fare?.taxes[0]?.OC | currency:"INR":"symbol-narrow"}}</td>

                            <td>{{item?.fare?.taxes[0]?.OT | currency:"INR":"symbol-narrow"}}</td>
                            <td>{{getssr(item?.ssr,'baggages') | currency:"INR":"symbol-narrow"}}</td>
                            <!-- <ng-container *ngFor="let ssr of bookingArr.json_doc.travellers.ssr; let i=index;">

                        </ng-container> -->

                            <td>{{getssr(item?.ssr,'meals') | currency:"INR":"symbol-narrow"}}</td>

                            <td>{{getssr(item?.ssr,'seats') | currency:"INR":"symbol-narrow"}}</td>

                            <td>{{checkIfNull(item?.fare?.splCharge) | currency:"INR":"symbol-narrow"}}</td>

                            <td>{{checkIfNull(item?.fare?.taxes[0]?.ADMIN_SERVICE_CHARGE) | currency:"INR":"symbol-narrow"}}</td>


                            <td>{{getPerPrice(item) | currency:"INR":"symbol-narrow"}}</td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row w-100 m-0 border-bottom pt-2 pb-2">

            <div class="fare-results col-12 p-0">
                <ng-container *ngFor="let item of bookingArr.json_doc.travellers; let i = index;">
                    <div class="col f-result">
                        <!-- <ng-container *ngFor="let legs of bookingArr.json_doc.segments[0].legs">
                            <div>
                                <span>{{legs?.operating_airline_name}} {{legs?.flight_number}}
										</span>
                            <span>{{legs?.origin_airport_city}},{{legs?.origin_airport_country}}
								({{legs?.origin_airport_code}})</span> to
                            <span>{{legs?.destination_airport_city}},{{legs?.destination_airport_country}}
								({{legs?.destination_airport_code}})</span>
                            <br>
                            <span>{{legs?.origin_timestamp | date : 'dd/mm/yyyy'}}</span> &nbsp; &nbsp;
                            <span>{{legs?.origin_timestamp | date : 'HH:mm'}}</span>
        </div>
        </ng-container> -->
                    </div>


                    <div class="clearfix"></div>


                </ng-container>
            </div>

        </div>
        <div class="row justify-content-end border-bottom border-top m-0 bg-light pt-1 pb-1 amt-det">
            <div class="col-md-2 grand-head">Net Commission</div>
            <div class="col-md-2 ">
                <span class="float-right grand-total">{{netCommission | currency:"INR":"symbol-narrow"}}</span>
            </div>
        </div>
        <div class="row justify-content-end border-bottom border-top m-0 bg-light pt-1 pb-1 amt-det">
            <div class="col-md-2 grand-head">TDS</div>
            <div class="col-md-2 ">
                <span class="float-right grand-total">{{tds | currency:"INR":"symbol-narrow"}}</span>
            </div>
        </div>
        <div class="row justify-content-end border-bottom border-top m-0 bg-light pt-1 pb-1 amt-det">
            <div class="col-md-2 grand-head">Grand Total</div>
            <div class="col-md-2 ">
                <span class="float-right grand-total">&#8377;{{getTotal(bookingArr.json_doc,'') | number : '1.0-0'}}</span>
            </div>
        </div>
        <div class="row justify-content-end border-bottom border-top m-0 bg-light pt-1 pb-1 gt-words">
            <div class="col-md-3"><span class="float-right grand-head">Grand Total (In Words) </span></div>
            <div class="col-md-7">
                <span class="text-capitalize float-right grand-total">{{totalFare | numberToWords }} Only</span>
            </div>
        </div>
        <div class="row w-100 m-0 h-50 pt-4 border mt-3">
            <h5 class="w-100 head-style-2">General Rules</h5>
            <ul class="pl-5 customList">
                <li>We recomment you check-in At LEAST 2 Hours prior to departure.</li>
                <li>Changes/cancellation permitted more than Six(6) hours prior to departure with payment of change/cancellation fee as applicable from time to time</li>
                <li>If booked through a Travel Partner, a non-refundable transaction fee will be collected directly by the Travel Partner.
                </li>
                <li>The contact details printed above are the details updated with airline. You may need these if you have to contact the airline directly.</li>
                <li>All bookings are governed by Airline Terms and Conditions of Carriage. These are available from Airport Counters. Airline websites and officers.</li>
                <li>Name corrections/changes on your booking are not permitted.</li>
                <li>In case of cancellations within 6 hours of departure, please contact the respective airline and cancel the ticket directly with the airline. Please mail us the cancellation details and we will process the refund accordingly.
                </li>
            </ul>
        </div>
    </div>

    <div class="actionbtn mt-3">
        <a href="javascript:void(0)" class="btnPrint" (click)="print()">Print</a>
    </div>

</div>