import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BookingSummaryDetailRoutingModule } from './booking-summary-detail-routing.module';
import { BookingSummaryDetailComponent } from './booking-summary-detail.component';
import { DetailComponent } from './detail/detail.component';
import { PricingComponent } from './pricing/pricing.component';
import { MenuComponent } from './menu/menu.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddonSeatComponent } from './addon-seat/addon-seat.component';
import { AddonMealComponent } from './addon-meal/addon-meal.component';
import { AddonBaggageComponent } from './addon-baggage/addon-baggage.component';
import { AddonPricingComponent } from './addon-pricing/addon-pricing.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { BookingActivityComponent } from './booking-activity/booking-activity.component';
import { AddonAncillaryComponent } from './addon-ancillary/addon-ancillary.component';

@NgModule({
  imports: [
    CommonModule,
    BookingSummaryDetailRoutingModule,ReactiveFormsModule,FormsModule,SharedModule,
    NgxLoadingModule.forRoot({
			animationType: ngxLoadingAnimationTypes.circle
		})
  ],
  declarations: [BookingSummaryDetailComponent, DetailComponent, PricingComponent, MenuComponent, AddonSeatComponent, AddonMealComponent, AddonBaggageComponent, AddonPricingComponent, BookingActivityComponent, AddonAncillaryComponent]
})
export class BookingSummaryDetailModule { }
