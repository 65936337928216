<div #modalContainer class="custom-modal-box top-right transition add-on-modal-box p-3 " style="min-width:315px;top: 0; height: 100vh;" [ngClass]="isClickedOnAddonSideBarButton ? 'active' : ''"  >
  <div class="filter-header ">
      <img  src="../../../assets/v3images/icons/red-close.svg" alt="" (click)="hideAddOnSidebar()" srcset="" class="custom-modal-close-btn position-absolute grayscale-filter no-grayscale-filter-hover cursor">
      <small class="filterName m-0 text-uppercase text-pinkish-red ">Details</small>
      <h5 class="mt-0 text-bold text-capitalize">ADD-ON<small class="text-black">(<span>S</span>)</small></h5>
      <!-- <small class="filter-result-counts"> 1424 Results Found</small> -->
  </div>  
  <div class="add-on-details-area" *ngIf="selectedSsrInRedux != undefined && selectedSsrInRedux != null">
      <mat-accordion>
        <ng-container *ngFor="let traveller of selectedSsrInRedux.travellers ; let i =index">
          <ng-container *ngIf="traveller.type != 'INF'">
            <div class="card-tab m-2" >
              <mat-expansion-panel class="relative m-0" >
                  <mat-expansion-panel-header>
                      <mat-panel-title class="header-title">
                          <span class="text-grey text-bold mr-2">#{{i+1}}</span><span class="text-uppercase">{{traveller.title}}. {{traveller.firstName}} ({{traveller.type}}) </span>
                      </mat-panel-title>
                      <a class=" text-dark-pink text-bold mr-2" aria-controls="u804re-accordion" role="tab" id="u804re-accordion-label"
                          aria-expanded="true" aria-selected="true"><small>₹ {{traveller.subTotalAmount}}</small></a>
                  </mat-expansion-panel-header>
                  <ng-container *ngFor="let segment of traveller.segments">
                    <ng-container *ngFor="let leg of segment.legs">
                      <div class="card mb-2">
                        <div class="d-flex align-items-center justify-content-between card-header">
                            <h4 class="m-0 text-bold" >
                              <span>{{leg.origin.code}}</span>
                                <img src="../../../assets/v3images/icons/purple-take-off-plan.svg" alt="" width="18px" height="18px" class="mr-2 ml-2">
                              <span>{{leg.destination.code}}</span></h4>
                                <p class="m-0 text-leaf-green text-bold">₹ {{leg.legSubtotal | number : '0.0-0'}}</p>
                        </div>
                        <div class="card-body p-0">
                            <div class="custom-table">
                                <table class="table">
                                  <!-- <thead>
                                    <tr>
                                      <th class="text-left">Particulars</th>
                                      <th class="text-right">Amount</th>
                                    </tr>
                                  </thead> -->
                                  <tbody style="word-break :break-all">
                                    <tr *ngIf="leg.seat">
                                      <td class="">Seat : {{leg.seat.name ? leg.seat.name : leg.seat.number}}</td>
                                      <td class="text-right">&#8377; {{leg.seat.price}}</td>
                                    </tr>
                                    <tr *ngIf="leg.meal">
                                      <td class="">Meal : {{leg.meal.name ? leg.meal.name : leg.meal.code}}</td>
                                      <td class="text-right">&#8377; {{leg.meal.price}}</td>
                                    </tr>
                                    <tr *ngIf="leg.baggage">
                                      <td class="">Baggage : {{leg.baggage.name ? leg.baggage.name : leg.baggage.code}}</td>
                                      <td class="text-right">&#8377; {{leg.baggage.price}}</td>
                                    </tr>
                                    <tr *ngIf="leg.additionalBaggage">
                                      <td class="">Addnl. Baggage PC x {{leg.additionalBaggage.count}}: </td>
                                      <td class="text-right">&#8377; {{leg.additionalBaggage.price}}</td>
                                    </tr>
                                   
                                    <ng-container *ngIf="leg.ancillary && leg.ancillary.selectedItems">
                                      <tr *ngFor="let item of leg.ancillary.selectedItems">
                                        <td class="">Ancillary : {{item.name ? item.name : item.code}}</td>
                                        <td class="text-right">&#8377; {{item.totalFare}}</td>
                                      </tr>
                                    </ng-container>
                                   
    
                                    <tr *ngIf="leg.wheelchair">
                                      <td class="">Wheelchair : {{leg.wheelchair.name ? leg.wheelchair.name : leg.wheelchair.code}}</td>
                                      <td class="text-right">&#8377; {{leg.wheelchair.price}}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                        </div>
                    </div>
                    </ng-container>
                  </ng-container>
              </mat-expansion-panel>
          </div>
          </ng-container>
        </ng-container>
      </mat-accordion>
  </div>
  <div class="card active p-0 mt-2 ">
      <div class="row m-0 grand-total-card pr-1 pl-2">       
          <div class=" text-center pl-0 mr-2 ">
            <small class="text-white">Total</small>
              <h4 class="text-bold text-uppercase mt-0">Add-Ons</h4>
              <!-- <small class="d-block text-black">Include <span class="text-dark-pink " >3 add-ons</span></small> -->
          </div>
          <div class=" price-col ">
              <h3 class="offered-price d-flex align-items-center text-bold" style="color:#B900FA!important">&#8377; {{selectedSsrInRedux && selectedSsrInRedux.totalAmount ? selectedSsrInRedux.totalAmount : 0 | number : '0.0-0' }}</h3>
          </div>
      </div>
  </div>
</div>