export enum ActionTypes {
    setOutBoundFlight = '[setOutBoundFlight] SET OUTBOUND FLIGHT DATA',
    setInBoundFlight = '[setInBoundFlight] SET INBOUND FLIGHT DATA',
    setSpecialReturnFlight = '[setSpecialReturnFlight] SET SPL ROUND TRIP FLIGHT DATA',
 
  }
  
  export const setOutBoundFlight = (payload,setFrom) => ({
    type : ActionTypes.setOutBoundFlight,
    payload,
    setFrom
  })
  export const setInBoundFlight = (payload,setFrom) => ({
    type : ActionTypes.setInBoundFlight,
    payload,
    setFrom
  })
  export const setSpecialReturnFlight = (payload,setFrom) => ({
    type : ActionTypes.setSpecialReturnFlight,
    payload,
    setFrom
  })

  
  