import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class LedgerService {

  constructor(private commonService : CommonService) { }

  viewLedger(data){
    return this.commonService.getAccountValue(`ledger/getAffiliateLedger?affiliate=${data}`);
  }

  viewFilteredLedger(data){
    let url = `ledger/filterMIS?affiliate=${data.affiliate_id}&from_date=${data.fromDate}&to_date=${data.toDate}&type=${data.type}`
    if (data.affiliate_code != undefined) {
      url = `ledger/filterMIS?affiliate=${data.affiliate_id}&from_date=${data.fromDate}&to_date=${data.toDate}&type=${data.type}&affiliate_code=${data.affiliate_code}`
    }
    return this.commonService.getAccountValue(url);
  }
  viewStatement(data){
    return this.commonService.getAccountV2Value(`statement/getStatement?xlCode=${data.xlCode}`);
  }
}
