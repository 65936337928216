<div class="fare-right ">
  <div class="departure">
    <owl-carousel-o [options]="travellerFareSlider">  
      <ng-container *ngFor="let flight of flightData">
        <ng-container *ngFor="let segment of flight['segments']; let i = index">  
      <ng-template carouselSlide >
          <div class="p-2">
             <div class="card active transition">
               
          <div
            class="             
              card-header
            "
            *ngIf="!isSpecialRoundTrip"
          >
          <div
          class="d-flex align-items-center justify-content-between"
        >
        <p class="m-0 "><span class="text-pinkish-red text-bold">{{ direction }}</span> <br> ({{
          segment["legs"][0]["departureDateTime"] | dateGmtFormat
        }})</p> 
          
          <small class="text-right mb-0">
            <span class="airline-num"
              >{{ flight?.airline?.code }}-{{
                segment["legs"][0]["flightNumber"]
              }}</span
            >
            <br>
            <span title="{{flight.fares[0] &&
              flight.fares[0].displayPriceType?flight.fares[0].displayPriceType:flight.fares[0].priceType}}" class="fare-type-style d-flex shadow mt-1"
              >
              <ng-container *ngIf="flight.fares[0].supplierInfo && flight.fares[0].supplierInfo.subPriceType && (flight.fares[0].supplierInfo.subPriceType =='STUD' || flight.fares[0].supplierInfo.subPriceType =='SRCT') ;else regularFareTag">
                <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='STUD'" src="assets/v3images/icons/white-student-cap.svg" alt="STUD">
                <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='SRCT'" src="assets/v3images/icons/white-senior-citizen.svg" alt="SRCT">
              </ng-container>
              <ng-template #regularFareTag>
                <i class="fa fa-tags bg-success text-white mr-1"></i>
              </ng-template>
              
              {{
                flight.fares[0] &&
                flight.fares[0].displayPriceType?flight.fares[0].displayPriceType:flight.fares[0].priceType}}</span

            >
              </small>
        </div>
            <!-- <p class="m-0 text-pinkish-red text-bold">{{ direction }}</p>
            <p class="m-0 ">
              ({{ segment["legs"][0]["departureDateTime"] | dateGmtFormat }}) |
              <span class="airline-num"
                >{{ flight?.airline?.code }}-{{
                  segment["legs"][0]["flightNumber"]
                }}</span
              >
            </p> -->
          </div>

          <div
            class="
              card-header
            "
            *ngIf="isSpecialRoundTrip && i == 0"
          >
            <!-- <p class="m-0 text-pinkish-red text-bold">Departure</p>
            <p class="m-0 ">
              ({{ segment["legs"][0]["departureDateTime"] | dateGmtFormat }}) |
              <span class="airline-num"
                >{{ airlineCode }}-{{
                  segment["legs"][0]["flightNumber"]
                }}</span
              >
            </p> -->
            <div             class="d-flex align-items-center justify-content-between"          >
              <p class="m-0 "><span class="text-pinkish-red text-bold">Departure</span> <br> ({{
                segment["legs"][0]["departureDateTime"] | dateGmtFormat
              }})</p> 
            
            <small class="text-right mb-0">
              <span class="airline-num"
                >{{ airlineCode }}-{{
                  segment["legs"][0]["flightNumber"]
                }}</span
              >
              <br>
              <span class="fare-type-style d-flex shadow mt-1"
              >
              <ng-container *ngIf="flight.fares[0].supplierInfo && flight.fares[0].supplierInfo.subPriceType && (flight.fares[0].supplierInfo.subPriceType =='STUD' || flight.fares[0].supplierInfo.subPriceType =='SRCT') ;else regularFareTag">
                <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='STUD'" src="assets/v3images/icons/white-student-cap.svg" alt="STUD">
                <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='SRCT'" src="assets/v3images/icons/white-senior-citizen.svg" alt="SRCT">
              </ng-container>
              <ng-template #regularFareTag>
                <i class="fa fa-tags bg-success text-white mr-1"></i>
              </ng-template>
              {{flight.fares[0] &&
                flight.fares[0].displayPriceType?flight.fares[0].displayPriceType:flight.fares[0].priceType}}</span

              >
                </small>
          </div>
          </div>
          <div
            class="
              card-header
            "
            *ngIf="isSpecialRoundTrip && i != 0"
          >
            <!-- <p class="m-0 text-pinkish-red text-bold">Arrival</p>
            <p class="m-0 ">
              ({{ segment["legs"][0]["departureDateTime"] | dateGmtFormat }}) |
              <span class="airline-num"
                >{{ airlineCode }}-{{
                  segment["legs"][0]["flightNumber"]
                }}</span
              >
            </p> -->

            <div             class="d-flex align-items-center justify-content-between"          >
              <p class="m-0 "><span class="text-pinkish-red text-bold">Arrival</span> <br> ({{
                segment["legs"][0]["departureDateTime"] | dateGmtFormat
              }})</p> 
            
            <small class="text-right mb-0">
              <span class="airline-num"
                >{{ airlineCode }}-{{
                  segment["legs"][0]["flightNumber"]
                }}</span
              >
              <br>
              <ng-container *ngIf="segment?.supplierInfo?.priceType;else fareType">
                <span title="{{segment?.supplierInfo?.priceType}}" class="fare-type-style d-flex shadow mt-1">
                    
                    <ng-container *ngIf="flight.fares[0].supplierInfo && flight.fares[0].supplierInfo.subPriceType && (flight.fares[0].supplierInfo.subPriceType =='STUD' || flight.fares[0].supplierInfo.subPriceType =='SRCT') ;else regularFareTag">
                        <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='STUD'" src="assets/v3images/icons/white-student-cap.svg" alt="STUD">
                        <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='SRCT'" src="assets/v3images/icons/white-senior-citizen.svg" alt="SRCT">
                      </ng-container>
                      <ng-template #regularFareTag>
                        <i class="fa fa-tags bg-success text-white mr-1"></i>
                      </ng-template>
                    {{
                        segment?.supplierInfo?.priceType
                    }}
                </span>
  
            </ng-container>
            <ng-template #fareType>
                <span title="{{flight.fares[0] &&
                    flight.fares[0].displayPriceType?flight.fares[0].displayPriceType:flight.fares[0].priceType}}" class="fare-type-style d-flex shadow mt-1">
                    
                    <ng-container *ngIf="flight.fares[0].supplierInfo && flight.fares[0].supplierInfo.subPriceType && (flight.fares[0].supplierInfo.subPriceType =='STUD' || flight.fares[0].supplierInfo.subPriceType =='SRCT') ;else regularFareTag">
                        <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='STUD'" src="assets/v3images/icons/white-student-cap.svg" alt="STUD">
                        <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='SRCT'" src="assets/v3images/icons/white-senior-citizen.svg" alt="SRCT">
                      </ng-container>
                      <ng-template #regularFareTag>
                        <i class="fa fa-tags bg-success text-white mr-1"></i>
                      </ng-template>
                    {{
                        flight.fares[0] &&
                        flight.fares[0].displayPriceType?flight.fares[0].displayPriceType:flight.fares[0].priceType
                    }}
                </span>
            </ng-template>
                </small>
          </div>
          </div>

          <div class="card-body p-2">
            <div class="row m-0">
              <div class="col-md-4 p-0">
                <div class="departure flight-info">
                  <h4 class="text-bold">
                    <img
                      src="../../../../../assets/v3images/icons/purple-take-off-plan.svg"
                      alt=""
                      width="25px"
                      height="25px"
                      class="mr-1"
                    />
                    {{ segment["legs"][0]["originAirport"]?.code }}
                  </h4>
                  <div class="flight-time-date mt-2">
                    <p class="flight-date text-bold mb-0">
                      <img
                        src="../../../../../assets/v3images/icons/purple-clock.svg"
                        alt=""
                        width="15px"
                        height="15px"
                      />
                      {{ segment["legs"][0]["departureDateTime"] | timeFormat }}
                    </p>
                    <span class="flight-time"
                      ><small class="text-bold">{{
                        segment.legs[0]["departureDateTime"] | dateGmtFormat
                      }}</small></span
                    >
                  </div>
                </div>
                <!-- <div class="dep-details ">
                <span class="dep">{{segment['legs'][0]['originAirport']?.code}}</span>
                <span class="dep-time">{{segment['legs'][0]['departureDateTime'] | timeFormat}}</span>
               </div> -->
              </div>
              <div class="col-md-4">
                <div class="travelling-time">
                  <div
                    class="box segment-info type text-center lay-tooltip"
                  >
                    <small class="mb-1">Duration</small>
                    <div class="separator-line">
                      <!-- <span
                        class="flight-icon one-way-flight mt-4"
                        *ngIf="!isRoundTrip && !isSpecialRoundTrip"
                        ><img src="../../../assets/v3images/icons/right-flight.png"
                      /></span>
                      <span
                        class="flight-icon mt-4 "
                        *ngIf="isRoundTrip && !isSpecialRoundTrip"
                        ><img src="../../../assets/v3images/icons/right-flight.png"
                      /></span>
                      <span
                        class="flight-icon mt-4 "
                        *ngIf="isSpecialRoundTrip && i != 0"
                        ><img src="../../../assets/v3images/icons/right-flight.png"
                      /></span>
                      <span
                        class="flight-icon mt-4"
                        *ngIf="isSpecialRoundTrip && i == 0"
                        ><img src="../../../assets/v3images/icons/right-flight.png"
                      /></span> -->
                    </div> 
                    <small class="text-bold text-cent">{{
                      getDuration(
                        segment["legs"][0]["departureDateTime"],
                        segment["legs"][segment["legs"]["length"] - 1][
                          "arrivalDateTime"
                        ],
                        segment,
                        flight.supplierCode
                      )
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="col-md-4 p-0">
                <div class="destination text-right flight-info">
                  <h4 class="text-bold justify-content-end">
                    <img
                      src="../../../../../assets/v3images/icons/purple-landing-plan.svg"
                      alt=""
                      width="25px"
                      height="25px"
                      class="mr-1"
                    />
                    {{
                      segment["legs"][segment["legs"]["length"] - 1][
                        "destinationAirport"
                      ]?.code
                    }}
                  </h4>
                  <div class="flight-time-date mt-2">
                    <p class="flight-date text-bold mb-0 justify-content-end">
                      <img
                        src="../../../../../assets/v3images/icons/purple-clock.svg"
                        alt=""
                        width="15px"
                        height="15px"
                        class="mr-2"
                      />{{
                        segment["legs"][segment["legs"]["length"] - 1][
                          "arrivalDateTime"
                        ] | timeFormat
                      }}
                    </p>
                    <span class="flight-time"
                      ><small class="text-bold">{{
                        segment["legs"][segment["legs"]["length"] - 1][
                          "arrivalDateTime"
                        ] | dateGmtFormat
                      }}</small></span
                    >
                  </div>
                </div>
              </div>

              <!-- <div class="d-inline-block text-center">
                <span class="hr-line"></span>
                <span class="flight-icon" ><img src="../../../assets/images/airplane-shape.png" /></span>
                <span class="flight-icon reverse" ><img src="../../../assets/images/airplane-shape.png" /></span>
                <span class="flight-icon reverse"><img src="../../../assets/images/airplane-shape.png" /></span>
                <span class="flight-icon"><img src="../../../assets/images/airplane-shape.png" /></span>

                <span class="hr-line"></span>
                <span class="d-block flight-time">Flight Time: {{getDuration(segment['legs'][0]['departureDateTime'], segment['legs'][segment['legs']['length']-1]['arrivalDateTime'],segment,flight.supplierCode)}}</span>
            </div> -->

              <!-- <div class="arr-details float-right text-right">
                <span class="arr">{{segment['legs'][segment['legs']['length']-1]['destinationAirport']?.code}}</span>
                <span class="arr-time">{{segment['legs'][segment['legs']['length']-1]['arrivalDateTime'] | timeFormat}}</span>
            </div> -->
            </div>
          </div>
        </div>
               </div>
      </ng-template>
      </ng-container>     
      </ng-container> 
  </owl-carousel-o> 
     <!-- <ng-container *ngFor="let flight of flightData">
      <ng-container *ngFor="let segment of flight['segments']; let i = index">          
        <div class="card active transition">
          <div
            class="
              d-flex
              align-items-center
              justify-content-between
              card-header
            "
            *ngIf="!isSpecialRoundTrip"
          >
            <p class="m-0 text-pinkish-red text-bold">{{ direction }}</p>
            <p class="m-0 text-pinkish-red text-bold">
              ({{ segment["legs"][0]["departureDateTime"] | dateGmtFormat }}) |
              <span class="airline-num"
                >{{ flight?.airline?.code }}-{{
                  segment["legs"][0]["flightNumber"]
                }}</span
              >
            </p>
          </div>

          <div
            class="
              d-flex
              align-items-center
              justify-content-between
              card-header
            "
            *ngIf="isSpecialRoundTrip && i == 0"
          >
            <p class="m-0 text-pinkish-red text-bold">Departure</p>
            <p class="m-0 text-pinkish-red text-bold">
              ({{ segment["legs"][0]["departureDateTime"] | dateGmtFormat }}) |
              <span class="airline-num"
                >{{ airlineCode }}-{{
                  segment["legs"][0]["flightNumber"]
                }}</span
              >
            </p>
          </div>
          <div
            class="
              d-flex
              align-items-center
              justify-content-between
              card-header
            "
            *ngIf="isSpecialRoundTrip && i != 0"
          >
            <p class="m-0 text-pinkish-red text-bold">Arrival</p>
            <p class="m-0 text-pinkish-red text-bold">
              ({{ segment["legs"][0]["departureDateTime"] | dateGmtFormat }}) |
              <span class="airline-num"
                >{{ airlineCode }}-{{
                  segment["legs"][0]["flightNumber"]
                }}</span
              >
            </p>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="departure flight-info">
                  <h4 class="text-bold">
                    <img
                      src="../../../../../assets/v3images/icons/purple-take-off-plan.svg"
                      alt=""
                      width="25px"
                      height="25px"
                      class="mr-2"
                    />
                    {{ segment["legs"][0]["originAirport"]?.code }}
                  </h4>
                  <div class="flight-time-date mt-2">
                    <p class="flight-date text-bold mb-0">
                      <img
                        src="../../../../../assets/v3images/icons/purple-clock.svg"
                        alt=""
                        width="15px"
                        height="15px"
                      />
                      {{ segment["legs"][0]["departureDateTime"] | timeFormat }}
                    </p>
                    <span class="flight-time"
                      ><small class="text-bold">{{
                        segment.legs[0]["departureDateTime"] | dateGmtFormat
                      }}</small></span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="travelling-time">
                  <div
                    class="box segment-info type text-center lay-tooltip"
                    (mouseenter)="showLayoverTooltip(segment.legs)"
                  >
                    <small class="mb-1">Duration</small>
                    <div class="separator-line">
                      <span
                        class="flight-icon mt-5"
                        *ngIf="!isRoundTrip && !isSpecialRoundTrip"
                        ><img src="../../../assets/images/regularfare.png"
                      /></span>
                      <span
                        class="flight-icon mt-5 reverse"
                        *ngIf="isRoundTrip && !isSpecialRoundTrip"
                        ><img src="../../../assets/images/regularfare.png"
                      /></span>
                      <span
                        class="flight-icon mt-5 reverse"
                        *ngIf="isSpecialRoundTrip && i != 0"
                        ><img src="../../../assets/images/regularfare.png"
                      /></span>
                      <span
                        class="flight-icon mt-5"
                        *ngIf="isSpecialRoundTrip && i == 0"
                        ><img src="../../../assets/images/regularfare.png"
                      /></span>
                    </div>
                    <p class="text-bold">
                      <small>{{
                        getDuration(
                          segment["legs"][0]["departureDateTime"],
                          segment["legs"][segment["legs"]["length"] - 1][
                            "arrivalDateTime"
                          ],
                          segment,
                          flight.supplierCode
                        )
                      }}</small>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="destination text-right flight-info">
                  <h4 class="text-bold">
                    <img
                      src="../../../../../assets/v3images/icons/purple-landing-plan.svg"
                      alt=""
                      width="25px"
                      height="25px"
                      class="mr-2"
                    />
                    {{
                      segment["legs"][segment["legs"]["length"] - 1][
                        "destinationAirport"
                      ]?.code
                    }}
                  </h4>
                  <div class="flight-time-date mt-2">
                    <p class="flight-date text-bold mb-0">
                      <img
                        src="../../../../../assets/v3images/icons/purple-clock.svg"
                        alt=""
                        width="15px"
                        height="15px"
                        class="mr-2"
                      />{{
                        segment["legs"][segment["legs"]["length"] - 1][
                          "arrivalDateTime"
                        ] | timeFormat
                      }}
                    </p>
                    <span class="flight-time"
                      ><small class="text-bold">{{
                        segment["legs"][segment["legs"]["length"] - 1][
                          "arrivalDateTime"
                        ] | dateGmtFormat
                      }}</small></span
                    >
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </ng-container>
    </ng-container> -->
    <div class="fare-datails-container">
      
          <!-- Adult Fare Details  -->
          <div class="card">
            <div
              (click)="toggleAdultDetails()"
              class="card-header d-flex align-items-center justify-content-between"
            >
              <p class="text-bold p-0 m-0">Fare details for Adults</p>
              <i
                class="fa"
                [ngClass]="{
                  'fa-angle-up': toggleAdult,
                  'fa-angle-down': !toggleAdult
                }"
                aria-hidden="true"
              ></i>
            </div>
            <div *ngIf="toggleAdult && toggleFare" class="card-body pl-2 pr-3">
              <div class="custom-table">
                <table class="table">
                  <tbody>
                    <tr>
                      <td class="cursor-pointer">Adult (Base Fare) 
                      </td>
                      <td class="">&#8377; {{ adultBaseFare }} </td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">Tax </td>
                      <td >&#8377; {{ adultTaxFare }} </td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">OT Tax and S. Charges </td>
                      <td class="">&#8377; 0</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="text-bold text-pinkish-red">TOTAL</td>
                      <td class="text-bold text-pinkish-red">
                        &#8377; {{ adultBaseFare + adultTaxFare }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <!-- <div class="row ml-0 mr-0 mt-3">
            <span (click)="toggleAdultDetails()" class="fare-details-for text-center"
              >Fare details for Adults<span class="collapse-icon custom-icon">
                <i
                  class="fa"
                  [ngClass]="{
                    'fa-minus-square': toggleAdult,
                    'fa-plus-square': !toggleAdult
                  }"
                  aria-hidden="true"
                ></i></span
            ></span>
            <div *ngIf="toggleAdult && toggleFare" class="tg-wrap w-100">
              <table class="table table-sm table-bordered text-center">
                <tr>
                  <th class="tg-0pky">Particulars</th>
                  <th class="tg-0pky">Fare</th>
                </tr>
                <tr>
                  <td class="tg-0pky">Adult (Base Fare)</td>
                  <td class="tg-0pky">&#8377; {{ adultBaseFare }}</td>
                </tr>
                <tr>
                  <td class="tg-0lax">Tax</td>
                  <td class="tg-0lax">&#8377; {{ adultTaxFare }}</td>
                </tr>
                <tr>
                  <td class="tg-0lax">OT Tax and S. Charges</td>
                  <td class="tg-0lax">&#8377; 0</td>
                </tr>
                <tr>
                  <td class="tg-0lax hilight">TOTAL</td>
                  <td class="tg-0lax hilight">
                    &#8377; {{ adultBaseFare + adultTaxFare }}
                  </td>
                </tr>
              </table>
            </div>
          </div> -->
          <!-- Child Fare Detials  -->
          <div class="card" *ngIf="noOfChild > 0">
            <div
              (click)="toggleChildDetails()"
              class="card-header d-flex align-items-center justify-content-between"
            >
              <p class="text-bold p-0 m-0">Fare details for Child</p>
              <i
                class="fa"
                [ngClass]="{
                  'fa-angle-up': toggleChild,
                  'fa-angle-down': !toggleChild
                }"
                aria-hidden="true"
              ></i>
            </div>
            <div *ngIf="toggleChild && toggleFare" class="card-body pl-2 pr-3">
              <div class="custom-table">
                <table class="table">
                  <tbody>
                    <tr>
                      <td class="cursor-pointer">Child (Base Fare) </td>
                      <td>&#8377; {{ childBaseFare }}</td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">Tax</td>
                      <td>&#8377; {{ childTaxFare }}</td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">OT Tax and S. Charges</td>
                      <td>&#8377; 0</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="text-bold text-pinkish-red">TOTAL</td>
                      <td class="text-bold text-pinkish-red">
                        &#8377; {{ childBaseFare + childTaxFare }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
      
          <!-- <div class="row ml-0 mr-0 mt-3" *ngIf="noOfChild > 0">
            <span (click)="toggleChildDetails()" class="fare-details-for text-center"
              >Fare details for Child<span class="collapse-icon custom-icon">
                <i
                  class="fa"
                  [ngClass]="{
                    'fa-minus-square': toggleChild,
                    'fa-plus-square': !toggleChild
                  }"
                  aria-hidden="true"
                ></i></span
            ></span>
            <div *ngIf="toggleChild && toggleFare" class="tg-wrap w-100">
              <table class="table table-sm table-bordered text-center">
                <tr>
                  <th class="tg-0pky">Particulars</th>
                  <th class="tg-0pky">Fare</th>
                </tr>
                <tr>
                  <td class="tg-0pky">Child (Base Fare)</td>
                  <td class="tg-0pky">&#8377; {{ childBaseFare }}</td>
                </tr>
                <tr>
                  <td class="tg-0lax">Tax</td>
                  <td class="tg-0lax">&#8377; {{ childTaxFare }}</td>
                </tr>
                <tr>
                  <td class="tg-0lax">OT Tax and S. Charges</td>
                  <td class="tg-0lax">&#8377; 0</td>
                </tr>
                <tr>
                  <td class="tg-0lax hilight">TOTAL</td>
                  <td class="tg-0lax hilight">
                    &#8377; {{ childBaseFare + childTaxFare }}
                  </td>
                </tr>
              </table>
            </div>
          </div> -->
      
          <!-- Infant Fare Detsils  -->
          <div class="card" *ngIf="noOfInfant > 0">
            <div
              (click)="toggleInfantDetails()"
              class="card-header d-flex align-items-center justify-content-between"
            >
              <p class="text-bold p-0 m-0">Fare details for Infant</p>
              <i
                class="fa"
                [ngClass]="{
                  'fa-angle-up': toggleInfant,
                  'fa-angle-down': !toggleInfant
                }"
                aria-hidden="true"
              ></i>
            </div>
            <div *ngIf="toggleInfant && toggleFare" class="card-body pl-2 pr-3">
              <div class="custom-table">
                <table class="table">
                  <tbody>
                    <tr>
                      <td class="cursor-pointer">Infant (Base Fare)</td>
                      <td class="">&#8377; {{ infBaseFare }}</td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">Tax</td>
                      <td class="">&#8377; {{ infTaxFare }}</td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">OT Tax and S. Charges</td>
                      <td class="">&#8377; 0</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="text-bold text-pinkish-red">TOTAL</td>
                      <td class="text-bold text-pinkish-red">
                        &#8377; {{ infBaseFare + infTaxFare }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <!-- <div class="row ml-0 mr-0 mt-3" *ngIf="noOfInfant > 0">
            <span (click)="toggleInfantDetails()" class="fare-details-for text-center"
              >Fare details for Infant<span class="collapse-icon custom-icon">
                <i
                  class="fa"
                  [ngClass]="{
                    'fa-minus-square': toggleInfant,
                    'fa-plus-square': !toggleInfant
                  }"
                  aria-hidden="true"
                ></i></span
            ></span>
            <div *ngIf="toggleInfant && toggleFare" class="tg-wrap w-100">
              <table class="table table-sm table-bordered text-center">
                <tr>
                  <th class="tg-0pky">Particulars</th>
                  <th class="tg-0pky">Fare</th>
                </tr>
                <tr>
                  <td class="tg-0pky">Infant (Base Fare)</td>
                  <td class="tg-0pky">&#8377; {{ infBaseFare }}</td>
                </tr>
                <tr>
                  <td class="tg-0lax">Tax</td>
                  <td class="tg-0lax">&#8377; {{ infTaxFare }}</td>
                </tr>
                <tr>
                  <td class="tg-0lax">OT Tax and S. Charges</td>
                  <td class="tg-0lax">&#8377; 0</td>
                </tr>
                <tr>
                  <td class="tg-0lax hilight">TOTAL</td>
                  <td class="tg-0lax hilight">
                    &#8377; {{ infBaseFare + infTaxFare }}
                  </td>
                </tr>
              </table>
            </div>
          </div> -->
      
          <!-- total fare -->
          <div class="card">
            <div
              (click)="toggleTotalFareDetails()"
              class="card-header d-flex align-items-center justify-content-between"
            >
              <p class="text-bold p-0 m-0">Total Fare</p>
              <!-- <p class="text-bold p-0 m-0">
                <span *ngIf="toggleFare">Hide</span>
                <span *ngIf="!toggleFare">Show</span> fare details
              </p> -->
              <i
              (click)="toggleFare = !toggleFare"
                class="fa"
                [ngClass]="
                toggleTotalFare ? 'fa-eye' : 'fa-eye-slash'"
                aria-hidden="true"
              ></i>
              <!-- <i
                class="fa"
                [ngClass]="{
                  'fa-angle-up': toggleTotalFare,
                  'fa-angle-down': !toggleTotalFare
                }"
                aria-hidden="true"
              ></i> -->
            </div>
            <div *ngIf="toggleTotalFare && toggleFare" class="card-body pl-2 pr-3">
              <div class="custom-table">
                <table class="table">
                  <tbody>
                    <tr>
                      <td class="cursor-pointer">Adult x {{ noOfAdult }} </td>
                      <td class="">
                        &#8377; {{ (adultBaseFare + adultTaxFare) * noOfAdult }}
                      </td>
                    </tr>
      
                    <tr *ngIf="noOfChild > 0">
                      <td class="cursor-pointer">Child x {{ noOfChild }} </td>
                      <td class="">
                        &#8377; {{ (childBaseFare + childTaxFare) * noOfChild }}
                      </td>
                    </tr>
      
                    <tr *ngIf="noOfInfant > 0">
                      <td class="cursor-pointer">Infant x {{ noOfInfant }} </td>
                      <td class="">
                        &#8377; {{ (infBaseFare + infTaxFare) * noOfInfant }}
                      </td>
                    </tr>
      
                    <tr>
                      <td class="cursor-pointer">Baggage Charges </td>
                      <td class="">&#8377; {{ baggageCharges }}</td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">Addnl. Baggage Charges </td>
                      <td class="">&#8377; {{ additionalBaggageCharges }}</td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">Anicillary Charges </td>
                      <td class="">&#8377; {{ anicillaryCharges }}</td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">Meal Charges </td>
                      <td class="">&#8377; {{ mealCharges }}</td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">Seat Charges </td>
                      <td class="">&#8377; {{ seatCharges }}</td>
                    </tr>
                    <tr *ngIf="insuranceAmountPerPerson && insuranceAmountPerPerson > 0">
                      <td class="cursor-pointer">Insurance Premium<small>({{noOfAdult + noOfChild + noOfInfant}} X &#8377;{{insuranceAmountPerPerson}})</small> </td>
                      <td class="">&#8377; {{totalInsuranceAmount}} </td>
                    </tr>
                    <tr>
                      <td class="">Total GST</td>
                      <td class="">&#8377; 0</td>
                    </tr>
                  </tbody>
                  <tfoot class="double-top-btm-line">
                    <tr>
                      <td class="text-bold text-pinkish-red text-uppercase">
                        Total Pub. Fare
                      </td>
                      <td class="text-bold text-pinkish-red">
                        &#8377; {{ netTotalayable+ (totalInsuranceAmount ? totalInsuranceAmount : 0) }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <!-- <div class="row ml-0 mr-0 mt-3">
            <span
              (click)="toggleTotalFareDetails()"
              class="fare-details-for text-center"
              >Total Fare<span class="collapse-icon custom-icon">
                <i
                  class="fa"
                  [ngClass]="{
                    'fa-minus-square': toggleTotalFare,
                    'fa-plus-square': !toggleTotalFare
                  }"
                  aria-hidden="true"
                ></i></span
            ></span>
            <div *ngIf="toggleTotalFare && toggleFare" class="tg-wrap w-100">
              <table class="table table-sm table-bordered text-center">
                <tr>
                  <td class="tg-0pky">Adult x {{ noOfAdult }}</td>
                  <td class="tg-0pky">
                    &#8377; {{ (adultBaseFare + adultTaxFare) * noOfAdult }}
                  </td>
                </tr>
      
                <tr *ngIf="noOfChild > 0">
                  <td class="tg-0pky">Child x {{ noOfChild }}</td>
                  <td class="tg-0pky">
                    &#8377; {{ (childBaseFare + childTaxFare) * noOfChild }}
                  </td>
                </tr>
      
                <tr *ngIf="noOfInfant > 0">
                  <td class="tg-0pky">Infant x {{ noOfInfant }}</td>
                  <td class="tg-0pky">
                    &#8377; {{ (infBaseFare + infTaxFare) * noOfInfant }}
                  </td>
                </tr>
      
                <tr>
                  <td class="tg-0pky">Baggage Charges</td>
                  <td class="tg-0pky">&#8377; {{ baggageCharges }}</td>
                </tr>
                <tr>
                  <td class="tg-0pky">Meal Charges</td>
                  <td class="tg-0pky">&#8377; {{ mealCharges }}</td>
                </tr>
                <tr>
                  <td class="tg-0pky">Seat Charges</td>
                  <td class="tg-0pky">&#8377; {{ seatCharges }}</td>
                </tr>
                <tr>
                  <td class="tg-0pky">Total GST</td>
                  <td class="tg-0pky">&#8377; 0</td>
                </tr>
      
                <tr>
                  <td class="tg-0pky grand-hilight">Total Pub. Fare</td>
                  <td class="tg-0pky grand-hilight">&#8377; {{ netTotalayable }}</td>
                </tr>
              </table>
            </div>
          </div> -->
      
          <!--if Offer -->
          <div class="card" *ngIf="isOffer">
            <div
              class="card-header d-flex align-items-center justify-content-between"
            >
              <p class="text-bold p-0 m-0">Total Price Details</p>
              <i class="fa fa-angle-up" aria-hidden="true"></i>
            </div>
            <div *ngIf="toggleFare" class="card-body">
              <div class="custom-table">
                <table class="table">
                  <tbody>
                    <tr>
                      <td class="cursor-pointer">
                        <span class="float-left">Comm. Earned</span
                        ><span class="float-right font-weight-bold">(-)</span>
                      </td>
                      <td>
                        {{ netCommission | currency: "INR":"symbol-narrow" }}
                      </td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">
                        <span class="float-left">Service Fee</span
                        ><span class="float-right font-weight-bold">(-)</span>
                      </td>
                      <td>
                        {{
                          totalServiceCharge
                            ? totalServiceCharge
                            : (0 | currency: "INR":"symbol-narrow")
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">
                        <span class="float-left">TDS</span
                        ><span class="float-right font-weight-bold">(+)</span>
                      </td>
                      <td>{{ tds | currency: "INR":"symbol-narrow" }}</td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">
                        <span class="float-left">GST</span
                        ><span class="float-right font-weight-bold">(+)</span>
                      </td>
                      <td>
                        {{
                          totalGst ? totalGst : (0 | currency: "INR":"symbol-narrow")
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">
                        <span class="float-left">My Markup</span
                        ><span class="float-right font-weight-bold">(-)</span>
                      </td>
                      <td>
                        {{ affiliateMarkup | currency: "INR":"symbol-narrow" }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="text-bold text-pinkish-red text-uppercase">
                        Net Payable
                      </td>
                      <td class="text-bold text-pinkish-red">
                        &#8377; {{ netPayable }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <!-- <div class="row ml-0 mr-0 mt-3" *ngIf="isOffer">
            <span class="fare-details-for text-center"
              >Total Price Details<span class="collapse-icon custom-icon"
                ><i class="fa fa-minus-square" aria-hidden="true"></i></span
            ></span>
            <div *ngIf="toggleFare" class="tg-wrap w-100">
              <table class="table table-sm table-bordered text-center">
                <tr>
                  <td class="tg-0pky">
                    <span class="float-left">Comm. Earned</span
                    ><span class="float-right font-weight-bold">(-)</span>
                  </td>
                  <td class="tg-0pky">
                    {{ netCommission | currency: "INR":"symbol-narrow" }}
                  </td>
                </tr>
                <tr>
                  <td class="tg-0pky">
                    <span class="float-left">Service Fee</span
                    ><span class="float-right font-weight-bold">(-)</span>
                  </td>
                  <td class="tg-0pky">
                    {{
                      totalServiceCharge
                        ? totalServiceCharge
                        : (0 | currency: "INR":"symbol-narrow")
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="tg-0pky">
                    <span class="float-left">TDS</span
                    ><span class="float-right font-weight-bold">(+)</span>
                  </td>
                  <td class="tg-0pky">{{ tds | currency: "INR":"symbol-narrow" }}</td>
                </tr>
                <tr>
                  <td class="tg-0pky">
                    <span class="float-left">GST</span
                    ><span class="float-right font-weight-bold">(+)</span>
                  </td>
                  <td class="tg-0pky">
                    {{ totalGst ? totalGst : (0 | currency: "INR":"symbol-narrow") }}
                  </td>
                </tr>
                <tr>
                  <td class="tg-0pky">
                    <span class="float-left">My Markup</span
                    ><span class="float-right font-weight-bold">(-)</span>
                  </td>
                  <td class="tg-0pky">
                    {{ affiliateMarkup | currency: "INR":"symbol-narrow" }}
                  </td>
                </tr>
                <tr>
                  <td class="tg-0pky grand-hilight">Net Payable</td>
                  <td class="tg-0pky grand-hilight">&#8377; {{ netPayable }}</td>
                </tr>
              </table>
            </div>
          </div> -->
      
          <!--if Reissue -->
          <div class="card" *ngIf="isReissue">
            <div
              (click)="toggleReIssueDetails()"
              class="card-header d-flex align-items-center justify-content-between"
            >
              <p class="text-bold p-0 m-0">Re - Issuance</p>
              <i
                class="fa"
                [ngClass]="{
                  'fa-angle-up': toggleReIssue,
                  'fa-angle-down': !toggleReIssue
                }"
                aria-hidden="true"
              ></i>
            </div>
            <div *ngIf="toggleReIssue" class="card-body pl-2 pr-3">
              <div class="custom-table">
                <table class="table mb-0">
                  <tbody>
                    <tr>
                      <td class="">Old Booking Amount</td>
                      <td class="">
                        <span  *ngIf="reIssueData.oldBookingAmount">&#8377; {{ reIssueData.oldBookingAmount | number : '0.0-0' }}</span>
                        <div class="shimmer" *ngIf="!reIssueData.oldBookingAmount">
                          <div class="wrapper">
                            <div class="stroke animate title m-0"></div>
                            </div>
                          </div>
                      </td>
                    </tr>
      
                    <tr>
                      <td class="">Current Booking Amount</td>
                      <td class="">
                        <span  *ngIf="reIssueData.currentNetPayable">&#8377; {{ reIssueData.currentNetPayable | number : '0.0-0'}}</span>
                        <!-- <div class="shimmer" *ngIf="!reIssueData.oldBookingAmount">
                          <div class="wrapper">
                            <div class="stroke animate title m-0"></div>
                            </div>
                          </div> -->

                      </td>
                    </tr>
                    <tr>
                      <td class="tg-0pky">Fare Difference</td>
                      <td class="tg-0pky">
                        &#8377; {{ reIssueData?.fareDifference }}
                      </td>
                    </tr>
      
                    <tr>
                      <td class="">Reissuance Charges</td>
                      <td class="">
                        <span>&#8377; {{ reIssueData.cancellationCharges?reIssueData.cancellationCharges:0 }}</span>
                        <div class="shimmer" *ngIf="!reIssueData.hasOwnProperty('cancellationCharges')">
                          <div class="wrapper">
                            <div class="stroke animate title m-0"></div>
                            </div>
                          </div>
                      </td>
                    </tr>
                    <tr *ngIf="reIssueData && reIssueData.hasOwnProperty('reIssuanceChargeAdmin')">
                      <td class="">Service Charges</td>
                      <td class="">
                        <span>&#8377; {{ reIssueData.reIssuanceChargeAdmin?reIssueData.reIssuanceChargeAdmin:0 }}</span>
                        <div class="shimmer" *ngIf="!reIssueData.hasOwnProperty('reIssuanceChargeAdmin')">
                          <div class="wrapper">
                            <div class="stroke animate title m-0"></div>
                            </div>
                          </div>
                      </td>
                    </tr>
                    <tr *ngIf="reIssueData && reIssueData.hasOwnProperty('reIssuanceChargeAdminGST')">
                      <td class="">GST on Service Charges</td>
                      <td class="">
                        <span>&#8377; {{ reIssueData.reIssuanceChargeAdminGST?reIssueData.reIssuanceChargeAdminGST:0 }}</span>
                        <div class="shimmer" *ngIf="!reIssueData.hasOwnProperty('reIssuanceChargeAdminGST')">
                          <div class="wrapper">
                            <div class="stroke animate title m-0"></div>
                            </div>
                          </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="text-bold text-pinkish-red text-uppercase">
                        Net Payable
                      </td>
                      <td class="text-bold text-pinkish-red">
                        <!-- {{
                          reIssueData.currentNetPayable +
                            reIssueData.cancellationCharges -
                            reIssueData.oldBookingAmount
                        }} -->
                        <span *ngIf="reIssueData.hasOwnProperty('currentNetPayable') && reIssueData.hasOwnProperty('cancellationCharges') &&  reIssueData.hasOwnProperty('oldBookingAmount')">&#8377; {{
                          reIssueData.currentNetPayable +
                          (reIssueData.reIssuanceChargeAdmin?reIssueData.reIssuanceChargeAdmin:0) +
                          (reIssueData.reIssuanceChargeAdminGST?reIssueData.reIssuanceChargeAdminGST:0) +
                            reIssueData.cancellationCharges -
                            reIssueData.oldBookingAmount
                            | number : '0.0-0'
                        }}</span>
                        <div class="shimmer" *ngIf="!(reIssueData.hasOwnProperty('currentNetPayable') && reIssueData.hasOwnProperty('cancellationCharges') &&  reIssueData.hasOwnProperty('oldBookingAmount'))">
                          <div class="wrapper">
                            <div class="stroke animate title m-0"></div>
                            </div>
                          </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
    </div>    
    <!-- <div class="row ml-0 mr-0 mt-3" *ngIf="isReissue">
      <span
        (click)="toggleReIssueDetails()"
        class="fare-details-for text-center"
        >Re - Issuance
        <span class="collapse-icon custom-icon">
          <i
            class="fa"
            [ngClass]="{
              'fa-minus-square': toggleReIssue,
              'fa-plus-square': !toggleReIssue
            }"
            aria-hidden="true"
          ></i></span
      ></span>
      <div *ngIf="toggleReIssue" class="tg-wrap w-100">
        <table class="table table-sm table-bordered text-center">
          <tr>
            <td class="tg-0pky">Old Booking Amount</td>
            <td class="tg-0pky">&#8377; {{ reIssueData.oldBookingAmount }}</td>
          </tr>

          <tr>
            <td class="tg-0pky">Current Booking Amount</td>
            <td class="tg-0pky">&#8377; {{ reIssueData.currentNetPayable }}</td>
          </tr>
          <tr>
            <td class="tg-0pky">Fare Difference</td>
            <td class="tg-0pky">
              &#8377; {{ reIssueData?.fareDifference }}
            </td>
          </tr>
          <tr>
            <td class="tg-0pky">Reissuance Charges</td>
            <td class="tg-0pky">
              &#8377; {{ reIssueData?.cancellationCharges }}
            </td>
          </tr>
          <tr>
            <td class="tg-0pky grand-hilight">Net Payable</td>
            <td class="tg-0pky grand-hilight" *ngIf="reIssueData.hasOwnProperty('refundAmount')">
              &#8377;
              {{
                reIssueData.currentNetPayable +
                reIssueData.cancellationCharges -
                reIssueData.oldBookingAmount
              }}
            </td>
          </tr>
        </table>
      </div>
    </div> -->
  </div>
</div>
