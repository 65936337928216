<div class="seat-container" [@.disabled]="true">
    <mat-tab-group>
        <div *ngFor="let segment of seatMapArr; let s = index">
            <mat-tab label="{{segment[0]?.departure}}-{{segment[segment.length-1]?.arrival}}">
                <mat-tab-group>
                    <ng-container *ngFor="let item of segment; let k = index;">
                        <mat-tab label="{{item.flightCode}}-{{item.flightNo}} ({{item.departure}} - {{item.arrival}})">
                            <div class="">
                                <div class="option2" style="width: auto;overflow-x:scroll;">
                                    <div class="seatmap2 row ml-0 mr-0" *ngFor="let key of item['keys']; let i = index">
                                        <div class="seatrow2" *ngFor="let key of item['values'][i]; let j = index">
                                            <div *ngIf="key.type == 'Reserved'" class="seat2 seat2-occupied">{{key['number']}}</div>
                                            <div *ngIf="key.type == 'ReservedForPNR'" class="seat2 seat2-occupied">{{key['number']}}</div>
                                            <div *ngIf="key.type == 'Restricted' || key.type == 'Unavailable'" class="seat2 seat2-unavailable">{{key['number']}}
                                            </div>
                                            <!-- CheckedIn -->
                                            <div *ngIf="key.type == 'CheckedIn'" class="seat2 seat2-checked">{{key['number']}}
                                            </div>
                                            <div *ngIf="key.type == 'FleetBlocked'" class="seat2 seat2-occupied">{{key['number']}}
                                            </div>
                                            <div *ngIf="key.type == 'selected'" (click)="removeSeat(item,item.travellers[getTravellerIndexBySeatNumber(key,item)]['seat'],k,s,getTravellerIndexBySeatNumber(key,item))" class="seat2 seat2-available" >{{key['number']}}
                                            </div>
                                            <!-- {{key?.displayAttribute?.EXITROW}} -->
                                            <div *ngIf="key.type == 'Open' && key?.displayAttribute?.EXITROW == 'True'" style="cursor: pointer;" (click)="selectSeat(key,item,k,s)" class="seat2 seat2-exit-seat" title=" &#8377; {{key.totalFare}}" [ngClass]="{'seat2-paid': key.totalFare > 0}">{{key['number']}}</div>
                                            <!-- displayAttribute  EXITROW -->
                                            <!-- Unavailable -->
                                            <!-- HeldForAnotherSession  Need to show some terms and condition-->
                                            <div *ngIf="(key.type == 'Open' || key.type == 'HeldForAnotherSession') && key?.displayAttribute?.EXITROW != 'True'" style="cursor: pointer;" (click)="selectSeat(key,item,k,s)" class="seat2" [ngClass]="{'seat2-paid': key.totalFare > 0}" title=" &#8377; {{key.totalFare}}">{{key['number']}}</div>
                                        </div>
                                        <ng-container *ngIf="(item.cabinInfo && item.cabinInfo[i] && item.cabinInfo[i+1] && item.cabinInfo[i]['aisleSeat'] && item.cabinInfo[i+1]['aisleSeat'])">
                                          <ul>
                                            <li *ngFor="let key of item['values'][i]; let j = index">
                                              <label class="ml-4 fa-12p"></label>
                                            </li>
                                          </ul>
                                        </ng-container>
                                        <ng-container *ngIf="!item.cabinInfo && (item.keys.length/2 == i+1)">
                                            <ul>
                                                <li *ngFor="let key of item['values'][i]; let j = index">
                                                    <label class="ml-4 fa-12p"></label>
                                                </li>
                                            </ul>
                                        </ng-container>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="seat2-options mb-2">
                                    <div class="legends d-flex align-items-center justify-content-between"><span>Legends</span>
                                        <div class="d-flex justify-content-center legend-map ">
                                            <span
                                              title="Free Seats"
                                              class="seat2-selected seat2-style cursor-unset ml-2 mr-2"
                                            ></span>
                                            <span
                                            title="Paid Seats"
                                            class="seat2-paid-inr seat2-style cursor-unset ml-2 mr-2"
                                            ></span>
                                            <span
                                              title="Selected Seats"
                                              class="seat2-available seat2-style cursor-unset ml-2 mr-2"
                                            ></span>
                                            <span
                                              title="Non Reclining Seats"
                                              class="seat2-norecline seat2-style cursor-unset ml-2 mr-2"
                                            ></span>
                                            <span
                                              title="Occupied Seats"
                                              class="seat2-occupied seat2-style cursor-unset ml-2 mr-2"
                                            ></span>
                                            <span
                                              title="Emergency Exit Seats"
                                              class="seat2-exit-seat seat2-style cursor-unset ml-2"
                                            ></span>
                                          </div>
                                    </div>
                                    
                                  </div>

                                  <!-- <ng-container *ngFor="let pass of item.travellers; let l = index">
                                    <div
                                      class="col-12 bg-light card pl-3 pr-3 pt-2 pb-2 clearfix m-1"
                                      [ngClass]="{
                                        'selected-passenger': pass && pass.isSelected == true
                                      }"
                                      (click)="selectPassenger(item.travellers, l)"
                                    >
                                      <ng-container *ngIf="pass.type != 'INF'">
                                        <div class="d-flex align-items-center justify-content-between">
                                          <div class="row m-0" style="width: 330px;">
                                            <div class="col-md-6">
                                                <p
                                              class="passName text-capitalize text-bold text-dark-green m-0"
                                              
                                            >
                                              {{ pass.title }} {{ pass.firstName }} {{ pass.lastName }}
                                            </p>
                                            </div>
                                            <div
                                              class="col-md-6 d-flex align-items-center justify-content-between"
                                              *ngIf="pass.seat != undefined"
                                            >
                                              <div
                                                class="d-flex align-items-center "
                                              >
                                                <div class="text-bold mr-2">{{ pass.seat }} &nbsp;</div>
                                                <div class="text-bold">
                                                  &#8377; {{ pass.totalFare }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            class="pl-2 d-inline-block float-right cursor-pointer"
                                            (click)="removeSeat(pass.seat, item, k, l)"
                                          >
                                            <img
                                              src="../../../../assets/v3images/icons/red-close.svg"
                                              alt=""
                                              width="25px"
                                              height="25px"
                                            />
                                          </div>
                                        </div>
                                      </ng-container>
                                    </div>
                                  </ng-container> -->
  
                                <ng-container *ngFor="let pass of item.travellers; let l = index">
                                    <ng-container *ngIf="pass.type != 'INF'">
                                      <div class="col-12 bg-light card pl-3 pr-3 pt-2 pb-2  clearfix m-1" *ngIf="pass?.isSelected">
                                        <ng-container *ngIf="pass.type != 'INF'">
                                            <div class="row m-0" >
                                                <div class="col-md-4">
                                            <span class="passName">
                                                <p style="text-transform: uppercase; font-size: 12px;">{{pass.title}} {{pass?.firstName}} {{pass?.lastName}}  {{pass?.first_name}} {{pass?.last_name}}</p>
                                            </span>
                                            </div>
                                            <div class="col-md-6">
                                            <div class="seatPos p-2" *ngIf="pass.seat !=undefined">
                                                <span class="mr-2 font-size: 12px;">{{pass.seat}} &nbsp;</span>
                                                <span class="font-size: 12px;">&#8377; {{pass.totalFare}}</span>
                                            </div>
                                            <small class="text-danger text-right text-danger ml-2" *ngIf="errorMessage[s] && errorMessage[s][k] && errorMessage[s][k][l]">
                                                Seat  <strong>{{errorMessage[s][k][l]}}</strong> is already assigned.
                                              </small>
                                            </div>
                                           
                                            </div>
                                           
                                        </ng-container>
                                    </div>   
                                    </ng-container>              


                                    <div class="col-12 bg-light card pl-3 pr-3 pt-2 pb-2  clearfix m-1" *ngIf="!pass?.isSelected && pass.type != 'INF'">
                                            <div class="row m-0" >
                                                <div class="col-md-4">
                                                    <span class="passName" >
                                                        <p style="text-transform: uppercase; font-size: 12px;">{{pass.title}} {{pass?.firstName}} {{pass?.lastName}}  {{pass?.first_name}} {{pass?.last_name}}</p>
                                                      <ng-container *ngIf="responseData != undefined">
                                                         <ng-container *ngIf="responseData?.ssrs != undefined">
                                                          <ng-container *ngIf="responseData?.ssrs[k] != undefined">
                                                              <ng-container *ngIf="responseData.ssrs[k]?.seats != undefined && responseData.ssrs[k]?.seats != null">
                                                                  <ng-container *ngFor="let responseSeat of responseData.ssrs[k].seats">
                                                                      <ng-container *ngIf="responseSeat.travellerNumber == l">
                                                                          <span *ngIf="responseSeat.status == 'SUCCESS'" class="ssr-status status-success">  {{responseSeat.status}}</span>
                                                                          <span *ngIf="responseSeat.status == 'FAILED'" class="ssr-status status-error">  {{responseSeat.status}}</span>
                                                                      </ng-container>
                                                                  </ng-container>
                                                              </ng-container>
                                                          </ng-container>
                                                         </ng-container>
                                                      </ng-container>
                                                      <ng-container *ngIf="responseData == undefined && showRequestLoader">
                                                          <span class="status-loader"> <i class="fas fa-circle-notch fa-spin"></i></span>
                                                      </ng-container>
                                                      </span>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="seatPos p-0" *ngIf="pass.seat !=undefined">
                                                        <span class="mr-2 font-size: 12px;">{{pass.seat}} &nbsp;</span>
                                                        <span class="font-size: 12px;">&#8377; {{pass.totalFare}}</span>
                                                    </div>
                                                    <small class="text-danger text-right text-danger ml-2"  *ngIf="errorMessage[s] && errorMessage[s][k] && errorMessage[s][k][l]">
                                                        Seat  <strong>{{errorMessage[s][k][l]}}</strong> is already assigned.
                                                      </small>
                                                </div>
                                                
                                            </div>
                                    </div>
                                </ng-container>
              
                            </div>
                        </mat-tab>
                    </ng-container>
                </mat-tab-group>
            </mat-tab>
        </div>
    </mat-tab-group>

</div>