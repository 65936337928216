import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

	constructor(private router: Router) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		try {
			if (localStorage.getItem('access_token')) {
				var current_time = new Date().getTime() / 1000;
				let jwt = this.parseJwt(localStorage.getItem('access_token'))
				if (current_time > jwt.exp) {
					if( window.location.hash.includes("/booking-summary-detail")) {
						localStorage.setItem('reload', window.location.hash)
					}
					localStorage.removeItem('access_token')
					localStorage.removeItem('user_details')
					this.router.navigate(['login',{redirectTo : window.location.href}]);
					return true;
				}
				else {
					return true;
				}
				// return true;

			}
			else {
				if(window.location.hash.includes("/booking-summary-detail")) {
					localStorage.setItem('reload', window.location.hash)
				}
				localStorage.removeItem('access_token')
				localStorage.removeItem('user_details')
				this.router.navigate(['login',{redirectTo : window.location.href}]);
				return false;
			}
		}
		catch (Exception) {
			localStorage.removeItem('access_token')
			localStorage.removeItem('user_details')
			this.router.navigate(['login']);
			return false;
		}

	}

	parseJwt(token) {
		var base64Url = token.split('.')[1];
		var base64 = base64Url.replace('-', '+').replace('_', '/');
		return JSON.parse(window.atob(base64));
	}
}
