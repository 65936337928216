<div class="baggage-container">
  <div class="baggage-box" [@.disabled]="true">
      <mat-tab-group>
          <div *ngFor="let segment of ssrAvailability; let s=index;">
              <mat-tab label="{{segment[0].departure}}-{{segment[segment.length-1].arrival}}">
                <div *ngFor="let item of segment; let i = index;">
                  <ng-container *ngIf="i==0">
                      <div class="row baggage-identifier">
                          <div class="col">
                              <span class="head">Travellers</span>
                          </div>
                          <div class="col">
                              <span class="head"> Ancillary</span>
                          </div>
                          <div class="col">
                              <span class="head">Your
                                  Selection</span>
                          </div>
                      </div>
                      <div class="custom-border  row ">
                        <ng-container >
                          <div class="col-3 " style="max-height: 200px;height: auto;overflow-y: scroll;">
                            <div class="" *ngFor="let passenger of item.travellers; let p = index;">
                                <ng-container  *ngIf="passenger.type != 'INF'">
                                    <div class="traveller-name" [ngClass]="{'active': p == selectedPassenger}" (click)="selectPassenger(p)">{{passenger.title}} {{passenger.first_name}} {{passenger.last_name}}</div>
                                </ng-container>
                            </div>
                          </div>
                          <div class="col-5 meal-select">
                            <ng-container
                                *ngIf="responseData != undefined && responseData?.ssrs != undefined && responseData?.ssrs[i] != undefined && responseData.ssrs[i]?.ancillaries != undefined && responseData.ssrs[i].ancillaries[k] != undefined && responseData.ssrs[i].ancillaries[k].status !=undefined && responseData.ssrs[i].ancillaries[k].status == 'SUCCESS';else ancillarySelection">
                                
                            </ng-container>
                            <ng-template #ancillarySelection>
                              <div class="d-flex flex-dir-column" style="max-height: 200px;height: auto;overflow-y: scroll; word-break :break-word">
                                <ng-container *ngIf="item.travellers[selectedPassenger] && item.travellers[selectedPassenger].ssr[s].ancillaries && item.travellers[selectedPassenger].ssr[s].ancillaries.code; else assignNewAncillaries">
                                  <ng-container *ngFor="let ancillary of item.travellers[selectedPassenger].ssr[s].ancillaries.code; let j = index" >
                                    <div class="d-flex" style="border-bottom: 1px solid;">
                                      <label style="font-size: 12px;" for="{{selectedPassenger}}_{{ancillary.name}}_{{ancillary.type}}">
                                        {{ancillary.type}} {{ancillary.name}} 
                                      </label>
                                    </div>
                                  </ng-container>
                                </ng-container>
                                <ng-template #assignNewAncillaries>
                                  <ng-container *ngFor="let ancillary of item.ancillaries; let j = index" >
                                    <div class="d-flex" style="border-bottom: 1px solid;">
                                      <input style="margin-right: 10px; width: auto;" [checked]="getChecked(s,i,ancillary)" (change)="changeAncillary($event,j, selectedPassenger,s)" type="checkbox" id="{{selectedPassenger}}_{{ancillary.name}}_{{ancillary.type}}"/> 
                                      <label style="font-size: 12px;" for="{{selectedPassenger}}_{{ancillary.name}}_{{ancillary.type}}">
                                        {{ancillary.type}} {{ancillary.name}} 
                                        <strong>
                                          &#8377;{{ancillary['totalFare']}}
                                        </strong>
                                      </label>
                                    </div>
                                  </ng-container>
                                </ng-template>
                            </div>
                            </ng-template>
                            
                        </div>
                        <div class="col-4 meal-select">
                          <div 
                              class="" 
                              style="max-height: 200px;height: auto;overflow-y: scroll; word-break :break-word"
                              *ngIf="item.travellers[selectedPassenger] && item.travellers[selectedPassenger].selectedAncillary !=undefined">
                              <ng-container   *ngFor="let item of item.travellers[selectedPassenger].selectedAncillary" >
                                  <label style="border-bottom: 1px solid; font-size: 12px;" >
                                      <ng-container *ngIf="item.name;else itemCode">
                                          {{item.name}} 
                                      </ng-container>
                                      <ng-template #itemCode>
                                          {{item.code}}
                                      </ng-template>
                                      <ng-container *ngIf="responseData == undefined && showRequestLoader">
                                        <span class="status-loader"> 
                                            <i class="fas fa-circle-notch fa-spin"></i>
                                        </span>
                                      </ng-container>
                                      <ng-container *ngIf="responseData != undefined">
                                        <ng-container *ngIf="responseData?.ssrs != undefined">
                                            <ng-container *ngIf="responseData?.ssrs[i] != undefined">
                                                <ng-container
                                                    *ngIf="responseData.ssrs[i]?.ancillaries != undefined && responseData.ssrs[i]?.ancillaries != null">
                                                    <ng-container
                                                        *ngFor="let responseAncillary of responseData.ssrs[i].ancillaries">
                                                        <ng-container
                                                            *ngIf="responseAncillary.travellerNumber == selectedPassenger">
                                                           <ng-container *ngFor="let bookedItem of responseAncillary.items" >
                                                            <span
                                                            *ngIf="bookedItem.code == item.code && bookedItem.status == 'SUCCESS'"
                                                            class="ssr-status  pl-2">
                                                            <i class="fa fa-check-circle text-success" title="SUCCESS"></i>
                                                            </span>
                                                            <span *ngIf="bookedItem.code == item.code && bookedItem.status == 'FAILED'"
                                                                class="ssr-status  pl-2 ">
                                                                <i class="fa fa-times-circle text-danger" title="{{bookedItem.message}}"></i>
                                                                
                                                            </span>
                                                           </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    </label>
                              </ng-container>
                          </div>
                         
                      </div>
                        </ng-container>
                      </div>
                  </ng-container>
              </div>
              </mat-tab>
          </div>
      </mat-tab-group>
  </div>
</div>