import { Pipe, PipeTransform } from '@angular/core';
import * as _ from "lodash";

@Pipe({name: 'ledger'})
export class ledgerPipe implements PipeTransform {
  transform(value: Array<object>, search: string): Array<object> {
    var array=[];
    if(search == ''|| search==undefined){
     return value;  
   }
    search = search.toLocaleLowerCase()
    _.find(value,function(o){
     if ( o.transaction_ref_no !=null) {
      if( 
        o.limit_type.toLocaleLowerCase().indexOf(search)>=0 ||
        o.transaction_ref_no.toLocaleLowerCase().indexOf(search)>=0
        ){
        array.push(o)
      }
     }
    })
    return array   
  }  
}