import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statementTotal'
})
export class StatementTotalPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let amount = 0
    value.forEach(element => {
      if (element.typhe == args) {
        amount += Number(element.amount)
      }
    });
    return amount;
  }

}
