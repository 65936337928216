
<footer id="homefooter">
  <div class="tree-img">
    <img src="../../../assets/v3images/shapes/green-trees.png" alt="" srcset="" width="100%" height="100%">
  </div>
  <div class="footer-box pt-4 pb-4 position-relative">
    <div class="container">
      <div class="row">
        <div class="col-sm-3">
          <img src="../../../assets/v3images/logoes/full-logo-footer.svg" width="200px" alt="" srcset="">
          <div class="award mt-2">
            <img src="../../../assets/v3images/award-img.png" alt="">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row">
            <div class="col-md-6 col-lg-4">
              <h4 class="text-white">Company</h4>
              <ul class="p-0 m-0 list-unstyled footer-menues">
                <li class="list-item"><a href="javascript:void(0)" routerLink="/about-us">About Us</a></li>
                <!-- <li class="list-item"><a href="#">Career</a></li> -->
                <li *ngIf="!isLogin" class="list-item"><a href="javascript:void(0)" (click)="moveToSection('registration-section-header')">Register As A Affiliate</a></li>
              </ul>
            </div>
            <div class="col-md-6 col-lg-4">
              <h4 class="text-white">Contact</h4>
              <ul class="p-0 m-0 list-unstyled footer-menues">
                <!-- <li class="list-item"><a href="#">Help/Faqs</a></li> -->
                <li class="list-item"><a href="javascript:void(0)" routerLink="/contact-us">Call Support</a></li>
                <li class="list-item"><a href="javascript:void(0)" routerLink="/general-policy">General T & C</a></li>
              </ul>
            </div>
            <div class="col-md-6 col-lg-4">
              <h4 class="text-white">More</h4>
              <ul class="p-0 m-0 list-unstyled footer-menues">
                <li class="list-item"><a href="javascript:void(0)" routerLink="/payment-policy">Cards & Refunds</a></li>
                <li class="list-item"><a href="javascript:void(0)" routerLink="/privacy-policy">Privacy Policy</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-3 text-center">
          <img src="../../../assets/v3images/flying-plan.png" alt="" srcset="" class="flying-plan position-absolute">
          <div class="social-box d-flex justify-content-center align-items-center">
            <a href="#" class="link"><img src="../../../assets/v3images/icons/fb.svg" alt="" srcset="" ></a>
            <a href="#" class="link"><img src="../../../assets/v3images/icons/twitter.svg" alt="" srcset=""  ></a>
            <a href="#" class="link"><img src="../../../assets/v3images/icons/insta.svg" alt="" srcset="" ></a>
        </div>
            <p class="text-white text-bold">Discover Our App</p>
            <div class="mt-2 d-flex justify-content-center align-items-center app-stores">
              <a href="https://play.google.com/store/apps/details?id=com.aadeshtravels.app" target="_blank"> <img src="../../assets/v3images/play-store.png" alt="" srcset="" class="mr-2 hover-shadow"  /></a>
              <a href="https://apps.apple.com/in/app/aadesh-travel/id1529118394#?platform=iphone" target="_blank"> <img src="../../assets/v3images/app-store.png" alt="" srcset="" class="hover-shadow"  /></a>
          </div>
            <!-- <div class="d-flex justify-content-center align-items-center">
                <img src="../../../assets/v3images/play-store.png" alt="" srcset="" class="mr-2">
                <img src="../../../assets/v3images/app-store.png" alt="" srcset="">
            </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="bottom-footer">
    <div class="container d-flex align-items-center justify-content-between">
      <img src="../../../assets/v3images/logoes/iata-logo.png" alt="" srcset="">
      <p class="text-white m-0">All Rights Reserved @ 2008 - 2021 <a href="#" class="text-dark-orange">Aadesh Travels Pvt Ltd</a></p>
      <img src="../../../assets/v3images/payments-methods.png" alt="" srcset="">
    </div>
  </div>
</footer>

<!-- <footer id="homefooter">
  <div class="lfooter">
    <div class="social">
      <a routerLink="" class="fb"></a>
      <a routerLink="" class="gpluse"></a>
    </div>
    <p>All Rights Reserved @ 2008-2018 Aadesh Travels Pvt. Ltd.</p>
  </div>
  <div class="rfooter">
    <ul>
      <li><a class="" href="javascript:void(0)" routerLink="/about-us">About us</a></li>
      <li><a class="" href="javascript:void(0)" routerLink="/contact-us">Contact</a></li>
      <li><a class="" href="javascript:void(0)" routerLink="/payment-policy">Cards & Refunds</a></li>
      <li><a class="" href="javascript:void(0)" routerLink="/privacy-policy">Privacy Policy</a></li>
      <li><a class="" href="javascript:void(0)" routerLink="/general-policy">General Terms and Conditions</a></li>
    </ul>
  </div>
</footer> -->