import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-addon-meal',
  templateUrl: './addon-meal.component.html',
  styleUrls: ['./addon-meal.component.scss']
})
export class AddonMealComponent implements OnInit {
  @Input() ssrAvailability;
  @Input() responseData;
  @Input() showRequestLoader;
	@Output() callMealEvent : EventEmitter<any> = new EventEmitter();
	status : boolean = false;
	errorMessage: any;
	constructor() { }

	ngOnInit() {
	}

	toggleEvent() {
		this.status = false;
		let eventValue = event.currentTarget['parentElement']['nextElementSibling']['className'];
		if (event.currentTarget['parentElement']['nextElementSibling']['className'].includes('hide')) {
			this.status = false;
			event.currentTarget['parentElement']['nextElementSibling']['className'] = "meal-dropdown show";
		}
		else {
			event.currentTarget['parentElement']['nextElementSibling']['className'] = "meal-dropdown hide";
		}

	}

	changeMeal(meal,segment, leg, pax, isRemove) {
			const tempArr = Object.assign({}, this.ssrAvailability[segment][leg])
			let selectedMeal = meal;
			 
			if (isRemove) {
				event.currentTarget['parentElement']['parentElement']['parentElement']['firstElementChild']['firstElementChild'].value = 'No Meal';
				this.ssrAvailability[segment][leg].travellers[pax]['meal'] = undefined;
				this.createMealObject(pax, null, true, 0, leg,segment, tempArr, null)
				event.currentTarget['parentElement'].parentElement.className = "meal-dropdown hide";

			}
			else {
				this.ssrAvailability[segment][leg].travellers[pax]['meal'] = selectedMeal.name;
				this.ssrAvailability[segment][leg].travellers[pax]['mealType'] = selectedMeal.type;
				if (selectedMeal.name == null) {
					this.ssrAvailability[segment][leg].travellers[pax]['meal'] = selectedMeal.code;
				}
				this.ssrAvailability[segment][leg].travellers[pax]['mealFare'] = selectedMeal.totalFare;
				this.createMealObject(pax, selectedMeal.code, false, selectedMeal.totalFare, leg,segment, tempArr, meal)
				event.currentTarget['parentElement']['parentElement']['parentElement']['parentElement']['firstElementChild']['firstElementChild'].value = `${selectedMeal.name ? selectedMeal.name : ''} ${selectedMeal.code} `
				event.currentTarget['parentElement'].parentElement.parentElement.className = "meal-dropdown hide";
				
			}
	}
	createMealObject(passengerIndex, seatNumber, isRemove, price, leg,segment, tempArr, meal) {
		let flag = true;
		 
		if (tempArr.flightInfo.ssrs[segment] && tempArr.flightInfo.ssrs[segment][leg] && tempArr.flightInfo.ssrs[segment][leg].meals != undefined && tempArr.flightInfo.ssrs[segment][leg].meals != null) {
			tempArr.flightInfo.ssrs[segment][leg].meals = tempArr.flightInfo.ssrs[segment][leg].meals.filter(obj => {
				if (isRemove) {
					flag = false;
					if (obj.travellerNumber != passengerIndex) {
						return obj;
					}
				}
				else {
					if (obj.travellerNumber == passengerIndex) {
						obj['code'] = seatNumber;
						obj['price'] = price;
						obj['item'] = meal;
						flag = false;
					}
					return obj;
				}

			});
		}

		if (flag) {
			let obj = {
				"code": seatNumber,
				"travellerNumber": passengerIndex,
				"item": meal,
				"price": price,
				"title": this.ssrAvailability[segment][leg].travellers[passengerIndex].title,
				"firstName": this.ssrAvailability[segment][leg].travellers[passengerIndex].first_name,
				"lastName": this.ssrAvailability[segment][leg].travellers[passengerIndex].last_name,
			}
			if(tempArr.flightInfo.ssrs[segment] == undefined){
				tempArr.flightInfo.ssrs[segment] = []
			}
			if(tempArr.flightInfo.ssrs[segment][leg] == undefined){
				tempArr.flightInfo.ssrs[segment][leg] = {}
			}
			if (tempArr.flightInfo.ssrs[segment][leg].meals == undefined || tempArr.flightInfo.ssrs[segment][leg].meals == null) {
				tempArr.flightInfo.ssrs[segment][leg].meals = [];
			}
			tempArr.flightInfo.ssrs[segment][leg].meals.push(obj)
		}
		console.log(tempArr)
		this.callMealEvent.emit(tempArr.flightInfo.ssrs);
	}
}