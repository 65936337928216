<section class="sidemenu d-none" [ngClass]="{showMenu: menuStatus}">

    <div class="leftmenu">
        <a [routerLink]="['/payment']">Payment</a>
        <a [routerLink]="['/ledger']">View Ledger</a>
        <a [routerLink]="['/bookingsummary']">Booking Summary</a>
        <a *ngIf="isWhiteLabel" [routerLink]="['/white-label/portal-configuration']" class="portal-config"><span>Portal Configuration</span><i class="fas fa-cog"></i></a>
    </div>
    <div class="rightmenu">
        <!-- <a class="whitelabel" [routerLink]="['/white-label/affiliate-management']">White Label</a> -->
        <!-- <a *ngIf="environmentName != 'stage'"> Calender <span class="count">1</span></a> -->
        <a *ngIf="isGroupFareEnabled" [routerLink]="['/group-fare']">Group Fare</a>
        <a [routerLink]="['/my-account']">My Account</a>
        <a [routerLink]="['/statement']">Statement </a>
    </div>
</section>