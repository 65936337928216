<div class="meal-container">
  <div class="meal-box">
      <div class="meal-containerFull" [@.disabled]="true">
         
          <mat-tab-group>
            <div *ngFor="let segment of ssrAvailability; let s=index;">
              <mat-tab label="{{segment[0].departure}}-{{segment[segment.length-1].arrival}}">
                <mat-tab-group>
                    <div *ngFor="let item of segment; let i = index;">
                        <mat-tab label="{{item.flightCode}}-{{item.flightNo}} ({{item.departure}} - {{item.arrival}})">
                            <div class="traveller-section mt-3">
                                <div class="meal-identifier row">
                                    <div class="col">
                                        <span class="head">Travellers</span>
                                    </div>
                                    <div class="col">
                                        <span class="head">Meals</span>
                                    </div>
                                    <div class="col">
                                        <span class="head">Your Selection</span>
                                    </div>
                                </div>
                                <div class="custom-border row mb-3" *ngFor="let passenger of item.travellers; let k = index;">
                                       <ng-container>
                                          <ng-container *ngIf="passenger.type != 'INF'">
                                              <div class="col traveller-info">
                                                  <div class="traveller-name">{{passenger.title}} {{passenger.first_name}} {{passenger.last_name}}</div>
                                              </div>
                                              <div class="col meal-select">
                                                <!-- MEAL NAME AFTER BOOKING START -->
                                                  <ng-container *ngIf="responseData != undefined && responseData?.ssrs != undefined && responseData?.ssrs[i] != undefined && responseData.ssrs[i]?.meals != undefined && responseData.ssrs[i].meals[k] != undefined && responseData.ssrs[i].meals[k].status != undefined">
                                                      <span *ngIf="responseData.ssrs[i].meals[k].status == 'SUCCESS' else mealSelection"> {{passenger.meal}}</span>
                                                  </ng-container>
                                                <!-- MEAL NAME AFTER BOOKING END -->
                                                  
                                                <!-- MEAL SELECTION WHEN NO MEAL IS SELECTED WHILE BOOKING START -->
                                                  <ng-container *ngFor="let paxSSR of passenger.ssr">
                                                    <ng-container *ngIf="paxSSR.origin == item.departure && paxSSR.destination == item.arrival && paxSSR.hasMeal == undefined">
                                                        <div class="meal-input" >
                                                            <input class="meal-select-field" (click)="toggleEvent()" type="text" placeholder="Select meal">
                                                        </div>
                                                        <div class="meal-dropdown" [ngClass]="status ? 'show' : 'hide'">
                                                            <div class="meal-options">
                                                                <div class="default-selected" (click)="changeMeal(meal,s, i, k, true)" ><i class="fa fa-times" aria-hidden="true"></i>No Meal</div>
                                                                <div class="option-group" *ngFor="let meal of item.meals ; let j = index">
                                                                    <div class="option" (click)="changeMeal(meal,s, i, k, false)">
                                                                        <span class="meal-type" *ngIf="meal.type == 'Non Veg'" ><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1NTVEQzY3NDdFQzQxMUU4QThGMjlEQzUxRjgwM0Q4MSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1NTVEQzY3NTdFQzQxMUU4QThGMjlEQzUxRjgwM0Q4MSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU1NURDNjcyN0VDNDExRThBOEYyOURDNTFGODAzRDgxIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU1NURDNjczN0VDNDExRThBOEYyOURDNTFGODAzRDgxIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+3doPYAAAAVVJREFUeNrclr8vBEEUx+dt/DoROUqRIBKFSqEQ1SkV/oETtYZWolRJFEhUdBKNVqG5QiUKjU5BqXLBXSI0x/N5d3sddpg5uXjJ55vd2WS+82Zn3oyoqquHSA86Bd0uPKzTa6datpckNSigWzDg4kQHLNPvatOyH7Yto+g4V4SCIPN4VWi9cLFDxGZqw6QLXl0rQvUdrSXuD6INTUQE8tAZ36TR8R5Pj/AEz7yfwGQcE5FB9BxWIJ+22mJZgEu+z8bIxDbpVyPuhaOs6UsyssihxYxBjMFcSCbDkPPIdiLEpOq5gKq/N1G9R68yDGpwFvrj1+Dtm+87DOYuzES1hC5+MiVWl3Zh3afu+xS6Y1ZaKd0bo+mGPKX91vdw8a2ottsP/0mBbGOTxExuYLol3YuMoA/NA38TZiJfIobgAPqkfu9qHPhLMJ6x8X7yG15gH8fKhwADAGt0zWhW6/e8AAAAAElFTkSuQmCC"/></span>
                                                                        <span class="meal-type" *ngIf="meal.type == 'Veg'" ><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0NjIxQTlDMDdFQzQxMUU4QTgwMkM5MEVCODgxQUE5NiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0NjIxQTlDMTdFQzQxMUU4QTgwMkM5MEVCODgxQUE5NiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQ2MjFBOUJFN0VDNDExRThBODAyQzkwRUI4ODFBQTk2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjQ2MjFBOUJGN0VDNDExRThBODAyQzkwRUI4ODFBQTk2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+ymZwewAAAVZJREFUeNpi/P//PwMImJxNZwVShkDMyUAdcOuM8cznIAYjyBKgBTpAdi4QbwHiT1SwgBHkbqiDWxiNz6QxAxlTgDgLaPN/BioCoOMdgZQkE5DQA+Jd1LYABIBm7gdSmiBL+ID4HQPtwB8mBjqAwWkJMDL5gZidFD0sRBoMSoo1QJwMxOJA/BcodghIVwIj9yTFPoFasBeIq6AWgAAo2YOS5yGgvB81ggtkuCUOOTYgXgAKQkotSSQgLwjEgWRbAnQhL5CSJsIhapT45DsQ/yLCkg9kWwJMOX+A1B4iLNlFaZxUA/FPPPKLgY65QJElUANAyfQVFulFQJxBlcwItGgXMBEoA5k+0Ej+AgpGoPglquV4qEUgg1cMnwKSXEv+khJs5FpyA4jNaGE4MLEIgC0BRugbIP0bKOBFAwvagXgmI1K7KwRIGYAspFKTCBQNs4GeeAEQYADxe2VeY4WZQgAAAABJRU5ErkJggg=="/></span>
                                                                        <span class="meal-name">{{meal.name}} {{meal.code}}</span>
                                                                        <span class="meal-price">&#8377; {{meal.totalFare}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                  </ng-container>

                                                  <!-- MEAL SELECTION WHEN NO MEAL IS SELECTED WHILE BOOKING END -->

                                                  <!-- ASSIGNED MEAL TEMPLATE START -->
                                                  <ng-template #assignedMeal>
                                                    <ng-container *ngFor="let paxSSR of passenger.ssr">
                                                       <ng-container *ngIf="paxSSR.origin == item.departure && paxSSR.destination == item.arrival">
                                                        <span class="meal-type  mr-2" *ngIf="paxSSR?.meals != null && paxSSR?.meals?.code?.name?.includes('Non Veg')" ><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1NTVEQzY3NDdFQzQxMUU4QThGMjlEQzUxRjgwM0Q4MSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1NTVEQzY3NTdFQzQxMUU4QThGMjlEQzUxRjgwM0Q4MSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU1NURDNjcyN0VDNDExRThBOEYyOURDNTFGODAzRDgxIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU1NURDNjczN0VDNDExRThBOEYyOURDNTFGODAzRDgxIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+3doPYAAAAVVJREFUeNrclr8vBEEUx+dt/DoROUqRIBKFSqEQ1SkV/oETtYZWolRJFEhUdBKNVqG5QiUKjU5BqXLBXSI0x/N5d3sddpg5uXjJ55vd2WS+82Zn3oyoqquHSA86Bd0uPKzTa6datpckNSigWzDg4kQHLNPvatOyH7Yto+g4V4SCIPN4VWi9cLFDxGZqw6QLXl0rQvUdrSXuD6INTUQE8tAZ36TR8R5Pj/AEz7yfwGQcE5FB9BxWIJ+22mJZgEu+z8bIxDbpVyPuhaOs6UsyssihxYxBjMFcSCbDkPPIdiLEpOq5gKq/N1G9R68yDGpwFvrj1+Dtm+87DOYuzES1hC5+MiVWl3Zh3afu+xS6Y1ZaKd0bo+mGPKX91vdw8a2ottsP/0mBbGOTxExuYLol3YuMoA/NA38TZiJfIobgAPqkfu9qHPhLMJ6x8X7yG15gH8fKhwADAGt0zWhW6/e8AAAAAElFTkSuQmCC"/></span>
                                                        <span class="meal-type  mr-2" *ngIf="paxSSR?.meals != null && !paxSSR?.meals?.code?.name?.includes('Non Veg')" ><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0NjIxQTlDMDdFQzQxMUU4QTgwMkM5MEVCODgxQUE5NiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0NjIxQTlDMTdFQzQxMUU4QTgwMkM5MEVCODgxQUE5NiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQ2MjFBOUJFN0VDNDExRThBODAyQzkwRUI4ODFBQTk2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjQ2MjFBOUJGN0VDNDExRThBODAyQzkwRUI4ODFBQTk2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+ymZwewAAAVZJREFUeNpi/P//PwMImJxNZwVShkDMyUAdcOuM8cznIAYjyBKgBTpAdi4QbwHiT1SwgBHkbqiDWxiNz6QxAxlTgDgLaPN/BioCoOMdgZQkE5DQA+Jd1LYABIBm7gdSmiBL+ID4HQPtwB8mBjqAwWkJMDL5gZidFD0sRBoMSoo1QJwMxOJA/BcodghIVwIj9yTFPoFasBeIq6AWgAAo2YOS5yGgvB81ggtkuCUOOTYgXgAKQkotSSQgLwjEgWRbAnQhL5CSJsIhapT45DsQ/yLCkg9kWwJMOX+A1B4iLNlFaZxUA/FPPPKLgY65QJElUANAyfQVFulFQJxBlcwItGgXMBEoA5k+0Ej+AgpGoPglquV4qEUgg1cMnwKSXEv+khJs5FpyA4jNaGE4MLEIgC0BRugbIP0bKOBFAwvagXgmI1K7KwRIGYAspFKTCBQNs4GeeAEQYADxe2VeY4WZQgAAAABJRU5ErkJggg=="/></span>
                                                         {{paxSSR?.meals?.name}}
                                                       </ng-container>
                                                    </ng-container>
                                                  </ng-template>
                                                  <!-- ASSIGNED MEAL TEMPLATE END -->

                                                  <!-- MEAL SELECTION TEMPLATE START -->
                                                  <ng-template #mealSelection>
                                                      <div class="meal-input">
                                                          <input class="meal-select-field" (click)="toggleEvent()" type="text" placeholder="Select meal">
                                                      </div>
                                                      <div class="meal-dropdown" [ngClass]="status ? 'show' : 'hide'">
                                                          <div class="meal-options">
                                                              <div class="default-selected" (click)="changeMeal(meal,s, i, k, true,false)" ><i class="fa fa-times" aria-hidden="true"></i>No Meal</div>
                                                              <div class="option-group" *ngFor="let meal of item.meals ; let j = index">
                                                                  <div class="option" (click)="changeMeal(meal, i, k, false)">
                                                                      <span class="meal-type" *ngIf="meal.type == 'Non Veg'" ><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1NTVEQzY3NDdFQzQxMUU4QThGMjlEQzUxRjgwM0Q4MSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1NTVEQzY3NTdFQzQxMUU4QThGMjlEQzUxRjgwM0Q4MSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU1NURDNjcyN0VDNDExRThBOEYyOURDNTFGODAzRDgxIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU1NURDNjczN0VDNDExRThBOEYyOURDNTFGODAzRDgxIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+3doPYAAAAVVJREFUeNrclr8vBEEUx+dt/DoROUqRIBKFSqEQ1SkV/oETtYZWolRJFEhUdBKNVqG5QiUKjU5BqXLBXSI0x/N5d3sddpg5uXjJ55vd2WS+82Zn3oyoqquHSA86Bd0uPKzTa6datpckNSigWzDg4kQHLNPvatOyH7Yto+g4V4SCIPN4VWi9cLFDxGZqw6QLXl0rQvUdrSXuD6INTUQE8tAZ36TR8R5Pj/AEz7yfwGQcE5FB9BxWIJ+22mJZgEu+z8bIxDbpVyPuhaOs6UsyssihxYxBjMFcSCbDkPPIdiLEpOq5gKq/N1G9R68yDGpwFvrj1+Dtm+87DOYuzES1hC5+MiVWl3Zh3afu+xS6Y1ZaKd0bo+mGPKX91vdw8a2ottsP/0mBbGOTxExuYLol3YuMoA/NA38TZiJfIobgAPqkfu9qHPhLMJ6x8X7yG15gH8fKhwADAGt0zWhW6/e8AAAAAElFTkSuQmCC"/></span>
                                                                      <span class="meal-type" *ngIf="meal.type == 'Veg'" ><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0NjIxQTlDMDdFQzQxMUU4QTgwMkM5MEVCODgxQUE5NiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0NjIxQTlDMTdFQzQxMUU4QTgwMkM5MEVCODgxQUE5NiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQ2MjFBOUJFN0VDNDExRThBODAyQzkwRUI4ODFBQTk2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjQ2MjFBOUJGN0VDNDExRThBODAyQzkwRUI4ODFBQTk2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+ymZwewAAAVZJREFUeNpi/P//PwMImJxNZwVShkDMyUAdcOuM8cznIAYjyBKgBTpAdi4QbwHiT1SwgBHkbqiDWxiNz6QxAxlTgDgLaPN/BioCoOMdgZQkE5DQA+Jd1LYABIBm7gdSmiBL+ID4HQPtwB8mBjqAwWkJMDL5gZidFD0sRBoMSoo1QJwMxOJA/BcodghIVwIj9yTFPoFasBeIq6AWgAAo2YOS5yGgvB81ggtkuCUOOTYgXgAKQkotSSQgLwjEgWRbAnQhL5CSJsIhapT45DsQ/yLCkg9kWwJMOX+A1B4iLNlFaZxUA/FPPPKLgY65QJElUANAyfQVFulFQJxBlcwItGgXMBEoA5k+0Ej+AgpGoPglquV4qEUgg1cMnwKSXEv+khJs5FpyA4jNaGE4MLEIgC0BRugbIP0bKOBFAwvagXgmI1K7KwRIGYAspFKTCBQNs4GeeAEQYADxe2VeY4WZQgAAAABJRU5ErkJggg=="/></span>
                                                                      <span class="meal-name">{{meal.name}} {{meal.code}}</span>
                                                                      <span class="meal-price">&#8377; {{meal.totalFare}}</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </ng-template>
                                                  <!-- MEAL SELECTION TEMPLATE END -->
        
                                              </div>
                                              <div class="col selected-meal">
                                                  <ng-container *ngIf="passenger.meal !=undefined else assignedMeal">
                                                      <span class="meal-type" *ngIf="passenger.mealType == 'Non Veg'" ><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1NTVEQzY3NDdFQzQxMUU4QThGMjlEQzUxRjgwM0Q4MSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1NTVEQzY3NTdFQzQxMUU4QThGMjlEQzUxRjgwM0Q4MSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU1NURDNjcyN0VDNDExRThBOEYyOURDNTFGODAzRDgxIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU1NURDNjczN0VDNDExRThBOEYyOURDNTFGODAzRDgxIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+3doPYAAAAVVJREFUeNrclr8vBEEUx+dt/DoROUqRIBKFSqEQ1SkV/oETtYZWolRJFEhUdBKNVqG5QiUKjU5BqXLBXSI0x/N5d3sddpg5uXjJ55vd2WS+82Zn3oyoqquHSA86Bd0uPKzTa6datpckNSigWzDg4kQHLNPvatOyH7Yto+g4V4SCIPN4VWi9cLFDxGZqw6QLXl0rQvUdrSXuD6INTUQE8tAZ36TR8R5Pj/AEz7yfwGQcE5FB9BxWIJ+22mJZgEu+z8bIxDbpVyPuhaOs6UsyssihxYxBjMFcSCbDkPPIdiLEpOq5gKq/N1G9R68yDGpwFvrj1+Dtm+87DOYuzES1hC5+MiVWl3Zh3afu+xS6Y1ZaKd0bo+mGPKX91vdw8a2ottsP/0mBbGOTxExuYLol3YuMoA/NA38TZiJfIobgAPqkfu9qHPhLMJ6x8X7yG15gH8fKhwADAGt0zWhW6/e8AAAAAElFTkSuQmCC"/></span>
                                                      <span class="meal-type" *ngIf="passenger.mealType == 'Veg'" ><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0NjIxQTlDMDdFQzQxMUU4QTgwMkM5MEVCODgxQUE5NiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0NjIxQTlDMTdFQzQxMUU4QTgwMkM5MEVCODgxQUE5NiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQ2MjFBOUJFN0VDNDExRThBODAyQzkwRUI4ODFBQTk2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjQ2MjFBOUJGN0VDNDExRThBODAyQzkwRUI4ODFBQTk2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+ymZwewAAAVZJREFUeNpi/P//PwMImJxNZwVShkDMyUAdcOuM8cznIAYjyBKgBTpAdi4QbwHiT1SwgBHkbqiDWxiNz6QxAxlTgDgLaPN/BioCoOMdgZQkE5DQA+Jd1LYABIBm7gdSmiBL+ID4HQPtwB8mBjqAwWkJMDL5gZidFD0sRBoMSoo1QJwMxOJA/BcodghIVwIj9yTFPoFasBeIq6AWgAAo2YOS5yGgvB81ggtkuCUOOTYgXgAKQkotSSQgLwjEgWRbAnQhL5CSJsIhapT45DsQ/yLCkg9kWwJMOX+A1B4iLNlFaZxUA/FPPPKLgY65QJElUANAyfQVFulFQJxBlcwItGgXMBEoA5k+0Ej+AgpGoPglquV4qEUgg1cMnwKSXEv+khJs5FpyA4jNaGE4MLEIgC0BRugbIP0bKOBFAwvagXgmI1K7KwRIGYAspFKTCBQNs4GeeAEQYADxe2VeY4WZQgAAAABJRU5ErkJggg=="/></span>
                                                      <span class="s-meal-name">{{passenger.meal}} &nbsp;</span>
                                                      <!-- <span class="s-price">&#8377; {{passenger.mealFare}}</span> -->
                                                  </ng-container>
                                                          <ng-container *ngIf="responseData != undefined && responseData?.ssrs != undefined && responseData?.ssrs[i] != undefined && responseData.ssrs[i]?.meals != undefined && responseData.ssrs[i].meals[k] != undefined && responseData.ssrs[i].meals[k].status != undefined">
                                                              <span *ngIf="responseData.ssrs[i].meals[k].status == 'SUCCESS'" class="ssr-status status-success"> {{responseData.ssrs[i].meals[k].status}}</span>
                                                              <span *ngIf="responseData.ssrs[i].meals[k].status == 'FAILED'" class="ssr-status status-error"> {{responseData.ssrs[i].meals[k].status}}</span>
                                                          </ng-container>
                                                           <ng-container *ngIf="responseData == undefined && showRequestLoader">
                                                             <span class="status-loader"> <i class="fas fa-circle-notch fa-spin"></i></span>
                                                           </ng-container>
      
      
                                                  <ng-template #statusField>
                                                      <ng-container *ngIf="responseData != undefined && responseData?.ssrs != undefined && responseData?.ssrs[i] != undefined && responseData.ssrs[i]?.meals != undefined && responseData.ssrs[i]?.meals != null">
                                                          <ng-container *ngFor="let bResp of responseData.ssrs[i].meals">
                                                              <span *ngIf="bResp.status == 'SUCCESS' && bResp.travellerNumber == k" class="ssr-status status-success"> {{bResp.status}}</span>
                                                              <span *ngIf="bResp.status == 'FAILED' && bResp.travellerNumber == k" class="ssr-status status-error"> {{bResp.status}}</span>
                  
                                                          </ng-container>
                                                      </ng-container>
                                                  </ng-template>
                                              </div>
                                          </ng-container>
                                       </ng-container>
                                </div>
                            </div>
                        </mat-tab>
                    </div>
                </mat-tab-group>
              </mat-tab>
            </div>
          </mat-tab-group>
      </div>
  </div>
</div> 

