<form [action]="myAppURL" method="GET" #refreshForm></form>
<!-- Button to Open the Modal -->
<div class="page-loader" [ngClass]="loading ? 'd-block' : 'd-none'">
  <div class="loader">
    <div
    class=" text-center" 
  >
    <div class="img-container">
      <img
        src="../assets/v3images/animated-rocket.gif"
        alt=""
      />
    </div>
    <div
      class="animated-loader-text d-flex align-items-center justify-content-center"
    >
      <p class="text-white text-center" ><span class="text-bold d-block">Please Wait!</span><small>we are processsing data </small></p>
      <!-- <div id="wave">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div> -->
    </div>
  </div>
  </div>
</div>

<button type="button" data-backdrop="static" class="btn btn-primary" data-toggle="modal" data-target="#newRelease" #newRelease [hidden]="true" > 
    Open modal
  </button>
  <!-- The Modal -->
  <div class="modal fade" id="newRelease">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content new-release">
        <!-- Modal body -->
        <div class="modal-body">
           <div class="dashboard">
            <img src="../assets/images/rocket.png" alt="">
           </div>
         <h3>New Version is now available!</h3>
         <p>Our team worked super hard to bring some new features and improving your experience !
        </p>
         
    <button class="btn cursor-pointer pulse btn-success rounded-pill pl-3 pr-3 " (click)="setVersion()"><i class="fa fa-refresh text-white mr-2"></i>Reload</button>
        </div>
  
     
      </div>
    </div>
  </div>
<div class="new-version-bar alert alert-warning d-flex justify-content-between align-items-center" *ngIf="showStrip">
  <div class="mr-3">
    <h4 class=" m-0"><span style="color:#fbff00">New Version</span> is now available!</h4>
  <p>Our team worked super hard to bring some new features and improving your experience !</p>
  </div>
    <button class="btn btn-sm cursor-pointer pulse bg-white rounded-pill pl-3 pr-3 " (click)="setVersion()"><i class="fa fa-refresh  mr-2"></i>Reload</button>
</div>
<app-header *ngIf="isHeader" [appVersion]="currentVersion"></app-header>
<!-- <ngx-loading [show]="loading" [config]="{  }" [template]="customLoadingTemplate"></ngx-loading>    -->


<router-outlet></router-outlet>
<!-- <app-footer *ngIf="showFooter"></app-footer> -->
<div [ngClass]="showFooter == false ? 'd-none' : 'd-block' "   >
  <app-footer  [isLogin]="isLogin" ></app-footer>
</div>


  <div *ngIf="isLogin && showWhatsappLogo" class="chat-wrapper">
    <button class="chat-button" (click)="togglePopup()" title="Chat with Us">
      <img src="../assets/icons/whatsapp.svg" alt="whatsapp" width="35px" height="35px"/>
      </button>
    <app-whatsapp-popup [isPopupVisible]="isPopupVisible"></app-whatsapp-popup>
  </div>





