<section class="contents viewledger mat-white-input-tab mb-3 pb-5">

  <div class="container-fluid mt-3 ledger-container">
    <div class="row" >
        <div class="col-12 text-center" [ngClass]="{show : printDiv, hide : !printDiv}">
            <div >
              <img src="../../../assets/images/logo2.png" alt="">
            </div>
          </div>
      <div class="col-12 text-center">
        <p class="text-dark-green text-bold fa-2x">Weekly Statement</p>
      </div>
    </div>
    <div class="ledger-table-container container-fluid">
      <div class="align-items-center card d-flex flex-dir-row justify-content-between mb-2 pb-2 pl-3 pr-3 pt-2">
          <label class="control-lable text-black text-bold  " *ngIf="dataSource.length > 0">For the Period of <span class="text-pinkish-red ">{{fromDate | date}}</span> To <span class="text-pinkish-red">{{toDate | date}}</span></label>
      <div class=" text-center  " *ngIf="dataSource.length > 0" [ngClass]="{hide: printDiv, show : !printDiv}">
        <button class="btn btn-success" (click)="callPdfGeneratorApi()">Save as PDF</button> 
      </div>
        </div>
      <!-- <div class="col-12 text-center clearfix  mb-2 pr-0" *ngIf="dataSource.length > 0">
        <label class="control-lable font-weight-bold">For the Period of <span
            class="lblHead">{{fromDate | date}}</span> To <span class="lblHead">{{toDate | date}}</span>  </label>
      </div> -->
      <!-- <div class="col-12 text-center clearfix  mb-2 pr-0" *ngIf="dataSource.length > 0" [ngClass]="{hide: printDiv, show : !printDiv}">
        <label class="col control-lable font-weight-bold text-center"><button class="btn btn-info" (click)="callPdfGeneratorApi()">Save as PDF</button>  </label>
      </div>
     -->
      <div class="mobile-scroll-table custom-table">
        <table class="table ">
          <thead  [ngClass]="{hide: printDiv, show : !printDiv}">
            <tr>
              <th  class="text-left"> Date <i class="fa fa-sort" aria-hidden="true" (click)="dateSort()"></i></th>
              <th class="text-left">Reference No.<i class="fa fa-sort" aria-hidden="true" (click)="invoiceSort()"></i></th>
              <th class="text-left">Particulars</th>
              <th class="text-right">Debit</th>
              <th class="text-right">Credit</th>
              <th class="text-right">Balance</th>
            </tr>
          </thead>
          <tbody class="custom-table-body" #printBox [ngStyle]="{height: innerHeight}">
              <tr [ngClass]="{show : printDiv, hide : !printDiv}">
                  <td class="text-left"> <strong>Date</strong></td>
                  <td class="text-left"> <strong>Reference No.</strong></td>
                  <td class="text-left"> <strong>Particulars</strong></td>
                  <td class="text-right"> <strong>Debit</strong></td>
                  <td class="text-right"> <strong>Credit</strong></td>
                  <td class="text-right"> <strong>Balance</strong></td>
                </tr>
            <tr class="custom-table-row" *ngFor="let element of dataSource">
              <td  class="text-left">{{element.timestamph | dateFormat}}</td>
              <td  class="text-left"><span>{{element.transaction_ref_no}}</span></td>
              <td  class="text-left">
                <span class="no-wrap">{{element.narration_1}}</span>
                <span class="no-wrap">{{element.narration_2}}</span>
                <span class="no-wrap">{{element.narration_3}}</span>
                <span class="no-wrap">{{element.narration_4}}</span>
              </td>
              <td class="text-right">
                <span class="text-bold   text-danger" *ngIf="element.typhe == 'debit'">
                  {{element.amount | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                <span class="text-bold   text-danger"
                  *ngIf="element.typhe == 'credit'">{{0 | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
              </td>
              <td class="text-right ">
                <span class="text-bold text-success "
                  *ngIf="element.typhe == 'debit'">{{0 | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                <span class="text-bold text-success"
                  *ngIf="element.typhe == 'credit'">{{element.amount | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
              </td>
              <td class="text-right "><span class="text-info text-bold">&#8377; {{element.balance | number : '1.0-0'}}</span></td>
            </tr>
            <tr class="custom-table-row2" [ngClass]="{show: printDiv, hide : !printDiv}">
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td><strong>Total : </strong></td>
                <td class="text-right  text-bold">
                  <span class="text-bold text-danger">
                    {{dataSource | statementTotal:'debit' | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                </td>
                <td class="text-right text-success  text-bold">
                  <span
                    class="bold">{{dataSource | statementTotal:'credit' | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
                </td>
                <td class="text-right text-info  text-bold">&#8377;
                  {{(dataSource | statementTotal : 'debit') - (dataSource | statementTotal : 'credit') | number : '1.0-0'}}
                </td>
              </tr>
          </tbody>
          
          <tfoot class="align-items-center card d-flex flex-dir-row justify-content-between mb-2 pb-2 pl-3 pr-3 pt-2" [ngClass]="{hide: printDiv, show : !printDiv}">
            <tr class="custom-table-row2">
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td ><strong>Total : </strong></td>
              <td class="text-right  text-bold">
                <span class="text-bold text-danger">
                  {{dataSource | statementTotal:'debit' | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
              </td>
              <td class="text-right   text-bold">
                <span
                  class="text-bold text-success">{{dataSource | statementTotal:'credit' | currency:"INR":"symbol-narrow":'1.0-0'}}</span>
              </td>
              <td class="text-right text-info text-bold">&#8377;
                {{(dataSource | statementTotal : 'debit') - (dataSource | statementTotal : 'credit') | number : '1.0-0'}}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</section>