import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TravellerRoutingModule } from './traveller-routing.module';
// import { TravellerComponent } from './traveller.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CountdownModule } from 'ngx-countdown';
import { BookingService } from './booking.service';
// import { SeatMapComponent } from './seat-map/seat-map.component';
// import { MealComponent } from './meal/meal.component';
// import { BaggageComponent } from './baggage/baggage.component';
import { CentrifugeService } from 'src/app/services/centrifuge.service';

@NgModule({
  imports: [
    CommonModule,
    TravellerRoutingModule,
    SharedModule,CountdownModule
  ],
  // declarations: [TravellerComponent, MealComponent, BaggageComponent],
  providers : [BookingService,CentrifugeService]
})
export class TravellerModule { }
