import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatTabHeaderPosition } from '@angular/material';

@Component({
  selector: 'app-re-attemp-seat',
  templateUrl: './re-attemp-seat.component.html',
  styleUrls: ['./re-attemp-seat.component.scss']
})
export class ReAttempSeatComponent implements OnInit {
  @Input() seatMapArr;
	@Input() headerPosition: MatTabHeaderPosition
	@Output() callSeatEvent: EventEmitter<any> = new EventEmitter();
	selected_seat: boolean = false;
	selectCount: number = 0;
	totalFare: Number = 0;
	passNumber: number;
	passenger: any = [];
	seat = [];
	selectedPass: number;
	@Input() passengerArr = []
	removedSeatPassengerNumber = 0;
	removedSeat = false;
	constructor() { }

	ngOnInit() {
		this.selectedPass = 0
	}
	selectSeat(key, item, k) {
		if (key.type.toLocaleUpperCase() == 'OPEN') {
			let passengerLen = 0;
			for (let i = 0; i < item.travellers.length; i++) {
				if (item.travellers[i].type != 'INF') {
					passengerLen++;
				}
			}
			if (this.passengerArr.length == 0) {
				let obj = {
					pCount: 0
				}
				this.passengerArr[k] = obj;
			}
			else {
				if (this.passengerArr[k] == undefined || !this.passengerArr[k].hasOwnProperty('pCount')) {
					let obj = {
						pCount: 0
					}
					this.passengerArr[k] = obj;
				}
				else if (this.passengerArr[k].pCount < passengerLen - 1) {
					this.passengerArr[k].pCount++
				}

			}
			this.selectCount = this.passengerArr[k].pCount;
			if (this.removedSeat) {
				this.selectCount = this.removedSeatPassengerNumber;
				this.passengerArr[k].pCount = this.selectCount;
				this.removedSeat = false;
			}
			let assosiateTravellerInfoMap = item["associateTravellerInfoMap"];
			if(assosiateTravellerInfoMap.has(item.travellers[this.selectCount].firstName)) {
				let exitRow = key.displayAttribute ? key.displayAttribute['EXITROW'] ? key.displayAttribute['EXITROW'] : false : false;
				if(exitRow == "True") {
					exitRow = true;
					if(this.passengerArr[k].pCount == 0) {
						this.passengerArr[k] = [];
					} 
					else{
						this.passengerArr[k].pCount--;
					}
					alert('This seat can not be reserved for adult who is accompanying infant!');
					return false;
				}
				else{
					for(let i = 0; i<item.values.length;i++){
						if(item.values[i][0].number == key.number) {
							if(this.passengerArr[k].pCount == 0) {
								this.passengerArr[k] = [];
							} 
							else{
								this.passengerArr[k].pCount--;
							}
							alert('This seat can not be reserved for adult who is accompanying infant!');
							return false;
						}
					}
				}

				
			}
			this.totalFare = 0;
			const tempArr = Object.assign({}, item)
			this.passenger = tempArr.travellers[this.selectCount];
			const element = Object.assign({}, tempArr);
			const tempMyObj = Object.assign({}, element.travellers[this.selectCount]);
			tempMyObj['isSelected'] = false;
			tempMyObj["seat"] = key.number;
			tempMyObj["totalFare"] = key['totalFare'];
			if (element.travellers[this.selectCount].seat == undefined || element.travellers[this.selectCount].seat == "") {
				element.travellers[this.selectCount] = tempMyObj;
			} else {
				this.unselectSeat(tempArr.values, element.travellers[this.selectCount].seat, k)
				element.travellers[this.selectCount] = tempMyObj;
			}
			this.makeSeatObject(this.selectCount, element.travellers[this.selectCount].seat, false, tempMyObj["totalFare"], k, tempArr, key)
			tempArr['totalSelected'] += 1;
			key['status'] = 'selected';
			key['type'] = 'selected';
			this.seatMapArr[k] = tempArr;
		}
		else if (key.type == 'Reserved') {
			alert('Seat is Already reserved')
		}
		else if (key.type == 'FleetBlocked') {
			alert('Fleet Blocked')
		}
		else {
			alert('Selected Seat Cannot be booked online')
		}
		console.log('Seat Is', key.type)
	}

	removeSeat(selectedSeat, item, legNo, passengerNo) {
		let fullSeats = item.values
		let traveller = item.travellers;
		this.unselectSeat(fullSeats, selectedSeat, legNo);
		const element = traveller[passengerNo];
		if (selectedSeat == element.seat) {
			element.seat = undefined;
			this.removedSeatPassengerNumber = passengerNo;
			this.removedSeat = true;
		}
		this.makeSeatObject(passengerNo, selectedSeat, true, 0, legNo, item, '')
	}
	unselectSeat(fullSeats, selectedSeat, k) {
		for (let j = 0; j < fullSeats.length; j++) {
			const element = fullSeats[j];
			let charges = Object.values(element)
			for (let k = 0; k < charges.length; k++) {
				let ch = charges[k]
				if (ch['number'] == selectedSeat) {
					ch['status'] = 'notselected'
					ch['type'] = 'Open'

				}
			}
		}
		//  ;
		// this.seatMapArr

	}

	selectPassenger(passenger,l){
		for(let i=0;i<passenger.length;i++) {
			if(passenger[i].hasOwnProperty('isSelected')) {
				passenger[i]['isSelected'] =  false
			}
			
		}
		passenger[l]['isSelected'] =  true
		this.removedSeatPassengerNumber = l;
	}

	makeSeatObject(passengerIndex, seatNumber, isRemove, price, leg, tempArr, key) {
		let flag = true;
		if (tempArr.flightInfo.ssrs[leg].seats != undefined) {
			tempArr.flightInfo.ssrs[leg].seats = tempArr.flightInfo.ssrs[leg].seats.filter(obj => {
				if (isRemove) {
					flag = false;
					if (obj.travellerNumber != passengerIndex) {
						return obj;
					}
				}
				else {
					if (obj.travellerNumber == passengerIndex) {
						obj['number'] = seatNumber;
						obj['name'] = seatNumber;
						obj['price'] = price;
						obj['item'] = key;
						flag = false;
					}
					return obj;
				}

			});
		}

		if (flag) {
			let obj = {
				"number": seatNumber,
				"name": seatNumber,
				"travellerNumber": passengerIndex,
				"item": key,
				"price": price
			}
			if (tempArr.flightInfo.ssrs[leg].seats == undefined) {
				tempArr.flightInfo.ssrs[leg].seats = [];
			}
			tempArr.flightInfo.ssrs[leg].seats.push(obj)
		}
		console.log(tempArr.flightInfo.ssrs);
		this.callSeatEvent.emit(tempArr.flightInfo.ssrs);
	}
}
