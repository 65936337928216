<div class="summary card mt-2">
  <p class="card-header text-bold m-0 text-left text-pinkish-red">SSR</p>
  <div class="card-body p-2 pl-3 pr-3">
    <div>
      <div>
        <span class="float-left">Seat Charges (+)</span>
        <span class="float-right">{{
          seatAmount | currency: "INR":"symbol-narrow"
        }}</span>
        <div class="clearfix"></div>
      </div>

      <div>
        <span class="float-left">Meal Charges (+)</span>
        <span class="float-right">{{
          mealAmount | currency: "INR":"symbol-narrow"
        }}</span>
        <div class="clearfix"></div>
      </div>

      <div>
        <span class="float-left">Baggage Charges (+)</span>
        <span class="float-right">{{
          baggageAmount | currency: "INR":"symbol-narrow"
        }}</span>
        <div class="clearfix"></div>
      </div>

      <div>
        <span class="float-left">Addnl. Baggage Charges (+)</span>
        <span class="float-right">{{
          additionalBaggageAmount | currency: "INR":"symbol-narrow"
        }}</span>
        <div class="clearfix"></div>
      </div>

      <div>
        <span class="float-left">Ancillary Charges (+)</span>
        <span class="float-right">{{
          ancillaryAmount | currency: "INR":"symbol-narrow"
        }}</span>
        <div class="clearfix"></div>
      </div>

      <div>
        <span class="float-left">Total SSR Amount</span>
        <span class="float-right">{{
          totalAddOnPrice | currency: "INR":"symbol-narrow"
        }}</span>
        <div class="clearfix"></div>
      </div>

      <!-- <div class="total-p-fare">
				<span class="float-left">Grand Total</span>
				<span class="float-right">{{totalUpdatedAmount | currency:"INR":"symbol-narrow"}}</span>
				<div class="clearfix"></div>
			</div> -->
    </div>
  </div>
  <div class="card active p-0 mt-2 traveller-grand-total">
    <div class="row m-0 grand-total-card">
      <div class="text-center pl-0">
        <h4 class="text-bold text-uppercase">Grand Total</h4>       
      </div>
      <!---->
      <div class="price-col ng-star-inserted">
        <h3 class="offered-price text-bold d-flex align-items-center">
          <i class="fas fa-tags mr-3"></i>{{ totalUpdatedAmount  | currency: "INR":"symbol-narrow":"0.0-0"}}
        </h3>
      </div>
      <!---->
    </div>
  </div>
  <div class="summary-inner">
    <div class="flight-summary">
      <div class="full-fare-details"></div>
    </div>
  </div>
</div>
