import { Component, OnInit, OnChanges, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PostService } from 'src/app/services/post.service';
import { TravellerService } from 'src/app/services/traveller/traveller.service';
import { MatDialog } from '@angular/material';
import { NotificationService } from 'src/app/services/notification.service';
import { CommonService } from 'src/app/services/common.service';
import { FlightDetailPopupComponent } from '../../flight/flight-detail-popup/flight-detail-popup.component';

@Component({
	selector: 'app-pnr-retrieve-selected-flight',
	templateUrl: './pnr-retrieve-selected-flight.component.html',
	styleUrls: ['./pnr-retrieve-selected-flight.component.scss']
})

export class PnrRetrieveSelectedFlightComponent implements OnInit {
	messageList
	loading: boolean = false;
	isMinimized = true;
	@Input() invalidCredit;
	constructor(
		private router: Router, 
		private commonService : CommonService,
		private postService: PostService,
		private travellerService: TravellerService,
		public dialog: MatDialog,
		private notificationService : NotificationService) { }

	@Input() selectedFlightData;
	@Input() selectedFlightCount;
	@Input() total;
	@Input() mode;
	@Input() bookingRef;
	selectedSegmentsCode = ''
	searchedSegmentsCode = ''
	@Output() callDoBooking:  EventEmitter<any> = new EventEmitter();
	@Input() totalCommission;
	ngOnInit() {
		this.messageList = this.notificationService.messageObj
	}	

	showMessage(type,message,detail){
		this.messageList['status'] = true
		let obj ={
			warningType : type,
			message : message,
			detail : detail
		}
		this.messageList['messageList'].push(obj)
		this.notificationService.showMessage(this.messageList)
	}

	secureSeat(data) {
		return new Promise((resolve, reject) => {
			this.postService.postMethod(`${environment.url}/book/secureseat`, data).subscribe(res => {
				if (res != "") {
					resolve(res);
				}
				else {
					resolve('');
				}
			}, err => {
				 
				console.log("Error in booking : ", err)
				resolve(err['error']);
			})

		})
	}

	getFlightFromStateManage(key) {
		return new Promise((resolve, reject) => {
			this.postService.getMethod(`${environment.url}/state/${key}`).subscribe(res => {
				if(JSON.parse(res["_body"])) {
					resolve(JSON.parse(res["_body"]).data)
				}
				
			}, err => {
				 
				console.log("Error in booking : ", err)
				resolve(err['error']);
			})

		})
	}

	addBackDrop() {
		const body = document.getElementsByTagName('body')[0];
		body.classList.add('blockOverlay');
		this.loading = true
	}
	removeBackDrop() {
		const body = document.getElementsByTagName('body')[0];
		body.classList.remove('blockOverlay');
		this.loading = false
	}
	
	flightBook(flight){

		localStorage.setItem('selectedFlight', JSON.stringify(flight))
		this.commonService.insertFlightData(flight);
		let tab = JSON.parse(sessionStorage.getItem('matSelectedSearchFlight'));
		if (this.mode == 're-issue') {
			this.router.navigate(['pages/traveller-re-issue',{ mode : this.mode ,ref : this.bookingRef}]);		
		}else{
			if(tab.name == 'Return'){
				if (this.selectedFlightCount['outboundSelected'] && this.selectedFlightCount['inboundSelected']) {
					this.router.navigate(['pages/twoway-traveller']);
				}
				else{
					alert('Select Both Flight');
				}
			}
			else{
				this.router.navigate(['pages/traveller']);			
			}
		}
	}
	returnDate(date) {
		return this.commonService.returnDate(date);
	}

	returnTime(date) {
		return this.commonService.returnTime(date);
	}

	getDuration(dt1, dt2,segment,supplier) {
		let duration = ''
		// duration = this.commonService.timeDuration(dt2,dt1);
		if (supplier == 'AMADEUS_V4' || supplier == 'AMADEUS_STUD' || supplier == 'SABRE') {
			duration =  this.commonService.getMinuteToHour(segment.durationInMinutes);
		}
		else if ((supplier == 'AMADEUS_V4' || supplier == 'AMADEUS_STUD' || supplier == 'SABRE') && segment.durationInMinutes) {
			duration = this.commonService.timeDuration(dt2,dt1);
		}
		else{
			duration = this.commonService.timeDuration(dt2,dt1);
		}
		return duration
	}
	
	
	checkIfNUll = (value)=>{
		// console.log(value)
		if(value == null || value == undefined || value == ""){
		
			return '-'
		}
		else{
			return value;
		}
	}

	getFare(flight){
		let total = 0
		for(const fare of flight.fares) {
			if(fare.priceType == flight.priceType) {
				total =  fare.total;
				break;
			}
		}
		return total;
	}

	filightDetail(flight) {
		
		const dialogRef = this.dialog.open(FlightDetailPopupComponent, {
			width: '700px',
			panelClass: 'custom-dialog-container',
			data: flight
		});
		dialogRef.afterClosed().subscribe(result => {
		});
	}

	doBooking() {
		this.callDoBooking.emit();
	}
	
}
