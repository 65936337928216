import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BookingService } from 'src/app/services/booking/booking.service';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver'; 


@Component({
  selector: 'app-print-ticket-multicity',
  templateUrl: './print-ticket-multicity.component.html',
  styleUrls: ['./print-ticket-multicity.component.scss']
})
export class PrintTicketMulticityComponent implements OnInit {

  @ViewChild('close') close : ElementRef;
	data: any = [];
	bookingData: any = [];
	userData: any = [];
	flightRequest: any = [];
	fareCharges: any = [];
	legs: any = [];
	statesArr: any = [];
	segment: any; 
	flightFare: any = [];
	bookingArr: any = [];
	valid: boolean = false;
	pricePaxArr: any = [];
	affiliateMarkUpPrice: number = 0;
	totalFare;
	searchId: string;
	userDetail: any = {};
	ticketArr: any = [];
	status: boolean = false;
	// isSelected : boolean = false;
	hasMeal: boolean = false;
	hasBaggage: boolean = false;
	emailForm: any;
	successMsg: boolean = false;
	errorMsg: boolean = false;
	isMulticity: boolean = false;
  multicityData: any;


	constructor(private router: Router,private commonService : CommonService, private route: ActivatedRoute,
		private bookingService: BookingService, private affiliateService: AffiliateService
		,private fb: FormBuilder) { }

	ngOnInit() {
		let searchId = this.route.snapshot.params['searchId'];
		let withoutPrice = ""
		this.status  = false
		if (window.location.hash.includes('?')) {
			withoutPrice = window.location.hash.split('?')[1].split("=")[1]
		}
		if (withoutPrice == "true") {
			this.status  = true
		}
		this.userData = JSON.parse(localStorage.getItem('user_details'));
		this.searchId = searchId;
		this.bookingService.getBookingWithBookingRef(searchId).subscribe(res=>{
			let data = JSON.parse(res["_body"])['data']
			this.ticketArr = data
      this.bookingArr = this.ticketArr[0]['json_doc']
			console.log(this.bookingArr);
			this.checkSSR(this.bookingArr.travellers)
      this.getLoginAffiliateDetails(this.ticketArr[0]['json_doc']['affiliate_id']);
      this.bookingService.getMulticityTicket(this.searchId).subscribe(res=>{
         
        let multicyData =  JSON.parse(res["_body"])['data']
        this.multicityData = multicyData
        this.isMulticity = true
      })
		})  
		this.emailForm = this.fb.group({
			'txtEmail': ['', [Validators.required, Validators.email]],
			'nopriceCheck': [false],
		})
		// this.ticketArr = JSON.parse(localStorage.getItem('bookingArr'))
		// this.bookingArr = this.ticketArr['json_doc']
		// console.log('Booking Arr', this.bookingArr)
		
	}
	get txtEmail(){
		return this.emailForm.controls.txtEmail;
	}
	get nopriceCheck(){
		return this.emailForm.controls.nopriceCheck;
	}
	checkSSR(travellers){
		this.hasMeal = false
		this.hasBaggage = false
		for (let i = 0; i < travellers.length; i++) {
			const element = travellers[i];
			if (element.ssr != null) {
				for (let j = 0; j < element.ssr.length; j++) {
					const el = element.ssr[j];
					if (el.baggages != null) {
						if (el.baggages.name) {
							this.hasBaggage = true
						}
					}
					if (el.meals != null) {
						if (el.meals.code) {
							this.hasMeal = true
						}
					}
				}
			}
		}
		
	}
	getTime(date) {
		return date.split('+').splice(0, 1)
	}
	getLoginAffiliateDetails(affiliateID) {
		// createAffliate/getAffiliate?userId=136
		this.commonService.getAffiliateDetailForTicket('createAffliate/getAffiliate?userId='+affiliateID).subscribe(data=>{
			this.userDetail = data[0];
		})
		// this.affiliateService.getAffiliateDetails().subscribe(res => {
		// 	this.userDetail = res[0];
		// 	console.log('affiliate details', this.userDetail)
		// })
	}
	getTicket(ticket){
		if (ticket != null && ticket != undefined) {
			return ticket
		}
		else{
			return "N/A"
		}
	}

	printTicket(){
		// document.body.getElementsByTagName('app-print-ticket-multicity')[0].firstElementChild.classList.remove('mb-fix')
		window.print()
	}

	getSSRPrice( ssr) {
		let meal = 0;
		let baggage = 0;
		let seat = 0;
		if (ssr != null) {
			for (let i = 0; i < ssr.length; i++) {
				const element = ssr[i];
				if (element.meals != null && element.meals.code != null) {
					meal += Number(this.checkifNull(element.meals.code.totalFare));
				}
				if (element.baggages != null && element.baggages.code != null) {
					baggage += Number(this.checkifNull(element.baggages.code.totalFare));
				}
				if (element.seats != null && element.seats.code != null) {
					seat += Number(this.checkifNull(element.seats.code.totalFare));
				}
			}
		}
		return  Number(meal) + Number(baggage) + Number(seat)
	}

	checkifNull(value){
		if (value == undefined) {
			return 0
		}
		else{
			return value
		}
	}

	createPriceDetails(data) {
		let mapArr = new Map();
		this.totalFare = 0;
		data.forEach(val => {
			if (val.tax_name == 'total') {
				this.totalFare += Number(val.tax_price);
			}
			if (val.tax_name == 'affiliateMarkup') {
				this.affiliateMarkUpPrice += Number(val.tax_price);
			}
			if (mapArr.has(val.pax_name + val.id)) {
				let mapVal = mapArr.get(val.pax_name + val.id)
				if (mapVal['price']) {
					if (mapVal.price[val.tax_name] != undefined) {
						let value = mapVal.price[val.tax_name];
						val.tax_price = Number(val.tax_price) + Number(value);
					}
					mapVal['price'][val.tax_name] = val.tax_price;
				}
				else {
					mapVal['price'] = {};
					mapVal['price'][val.tax_name] = val.tax_price;
				}

				val = mapVal;
				mapArr.set(val.pax_name + val.id, val)
			}
			else {
				val['price'] = {}
				val['price'][val.tax_name] = val.tax_price;
				mapArr.set(val.pax_name + val.id, val);
			}
		})
		this.pricePaxArr = Array.from(mapArr)

		console.log('map arr', this.pricePaxArr)
		// if(priceMap.has)
	}

	returnDate(date) {
		let d = new Date(date.split("+")[0]);
		return d.toDateString()

	}

	returnTime(date) {
		let d = new Date(date.split("+")[0]);
		return d.toTimeString().split(' ')[0];
	}

	print() {
		window.print()
	}



	checkStatus() {
		this.status = true;
		setTimeout(() => {
			window.print();
			this.status = false;
		}, 100);
	}


}
