import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateComponent } from './template.component';
import { Routes, RouterModule } from '@angular/router';
import { PrintTicketComponent } from './print-ticket/print-ticket.component';
import { PrintInvoiceComponent } from './print-invoice/print-invoice.component';
// import { NumberToWordsPipe } from './print-invoice/print-invoice.pipe';
import { CreditNoteRecieptComponent } from './credit-note-reciept/credit-note-reciept.component';
import { ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { OfflineReceiptComponent } from './offline-receipt/offline-receipt.component';
import { StatementPrintComponent } from './statement-print/statement-print.component';
import { PrintTicketMulticityComponent } from './print-ticket-multicity/print-ticket-multicity.component';

const routes: Routes = [
	{ path: '', redirectTo: 'template', pathMatch: 'full' },
	{
		path: '',
		component: TemplateComponent,
		children: [
			{ path: 'print-ticket', component: PrintTicketComponent },
			{ path: 'print-invoice', component: PrintInvoiceComponent },
			{ path: 'print-credit-receipt', component: CreditNoteRecieptComponent },
			{ path: 'offline-receipt', component: OfflineReceiptComponent },
			{ path: 'print-statement', component: StatementPrintComponent },
			{ path: 'print-multicity-ticket', component: PrintTicketMulticityComponent }
		]
	}
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(routes), SharedModule
	],
	declarations: [TemplateComponent,PrintTicketComponent,PrintInvoiceComponent, CreditNoteRecieptComponent, OfflineReceiptComponent, StatementPrintComponent, PrintTicketMulticityComponent]
})
export class TemplateModule { }
