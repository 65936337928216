<div class="timeline">
  <div class="outer">
    <div class="card invoice" *ngFor="let data of bookingActivity">
      <div class="type-img">
        <img src="../../../../assets/v3images/icons/white-invoice.png" alt="" srcset="">
      </div>
      <div class="info">
        <h3 class="title  "> 
          <small class="type text-bold d-block mb-1">INVOICE</small>
          <span class="date-time text-bold   ">{{data?.activity_timestamp | date : 'dd/MM/yyyy HH:mm'}}</span>
        </h3>
        <p class="discription mb-2">{{data.activity_description}}</p>
        <div class="booking-imp-links">
          <p *ngIf="data.accounting_ref_no != ''" class="mb-0">
            <small *ngIf="data.activity_tx_type != 'REFUNDED'" class="text-bold">Invoice No. : </small>
            <small *ngIf="data.activity_tx_type == 'REFUNDED'" class="text-bold">Credit Note No. : </small>
              <small class="text-bold  ml-2 text-dark-pink cursor" (click)="printInvoice(data.accounting_ref_no,data.activity_tx_type)" >{{data.accounting_ref_no}}</small>
          </p>
          <p  class="mb-0">
            <small   class="text-bold">Booking Reference :</small>
                <small class="text-bold  ml-2 text-dark-pink cursor"  (click)="openDetail(data.booking_ref_no)">{{data?.booking_ref_no}}</small>
          </p>
          <p class="mb-0" *ngIf="data.booking_ref_no != data.child_booking_ref_no">
            <small   class="text-bold" >
              New Booking Reference :</small>
                <small class="text-bold  ml-2 text-dark-pink cursor"  (click)="openDetail(data.child_booking_ref_no)">{{data?.child_booking_ref_no}}</small>
          </p>
          <div class="d-flex justify-content-center" *ngIf="bookingActivity == undefined">
            <small class="txet-bold text-black"> There is no activity for <b class="text-pinkish-red">{{bookingActivityReference}}</b> </small>
          </div>
        </div>

        <small class="user-name d-block text-right text-grey text-bold mt-2">by: {{data?.activity_by_user_name}}</small>
      </div>
    </div>

  </div>
</div>






<!-- <div class="timeline">
  <div class="outer">
    <div class="card" *ngFor="let data of bookingActivity">
      <div class="info">
        <h3 class="title d-flex align-item-center justify-content-between"> 
          <span class="date-time text-bold text-pinkish-red ">{{data?.activity_timestamp | date : 'dd/MM/yyyy HH:mm'}}</span>
          <small class="user-name text-grey text-bold mt-2">by: {{data?.activity_by_user_name}}</small>
        </h3>
        <small class="mb-2">{{data.activity_description}}</small>
        <p *ngIf="data.accounting_ref_no != ''" class="mb-0">
          <small *ngIf="data.activity_tx_type != 'REFUNDED'" class="text-bold">Invoice No. : </small>
          <small *ngIf="data.activity_tx_type == 'REFUNDED'" class="text-bold">Credit Note No. : </small>
            <small class="text-bold  ml-2 text-dark-pink cursor" (click)="printInvoice(data.accounting_ref_no,data.activity_tx_type)" >{{data.accounting_ref_no}}</small>
        </p>
        <p  class="mb-0">
          <small   class="text-bold">Booking Reference :</small>
              <small class="text-bold  ml-2 text-dark-pink cursor"  (click)="openDetail(data.booking_ref_no)">{{data?.booking_ref_no}}</small>
        </p>
        <p class="mb-0" *ngIf="data.booking_ref_no != data.child_booking_ref_no">
          <small   class="text-bold" >
            New Booking Reference :</small>
              <small class="text-bold  ml-2 text-dark-pink cursor"  (click)="openDetail(data.child_booking_ref_no)">{{data?.child_booking_ref_no}}</small>
        </p>
        <div class="d-flex justify-content-center" *ngIf="bookingActivity == undefined">
          <small class="txet-bold text-black"> There is no activity for <b class="text-pinkish-red">{{bookingActivityReference}}</b> </small>
        </div>
      </div>
    </div>

  </div>
</div> -->



<!-- 
<div class="kt-portlet kt-portlet--height-fluid">
    <div class="kt-portlet__body">
        <div class="kt-timeline-v2 ps ps--active-y">
          <div class="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
            <div class="kt-timeline-v2__item" *ngFor="let data of bookingActivity" >
              <span class="kt-timeline-v2__item-time">
                  <span class="date-time">{{data?.activity_timestamp | date : 'dd/MM/yyyy HH:mm'}}</span>
                  {{data?.activity_by_user_name}}
                  
              </span>
              <div class="kt-timeline-v2__item-cricle">
                <i class="far fa-circle kt-circle-1"></i>
              </div>
              <div class="kt-timeline-v2__item-text kt-padding-top-5">
                <span class="d-flex">
                 {{data.activity_description}}
                </span> 
                <span class="d-flex" *ngIf="data.accounting_ref_no != ''">
                  <ng-container *ngIf="data.activity_tx_type != 'REFUNDED'">Invoice No. </ng-container>
                  <ng-container *ngIf="data.activity_tx_type == 'REFUNDED'">Credit Note No. </ng-container>
                    : &nbsp; <a href="javascript:void(0)" (click)="printInvoice(data.accounting_ref_no,data.activity_tx_type)">{{data.accounting_ref_no}}</a>
                 </span>
                <span class="d-flex">
                  Booking Reference :  &nbsp; <a href="javascript:void(0)" (click)="openDetail(data.booking_ref_no)">{{data?.booking_ref_no}}</a>
                 </span>
                <span class="d-flex" *ngIf="data.booking_ref_no != data.child_booking_ref_no">
                  New Booking Reference :  &nbsp; <a href="javascript:void(0)" (click)="openDetail(data.child_booking_ref_no)">{{data?.child_booking_ref_no}}</a>
                 </span>
              </div>
            </div>
            <div class="" *ngIf="bookingActivity == undefined">
              <span> There is no activity for {{bookingActivityReference}} </span>
            </div>
            </div>
        </div>
    </div>
</div> -->

