<section class="one-way-selected-fare bootmfixpart bottom-modal">
  <!-- <ngx-loading [show]="loading" [config]="{ fullScreenBackdrop : false }"></ngx-loading> -->
  <div
    class="result-preloarder text-center"
    [ngClass]="loading ? 'd-block' : 'd-none'"
    style="background: #fafafa"
  >
    <div class="img-container">
      <img
        src="../../../../../assets/v3images/flying-flying.gif"
        alt=""
      />
    </div>
    <div
      class="animated-loader-text d-flex align-items-center justify-content-center"
    >
      <!-- <small >Fetching Search Result </small> -->
      <div id="wave">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </div>
  </div>
  <!-- <div [ngClass]="loading ? 'd-none' : 'd-block'">
	<button  [ngClass]="isMinimized ? 'd-block' : 'd-none'" (click)="isMinimized = !isMinimized" title="Minimize" class="min-max-btn btn-green btn-sm"><i class="fa fa-angle-down "></i></button>
	<button [ngClass]="isMinimized ? 'd-none' : 'd-block'" (click)="isMinimized = !isMinimized"  title="Minimize" class="min-max-btn btn-green btn-sm"><i class="fa fa-angle-up "></i></button>
	</div> -->
  <div *ngIf="selectedFlightData">
    <ng-container *ngFor="let flight of selectedFlightData">
      <div class="flight-detailsbox active">
        <ng-container *ngFor="let segment of flight.json_doc['segments']; let s=index">
          <div class="container-fluid">
            <div class="row m-0">
              <div
                class="col-md-2 airline-box"
                [ngClass]="isMinimized ? 'd-none' : 'd-block'"
              >
                <div
                  class="airline-logo-name mt-2 d-flex align-items-center justify-content-center"
                >
                  <div class="airlogo m-0">
                    <img
                      src="assets/images/flight/{{ segment.legs[0].operating_airline_code }}.png"
                      alt=""
                      onError="this.src='assets/images/icon7.png'"
                    />
                  </div>
                  <div>
                    <p class="airline-name mb-0 text-uppercase">
                      {{ segment.legs[0].operating_airline_name }}
                    </p>
                    <div class="airline-flight-codes">
                      <span
                        >{{ segment.legs[0].operating_airline_code }} -
                        {{ segment.legs[0]["flight_number"] }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-md-10"
                [ngClass]="isMinimized ? 'd-none' : 'd-block'"
              >
                <div class="d-flex align-items-center justify-content-between m-0">
                  <div class="airline-card-content">
                    <div
                      class="departure flight-info d-flex align-items-center"
                    >
                      <h4>
                        <img
                          src="../../../../../assets/v3images/icons/purple-take-off-plan.svg"
                          alt=""
                          width="25px"
                          height="25px"
                          class="mr-2"
                        />
                        {{ segment.legs[0].origin_airport_city }}
                        <span class="text-grey ml-2"
                          >({{
                            checkIfNUll(segment.legs[0].origin_airport_code)
                          }})</span
                        >
                      </h4>
                      <div class="flight-time-date mt-2 ml-4">
                        <p class="flight-time mb-0">
                          <img
                            src="../../../../../assets/v3images/icons/purple-clock.svg"
                            alt=""
                            width="20px"
                            height="20px"
                            class="mr-2"
                          />
                          {{ segment.legs[0].origin_timestamp | timeFormat }}
                        </p>
                        <span class="flight-date">{{
                          segment.legs[0].origin_timestamp | dateGmtFormat
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="travelling-time">
                    <div
                      class="box segment-info type text-center lay-tooltip"
                    >
                      <p class="mb-1 text-bold">
                        {{
                          getDuration(
                            segment.legs[0].origin_timestamp,
                            segment.legs[segment.legs.length - 1]
                              .destination_timestamp,
                            segment,
                            segment.booking_supplier
                          )
                        }}
                      </p>
                      <div class="separator-line"></div>
                      <h6>
                        <span *ngIf="segment.legs.length == 1"> Non Stop</span>
                        <span *ngIf="segment.legs.length > 1">
                          {{ segment.legs.length - 1 }} Stop</span
                        >
                      </h6>
                    </div>
                  </div>
                  <div class="airline-card-content">
                    <div
                      class="destination flight-info d-flex align-items-center"
                    >
                      <h4>
                        <img
                          src="../../../../../assets/v3images/icons/purple-landing-plan.svg"
                          alt=""
                          width="25px"
                          height="25px"
                          class="mr-2"
                        />
                        {{
                          segment.legs[segment.legs.length - 1]
                            .arr
                        }}<span class="text-grey ml-2"
                          >({{
                            checkIfNUll(
                              segment.legs[segment.legs.length - 1]
                                .destination_airport_code
                            )
                          }})</span
                        >
                      </h4>
                      <div class="flight-time-date mt-2 ml-4">
                        <p class="flight-time mb-0">
                          <img
                            src="../../../../../assets/v3images/icons/purple-clock.svg"
                            alt=""
                            width="20px"
                            height="20px"
                            class="mr-2"
                          />{{
                            segment.legs[segment.legs.length - 1]
                              .destination_timestamp | timeFormat
                          }}
                        </p>
                        <span class="flight-date">{{
                          segment.legs[segment.legs.length - 1].destination_timestamp
                            | dateGmtFormat
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 pl-0 pr-0">
                <div class="grandtotal row m-0">
                  <div class="col-lg-3" [ngClass]="isMinimized ? 'd-none' : 'd-block'">
                    <h4 class="text-bold text-uppercase">
                      Grand Total<span class="text-pinkish-red" [ngClass]="isMinimized ? ' airline-box' : ' '">&#8377; {{flight.totalPublishedFare}} </span>
                    </h4>
                  </div>
                  <div class="col-lg-8 p-0">
                    <div class="row m-0 minified-flight-brief" [ngClass]="isMinimized ? '' : 'd-none'">
                      <div class="col-md-4 col-xl-3 airline-box m-0" style="border-right: 1px solid #e6e6e6">
                        <div class="airline-logo-name mt-2 d-flex align-items-center">
                          <div class="airlogo m-0">
                            <img src="assets/images/flight/{{segment.legs[0].operating_airline_code}}.png" alt="" onError="this.src='assets/images/icon7.png'" />
                          </div>
                          <div class="ml-2 text-left">
                            <p class="airline-name m-0 text-uppercase">
                              {{ segment.legs[0].operating_airline_name }}
                            </p>
                            <div class="airline-flight-codes d-flex align-items-center">
                              <span class="ml-0">{{ segment.legs[0].operating_airline_code }} -
                                {{ segment.legs[0]["flight_number"] }}</span>
                            </div>
                          </div>
                          <img (click)="filightDetail(flight)" title="Flight Detail" src="../../../../../assets/v3images/icons/orange-info.svg"
                            alt="" srcset="" width="10px" height="10px" class="ml-2 cursor-pointer" />
                        </div>
                      </div>
                      <div class="col-md-8 col-lg-9" style="display: flex;align-items: center;" *ngIf="s == 0">
                          <div class="row" style="max-width: 100%;width: 100%;">
                              <div class="col-3" style="display: flex;align-items: center;"><div class="fare-type">{{flight.json_doc.fare_type}}</div></div>
                              <div class="col-6" style="display:flex;align-items: center;">
                                <p class="commission-info" >Your Commission : <strong style="margin-left: 5px;" class="text-pinkish-red">₹{{totalCommission | number : '1.0-0'}}</strong> </p>
                              </div>
                          </div>
                        <!-- <div class="d-flex align-items-center justify-content-between m-0">
                          <div class="airline-card-content">
                            <div class="departure flight-info d-flex flex-warp">
                              <h4>
                                <img
                                  src="../../../../../assets/v3images/icons/purple-take-off-plan.svg"
                                  alt=""
                                  class="mr-1"
                                  width="15px"
                                  height="15px"
                                />
                                {{
                                  checkIfNUll(
                                    segment.legs[0].origin_airport_code
                                  )
                                }}
                              </h4>
                              <div class="flight-time-date ml-2">
                                <p class="flight-time mb-0">
                                  <img
                                    src="../../../../../assets/v3images/icons/purple-clock.svg"
                                    alt=""
                                    width="10px"
                                    height="10px"
                                    class="mr-1"
                                  />
                                  {{
                                    segment.legs[0].origin_timestamp
                                      | timeFormat
                                  }}
                                </p>
                                <small class="flight-date">{{
                                  segment.legs[0].origin_timestamp
                                    | dateGmtFormat
                                }}</small>
                              </div>
                            </div>
                          </div>
                          <div class="travelling-time">
                            <div
                              class="box segment-info type text-center lay-tooltip"
                            >
                              <small class="mb-1">
                                {{
                                  getDuration(
                                    segment.legs[0].origin_timestamp,
                                    segment.legs[segment.legs.length - 1]
                                      .destination_timestamp
                                  )
                                }}
                              </small>
                              <small class="d-block">
                                <span *ngIf="segment.legs.length == 1">
                                  Non Stop</span
                                >
                                <span *ngIf="segment.legs.length > 1">
                                  {{ segment.legs.length - 1 }} Stop</span
                                ></small
                              >
                            </div>
                          </div>
                          <div class="airline-card-content">
                            <div
                              class="destination flight-info d-flex flex-wrap"
                            >
                              <h4>
                                <img
                                  src="../../../../../assets/v3images/icons/purple-landing-plan.svg"
                                  alt=""
                                  width="15px"
                                  height="15px"
                                  class="mr-1"
                                />
                                {{
                                  checkIfNUll(
                                    segment.legs[segment.legs.length - 1]
                                      .destination_airport_code
                                  )
                                }}
                              </h4>
                              <div class="flight-time-date ml-2">
                                <p class="flight-time mb-0">
                                  <img
                                    src="../../../../../assets/v3images/icons/purple-clock.svg"
                                    alt=""
                                    width="10px"
                                    height="10px"
                                    class="mr-1"
                                  />{{
                                    segment.legs[segment.legs.length - 1]
                                      .destination_timestamp | timeFormat
                                  }}
                                </p>
                                <small class="flight-date">{{
                                  segment.legs[segment.legs.length - 1]
                                    .destination_timestamp | dateGmtFormat
                                }}</small>
                              </div>
                            </div>
                          </div>
                          <div class="flight-price">
                            <span class="fare-type-style d-flex shadow mt-1" title="{{flight.priceType}}">
                              <ng-container *ngIf="flight.priceType == 'STUD' || flight.priceType == 'SRCT' ;else regularFareTag">
                                <img class="w-25  invert" *ngIf="flight.priceType =='STUD'" src="assets/v3images/icons/white-student-cap.svg" alt="STUD">
                                <img class="w-25  invert" *ngIf="flight.priceType =='SRCT'" src="assets/v3images/icons/white-senior-citizen.svg" alt="SRCT">
                              </ng-container>
                              <ng-template #regularFareTag>
                                <i class="fa fa-tags bg-success text-white mr-1"></i> 
                              </ng-template>
                                                                       
                              {{flight.json_doc.fare_type}}
                            </span>

                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div *ngIf="s == 0"
                    class="d-flex align-items-center justify-content-end"
                    [ngClass]="isMinimized ? 'col-md-4' : 'col-lg-3'"
                  >
                    <h4
                      class="text-bold text-uppercase"
                      [ngClass]="isMinimized ? 'd-block mr-3' : 'd-none'"
                    >
                      <small class="ml-2 d-block mb-1 mt-2"
                        >Total Payble</small
                      ><span class="text-pinkish-red">&#8377; {{flight.totalPublishedFare}}</span>
                    </h4>
                    <button
                      [ngClass]="loading ? 'd-none' : 'd-block'"
                      type="submit"
                      class="btn btn-green btn-large text-bold"
                      (click)="doBooking()"
                      *ngIf="!invalidCredit"
                    >
                      Issue Ticket
                      <i class="fa fa-angle-right text-white ml-2"></i>
                    </button>
                    <span *ngIf="invalidCredit" style="text-transform: uppercase; color: red;">Insufficient Balance!</span>
                    <button
                      class="btn btn-green btn-large text-bold"
                      [ngClass]="loading ? 'd-block' : 'd-none'"
                    >
                      <div id="wave">
                        <span class="dot"></span>
                        <span class="dot"></span>
                        <span class="dot"></span>
                      </div>
                    </button>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</section>

